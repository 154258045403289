import React from "react";

function EstimationTransactionsStatusesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#292d32"
        data-name="Group 179968"
        transform="translate(5017.75 11620.75)"
      >
        <path
          d="M8 8.75H4C.582 8.75-.75 7.418-.75 4V0a.75.75 0 01.463-.693.75.75 0 01.817.163l8 8A.75.75 0 018 8.75zM.75 1.811V4c0 2.582.668 3.25 3.25 3.25h2.189z"
          transform="translate(-5005 -11620)"
        ></path>
        <g data-name="Group 179967" transform="translate(-5191 -11810)">
          <path
            d="M13 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h5a.75.75 0 01.75.75.75.75 0 01-.75.75H7c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h6c2.346 0 3.886-.447 4.845-1.405S19.25 15.346 19.25 13V8a.75.75 0 011.5 0v5c0 2.77-.586 4.647-1.845 5.905S15.77 20.75 13 20.75z"
            data-name="Vector"
            transform="translate(174 190)"
          ></path>
          <path
            d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(179 201)"
          ></path>
          <path
            d="M4 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(179 205)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default EstimationTransactionsStatusesIcon;