import { isEnglish, isNotEmpty, parseNumber } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import EstimationMapIcon from "../../../../icons/EstimationMap";
import { CUSTOMER_LEVEL_SPECIFIC, POLICY_LVL_GENERAL, STATUS_ACTIVE } from "../../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const HeaderSummary = (props) => {
    const { propertyPolicy } = props;
    const { t } = useTranslation();

    return (
        <div className="frm-summary-card">
            <div className='summary-header-title'>
                <div className="header-title-panel">
                    <span><EstimationMapIcon /></span>
                    <label>{t('propertiesPolicies:summary')}</label>
                </div>
            </div>
            <div className={style.summaryCode}>
                <div className="td-column">{propertyPolicy?.code}</div>
                <div>
                    <div title={t(propertyPolicy?.isActive == STATUS_ACTIVE ? 'active' : 'inactive')} className={`status-${propertyPolicy.isActive}`}>
                        <label >
                            {t(propertyPolicy?.isActive == STATUS_ACTIVE ? 'active' : 'inactive')}
                        </label>
                    </div>
                </div>
            </div>

            {(propertyPolicy?.usageLevelData || propertyPolicy?.function) &&
                <div className={style.summaryRef}>
                    <div>
                        <label className="form-label">{t('propertiesPolicies:policy_level')}</label>
                        <div>{(isEnglish() ? propertyPolicy.usageLevelData?.descLo : propertyPolicy.usageLevelData?.descFo) || '-'}</div>
                    </div>
                    <div>
                        <label className="form-label">{t('propertiesPolicies:form')}</label>
                        <div>{(isEnglish() ? propertyPolicy.function?.descLo : propertyPolicy.function?.descFo) || '-'}</div>
                    </div>
                </div>
            }

            {(propertyPolicy?.descLo || propertyPolicy.descFo) &&
                <div className={style.descSummaryContainer}>
                    <div className={style.payMethodTitle}>
                        <label>{t('propertiesPolicies:name')}</label>
                    </div>
                    <div className={style.descSummary}>
                        {((isEnglish() ? propertyPolicy?.descLo : propertyPolicy.descFo) || "-")}
                    </div>
                </div>
            }

            {propertyPolicy?.rem &&
                <div className={style.descSummaryContainer}>
                    <div className={style.payMethodTitle}>
                        <label>{t('propertiesPolicies:remarks')}</label>
                    </div>
                    <div className={style.descSummary}>
                        {propertyPolicy.rem}
                    </div>
                </div>
            }
        </div>
    )
}

const Totals = (props) => {
    const { propertyPolicy: data } = props;

    const { t } = useTranslation("propertiesPolicies");

    return (
        <div className={style.totalsTrx}>

            {(data?.ffrId != RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID && data?.ffrId != RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID && isNotEmpty(data.ipeId)) &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('actions')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{(data?.actions || []).length}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allBranches) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('branches')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allBranches == CUSTOMER_LEVEL_SPECIFIC ? (data?.branches || []).length : t(`customer_level_${data?.allBranches}`)}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allDivisions) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('divisions')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allDivisoions == CUSTOMER_LEVEL_SPECIFIC ? (data?.divisions || []).length : t(`customer_level_${data?.allDivisoions}`)}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allPropertyTypes) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('properties_types')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allPropTypes == CUSTOMER_LEVEL_SPECIFIC ? (data?.propertiesTypes || []).length : t(`customer_level_${data?.allPropTypes}`)}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allLocation) == 1 &&
                <>
                    <div>
                        <div className='totalsLbl'>
                            <label>{t('regions')}</label>
                        </div>
                        <div className='totalsVal'>
                            <label id="payment-orders-total-alc">{data?.allRegions == CUSTOMER_LEVEL_SPECIFIC ? (data?.regions || []).length : t(`customer_level_${data?.allRegions}`)}</label>
                        </div>
                    </div>

                    <div>
                        <div className='totalsLbl'>
                            <label>{t('cities')}</label>
                        </div>
                        <div className='totalsVal'>
                            <label id="payment-orders-total-alc">{data?.allCities == CUSTOMER_LEVEL_SPECIFIC ? (data?.cities || []).length : t(`customer_level_${data?.allCities}`)}</label>
                        </div>
                    </div>
                </>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allRsmParties) == 1 &&
                <>
                    <div>
                        <div className='totalsLbl'>
                            <label>{t('parties_categories')}</label>
                        </div>
                        <div className='totalsVal'>
                            <label id="payment-orders-total-alc">{data?.allPropPartyCats == CUSTOMER_LEVEL_SPECIFIC ? (data?.partiesCategories || []).length : t(`customer_level_${data?.allPropPartyCats}`)}</label>
                        </div>
                    </div>

                    <div>
                        <div className='totalsLbl'>
                            <label>{t('properties_parties')}</label>
                        </div>
                        <div className='totalsVal'>
                            <label id="payment-orders-total-alc">{data?.allPropParties == CUSTOMER_LEVEL_SPECIFIC ? (data?.propertiesParties || []).length : t(`customer_level_${data?.allPropParties}`)}</label>
                        </div>
                    </div>
                </>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allCustCat) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('customer_categories')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allCustCats == CUSTOMER_LEVEL_SPECIFIC ? (data?.customerCategories || []).length : t(`customer_level_${data?.allCustCats}`)}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allCustomers) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('customers')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allCustomers == CUSTOMER_LEVEL_SPECIFIC ? (data?.customers || []).length : t(`customer_level_${data?.allCustomers}`)}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allEstimationForms) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('workflow')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allEstimationForms == CUSTOMER_LEVEL_SPECIFIC ? (data?.estimationForms || []).length : t(`customer_level_${data?.allEstimationForms}`)}</label>
                    </div>
                </div>
            }

            {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.estimationFormSec) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('menu_forms')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allEstimationFormSec == CUSTOMER_LEVEL_SPECIFIC ? (data?.securityForms || []).length : t(`customer_level_${data?.allEstimationFormSec}`)}</label>
                    </div>
                </div>
            }

            {parseNumber(data?.function?.estimationStatuses) == 1 &&
                <div>
                    <div className='totalsLbl'>
                        <label>{t('estimation_statuses')}</label>
                    </div>
                    <div className='totalsVal'>
                        <label id="payment-orders-total-alc">{data?.allEstimationStatuses == CUSTOMER_LEVEL_SPECIFIC ? (data?.estimationStatuses || []).length : t(`customer_level_${data?.allEstimationStatuses}`)}</label>
                    </div>
                </div>
            }
        </div>
    );
}

const Summary = (props) => {
    const { propertyPolicy } = props;

    return (
        <div className="summary-container">
            <div className="summary-table">
                <HeaderSummary propertyPolicy={propertyPolicy} />
            </div>
            {propertyPolicy?.function && <Totals propertyPolicy={propertyPolicy} />}
        </div>
    )
}

export default Summary;