import { deleteItemByIndex, isNotEmpty } from "@ipgd-gauge/utils";
import { CUSTOMER_LEVEL_ALL, CUSTOMER_LEVEL_NONE, POLICY_LVL_GENERAL } from "../../../../../util/constants";
import { RSM3005_PROPERTIES_ESTIMATION } from "../../../../../util/constants/forms";

export const ACTION_SET_PROPERTIES_POLICY = '1';
export const ACTION_SET_PROPERTIES_POLICY_VALUE = '2';
export const ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES = '3';
export const ACTION_DELETE_ALL_LINES_PROPERTIES_POLICY_DETAIL_LINES = '4';
export const ACTION_DELETE_LINE_PROPERTIES_POLICY_DETAIL_LINES = '5';
export const ACTION_SET_LINE_VALUE_PROPERTIES_POLICY_DETAIL_LINES = '6';
export const ACTION_SET_RESTICTION_POLICY_VALUE = '7';
export const ACTION_RETRIEVE_FUNCTION = '8';
export const ACTION_UPDATE_PROPERTIES_POLICY = '9';

const _handleFunctionNUsageLevelChange = (state, user) => {

    state.restrictionPolicy = {
        optn117201: false,
        optn117207: false,
        optn117220: false,
        optn117217: false,
        optn117221: false,
        optn117213: false,
        optn117215: false,
        optn117216: null,
        showAllInMap: false,
        optn117225: false,
        optn117209: null,
        mobileNumDigits: null,
        defaultVatPerc: null,
        quotationValidtyDays: null,
        estimationSessionTimeout: null,
        optn117214: null,
        organizationFeesCdaId: null,
        estimatorFeesCdaId: null,
    };

    if (state.ffrId == RSM3005_PROPERTIES_ESTIMATION) {
        state.restrictionPolicy.optn117209 = user?.fogId;
        state.restrictionPolicy.estimationSessionTimeout = 30;
    }

    if (state?.usageLevel == POLICY_LVL_GENERAL) {
        state.allBranches = (state?.usageLevel == POLICY_LVL_GENERAL && state.function?.allBranches) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
        state.allDivisoions = (state?.usageLevel == POLICY_LVL_GENERAL && state.function?.allDivisions) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE

        if (state?.usageLevel == POLICY_LVL_GENERAL && state.ffrId != RSM3005_PROPERTIES_ESTIMATION) {
            state.allPropTypes = CUSTOMER_LEVEL_ALL
            state.allPropPartyCats = CUSTOMER_LEVEL_ALL
            state.allPropParties = CUSTOMER_LEVEL_ALL
        } else {
            state.allPropTypes = state.ffrId == RSM3005_PROPERTIES_ESTIMATION ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
            state.allPropPartyCats = state.ffrId == RSM3005_PROPERTIES_ESTIMATION ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
            state.allPropParties = state.ffrId == RSM3005_PROPERTIES_ESTIMATION ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
        }

        if (state?.usageLevel == POLICY_LVL_GENERAL) {
            state.allRegions = CUSTOMER_LEVEL_ALL
            state.allCities = CUSTOMER_LEVEL_ALL
        } else {
            state.allRegions = CUSTOMER_LEVEL_NONE
            state.allCities = CUSTOMER_LEVEL_NONE
        }

        state.allCustCats = (state?.usageLevel == POLICY_LVL_GENERAL && state.function?.allCustCat) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
        state.allCustomers = (state?.usageLevel == POLICY_LVL_GENERAL && state.function?.allCustomers) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
        state.allEstimationForms = (state?.usageLevel == POLICY_LVL_GENERAL && state.function?.allEstimationForms) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE

        state.allEstimationFormSec = ((state.function?.estimationFormSec && state?.usageLevel == POLICY_LVL_GENERAL) || state?.usageLevel != POLICY_LVL_GENERAL) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
        state.allEstimationStatuses = ((state.ffrId == RSM3005_PROPERTIES_ESTIMATION && state?.usageLevel == POLICY_LVL_GENERAL) || state?.usageLevel != POLICY_LVL_GENERAL) ? CUSTOMER_LEVEL_ALL : CUSTOMER_LEVEL_NONE
    } else {
        state.allBranches = CUSTOMER_LEVEL_ALL
        state.allDivisoions = CUSTOMER_LEVEL_ALL
        state.allPropTypes = CUSTOMER_LEVEL_ALL
        state.allPropPartyCats = CUSTOMER_LEVEL_ALL
        state.allPropParties = CUSTOMER_LEVEL_ALL
        state.allRegions = CUSTOMER_LEVEL_ALL
        state.allCities = CUSTOMER_LEVEL_ALL
        state.allCustCats = CUSTOMER_LEVEL_ALL
        state.allCustomers = CUSTOMER_LEVEL_ALL
        state.allEstimationForms = CUSTOMER_LEVEL_ALL
        state.allEstimationFormSec = CUSTOMER_LEVEL_ALL
        state.allEstimationStatuses = CUSTOMER_LEVEL_ALL
    }

    state.branches = [];
    state.divisions = [];
    state.propertiesCategories = [];
    state.propertiesTypes = [];
    state.regions = [];
    state.cities = [];
    state.partiesCategories = [];
    state.propertiesParties = [];
    state.customerCategories = [];
    state.customers = [];
    state.estimationStatuses = [];
    state.securityForms = [];
    state.estimationForms = [];
}

const _prepareFunction = (functionData) => {
    if (typeof functionData?.restrictionList === "string") {
        let restrictionList = [];
        if (isNotEmpty(functionData?.restrictionList)) {
            restrictionList = functionData?.restrictionList.split(',');
        }
        functionData.restrictionList = [...restrictionList];
    }
}

const PropertiesPolicyReducer = (state, action) => {
    switch (action.type) {
        case ACTION_SET_PROPERTIES_POLICY: {
            return { ...action.value };
        }

        case ACTION_RETRIEVE_FUNCTION: {
            state.function = action.value
        }

        case ACTION_SET_PROPERTIES_POLICY_VALUE: {
            state[action.name] = action.value;

            if (action.name == "ipeId") {
                state.ipeId = action.value.value;
                state.ffrId = action.value.ffrId;
                state.function = { ...action.value };
                _prepareFunction(state.function)
                _handleFunctionNUsageLevelChange(state, action.user);
            } else if (action.name === "function") {
                _prepareFunction(state.function)
            } else if (action.name === "usageLevel") {
                state.usageLevel = action.value.value;
                state.usageLevelData = { ...action.value };
                _handleFunctionNUsageLevelChange(state, action.user);
            } else if (action.name === "allBranches") {
                state.branches = [];
            } else if (action.name === "allDivisoions") {
                state.divisions = [];
            } else if (action.name === "allPropCats") {
                state.propertiesCategories = [];
            } else if (action.name === "allPropTypes") {
                state.propertiesTypes = [];
            } else if (action.name === "allRegions") {
                state.regions = [];
            } else if (action.name === "allCities") {
                state.cities = [];
            } else if (action.name === "allPropPartyCats") {
                state.partiesCategories = [];
            } else if (action.name === "allPropParties") {
                state.propertiesParties = [];
            } else if (action.name === "allCustCats") {
                state.customerCategories = [];
            } else if (action.name === "allCustomers") {
                state.customers = [];
            } else if (action.name === "allEstimationStatuses") {
                state.estimationStatuses = [];
            } else if (action.name === "allEstimationFormSec") {
                state.securityForms = [];
            } else if (action.name === "allEstimationForms") {
                state.estimationForms = [];
            }

            return { ...state };
        }

        case ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES: {
            const lines = [...(state[action.name] || []), ...action.value];

            return { ...state, [action.name]: lines };
        }

        case ACTION_DELETE_ALL_LINES_PROPERTIES_POLICY_DETAIL_LINES: {
            state[action.name] = [];
            return { ...state };
        }

        case ACTION_DELETE_LINE_PROPERTIES_POLICY_DETAIL_LINES: {
            let lines = [...state[action.name]];

            if (!lines) {
                lines = [];
            }

            deleteItemByIndex(lines, action.index);

            return { ...state, [action.name]: lines };
        }

        case ACTION_SET_LINE_VALUE_PROPERTIES_POLICY_DETAIL_LINES: {
            state[action.list][action.index][action.name] = action.value;

            if (action.list == "paymentsClosingPriorities") {
                if (action.name == "source") {
                    state[action.list][action.index].cdaId = null;
                }
            }

            return { ...state };
        }

        case ACTION_SET_RESTICTION_POLICY_VALUE: {
            state.restrictionPolicy[action.name] = action.value

            if (action.name === "optn117207") {
                state.restrictionPolicy.mobileNumDigits = action.value ? 10 : null;
            } else if (action.name === "optn117215") {
                state.restrictionPolicy.optn117216 = null;
            }

            return { ...state };
        }

        case ACTION_UPDATE_PROPERTIES_POLICY: {
            return { ...action.value, function: state.function };
        }

        default:
            return state;
    }
}

export default PropertiesPolicyReducer;