import { DateFromToInput } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import FromToDecimalInput from "@ipgd-gauge/from-to-decimal-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../components/advance-filter";
import { getCitiesLov, getCommonCustomers, getCommonPartiesLov, getCommonPropertyTypes, getRegionsLov, getRsmEstimator, getTownsList } from "../../util/apis";
import { ESTIMATOR_PARTY, INSPECTOR_PARTY, POLICY_LVL_SELF_SERVICE, USERS_TYPES_ESTIMATER, USERS_TYPES_INSPECTOR } from "../../util/constants";
import { RSM3234_PROPERTIES_MAP_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";

const MapsFilter = (props, ref) => {
    const { searchAction } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const user = getUser();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getDefaultFilters = () => {
        const defaultFilters = { inspectors: [], estimators: [] }
        if (user?.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE && !user?.estimationPolicy.osmShowAllInMap) {
            if (user?.inspectorId) {
                defaultFilters.inspectors = [user?.inspectorId];
            }
            if (user?.estimatorId) {
                defaultFilters.estimators = [user?.estimatorId];
            }
        }
        return defaultFilters
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }))

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="mapsFilter"
            searchAction={searchAction}
            filterData={filterData}
            setFilterData={setFilterData}
            getDefaultFilter={getDefaultFilters}
            numOfRows={4}
            formId={RSM3234_PROPERTIES_MAP_FORM_ID}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        {/* <div className="w50">
                            <label className='form-label'>{t('estimationMap:from_date')}</label>
                            <FrmTimeDatePickr
                                id="from-filter"
                                value={filterData.fromDateFilter}
                                onChange={value => _handleDataChanged("fromDateFilter", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:to_date')}</label>
                            <FrmTimeDatePickr
                                id="to-filter"
                                value={filterData.toDateFilter}
                                onChange={value => _handleDataChanged("toDateFilter", value)}
                            />
                        </div> */}
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:from_to_date')}</label>
                            <DateFromToInput
                                id="date-from-to"
                                fromValue={filterData.fromDateFilter}
                                toValue={filterData.toDateFilter}
                                fromOnChange={value => _handleDataChanged("fromDateFilter", value)}
                                toOnChange={value => _handleDataChanged("toDateFilter", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:customer')}</label>
                            <FrmSelectMenu
                                id="customer-filter"
                                value={filterData.customers}
                                onChange={value => _handleDataChanged("customers", value)}
                                defualtValueId={filterData.customers}
                                defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, [], true)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isMulti={true}
                                isAsync={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:property_type')}</label>
                            <FrmSelectMenu
                                id="property-type-filter"
                                value={filterData.propertyTypes}
                                onChange={value => _handleDataChanged("propertyTypes", value)}
                                api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.propertyTypes)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:inspector')}</label>
                            <FrmSelectMenu
                                id="inspector-filter"
                                value={filterData.inspectors}
                                onChange={value => _handleDataChanged("inspectors", value)}
                                api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.inspectors, null, null, 1)}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                isMulti={true}
                                isDisabled={user?.inspectorId && user?.type == USERS_TYPES_INSPECTOR && user?.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:estimator')}</label>
                            <FrmSelectMenu
                                id="estimator-filter"
                                value={filterData.estimators}
                                onChange={value => _handleDataChanged("estimators", value)}
                                api={() => getRsmEstimator()}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                isMulti={true}
                                isDisabled={user?.estimatorId && user?.type == USERS_TYPES_ESTIMATER && user?.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:regions')}</label>
                            <FrmSelectMenu
                                id="regions-filter"
                                value={filterData.regions}
                                onChange={value => _handleDataChanged("regions", value)}
                                api={() => getRegionsLov(filterData.regions, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:cities')}</label>
                            <FrmSelectMenu
                                id="city-filter"
                                value={filterData.cities}
                                onChange={value => _handleDataChanged("cities", value)}
                                defualtValueId={filterData.cities}
                                defualtValueApi={() => getCitiesLov(filterData.cities)}
                                api={(searchValue) => getCitiesLov(null, null, null, null, searchValue, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                isAsync={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:towns')}</label>
                            <FrmSelectMenu
                                id="town-filter"
                                value={filterData.towns}
                                onChange={value => _handleDataChanged("towns", value)}
                                defualtValueId={filterData.towns}
                                defualtValueApi={() => getTownsList(null, filterData.towns, null, null, true, null, true)}
                                api={(searchValue) => getTownsList(searchValue, null, null, null, true, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                isAsync={true}
                                menuPlacement="top"
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('advance_filter')}</label>
                </div>
                <div className="container">
                    <div className="row advanceFilterContainer rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:price_from_to')}</label>
                            <FromToDecimalInput
                                id="price-from-to"
                                fromValue={filterData.priceFrom || ''}
                                toValue={filterData.priceTo}
                                fromOnBlur={value => _handleDataChanged("priceFrom", value)}
                                toOnBlur={value => _handleDataChanged("priceTo", value)}
                                decimalScale={2}
                                allowDecimals={true}
                                nullable
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:land_area_from_to')}</label>
                            <FromToDecimalInput
                                id="land-area-from-to"
                                fromValue={filterData.landAreaFromFilter}
                                toValue={filterData.landAreaToFilter}
                                fromOnBlur={value => _handleDataChanged("landAreaFromFilter", value)}
                                toOnBlur={value => _handleDataChanged("landAreaToFilter", value)}
                                allowDecimals={false}
                                nullable
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:building_area_from_to')}</label>
                            <FromToDecimalInput
                                id="building-area-from-to"
                                fromValue={filterData.buildAreaFromFilter}
                                toValue={filterData.buildAreaToFilter}
                                fromOnBlur={value => _handleDataChanged("buildAreaFromFilter", value)}
                                toOnBlur={value => _handleDataChanged("buildAreaToFilter", value)}
                                allowDecimals={false}
                                nullable
                            />
                        </div>


                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:plan_name')}</label>
                            <FrmInput
                                id="plan-name-maps"
                                value={filterData.planName}
                                onBlur={value => _handleDataChanged("planName", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:plan_num')}</label>
                            <FrmInput
                                id="plan-no-maps"
                                value={filterData.planNumber}
                                onBlur={value => _handleDataChanged("planNumber", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationMap:parcel_no')}</label>
                            <FrmInput
                                id="parcel-no-maps"
                                value={filterData.parcelNo}
                                onBlur={value => _handleDataChanged("parcelNo", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    );
}

export default forwardRef(MapsFilter);