import { DeleteBtn } from '@ipgd-gauge/table';
import { deleteItemByIndex } from '@ipgd-gauge/utils';
import { useRef } from 'react';
import { useTranslation } from "react-i18next";
import FormTitle from '../../components/form-title';
import MultiInsertDialog from '../../components/multi-insert-dialog';
import Table from "../../components/table";
import AddHeaderBtnIcon from '../../icons/AddHeaderBtn';
import DeleteTowIcon from '../../icons/Delete2';
import { getCommonPartiesLov } from '../../util/apis';
import { STATUS_ACTIVE } from '../../util/constants';
import { RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID } from '../../util/constants/forms';
import { confirmationDialog, isEnglish } from "../../util/util";
import style from './style.module.scss';

const PartiesListRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div> <div><label>{data?.sequence}</label></div></div>
            <div> <div><label>{data?.code}</label></div></div>
            <div> <div><label>{isEnglish() ? data?.nameLo : data?.nameFo}</label></div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};
const PartiesListTable = (props) => {
    const { user, data, setData } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '10%' },
        { title: t('estimationFees:code'), width: '20%' },
        { title: t('estimationFees:name'), width: '55%' },
        { title: "", width: '15%' },
    ];

    const _handleDeleteLine = (index) => {
        if (!data.parties) {
            data.parties = [];
        }

        deleteItemByIndex(data.parties, index);
        setData({ ...data });
    }

    return (
        <Table
            id="party-list-table"
            formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID}
            columns={columns}
            Row={PartiesListRow}
            tableStyle={style.partiesListTable}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            rowHeight={50}
            list={data.parties || []}
            user={user}
        />
    );
}

const Tab3 = (props) => {
    const { tab, user, data, setData } = props;

    const { t } = useTranslation();
    const partyListRef = useRef();

    const _insertParties = (selectedData) => {
        data.parties = [...data.parties, ...selectedData];
        setData({ ...data });

        partyListRef.current.close();
    }

    const _deleteAll = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.parties = [];
            setData({ ...data });
        });
    }

    return (
        <>
            <div className={tab == 3 ? style.activeEstimation : style.inActiveEstimation}>
                <div className={style.header}>
                    <div className={style.title}>
                        <FormTitle formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID} />
                    </div>
                    <div className="tableHeaderActions">
                        <button
                            onClick={() => partyListRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('estimationFees:insert')}
                            </label>
                        </button>
                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAll}
                            disabled={data.parties.length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('estimationFees:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
                <PartiesListTable user={user} data={data} setData={setData} />
            </div>

            <MultiInsertDialog
                id='party-insert-dialog'
                ref={partyListRef}
                formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID}
                title={t('estimationFees:insert_party')}
                okBtnAction={_insertParties}
                api={(searchValue) => getCommonPartiesLov(null, null, null, null, null, STATUS_ACTIVE, searchValue, 1)}
                searchPlaceholder={t('code') + " , " + t('name')}
                columns={[
                    { label: '', width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('code'), value: 'code', width: '30%' },
                    { label: t('name'), valueLo: 'nameLo', valueFo: 'nameFo', width: '60%' }
                ]}
                isSmallSize
            />
        </>
    )
}

export default Tab3;