import React from "react";

function BackToTopArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.658"
      height="42.271"
      viewBox="0 0 33.658 42.271"
    >
      <g
        fill="#fafafa"
        data-name="Group 173229"
        transform="translate(-17.701 -15.611)"
      >
        <path
          d="M83.008 70.332a2.111 2.111 0 01-1.435-3.659l14.719-13.622a2.112 2.112 0 012.867 0l14.719 13.622a2.11 2.11 0 01-2.867 3.1l-13.284-12.3L84.44 69.769a2.1 2.1 0 01-1.432.561z"
          data-name="Path 38686"
          transform="translate(-63.196 -36.879)"
        ></path>
        <path
          d="M132.024 123.3a2.093 2.093 0 01-2.3-1.784V92.954a2.38 2.38 0 014.608 0v28.563a2.093 2.093 0 01-2.308 1.783z"
          data-name="Path 38687"
          transform="translate(-97.494 -65.418)"
        ></path>
      </g>
    </svg>
  );
}

export default BackToTopArrowIcon;