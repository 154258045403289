import React from "react";

function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.675"
      height="12.566"
      viewBox="0 0 14.675 12.566"
    >
      <g
        fill="#668fff"
        data-name="Group 178404"
        transform="translate(-55.46 -279.1)"
      >
        <path
          d="M2.5-.5a3 3 0 11-3 3 3.005 3.005 0 013-3zm0 5a2 2 0 10-2-2 2 2 0 002 2z"
          transform="translate(60.296 282.884)"
        ></path>
        <path
          d="M6.838 12.066A8.209 8.209 0 01.05 7.865a4.219 4.219 0 010-4.165A8.209 8.209 0 016.838-.5a8.209 8.209 0 016.788 4.2 4.219 4.219 0 010 4.164 8.209 8.209 0 01-6.788 4.202zM6.838.5A7.237 7.237 0 00.894 4.238a3.225 3.225 0 000 3.09 7.237 7.237 0 005.945 3.738 7.237 7.237 0 005.944-3.738 3.225 3.225 0 000-3.09A7.237 7.237 0 006.838.5z"
          data-name="Vector"
          transform="translate(55.959 279.6)"
        ></path>
      </g>
    </svg>
  );
}

export default EyeIcon;
