import React from "react";

function PrevDownLoadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.53"
      height="21.001"
      viewBox="0 0 20.53 21.001"
    >
      <path
        fill="#292d32"
        d="M7.01 21c-2.5 0-4.195-.532-5.337-1.674S0 16.49 0 13.991v-.13a7.634 7.634 0 011.332-4.953A5.926 5.926 0 015.67 6.914a.75.75 0 11.14 1.493A4.485 4.485 0 002.5 9.845a6.376 6.376 0 00-1 4.015v.13c0 2.075.391 3.433 1.234 4.276S4.935 19.5 7.01 19.5h6.51c2.075 0 3.434-.392 4.276-1.234s1.234-2.2 1.234-4.276v-.13a6.345 6.345 0 00-1.017-4.035A4.551 4.551 0 0014.646 8.4a.75.75 0 01.128-1.5c3.981.343 5.755 2.489 5.755 6.957v.13c0 2.5-.532 4.195-1.673 5.336S16.019 21 13.52 21zm2.73-5.72l-3.35-3.35a.75.75 0 011.06-1.06l2.07 2.07V.75a.75.75 0 011.5 0v12.19l2.07-2.07a.75.75 0 011.061 1.06L10.8 15.281a.75.75 0 01-1.06 0z"
        data-name="Union 389"
      ></path>
    </svg>
  );
}

export default PrevDownLoadIcon;