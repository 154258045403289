import Checkbox from "@ipgd-gauge/checkbox";
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import SidePanel from "../../../components/side-panel";
import EstimationTransactionsStatusesIcon from "../../../icons/EstimationTransactionsStatuses";
import { getDomainValues, getEstimationTransactionsStatuses, saveEstimationTransactionsStatuses } from "../../../util/apis";
import { ESTIMATION_STATUS_COLOURS, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";

const dialogId = "estimation-transactions-statuses-dialog";

const EstimationTransactionsStatusesDialog = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const updateRecordRef = useRef(false);

    const defaultData = { isActive: STATUS_ACTIVE }

    const [data, setData] = useState({});

    const user = getUser();

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }

        showWaiting(`${dialogId}-btn`, true)
        saveEstimationTransactionsStatuses(data)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t)
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-btn`, true));
    }

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getEstimationTransactionsStatuses(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setData({ ...defaultData })
        }
    }

    const _postClose = () => {
        setData({})
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const close = () => {
        dialogRef.current.close();
    }

    const open = (id) => {
        dialogRef.current.open();
        _populateRecordData(id);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            formId={RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID}
            title={t('estimationTransactionsStatuses:estimation_transactions_statuses')}
            icon={<EstimationTransactionsStatusesIcon />}
            footerAction={_saveAction}
            withFooter={isAllowedAction(user, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, SAVE_ACTION)}
            footerLbl={t("save")}
            postClose={_postClose}
            preClose={_preCloseAction}
            nextPreviousAction={(id) => _populateRecordData(id)}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <CodeInput
                            id={`${dialogId}-code`}
                            value={data?.code}
                            onBlur={(value) => _handleDataChanged("code", value)}
                            column="OAE_CODE"
                            formId={RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-status-lbl`} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            sidePanelMode
                            options={[
                                {
                                    id: 'active',
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: 'inactive',
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]} />
                    </div>

                    <div className="w100">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChanged("descLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <Checkbox
                            id={`${dialogId}-include-estimation-fees-commission-calculations`}
                            label={t('estimationTransactionsStatuses:include_estimation_fees_commission_calculations')}
                            isChecked={data?.estFeesComm}
                            setIsChecked={value => _handleDataChanged("estFeesComm", value)}
                        />
                    </div>
                    <div className="w100">
                        <Checkbox
                            id={`${dialogId}-include-customer-invoice`}
                            label={t('estimationTransactionsStatuses:include_customer_invoice')}
                            isChecked={data?.customerInvoice}
                            setIsChecked={value => _handleDataChanged("customerInvoice", value)}
                        />
                    </div>
                    <div className="w100">
                        <Checkbox
                            id={`${dialogId}-show-map`}
                            label={t('estimationTransactionsStatuses:show_map')}
                            isChecked={data?.showInMaps}
                            setIsChecked={value => _handleDataChanged("showInMaps", value)}
                        />
                    </div>
                    <div className="w100">
                        <Checkbox
                            id={`${dialogId}-show-approved-signature`}
                            label={t('estimationTransactionsStatuses:show_approved_signature')}
                            isChecked={data?.showApprovedSignature}
                            setIsChecked={value => _handleDataChanged("showApprovedSignature", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-color-lbl`} className='form-label mandatory'>{t('estimationTransactionsStatuses:color')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-color`}
                            value={data.color}
                            api={() => getDomainValues(ESTIMATION_STATUS_COLOURS)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("color", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-remarks`}
                            value={data?.rem}
                            onBlur={value => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(EstimationTransactionsStatusesDialog);