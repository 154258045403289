import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getDomainValues, getPartyFilter } from "../../../util/apis";
import { PRICE_LIST_PARTIES_DOMAIN, STATUS_DOMAIN } from "../../../util/constants";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";

const PriceAgreementFilter = (props, ref) => {
    const { tableRef } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="price-agreement-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
            hideMoreOptions
            numOfRows={2}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('priceAgreement:party_level')}</label>
                            <FrmSelectMenu
                                id="price-agreement-filter-party"
                                isMulti={true}
                                value={filterData.party}
                                api={() => getDomainValues(PRICE_LIST_PARTIES_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("party", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('priceAgreement:party')}</label>
                            <FrmSelectMenu
                                id="price-agreement-filter-target"
                                isMulti={true}
                                value={filterData.target}
                                api={() => getPartyFilter()}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("target", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('priceAgreement:status')}</label>
                            <FrmSelectMenu
                                id="price-agreement-filter-status"
                                value={filterData.status}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value.value)}
                                portalTarget={document.body}
                                isClearable
                            />
                        </div>
                    </div>
                </div >
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(PriceAgreementFilter);