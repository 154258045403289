import React from "react";

function ViewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.948"
      height="11.95"
      viewBox="0 0 11.948 11.95"
    >
      <g
        fill="#668fff"
        data-name="Group 178403"
        transform="translate(-168.616 -279.555)"
      >
        <path
          d="M3.752.834H.417A.42.42 0 010 .417.42.42 0 01.417 0h3.335a.42.42 0 01.417.417.42.42 0 01-.417.417z"
          transform="translate(175.286 281.223)"
        ></path>
        <path
          d="M2.084.834H.417A.42.42 0 010 .417.42.42 0 01.417 0h1.667A.42.42 0 012.5.417a.42.42 0 01-.416.417z"
          data-name="Vector"
          transform="translate(175.286 282.89)"
        ></path>
        <path
          d="M5.7 11.394A5.7 5.7 0 015.7 0a.42.42 0 01.417.417.42.42 0 01-.417.417A4.863 4.863 0 1010.56 5.7a.417.417 0 11.834 0A5.7 5.7 0 015.7 11.394z"
          data-name="Vector"
          transform="translate(168.616 279.555)"
        ></path>
        <path
          d="M1.527 1.944a.412.412 0 01-.295-.122L.121.71A.417.417 0 01.71.121l1.112 1.111a.419.419 0 010 .589.412.412 0 01-.295.123z"
          data-name="Vector"
          transform="translate(178.622 289.561)"
        ></path>
      </g>
    </svg>
  );
}

export default ViewIcon;