import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues } from "../../../util/apis";
import { STATUS_DOMAIN } from "../../../util/constants";
import { RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID } from "../../../util/constants/forms";

const EstimationQuotationsTypesFilter = (props, ref) => {
    const { tableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            id="estimation-quotations-types-filter"
            formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={1}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotationsTypes:status')}</label>
                            <StatusSelectMenu
                                id="estimation-quotations-types-filter-status"
                                
                                value={filterData.status}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value.value)}
                                portalTarget={document.body}
                                isClearable
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(EstimationQuotationsTypesFilter);