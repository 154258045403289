import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteImportantLinks, getImportantLinksList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3266_IMPORTATNT_LINKS } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {
    const { t } = useTranslation("");
    const { data, rowActions } = props;
    const { dialogRef } = rowActions;
    console.log('------------------', data)

    return (
        <div className={`${style.importantLinksRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={isEnglish() ? data.descLo : data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('importantLinks:description')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>

            <div className="cardRowMode">
                <label className="nameLblMobile">{t('importantLinks:link')}</label>
                <label className={"linkLabel"}>
                    <a href={data.link ? (data.link.includes('https://') || data.link.includes('http://')) ? data.link : `http://${data.link}` : ''} target="_blank" title={data.link}>
                        {data.link}
                    </a>
                </label>
            </div>

            <div title={data.remarks} className="cardRowMode">
                <label className="nameLblMobile">{t('importantLinks:remark')}</label>
                <label>{data.remarks}</label>
            </div>

            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('importantLinks:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(getUser(), RSM3266_IMPORTATNT_LINKS, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef.current.open(data.id)} />
                    }
                    {isAllowedAction(getUser(), RSM3266_IMPORTATNT_LINKS, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => rowActions?.deleteImportantLinks(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const ImportantLinksTable = (props) => {
    const { tableRef, searchTextRef, filterRef, dialogRef } = props;

    const { t } = useTranslation("");

    const [list, setList] = useState();

    const user = getUser();
    console.log("🚀 ~ ImportantLinksTable ~ user:", user)

    const _deleteImportantLinks = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteImportantLinks(id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    tableRef?.current?.refresh();
                })
                .catch((error) => {
                    handleError(error, "delete_fields", t);
                })
        });
    }

    const _fetchData = (page, size) => {
        const filterData = Object.assign(filterRef.current?.getFilterData(), {});
        filterData.searchValue = searchTextRef.current.getSearchText();
        getImportantLinksList(page, size, filterData)
            .then((response) => {
                setList(response.data);
            })
            .catch((error) => {
                handleError(error, null, t)
            })
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: t('importantLinks:description'), width: "20%" },
        { title: t('importantLinks:link'), width: "30%" },
        { title: t('importantLinks:remark'), width: "20%" },
        { title: t('importantLinks:status'), width: "10%" },
        { title: "", width: "15%" },
    ];


    return (
        <div className={style.importantLinksContainer}>
            <Slisting
                id="important-links-table"
                ref={tableRef}
                formId={RSM3266_IMPORTATNT_LINKS}
                columns={columns}
                Row={Row}
                withFilter={true}
                tableStyle={style.importantLinksTable}
                pagingData={list}
                getData={_fetchData}
                rowActions={{ dialogRef: dialogRef, deleteImportantLinks: _deleteImportantLinks }}
                rowHeight={80}
                showWating={(data) => data == null}
                showNoDataFound={(data) => data && data.length == 0}
                newAction={() => dialogRef?.current?.open()}
                deleteAction={deleteImportantLinks}
                viewAction={(id) => dialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="90px"
            />
        </div>
    );
};

export default ImportantLinksTable;