import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import { RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID } from "../../util/constants/forms";
import WorkflowProcedureMessagesDialog from "./dialogs";
import WorkflowProcedureMessagesTable from "./listing";

const WorkflowProcedureMessages = () => {

    const { t } = useTranslation();
    const searchTextRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('form_code') + " , " + t('form_name')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID}
                    />
                </div>
            </div>

            <WorkflowProcedureMessagesTable
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                dialogRef={dialogRef}
            />

            <WorkflowProcedureMessagesDialog ref={dialogRef} tableRef={tableRef} />
        </>
    )
}

export default WorkflowProcedureMessages;