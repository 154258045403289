import FrmInput from "@ipgd-gauge/frm-input";
import { useTranslation } from "react-i18next";
import Map from "../../../components/map";
import MapPinIcon from "../../../icons/MapPin";
import style from './style.module.scss';

const dialogId = "estimations-history-dialog";

const Tab2 = (props) => {
    const { tab, state, handleDataChanged, mapRef } = props;

    const { t } = useTranslation('estimationsHistory');

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${dialogId}-longitude-lbl`} className='form-label mandatory'>{t('longitude')}</label>
                        <FrmInput
                            id={`${dialogId}-longitude`}
                            type="number"
                            value={state?.longitude}
                            onBlur={value => handleDataChanged("longitude", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-latitude-lbl`} className='form-label mandatory'>{t('latitude')}</label>
                        <FrmInput
                            id={`${dialogId}-latitude`}
                            type="number"
                            value={state?.latitude}
                            onBlur={value => handleDataChanged("latitude", value)}
                        />
                    </div>
                    <div className="w100">
                        <div>
                            <Map
                                key={state.id}
                                ref={mapRef}
                                value={state?.longitude && state.latitude ? `${state?.latitude},${state?.longitude}` : null}
                                withPin
                                withSearch
                                mapStyle={style.map}
                                MapPinCard={<MapPinIcon />}
                                handleChange={(e) => handleDataChanged("coordinates", e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab2;