import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getCitiesLov, getDomainValues } from "../../../util/apis";
import { STATUS_DOMAIN } from "../../../util/constants";
import { FND2621_TOWNS_DEFINITION_FORM_ID } from "../../../util/constants/forms";

const TownsFilter = (props, ref) => {
    const { townsTableRef } = props;

    const { t } = useTranslation();
    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        let data = Object.assign(filterData, {});
        return data;
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="townsFilter"
            formId={FND2621_TOWNS_DEFINITION_FORM_ID}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => townsTableRef?.current?.refresh()}
            numOfRows={1}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('towns:city')}</label>
                            <FrmSelectMenu
                                id="city-filter"
                                value={filterData.cities}
                                onChange={value => _handleDataChanged("cities", value)}
                                defualtValueId={filterData.cities}
                                defualtValueApi={() => getCitiesLov(filterData.cities)}
                                api={(searchValue) => getCitiesLov(null, null, null, null, searchValue)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                isAsync={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('towns:status')}</label>
                            <StatusSelectMenu
                                id="status-towns-filter"
                                value={filterData.status}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isClearable={true}
                                onChange={e => _handleDataChanged("status", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(TownsFilter);