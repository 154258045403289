import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { FND0042_USERS_DEFINITION_FORM_ID } from "../../util/constants/forms";
import UsersDefinitionTable from "./listing";
import UsersDefinitionFilter from "./listing/Filters";

const UsersDefinition = () => {
    const { t } = useTranslation("usersDefinition");

    const searchTextRef = useRef();
    const filtersRef = useRef();
    const tableRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={FND0042_USERS_DEFINITION_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name') + " , " + t('user_name') + " , " + t('email') + " , " + t('mobile_num')}
                        search={() => tableRef.current.refresh()}
                        formId={FND0042_USERS_DEFINITION_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filtersRef?.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <UsersDefinitionTable
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filtersRef={filtersRef}
            />

            <UsersDefinitionFilter ref={filtersRef} tableRef={tableRef} />
        </>
    );
}

export default UsersDefinition;