import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import UpdateValuationFieldsDialog from "./dialogs";
import UpdateValuationFieldsTable from "./listing";
import UpdateValuationFieldsFilter from "./listing/Filters";

const UpdateValuationFields = () => {
    const { t } = useTranslation("updateValuationFields");

    const searchTextRef = useRef();
    const tableRef = useRef();
    const filterRef = useRef();
    const dialogRef = useRef();
    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('system_name_en') + ' , ' + t('system_name_ar') + ' , ' + t('user_name_en') + ' , ' + t('user_name_ar')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef?.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <UpdateValuationFieldsTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
                dialogRef={dialogRef}
            />

            <UpdateValuationFieldsFilter ref={filterRef} tableRef={tableRef} />

            <UpdateValuationFieldsDialog user={user} ref={dialogRef} tableRef={tableRef} />
        </>
    )
}

export default UpdateValuationFields;