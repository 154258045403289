import { isEnglish } from "@ipgd-gauge/utils";
import style from "./style.module.scss"
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";

const AccountOption = (props) => {
    const { data } = props;
    return (
        <div className={style.accountOptionContainer}>
            <div>
                <label>{data.code}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>
        </div>
    )
}

const AccountLabel = (props) => {
    const { data } = props;
    return (
        <div className={style.accountLabelContainer}>
            <label>{data.code}</label>
            <label>{isEnglish() ? data.descLo : data.descFo}</label>
        </div>
    )
}

const AccountSelectMenu = (props) => {
    const { id, key, value, api, options, descLo, descFo, onChange, isClearable, isMulti, isDisabled, onDataRetrieved, menuWidth, withCustomLabel } = props;
    return (
        <FrmSelectMenu
            id={id}
            key={key}
            value={value}
            api={api}
            options={options}
            OptionComponent={({ data }) => <AccountOption data={data} />}
            descLo={descLo}
            onDataRetrieved={onDataRetrieved}
            descFo={descFo}
            onChange={onChange}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
            portalTarget={document.body}
            menuWidth={menuWidth}
            LabelComponent={withCustomLabel ? AccountLabel : null}
        />
    );
}

export default AccountSelectMenu;