import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import QuickSearch from "../../components/quick-search";
import Waiting from "../../components/waiting";
import ClockIcon from "../../icons/Clock";
import FavMessagesIcon from "../../icons/FavMessages";
import OpeningFavIcon from "../../icons/OpeningFav";
import SettingsIcon from "../../icons/Settings";
import { getFavoriteForms, getLastVisitedForms } from "../../util/apis";
import { isEnglish } from "../../util/util";
import Container from "./Container";
import style from './style.module.scss';

const SORT_BY_ASC = 1;
const SORT_BY_DSC = 2;

const MyFavorite = () => {
    const { t } = useTranslation();
    const mergedList = useRef();
    const searchRef = useRef();
    const filters = useRef({ moduleId: null })

    const favForms = useSelector(state => state.persistedReducer.userReducer.user.favoriteForms || []);

    const [data, setData] = useState({
        favorites: null,
        cards: null,
    })
    const [filteredData, setFilteredData] = useState({
        favorites: null,
        cards: null,
    })
    const [moduleOptions, setModuleOptions] = useState([])
    const [settings, setSettings] = useState({ sortBy: SORT_BY_ASC })
    const [mobileMode, setMobileMode] = useState(1);
    const [mobileAction, setMobileAction] = useState(false);

    const _filterModule = (cards, favorites, value) => {
        let latest = cards;
        let fav = favorites;
        if (value) {
            latest = cards.filter(card => card.moduleId == value);
            fav = favorites.filter(card => card.moduleId == value);
        }
        return { fav: [...fav], latest: [...latest] };
    }

    const searchAction = (list) => {
        const newList = Object.assign([], list)
        const spltIndex = newList.findIndex(item => !item.isFav)
        let latest = spltIndex == -1 ? [] : newList.slice(spltIndex)
        let fav = spltIndex == -1 ? newList : newList.slice(0, spltIndex)
        if (filters.current.moduleId) {
            let result = _filterModule(latest, fav, filters.current.moduleId);
            latest = result.latest;
            fav = result.fav;
        }
        setFilteredData({ favorites: fav, cards: latest })
    }

    const filterAction = (name, value) => {
        if (name == "module") {
            filters.current.moduleId = value;
            searchAction([...data.favorites, ...data.cards])
        }
    }

    const _fetchData = () => {
        setFilteredData({ cards: null, favorites: null });
        const filters = {
            searchText: searchRef.current.getSearchText(),
        }
        Promise.all([getFavoriteForms({ ...filters, isEn: isEnglish() }), getLastVisitedForms(filters)])
            .then(res => {
                res[0].data.map(item => item.isFav = true);
                data.favorites = res[0].data;
                data.cards = res[1].data;
                if (!mergedList.current) {
                    mergedList.current = [];
                }
                mergedList.current = data.favorites.concat(data.cards);
                setData({ ...data });
                setFilteredData({ ...data });
                const moduleOptions = [];
                for (let i = 0; i < mergedList?.current.length; i++) {
                    let currentItem = mergedList?.current[i];
                    let moduleIndex = moduleOptions.findIndex(item => item.id == currentItem.moduleId);
                    if (moduleIndex < 0) {
                        if (currentItem.moduleId) {
                            moduleOptions.push({
                                id: currentItem.moduleId,
                                descLo: currentItem.moduleDescLo,
                                descFo: currentItem.moduleDescFo
                            })
                        }
                    }
                }
                setModuleOptions(moduleOptions)
            })
    }

    useEffect(() => {
        _fetchData();
    }, [])

    const sortAction = (value) => {
        settings.sortBy = value
        setSettings({ ...settings })
        filteredData.cards.sort((a, b) => isEnglish() ? a.formDescLo.localeCompare(b.formDescLo, "en", { sensitivity: "base" }) : a.formDescFo.localeCompare(b.formDescFo, "ar", { sensitivity: "base", ignorePunctuation: true }));
        filteredData.favorites.sort((a, b) => isEnglish() ? a.formDescLo.localeCompare(b.formDescLo, "en", { sensitivity: "base" }) : a.formDescFo.localeCompare(b.formDescFo, "ar", { sensitivity: "base", ignorePunctuation: true }));
        if (value == SORT_BY_DSC) {
            filteredData.cards.reverse();
            filteredData.favorites.reverse();
        }
        setFilteredData({ ...filteredData })
    }

    const _onFavChange = (form, isFav) => {
        let newFavorites = [...filteredData.favorites];
        if (isFav) {
            newFavorites = newFavorites?.filter((favorite) => favorite.id != form.id && favorite.dfrId != form.dfrId);
        } else {
            newFavorites.push(form);
        }
        setFilteredData({ ...filteredData, favorites: [...newFavorites] })
    }

    return (
        <>
            <div className={`headerPagesPanel ${style.headerPagesPanel}`}>
                <div className="headerTitlePanel">
                    <div className="headerIconPanel">
                        <OpeningFavIcon />
                    </div>
                    <div className={`headerLblPanel ${style.title}`}>
                        {t('favorite_list')}
                    </div>
                </div>
                <div className={style.headerContainerSm}>
                    <div>
                        <QuickSearch
                            id="quick-search-2"
                            ref={searchRef}
                            placeholder={t("search")}
                            search={_fetchData}
                        />
                    </div>
                    <button onClick={() => setMobileAction(true)}>
                        <SettingsIcon />
                    </button>
                </div>
                <div className={` ${style.actionHeaderSm} ${mobileAction ? 'active' : ""}`}>
                    <div>
                        <label className='form-label'>{t('by_module')}</label>
                        <FrmSelectMenu
                            id="by-module"
                            portalTarget={document.body}
                            options={moduleOptions}
                            descLo={["descLo"]}
                            descFo={["descFo"]}
                            isClearable
                            onChange={(value) => filterAction("module", value.value)}
                        />
                    </div>
                    <div>
                        <label className='form-label'>{t('sort_by')}</label>
                        <FrmSelectMenu
                            id="sort-by"
                            portalTarget={document.body}
                            options={[
                                { id: SORT_BY_ASC, descLo: "a-z" },
                                { id: SORT_BY_DSC, descLo: "z-a" },
                            ]}
                            descLo={["descLo"]}
                            descFo={["descLo"]}
                            value={settings.sortBy}
                            onChange={(value) => {
                                sortAction(value.value)
                            }}
                        />
                    </div>
                </div>
                <div className={` ${style.bgSm} ${mobileAction ? 'active' : ""}`} onClick={() => setMobileAction(false)}></div>

                <div className={style.headerContainer}>
                    <div>
                        <label className='form-label'>{t('by_module')}</label>
                        <FrmSelectMenu
                            id="by-module"
                            portalTarget={document.body}
                            options={moduleOptions}
                            descLo={["descLo"]}
                            descFo={["descFo"]}
                            isClearable
                            onChange={(value) => filterAction("module", value.value)}
                        />
                    </div>
                    <div>
                        <label className='form-label'>{t('sort_by')}</label>
                        <FrmSelectMenu
                            id="sort-by"
                            portalTarget={document.body}
                            options={[
                                { id: SORT_BY_ASC, descLo: "a-z" },
                                { id: SORT_BY_DSC, descLo: "z-a" },
                            ]}
                            descLo={["descLo"]}
                            descFo={["descLo"]}
                            value={settings.sortBy}
                            onChange={(value) => {
                                sortAction(value.value)
                            }}
                        />
                    </div>
                    <div>
                        <QuickSearch
                            id="quick-search-1"
                            ref={searchRef}
                            placeholder={t("search")}
                            search={_fetchData}
                        />
                    </div>
                </div>
            </div>

            <div className={style.container}>
                <div className={style.tabsSm}>
                    <button className={mobileMode == 1 ? 'active' : ""} onClick={() => setMobileMode(1)}>{t('favorite_list')}</button>
                    <button className={mobileMode == 2 ? 'active' : ""} onClick={() => setMobileMode(2)}>{t('latest')}</button>
                </div>
                <div className={style.contentContainer}>
                    <div className={mobileMode == 1 ? 'active' : ""}>
                        {!filteredData.favorites ?
                            <Waiting id="favorite-list" />
                            :
                            <Container
                                title={t('favorite_list')}
                                icon={<FavMessagesIcon />}
                                forms={filteredData?.favorites}
                                favForms={favForms}
                                onFavChange={_onFavChange}
                            />
                        }
                    </div>
                    <div className={mobileMode == 2 ? 'active' : ""}>
                        {!filteredData.cards ?
                            <Waiting id="latest" />
                            :
                            <Container
                                title={t('latest')}
                                icon={<ClockIcon />}
                                forms={filteredData?.cards}
                                favForms={favForms}
                                onFavChange={_onFavChange}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyFavorite;