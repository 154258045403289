import { DecimalInput as DecimalInputComponent, FrmDecimalInput as FrmDecimalInputComponent } from '@ipgd-gauge/decimal-input';

export const DecimalInput = (props) => {
    const { id, name, className, placeholder, defaultValue, value, onClick,
        onChange, onBlur, onFocus, decimalScale, allowDecimals, prefix, suffix,
        disabled, maxValue, minValue, nullable, disableGroupSeparators, withoutFormat } = props;

    const DEFAULT_SCALE = window?.location?.href?.includes('estimation-trx?key=') ? JSON.parse(sessionStorage.getItem("systemConfig"))?.digitsAfterDot : '2';

    return (
        <DecimalInputComponent
            id={id}
            name={name}
            className={className}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            decimalScale={decimalScale || DEFAULT_SCALE}
            allowDecimals={allowDecimals}
            prefix={prefix}
            suffix={suffix}
            disabled={disabled}
            maxValue={maxValue}
            minValue={minValue}
            nullable={nullable}
            disableGroupSeparators={disableGroupSeparators}
            withoutFormat={withoutFormat}
        />
    )
}

export const FrmDecimalInput = (props) => {
    const { id, name, className, placeholder, defaultValue, value, onClick,
        onChange, onBlur, onFocus, decimalScale, prefix, suffix, disabled,
        maxValue, minValue, allowDecimals, selectWhenFocus, nullable, disableGroupSeparators, onKeyDown, withoutFormat } = props;

    const DEFAULT_SCALE = window?.location?.href?.includes('estimation-trx?key=') ? JSON.parse(sessionStorage.getItem("systemConfig"))?.digitsAfterDot : '2';

    return (
        <FrmDecimalInputComponent
            id={id}
            name={name}
            className={className}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            decimalScale={decimalScale || DEFAULT_SCALE}
            prefix={prefix}
            suffix={suffix}
            disabled={disabled}
            maxValue={maxValue}
            minValue={minValue}
            allowDecimals={allowDecimals}
            selectWhenFocus={selectWhenFocus}
            nullable={nullable}
            disableGroupSeparators={disableGroupSeparators}
            onKeyDown={onKeyDown}
            withoutFormat={withoutFormat}
        />
    )
}