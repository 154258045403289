import React from "react";

function SaveActionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.312"
      height="18.312"
      viewBox="0 0 18.312 18.312"
    >
      <g data-name="Group 180028" transform="translate(-668.688 -136.688)">
        <path
          d="M11.052 10a3.052 3.052 0 103.048 3.052A3.052 3.052 0 0011.052 10zm0 4.578a1.526 1.526 0 111.526-1.526 1.526 1.526 0 01-1.526 1.526z"
          data-name="Path 44876"
          opacity="0.4"
          transform="translate(666.792 134.318)"
        ></path>
        <path
          fill="#282d32"
          d="M17.195 3.145l-2.028-2.028A3.789 3.789 0 0012.469 0H3.815A3.819 3.819 0 000 3.815V14.5a3.819 3.819 0 003.815 3.815H14.5a3.819 3.819 0 003.812-3.815V5.842a3.789 3.789 0 00-1.117-2.7zm-4.224-1.558v.7a2.289 2.289 0 01-2.289 2.289H7.63a2.289 2.289 0 01-2.289-2.287v-.763h7.129a2.253 2.253 0 01.5.061zM16.786 14.5a2.289 2.289 0 01-2.286 2.286H3.815A2.289 2.289 0 011.526 14.5V3.815a2.289 2.289 0 012.289-2.289v.763A3.819 3.819 0 007.63 6.1h3.052a3.808 3.808 0 003.786-3.528l1.648 1.648a2.3 2.3 0 01.67 1.618z"
          data-name="Path 44877"
          transform="translate(668.688 136.688)"
        ></path>
      </g>
    </svg>
  );
}

export default SaveActionIcon;
