import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { getCommonCustomers, getDomainValues } from "../../../util/apis";
import { DOMAIN_CUSTOMER_ACCOUNT_TYPE, STATUS_ACTIVE } from "../../../util/constants";
import { ACTION_SET_HEADER_VALUE } from "./reducer";

const ID = "credit-notes-for-customer-estimation";

const Customer = (props) => {
    const { creditNote, dispatchCreditNote } = props;
    const { t } = useTranslation();
    const customerRef = useRef(true);

    const _handleChange = (name, value) => {
        dispatchCreditNote({ type: ACTION_SET_HEADER_VALUE, name, value })
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('creditNotesForCustomersEstimation:customer')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label id={`${ID}-customer-lbl`} className='form-label mandatory'>{t('creditNotesForCustomersEstimation:customer')}</label>
                        <FrmSelectMenu
                            id={`${ID}-customer`}
                            value={creditNote?.header?.atrId}
                            onChange={(e) => _handleChange("atrId", e)}
                            api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, true, false, false, [], false)}
                            defualtValueApi={() => getCommonCustomers(isEmpty(creditNote?.header?.atrId) ? [] : [creditNote?.header?.atrId], STATUS_ACTIVE, null, true, false, false, [], false)}
                            defualtValueId={creditNote?.header?.atrId}
                            descLo={['code', 'descLo']}
                            descFo={['code', 'descFo']}
                            isAsync={true}
                            onDataRetrieved={(list) => {
                                const id = getUrlParam("id");
                                if (isNotEmpty(id) && creditNote?.header?.atrId && customerRef.current) {
                                    list?.forEach((element) => {
                                        if (element?.id == creditNote?.header?.atrId) {
                                            _handleChange("customerData", { ...element })
                                            return;
                                        }
                                    })
                                }
                                if (customerRef.current && creditNote?.header?.atrId) {
                                    customerRef.current = false;
                                }
                            }}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${ID}-account-type-lbl`} className='form-label mandatory'>{t('creditNotesForCustomersEstimation:account_type')}</label>
                        <FrmSelectMenu
                            id={`${ID}-account-type`}
                            value={creditNote?.header?.accountType}
                            api={() => getDomainValues(DOMAIN_CUSTOMER_ACCOUNT_TYPE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleChange("accountType", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customer;