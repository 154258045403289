import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slisting from "../../../components/slisting";
import ViewIcon from "../../../icons/View";
import { deleteEstimationForm, getEstimationFormList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, dialogRef, deleteAction } = rowActions;

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={`${style.estimationFormsFieldsBuilderRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data?.descLo : data?.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('name')}</label>
                <label>{isEnglish() ? data?.descLo : data?.descFo}</label>
            </div>

            <div title={isEnglish() ? data?.activeDescLo : data?.activeDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.activeDescLo : data?.activeDescFo}</label>
            </div>
            <div>
                {isAllowedAction(user, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, VIEW_EDIT_ACTION) &&
                    <button title={t('view_valuation_details')} className={style.viewBtn} onClick={() => navigate(`/valuation-form?id=${data?.id}`)}>
                        <span>
                            <ViewIcon />
                        </span>
                        <label>{t('view_valuation_details')}</label>
                    </button>
                }
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const EstimationFormsFieldsBuilderTable = (props) => {
    const { user, tableRef, searchTextRef, filterRef, dialogRef } = props;

    const { t } = useTranslation();

    const [data, setData] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('code'), width: "25%" },
        { title: t('name'), width: "25%" },
        { title: t('status'), width: "15%" },
        { title: "", width: "12%" },
        { title: "", width: "18%" },
    ];

    const _fetchData = (page, size) => {
        getEstimationFormList(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t))
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (isNotEmpty(id)) {
                return deleteEstimationForm(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, "delete_fields", t));
            }
        });
    }

    return (
        <div className={style.estimationFormsFieldsBuilderTableContainer}>
            <Slisting
                id="estimation-forms-fields-builder-table"
                ref={tableRef}
                columns={columns}
                formId={RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID}
                user={user}
                Row={Row}
                tableStyle={style.estimationFormsFieldsBuilderTable}
                getData={_fetchData}
                pagingData={data}
                withFilter
                cardInMobile
                rowActions={{ user, dialogRef, deleteAction: _deleteAction }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={() => dialogRef?.current?.open()}
                deleteAction={deleteEstimationForm}
                viewAction={(id) => dialogRef.current?.open(id)}
                labelCardWidth="80px"
            />
        </div>
    );
}

export default EstimationFormsFieldsBuilderTable;