import PrintDialogComponent from '@ipgd-gauge/print-dialog';
import { forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { saveCurrentRecordId, saveSelectedRecords } from '../../store/formSelectedRecords/actions';
import { getValuationReports, printValuationReport } from '../../util/apis';
import { isTrnxForm } from '../../util/formUtil';

const PrintDialog = (props, ref) => {
    const { id, formId, user, prePrint } = props;

    const path = useLocation();
    const dispatch = useDispatch();

    const selectedIds = useSelector(state => state.persistedReducer.formSelectedRecords[formId]?.selectedIds || [])

    return (
        <PrintDialogComponent
            id={id}
            ref={ref}
            formId={formId}
            prePrint={prePrint}
            getReportsForSelect={getValuationReports}
            printReport={printValuationReport}
            userId={user?.fndUserId}
            isTransactionForm={isTrnxForm(path)}
            selectedIds={selectedIds}
            changeCurrentRecordId={(newId) => dispatch(saveCurrentRecordId(formId, newId))}
            cleareSelectedRecords={() => dispatch(saveSelectedRecords(formId, []))}
        />
    );
}

export default forwardRef(PrintDialog);