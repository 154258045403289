import FrmInput from "@ipgd-gauge/frm-input";
import { checkValidation, handleError, hideWaiting, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import UploadImg from "../../../components/upload-img";
import QrCodeIcon from "../../../icons/QrCode";
import { addQrCodeImage, deleteQrCodeImage } from "../../../util/apis";
import { ESTIMATION_TRANSACTION_QRCODE } from "../../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const DIALOG_ID = "transaction-qr-code-dialog";

const QrCodeDialog = (props, ref) => {

    const { tableRef } = props
    const { t } = useTranslation();
    const dialogRef = useRef();
    const uploadImagesRef = useRef();
    const transactionIdRef = useRef(null);

    const [depositCode, setDepositCode] = useState(null);

    const _saveAction = async () => {
        if (!checkValidation([{ id: DIALOG_ID }], t)) {
            return
        }
        const file = uploadImagesRef.current.getImages();
        const imageIds = uploadImagesRef.current.getImageIds();
        if (file == null && (imageIds || [])?.length == 0) {
            handleError(null, "estimationTransaction:qr_image_mandatory", t)
        }
        showWaiting(`${DIALOG_ID}-okButton`, true)
        const deletedImages = uploadImagesRef.current.getDeletedImagesIds();
        if (deletedImages?.length > 0) {
            try {
                await deleteQrCodeImage(transactionIdRef.current);
            } catch (error) {
                handleError(error, "delete_fields", t);
            }
        }
        addQrCodeImage(transactionIdRef.current, depositCode, file)
            .then(() => {
                showSuccessMessage("action_performed_successfully", t)
                tableRef?.current?.refresh();
                dialogRef.current.close();
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting(`${DIALOG_ID}-okButton`))
    }

    const _postClose = () => {
        transactionIdRef.current = null;
        uploadImagesRef.current.setImages([]);
        removeAllMandatoryClasses(document.getElementById(DIALOG_ID))
        setDepositCode(null);
    }

    const open = (transactionId, qrImagePath, depositCode) => {
        if (isNotEmpty(qrImagePath)) {
            uploadImagesRef.current.setImages([{ id: transactionId, path: qrImagePath }])
        }
        setDepositCode(depositCode || null);
        transactionIdRef.current = transactionId;
        dialogRef.current?.open();
    }

    useImperativeHandle(ref, () => ({ open }))

    return (
        <Modal
            id={DIALOG_ID}
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={<>
                <QrCodeIcon />
                {t('estimationTransaction:qr_code')}
            </>}
            className={`fitHeightDialog ${style.qrCodeDialog}`}
            withFooter
            withOkButton
            okBtnLabel={t('save')}
            okBtnAction={_saveAction}
            okBtnStyleAction="primary-button"
            withCancelButton
            cancelBtnStyleAction="cancel-button"
            postClose={_postClose}
            smallSize
        >
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className={`w100 ${style.uploadQrCodeContainer}`}>
                        <UploadImg
                            ref={uploadImagesRef}
                            formId={ESTIMATION_TRANSACTION_QRCODE}
                            title={t('estimationTransaction:qr_code')}
                            accept="image/*"
                            mandatory
                        />
                    </div>
                    <div className="w100">
                        <label id="deposit-code-lbl" className='form-label mandatory'>{t('estimationTransaction:deposit_code')}</label>
                        <FrmInput
                            id="deposit-code"
                            value={depositCode}
                            onBlur={value => setDepositCode(value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(QrCodeDialog);