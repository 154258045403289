import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues, getRealEstateUserLOV } from "../../../util/apis";
import { DOMAIN_ALERT_TYPE, DOMAIN_MESSAGE_STATUS, DOMAIN_PROGRESS_STATUS } from "../../../util/constants";
import { RSM3248_OUTBOX_FORM_ID } from "../../../util/constants/forms";

const OutboxFilter = (props, ref) => {

    const { outboxTableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const flagList = [
        { label: t('outBox:unfav'), value: "0" },
        { label: t('outBox:fav'), value: "1" },
    ]

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }))

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="outboxFilter"
            filterData={filterData}
            setFilterData={setFilterData}
            numOfRows={4}
            searchAction={() => outboxTableRef?.current?.refresh()}
            hideMoreOptions
            formId={RSM3248_OUTBOX_FORM_ID}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:message_type')}</label>
                            <FrmSelectMenu
                                id="message-type-filter"
                                api={() => getDomainValues(DOMAIN_ALERT_TYPE)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                value={filterData.types}
                                onChange={e => _handleDataChanged("types", e)}
                                isMulti
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:receiver')}</label>
                            <FrmSelectMenu
                                id="receiver-filter"
                                defualtValueId={filterData.receiverId}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, isEmpty(filterData.receiverId) ? [] : [filterData.receiverId], true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isAsync
                                value={filterData.receiverId}
                                onChange={e => _handleDataChanged("receiverId", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:progress_status')}</label>
                            <StatusSelectMenu
                                id="progress-status-filter"
                                api={() => getDomainValues(DOMAIN_PROGRESS_STATUS)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                value={filterData.progressStatuses}
                                onChange={e => _handleDataChanged("progressStatuses", e)}
                                isMulti
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:status')}</label>
                            <FrmSelectMenu
                                id="status-filter"
                                api={() => getDomainValues(DOMAIN_MESSAGE_STATUS)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                value={filterData.status}
                                onChange={e => _handleDataChanged("status", e.value)}
                                isClearable
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:flaged')}</label>
                            <FrmSelectMenu
                                id="flaged-filter"
                                options={flagList}
                                idName="value"
                                descLo={['label']}
                                descFo={['label']}
                                value={filterData.flag}
                                onChange={e => _handleDataChanged("flag", e.value)}
                                isClearable
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:from_date')}</label>
                            <FrmTimeDatePickr
                                id="form-date"
                                value={filterData.fromDate}
                                onChange={value => _handleDataChanged("fromDate", value)}
                                enableTime
                            />
                        </div>
                        <div className="w50 ">
                            <label className='form-label'>{t('outBox:to_date')}</label>
                            <FrmTimeDatePickr
                                id="to-date"
                                value={filterData.toDate}
                                onChange={value => _handleDataChanged("toDate", value)}
                                enableTime
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(OutboxFilter);