import React from "react";

function PasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 24 24"
    >
      <path d="M19 8.424V7A7 7 0 005 7v1.424A5 5 0 002 13v6a5.006 5.006 0 005 5h10a5.006 5.006 0 005-5v-6a5 5 0 00-3-4.576zM7 7a5 5 0 0110 0v1H7zm13 12a3 3 0 01-3 3H7a3 3 0 01-3-3v-6a3 3 0 013-3h10a3 3 0 013 3z"></path>
      <path d="M12 14a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1z"></path>
    </svg>
  );
}

export default PasswordIcon;
