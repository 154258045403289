import React from "react";

function NewTabIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g fill="#292D32" transform="translate(6.422 6.422)">
        <path d="M.1 12.4c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L10.7-.1c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1L.6 12.2c-.1.1-.3.2-.5.2z"></path>
        <path d="M11.2 9.4c-.4 0-.8-.3-.8-.8V1.2H3.1c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h8.2c.4 0 .8.3.8.8v8.2c-.1.5-.4.8-.9.8z"></path>
      </g>
    </svg>
  );
}

export default NewTabIcon;
