import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../util/constants/forms";
import PriceAgreementTable from "./listing";
import PriceAgreementFilter from "./listing/Filters";

const PriceAgreements = () => {
    const { t } = useTranslation("priceAgreement");

    const searchTextRef = useRef();
    const tableRef = useRef();
    const filterRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3007_PRICE_AGREEMENT_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t("property")}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <PriceAgreementTable
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
            />

            <PriceAgreementFilter
                ref={filterRef}
                tableRef={tableRef}
            />
        </>
    );
}

export default PriceAgreements;