import { NavMenu as MenuComponent, Node } from '@ipgd-gauge/nav-menu';
import 'rc-dropdown/assets/index.css';
// import Menu, { MenuItem } from 'rc-menu';
import { isAllowedForm } from '@ipgd-gauge/utils';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import DashboardsIcon from "../../icons/Dashboards";
import DifinitionModuleIcon from "../../icons/DifinitionModule";
import FinancialIcon from "../../icons/Financial";
import KindaLoginIcon from "../../icons/KindaLogin";
import NoDataFoundIcon from '../../icons/NoDataFound';
import ProcessesMangIcon from "../../icons/ProcessesMang";
import ReportsIcon from "../../icons/Reports";
import SettingsIcon from '../../icons/Settings';
import TransmittersIcon from "../../icons/Transmitters";
import ValueMateIcon from '../../icons/ValueMate';
import { changeFavoriteCount, changeFavoriteForms, userUpdateProfile } from '../../store/user/actions';
import { FND0042_USERS_DEFINITION_FORM_ID, FND0133_USERS_LOGIN_LOG_FORM_ID, FND2319_CITIES_DEFINITION_FORM_ID, FND2621_TOWNS_DEFINITION_FORM_ID, FND3168_REPORTS_DESIGN_FORM_ID, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, RSM2659_PROPERTIES_TYPES_FORM_ID, RSM2661_PROPERTIES_USERS_FORM_ID, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, RSM2669_BRANCHES_DEFINITIONS_FORM_ID, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, RSM2877_ADDITIONAL_ITEMS_FORM_ID, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, RSM3007_PRICE_AGREEMENT_FORM_ID, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID, RSM3190_EVALUATION_QUOTATIONS_FORM_ID, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, RSM3230_VALUATION_TRANSACTIONS_FORM_ID, RSM3232_DELEGATION_FORM_ID, RSM3234_PROPERTIES_MAP_FORM_ID, RSM3236_VALUATION_HISTORY_FORM_ID, RSM3240_NOT_EXECUTED_TASKS_FORM_ID, RSM3242_TRANSACTIONS_STATISTICS_FORM_ID, RSM3244_TASKS_STATISTICS_FORM_ID, RSM3246_INBOX_FORM_ID, RSM3248_OUTBOX_FORM_ID, RSM3250_VALUATION_REPORTS_FORM_ID, RSM3252_TASKS_LIST_FORM_ID, RSM3266_IMPORTATNT_LINKS, RSM3268_CUSTOMER_DUES_FOLLOW_UP, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP, RSM3272_EVALUATION_CONTRACTS_FORM_ID, RSM3274_CONTROL_WITH_EXCEL_FIELDS, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../util/constants/forms";
import { goToForm } from '../../util/formUtil';
import { isEnglish } from '../../util/util';
import style from './style.module.scss';
import { CorrespondenceMenu, DashbordsMenu, DifinitionMenu, FinancialMenu, OperationsManagementMenu, ReportsMenu, SettingMenu } from '../../icons/ModulMenu';
import { saveFavoriteForms } from '../../util/apis';
import UserRolesIcon from '../../icons/UserRoles';
import BuilderProceduresIcon from '../../icons/BuilderProcedures';

const NavMenu = ({ logoutAction }) => {
    const { t } = useTranslation();
    const user = useSelector(state => state.persistedReducer.userReducer.user);
    const favoriteCount = useSelector(state => state?.persistedReducer?.userReducer?.user?.favoriteCount || 0);

    return (
        <MenuComponent
            user={user}
            goToForm={goToForm}
            Logo={ValueMateIcon}
            logoutAction={logoutAction}
            favoriteCount={favoriteCount}
            KindaLoginIcon={KindaLoginIcon}
            NoDataFoundIcon={NoDataFoundIcon}
            userUpdateProfile={userUpdateProfile}
            withOutDashboard
            customStyle={style.valueMateMenu}
        >
            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                module={{
                    id: 'module-1', name: t('dashboards'),
                    style: style.moduleOne,
                    icon: <DashbordsMenu />,
                    color: "#ff5195",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-1-1',
                            forms: [
                                { id: RSM3240_NOT_EXECUTED_TASKS_FORM_ID, nameLo: t('not_executed_tasks', { lng: 'en' }), nameFo: t('not_executed_tasks', { lng: 'ar' }), path: ['/not-executed-tasks'] },
                                { id: RSM3242_TRANSACTIONS_STATISTICS_FORM_ID, nameLo: t('transactions_statistics', { lng: 'en' }), nameFo: t('transactions_statistics', { lng: 'ar' }), path: ['/transactions-statistics'] },
                                { id: RSM3244_TASKS_STATISTICS_FORM_ID, nameLo: t('tasks_statistics', { lng: 'en' }), nameFo: t('tasks_statistics', { lng: 'ar' }), path: ['/tasks-statistics'] },
                                { id: RSM3268_CUSTOMER_DUES_FOLLOW_UP, nameLo: t('customerDuesFollowUp:customer_dues_follow_up', { lng: 'en' }), nameFo: t('customerDuesFollowUp:customer_dues_follow_up', { lng: 'ar' }), path: ['/customer-dues-follow-up'] },
                                { id: RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP, nameLo: t('customerDuesFollowUp:inspectors_employees_dues_follow_up', { lng: 'en' }), nameFo: t('customerDuesFollowUp:inspectors_employees_dues_follow_up', { lng: 'ar' }), path: ['/inspectors-employees-dues-follow-up'] },
                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-2', name: t('transactions'), icon: <OperationsManagementMenu />,
                    style: style.moduleTwo,
                    color: "#06d3d4",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-2-1',
                            name: t('transactions'),
                            icon: <ProcessesMangIcon />,
                            forms: [
                                { id: RSM3230_VALUATION_TRANSACTIONS_FORM_ID, nameLo: t('estimation_transactions', { lng: 'en' }), nameFo: t('estimation_transactions', { lng: 'ar' }), path: ['/estimation-transactions', '/estimation-trx'] },
                                { id: RSM3234_PROPERTIES_MAP_FORM_ID, nameLo: t('estimation_map', { lng: 'en' }), nameFo: t('estimation_map', { lng: 'ar' }), path: ['/estimation-map'] },
                                { id: RSM3190_EVALUATION_QUOTATIONS_FORM_ID, nameLo: t('estimation_quotation', { lng: 'en' }), nameFo: t('estimation_quotation', { lng: 'ar' }), path: ['/estimation-quotations', '/estimation-quotation'] },
                                { id: RSM3007_PRICE_AGREEMENT_FORM_ID, nameLo: t('price_agreement', { lng: 'en' }), nameFo: t('price_agreement', { lng: 'ar' }), path: ['/price-agreement', '/price-agreements'] },
                                { id: RSM3272_EVALUATION_CONTRACTS_FORM_ID, nameLo: t('valuation_contracts', { lng: 'en' }), nameFo: t('valuation_contracts', { lng: 'ar' }), path: ['/valuation-contracts', '/valuation-contract'] },
                                { id: RSM3236_VALUATION_HISTORY_FORM_ID, nameLo: t('estimations_history', { lng: 'en' }), nameFo: t('estimations_history', { lng: 'ar' }), path: ['/estimations-history'] },
                                { id: RSM3266_IMPORTATNT_LINKS, nameLo: t('importantLinks:important_links', { lng: 'en' }), nameFo: t('importantLinks:important_links', { lng: 'ar' }), path: ['/important-links'] },
                                { id: RSM3232_DELEGATION_FORM_ID, nameLo: t('delegation', { lng: 'en' }), nameFo: t('delegation', { lng: 'ar' }), path: ['/delegation'] },
                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-3', name: t('mail'), icon: <CorrespondenceMenu />,
                    style: style.moduleThree,
                    color: "#48b8e0",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-3-1',
                            name: t('mail'),
                            icon: <TransmittersIcon />,
                            forms: [
                                { id: RSM3246_INBOX_FORM_ID, nameLo: t('inbox', { lng: 'en' }), nameFo: t('inbox', { lng: 'ar' }), path: ['/inbox'] },
                                { id: RSM3248_OUTBOX_FORM_ID, nameLo: t('outbox', { lng: 'en' }), nameFo: t('outbox', { lng: 'ar' }), path: ['/outbox'] },
                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-4', name: t('financial'), icon: <FinancialMenu />,
                    style: style.moduleFour,
                    color: "#a8de4f",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-4-1',
                            name: t('financial'),
                            icon: <FinancialIcon />,
                            forms: [
                                { id: RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID, nameLo: t('estimationFees:estimation_fees_commissions_calculation', { lng: 'en' }), nameFo: t('estimationFees:estimation_fees_commissions_calculation', { lng: 'ar' }), path: ['/estimation-fees-commissions-calculation'] },
                                { id: RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, nameLo: t('customers_invoices', { lng: 'en' }), nameFo: t('customers_invoices', { lng: 'ar' }), path: ['/customers-estimation-invoices', '/customers-estimation-invoice'] },
                                { id: RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, nameLo: t('estimators_inspectors_invoices', { lng: 'en' }), nameFo: t('estimators_inspectors_invoices', { lng: 'ar' }), path: ['/estimators-inspectors-invoices', '/estimators-inspectors-invoice'] },
                                { id: RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, nameLo: t('creditNotesForCustomersEstimation:credit_notes_for_customers_estimation', { lng: 'en' }), nameFo: t('creditNotesForCustomersEstimation:credit_notes_for_customers_estimation', { lng: 'ar' }), path: ['/credit-notes-for-customers-estimation', 'credit-notes-for-customer-estimation'] },

                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-5', name: t('definitions'), icon: <DifinitionMenu />,
                    style: style.moduleFive,
                    color: "#0071ff",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-5-1',
                            name: t('definitions'),
                            icon: <DifinitionModuleIcon />,
                            forms: [
                                { id: FND2319_CITIES_DEFINITION_FORM_ID, nameLo: t('cities', { lng: 'en' }), nameFo: t('cities', { lng: 'ar' }), path: ['/cities'] },
                                { id: FND2621_TOWNS_DEFINITION_FORM_ID, nameLo: t('towns', { lng: 'en' }), nameFo: t('towns', { lng: 'ar' }), path: ['/towns'] },
                                { id: RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, nameLo: t('customers', { lng: 'en' }), nameFo: t('customers', { lng: 'ar' }), path: ['/customers'] },
                                { id: RSM2659_PROPERTIES_TYPES_FORM_ID, nameLo: t('propertiesTypes:properties_types', { lng: 'en' }), nameFo: t('propertiesTypes:properties_types', { lng: 'ar' }), path: ['/properties-types'] },
                                { id: FND3168_REPORTS_DESIGN_FORM_ID, nameLo: t('reports_design', { lng: 'en' }), nameFo: t('reports_design', { lng: 'ar' }), path: ['/reports-design'] },
                                { id: RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, nameLo: t('divisionDefinition:division_definition', { lng: 'en' }), nameFo: t('divisionDefinition:division_definition', { lng: 'ar' }), path: ['/division-definition'] },
                                { id: RSM2669_BRANCHES_DEFINITIONS_FORM_ID, nameLo: t('branchesDefinition:branches_definition', { lng: 'en' }), nameFo: t('branchesDefinition:branches_definition', { lng: 'ar' }), path: ['/branches-definition'] },
                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-6', name: t('user_roles_security'), icon: <UserRolesIcon />,
                    style: style.moduleSubSix,
                    color: "#ffb607",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-6-1',
                            name: t('user_roles_security'),
                            icon: <UserRolesIcon />,
                            forms: [
                                { id: RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, nameLo: t('inspectors_and_employees_categories', { lng: 'en' }), nameFo: t('inspectors_and_employees_categories', { lng: 'ar' }), path: ['/inspectors-and-employees-categories'] },
                                { id: RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, nameLo: t('propertiesPartiesDefinition:inspectors_employees_definition', { lng: 'en' }), nameFo: t('propertiesPartiesDefinition:inspectors_employees_definition', { lng: 'ar' }), path: ['/properties-parties-definition'] },
                                { id: RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, nameLo: t('propertiesPolicies:evaluation_policies', { lng: 'en' }), nameFo: t('propertiesPolicies:evaluation_policies', { lng: 'ar' }), path: ['/evaluation-policies', '/evaluation-policy'] },
                                { id: RSM2661_PROPERTIES_USERS_FORM_ID, nameLo: t('propertiesUsersDefinition:user_roles_security', { lng: 'en' }), nameFo: t('propertiesUsersDefinition:user_roles_security', { lng: 'ar' }), path: ['/properties-users-definition'] },
                                { id: FND0042_USERS_DEFINITION_FORM_ID, nameLo: t('usersDefinition:users_definition', { lng: 'en' }), nameFo: t('usersDefinition:users_definition', { lng: 'ar' }), path: ['/users-definition'] },

                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-7', name: t('estimation_forms_builder_procedures'), icon: <BuilderProceduresIcon />,
                    style: style.moduleSubSixTwo,
                    color: "#f1634d",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-7-1',
                            name: t('estimation_forms_builder_procedures'),
                            icon: <SettingsIcon />,
                            forms: [
                                { id: RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, nameLo: t('estimationFormsFieldsBuilder:estimation_forms_fields_builder', { lng: 'en' }), nameFo: t('estimationFormsFieldsBuilder:estimation_forms_fields_builder', { lng: 'ar' }), path: ['/valuation-forms', '/valuation-form'] },
                                { id: RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, nameLo: t('transactions_statuses', { lng: 'en' }), nameFo: t('transactions_statuses', { lng: 'ar' }), path: ['/estimation-transactions-statuses'] },
                                { id: RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, nameLo: t('estimationMessagesTemplates:estimation_messages_templates', { lng: 'en' }), nameFo: t('estimationMessagesTemplates:estimation_messages_templates', { lng: 'ar' }), path: ['/estimation-messages-templates'] },
                                { id: RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, nameLo: t('workflow_procedure_and_messages', { lng: 'en' }), nameFo: t('workflow_procedure_and_messages', { lng: 'ar' }), path: ['/workflow-procedure-and-messages'] },
                            ]
                        },
                    ]
                }}
            />
            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-8', name: t('other_settings'), icon: <SettingMenu />,
                    style: style.moduleSix,
                    color: "#ffb607",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-8-1',
                            name: t('other_settings'),
                            icon: <SettingsIcon />,
                            forms: [
                                { id: RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, nameLo: t('properties_estimation_list_of_values', { lng: 'en' }), nameFo: t('properties_estimation_list_of_values', { lng: 'ar' }), path: ['/properties-estimation-list-of-values'] },
                                { id: RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, nameLo: t('estimation_quotations_types', { lng: 'en' }), nameFo: t('estimation_quotations_types', { lng: 'ar' }), path: ['/estimation-quotations-types'] },
                                { id: RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, nameLo: t('update_valuation_fields', { lng: 'en' }), nameFo: t('update_valuation_fields', { lng: 'ar' }), path: ['/update-valuation-fields'] },
                                { id: RSM3274_CONTROL_WITH_EXCEL_FIELDS, nameLo: t('controlWithExcelFields:control_with_excel_fields', { lng: 'en' }), nameFo: t('controlWithExcelFields:control_with_excel_fields', { lng: 'ar' }), path: ['/control-with-excel-fields'] },
                                { id: RSM2877_ADDITIONAL_ITEMS_FORM_ID, nameLo: t('additionalItems:additional_items', { lng: 'en' }), nameFo: t('additionalItems:additional_items', { lng: 'ar' }), path: ['/additional-items'] },

                            ]
                        },
                    ]
                }}
            />

            <Node
                user={user}
                goToForm={goToForm}
                isEnglish={isEnglish}
                isAllowedForm={isAllowedForm}
                saveFavoriteForms={saveFavoriteForms}
                changeFavoriteCount={changeFavoriteCount}
                changeFavoriteForms={changeFavoriteForms}
                module={{
                    id: 'module-9', name: t('reports'), icon: <ReportsMenu />,
                    style: style.moduleSeven,
                    color: "#a253e7",
                    oneLevel: true,
                    subModules: [
                        {
                            id: 'sub-module-9-1',
                            name: t('reports'),
                            icon: <ReportsIcon />,
                            forms: [
                                { id: RSM3252_TASKS_LIST_FORM_ID, nameLo: t('tasks_list', { lng: 'en' }), nameFo: t('tasks_list', { lng: 'ar' }), path: ['/tasks-list'] },
                                { id: FND0133_USERS_LOGIN_LOG_FORM_ID, nameLo: t('users_login_log', { lng: 'en' }), nameFo: t('users_login_log', { lng: 'ar' }), path: ['/users-login-log'] },
                                { id: RSM3250_VALUATION_REPORTS_FORM_ID, nameLo: t('estimation_reports', { lng: 'en' }), nameFo: t('estimation_reports', { lng: 'ar' }), path: ['/estimation-reports'] },
                                { id: RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID, nameLo: t('estimation_invoices_register', { lng: 'en' }), nameFo: t('estimation_invoices_register', { lng: 'ar' }), path: ['/estimation-invoices-register'] },
                                { id: RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID, nameLo: t('estimation_invoices_follow_up', { lng: 'en' }), nameFo: t('estimation_invoices_follow_up', { lng: 'ar' }), path: ['/estimation-invoices-follow-up'] },
                                { id: RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, nameLo: t('estimation_transactions_register', { lng: 'en' }), nameFo: t('estimation_transactions_register', { lng: 'ar' }), path: ['/estimation-transactions-register'] },
                                // التقارير الخاصة المالية و العقارات 
                                { id: MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, nameLo: t('customReports:properties_valuation_custom_reports', { lng: 'en' }), nameFo: t('customReports:properties_valuation_custom_reports', { lng: 'ar' }), path: ['/properties-valuation-custom-reports'] },
                            ]
                        },
                    ]
                }}
            />
        </MenuComponent>
    )
}

export default NavMenu;