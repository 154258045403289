import React from "react";

function VideoIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1067.27 1173.4">
            <path
                fill="#fff"
                d="M419.58 742.23L698.97 580.92 419.58 419.62 419.58 742.23z"
            ></path>
            <rect
                width="104.19"
                height="107"
                x="908.2"
                y="265.56"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="55.31"
                y="425.39"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="55.31"
                y="265.56"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="55.31"
                y="105.73"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="908.2"
                y="105.73"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <path
                fill="none"
                d="M424.59 736.07L693.96 580.54 424.59 580.54 424.59 736.07z"
            ></path>
            <path
                fill="none"
                d="M424.59 580.54L693.96 580.54 424.59 425.01 424.59 580.54z"
            ></path>
            <rect
                width="104.19"
                height="107"
                x="908.2"
                y="425.39"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="908.2"
                y="745.05"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="908.2"
                y="585.22"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="908.2"
                y="904.88"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="55.31"
                y="904.88"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="55.31"
                y="745.05"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <rect
                width="104.19"
                height="107"
                x="55.31"
                y="585.22"
                fill="none"
                rx="27.29"
                ry="27.29"
            ></rect>
            <path
                fill="#e34e40"
                d="M424.59 425.01l269.38 155.53h373.3V106.52C1067.27 47.69 1019.58 0 960.75 0H106.52C47.69 0 0 47.69 0 106.52v474.02h424.59V425.01zM908.2 133.02c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42c0 15.07-12.22 27.29-27.29 27.29h-49.61c-15.07 0-27.29-12.22-27.29-27.29v-52.42zm0 159.83c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42c0 15.07-12.22 27.29-27.29 27.29h-49.61c-15.07 0-27.29-12.22-27.29-27.29v-52.42zm0 159.83c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42c0 15.07-12.22 27.29-27.29 27.29h-49.61c-15.07 0-27.29-12.22-27.29-27.29v-52.42zM159.49 505.1c0 15.07-12.22 27.29-27.29 27.29H82.59c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zm0-159.83c0 15.07-12.22 27.29-27.29 27.29H82.59c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zM132.2 212.73H82.59c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42c0 15.07-12.22 27.29-27.29 27.29z"
            ></path>
            <path
                fill="#bc3c31"
                d="M693.96 580.54L424.58 736.07V580.54H0v486.33c0 58.83 47.69 106.52 106.52 106.52h854.22c58.83 0 106.52-47.69 106.52-106.52V580.54h-373.3zM159.49 984.59c0 15.07-12.22 27.29-27.29 27.29H82.59c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zm0-159.83c0 15.07-12.22 27.29-27.29 27.29H82.59c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zm0-159.83c0 15.07-12.22 27.29-27.29 27.29H82.59c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zm852.9 319.66c0 15.07-12.22 27.29-27.29 27.29h-49.61c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zm0-159.83c0 15.07-12.22 27.29-27.29 27.29h-49.61c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42zm0-159.83c0 15.07-12.22 27.29-27.29 27.29h-49.61c-15.07 0-27.29-12.22-27.29-27.29v-52.42c0-15.07 12.22-27.29 27.29-27.29h49.61c15.07 0 27.29 12.22 27.29 27.29v52.42z"
            ></path>
        </svg>
    );
}

export default VideoIcon;