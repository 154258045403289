import { handleError } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEstimationReports, getEstimationReportsTotals } from "../../../util/apis";
import { REPORT_CUSTOMERS, REPORT_CUSTOMERS_ACCORDING_REGION_CITY, REPORT_EMPLOYEES_INSPECTORS, REPORT_EMPLOYEES_INSPECTORS_ACCORDING_REGION_CITY, REPORT_EMPLOYEES_INSPECTORS_EXECUTION_PERCENTAGE, REPORT_EMPLOYEES_INSPECTORS_REPORT_ACCORDING_CUSTOMERS, REPORT_INSPECTORS_EMPLOYEES_ACCORDING_TASK, REPORT_REGIONS_ACCORDING_PROPERTYS_TYPE } from "../../../util/constants";
import TableCustomersAccordingRegionCityReport from './tables/TableCustomersAccordingRegionCityReport';
import TableCustomersReport from './tables/TableCustomersReport';
import TableEmployeesInspectorsAccordingRegionCityReport from './tables/TableEmployeesInspectorsAccordingRegionCityReport';
import TableEmployeesInspectorsExecutionPercentageReport from './tables/TableEmployeesInspectorsExecutionPercentageReport';
import TableEmployeesInspectorsReport from './tables/TableEmployeesInspectorsReport';
import TableEmployeesInspectorsReportAccordingCustomersReport from './tables/TableEmployeesInspectorsReportAccordingCustomersReport';
import TableInspectorsEmployeesAccordingTaskReport from './tables/TableInspectorsEmployeesAccordingTaskReport';
import TableRegionsAccordingPropertiesTypeReport from './tables/TableRegionsAccordingPropertiesTypeReport';

const EstimationReportsTable = (props) => {
    const { user, estimationReportsTableRef, estimationReportsFilterRef, searchTextRef, reportNumber } = props;

    const { t } = useTranslation("estimationReports");

    const [reports, setReports] = useState({});

    const firstTimeRender = useRef(true);

    const _getData = (page, size) => {
        if (reportNumber) {
            let filterData = {
                searchValue: searchTextRef.current.getSearchText(),
                ...estimationReportsFilterRef.current.getFilterData()
            }

            getEstimationReports(reportNumber, page, size, filterData)
                .then((response) => setReports(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setReports({ list: [] });
        }
    }

    const getTotals = () => {
        if (reportNumber) {
            let filterData = {
                searchValue: searchTextRef.current.getSearchText(),
                ...estimationReportsFilterRef.current.getFilterData()
            }
            return getEstimationReportsTotals(reportNumber, filterData)
        }
    }

    useEffect(() => {
        if (reportNumber && !firstTimeRender.current) {
            setReports({});
            estimationReportsTableRef.current.refresh();
        } else {
            firstTimeRender.current = false;
        }
    }, [reportNumber])

    return (
        <>

            <div>
                {reportNumber == REPORT_EMPLOYEES_INSPECTORS &&
                    <TableEmployeesInspectorsReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_EMPLOYEES_INSPECTORS_ACCORDING_REGION_CITY &&
                    <TableEmployeesInspectorsAccordingRegionCityReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_CUSTOMERS &&
                    <TableCustomersReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_CUSTOMERS_ACCORDING_REGION_CITY &&
                    <TableCustomersAccordingRegionCityReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_REGIONS_ACCORDING_PROPERTYS_TYPE &&
                    <TableRegionsAccordingPropertiesTypeReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_INSPECTORS_EMPLOYEES_ACCORDING_TASK &&
                    <TableInspectorsEmployeesAccordingTaskReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_EMPLOYEES_INSPECTORS_REPORT_ACCORDING_CUSTOMERS &&
                    <TableEmployeesInspectorsReportAccordingCustomersReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }

                {reportNumber == REPORT_EMPLOYEES_INSPECTORS_EXECUTION_PERCENTAGE &&
                    <TableEmployeesInspectorsExecutionPercentageReport
                        user={user}
                        estimationReportsTableRef={estimationReportsTableRef}
                        reports={reports}
                        getData={_getData}
                        getTotals={getTotals}
                    />
                }
            </div>
        </>
    );
};

export default EstimationReportsTable;