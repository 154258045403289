import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from '@ipgd-gauge/utils';
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SidePanel from "../../../../components/side-panel";
import CustomerIcon from "../../../../icons/Customer";
import { getCustomer } from "../../../../util/apis";
import { STATUS_ACTIVE } from "../../../../util/constants";
import { SAVE_ACTION } from "../../../../util/constants/actions";
import { RSM3009_CREATE_CUSTOMER_RSM_FORM_ID } from '../../../../util/constants/forms';
import Tab1 from "./Tab1";
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import style from './style.module.scss';

const COMMON_ID = "customers-dialog";

const CustomersDialog = (props, ref) => {
    const { saveApi, onSave, getApiParam, preClose } = props;

    const { t } = useTranslation();

    const dialogRef = useRef();
    const logosTabRef = useRef();
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);

    const user = useSelector(state => state.persistedReducer.userReducer.user);
    const defaultData = {
        isActive: STATUS_ACTIVE,
        organizations: [
            {
                fogId: user?.fogId,
                offecialNameLo: user?.orgNameLo,
                offecialNameFo: user?.orgNameFo,
                organizationCode: user?.orgCode
            }
        ],
    }

    const [customer, setCustomer] = useState({});

    const _handleDataChanged = (name, value) => {
        customer[name] = value;
        if (name == 'fcoId') {
            customer.ecdId = null;
            customer.criId = null;
        }
        setCustomer({ ...customer });
    }

    const _saveCustomer = () => {
        if (!checkValidation([{ id: COMMON_ID }], t)) {
            return
        }

        if (!saveApi) {
            return;
        }

        let apiParam = null;
        if (getApiParam) {
            apiParam = getApiParam();
        }

        showWaiting(`${COMMON_ID}-btn`, true);
        saveApi(customer, apiParam)
            .then(async (response) => {
                await logosTabRef.current.uploadImages(response.data.id)
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                if (onSave) {
                    onSave(response.data);
                }
                showSuccessMessage('save_successfully', t)
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => {
                hideWaiting(`${COMMON_ID}-btn`, true);
            });
    }

    const _populateRecordData = (customerId, defaultValues) => {
        if (isNotEmpty(customerId)) {
            updateRecordRef.current = true;
            getCustomer(customerId)
                .then((response) => setCustomer(response.data))
                .catch((error) => {
                    handleError(error, null, t)
                    setCustomer({ ...defaultData })
                });
        } else {
            setCustomer({ ...defaultValues, ...defaultData, imgKey: new Date().getTime() })
        }
    }

    const open = (customerId, defaultData) => {
        dialogRef.current.open();
        _populateRecordData(customerId, defaultData);
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _preCloseAction = () => {
        updateRecordRef.current = false;
        if (preClose) {
            preClose();
        }
    }

    const _postClose = () => {
        setCustomer({});
        tabsRef.current.changeTab(1);
        removeAllMandatoryClasses(document.getElementById(COMMON_ID));
    }

    return (
        <SidePanel
            id={COMMON_ID}
            ref={dialogRef}
            formId={RSM3009_CREATE_CUSTOMER_RSM_FORM_ID}
            title={t('customers')}
            icon={<CustomerIcon />}
            withFooter={isAllowedAction(user, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, SAVE_ACTION)}
            footerLbl={t("save")}
            footerAction={_saveCustomer}
            postClose={_postClose}
            preClose={_preCloseAction}
            nextPreviousAction={(id) => _populateRecordData(id)}
            showLog={isNotEmpty(customer.id)}
            user={user}
            masterId={customer.id}
        >
            <Tabs
                id={`${COMMON_ID}-tabs`}
                ref={tabsRef}
                labels={['home', 'customers:organization_list', 'address']}
                tabContentStyle={style.tabContentStyle}
                tabsButtonCustomStyle={style.citiesTabs}
            >
                <Tab1 customer={customer} handleDataChanged={_handleDataChanged} logosTabRef={logosTabRef} />
                <Tab2 customer={customer} setCustomer={setCustomer} />
                <Tab3 customer={customer} handleDataChanged={_handleDataChanged} />
            </Tabs>
        </SidePanel>
    )
}

export default forwardRef(CustomersDialog);