import ForeignCurrencyInputComponent from "@ipgd-gauge/foreign-currency";
import { isLocalCurrency } from "../../util/util";

const ForeignCurrencyInput = (props) => {

    const { id, value, onChange, onBlur, changeAlcAmount, currencyId, exchngRate, minValue, maxValue, decimalScale, withoutFormat, disabled } = props;

    return (
        <ForeignCurrencyInputComponent
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            changeAlcAmount={changeAlcAmount}
            currencyId={currencyId}
            exchngRate={exchngRate}
            minValue={minValue}
            maxValue={maxValue}
            decimalScale={decimalScale}
            withoutFormat={withoutFormat}
            disabled={disabled}
            isLocalCurrency={isLocalCurrency}
        />
    )
}

export default ForeignCurrencyInput;