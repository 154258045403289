import FormTitleComponent from "@ipgd-gauge/form-title";
import { changeFavoriteCount, changeFavoriteForms } from "../../store/user/actions";
import { saveFavoriteForms } from "../../util/apis";
import { getFormAttributeByPath } from "../../util/util";

const FormTitle = (props) => {
    const { customDiv, formId } = props

    return (
        <FormTitleComponent
            formId={formId}
            saveFavoriteForms={saveFavoriteForms}
            changeFavoriteCount={changeFavoriteCount}
            changeFavoriteForms={changeFavoriteForms}
            getFormAttributeByPath={getFormAttributeByPath}
            customDiv={customDiv}
        />
    )
}

export default FormTitle;