import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import { getBranch, getCommonDivisionsList, saveBranch } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2669_BRANCHES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const DIVISION_CONDITION = 'activeDivisions';

const BranchesDefinitionDialog = (props, ref) => {
    const { t } = useTranslation();
    const { tableRef, user } = props;

    const DEFAULT_DATA = { divisions: [], isActive: STATUS_ACTIVE };

    const [data, setData] = useState(DEFAULT_DATA);

    const updateRecordRef = useRef(false);
    const dialogRef = useRef();
    const divisionRef = useRef();

    const close = () => {
        dialogRef?.current?.closeIfCan();
    }

    const _okBtnAction = () => {
        if (!checkValidation([{ id: "branches-definition-dialog" }, {
            id: "branches-definition-tab-2-divisions-table",
            title: t('branchesDefinition:division'),
            data: data.divisions,
            disabledConditions: {
                fogId: () => true,
                dsvId: () => true
            }
        }], t)) {
            return;
        }
        if (isNotEmpty(data.descLo) && isNotEmpty(data.descFo) && isNotEmpty(data.isActive)) {
            showWaiting("branches-definition-dialog-okButton", true)
            saveBranch(data)
                .then(() => {
                    showSuccessMessage('save_successfully', t);
                    dialogRef.current.changeSelectedTab(1);
                    if (!updateRecordRef.current) {
                        _populateRecordData(null);
                    }
                })
                .catch((error) => handleError(null, error.response.data.message.split("#")[0], t, [{ name: "tableName", value: t('branchesDefinition:divisions') }]))
                .finally(() => hideWaiting("branches-definition-dialog-okButton"))
        } else {
            handleError(null, "manadetory_fields", t)
        }
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getBranch(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setData(DEFAULT_DATA);
        }
    }

    const open = (id) => {
        dialogRef?.current?.open();
        _populateRecordData(id)
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _insertDivisions = (selectedData) => {
        if (!data.divisions) {
            data.divisions = [];
        }

        let newLines = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                dsvId: element.id
            });
        }

        data.divisions = [...data.divisions, ...newLines];
        setData({ ...data });

        divisionRef?.current?.close();
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false
        tableRef?.current?.refresh()
    }

    return (
        <>
            <ModalWithTabs
                id="branches-definition-dialog"
                ref={dialogRef}
                title={'branchesDefinition:branches_definition'}
                labels={['home#1', 'branchesDefinition:divisions#1']}
                tableStyle={style.branchesDefinitionDialog}
                className={style.branchesDefinitionDialog}
                okBtnAction={_okBtnAction}
                preClose={_preCloseAction}
                postClose={() => {
                    setData(DEFAULT_DATA)
                    removeAllMandatoryClasses(document.getElementById("branches-definition-dialog"))
                }}
                nextPreviousAction={(id) => _populateRecordData(id)}
                hideOkBtn={!isAllowedAction(getUser(), RSM2669_BRANCHES_DEFINITIONS_FORM_ID, SAVE_ACTION)}
                formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                showLog
                user={user}
                masterId={data.id}
            >
                <Tab1 data={data} setData={setData} />
                <Tab2 data={data} setData={setData} divisionRef={divisionRef} user={user} />
            </ModalWithTabs>

            <MultiInsertDialog
                id={`branches-definition-dialog-divisions`}
                ref={divisionRef}
                tableStyle={style.branchesDefinitionMultiInsart}
                title={t('branchesDefinition:insert_divisions')}
                okBtnAction={_insertDivisions}
                api={(searchValue) => getCommonDivisionsList(searchValue, DIVISION_CONDITION, false, null, null, null, STATUS_ACTIVE, (data.divisions || []).map((line) => line.dsvId))}
                selectedIds={(data.divisions || []).map((line) => line.dsvId)}
                searchPlaceholder={t('branchesDefinition:code') + " , " + t('branchesDefinition:name')}
                formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('branchesDefinition:division'), valueLo: 'codeNameLo', valueFo: 'codeNameFo', width: '90%' },
                ]}
            />
        </>
    )
}


export default forwardRef(BranchesDefinitionDialog);