
import UploadImg from "../../../../components/upload-img";
import { IMAGE_TYPE_ESTIMATION_QUOTATION } from "../../../../util/constants";

const Tab2 = (props) => {
    const { tab, data, attachmentsImgRef } = props;
    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className="paddingFrm tabsBg">
                <UploadImg
                    ref={attachmentsImgRef}
                    isMulti={true}
                    masterId={data.id}
                    formId={IMAGE_TYPE_ESTIMATION_QUOTATION}
                />
            </div>
        </div>
    )
}

export default Tab2;