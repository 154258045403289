import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3248_OUTBOX_FORM_ID } from "../../util/constants/forms";
import OutboxTable from "./listing";
import OutboxFilter from "./listing/Filter";

const Outbox = () => {

    const { t } = useTranslation('outBox');

    const searchTextRef = useRef();
    const filterDialogRef = useRef();
    const outboxTableRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3248_OUTBOX_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('trnx_code') + " , " + t('content')}
                        search={() => outboxTableRef.current.refresh()}
                        formId={RSM3248_OUTBOX_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterDialogRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>
            <OutboxTable outboxTableRef={outboxTableRef} filterDialogRef={filterDialogRef} searchTextRef={searchTextRef} />
            <OutboxFilter ref={filterDialogRef} outboxTableRef={outboxTableRef} />
        </>
    )
}

export default Outbox;