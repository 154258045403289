import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM2669_BRANCHES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { encryptText } from "@ipgd-gauge/utils";

const commonId = "branches-definition-dialog";

const Tab1 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('branchesDefinition');

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id={`${commonId}-code`}
                            value={data.code}
                            onBlur={(value) => _handleDataChanged("code", value)}
                            column="MBN_CODE"
                            formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${commonId}-accounts-groups-status-lbl`} data-label={encryptText("MBN_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id={`${commonId}-accounts-groups-status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE)
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE)
                                },
                            ]} />
                    </div>
                    <div className="w50">
                        <label id={`${commonId}-english-name-lbl`} data-label={encryptText("MBN_DESC_LO")} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${commonId}-english-name`}
                            value={data?.descLo}
                            onBlur={(value) => _handleDataChanged("descLo", value)}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${commonId}-arabic-name-lbl`} data-label={encryptText("MBN_DESC_FO")} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${commonId}-arabic-name`}
                            value={data?.descFo}
                            onBlur={(value) => _handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("MBN_REM")} className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${commonId}-remarks`}
                            value={data?.rem}
                            onBlur={(value) => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Tab1;