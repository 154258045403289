import React from "react";

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.51"
      height="20.378"
      viewBox="0 0 21.51 20.378"
    >
      <g fill="#f7f9fa" data-name="Group 178407">
        <path
          d="M10.75 6.439A3.75 3.75 0 117 10.189a3.754 3.754 0 013.75-3.75zm0 6a2.25 2.25 0 10-2.25-2.25 2.253 2.253 0 002.25 2.25z"
          opacity="0.34"
        ></path>
        <path
          d="M13.959 20.378a2.449 2.449 0 01-2.093-1.2v-.008l-.11-.19c-.293-.512-.654-.8-.992-.8s-.7.293-1 .8l-.11.19v.008a2.449 2.449 0 01-2.093 1.2 2.377 2.377 0 01-1.219-.328l-1.725-.99a2.649 2.649 0 01-.977-3.616 1.332 1.332 0 00.2-1.264 1.328 1.328 0 00-1.19-.461A2.653 2.653 0 010 11.069v-1.76a2.653 2.653 0 012.65-2.65 1.328 1.328 0 001.19-.461 1.331 1.331 0 00-.2-1.263 2.649 2.649 0 01.978-3.617L6.342.331A2.377 2.377 0 017.55 0a2.449 2.449 0 012.095 1.195v.008l.11.19c.293.512.654.8.992.8s.7-.293 1-.8l.11-.19v-.008a2.449 2.449 0 012.093-1.2 2.377 2.377 0 011.209.331l1.725.987a2.649 2.649 0 01.977 3.616 1.332 1.332 0 00-.2 1.264 1.328 1.328 0 001.19.461 2.653 2.653 0 012.65 2.65v1.76a2.653 2.653 0 01-2.65 2.65 1.328 1.328 0 00-1.19.461 1.331 1.331 0 00.2 1.263 2.651 2.651 0 01-.977 3.617l-1.724.987a2.378 2.378 0 01-1.201.336zm-.8-1.959a.942.942 0 00.8.459.865.865 0 00.448-.128h.01l1.73-.99a1.131 1.131 0 00.535-.693 1.149 1.149 0 00-.113-.874 2.738 2.738 0 01-.2-2.76 2.738 2.738 0 012.49-1.212 1.151 1.151 0 001.15-1.15V9.309a1.151 1.151 0 00-1.15-1.15A2.738 2.738 0 0116.37 6.95a2.741 2.741 0 01.2-2.764 1.149 1.149 0 00-.42-1.566l-1.733-.99-.01-.007a.865.865 0 00-.448-.123.942.942 0 00-.8.459l-.107.185a2.749 2.749 0 01-2.302 1.552 2.732 2.732 0 01-2.291-1.554l-.109-.183a.942.942 0 00-.8-.459.865.865 0 00-.448.123l-.009.007-1.73.99a1.149 1.149 0 00-.423 1.564 2.74 2.74 0 01.2 2.766 2.738 2.738 0 01-2.49 1.209 1.151 1.151 0 00-1.15 1.15v1.76a1.151 1.151 0 001.15 1.15 2.738 2.738 0 012.49 1.212 2.741 2.741 0 01-.2 2.764 1.149 1.149 0 00.423 1.563l1.73.99.01.002a.865.865 0 00.448.123.942.942 0 00.8-.459l.107-.185a2.749 2.749 0 012.3-1.552 2.732 2.732 0 012.292 1.559z"
          data-name="Vector"
        ></path>
      </g>
    </svg>
  );
}

export default SettingsIcon;