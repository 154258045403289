import FrmInput from "@ipgd-gauge/frm-input";
import ToggleButton from "@ipgd-gauge/toggle-button";
import { useTranslation } from 'react-i18next';

const QuotationDescription = (props) => {
    const { estimationQuotation, handleDataChanged } = props;
    const { t } = useTranslation("estimationQuotation");

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('quotation_description')}
                    </label>
                    <div>
                        <ToggleButton
                            id="has-offer-details-toggle"
                            isChecked={estimationQuotation?.hasOfferDetails}
                            setIsChecked={(value) => handleDataChanged("hasOfferDetails", value)}
                        />
                    </div>
                </div>
            </div>
            {estimationQuotation?.hasOfferDetails &&
                <div className='container'>
                    <div className='row  rowSmModel'>
                        <div className="w100">
                            <label id="estimation-quotation-quotation-desc-lbl" className="form-label">{t('quotation_description')}</label>
                            <FrmInput
                                id="estimation-quotation-quotation-desc"
                                value={estimationQuotation?.quotationDesc}
                                onBlur={(value) => handleDataChanged("quotationDesc", value)}
                            />
                        </div>
                        <div className="w100">
                            <label id="estimation-quotation-details-lbl" className="form-label">{t('details')}</label>
                            <FrmInput
                                id="estimation-quotation-details"
                                value={estimationQuotation?.quotationDetails}
                                onBlur={(value) => handleDataChanged("quotationDetails", value)}
                            />
                        </div>
                        <div className="w100">
                            <label id="estimation-quotation-work-days-lbl" className="form-label">{t('no_of_days')}</label>
                            <FrmInput
                                id="estimation-quotation-work-days"
                                value={estimationQuotation?.noOfDays}
                                onBlur={(value) => handleDataChanged("noOfDays", value)}
                            />
                        </div>
                        <div className="w100">
                            <label id="estimation-quotation-documents-source-lbl" className="form-label">{t('documents_source')}</label>
                            <FrmInput
                                id="estimation-quotation-documents-source"
                                value={estimationQuotation?.documentsSource}
                                onBlur={(value) => handleDataChanged("documentsSource", value)}
                            />
                        </div>
                        <div className="w100">
                            <label id="estimation-quotation-reports-users-lbl" className="form-label">{t('reports_users')}</label>
                            <FrmInput
                                id="estimation-quotation-reports-users"
                                value={estimationQuotation?.reportUsers}
                                onBlur={(value) => handleDataChanged("reportUsers", value)}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default QuotationDescription;