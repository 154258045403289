import React from "react";

function CheckMarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g data-name="Group 176096" transform="translate(-.109 -.06)">
        <circle
          cx="10"
          cy="10"
          r="10"
          fill="#2baf37"
          data-name="Ellipse 31"
          transform="translate(.109 .06)"
        ></circle>
        <path
          fill="#fff"
          d="M8283.24 2605.571l-3.661-3.283 1.179-1.314 2.385 2.138 5.538-5.82 1.28 1.217z"
          data-name="Path 3215"
          transform="translate(-8274.469 -2591.606)"
        ></path>
      </g>
    </svg>
  );
}

export default CheckMarkIcon;