import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { deleteDistrict, getDistricts } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { FND2621_TOWNS_DEFINITION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {

    const { t } = useTranslation();
    const { data, rowActions } = props;
    const { townsDialogRef, deleteDistrict } = rowActions;

    return (
        <div title={data.sequence} className={`${style.townsTableRefRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>
            <div title={data.code} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('towns:code')}</label>
                <label>{data.code}</label>
            </div>
            <div title={isEnglish() ? data.descLo : data.descFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('towns:name')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>
            <div title={isEnglish() ? data.cityDescLo : data.cityDescFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('towns:city')}</label>
                <label>{isEnglish() ? data.cityDescLo : data.cityDescFo}</label>
            </div>

            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('towns:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(getUser(), FND2621_TOWNS_DEFINITION_FORM_ID, VIEW_EDIT_ACTION) && <EditBtn onClick={() => townsDialogRef?.current?.open(data.id)} />}
                    {isAllowedAction(getUser(), FND2621_TOWNS_DEFINITION_FORM_ID, DELETE_ACTION) && <DeleteBtn onClick={() => deleteDistrict(data.id)} />}
                </div>
            </div>
        </div>
    );
};

const TownsTable = (props) => {

    const { townsTableRef, searchTextRef, filtersRef, townsDialogRef } = props;
    const { t } = useTranslation();

    const [towns, setTowns] = useState();

    const _deleteDistrict = (districtId) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteDistrict(districtId)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    townsTableRef?.current?.refresh();
                })
                .catch((error) => handleError(error, "delete_fields", t))
        });
    }

    const columns = [
        { title: '#', width: "3%" },
        { title: t('towns:code'), width: "10%" },
        { title: t('towns:name'), width: "30%" },
        { title: t('towns:city'), width: "30%" },
        { title: t('towns:status'), width: "10%" },
        { title: "", width: "17%" },
    ];

    const _fetchData = (page, size) => {
        getDistricts(page, size, searchTextRef?.current?.getSearchText(), filtersRef.current?.getFilterData())
            .then((response) => {
                setTowns(response.data);
            })
            .catch(() => handleError(null, null, t))
    }

    return (
        <div className={style.townsTableContainer}>
            <Slisting
                id="townsTableRefTable"
                formId={FND2621_TOWNS_DEFINITION_FORM_ID}
                ref={townsTableRef}
                columns={columns}
                withFilter={true}
                Row={Row}
                tableStyle={style.townsTable}
                pagingData={towns}
                getData={_fetchData}
                rowActions={{ deleteDistrict: _deleteDistrict, townsDialogRef }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length === 0)}
                viewAction={(id) => townsDialogRef.current?.open(id)}
                deleteAction={deleteDistrict}
                newAction={() => townsDialogRef.current?.open()}
                cardInMobile
                labelCardWidth="80px"
                user={getUser()}
            />
        </div>
    );
};

export default TownsTable;
