import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../components/advance-filter";
import { getCommonCustomers, getRealEstateUserLOV, getRegionsLov, getTaskDescriptionLOV } from "../../util/apis";
import { POLICY_LVL_SELF_SERVICE, USERS_TYPES_CUSTOMER } from "../../util/constants";
import { RSM3244_TASKS_STATISTICS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";

const TasksStatisticsFilter = (props, ref) => {
    const { fetchData } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const user = getUser();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    useEffect(() => {
        if (user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE) {
            if (user.customerId) {
                _handleDataChanged("customerIds", [user.customerId])
            } else {
                _handleDataChanged("userIds", [user.id])
            }
        }
    }, [])

    const getFilterData = () => {
        return filterData;
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }));

    const getDefaultFilter = () => {
        const defaultFilters = {}
        if (user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE) {
            if (user.customerId) {
                defaultFilters.customerIds = [user.customerId];
            } else {
                defaultFilters.userIds = [user.id];
            }
        }
        return defaultFilters
    }

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="task-statistics-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={fetchData}
            getDefaultFilter={getDefaultFilter}
            hideMoreOptions
            numOfRows={2}
            formId={RSM3244_TASKS_STATISTICS_FORM_ID}
        >
            <div className="container">
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="row advanceFilterContainer rowSmModel">
                    <div className={`w50`}>
                        <label className='form-label'>{t('user')}</label>
                        <FrmSelectMenu
                            id="task-statistics-filter-user"
                            isMulti={true}
                            value={filterData.userIds}
                            isAsync={true}
                            defualtValueId={filterData.userIds}
                            defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.userIds, true)}
                            api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("userIds", value)}
                            portalTarget={document.body}
                            isDisabled={user.type != USERS_TYPES_CUSTOMER && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                        />
                    </div>
                    <div className={`w50`}>
                        <label className='form-label'>{t('tasksStatistics:task_description')}</label>
                        <FrmSelectMenu
                            id="task-statistics-filter-task-description"
                            isMulti={true}
                            value={filterData.taskDescIds}
                            api={() => getTaskDescriptionLOV(filterData.taskDescIds)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("taskDescIds", value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className={`w50`}>
                        <label className='form-label'>{t('customer')}</label>
                        <FrmSelectMenu
                            id="task-statistics-filter-customer"
                            value={filterData.customerIds}
                            onChange={value => _handleDataChanged("customerIds", value)}
                            defualtValueId={filterData.customerIds}
                            defualtValueApi={() => getCommonCustomers(filterData.customerIds, null, null, true, false, false, [], true)}
                            api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                            descLo={['code', 'descLo']}
                            descFo={['code', 'descFo']}
                            isMulti={true}
                            isAsync={true}
                            isDisabled={user.type == USERS_TYPES_CUSTOMER && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className={`w50`}>
                        <label className='form-label'>{t('region')}</label>
                        <FrmSelectMenu
                            id="task-statistics-filter-region"
                            isMulti={true}
                            value={filterData.regionIds}
                            api={() => getRegionsLov(filterData.regionIds, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("regionIds", value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(TasksStatisticsFilter);