import React from "react";

function NextArrowTwoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M211.41 411.2c-11.78-.05-21.29-9.64-21.24-21.42.02-5.66 2.29-11.07 6.31-15.06l112.85-112.85-112.85-112.85c-8.37-8.3-8.42-21.8-.12-30.17l.12-.12c8.32-8.27 21.76-8.27 30.08 0l128 128c8.37 8.3 8.42 21.8.12 30.17l-.12.12-128 128a21.378 21.378 0 01-15.15 6.19z"></path>
    </svg>
  );
}

export default NextArrowTwoIcon;
