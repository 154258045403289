import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { handleError, hideWaiting, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { getReports, prepareValuationPdfPrintLink, printValuationTrxWord } from "../../../util/apis";
import { ACTION_PRINT_PDF } from "../../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const ChooseReportDialog = (props, ref) => {
    const { postClose, onComplete } = props;
    const { t } = useTranslation("estimationTransaction");

    const dialogRef = useRef();
    const defaultReportIdRef = useRef();
    const activateOnCompleteActionRef = useRef(false);

    const [data, setData] = useState({});
    const [reports, setReports] = useState([]);

    useEffect(() => {
        getReports()
            .then((response) => {
                let reports = response.data;
                if (reports?.length > 0) {
                    defaultReportIdRef.current = reports[0].id;
                    setReports(response.data);
                }
            })
    }, [])

    const _printAction = () => {
        showWaiting(`choose-report-dialog-okButton`, true);
        if (data.isPDF) {
            let printedSuccessfully = false;
            prepareValuationPdfPrintLink(data)
                .then((response) => {
                    printedSuccessfully = true;
                    window.open(response.data);
                })
                .catch((error) => {
                    handleError(error, null, t)
                })
                .finally(async () => {
                    if (activateOnCompleteActionRef.current && onComplete) {
                        await onComplete(printedSuccessfully, true, data.trxId, data.taskId);
                    }
                    hideWaiting(`choose-report-dialog-okButton`);
                    close();
                })
        } else {
            printValuationTrxWord(data, `${data.trxCode || data.trxId}`, async (printedSuccessfully) => {
                if (activateOnCompleteActionRef.current && onComplete) {
                    await onComplete(printedSuccessfully, false, data.trxId, data.taskId);
                }
                hideWaiting(`choose-report-dialog-okButton`);
                close();
            })
        }
    }

    const _postClose = () => {
        setData({})
        if (postClose) {
            postClose();
        }
    }

    const open = (action, trxId, reportId, trxCode, taskId, activateOnCompleteAction) => {
        activateOnCompleteActionRef.current = activateOnCompleteAction;
        data.trxId = trxId;
        data.trxCode = trxCode;
        data.taskId = taskId;
        if (reportId) {
            data.reportId = reportId;
        } else {
            data.reportId = defaultReportIdRef.current;
        }
        data.isPDF = action == ACTION_PRINT_PDF
        setData({ ...data })
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="choose-report-dialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={t('choose_report')}
            withFooter
            className={`fitHeightDialog ${style.actionDailog}`}
            okBtnLabel={t("print")}
            withOkButton
            withCancelButton
            okBtnStyleAction="primary-button"
            okBtnAction={_printAction}
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={_postClose}
            smallSize
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label className='form-label mandatory'>{t('report')}</label>
                        <FrmSelectMenu
                            id="report"
                            value={data.reportId}
                            options={reports}
                            descLo={['name']}
                            descFo={['name']}
                            onChange={e => {
                                data.reportId = e.value
                                setData({ ...data })
                            }}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(ChooseReportDialog);