import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { getDomainValues, getPartiesList } from "../../../util/apis";
import { PRICE_LIST_PARTIES_DOMAIN } from "../../../util/constants";

const COMMON_ID = "price-agreements";

const Party = (props) => {
    const { data, handleDataChange } = props;

    const { t } = useTranslation('priceAgreement');
    const partyRef = useRef(true);
    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('party')}
                    </label>
                </div>
            </div>

            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label id={`${COMMON_ID}-home-party-lbl`} className='form-label mandatory'>{t('party_level')}</label>
                        <FrmSelectMenu
                            id={`${COMMON_ID}-home-party`}
                            value={data?.party}
                            onChange={value => handleDataChange("party", value.value)}
                            api={() => getDomainValues(PRICE_LIST_PARTIES_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w75">
                        <label id={`${COMMON_ID}-home-target-lbl`} className='form-label mandatory'>{t('party')}</label>
                        <FrmSelectMenu
                            id={`${COMMON_ID}-home-target`}
                            key={`${COMMON_ID}-home-target-${data?.party}`}
                            value={data?.gprId}
                            onChange={value => handleDataChange("gprId", value)}
                            api={() => getPartiesList(data?.party, data?.gprId)}
                            descLo={['code', 'descLo']}
                            descFo={['code', 'descFo']}
                            onDataRetrieved={(list) => {
                                const id = getUrlParam("id");
                                if (isNotEmpty(id) && data.gprId && partyRef.current) {
                                    list?.forEach((element) => {
                                        if (element.id == data.gprId) {
                                            handleDataChange("partyData", { ...element })
                                            return;
                                        }
                                    })
                                }
                                if (partyRef.current && data?.id) {
                                    partyRef.current = false;
                                }
                            }}
                            isDisabled={isEmpty(data?.party)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Party;