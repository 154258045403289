import { isNotEmpty } from "@ipgd-gauge/utils";
import Customer from "./Customer";
import Details from "./Details";
import Header from "./Header";
import QuotationDescription from "./QuotationDescription";
import style from "../style.module.scss";
const Tab1 = (props) => {
    const { tab, currentUser, estimationQuotation, handleDataChanged, setEstimationQuotation } = props

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`toggle-mode ${style.toggleMode}`}>
                <Header
                    currentUser={currentUser}
                    estimationQuotation={estimationQuotation}
                    handleDataChanged={handleDataChanged}
                />

                {isNotEmpty(estimationQuotation?.quotationType?.fields) &&
                    <>
                        <Customer estimationQuotation={estimationQuotation} handleDataChanged={handleDataChanged} />
                        <QuotationDescription estimationQuotation={estimationQuotation} handleDataChanged={handleDataChanged} />
                        <Details estimationQuotation={estimationQuotation} setEstimationQuotation={setEstimationQuotation} handleDataChanged={handleDataChanged} user={currentUser} />
                    </>
                }
            </div>
        </div>

    )

}

export default Tab1;