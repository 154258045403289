import React from "react";

function RecalculateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.9"
      height="21.5"
      viewBox="0 0 20.9 21.5"
    >
      <path
        fill="#292d32"
        d="M7.5 21.28l-3.11-3.11-3.11 3.11a.709.709 0 01-.53.221.742.742 0 01-.53-.221.754.754 0 010-1.059l3.11-3.11-3.1-3.1a.749.749 0 011.06-1.061l3.1 3.1 3.1-3.1a.75.75 0 011.06 1.06l-3.1 3.1 3.11 3.11a.754.754 0 010 1.059.747.747 0 01-1.06 0zm5.38-.39a.75.75 0 110-1.5h7.27a.75.75 0 110 1.5zm0-6.06a.75.75 0 010-1.5h7.27a.75.75 0 010 1.5zm2.91-6.81V5.14h-2.91a.75.75 0 010-1.5h2.91V.75a.75.75 0 011.5 0v2.891h2.86a.75.75 0 110 1.5h-2.86V8.02a.742.742 0 01-.75.75.755.755 0 01-.75-.75zM.75 5.14a.75.75 0 010-1.5h7.27a.754.754 0 01.75.75.747.747 0 01-.74.75z"
        data-name="Union 29"
      ></path>
    </svg>
  );
}

export default RecalculateIcon;
