
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM2877_ADDITIONAL_ITEMS_FORM_ID } from "../../util/constants/forms";
import AdditionalItemsDialog from "./dialogs";
import AdditionalItemsTable from "./listing";
import AdditionalItemsFilter from "./listing/Filters";

const AdditionalItems = () => {
    const { t } = useTranslation("additionalItems");

    const searchTextRef = useRef("");
    const filterRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();
    const _openFilter = () => {
        filterRef.current?.open();
    }

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM2877_ADDITIONAL_ITEMS_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => tableRef.current.refresh()}
                    />
                    <button className="headerPanelBtn" onClick={_openFilter}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>


            <AdditionalItemsTable
                filterRef={filterRef}
                dialogRef={dialogRef}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
            />

            <AdditionalItemsFilter
                ref={filterRef}
                tableRef={tableRef}
            />

            <AdditionalItemsDialog
                ref={dialogRef}
                tableRef={tableRef}
            />
        </>
    )
}
export default AdditionalItems;