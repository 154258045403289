import React from "react";

function PrinterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.504"
      height="27.017"
      data-name="Group 178580"
      viewBox="0 0 24.504 27.017"
    >
      <path
        fill="#668fff"
        d="M.192 7.418H12.13a.942.942 0 00.942-.942V3.962A4.809 4.809 0 0011.993.49 4.724 4.724 0 008.36-.75h-4.4A4.724 4.724 0 00.33.49 4.809 4.809 0 00-.75 3.962v2.513a.942.942 0 00.942.943zm11-1.885H1.135V3.962A3.176 3.176 0 011.7 1.779a3.034 3.034 0 012.258-.645h4.4a3.034 3.034 0 012.258.645 3.176 3.176 0 01.57 2.183z"
        opacity="0.4"
        transform="translate(6.09 .75)"
      ></path>
      <path
        fill="#668fff"
        d="M10.245-.75H.192A.942.942 0 00-.75.192v5.027c0 3.039 1.673 4.712 4.712 4.712h2.513c3.039 0 4.712-1.673 4.712-4.712V.192a.942.942 0 00-.942-.942zm-9.11 1.885H9.3v4.084c0 1.982-.846 2.827-2.827 2.827H3.962c-1.982 0-2.827-.846-2.827-2.827z"
        data-name="Vector"
        transform="translate(7.033 17.086)"
      ></path>
      <path
        fill="#668fff"
        d="M19.041-.75H3.962C.923-.75-.75.923-.75 3.962v6.283c0 3.039 1.673 4.712 4.712 4.712h2.513a.942.942 0 00.942-.942v-2.827h8.168v2.827a.942.942 0 00.942.942h2.513c3.039 0 4.712-1.674 4.712-4.712V3.962C23.753.923 22.08-.75 19.041-.75zM3.962 13.072c-1.982 0-2.827-.846-2.827-2.827V3.962c0-1.982.846-2.827 2.827-2.827h15.079c1.982 0 2.827.846 2.827 2.827v6.283c0 1.982-.846 2.827-2.827 2.827h-1.57v-2.827a.942.942 0 00-.942-.942H6.475a.942.942 0 00-.942.942v2.827z"
        data-name="Vector"
        transform="translate(.75 7.033)"
      ></path>
      <path
        fill="#668fff"
        d="M.192 1.135h12.566a.942.942 0 100-1.885H.192a.942.942 0 100 1.885z"
        data-name="Vector"
        transform="translate(5.776 17.086)"
      ></path>
      <path
        fill="#668fff"
        d="M.192 1.135h3.77a.942.942 0 100-1.885H.192a.942.942 0 000 1.885z"
        data-name="Vector"
        opacity="0.4"
        transform="translate(14.573 12.059)"
      ></path>
    </svg>
  );
}

export default PrinterIcon;
