import React from "react";

function InputsBtnVeiwIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <path d="M16 0H8C5.243 0 3 2.243 3 5v14c0 2.757 2.243 5 5 5h8c2.757 0 5-2.243 5-5V5c0-2.757-2.243-5-5-5zm3 19c0 1.654-1.346 3-3 3H8c-1.654 0-3-1.346-3-3V5c0-1.654 1.346-3 3-3h8c1.654 0 3 1.346 3 3v14zM17 6a1 1 0 01-1 1H8a1 1 0 110-2h8a1 1 0 011 1zm0 5a1 1 0 01-1 1H8a1 1 0 110-2h8a1 1 0 011 1zm-4 5a1 1 0 01-1 1H8a1 1 0 110-2h4a1 1 0 011 1z"></path>
    </svg>
  );
}

export default InputsBtnVeiwIcon;