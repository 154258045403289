import { createUUID, isAllowedForm, isEnglish } from "@ipgd-gauge/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { persistor } from "../store";
import { saveFormsFilter } from "../store/formsFilter/actions";
import { saveUserUsedForms } from "./apis";
import { FND0042_USERS_DEFINITION_FORM_ID, FND0133_USERS_LOGIN_LOG_FORM_ID, FND2319_CITIES_DEFINITION_FORM_ID, FND2621_TOWNS_DEFINITION_FORM_ID, FND3168_REPORTS_DESIGN_FORM_ID, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, RSM2659_PROPERTIES_TYPES_FORM_ID, RSM2661_PROPERTIES_USERS_FORM_ID, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, RSM2669_BRANCHES_DEFINITIONS_FORM_ID, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, RSM2877_ADDITIONAL_ITEMS_FORM_ID, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, RSM3007_PRICE_AGREEMENT_FORM_ID, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID, RSM3190_EVALUATION_QUOTATIONS_FORM_ID, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, RSM3230_VALUATION_TRANSACTIONS_FORM_ID, RSM3232_DELEGATION_FORM_ID, RSM3234_PROPERTIES_MAP_FORM_ID, RSM3236_VALUATION_HISTORY_FORM_ID, RSM3240_NOT_EXECUTED_TASKS_FORM_ID, RSM3242_TRANSACTIONS_STATISTICS_FORM_ID, RSM3244_TASKS_STATISTICS_FORM_ID, RSM3246_INBOX_FORM_ID, RSM3248_OUTBOX_FORM_ID, RSM3250_VALUATION_REPORTS_FORM_ID, RSM3252_TASKS_LIST_FORM_ID, RSM3266_IMPORTATNT_LINKS, RSM3268_CUSTOMER_DUES_FOLLOW_UP, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP, RSM3272_EVALUATION_CONTRACTS_FORM_ID, RSM3274_CONTROL_WITH_EXCEL_FIELDS, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "./constants/forms";

export const getFormId = () => {
    const url = window.location.pathname.replace('/', '');

    switch (url) {
        case 'not-executed-tasks':
            return RSM3240_NOT_EXECUTED_TASKS_FORM_ID;

        case 'transactions-statistics':
            return RSM3242_TRANSACTIONS_STATISTICS_FORM_ID;

        case 'tasks-statistics':
            return RSM3244_TASKS_STATISTICS_FORM_ID;
        case 'customer-dues-follow-up':
            return RSM3268_CUSTOMER_DUES_FOLLOW_UP;
        case 'inspectors-employees-dues-follow-up':
            return RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP;

        case 'estimation-transactions':
        case 'estimation-trx':
            return RSM3230_VALUATION_TRANSACTIONS_FORM_ID;

        case 'estimation-map':
            return RSM3234_PROPERTIES_MAP_FORM_ID;

        case 'estimation-quotations':
        case 'estimation-quotation':
            return RSM3190_EVALUATION_QUOTATIONS_FORM_ID;

        case 'price-agreements':
        case 'price-agreement':
            return RSM3007_PRICE_AGREEMENT_FORM_ID;

        case 'estimations-history':
            return RSM3236_VALUATION_HISTORY_FORM_ID;

        case 'delegation':
            return RSM3232_DELEGATION_FORM_ID;

        case 'inbox':
            return RSM3246_INBOX_FORM_ID;

        case 'outbox':
            return RSM3248_OUTBOX_FORM_ID;

        case 'estimation-fees-commissions-calculation':
            return RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID;

        case 'customers-estimation-invoices':
            return RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID;

        case 'customers-estimation-invoice':
            return RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID;

        case 'estimators-inspectors-invoices':
            return RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID;

        case 'estimators-inspectors-invoice':
            return RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID;

        case 'cities':
            return FND2319_CITIES_DEFINITION_FORM_ID;

        case 'towns':
            return FND2621_TOWNS_DEFINITION_FORM_ID;

        case 'customers':
            return RSM3009_CREATE_CUSTOMER_RSM_FORM_ID;

        case 'properties-types':
            return RSM2659_PROPERTIES_TYPES_FORM_ID;

        case 'reports-design':
            return FND3168_REPORTS_DESIGN_FORM_ID;

        case 'properties-parties-definition':
            return RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID;

        case 'users-definition':
            return FND0042_USERS_DEFINITION_FORM_ID;

        case 'evaluation-policies':
            return RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID;
        case 'evaluation-policy':
            return RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID;

        case 'properties-users-definition':
            return RSM2661_PROPERTIES_USERS_FORM_ID;

        case 'valuation-forms':
        case 'valuation-form':
            return RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID;

        case 'estimation-transactions-statuses':
            return RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID;

        case 'estimation-messages-templates':
            return RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID;

        case 'workflow-procedure-and-messages':
            return RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID;

        case 'properties-estimation-list-of-values':
            return RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID;

        case 'estimation-quotations-types':
            return RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID;

        case 'update-valuation-fields':
            return RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID;

        case 'tasks-list':
            return RSM3252_TASKS_LIST_FORM_ID;

        case 'users-login-log':
            return FND0133_USERS_LOGIN_LOG_FORM_ID;

        case 'estimation-reports':
            return RSM3250_VALUATION_REPORTS_FORM_ID;

        case 'estimation-invoices-register':
            return RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID;

        case 'estimation-invoices-follow-up':
            return RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID;

        case 'estimation-transactions-register':
            return RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID;

        case 'properties-valuation-custom-reports':
            return MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID;

        case 'inspectors-and-employees-categories':
            return RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID;

        case 'control-with-excel-fields':
            return RSM3274_CONTROL_WITH_EXCEL_FIELDS;

        case 'branches-definition':
            return RSM2669_BRANCHES_DEFINITIONS_FORM_ID;

        case 'division-definition':
            return RSM2667_DIVISIONS_DEFINITIONS_FORM_ID;
        case 'important-links':
            return RSM3266_IMPORTATNT_LINKS;
        case 'additional-items':
            return RSM2877_ADDITIONAL_ITEMS_FORM_ID

        case 'valuation-contract':
        case 'valuation-contracts':
            return RSM3272_EVALUATION_CONTRACTS_FORM_ID

        case 'credit-notes-for-customers-estimation':
        case 'credit-notes-for-customer-estimation':
            return RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID

        default:
            return null;
    }
}

export const getFormUrl = (formId, toListing) => {
    switch (formId) {
        case RSM3240_NOT_EXECUTED_TASKS_FORM_ID:
            return '/not-executed-tasks';
        case RSM3242_TRANSACTIONS_STATISTICS_FORM_ID:
            return '/transactions-statistics';
        case RSM3244_TASKS_STATISTICS_FORM_ID:
            return '/tasks-statistics';
        case RSM3268_CUSTOMER_DUES_FOLLOW_UP:
            return '/customer-dues-follow-up';
        case RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP:
            return '/inspectors-employees-dues-follow-up';
        case RSM3230_VALUATION_TRANSACTIONS_FORM_ID:
            if (toListing) {
                return '/estimation-transactions';
            }
            return '/estimation-trx';
        case RSM3234_PROPERTIES_MAP_FORM_ID:
            return '/estimation-map';
        case RSM3190_EVALUATION_QUOTATIONS_FORM_ID:
            if (toListing) {
                return '/estimation-quotations'
            }
            return '/estimation-quotation';
        case RSM3007_PRICE_AGREEMENT_FORM_ID:
            if (toListing) {
                return '/price-agreements';
            }
            return '/price-agreement';
        case RSM3236_VALUATION_HISTORY_FORM_ID:
            return '/estimations-history';
        case RSM3232_DELEGATION_FORM_ID:
            return '/delegation';
        case RSM3246_INBOX_FORM_ID:
            return '/inbox';
        case RSM3248_OUTBOX_FORM_ID:
            return '/outbox';

        case RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID:
            return '/estimation-fees-commissions-calculation';
        case RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID:
            if (toListing) {
                return '/customers-estimation-invoices';
            }
            return '/customers-estimation-invoice';

        case RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID:
            return '/estimators-inspectors-invoices';
        case FND2319_CITIES_DEFINITION_FORM_ID:
            return '/cities';
        case FND2621_TOWNS_DEFINITION_FORM_ID:
            return '/towns';
        case RSM3009_CREATE_CUSTOMER_RSM_FORM_ID:
            return '/customers';
        case RSM2659_PROPERTIES_TYPES_FORM_ID:
            return '/properties-types';
        case FND3168_REPORTS_DESIGN_FORM_ID:
            return '/reports-design';
        case RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID:
            return '/properties-parties-definition';
        case FND0042_USERS_DEFINITION_FORM_ID:
            return '/users-definition';
        case RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID:
            return '/evaluation-policies';
        case RSM2661_PROPERTIES_USERS_FORM_ID:
            return '/properties-users-definition';
        case RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID:
            if (toListing) {
                return '/valuation-forms'
            }
            return '/valuation-form';
        case RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID:
            return '/estimation-transactions-statuses';
        case RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID:
            return '/estimation-messages-templates';
        case RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID:
            return '/workflow-procedure-and-messages';
        case RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID:
            return '/properties-estimation-list-of-values';
        case RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID:
            return '/estimation-quotations-types';
        case RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID:
            return '/update-valuation-fields';
        case RSM3252_TASKS_LIST_FORM_ID:
            return '/tasks-list';
        case FND0133_USERS_LOGIN_LOG_FORM_ID:
            return '/users-login-log';
        case RSM3250_VALUATION_REPORTS_FORM_ID:
            return '/estimation-reports';
        case RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID:
            return '/estimation-invoices-register';
        case RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID:
            return '/estimation-invoices-follow-up';
        case RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID:
            return '/estimation-transactions-register';
        case MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID:
            return '/properties-valuation-custom-reports';
        case RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID:
            return '/inspectors-and-employees-categories'
        case RSM3274_CONTROL_WITH_EXCEL_FIELDS:
            return '/control-with-excel-fields'
        case RSM2669_BRANCHES_DEFINITIONS_FORM_ID:
            return '/branches-definition';
        case RSM2667_DIVISIONS_DEFINITIONS_FORM_ID:
            return '/division-definition';
        case RSM3266_IMPORTATNT_LINKS:
            return '/important-links';

        case RSM2877_ADDITIONAL_ITEMS_FORM_ID:
            return '/additional-items'

        case RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID:
            if (toListing) {
                return '/credit-notes-for-customers-estimation'
            }
            return '/credit-notes-for-customer-estimation';

        case RSM3272_EVALUATION_CONTRACTS_FORM_ID:
            if (toListing) {
                return '/valuation-contracts'
            }
            return '/valuation-contract';
        default:
            return null;
    }
}

export const goToForm = (user, dispatch, navigate, formId, queryParams, filters, postOpen, inNewTab, toListing) => {
    let formUrl = getFormUrl(formId, toListing);
    let key = null;
    if (isAllowedForm(user, formId)) {
        if (filters) {
            key = createUUID();
            let filterData = Object.assign({}, filters);
            dispatch(saveFormsFilter(`custom-filter-${key}`, filterData))
            persistor.flush()
        }
        let url = null;
        if (key) {
            url = `${formUrl}${queryParams ? `${queryParams}&sdid=${key}` : `?sdid=${key}`}`;
        } else {
            url = `${formUrl}${`${queryParams || ''}`}`;
        }
        if (inNewTab) {
            window.open(url, "_blank")
        } else {
            navigate(url)
        }
        if (postOpen) {
            postOpen();
        }

        saveUserUsedForms(formId)
            .catch(() => { });
    } else {
        const MySwal = withReactContent(Swal);
        return MySwal.fire({
            title: isEnglish() ? "unauthorized to view this page" : "غير مصرح لك عرض هذه الصفحة",
            icon: 'error',
            focusConfirm: false,
            focusCancel: false,
            confirmButtonText: isEnglish() ? "Ok" : "موافق",
            customClass: {
                title: isEnglish() ? 'swal2-title-en' : 'swal2-title-ar',
                actions: isEnglish() ? 'swal2-actions-panel-en' : 'swal2-actions-panel-ar',
                cancelButton: isEnglish() ? 'swal2-cancel-button swal2-cancel-en' : 'swal2-cancel-button swal2-cancel-ar',
                confirmButton: isEnglish() ? 'swal2-confirm-en' : 'swal2-confirm-ar',
                denyButton: isEnglish() ? 'swal2-deny-en' : 'swal2-deny-ar',
            },
        })
    }
}

export const isTrnxForm = (path) => {
    return path?.pathname === "/estimation-trx" || path?.pathname === "/customers-estimation-invoice" || path?.pathname === "/estimators-inspectors-invoice" || path?.pathname === "/estimation-fees-commissions-calculation"
        || path?.pathname === "/trx-summary" || path?.pathname === "/profile" || path?.pathname === "/estimation-quotation" || path?.pathname === "/evaluation-policie" || path?.pathname === "/price-agreement"
}