import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";

const STATUS_GO = btoa("go-to-form");
const STATUS_BACK = btoa("back-from-form");
const STATUS_CANCELED = btoa("canceled");

export const useCalleeForm = (sourceFormUrl, targetFormUrl, callBack) => {

    const dataKey = btoa(`${sourceFormUrl}-${targetFormUrl}`)
    const statusKey = btoa(`${sourceFormUrl}-${targetFormUrl}-status`)
    const dataRef = useRef(null);

    const path = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem(dataKey));
        const status = sessionStorage.getItem(statusKey);
        if (status == STATUS_BACK || (sourceFormUrl == path.pathname && (data || Object.keys(data || {}).length > 0)) || status == STATUS_CANCELED) {
            if (status == STATUS_BACK) {
                dataRef.current = data;
                if (callBack && typeof callBack === "function") {
                    callBack(data)
                }
            }
            sessionStorage.removeItem(dataKey);
            sessionStorage.removeItem(statusKey);
        }
    }, [])

    const getData = () => {
        if (dataRef.current) {
            return dataRef.current
        }
        return JSON.parse(sessionStorage.getItem(dataKey));
    }

    const isCallee = () => {
        const status = sessionStorage.getItem(statusKey);
        return targetFormUrl.includes(path.pathname) && status == STATUS_GO;
    }

    const goToSourceForm = (data) => {
        sessionStorage.setItem(dataKey, JSON.stringify(data || {}));
        sessionStorage.setItem(statusKey, STATUS_BACK);
        navigate(sourceFormUrl)
    }

    const goToTargetForm = (data) => {
        sessionStorage.setItem(dataKey, JSON.stringify(data || {}));
        sessionStorage.setItem(statusKey, STATUS_GO);
        navigate(targetFormUrl)
    }

    const cancelCallee = () => {
        sessionStorage.setItem(statusKey, STATUS_CANCELED);
    }

    return { getData, isCallee, goToSourceForm, goToTargetForm, cancelCallee }
}