import React from "react";

function LeftArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.098"
      height="20.197"
      viewBox="0 0 11.098 20.197"
    >
      <path
        fill="none"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.684 1.414l-8.683 8.679 8.684 8.684"
        data-name="Path 43652"
      ></path>
    </svg>
  );
}

export default LeftArrow;