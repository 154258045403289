import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import EstimationsHistoryIcon from "../../../icons/EstimationsHistory";
import { getCurrentTime, getEstimationsHistory, saveEstimationsHistory } from "../../../util/apis";
import { LIMIT_LAT, LIMIT_LNG } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3236_VALUATION_HISTORY_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import style from './style.module.scss';

const dialogId = "estimations-history-dialog";

const EstimationsHistoryDialog = (props, ref) => {
    const { user, tableRef } = props

    const { t } = useTranslation();

    const dialogRef = useRef();
    const mapRef = useRef();
    const imageRef = useRef();
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);

    const [state, setState] = useState({});

    const _newAction = async () => {
        imageRef?.current?.setImages([]);
        const currentTime = (await getCurrentTime()).data;
        setState({ entryDateTime: currentTime })
    }

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }

        showWaiting(`${dialogId}-btn`, true);
        saveEstimationsHistory(state)
            .then(async (response) => {
                await imageRef.current.uploadImages(response.data.id);
                showSuccessMessage('save_successfully', t);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t);
            })
            .finally(() => hideWaiting(`${dialogId}-btn`));
    }

    const _handleDataChanged = (name, value) => {

        if (name === "coordinates") {
            let longitude = value.lng;
            let latitude = value.lat;

            if (Math.abs(longitude) > LIMIT_LNG) {
                longitude = (longitude < 0 ? -1 : 1) * LIMIT_LNG
            }

            if (Math.abs(latitude) > LIMIT_LAT) {
                latitude = (latitude < 0 ? -1 : 1) * LIMIT_LAT
            }

            longitude = Number(longitude).toFixed("6")
            latitude = Number(latitude).toFixed("6")

            state.longitude = longitude;
            state.latitude = latitude;
        }

        if (name == "ecdId") {
            state.criId = null
        }

        state[name] = value;
        setState({ ...state });
    }

    const _postClose = () => {
        updateRecordRef.current = false;
        setState({});
        tableRef?.current?.refresh();
        tabsRef.current.changeTab(1);
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _populateRecordData = (id) => {
        if (id) {
            updateRecordRef.current = true;
            getEstimationsHistory(id)
                .then((response) => setState(response.data))
                .catch((error) => handleError(error, null, t))
        } else {
            updateRecordRef.current = false;
            _newAction();
        }
    }

    const open = (id) => {
        dialogRef.current.open();
        _populateRecordData(id);
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            title={t('estimationsHistory:estimations_history')}
            formId={RSM3236_VALUATION_HISTORY_FORM_ID}
            icon={<EstimationsHistoryIcon />}
            footerLbl={t("save")}
            footerAction={_saveAction}
            nextPreviousAction={_populateRecordData}
            postClose={_postClose}
            withFooter={isAllowedAction(user, RSM3236_VALUATION_HISTORY_FORM_ID, SAVE_ACTION)}
            className={style.estimationsHistorySidePanel}
        >
            <Tabs
                id={`${dialogId}-tabs`}
                ref={tabsRef}
                labels={['estimationsHistory:home', 'estimationsHistory:coordinates', 'estimationsHistory:attachments']}
                tabContentStyle={style.tabContentStyle}
                tabsButtonCustomStyle={style.estimationsTabs}
            >
                <Tab1 state={state} handleDataChanged={_handleDataChanged} />
                <Tab2 state={state} handleDataChanged={_handleDataChanged} mapRef={mapRef} />
                <Tab3 id={state?.id} imageRef={imageRef} />
            </Tabs>
        </SidePanel>
    )
}

export default forwardRef(EstimationsHistoryDialog);