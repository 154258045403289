import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import { getPoliciesList } from "../../../util/apis";
import { PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID, PROPERTIES_USERS_ESTIMATION_POLICY_FORM_ID, STATUS_ACTIVE } from "../../../util/constants";

const dialogId = "properties-users-definition-dialog";

const Tab4 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('propertiesUsersDefinition');

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 4 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label className='form-label'>{t('estimation_policy')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-estimation-policy`}
                            key={`${dialogId}-estimation-policy-${data.id || ''}`}
                            value={data?.estimationLsoId}
                            isClearable={true}
                            api={() => getPoliciesList(PROPERTIES_USERS_ESTIMATION_POLICY_FORM_ID, data?.estimationLsoId, STATUS_ACTIVE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("estimationLsoId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('estimation_invoice_policy')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-estimation-invoice-policy`}
                            key={`${dialogId}-estimation-invoice-policy-${data.id || ''}`}
                            value={data?.estimationInvoiceLsoId}
                            isClearable={true}
                            api={() => getPoliciesList(PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID, data?.estimationInvoiceLsoId, STATUS_ACTIVE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("estimationInvoiceLsoId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    {/* <div className="w50">
                        <label className='form-label'>{t('estimation_quotations_policy')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-valuation-default-values`}
                            value={data?.estQuotationLsoId}
                            isClearable={true}
                            api={() => getPoliciesList(PROPERTIES_USERS_ESTIMATION_QUOTATIONS_POLICY_FORM_ID, data?.estQuotationLsoId, STATUS_ACTIVE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("estQuotationLsoId", value.value)}
                            portalTarget={document.body}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Tab4;