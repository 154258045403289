import Checkbox from "@ipgd-gauge/checkbox";
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import Modal from "../../../components/modal";
import { getEstimationForm, saveEstimationForm } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID } from "../../../util/constants/forms";
import EstimationFormsFieldsBuilderIcon from "../../../icons/EstimationFormsFieldsBuilder";
import style from './style.module.scss';

const dialogId = "estimation-forms-fields-builder-dialog";

const EstimationFormsFieldsBuilderDialog = (props, ref) => {
    const { tableRef, user } = props;
    const { t } = useTranslation();
    const dialogRef = useRef();
    const defaultData = { isActive: STATUS_ACTIVE }

    const [data, setData] = useState({});

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return;
        }
        showWaiting(`${dialogId}-okButton`, true)
        saveEstimationForm(data)
            .then(() => {
                close();
                showSuccessMessage("save_successfully", t);
                tableRef?.current?.refresh();
            })
            .catch((error) => handleError(null, error?.response?.data?.message.split("#")[0], t))
            .finally(e => {
                document.getElementById("modal_save_btn").removeAttribute("disabled")
            })
            .finally(() => hideWaiting(`${dialogId}-okButton`));
    }

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _postClose = () => {
        setData({})
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            getEstimationForm(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setData({ ...defaultData })
        }
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <Modal
            id={dialogId}
            ref={dialogRef}
            title={
                <>
                    <EstimationFormsFieldsBuilderIcon />
                    {t('add')}
                </>
            }
            withFooter
            withOkButton
            okBtnLabel={t("save")}
            okBtnAction={_saveAction}
            okBtnStyleAction="primary-button"
            withCancelButton
            cancelBtnLabel={t('exit')}
            cancelBtnStyleAction="cancel-button"
            postClose={_postClose}
            hideOkBtn={!(isAllowedAction(user, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, SAVE_ACTION))}
            nextPreviousAction={_populateRecordData}
            smallSize
        >
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id={`${dialogId}-code`}
                            value={data.code}
                            onBlur={(value) => _handleDataChanged("code", value)}
                            column="FNO_CODE"
                            formId={RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label mandatory' id={`${dialogId}-status-lbl`}> {t('status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChanged("descFo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label' id={`${dialogId}-remarks-lbl`}>{t('estimationFormsFieldsBuilder:remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-remarks`}
                            value={data?.rem}
                            onBlur={value => _handleDataChanged("rem", value)}
                        />
                    </div>

                    <div className="w100">
                        <Checkbox
                            id={`${dialogId}-new-remain-method`}
                            label={t('estimationFormsFieldsBuilder:test')}
                            isChecked={data?.newRemainMethod}
                            setIsChecked={value => _handleDataChanged("newRemainMethod", value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(EstimationFormsFieldsBuilderDialog);