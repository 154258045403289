import BackToTopArrowIcon from '../../../../icons/BackToTopArrow';
import style from './style.module.scss';

const BackToTop = (props) => {
    const { containerRef } = props;
    
    const scrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    };

    return (
        <button className={style.backToTop} id='toTop' onClick={scrollToTop}>
            <BackToTopArrowIcon />
        </button>
    )
}
export default BackToTop;