import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isNotEmpty } from '@ipgd-gauge/utils';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getEstTrxLovData } from "../../../../util/apis";
import { DATA_TYPE_MULTI_LOV } from "../../../../util/constants";
import DetailsCard from "./Card";

const ASYNC = ["TRI_ECD_ID", "TRI_CRI_ID", "TRI_ATR_ID", "LDI_SELF_CONST_PARENT_TRI_ID"];
const MULTI = ["MTA_ASSUMPTIONS_1"];

const SelectMenu = (props) => {
    const { feild, handleChange, inCard, inTable, getTrxKey, displayMultiLines, className } = props;
    const isMulti = feild.dataType == DATA_TYPE_MULTI_LOV;

    const value = useSelector(state => {
        let data = state.estTrnxReducer.data[feild.internalName];
        if (isMulti) {
            return data ? data.split(",") : null
        }
        return data
    });
    const key = useSelector(state => state.estTrnxReducer.data[`${feild.internalName}_KEY`]);

    const firstTimeRenderRef = useRef(true);

    const data = {
        fieldName: feild.internalName,
        key: getTrxKey(),
    }

    const _onChangeValue = (e) => {
        if (isMulti) {
            handleChange(feild.internalName, e?.length > 0 ? e.join(",") : null)
        } else {
            handleChange(feild.internalName, e.value)
        }
    }

    return (
        <DetailsCard feild={feild} inCard={inCard} inTable={inTable}>
            <div>
                <FrmSelectMenu
                    id={`${feild.internalName}_id`}
                    value={value}
                    key={key || feild.internalName}
                    isAsync={ASYNC.includes(feild.internalName)}
                    api={(searchValue) => getEstTrxLovData({ ...data, searchValue })}
                    defualtValueId={value}
                    defualtValueApi={(searchValue, ids) => getEstTrxLovData({ ...data, searchValue, ids: Array.isArray(ids) ? ids : [ids] })}
                    descLo={['descLo']}
                    descFo={['descFo']}
                    onChange={_onChangeValue}
                    portalTarget={document.body}
                    isDisabled={!feild.enabled}
                    isMulti={isMulti}
                    className={className}
                    displayMultiLines={displayMultiLines}
                    onDataRetrieved={(list) => {
                        if (firstTimeRenderRef.current && feild.internalName === "TRI_SIGNATURE_APR_ID" && list?.length === 1 && isNotEmpty(value)) {
                            handleChange(feild.internalName, list[0].id)
                        }
                        if (firstTimeRenderRef.current) {
                            firstTimeRenderRef.current = false;
                        }
                    }}
                    isClearable
                />
            </div>
        </DetailsCard>
    )
}

export default SelectMenu;