export const TransactionsNumberDashOne = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            data-name="Layer 1"
            viewBox="0 0 24 24"
        >
            <path d="M14 7V.46a6.977 6.977 0 012.465 1.59l3.484 3.486A6.954 6.954 0 0121.54 8H15a1 1 0 01-1-1zm8 3.485V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515c.163 0 .324.013.485.024V7a3 3 0 003 3h6.976c.011.161.024.322.024.485zM14 19a1 1 0 00-1-1H8a1 1 0 000 2h5a1 1 0 001-1zm3-4a1 1 0 00-1-1H8a1 1 0 000 2h8a1 1 0 001-1z"></path>
        </svg>
    )
}
export const TransactionsNumberDashTwo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.96 87.84">
            <path d="M60.7 72.16v4.02a1.05 1.05 0 11-2.1 0v-4.02h-4.44v7a1.05 1.05 0 11-2.1 0v-7h-4.45v4.02a1.05 1.05 0 11-2.1 0v-4.03h-4.44v7a1.05 1.05 0 11-2.1 0v-7h-4.44v4.02c-.02.58-.5 1.04-1.09 1.02-.56-.02-1-.46-1.02-1.02v-4.02h-4.44v7a1.05 1.05 0 11-2.1 0v-7h-4.45v4.02a1.05 1.05 0 11-2.1 0v-4.02h-2.55c-.58 0-1.05-.47-1.05-1.05v-3.84h-3.77a1.05 1.05 0 110-2.1h3.77v-4.44H8.98a1.05 1.05 0 110-2.1h6.75v-4.45h-3.77a1.05 1.05 0 110-2.1h3.77v-4.44H8.98a1.05 1.05 0 110-2.1h6.75v-4.44h-3.77a1.05 1.05 0 110-2.1h3.77v-4.44H8.98a1.05 1.05 0 110-2.1h6.75v-4.44h-3.77a1.05 1.05 0 110-2.1h3.77v-4.44H8.98c-.58-.02-1.04-.5-1.02-1.09.02-.56.46-1 1.02-1.02h6.75v-4.44h-3.77a1.05 1.05 0 110-2.1h3.77V8.38H8.98a1.05 1.05 0 110-2.1h6.75V0H0v87.84h63.96V72.16H60.7zm1.48-12.62c.42-.4 1.09-.38 1.49.04.38.41.38 1.04 0 1.45l-3.27 3.28s-.07.07-.11.1-.04.03-.07.05-.04.03-.07.04c-.32.18-.71.18-1.03 0s-.04-.03-.07-.04l-.06-.05s-.08-.06-.11-.1l-3.28-3.28c-.42-.41-.42-1.07-.02-1.49.41-.42 1.07-.42 1.49-.02l.02.02 1.48 1.48V14.86h-31.1l1.49 1.48c.42.41.42 1.07.02 1.49-.41.42-1.07.42-1.49.02l-.02-.02-3.28-3.27s-.07-.07-.1-.11-.03-.04-.05-.06-.03-.04-.04-.07a1.03 1.03 0 010-1.02s.03-.05.04-.07.03-.04.05-.06.06-.07.1-.11l3.28-3.28c.42-.4 1.09-.38 1.49.04.38.41.38 1.04 0 1.45l-1.49 1.49h32.15c.58 0 1.05.47 1.05 1.05v47.22l1.49-1.48z"></path>
        </svg>
    )
}
export const TransactionsNumberDashThree = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 24 24"
        >
            <path d="M21.841 10H20.49a8.969 8.969 0 01-.467 1.086L20.855 16h-5.186l-.929.909.639 5.091H8.623l.638-5.09-.93-.91H3.146l.874-4.841A8.814 8.814 0 013.515 10H2.159L.015 22.329 0 24h24v-1.5L21.841 10zM6.609 22H2.102l.696-4H7.07l-.462 4zm10.782 0l-.462-4h4.273l.696 4h-4.507zM12 16.791l4.949-4.841C18.271 10.628 19 8.87 19 7s-.729-3.627-2.051-4.95C15.628.728 13.87 0 12 0S8.373.728 7.05 2.05c-2.729 2.729-2.729 7.17.008 9.907L12 16.79zM8.464 3.464C9.408 2.52 10.664 2 12 2s2.591.52 3.535 1.464S17 5.664 17 7s-.521 2.591-1.457 3.528L12 13.994l-3.536-3.458a5.006 5.006 0 010-7.071zm.535 3.526a3 3 0 116 0 3 3 0 01-6 0z"></path>
        </svg>
    )
}
export const TransactionsNumberDashFour = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            data-name="Layer 1"
            viewBox="0 0 24 24"
        >
            <path d="M12 0C5.383 0 0 5.383 0 12c.603 15.898 23.4 15.894 24 0 0-6.617-5.383-12-12-12zm1 17v1c-.005 1.308-1.995 1.307-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.002c.179.31.511.501.867.501h2.268c.997.065 1.443-1.55.356-1.76l-3.041-.507C6.812 12.084 7.566 6.98 10.999 7V6c.006-1.308 1.995-1.307 2 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.997-.065-1.442 1.55-.356 1.76l3.041.507c3.502.649 2.748 5.753-.685 5.733z"></path>
        </svg>
    )
}
