import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import { getPropertyPartyCategory, savePropertyPartyCategory } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import style from './style.module.scss';

const dialogId = "properties-parties-categories-dialog";

const InspectorsAndEmployeesCategoriesDialog = (props, ref) => {
    const { tableRef, user } = props;

    const { t } = useTranslation();

    const [data, setData] = useState({});

    const dialogRef = useRef();
    const updateRecordRef = useRef(false);

    const _handleDataChanged = (name, value) => {
        data[name] = value;

        if (name === "general" && value) {
            data.organizations = [];
        }

        setData({ ...data });
    }

    const close = () => {
        dialogRef?.current?.close();
    }

    const _newAction = () => {
        let newData = {
            isActive: STATUS_ACTIVE,
            general: false,
            organizations: [
                {
                    fogId: user?.fogId,
                    offecialNameLo: user?.orgNameLo,
                    offecialNameFo: user?.orgNameFo,
                    organizationCode: user?.orgCode
                }
            ],
            financialIntegration: []
        }

        setData({ ...newData });
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getPropertyPartyCategory(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction();
        }
    }

    const open = (id) => {
        dialogRef?.current?.open();
        _populateRecordData(id)
    }

    const _okBtnAction = () => {
        if (!checkValidation([{ id: dialogId }, {
            id: `financial-integration-table`,
            title: t('propertiesPartiesCategories:financial_integration'),
            data: data.financialIntegration,
        }], t)) {
            return
        }
        showWaiting(`${dialogId}-okButton`, true)
        savePropertyPartyCategory(data)
            .then(() => {
                showSuccessMessage('save_successfully', t);
                dialogRef.current.changeSelectedTab(1);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
            })
            .catch((error) => handleError(null, error?.response?.data?.message?.split("#")[0], t))
            .finally(() => hideWaiting(`${dialogId}-okButton`));
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false
        tableRef?.current?.refresh()
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <ModalWithTabs
            id={dialogId}
            ref={dialogRef}
            title={'propertiesPartiesCategories:inspectors_and_employees_categories'}
            labels={['home#1', 'propertiesPartiesCategories:organization_list#1', 'propertiesPartiesCategories:financial_integration']}
            withFooter="true"
            withOkButton="true"
            withCancelButton="true"
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            okBtnAction={_okBtnAction}
            preClose={_preCloseAction}
            postClose={_newAction}
            formId={RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID}
            className={style.propertiesPartiesCategoriesDialog}
            nextPreviousAction={(id) => _populateRecordData(id)}
            hideOkBtn={!isAllowedAction(user, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, SAVE_ACTION)}
        >
            <Tab1 data={data} handleDataChanged={_handleDataChanged} />
            <Tab2 data={data} setData={setData} handleDataChanged={_handleDataChanged} />
            <Tab3 data={data} setData={setData} />
        </ModalWithTabs>
    );
}

export default forwardRef(InspectorsAndEmployeesCategoriesDialog);