import Checkbox from "@ipgd-gauge/checkbox";
import { isEnglish } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import { RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleDataChange, tableId } = rowActions;

    const { t } = useTranslation();
    return (
        <div
            dir={i18next.language === 'en' ? "ltr" : "rtl"} className="v_table_row" style={virtualizedStyle}>
            <div> <div> <label>{data?.sequence}</label></div></div>
            <div> <div> <label>{isEnglish() ? data?.blockDescLo : data?.blockDescFo}</label> </div></div>
            <div> <div> <label>{isEnglish() ? data?.fieldDescLo : data?.fieldDescFo}</label></div></div>
            <div> <div> <label>{isEnglish() ? data?.fieldUserDescLo : data?.fieldUserDescFo}</label> </div></div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-show-in-estimation-${index}`}
                        isChecked={data?.showInEstimation}
                        setIsChecked={value => handleDataChange(index, "showInEstimation", value)}
                        disabled={data.isSystemField}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:inspection_estimation_visible')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-optional-estimation-${index}`}
                        isChecked={data?.isOptionalEstimation}
                        setIsChecked={value => handleDataChange(index, "isOptionalEstimation", value)}
                        disabled={!data?.showInEstimation || !data.systemIsOptional}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:inspection_estimation_optional')}
                    />
                </div>
            </div>
            <div>
                <div className={style.checkAll}>
                    <Checkbox
                        id={`${tableId}-disabled-estimation-${index}`}
                        isChecked={data?.isDisabledEstimation}
                        setIsChecked={value => handleDataChange(index, "isDisabledEstimation", value)}
                        disabled={!data?.showInEstimation || data.systemIsDisabled}
                        styleClass={style.tableCheckBox}
                        label={t('estimationFormsFieldsBuilder:inspection_estimation_disabled')}
                    />
                </div>
            </div>
        </div>
    );
};

const Tab2 = (props) => {
    const { tab, user, fields, setFields, handleDataChange, isSelected } = props;

    const tableId = "estimation-forms-inspection-settings";

    const { t } = useTranslation();

    const _handleChangeAllLines = (name, value) => {
        for (let index = 0; index < fields.length; index++) {

            if (name == "showInEstimation" && !Boolean(fields[index].isSystemField)) {
                fields[index][name] = value;
            }

            if (name == "showInEstimation" && !value) {
                if (Boolean(fields[index].systemIsOptional)) {
                    fields[index].isOptionalEstimation = false;
                }
                if (!Boolean(fields[index].systemIsDisabled)) {
                    fields[index].isDisabledEstimation = false;
                }
            }

            if (name == "isDisabledEstimation" && !Boolean(fields[index].systemIsDisabled)) {
                if (!fields[index].showInEstimation) {
                    fields[index][name] = false;
                } else {
                    fields[index][name] = value;
                }
            }

            if (name == "isOptionalEstimation" && Boolean(fields[index].systemIsOptional) && fields[index].showInEstimation) {
                fields[index][name] = value;
            }

        }
        setFields([...fields]);
    }

    const columns = [
        { title: '#', width: "4%" },
        { title: t('estimationFormsFieldsBuilder:block_name'), width: "17%" },
        { title: t('estimationFormsFieldsBuilder:field_user_name_a'), width: "17%" },
        { title: t('estimationFormsFieldsBuilder:description'), width: "17%" },
        {
            title:
                <Checkbox
                    id={`${tableId}-show-in-estimation-all`}
                    label={t('estimationFormsFieldsBuilder:inspection_estimation_visible')}
                    isChecked={isSelected("showInEstimation")}
                    setIsChecked={value => _handleChangeAllLines("showInEstimation", value)}
                />, width: "15%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-optional-estimation-all`}
                    label={t('estimationFormsFieldsBuilder:inspection_estimation_optional')}
                    isChecked={isSelected("isOptionalEstimation")}
                    setIsChecked={value => _handleChangeAllLines("isOptionalEstimation", value)}
                />, width: "15%"
        },
        {
            title:
                <Checkbox
                    id={`${tableId}-disabled-estimation-all`}
                    label={t('estimationFormsFieldsBuilder:inspection_estimation_disabled')}
                    isChecked={isSelected("isDisabledEstimation")}
                    setIsChecked={value => _handleChangeAllLines("isDisabledEstimation", value)}
                />, width: "15%"
        },
    ]

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <Table
                id={tableId}
                columns={columns}
                user={user}
                formId={RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID}
                Row={Row}
                tableStyle={style.valuationFormTable}
                rowActions={{ handleDataChange: handleDataChange, tableId: tableId }}
                rowHeight={40}
                list={fields}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
            />
        </div>
    );
};

export default Tab2;