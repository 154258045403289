import { deleteUrlParam, getUrlParam, handleError, isNotEmpty } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import XIcon from "../../../icons/X";
import { getPropertyPolicy } from "../../../util/apis";
import { CUSTOMER_LEVEL_NONE, STATUS_ACTIVE } from "../../../util/constants";
import { getUser } from "../../../util/cookies";
import { closeSummary } from "../../../util/util";
import Actions from "./actions";
import Lines from "./lines";
import PropertiesPolicyReducer, { ACTION_SET_PROPERTIES_POLICY } from "./lines/reducer";
import style from './style.module.scss';
import Summary from "./summary";

const EvaluationPolicy = () => {

    const user = getUser();
    const ppid = getUrlParam('id');

    const { t } = useTranslation();
    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);

    const DEFAULT_VALUE = {
        isActive: STATUS_ACTIVE,
        general: false,
        allBranches: CUSTOMER_LEVEL_NONE,
        allDivisoions: CUSTOMER_LEVEL_NONE,
        allPropTypes: CUSTOMER_LEVEL_NONE,
        allRegions: CUSTOMER_LEVEL_NONE,
        allCities: CUSTOMER_LEVEL_NONE,
        allPropPartyCats: CUSTOMER_LEVEL_NONE,
        allPropParties: CUSTOMER_LEVEL_NONE,
        allCustCats: CUSTOMER_LEVEL_NONE,
        allCustomers: CUSTOMER_LEVEL_NONE,
        allEstimationForms: CUSTOMER_LEVEL_NONE,
        allEstimationFormSec: CUSTOMER_LEVEL_NONE,
        allEstimationStatuses: CUSTOMER_LEVEL_NONE,
        organizations: [
            {
                fogId: user?.fogId,
                offecialNameLo: user?.orgNameLo,
                offecialNameFo: user?.orgNameFo,
                organizationCode: user?.orgCode
            }
        ],
        restrictionPolicy: {
            optn117201: false,
            optn117207: false,
            optn117220: false,
            optn117217: false,
            optn117213: false,
            optn117215: false,
            optn117225: false,
            optn117226: false
        }
    }

    const [propertyPolicy, dispatchPropertyPolicy] = useReducer(PropertiesPolicyReducer, { ...DEFAULT_VALUE });

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromNewActionRef.current = false;
            return;
        }

        if (isNotEmpty(ppid)) {
            getPropertyPolicy(ppid)
                .then((response) => dispatchPropertyPolicy({ type: ACTION_SET_PROPERTIES_POLICY, value: response.data }))
                .catch((error) => {
                    handleError(error, null, t);
                    _newAction();
                });
        } else {
            _newAction();
        }
    }, [ppid]);

    const _newAction = () => {
        fromNewActionRef.current = true;
        dispatchPropertyPolicy({ type: ACTION_SET_PROPERTIES_POLICY, value: { ...DEFAULT_VALUE } });
        deleteUrlParam("id");
    }

    return (
        <>
            <div className={style.container}>
                <div className={style.linesContainer}>
                    <Lines user={user} data={propertyPolicy} dispatchPropertyPolicy={dispatchPropertyPolicy} />
                </div>
                <div id='evaluation-policies-summary' className="mobileSummary">
                    <span className='customHeaderSummarySm'>
                        <label>{t('summary')}</label>
                        <button onClick={() => closeSummary('evaluation-policies-summary')}> <XIcon /> </button>
                    </span>
                    <Summary propertyPolicy={propertyPolicy} />
                </div>
                <div id='summary-bg-mobile' onClick={() => closeSummary('evaluation-policies-summary')} className='mobileBg'></div>
            </div>

            <Actions
                user={user}
                propertyPolicy={propertyPolicy}
                dispatchPropertyPolicy={dispatchPropertyPolicy}
                newAction={_newAction}
                fromSaveActionRef={fromSaveActionRef}
            />
        </>
    );
}

export default EvaluationPolicy;