import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, isAllowedAction, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import EditTableIcon from "../../../icons/EditTable";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import ViewIcon from "../../../icons/View";
import { confirmEstimatorInvoice, deleteEstimatorInvoice, rejectEstimatorInvoice, unpostEstimatorInvoice } from "../../../util/apis";
import { GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, PRINT_ACTION, REJECT_ACTION, UN_POST_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";

const Actions = (props) => {
    const { user, data, tableRef, printDialogRef } = props;

    const { t } = useTranslation();
    const [actions, setActions] = useState([]);
    const navigate = useNavigate();

    const _deleteInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimatorInvoice(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => {
                    handleError(error, null, t);
                })
        });
    }

    const _rejectInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectEstimatorInvoice(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage(null, t);
                })
                .catch((error) => {
                    handleError(error, null, t);
                })
        });
    }

    const _confirmInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return confirmEstimatorInvoice(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage(null, t);
                })
                .catch((error) => {
                    let message = error?.response?.data?.message;
                    if (message.includes('#')) {
                        message = message.split('#')[0]
                    }
                    handleError(error, message, t)
                })
        });
    }

    const _unpostInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return unpostEstimatorInvoice(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage(null, t);
                })
                .catch((error) => {
                    handleError(error, null, t);
                })
        });
    }

    const _getActions = () => {
        let actions = [];

        if (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, VIEW_EDIT_ACTION)) {
            if (data.globalStatus == GLOBAL_STATUS_REJECTED || data.globalStatus == GLOBAL_STATUS_CONFIRMED) {
                actions.push(
                    {
                        label: t('view'),
                        action: () => navigate(`/estimators-inspectors-invoice?id=${data.id}`),
                        icon: <ViewIcon />,
                    }
                )
            } else {
                actions.push(
                    {
                        label: t('edit'),
                        action: () => navigate(`/estimators-inspectors-invoice?id=${data.id}`),
                        icon: <EditTableIcon />,
                    }
                )
            }
        }

        if (data.globalStatus == GLOBAL_STATUS_INCOMPLETE && (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, DELETE_ACTION))) {
            actions.push(
                {
                    label: t('estimatorsInspectorsInvoices:delete'),
                    color: "var(--action-delete-bg)",
                    iconColor: "var(--action-delete-bg)",
                    action: _deleteInvoice,
                    icon: <DeleteTowIcon />,
                }
            )
        }

        if (data.globalStatus == GLOBAL_STATUS_SUBMITTED) {
            if (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, REJECT_ACTION)) {
                actions.push(
                    {
                        label: t('estimatorsInspectorsInvoices:reject'),
                        action: _rejectInvoice,
                        icon: <RejectIcon />,
                    }
                )
            }
            if (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, CONFIRM_FORM_ACTION)) {
                actions.push(
                    {
                        label: t('estimatorsInspectorsInvoices:confirm'),
                        action: _confirmInvoice,
                        icon: <ApproveIcon />,
                    }
                )
            }
        }
        if (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, PRINT_ACTION)) {
            actions.push(
                {
                    label: t('estimatorsInspectorsInvoices:print'),
                    action: () => printDialogRef.current?.open([data?.id]),
                    icon: <PrinterIcon />
                }
            )
        }
        // if (data.globalStatus == GLOBAL_STATUS_CONFIRMED && isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, UN_POST_ACTION)) {
        //     actions.push(
        //         {
        //             label: t('estimatorsInspectorsInvoices:unpost'),
        //             action: _unpostInvoice,
        //         }
        //     )
        // }

        setActions(actions);
    }

    useEffect(() => {
        _getActions();
    }, [data.id, data.globalStatus])

    return (
        <TableActionsButton defaultIndex={0} buttons={actions} />
    )
}

export default Actions;