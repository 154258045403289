import React from "react";

function StatusDashboardIconIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.19"
      height="17.19"
      viewBox="0 0 17.19 17.19"
    >
      <g data-name="Group 179872" transform="translate(-1129.108 -319.828)">
        <g
          fill="#ff2b2b"
          data-name="Group 178423"
          transform="translate(1129.108 319.828)"
        >
          <path
            d="M7.845 16.44A8.586 8.586 0 011.774 1.774a8.586 8.586 0 1112.142 12.142 8.534 8.534 0 01-6.071 2.524zm0-15.991a7.4 7.4 0 107.4 7.4 7.4 7.4 0 00-7.4-7.4z"
            transform="translate(.75 .75)"
          ></path>
          <circle
            cx="8"
            cy="8"
            r="8"
            data-name="Ellipse 179"
            transform="translate(.332 .172)"
          ></circle>
        </g>
        <path
          fill="#fff"
          d="M4.7 5.55L2.863 3.711 1.025 5.55a.6.6 0 01-.848 0 .6.6 0 010-.848l1.838-1.839-1.838-1.84a.6.6 0 01.848-.848l1.838 1.839L4.7.175a.6.6 0 01.848.848l-1.837 1.84L5.549 4.7a.6.6 0 11-.849.85z"
          data-name="Union 355"
          transform="translate(1134.841 325.56)"
        ></path>
      </g>
    </svg>
  );
}

export default StatusDashboardIconIcon;


