import { EditBtn } from "@ipgd-gauge/table";
import { handleError, isAllowedAction } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { getControlWithExcelFieldsList } from "../../../util/apis";
import { VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3274_CONTROL_WITH_EXCEL_FIELDS } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { t } = useTranslation("");
    const { data, rowActions } = props;
    const { user, dialogRef } = rowActions;

    return (
        <div className={`${style.controlWithExcelRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.sourceField} className="cardRowMode">
                <label className="nameLblMobile">{t('controlWithExcelFields:internal_name')}</label>
                <label>{data.sourceField}</label>
            </div>

            <div title={data.labelLo} className="cardRowMode">
                <label className="nameLblMobile">{t('controlWithExcelFields:english_name')}</label>
                <label>{data.labelLo}</label>
            </div>

            <div title={data.labelFo} className="cardRowMode">
                <label className="nameLblMobile">{t('controlWithExcelFields:arabic_name')}</label>
                <label>{data.labelFo}</label>
            </div>

            <div title={data.excelSeq} className="cardRowMode">
                <label className="nameLblMobile">{t('controlWithExcelFields:sequance')}</label>
                <label>{data.excelSeq}</label>
            </div>

            <div title={t(`status_${data.status}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('controlWithExcelFields:status')}</label>
                <label className={`status-${data.excelStatus}`}>{t(`status_${data.excelStatus}`)}</label>
            </div>

            <div>
                <div className={`containerButtonTable ${style.updateValuationFieldsBtnStyle}`}>
                    {isAllowedAction(user, RSM3274_CONTROL_WITH_EXCEL_FIELDS, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef.current.open(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const ControlWithExcelFieldsTable = (props) => {
    const { user, tableRef, searchTextRef, dialogRef } = props;

    const { t } = useTranslation("");

    const [list, setList] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('controlWithExcelFields:internal_name'), width: "18%" },
        { title: t('controlWithExcelFields:english_name'), width: "19%" },
        { title: t('controlWithExcelFields:arabic_name'), width: "19%" },
        { title: t('controlWithExcelFields:sequance'), width: "18%" },
        { title: t('controlWithExcelFields:status'), width: "8%" },
        { title: "", width: "13%" },
    ];

    const _fetchData = (page, size) => {
        getControlWithExcelFieldsList(page, size, searchTextRef?.current?.getSearchText())
            .then((response) => setList(response.data))
            .catch((error) => handleError(null, null, t))
    }

    return (
        <Slisting
            id="control-with-excel-table"
            ref={tableRef}
            formId={RSM3274_CONTROL_WITH_EXCEL_FIELDS}
            columns={columns}
            Row={Row}
            tableStyle={style.controlWithExcelTable}
            pagingData={list}
            getData={_fetchData}
            rowActions={{ user, dialogRef }}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            viewAction={(id) => dialogRef.current?.open(id)}
            cardInMobile
            labelCardWidth="90px"
        />
    );
};

export default ControlWithExcelFieldsTable;