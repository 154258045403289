import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../util/constants/forms";
import PropertiesPoliciesFilter from "./listing/Filters";
import PropertiesPoliciesTable from "./listing/Table";

const PropertiesPolicies = () => {
    const { t } = useTranslation("propertiesPolicies");

    const searchTextRef = useRef("");
    const filtersRef = useRef({});
    const propertiesPoliciesTableRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => propertiesPoliciesTableRef.current.refresh()}
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <PropertiesPoliciesTable
                propertiesPoliciesTableRef={propertiesPoliciesTableRef}
                searchTextRef={searchTextRef}
                filtersRef={filtersRef}
            />

            <PropertiesPoliciesFilter
                ref={filtersRef}
                propertiesPoliciesTableRef={propertiesPoliciesTableRef}
            />
        </>
    );
}

export default PropertiesPolicies;