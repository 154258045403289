import React from "react";

function PreviewImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#292d32"
        data-name="Group 179927"
        transform="translate(4608.75 10833.5)"
      >
        <path
          d="M13 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h6c2.77 0 4.647.586 5.905 1.845S20.75 4.23 20.75 7v6c0 2.77-.586 4.647-1.845 5.905S15.77 20.75 13 20.75zm-6-20c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h6c2.346 0 3.886-.447 4.845-1.405S19.25 15.346 19.25 13V7c0-2.346-.447-3.886-1.405-4.845S15.346.75 13 .75z"
          transform="translate(-4608 -10832.75)"
        ></path>
        <path
          d="M2-.75A2.75 2.75 0 11-.75 2 2.753 2.753 0 012-.75zm0 4A1.25 1.25 0 10.75 2 1.251 1.251 0 002 3.25z"
          data-name="Vector"
          transform="translate(-4603 -10828.75)"
        ></path>
        <path
          d="M0 7.7a.749.749 0 01-.623-.332.75.75 0 01.2-1.041l4.93-3.31a3.019 3.019 0 013.547.194l.006.006.326.287a1.48 1.48 0 001.84 0l4.16-3.57a2.971 2.971 0 013.8 0l1.63 1.4a.75.75 0 11-.977 1.138l-1.63-1.4a1.48 1.48 0 00-1.843 0l-4.16 3.57a2.971 2.971 0 01-3.8 0L7.4 4.636l-.326-.287a1.5 1.5 0 00-1.731-.084L.418 7.575A.747.747 0 010 7.7z"
          data-name="Vector"
          transform="translate(-4607.33 -10822.752)"
        ></path>
      </g>
    </svg>
  );
}

export default PreviewImageIcon;