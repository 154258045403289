import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { isEnglish } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../../../components/multi-insert-dialog";
import Table from "../../../../../components/table";
import AddHeaderBtnIcon from "../../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../../icons/Delete2";
import { getCommonCustomers } from "../../../../../util/apis";
import { CUSTOMER_LEVEL_SPECIFIC, POLICY_LVL_GENERAL, STATUS_ACTIVE } from "../../../../../util/constants";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../../../util/constants/forms";
import { ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES } from "../reducer";
import style from '../style.module.scss';

const Customers = (props) => {
    const { user, data, lov, handleDataChanged, handleDeleteLine, handleDeleteAllLines, dispatchPropertyPolicy } = props;

    const { t } = useTranslation();
    const customersDialogRef = useRef();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPolicies:code'), width: "20%" },
        { title: t('propertiesPolicies:name'), width: "60%" },
        { title: "", width: "15%" },
    ];

    const _insertCustomers = (selectedData) => {
        const newLines = [];

        selectedData?.forEach(element => {
            newLines.push({
                atrId: element.id,
                code: element.code,
                descLo: element.officialNameLo,
                descFo: element.officialNameFo,
            });
        });

        dispatchPropertyPolicy({ type: ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES, name: "customers", value: newLines })
        customersDialogRef?.current?.close();
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle-table">
                <div>
                    <label className="frm-title-form-border">
                        {t('propertiesPolicies:customers')}
                    </label>
                </div>
                <div className={style.actionSections}>
                    <div className={style.inputSection}>
                        <label className='form-label' > {t('propertiesPolicies:level')}</label>
                        <FrmSelectMenu
                            id={`properties-policies-dialog-customers`}
                            value={data.allCustomers}
                            onChange={value => handleDataChanged("allCustomers", value.value)}
                            options={lov?.customerLevel}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isDisabled={!(data?.usageLevel == POLICY_LVL_GENERAL && data?.function?.allCustomers)}
                            portalTarget={document.body}
                        />
                    </div>

                    {data?.allCustomers == CUSTOMER_LEVEL_SPECIFIC &&
                        <div className="tableHeaderActionsOrg">
                            <button title={t('insert')} onClick={() => customersDialogRef?.current.open()}>
                                <span><AddHeaderBtnIcon /></span>
                                <label>{t('insert')}</label>
                            </button>

                            <button
                                className="tableHeaderDeleBtn"
                                title={t('delete_all')}
                                onClick={() => handleDeleteAllLines('customers')}
                                disabled={(data?.customers || []).length === 0}>
                                <span><DeleteTowIcon /></span>
                                <label>{t('delete_all')}</label>
                            </button>
                        </div>
                    }
                </div>
            </div>
            {data?.allCustomers == CUSTOMER_LEVEL_SPECIFIC &&
                <>
                    <Table
                        id="policy-customers-table"
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        columns={columns}
                        user={user}
                        Row={CustomersRow}
                        rowHeight={40}
                        tableStyle={style.customersTable}
                        list={data?.customers || []}
                        rowActions={{ handleDeleteLine }}
                    />

                    <MultiInsertDialog
                        id="properties-Policies-multi-insert-customers-dialog"
                        ref={customersDialogRef}
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        title={t('propertiesPolicies:insert_customers')}
                        okBtnAction={_insertCustomers}
                        api={(searchValue) => getCommonCustomers([], STATUS_ACTIVE, searchValue, false, true, data.general, data.organizations?.map((org) => org.fogId), false)}
                        selectedIds={(data?.customers || []).map((line) => line.atrId)}
                        searchPlaceholder={t('propertiesPolicies:code') + " , " + t('propertiesPolicies:name')}
                        columns={[
                            { width: '5%' },
                            { label: '#', width: '10%' },
                            { label: t('propertiesPolicies:code'), value: 'code', width: '25%' },
                            { label: t('propertiesPolicies:name'), valueLo: 'descLo', valueFo: 'descFo', width: '60%' },
                        ]}
                    />
                </>
            }
        </div>
    );
}

const CustomersRow = (props) => {
    const { virtualizedStyle, data, rowActions, index } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${style.customersRow}`} style={virtualizedStyle}>
            <div><div>{data.sequence}</div></div>
            <div><div>{data.code}</div></div>
            <div><div>{isEnglish() ? data.officialNameLo : data.officialNameFo}</div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index, "customers")} />
                </div>
            </div>
        </div>
    );
};

export default Customers;