import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../util/constants/forms";
import PropertiesPartiesDefinitionDialog from "./dialogs";
import PropertiesPartiesDefinitionTable from "./listing";
import PropertiesPartiesDefinitionFilter from "./listing/Filters";

const PropertiesPartiesDefinition = () => {
    const { t } = useTranslation("propertiesPartiesDefinition");

    const searchTextRef = useRef();
    const filtersRef = useRef();
    const propertiesPartiesDefinitionTableRef = useRef();
    const propertiesPartiesDefinitionDialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name') + " , " + t('personal_id') + " , " + t('membership_id') + " , " + t('mobile_num')}
                        search={() => propertiesPartiesDefinitionTableRef?.current?.refresh()}
                        formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <PropertiesPartiesDefinitionTable
                filtersRef={filtersRef}
                propertiesPartiesDefinitionDialogRef={propertiesPartiesDefinitionDialogRef}
                propertiesPartiesDefinitionTableRef={propertiesPartiesDefinitionTableRef}
                searchTextRef={searchTextRef}
            />

            <PropertiesPartiesDefinitionFilter
                ref={filtersRef}
                propertiesPartiesDefinitionTableRef={propertiesPartiesDefinitionTableRef}
            />

            <PropertiesPartiesDefinitionDialog
                ref={propertiesPartiesDefinitionDialogRef}
                propertiesPartiesDefinitionTableRef={propertiesPartiesDefinitionTableRef}
            />
        </>
    )
}

export default PropertiesPartiesDefinition;