export const ACTION_USER_LOGIN = 'USER_LOGIN';
export const ACTION_USER_UPDATE_PROFILE = 'USER_LOGOUT';
export const ACTION_USER_LOGOUT = 'USER_LOGOUT';
export const ACTION_CHANGE_FAVORITE_FORMS = 'CHANGE_FAVORITE_FORMS';
export const ACTION_CHANGE_FAVORITE_COUNT = 'CHANGE_FAVORITE_COUNT';
export const ACTION_CHANGE_UNREADED_MSGS_COUNT = 'CHANGE_UNREADED_MSGS_COUNT';


export const userLogin = (user, token) => ({
    type: ACTION_USER_LOGIN,
    user: user,
    token: token,
});

export const userUpdateProfile = (user) => ({
    type: ACTION_USER_UPDATE_PROFILE,
    user: user,
});

export const userLogout = () => ({
    type: ACTION_USER_LOGOUT,
    user: null,
    token: null,
});

export const changeFavoriteForms = (formId) => ({
    type: ACTION_CHANGE_FAVORITE_FORMS,
    formId: formId
});

export const changeFavoriteCount = (count) => ({
    type: ACTION_CHANGE_FAVORITE_COUNT,
    count: count
});

export const changeUnreadedMsgsCount = (count) => ({
    type: ACTION_CHANGE_UNREADED_MSGS_COUNT,
    count: count
});