import React from "react";

function ClipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.675"
      height="26.64"
      viewBox="0 0 25.675 26.64"
    >
      <path
        fill="#ffffff"
        d="M25.455 10.653a1.11 1.11 0 00-1.569 0L11.8 22.792a5.549 5.549 0 11-7.848-7.847L15.658 3.192A3.329 3.329 0 1120.366 7.9L8.66 19.653a1.134 1.134 0 01-1.569 0 1.11 1.11 0 010-1.569l10.422-10.47a1.11 1.11 0 10-1.569-1.569L5.522 16.515a3.329 3.329 0 000 4.708 3.41 3.41 0 004.708 0l11.7-11.755a5.548 5.548 0 10-7.847-7.846l-11.7 11.754a7.768 7.768 0 0010.986 10.986l12.086-12.135a1.11 1.11 0 000-1.574z"
        transform="translate(-.107 .003)"
      ></path>
    </svg>
  );
}

export default ClipIcon;