import React from "react";

function FiltersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      data-name="Group 178401"
      viewBox="0 0 24 24.001"
    >
      <path
        fill="#668fff"
        d="M23 4.75h-2.736a3.728 3.728 0 01-7.2 0H1a1 1 0 110-2h12.069a3.728 3.728 0 017.195 0H23a1 1 0 110 2zM16.667 2a1.75 1.75 0 101.75 1.75A1.75 1.75 0 0016.667 2z"
        data-name="Path 43258"
      ></path>
      <path
        fill="#668fff"
        d="M1 11h2.736a3.727 3.727 0 017.194 0H23a1 1 0 010 2H10.93a3.727 3.727 0 01-7.194 0H1a1 1 0 010-2zm6.333 2.75A1.75 1.75 0 105.583 12a1.75 1.75 0 001.75 1.75z"
        data-name="Path 43259"
      ></path>
      <path
        fill="#668fff"
        d="M1 19.25h12.069a3.728 3.728 0 017.195 0H23a1 1 0 010 2h-2.736a3.728 3.728 0 01-7.2 0H1a1 1 0 010-2zM16.667 22a1.75 1.75 0 10-1.75-1.75 1.75 1.75 0 001.75 1.75z"
        data-name="Path 43260"
      ></path>
    </svg>
  );
}

export default FiltersIcon;