import LocalCurrencyInput from "@ipgd-gauge/local-currency";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import ExchangeRateInput from "../../../components/exchange-rate";
import ForeignCurrencyInput from "../../../components/foreign-currency";
import { getCurrenciesLov, getDomainValues } from "../../../util/apis";
import { AGREEMENT_LEVEL_DOMAIN, AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_ESTIMATION_VALUE, AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_REVENUES, INVOICE_CREATION_DOMAIN } from "../../../util/constants";
import { isLocalCurrency } from "../../../util/util";
import style from './style.module.scss'

const COMMON_ID = "price-agreements";

const PriceAgreementComponent = (props) => {
    const { data, handleDataChange, currentUser } = props;
    const { t } = useTranslation('priceAgreement');

    const levelRef = useRef(true);
    const currRef = useRef(true);

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('price_agreement')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label id={`${COMMON_ID}-home-type-lbl`} className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id={`${COMMON_ID}-home-type`}
                            value={data?.level}
                            api={() => getDomainValues(AGREEMENT_LEVEL_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            key={`${COMMON_ID}-home-type-${data?.level}`}
                            onChange={value => handleDataChange("level", value)}
                            onDataRetrieved={(list) => {
                                const id = getUrlParam("id");
                                if (isNotEmpty(id) && data.level && levelRef.current) {
                                    list?.forEach((element) => {
                                        if (element.id == data.level) {
                                            handleDataChange("levelData", { ...element })
                                            return;
                                        }
                                    })
                                }
                                if (levelRef.current && data?.id) {
                                    levelRef.current = false;
                                }
                            }}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${COMMON_ID}-home-currency-lbl`} className='form-label mandatory'>{t('currency')}</label>
                        <FrmSelectMenu
                            id={`${COMMON_ID}-home-currency`}
                            value={data?.fcuId}
                            key={`${COMMON_ID}-home-type-${data?.id}`}
                            onChange={value => handleDataChange("fcuId", value)}
                            api={() => getCurrenciesLov(data?.fcuId)}
                            onDataRetrieved={(list) => {
                                if (data.fcuId && currRef.current) {
                                    list?.forEach((element) => {
                                        if (element.id == data.fcuId) {
                                            handleDataChange("currencyData", { ...element })
                                            return;
                                        }
                                    })
                                }
                                if (currRef.current && data?.id) {
                                    currRef.current = false;
                                }
                            }}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${COMMON_ID}-home-ex-rate-lbl`} className='form-label mandatory'>{t('ex_rate')}</label>
                        <ExchangeRateInput
                            id={`${COMMON_ID}-home-ex-rate`}
                            value={data.exchngRate}
                            onBlur={(value) => handleDataChange("exchngRate", value)}
                            currencyId={data.fcuId}
                            transactionId={data.id}
                            organizationCurrency={currentUser.fcuId}
                        />
                    </div>

                    <div className={`w25 ${isLocalCurrency(data?.fcuId) ? style.hideElement : ''}`}>
                        <label id={`${COMMON_ID}-home-default-price-afc-lbl`} className='form-label mandatory'>{t('price_fc')}</label>
                        <ForeignCurrencyInput
                            id={`${COMMON_ID}-home-default-price-afc`}
                            value={data?.defaultPriceAfc}
                            onBlur={value => handleDataChange("defaultPriceAfc", value)}
                            changeAlcAmount={(value) => handleDataChange("defaultPriceAlc", value)}
                            currencyId={data.fcuId}
                            exchngRate={data.exchngRate}
                            disabled={data?.level == AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_REVENUES || data?.level == AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_ESTIMATION_VALUE}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${COMMON_ID}-home-default-price-alc-lbl`} className='form-label mandatory'>{t('price_lc')}</label>
                        <LocalCurrencyInput
                            id={`${COMMON_ID}-home-default-price-alc`}
                            value={data?.defaultPriceAlc}
                            onBlur={value => handleDataChange("defaultPriceAlc", value)}
                            disabled={data?.level == AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_ESTIMATION_VALUE || data?.level == AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_REVENUES}
                            withoutFormat
                        />
                    </div>
                    {data?.level == AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_REVENUES || data?.level == AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_ESTIMATION_VALUE ?
                        <div className="w25">
                            <label id={`${COMMON_ID}-home-estimation-percentage-lbl`} className='form-label mandatory'>{t('estimation_per')}</label>
                            <FrmDecimalInput
                                id={`${COMMON_ID}-home-estimation-percentage`}
                                minValue={0}
                                maxValue={100}
                                allowDecimals
                                value={data.percentage || null}
                                onBlur={(value) => handleDataChange("percentage", value)}
                            />
                        </div>
                        :
                        <></>
                    }
                    <div className="w25">
                        <label id={`${COMMON_ID}-home-invoice-creation-lbl`} className='form-label mandatory'>{t('invoice_creation')}</label>
                        <FrmSelectMenu
                            id={`${COMMON_ID}-home-invoice-creation`}
                            value={data?.invoiceCreation}
                            api={() => getDomainValues(INVOICE_CREATION_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChange("invoiceCreation", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${COMMON_ID}-home-day-lbl`} className='form-label mandatory'>{t('day')}</label>
                        <FrmDecimalInput
                            id={`${COMMON_ID}-home-day`}
                            value={data.day}
                            allowDecimals={false}
                            onBlur={(value) => handleDataChange("day", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PriceAgreementComponent;