import React from "react";

export function WaterIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.7"
            height="21.5"
            viewBox="0 0 17.7 21.5"
        >
            <path
                fill="#292d32"
                d="M8.09-.75a1.752 1.752 0 011.07.368A27.679 27.679 0 0113.75 4.1c2.128 2.678 3.2 5.3 3.2 7.807A8.85 8.85 0 01-.75 11.9a12.622 12.622 0 013.18-7.835A27.139 27.139 0 017.022-.384 1.752 1.752 0 018.09-.75zm.01 20a7.353 7.353 0 007.35-7.34C15.46 6.779 9.935 2.112 8.242.8a.241.241 0 00-.3 0h-.005C6.242 2.1.722 6.715.75 11.9a7.359 7.359 0 007.35 7.35z"
                transform="translate(.75 .75)"
            ></path>
        </svg>
    );
}

export function FillWaterIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.2"
            height="21.041"
            viewBox="0 0 17.2 21.041"
        >
            <g data-name="Group 179926" transform="translate(4637.59 10832.541)">
                <path
                    fill="#61c5ff"
                    stroke="#1e98df"
                    strokeWidth="1"
                    d="M-4628.39-10831.79a.991.991 0 00-1.22 0c-1.9 1.45-7.51 6.18-7.48 11.69a8.1 8.1 0 0016.2.01c.01-5.43-5.61-10.24-7.5-11.7z"
                ></path>
                <path
                    fill="#fff"
                    d="M-4622.771-10820.105a.325.325 0 01-.319-.268 13.091 13.091 0 00-4.947-8.276.325.325 0 01-.094-.449.325.325 0 01.45-.094 11.016 11.016 0 012.225 2.119 14.384 14.384 0 013.005 6.585.326.326 0 01-.263.378.382.382 0 01-.057.005z"
                    data-name="Path 44848"
                    transform="translate(-.806)"
                ></path>
            </g>
        </svg>
    );
}
