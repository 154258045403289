export const ACTION_SET_USER_TABLE_PROFILES = 'ACTION_SET_USER_TABLE_PROFILES';
export const ACTION_UPDATE_USER_TABLE_PROFILES = 'ACTION_UPDATE_USER_TABLE_PROFILES';

export const setUserTableProfiles = (data) => ({
    type: ACTION_SET_USER_TABLE_PROFILES,
    data: data,
});

export const updateUserTableProfiles = (id, data) => ({
    type: ACTION_UPDATE_USER_TABLE_PROFILES,
    id: id,
    data: data,
});