import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import { getCitiesLov, getCountriesLov, getTownsList } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { isEmpty } from "@ipgd-gauge/utils";

const Tab4 = (props) => {
    const { tab, data, handleDataChanged } = props;
    const { t } = useTranslation('propertiesPartiesDefinition');

    return (
        <div className={tab == 4 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w33">
                        <label className='form-label'>{t('country')}</label>
                        <FrmSelectMenu
                            id="country"
                            value={data?.address?.fcoId}
                            api={() => getCountriesLov([data?.address?.fcoId], STATUS_ACTIVE)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            onChange={value => handleDataChanged("fcoId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w33">
                        <label className='form-label'>{t('city')}</label>
                        <FrmSelectMenu
                            id="city"
                            value={data?.address?.ecdId}
                            key={`city-${data.address?.fcoId}`}
                            defualtValueId={data?.address?.ecdId}
                            defualtValueApi={() => getCitiesLov(isEmpty(data?.address?.ecdId) ? [] : [data?.address?.ecdId])}
                            api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, `${data.address?.fcoId}`, null, searchValue)}
                            isAsync={true}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("ecdId", value.value)}
                            isDisabled={!data?.address?.fcoId}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('town')}</label>
                        <FrmSelectMenu
                            id="town"
                            value={data?.address?.criId}
                            key={`town-${data?.address?.ecdId}-${data.id}`}
                            isAsync
                            defualtValueApi={() => getTownsList(null, isEmpty(data?.address?.criId) ? [] : [data?.address?.criId], data.address?.ecdId, STATUS_ACTIVE)}
                            api={(searchKey) => getTownsList(searchKey, null, data.address?.ecdId, STATUS_ACTIVE)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            defualtValueId={data?.address?.criId}
                            onChange={value => handleDataChanged("criId", value.value)}
                            isDisabled={!data?.address?.ecdId}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('main_street')}</label>
                        <FrmInput
                            id="main_street"
                            value={data?.address?.mainStreet}
                            onBlur={(value) => handleDataChanged("mainStreet", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('sub_street')}</label>
                        <FrmInput
                            id="sub_street"
                            value={data?.address?.subStreet}
                            onBlur={(value) => handleDataChanged("subStreet", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('building_num')}</label>
                        <FrmInput
                            id="building_num"
                            value={data?.address?.buildingNum}
                            onBlur={(value) => handleDataChanged("buildingNum", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('p_o_box')}</label>
                        <FrmInput
                            id="p_o_box"
                            value={data?.address?.poBox}
                            onBlur={(value) => handleDataChanged("poBox", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('postal_code')}</label>
                        <FrmInput
                            id="postal_code"
                            value={data?.address?.postalCode}
                            onBlur={(value) => handleDataChanged("postalCode", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('address')}</label>
                        <FrmInput
                            id="address"
                            value={data?.address?.address}
                            onBlur={(value) => handleDataChanged("address", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id="remarks"
                            value={data?.address?.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab4;