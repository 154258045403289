import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { getTaskStatusList } from "../../../util/apis";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const RecoveryDialog = (props, ref) => {
    const { okAction, postClose } = props;

    const { t } = useTranslation();
    const trnxIdRef = useRef();
    const dialogRef = useRef();

    const [sseId, setSseId] = useState();
    const [taskStatus, setTaskStatus] = useState([]);

    const _postClose = () => {
        setSseId('-1');
        setTaskStatus([]);
        trnxIdRef.current = "";
        if (postClose) {
            postClose();
        }
    }

    const _okAction = () => {
        if (okAction) {
            okAction(trnxIdRef.current, { sseId: sseId == '-1' ? '' : sseId })
        }
    }

    const open = (trnxId) => {
        if (trnxId) {
            trnxIdRef.current = trnxId;
            getTaskStatusList(trnxId)
                .then((response) => {
                    const list = response.data;
                    list.push({ id: '-1', description: t('estimationTransaction:recover_to_incomplete_transaction') });
                    setSseId(list[0].id)
                    setTaskStatus(list);
                })
                .catch(() => { })
        }
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="recoveryDialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={t('estimationTransaction:recovery')}
            withFooter
            okBtnLabel={t("save")}
            withOkButton
            withCancelButton
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={`fitHeightDialog ${style.actionDailog}`}
            okBtnAction={_okAction}
            postClose={_postClose}
            smallSize
        >
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w100">
                        <label className='form-label mandatory'>{t('estimationTransaction:task')}</label>
                        <FrmSelectMenu
                            id="task"
                            value={sseId}
                            options={taskStatus}
                            descLo={['description']}
                            descFo={['description']}
                            onChange={e => setSseId(e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(RecoveryDialog);