export const DashbordsMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
        >
            <g fill="#ff5195" data-name="Group 176566">
                <path d="M8.5 17.9V2.1C8.5.6 7.86 0 6.27 0H2.23C.64 0 0 .6 0 2.1v15.8C0 19.4.64 20 2.23 20h4.04c1.59 0 2.23-.6 2.23-2.1z"></path>
                <path
                    d="M19 8.9V2.1C19 .6 18.36 0 16.77 0h-4.04c-1.59 0-2.23.6-2.23 2.1v6.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1zM19 17.9v-2.8c0-1.5-.64-2.1-2.23-2.1h-4.04c-1.59 0-2.23.6-2.23 2.1v2.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1z"
                    data-name="Vector"
                    opacity="0.4"
                ></path>
            </g>
        </svg>
    );
}
export const ReportsMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g data-name="Group 176560">
                <path
                    fill="#a253e7"
                    d="M21.99 6.02a2.342 2.342 0 01-.06.74l-3.37 13.53A2.228 2.228 0 0116.38 22H3.24a2.261 2.261 0 01-2.14-2.93L5.31 5.54a2.241 2.241 0 012.14-1.58h12.3a2.213 2.213 0 012.07 1.37 2 2 0 01.17.69z"
                    data-name="Vector"
                ></path>
                <g data-name="Group 176558">
                    <g data-name="Group 176559">
                        <path
                            fill="#1b0407"
                            d="M22.99 19.62A2.21 2.21 0 0120.78 22h-4.4a2.228 2.228 0 002.18-1.71l3.37-13.53a2.342 2.342 0 00.06-.74L22 6zM9.68 7.13a1.1 1.1 0 01-.18-.02.751.751 0 01-.55-.91l1.04-4.32a.759.759 0 01.91-.55.751.751 0 01.55.91l-1.04 4.32a.748.748 0 01-.73.57z"
                            data-name="Vector"
                            opacity="0.4"
                        ></path>
                        <g data-name="Group">
                            <path
                                fill="#1b0407"
                                d="M16.38 7.14a.44.44 0 01-.16-.02.765.765 0 01-.58-.89l.94-4.34a.758.758 0 01.89-.58.765.765 0 01.58.89l-.94 4.34a.738.738 0 01-.73.6z"
                                data-name="Vector"
                                opacity="0.4"
                            ></path>
                        </g>
                        <g data-name="Group">
                            <path
                                fill="#1b0407"
                                d="M15.7 12.75h-8a.755.755 0 01-.75-.75.755.755 0 01.75-.75h8a.755.755 0 01.75.75.755.755 0 01-.75.75z"
                                data-name="Vector"
                                opacity="0.4"
                            ></path>
                        </g>
                        <g data-name="Group">
                            <path
                                fill="#1b0407"
                                d="M14.7 16.75h-8a.755.755 0 01-.75-.75.755.755 0 01.75-.75h8a.755.755 0 01.75.75.755.755 0 01-.75.75z"
                                data-name="Vector"
                                opacity="0.4"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export const FinancialMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="17"
            viewBox="0 0 20 17"
        >
            <g data-name="Group 176563">
                <path
                    fill="#a8de4f"
                    d="M15 17H5c-3 0-5-1.5-5-5V5c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                ></path>
                <path
                    d="M13 8.5a3 3 0 11-3-3 3 3 0 013 3z"
                    data-name="Vector"
                    opacity="0.64"
                ></path>
                <g data-name="Group">
                    <path
                        d="M3.5 11.75a.755.755 0 01-.75-.75V6a.755.755 0 01.75-.75.755.755 0 01.75.75v5a.755.755 0 01-.75.75z"
                        data-name="Vector"
                        opacity="0.64"
                    ></path>
                </g>
                <g data-name="Group">
                    <path
                        d="M16.5 11.75a.755.755 0 01-.75-.75V6a.755.755 0 01.75-.75.755.755 0 01.75.75v5a.755.755 0 01-.75.75z"
                        data-name="Vector"
                        opacity="0.64"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const OperationsManagementMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.98"
            height="20"
            viewBox="0 0 19.98 20"
        >
            <g fill="#06d3d4" data-name="Group 176565">
                <path
                    d="M19.97 4.41v6.5H0v-6.5A4.417 4.417 0 014.41 0h11.15a4.417 4.417 0 014.41 4.41z"
                    opacity="0.4"
                ></path>
                <path
                    d="M0 10.92v.2a4.411 4.411 0 004.41 4.41h3.84a1 1 0 011 1v.97a1 1 0 01-1 1H5.83a.75.75 0 000 1.5h8.35a.75.75 0 100-1.5h-2.42a1 1 0 01-1-1v-.97a1 1 0 011-1h3.81a4.411 4.411 0 004.41-4.41v-.2z"
                    data-name="Vector"
                ></path>
            </g>
        </svg>
    );
}
export const CorrespondenceMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="17"
            viewBox="0 0 20 17"
        >
            <g data-name="Group 176564">
                <path
                    fill="#00bcff"
                    d="M15 17H5c-3 0-5-1.5-5-5V5c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                ></path>
                <path
                    fill="#352e68"
                    d="M10 9.37a3.717 3.717 0 01-2.34-.79l-3.13-2.5a.747.747 0 11.93-1.17l3.13 2.5a2.386 2.386 0 002.81 0l3.13-2.5a.738.738 0 011.05.12.738.738 0 01-.12 1.05l-3.13 2.5a3.67 3.67 0 01-2.33.79z"
                    data-name="Vector"
                    opacity="0.79"
                ></path>
            </g>
        </svg>
    );
}
export const SettingMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.01"
            height="18.878"
            viewBox="0 0 20.01 18.878"
        >
            <g data-name="Group 176567" transform="translate(-334 -633.561)">
                <g data-name="Group 176561" transform="translate(9633 -73)">
                    <path
                        fill="#ffb607"
                        d="M0 10.319v-1.76a1.906 1.906 0 011.9-1.9c1.81 0 2.55-1.28 1.64-2.85a1.9 1.9 0 01.7-2.59l1.73-.99a1.669 1.669 0 012.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19a1.669 1.669 0 012.28-.6l1.73.99a1.9 1.9 0 01.7 2.59c-.91 1.57-.17 2.85 1.64 2.85a1.906 1.906 0 011.9 1.9v1.76a1.906 1.906 0 01-1.9 1.9c-1.81 0-2.55 1.28-1.64 2.85a1.9 1.9 0 01-.7 2.59l-1.73.99a1.669 1.669 0 01-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19a1.669 1.669 0 01-2.28.6l-1.73-.99a1.9 1.9 0 01-.7-2.59c.91-1.57.17-2.85-1.64-2.85a1.906 1.906 0 01-1.9-1.9z"
                        transform="translate(-9299 706.561)"
                    ></path>
                </g>
                <path
                    fill="#572538"
                    d="M6.5 3.25A3.25 3.25 0 113.25 0 3.25 3.25 0 016.5 3.25z"
                    data-name="Vector"
                    transform="translate(340.75 639.75)"
                ></path>
            </g>
        </svg>
    );
}
export const DifinitionMenu = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.41"
            height="20"
            viewBox="0 0 17.41 20"
        >
            <g data-name="Group 176562">
                <path
                    fill="#0080ff"
                    d="M12.95 1.65H4.47A4.475 4.475 0 000 6.12v9.41A4.481 4.481 0 004.47 20h8.47a4.475 4.475 0 004.47-4.47V6.12a4.46 4.46 0 00-4.46-4.47z"
                ></path>
                <path
                    fill="#163ea9"
                    d="M11.316 0h-4.7a1.886 1.886 0 00-1.89 1.88v.94a1.878 1.878 0 001.88 1.88h4.71a1.878 1.878 0 001.88-1.88v-.94A1.871 1.871 0 0011.316 0zM11.966 10.95h-7a.755.755 0 01-.75-.75.755.755 0 01.75-.75h7a.755.755 0 01.75.75.755.755 0 01-.75.75zM9.346 14.95h-4.38a.755.755 0 01-.75-.75.755.755 0 01.75-.75h4.38a.755.755 0 01.75.75.755.755 0 01-.75.75z"
                    data-name="Vector"
                ></path>
            </g>
        </svg>
    );
}