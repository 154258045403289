import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import accountsGroupsAr from "./locales/ar/accountsGroups.json";
import additionalItemsAr from "./locales/ar/additionalItems.json";
import banksAccountsDefinitionAr from "./locales/ar/banksAccountsDefinition.json";
import branchesAccountsSetupAr from "./locales/ar/branchesAccountsSetup.json";
import branchesDefinitionAr from "./locales/ar/branchesDefinition.json";
import citiesAr from "./locales/ar/cities.json";
import closingVouchersAr from "./locales/ar/closingVouchers.json";
import ar from "./locales/ar/common.json";
import controlWithExcelFieldsAr from "./locales/ar/controlWithExcelFields.json";
import customFinancialReportsAr from "./locales/ar/customFinancialReports.json";
import customReportsAr from "./locales/ar/customReports.json";
import customerDuesFollowUpAr from "./locales/ar/customerDuesFollowUp.json";
import customersAr from "./locales/ar/customers.json";
import customersEstimationInvoicesAr from "./locales/ar/customersEstimationInvoices.json";
import delegationAr from "./locales/ar/delegation.json";
import divisionDefinitionAr from "./locales/ar/divisionDefinition.json";
import estimationFeesAr from "./locales/ar/estimationFees.json";
import estimationFormsFieldsBuilderAr from "./locales/ar/estimationFormsFieldsBuilder.json";
import estimationInvoicesAr from "./locales/ar/estimationInvoices.json";
import estimationInvoicesRegisterAr from "./locales/ar/estimationInvoicesRegister.json";
import estimationMapAr from "./locales/ar/estimationMap.json";
import estimationMessagesTemplatesAr from "./locales/ar/estimationMessagesTemplates.json";
import estimationQuotationAr from "./locales/ar/estimationQuotation.json";
import estimationQuotationsTypesAr from "./locales/ar/estimationQuotationsTypes.json";
import estimationReportsAr from "./locales/ar/estimationReports.json";
import estimationTransactionAr from "./locales/ar/estimationTransaction.json";
import estimationTransactionsRegisterAr from "./locales/ar/estimationTransactionsRegister.json";
import estimationTransactionsStatusesAr from "./locales/ar/estimationTransactionsStatuses.json";
import estimationsHistoryAr from "./locales/ar/estimationsHistory.json";
import estimatorsInspectorsInvoicesAr from "./locales/ar/estimatorsInspectorsInvoices.json";
import financialPeriodsAr from "./locales/ar/financialPeriods.json";
import fiscalPeriodsAr from "./locales/ar/fiscalPeriods.json";
import importantLinksAr from "./locales/ar/importantLinks.json";
import inboxAr from "./locales/ar/inbox.json";
import locationsGroupsAr from "./locales/ar/locationsGroups.json";
import notExecutedTasksAr from "./locales/ar/notExecutedTasks.json";
import notificationChannelAr from "./locales/ar/notificationChannel.json";
import outBoxAr from "./locales/ar/outBox.json";
import priceAgreementAr from "./locales/ar/priceAgreement.json";
import propertiesEstimationListOfValuesAr from "./locales/ar/propertiesEstimationListOfValues.json";
import propertiesNotificationsSetupAr from "./locales/ar/propertiesNotificationsSetup.json";
import propertiesPartiesCategoriesAr from "./locales/ar/propertiesPartiesCategories.json";
import propertiesPartiesDefinitionAr from "./locales/ar/propertiesPartiesDefinition.json";
import propertiesPoliciesAr from "./locales/ar/propertiesPolicies.json";
import propertiesTypesAr from "./locales/ar/propertiesTypes.json";
import propertiesUsersDefinitionAr from "./locales/ar/propertiesUsersDefinition.json";
import reportsDesignAr from "./locales/ar/reportsDesign.json";
import tasksListAr from "./locales/ar/tasksList.json";
import tasksStatisticsAr from "./locales/ar/tasksStatistics.json";
import townsAr from "./locales/ar/towns.json";
import transactionsStatisticsAr from "./locales/ar/transactionStatistics.json";
import updateValuationFieldsAr from "./locales/ar/updateValuationFields.json";
import usersDefinitionAr from "./locales/ar/usersDefinition.json";
import usersLoginLogAr from "./locales/ar/usersLoginLog.json";
import workflowProcedureMessagesAr from "./locales/ar/workflowProcedureMessages.json";
import accountsGroupsEn from "./locales/en/accountsGroups.json";
import additionalItemsEn from "./locales/en/additionalItems.json";
import banksAccountsDefinitionEn from "./locales/en/banksAccountsDefinition.json";
import branchesAccountsSetupEn from "./locales/en/branchesAccountsSetup.json";
import branchesDefinitionEn from "./locales/en/branchesDefinition.json";
import citiesEn from "./locales/en/cities.json";
import closingVouchersEn from "./locales/en/closingVouchers.json";
import en from "./locales/en/common.json";
import controlWithExcelFieldsEn from "./locales/en/controlWithExcelFields.json";
import customFinancialReportsEn from "./locales/en/customFinancialReports.json";
import customReportsEn from "./locales/en/customReports.json";
import customerDuesFollowUpEn from "./locales/en/customerDuesFollowUp.json";
import customersEn from "./locales/en/customers.json";
import customersEstimationInvoicesEn from "./locales/en/customersEstimationInvoices.json";
import delegationEn from "./locales/en/delegation.json";
import divisionDefinitionEn from "./locales/en/divisionDefinition.json";
import estimationFeesEn from "./locales/en/estimationFees.json";
import estimationFormsFieldsBuilderEn from "./locales/en/estimationFormsFieldsBuilder.json";
import estimationInvoicesEn from "./locales/en/estimationInvoices.json";
import estimationInvoicesRegisterEn from "./locales/en/estimationInvoicesRegister.json";
import estimationMapEn from "./locales/en/estimationMap.json";
import estimationMessagesTemplatesEn from "./locales/en/estimationMessagesTemplates.json";
import estimationQuotationEn from "./locales/en/estimationQuotation.json";
import estimationQuotationsTypesEn from "./locales/en/estimationQuotationsTypes.json";
import estimationReportsEn from "./locales/en/estimationReports.json";
import estimationTransactionEn from "./locales/en/estimationTransaction.json";
import estimationTransactionsRegisterEn from "./locales/en/estimationTransactionsRegister.json";
import estimationTransactionsStatusesEn from "./locales/en/estimationTransactionsStatuses.json";
import estimationsHistoryEn from "./locales/en/estimationsHistory.json";
import estimatorsInspectorsInvoicesEn from "./locales/en/estimatorsInspectorsInvoices.json";
import financialPeriodsEn from "./locales/en/financialPeriods.json";
import fiscalPeriodsEn from "./locales/en/fiscalPeriods.json";
import importantLinksEn from "./locales/en/importantLinks.json";
import inboxEn from "./locales/en/inbox.json";
import locationsGroupsEn from "./locales/en/locationsGroups.json";
import notExecutedTasksEn from "./locales/en/notExecutedTasks.json";
import notificationChannelEn from "./locales/en/notificationChannel.json";
import outBoxEn from "./locales/en/outBox.json";
import priceAgreementEn from "./locales/en/priceAgreement.json";
import propertiesEstimationListOfValuesEn from "./locales/en/propertiesEstimationListOfValues.json";
import propertiesNotificationsSetupEn from "./locales/en/propertiesNotificationsSetup.json";
import propertiesPartiesCategoriesEn from "./locales/en/propertiesPartiesCategories.json";
import propertiesPartiesDefinitionEn from "./locales/en/propertiesPartiesDefinition.json";
import propertiesPoliciesEn from "./locales/en/propertiesPolicies.json";
import propertiesTypesEn from "./locales/en/propertiesTypes.json";
import propertiesUsersDefinitionEn from "./locales/en/propertiesUsersDefinition.json";
import reportsDesignEn from "./locales/en/reportsDesign.json";
import tasksListEn from "./locales/en/tasksList.json";
import tasksStatisticsEn from "./locales/en/tasksStatistics.json";
import townsEn from "./locales/en/towns.json";
import transactionsStatisticsEn from "./locales/en/transactionStatistics.json";
import updateValuationFieldsEn from "./locales/en/updateValuationFields.json";
import usersDefinitionEn from "./locales/en/usersDefinition.json";
import usersLoginLogEn from "./locales/en/usersLoginLog.json";
import workflowProcedureMessagesEn from "./locales/en/workflowProcedureMessages.json";
import valuationContractsEn from "./locales/en/valuationContracts.json";
import valuationContractsAr from "./locales/ar/valuationContracts.json";

import creditNotesForCustomersEstimationEn from "./locales/en/creditNotesForCustomersEstimation.json";
import creditNotesForCustomersEstimationAr from "./locales/ar/creditNotesForCustomersEstimation.json";

import { getUser } from "./util/cookies";


let lng = 'ar';

try {
  const user = getUser();
  if (user) {
    lng = user.en ? 'en' : 'ar';
  }
} catch (e) {
  console.error("🚀 ~ file: i18n.js ~ line 14 ~ e", e)
}

i18next.use(initReactI18next).init({
  debug: true,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: true,
    unescapePrefix: ':'
  },
  resources: {
    en: {
      translation: {
        ...en
      },
      customers: {
        ...customersEn
      },
      towns: {
        ...townsEn
      },
      usersLoginLog: {
        ...usersLoginLogEn
      },
      reportsDesign: {
        ...reportsDesignEn
      },
      estimationReports: {
        ...estimationReportsEn
      },
      cities: {
        ...citiesEn
      },
      tasksList: {
        ...tasksListEn
      },
      estimationQuotation: {
        ...estimationQuotationEn
      },
      delegation: {
        ...delegationEn
      },
      estimationsHistory: {
        ...estimationsHistoryEn
      },
      notExecutedTasks: {
        ...notExecutedTasksEn
      },
      transactionsStatistics: {
        ...transactionsStatisticsEn
      },
      tasksStatistics: {
        ...tasksStatisticsEn
      },
      inbox: {
        ...inboxEn
      },
      outBox: {
        ...outBoxEn
      },
      estimationMap: {
        ...estimationMapEn
      },
      estimationTransaction: {
        ...estimationTransactionEn
      },
      accountsGroups: {
        ...accountsGroupsEn
      },
      branchesDefinition: {
        ...branchesDefinitionEn
      },
      divisionDefinition: {
        ...divisionDefinitionEn
      },
      financialPeriods: {
        ...financialPeriodsEn
      },
      fiscalPeriods: {
        ...fiscalPeriodsEn
      },
      notificationChannel: {
        ...notificationChannelEn
      },
      branchesAccountsSetup: {
        ...branchesAccountsSetupEn
      },
      banksAccountsDefinition: {
        ...banksAccountsDefinitionEn
      },
      locationsGroups: {
        ...locationsGroupsEn
      },
      usersDefinition: {
        ...usersDefinitionEn
      },
      closingVouchers: {
        ...closingVouchersEn
      },
      customFinancialReports: {
        ...customFinancialReportsEn
      },
      propertiesPartiesCategories: {
        ...propertiesPartiesCategoriesEn
      },
      propertiesPartiesDefinition: {
        ...propertiesPartiesDefinitionEn
      },
      propertiesNotificationsSetup: {
        ...propertiesNotificationsSetupEn
      },
      estimationFormsFieldsBuilder: {
        ...estimationFormsFieldsBuilderEn
      },
      estimationTransactionsStatuses: {
        ...estimationTransactionsStatusesEn
      },
      estimationFees: {
        ...estimationFeesEn
      },
      estimationInvoices: {
        ...estimationInvoicesEn
      },
      estimationMessagesTemplates: {
        ...estimationMessagesTemplatesEn
      },
      priceAgreement: {
        ...priceAgreementEn
      },
      workflowProcedureMessages: {
        ...workflowProcedureMessagesEn
      },
      customersEstimationInvoices: {
        ...customersEstimationInvoicesEn
      },
      estimationInvoicesRegister: {
        ...estimationInvoicesRegisterEn
      },
      estimatorsInspectorsInvoices: {
        ...estimatorsInspectorsInvoicesEn
      },
      propertiesPolicies: {
        ...propertiesPoliciesEn
      },
      estimationQuotationsTypes: {
        ...estimationQuotationsTypesEn
      },
      updateValuationFields: {
        ...updateValuationFieldsEn
      },
      estimationTransactionsRegister: {
        ...estimationTransactionsRegisterEn
      },
      propertiesEstimationListOfValues: {
        ...propertiesEstimationListOfValuesEn
      },
      customReports: {
        ...customReportsEn
      },
      propertiesUsersDefinition: {
        ...propertiesUsersDefinitionEn
      },
      propertiesTypes: {
        ...propertiesTypesEn
      },
      controlWithExcelFields: {
        ...controlWithExcelFieldsEn
      },
      importantLinks: {
        ...importantLinksEn
      },
      customerDuesFollowUp: {
        ...customerDuesFollowUpEn
      },
      additionalItems: {
        ...additionalItemsEn
      },
      valuationContracts: {
        ...valuationContractsEn
      },

      creditNotesForCustomersEstimation: {
        ...creditNotesForCustomersEstimationEn
      },
    },
    ar: {
      translation: {
        ...ar
      },
      additionalItems: {
        ...additionalItemsAr
      },
      customers: {
        ...customersAr
      },
      towns: {
        ...townsAr
      },
      usersLoginLog: {
        ...usersLoginLogAr
      },
      reportsDesign: {
        ...reportsDesignAr
      },
      estimationReports: {
        ...estimationReportsAr
      },
      cities: {
        ...citiesAr
      },
      tasksList: {
        ...tasksListAr
      },
      estimationQuotation: {
        ...estimationQuotationAr
      },
      delegation: {
        ...delegationAr
      },
      estimationsHistory: {
        ...estimationsHistoryAr
      },
      notExecutedTasks: {
        ...notExecutedTasksAr
      },
      transactionsStatistics: {
        ...transactionsStatisticsAr
      },
      tasksStatistics: {
        ...tasksStatisticsAr
      },
      inbox: {
        ...inboxAr
      },
      outBox: {
        ...outBoxAr
      },
      estimationMap: {
        ...estimationMapAr
      },
      estimationTransaction: {
        ...estimationTransactionAr
      },
      accountsGroups: {
        ...accountsGroupsAr
      },
      branchesDefinition: {
        ...branchesDefinitionAr
      },
      divisionDefinition: {
        ...divisionDefinitionAr
      },
      financialPeriods: {
        ...financialPeriodsAr
      },
      fiscalPeriods: {
        ...fiscalPeriodsAr
      },
      notificationChannel: {
        ...notificationChannelAr
      },
      branchesAccountsSetup: {
        ...branchesAccountsSetupAr
      },
      banksAccountsDefinition: {
        ...banksAccountsDefinitionAr
      },
      locationsGroups: {
        ...locationsGroupsAr
      },
      usersDefinition: {
        ...usersDefinitionAr
      },
      closingVouchers: {
        ...closingVouchersAr
      },
      customFinancialReports: {
        ...customFinancialReportsAr
      },
      propertiesPartiesCategories: {
        ...propertiesPartiesCategoriesAr
      },
      propertiesPartiesDefinition: {
        ...propertiesPartiesDefinitionAr
      },
      propertiesNotificationsSetup: {
        ...propertiesNotificationsSetupAr
      },
      estimationFormsFieldsBuilder: {
        ...estimationFormsFieldsBuilderAr
      },
      estimationTransactionsStatuses: {
        ...estimationTransactionsStatusesAr
      },
      estimationFees: {
        ...estimationFeesAr
      },
      estimationInvoices: {
        ...estimationInvoicesAr
      },
      estimationMessagesTemplates: {
        ...estimationMessagesTemplatesAr
      },
      priceAgreement: {
        ...priceAgreementAr
      },
      workflowProcedureMessages: {
        ...workflowProcedureMessagesAr
      },
      customersEstimationInvoices: {
        ...customersEstimationInvoicesAr
      },
      estimationInvoicesRegister: {
        ...estimationInvoicesRegisterAr
      },
      estimatorsInspectorsInvoices: {
        ...estimatorsInspectorsInvoicesAr
      },
      propertiesPolicies: {
        ...propertiesPoliciesAr
      },
      estimationQuotationsTypes: {
        ...estimationQuotationsTypesAr
      },
      updateValuationFields: {
        ...updateValuationFieldsAr
      },
      estimationTransactionsRegister: {
        ...estimationTransactionsRegisterAr
      },
      propertiesEstimationListOfValues: {
        ...propertiesEstimationListOfValuesAr
      },
      customReports: {
        ...customReportsAr
      },
      propertiesUsersDefinition: {
        ...propertiesUsersDefinitionAr
      },
      propertiesTypes: {
        ...propertiesTypesAr
      },
      controlWithExcelFields: {
        ...controlWithExcelFieldsAr
      },
      importantLinks: {
        ...importantLinksAr
      },
      customerDuesFollowUp: {
        ...customerDuesFollowUpAr
      },
      valuationContracts: {
        ...valuationContractsAr
      },

      creditNotesForCustomersEstimation: {
        ...creditNotesForCustomersEstimationAr
      },
    }
  }
});
