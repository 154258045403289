import ActionsButton from "@ipgd-gauge/actions-button";
import { checkValidation, confirmationDialog, handleError, hideActionWaiting, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResumeTrxIcon } from "../../icons/EstimationTrx";
import { calculateEstimationFeesNCommissions, getOrgnizationById } from "../../util/apis";
import { CUSTOMER_LEVEL_ALL, CUSTOMER_LEVEL_SPECIFIC, DATE_LEVEL_DOMAIN_TRANSACTION_DATE } from "../../util/constants";
import { getUser } from "../../util/cookies";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import style from './style.module.scss';

const EstimationFeesCommissionsCalculation = () => {
    const { t } = useTranslation();

    const [tab, setTab] = useState(1);

    const currentUser = getUser();

    const [data, setData] = useState({
        dateLevel: DATE_LEVEL_DOMAIN_TRANSACTION_DATE,
        customerLevel: CUSTOMER_LEVEL_ALL,
        partyLevel: CUSTOMER_LEVEL_ALL,
        customers: [],
        parties: [],
    });

    useEffect(() => {
        getOrgnizationById(currentUser.fogId)
            .then((response) => {
                data.orgDescFo = response.data.offecialNameFo;
                data.orgDescLo = response.data.offecialNameLo;
                setData({ ...data });
            }).catch((error) => handleError(error, null, t));
    }, []);

    const _calcalate = () => {
        if (!checkValidation([{ id: 'estimation-fees-commissions-calculation-tabs' }], t)) {
            return
        }
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            let tempData = Object.assign({}, data);
            tempData.customers = (data.customers || []).map(a => a.id);
            tempData.parties = (data.parties || []).map(a => a.id);
            showActionWaiting('calculate-action');
            return calculateEstimationFeesNCommissions(tempData)
                .then(() => showSuccessMessage(null, t))
                .catch((error) => handleError(null, error.response.data.message.split('#'), t))
                .finally(() => hideActionWaiting('calculate-action'));
        });
    }

    return (
        <>
            <div id='estimation-fees-commissions-calculation-tabs' className={style.estimationFeesCommissionsContainer}>
                <div className={style.containerTabs}>
                    <button className={tab == 1 ? style.activeBtn : ''} onClick={() => setTab(1)}>{t('estimationFees:home')}</button>

                    {data.customerLevel == CUSTOMER_LEVEL_SPECIFIC &&
                        <button className={tab == 2 ? style.activeBtn : ''} onClick={() => setTab(2)}>{t('estimationFees:customers_list')}</button>
                    }

                    {data.partyLevel == CUSTOMER_LEVEL_SPECIFIC &&
                        <button className={tab == 3 ? style.activeBtn : ''} onClick={() => setTab(3)}>{t('estimationFees:parties_list')}</button>
                    }
                </div>
                <div className={style.estimationFeesCommissionsPages}>
                    <Tab1 tab={tab} data={data} setData={setData} />

                    {data.customerLevel == CUSTOMER_LEVEL_SPECIFIC &&
                        <Tab2 tab={tab} user={currentUser} data={data} setData={setData} />
                    }

                    {data.partyLevel == CUSTOMER_LEVEL_SPECIFIC &&
                        <Tab3 tab={tab} user={currentUser} data={data} setData={setData} />
                    }
                </div>
            </div>

            <ActionsButton actions={[{ id: 'calculate-action', label: t('estimationFees:calculate'), icon: <ResumeTrxIcon />, action: _calcalate }]} />
        </>
    )
}

export default EstimationFeesCommissionsCalculation;