import { isEmpty, isEnglish } from '@ipgd-gauge/utils';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Progress from '../../../../components/chart/progress';
import { TRX_DISPLAY_MODE_1 } from '../../../../util/constants';
import style from './style.module.scss';

const DURATION = .5
const DECIMALS = 0
const SEPARATOR = ","

const HeaderCard = (props) => {
    const { block, index, displayMode, handleSelectBlock } = props;
    const { t } = useTranslation("estimationTransaction");

    const [optionalFeilds, setOptionalFeilds] = useState({ tot: 0, totFilled: 0 });
    const [mandatoryFeilds, setMandatoryFeilds] = useState({ tot: 0, totFilled: 0 });

    const feilds = useSelector(state => state.estTrnxReducer.blocks[block.id]);
    const data = useSelector(state => state.estTrnxReducer.data);
    const forceUpdate = useSelector(state => state.estTrnxReducer.forceUpdate);
    const mandatoryFeildsPer = mandatoryFeilds.tot > 0 ? ((mandatoryFeilds.totFilled * 100) / mandatoryFeilds.tot) : 100;

    useEffect(() => {
        if (feilds) {
            let totOptional = 0, totFilledOptional = 0, totMandatory = 0, totFilledMandatory = 0;

            feilds.map(feild => {
                if (feild.internalName) {
                    if (feild.enabled) {
                        if (feild.mandatory) {
                            ++totMandatory;
                            if (!isEmpty(data[feild.internalName])) {
                                ++totFilledMandatory;
                            }
                        } else {
                            ++totOptional;
                            if (!isEmpty(data[feild.internalName])) {
                                ++totFilledOptional;
                            }
                        }
                    }
                } else {
                    feild.fieldDetails?.map(detail => {
                        if (detail.enabled) {
                            if (detail.mandatory) {
                                ++totMandatory;
                                if (!isEmpty(data[detail.internalName])) {
                                    ++totFilledMandatory;
                                }
                            } else {
                                ++totOptional;
                                if (!isEmpty(data[detail.internalName])) {
                                    ++totFilledOptional;
                                }
                            }
                        }
                    })
                }
            })
            optionalFeilds.tot = totOptional;
            optionalFeilds.totFilled = totFilledOptional;
            setOptionalFeilds({ ...optionalFeilds });

            mandatoryFeilds.tot = totMandatory;
            mandatoryFeilds.totFilled = totFilledMandatory;
            setMandatoryFeilds({ ...mandatoryFeilds });
        }
    }, [feilds, forceUpdate])

    const _handleScrollIntoView = (e) => {
        if (displayMode == TRX_DISPLAY_MODE_1) {
            const target = document.getElementById(`block-${block.id}`);
            target.parentNode.scrollTop = target.offsetTop - 140;

            Array.from(document.querySelectorAll('.active-card')).forEach(function (el) {
                el.classList.remove('active-card');
            });
            document.getElementById(`block-card-${block.id}`).classList.add("active-card")
        } else {
            handleSelectBlock(e);
            document.getElementById('displayPanel').scrollTop = 0;
        }
    }

    return (
        <div id={`block-card-${block.id}`} className={`${style.headerCrad} ${block.id == '0' ? style.attachementsCard : ''} ${index == 0 ? 'active-card' : ''} ${feilds?.length > 0 ? '' : style.hiddenBlock}`} onClick={e => _handleScrollIntoView(e)} title={block.id == '0' ? t('attachments') : isEnglish() ? block.userDescLo : block.userDescFo}>
            <span>
                {/* we use this span for counter in secound mood dont remove it */}
                <span></span>
                <div className={style.doteStatus} style={{ backgroundColor: mandatoryFeildsPer == 100 ? "var(--success)" : mandatoryFeildsPer == 0 ? "var(--error)" : "var(--worning)" }}>
                </div>
            </span>
            <div className={style.cradDetailes}>
                <div className={style.cradName}>
                    <label className={style.cradTitLbl}>{block.id == '0' ? t('attachments') : isEnglish() ? block.userDescLo : block.userDescFo}</label>
                </div>
                {block.id != '0' &&
                    <>
                        <div className={style.mainProgressBar}>
                            <Progress
                                prec={mandatoryFeilds.tot > 0 ? ((mandatoryFeilds.totFilled * 100) / mandatoryFeilds.tot) : 100}
                                color="var(--secondary-2)"
                                className={style.progressBar}
                                customValueComponent={
                                    <CountUp duration={DURATION} decimals={DECIMALS} separator={SEPARATOR} end={mandatoryFeilds.tot > 0 ? ((mandatoryFeilds.totFilled * 100) / mandatoryFeilds.tot) : 100} />
                                }
                            />
                        </div>
                        <div className={style.mainProgressBar}>
                            <Progress
                                prec={(optionalFeilds?.totFilled * 100) / optionalFeilds.tot}
                                color="var(--primary-color)"
                                className={style.progressBar}
                                customValueComponent={
                                    <CountUp duration={DURATION} decimals={DECIMALS} separator={SEPARATOR} end={(optionalFeilds.totFilled * 100) / optionalFeilds.tot} />
                                }
                            />
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default HeaderCard;