export const FORM_TYPE_LISTING = "LISTING";
export const FORM_TYPE_DEFINITION = "DEFINITION";
export const FORM_TYPE_TEMPLATE = "TEMPLATE";
export const FORM_TYPE_TREE = "TREE";
export const FORM_TYPE_REPORT = "REPORT";

/********************************* FND MODULE ***********************************/
export const FND0042_USERS_DEFINITION_FORM_ID = "42";
export const FND0133_USERS_LOGIN_LOG_FORM_ID = "133";
export const FND2319_CITIES_DEFINITION_FORM_ID = "2319";
export const FND2621_TOWNS_DEFINITION_FORM_ID = "2621";
export const FND3168_REPORTS_DESIGN_FORM_ID = "3168";
/********************************* FND MODULE ***********************************/

/********************************* RSM MODULE ***********************************/
export const RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID = "2651";
export const RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID = "2653";
export const RSM2659_PROPERTIES_TYPES_FORM_ID = "2659";
export const RSM2661_PROPERTIES_USERS_FORM_ID = "2661";
export const RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID = "2685";
export const RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID = "3003";
export const RSM3007_PRICE_AGREEMENT_FORM_ID = "3007";
export const RSM3009_CREATE_CUSTOMER_RSM_FORM_ID = "3009";
export const RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID = "3011";
export const RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID = "3015";
export const RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID = "3017";
export const RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID = "3043";
export const RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID = "3055";
export const RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID = "3059";
export const RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID = "3061";
export const RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID = "3079";
export const RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID = "3081";
export const RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID = "3085";
export const RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID = "3131";
export const RSM3190_EVALUATION_QUOTATIONS_FORM_ID = "3190";
export const RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID = "3192";
export const RSM3230_VALUATION_TRANSACTIONS_FORM_ID = "3230";
export const RSM3232_DELEGATION_FORM_ID = "3232";
export const RSM3234_PROPERTIES_MAP_FORM_ID = "3234";
export const RSM3236_VALUATION_HISTORY_FORM_ID = "3236";
export const RSM3238_EMAILS_SETTINGS_FORM_ID = "3238";
export const RSM3240_NOT_EXECUTED_TASKS_FORM_ID = "3240";
export const RSM3242_TRANSACTIONS_STATISTICS_FORM_ID = "3242";
export const RSM3244_TASKS_STATISTICS_FORM_ID = "3244";
export const RSM3246_INBOX_FORM_ID = "3246";
export const RSM3248_OUTBOX_FORM_ID = "3248";
export const RSM3250_VALUATION_REPORTS_FORM_ID = "3250";
export const RSM3252_TASKS_LIST_FORM_ID = "3252";
export const RSM3005_PROPERTIES_ESTIMATION = "3005";
export const RSM3268_CUSTOMER_DUES_FOLLOW_UP = "3268";
export const RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP = "3270";
export const RSM2669_BRANCHES_DEFINITIONS_FORM_ID = "2669"
export const RSM3274_CONTROL_WITH_EXCEL_FIELDS = "3274";
export const RSM3266_IMPORTATNT_LINKS = "3266";
export const RSM2667_DIVISIONS_DEFINITIONS_FORM_ID = "2667";
export const RSM3272_EVALUATION_CONTRACTS_FORM_ID = "3272";
export const RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID = "3278";

export const RSM2877_ADDITIONAL_ITEMS_FORM_ID = "2877";

/********************************* RSM MODULE ***********************************/

/********************************* MGR MODULE ***********************************/
export const MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID = "3188";
/********************************* MGR MODULE ***********************************/