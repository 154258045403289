import React from "react";

export const AtLeastOneReadIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.77"
            height="12.232"
            viewBox="0 0 19.77 12.232"
        >
            <g
                fill="#756f86"
                data-name="Group 178519"
                transform="translate(-1703 -400)"
            >
                <path
                    d="M4.531 12.232a.749.749 0 01-.53-.22L.219 8.23A.75.75 0 011.28 7.17l3.2 3.2L12.588.583a.75.75 0 111.154.957L5.108 11.961a.751.751 0 01-.542.27h-.035"
                    data-name="Path 44752"
                    transform="translate(1703 400)"
                ></path>
                <path
                    d="M19.5.173a.748.748 0 00-1.056.1l-8.107 9.786-1.4-1.366-.965 1.12L9.857 11.7a.751.751 0 00.531.22h.035a.749.749 0 00.542-.27L19.6 1.229a.75.75 0 00-.1-1.056"
                    data-name="Path 44753"
                    transform="translate(1703 400)"
                ></path>
            </g>
        </svg>
    );
}

export const NoOneReadIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.915"
            height="11.92"
            viewBox="0 0 13.915 11.92"
        >
            <path
                fill="#756f86"
                d="M4.531 12.232a.749.749 0 01-.53-.22L.219 8.23A.75.75 0 011.28 7.17l3.2 3.2L12.588.583a.75.75 0 111.154.957L5.108 11.961a.751.751 0 01-.542.27h-.035"
                data-name="Path 44755"
                transform="translate(0 -.312)"
            ></path>
        </svg>
    );
}

export const AllReadIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.77"
            height="12.232"
            viewBox="0 0 19.77 12.232"
        >
            <g
                fill="#668fff"
                data-name="Group 178522"
                transform="translate(-1703 -400)"
            >
                <path
                    d="M4.531 12.232a.749.749 0 01-.53-.22L.219 8.23A.75.75 0 011.28 7.17l3.2 3.2L12.588.583a.75.75 0 111.154.957L5.108 11.961a.751.751 0 01-.542.27h-.035"
                    data-name="Path 44752"
                    transform="translate(1703 400)"
                ></path>
                <path
                    d="M19.5.173a.748.748 0 00-1.056.1l-8.107 9.786-1.4-1.366-.965 1.12L9.857 11.7a.751.751 0 00.531.22h.035a.749.749 0 00.542-.27L19.6 1.229a.75.75 0 00-.1-1.056"
                    data-name="Path 44753"
                    transform="translate(1703 400)"
                ></path>
            </g>
        </svg>
    );
}

export const OneExecuteIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.77"
            height="19.234"
            viewBox="0 0 19.77 19.234"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        fill="none"
                        d="M0 0H9.868V13.118H0z"
                        data-name="Rectangle 164068"
                    ></path>
                </clipPath>
            </defs>
            <g data-name="Group 178521" transform="translate(-1675 -401.27)">
                <g
                    fill="#668fff"
                    data-name="Group 178518"
                    transform="translate(-28 8.272)"
                >
                    <path
                        d="M4.531 12.232a.749.749 0 01-.53-.22L.219 8.23A.75.75 0 011.28 7.17l3.2 3.2L12.588.583a.75.75 0 111.154.957L5.108 11.961a.751.751 0 01-.542.27h-.035"
                        data-name="Path 44752"
                        transform="translate(1703 400)"
                    ></path>
                    <path
                        d="M19.5.173a.748.748 0 00-1.056.1l-8.107 9.786-1.4-1.366-.965 1.12L9.857 11.7a.751.751 0 00.531.22h.035a.749.749 0 00.542-.27L19.6 1.229a.75.75 0 00-.1-1.056"
                        data-name="Path 44753"
                        transform="translate(1703 400)"
                    ></path>
                </g>
                <g data-name="Group 178520" transform="translate(1675.017 401.27)">
                    <g clipPath="url(#clip-path)" data-name="Group 178520">
                        <path
                            fill="#cebc1b"
                            d="M8.669 5.282H7.19V1.206A1.19 1.19 0 006.413.081 1.183 1.183 0 005.086.41L.3 5.844a1.2 1.2 0 00.9 2h1.478v4.076a1.19 1.19 0 00.778 1.125 1.254 1.254 0 00.429.075 1.191 1.191 0 00.9-.4l4.781-5.442a1.2 1.2 0 00-.9-2m.183 1.371l-4.78 5.445a.238.238 0 01-.279.069.247.247 0 01-.164-.24v-4.55a.478.478 0 00-.474-.473H1.2a.247.247 0 01-.174-.066.253.253 0 01-.016-.357l4.784-5.44a.229.229 0 01.183-.09.2.2 0 01.095.019.247.247 0 01.164.24v4.55a.478.478 0 00.474.474h1.953a.247.247 0 01.173.066.253.253 0 01.016.357"
                            data-name="Path 44754"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}