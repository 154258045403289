import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty, isEnglish } from "@ipgd-gauge/utils";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { getActionList, getEvaluationFunctions } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";

const dialogId = "workflow-procedure-messages-dialog";

const Tab1 = (props) => {
    const { tab, data, setData } = props;
    const { t } = useTranslation('workflowProcedureMessages');

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label id={`${dialogId}-organization-lbl`} className='form-label mandatory'>{t('organization')}</label>
                        <FrmInput
                            id={`${dialogId}-organization`}
                            value={isEnglish() ? data?.orgDescLo : data?.orgDescFo}
                            disabled={true}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-workflow-lbl`} className='form-label mandatory'>{t('workflow')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-workflow`}
                            key={`${dialogId}-workflow-${data?.id}`}
                            value={data.fnoId}
                            api={() => getEvaluationFunctions(isEmpty(data.fnoId) ? [] : [data.fnoId], null, STATUS_ACTIVE, '1', false, data.fnoId)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => _handleDataChanged("fnoId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-action-lbl`} className='form-label mandatory'>{t('action')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-action`}
                            value={data.actionDdvId}
                            api={() => getActionList("1")}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => _handleDataChanged("actionDdvId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Tab1);