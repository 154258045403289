import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getDomainValues } from "../../../util/apis";
import { STATUS_DOMAIN } from "../../../util/constants";
import { RSM2669_BRANCHES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import StatusSelectMenu from "../../../components/status-select-menu";

const BranchesDefinitionFilter = (props, ref) => {
    const { tableRef } = props;
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({});
    const advanceFilterRef = useRef();

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }
    const getFilterData = () => {
        let data = Object.assign(filterData, {});
        return data;
    }
    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    return (
        <AdvanceFilter
            id="branchesDefinition-filter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={1}
            hideMoreOptions
            formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('branchesDefinition:status')}</label>
                            <StatusSelectMenu
                                id="branches-definition-filter-status"
                                value={filterData.active}
                                isClearable={true}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("active", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </AdvanceFilter>
    )
}

export default forwardRef(BranchesDefinitionFilter);