import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getCitiesLov, getCommonCustomers, getCommonPropertyTypes, getTownsList } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { RSM3236_VALUATION_HISTORY_FORM_ID } from "../../../util/constants/forms";

const EstimationsHistoryFilter = (props, ref) => {
    const { tableRef } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="estimations-history-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={2}
            formId={RSM3236_VALUATION_HISTORY_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationsHistory:city')}</label>
                            <FrmSelectMenu
                                id="estimations-history-filter-city"
                                value={filterData.cityIds}
                                onChange={value => _handleDataChanged("cityIds", value)}
                                defualtValueId={filterData.cityIds}
                                defualtValueApi={() => getCitiesLov(filterData.cityIds, null, null, null, null, false, true)}
                                api={(searchValue) => getCitiesLov(null, null, null, null, searchValue, false, true)}
                                descLo={['regionsCitiesLo']}
                                descFo={['regionsCitiesFo']}
                                isMulti={true}
                                isAsync={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationsHistory:town')}</label>
                            <FrmSelectMenu
                                id="estimations-history-filter-towns"
                                value={filterData.townIds}
                                onChange={value => _handleDataChanged("townIds", value)}
                                defualtValueId={filterData.townIds}
                                defualtValueApi={() => getTownsList(null, filterData.townIds, null, null, false, null, true)}
                                api={(searchValue) => getTownsList(searchValue, null, null, null, false, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                isAsync={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationsHistory:customer')}</label>
                            <FrmSelectMenu
                                id="estimations-history-filter-customers"
                                value={filterData.customerIds}
                                onChange={value => _handleDataChanged("customerIds", value)}
                                defualtValueId={filterData.customerIds}
                                defualtValueApi={() => getCommonCustomers(filterData.customerIds, null, null, false, false, false, [], false)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, false, false, false, [], false)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationsHistory:property_type')}</label>
                            <FrmSelectMenu
                                id="estimations-history-filter-property-type"
                                isMulti={true}
                                value={filterData.propertyTypeIds}
                                api={() => getCommonPropertyTypes(null, null, false, false, false, [], false, filterData.propertyTypeIds)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("propertyTypeIds", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </AdvanceFilter>
    )
}

export default forwardRef(EstimationsHistoryFilter);