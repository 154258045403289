
export const EditIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.76"
            height="21.751"
            viewBox="0 0 21.76 21.751"
        >
            <g
                fill="none"
                stroke="#292d32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                data-name="Group 180033"
                transform="translate(-901.285 -207.229)"
            >
                <path
                    d="M911.035 208.23h-2c-5 0-7 2-7 7v6c0 5 2 7 7 7h6c5 0 7-2 7-7v-2"
                    opacity="0.4"
                ></path>
                <path
                    d="M916.075 209.25l-7.88 7.88a2.712 2.712 0 00-.66 1.32l-.43 3.01a1.424 1.424 0 001.7 1.7l3.01-.43a2.8 2.8 0 001.32-.66l7.88-7.88c1.36-1.36 2-2.94 0-4.94s-3.58-1.36-4.94 0z"
                    data-name="Vector"
                ></path>
                <path
                    d="M914.945 210.381a7.144 7.144 0 004.94 4.94"
                    data-name="Vector"
                    opacity="0.4"
                ></path>
            </g>
        </svg>
    );
}

export const PdfTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64.042"
            height="66.3"
            viewBox="0 0 64.042 66.3"
        >
            <path
                fill="#c01a1a"
                d="M21.247 78.1a3.893 3.893 0 01-3.447-1.851c-1.288-2.084-.632-5.057.411-7.143 1.773-3.546 7.68-7.455 16.26-10.776a142.877 142.877 0 008.906-22.005A48.409 48.409 0 0140 19a9.457 9.457 0 011.175-5.056 4.355 4.355 0 013.7-2.147A5.6 5.6 0 0149 13.89a8.528 8.528 0 011.717 3.228 9.93 9.93 0 01.264 4.165 103.789 103.789 0 01-3.361 14.842 37.749 37.749 0 0012.372 15.852A87.9 87.9 0 0172 51a11.781 11.781 0 016.5 1.645 5.408 5.408 0 012.634 4.447 4.787 4.787 0 01-1.993 3.821 7.717 7.717 0 01-4.68 1.377 13.4 13.4 0 01-2.891-.338l-.064-.014-.063-.018A44.506 44.506 0 0159 56.166c-5.276.807-9.4 1.749-9.543 1.782a106.2 106.2 0 00-12.137 3.584c-2.32 4.4-6.418 11.427-10.154 14.1-2.33 1.657-4.266 2.468-5.919 2.468zm-.072-4h.072c.276 0 1.35-.124 3.59-1.724 1.711-1.222 4.025-4.219 6.5-8.355a44.028 44.028 0 00-5.166 2.84c-3.168 2.067-4.121 3.512-4.384 4.037a4.752 4.752 0 00-.612 3.196zM72.5 58.061a9.34 9.34 0 001.961.229c1.844 0 2.673-.7 2.673-1.2 0-.268-.222-.7-.844-1.113A8.029 8.029 0 0072 55c-1.927 0-4.094.115-6.478.343a42.372 42.372 0 006.978 2.718zm-32.42-1.846l-.1.185c2.707-.858 5.57-1.643 8.562-2.347h.008c.134-.031 2.411-.554 5.773-1.16l-.1-.068.4-.059a40.793 40.793 0 01-5.341-5.81 43.183 43.183 0 01-3.372-5.214l-.137.45-.065-.159A149.377 149.377 0 0139.93 56.27zm4.8-40.418c-.035 0-.14 0-.317.281A5.573 5.573 0 0044 19a43.119 43.119 0 001.328 10.321c.739-3.015 1.305-5.893 1.692-8.6a5.326 5.326 0 00-1.043-4.206 1.826 1.826 0 00-1.101-.715z"
                data-name="Path 44938"
                transform="translate(-17.088 -11.797)"
            ></path>
        </svg>
    );
}
export const WordTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.326"
            height="14.3"
            viewBox="0 0 14.326 14.3"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.174"
                    x2="0.826"
                    y1="-120.345"
                    y2="-119.214"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#2368c4"></stop>
                    <stop offset="0.5" stopColor="#1a5dbe"></stop>
                    <stop offset="1" stopColor="#1146ac"></stop>
                </linearGradient>
            </defs>
            <g data-name="Group 180024" transform="translate(-436.843 -140.85)">
                <g data-name="Group 179992" transform="translate(434.843 132)">
                    <path
                        fill="url(#linear-gradient)"
                        d="M3.194 8.85h11.938a1.193 1.193 0 011.194 1.191v11.918a1.193 1.193 0 01-1.194 1.191H3.194A1.192 1.192 0 012 21.959V10.041A1.192 1.192 0 013.194 8.85z"
                        data-name="Path 44872"
                    ></path>
                    <path
                        fill="#fff"
                        d="M6.9 17.988c.023.184.039.344.046.481h.028c.01-.13.032-.287.065-.47s.062-.338.089-.465l1.255-5.407h1.624l1.3 5.326a7.761 7.761 0 01.162 1h.022a7.6 7.6 0 01.135-.975l1.039-5.358h1.477l-1.824 7.748h-1.727l-1.237-5.126q-.054-.222-.122-.578t-.084-.52h-.021q-.021.189-.084.561c-.042.249-.075.432-.1.552L7.78 19.871H6.024L4.19 12.127h1.5l1.131 5.418a4.469 4.469 0 01.079.443z"
                        data-name="Path 44873"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const CancelTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.95"
            height="19.95"
            viewBox="0 0 19.95 19.95"
        >
            <g data-name="Group 180029" transform="translate(-724.518 -136.3)">
                <g
                    fill="#292d32"
                    data-name="Group 179982"
                    transform="translate(5544.732 12944)"
                >
                    <path
                        d="M9.225-.75a9.851 9.851 0 017.41 3.319A9.935 9.935 0 0119.2 9.225a9.08 9.08 0 01-.3 2.324 9.377 9.377 0 01-1.148 2.816A9.905 9.905 0 019.225 19.2a9.816 9.816 0 01-6.634-2.565A9.184 9.184 0 01.7 14.363 9.791 9.791 0 01-.75 9.225 9.961 9.961 0 019.225-.75zm0 18.45a8.46 8.46 0 007.243-4.108l.006-.011a7.856 7.856 0 00.967-2.377l.007-.029a7.577 7.577 0 00.252-1.95 8.438 8.438 0 00-2.182-5.655l-.007-.007a8.462 8.462 0 00-12.367-.249A8.433 8.433 0 00.75 9.225a8.292 8.292 0 001.226 4.357l.008.013a7.8 7.8 0 001.6 1.912l.015.013a8.319 8.319 0 005.626 2.18z"
                        opacity="0.5"
                        transform="translate(-4819.465 -12806.95)"
                    ></path>
                    <g transform="translate(-4812.708 -12800.192)">
                        <path
                            d="M4.889 5.616A.748.748 0 014.36 5.4L-.529.532a.75.75 0 010-1.061.75.75 0 011.061 0l4.886 4.864a.75.75 0 01-.529 1.282z"
                            data-name="Vector"
                            transform="translate(.046)"
                        ></path>
                        <path
                            d="M0 5.616A.748.748 0 01-.532 5.4a.75.75 0 010-1.061L4.36-.532a.75.75 0 011.061 0 .75.75 0 010 1.061L.529 5.4A.748.748 0 010 5.616z"
                            data-name="Vector"
                            transform="translate(0 .069)"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export const RecoveryTrxIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.76"
            height="14.119"
            viewBox="0 0 17.76 14.119"
        >
            <g
                fill={props.fill || "#292d32"}
                data-name="Group 180022"
                transform="translate(-274.12 -140.94)"
            >
                <path
                    d="M11 10.75H3a.75.75 0 010-1.5h8a4.25 4.25 0 000-8.5H0A.75.75 0 01-.75 0 .75.75 0 010-.75h11a5.75 5.75 0 010 11.5z"
                    opacity="0.4"
                    transform="translate(275.13 144.31)"
                ></path>
                <path
                    d="M2.56 5.87a.748.748 0 01-.53-.22L-.53 3.09a.75.75 0 010-1.061L2.03-.53a.75.75 0 011.061 0A.75.75 0 013.09.53L1.061 2.56 3.09 4.59a.75.75 0 01-.53 1.28z"
                    data-name="Vector"
                    transform="translate(274.87 141.69)"
                ></path>
            </g>
        </svg>
    );
}
export const HangUpTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g
                fill="#292d32"
                data-name="Group 180220"
                transform="translate(-205.22 -137.25)"
            >
                <path
                    d="M10-.75a10.75 10.75 0 017.6 18.35A10.75 10.75 0 012.4 2.4 10.68 10.68 0 0110-.75zm0 20A9.25 9.25 0 10.75 10 9.26 9.26 0 0010 19.25z"
                    opacity="0.4"
                    transform="translate(205.97 138)"
                ></path>
                <path
                    d="M2 7.15H.71A1.311 1.311 0 01-.75 5.73V.67A1.311 1.311 0 01.71-.75h1.3A1.311 1.311 0 013.47.67v5.06A1.316 1.316 0 012 7.15zM.75 5.65h1.22V.75H.75z"
                    data-name="Vector"
                    transform="translate(212 144.8)"
                ></path>
                <path
                    d="M2 7.15H.71A1.311 1.311 0 01-.75 5.73V.67A1.311 1.311 0 01.71-.75H2A1.311 1.311 0 013.46.67v5.06A1.311 1.311 0 012 7.15zM.75 5.65h1.21V.75H.75z"
                    data-name="Vector"
                    transform="translate(217.29 144.8)"
                ></path>
            </g>
        </svg>
    );
}
export const ResumeTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.505"
            height="21.42"
            viewBox="0 0 21.505 21.42"
        >
            <g data-name="Group 180019" transform="translate(-137.248 -137.29)">
                <g
                    fill="#292d32"
                    data-name="Group 179996"
                    transform="translate(5590 12944)"
                >
                    <path
                        d="M0 14.89a.748.748 0 01-.53-.22.75.75 0 010-1.061A9.194 9.194 0 002.183 7.06 9.169 9.169 0 00-.53.531a.75.75 0 010-1.061.75.75 0 011.061 0 10.66 10.66 0 013.153 7.59A10.684 10.684 0 01.53 14.67a.748.748 0 01-.53.22z"
                        transform="translate(-5434.93 -12803.07)"
                    ></path>
                    <path
                        d="M2.932 14.89a.748.748 0 01-.53-.219 10.739 10.739 0 010-15.2.75.75 0 011.061 0 .75.75 0 010 1.061 9.239 9.239 0 000 13.079.75.75 0 01-.53 1.281z"
                        data-name="Vector"
                        transform="translate(-5452.002 -12803.07)"
                    ></path>
                    <path
                        d="M3.3 1.3A10.821 10.821 0 01-.245.709a.75.75 0 01-.464-.954.75.75 0 01.954-.464A9.324 9.324 0 003.3-.2a9.612 9.612 0 003.055-.509.75.75 0 01.954.464.75.75 0 01-.464.954 11.109 11.109 0 01-3.538.591z"
                        data-name="Vector"
                        transform="translate(-5445.3 -12786.59)"
                    ></path>
                    <path
                        d="M6.6 1.3a.749.749 0 01-.245-.041A9.325 9.325 0 003.3.75a9.325 9.325 0 00-3.055.509A.75.75 0 01-.709.8a.75.75 0 01.464-.959A10.821 10.821 0 013.3-.75a10.821 10.821 0 013.545.591A.75.75 0 016.6 1.3z"
                        data-name="Vector"
                        transform="translate(-5445.3 -12805.96)"
                    ></path>
                    <path
                        d="M1.816-.75a3.669 3.669 0 011.829.545l2.9 1.68A3 3 0 018.27 4.014a3 3 0 01-1.725 2.539l-2.9 1.68a3.67 3.67 0 01-1.83.545C.281 8.778-.75 7.539-.75 5.694v-3.36C-.75.489.281-.75 1.816-.75zm0 8.028a2.241 2.241 0 001.079-.343l2.9-1.68c.62-.358.976-.811.976-1.241s-.355-.883-.975-1.241l-2.9-1.68A2.239 2.239 0 001.816.75C.854.75.75 1.858.75 2.334v3.36c0 .476.104 1.584 1.066 1.584z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(-5445.26 -12800.004)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const LogTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                fill="#292d32"
                data-name="Group 180035"
                transform="translate(-445.9 -205)"
            >
                <path
                    d="M5-.75h8A5.686 5.686 0 0117.362.865 5.869 5.869 0 0118.75 5v10a5.869 5.869 0 01-1.388 4.135A5.686 5.686 0 0113 20.75H5a5.686 5.686 0 01-4.362-1.615A5.869 5.869 0 01-.75 15V5A5.869 5.869 0 01.638.865 5.686 5.686 0 015-.75zm8 20a4.271 4.271 0 003.263-1.135A4.442 4.442 0 0017.25 15V5a4.442 4.442 0 00-.987-3.115A4.271 4.271 0 0013 .75H5a4.271 4.271 0 00-3.263 1.135A4.442 4.442 0 00.75 5v10a4.442 4.442 0 00.987 3.115A4.271 4.271 0 005 19.25z"
                    transform="translate(448.9 207)"
                ></path>
                <path
                    d="M4 4.75H2A2.753 2.753 0 01-.75 2V0A.75.75 0 010-.75.75.75 0 01.75 0v2A1.251 1.251 0 002 3.25h2a.75.75 0 010 1.5z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(460.4 209.5)"
                ></path>
                <path
                    d="M4 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(453.9 218)"
                ></path>
                <path
                    d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(453.9 222)"
                ></path>
            </g>
        </svg>
    );
}
export const EstimationQualityTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path d="M15.668 8.626L24 9.785l-6.065 5.874L19.415 24l-7.416-3.997L4.583 24l1.481-8.341L0 9.785l8.331-1.159L11.999 1l3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z"></path>
        </svg>
    );
}
export const StarTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.285"
            height="22.475"
            viewBox="0 0 23.285 22.475"
        >
            <g data-name="Group 175689" transform="translate(.647 .571)">
                <g data-name="Group 175690">
                    <path
                        fill="#f6a645"
                        stroke="#f6a645"
                        strokeWidth="1"
                        d="M20.05 6.516h-4.887a.616.616 0 01-.589-.428l-1.51-4.648a2.1 2.1 0 00-3.987 0l-1.51 4.648a.616.616 0 01-.589.428H2.091a2.1 2.1 0 00-1.235 3.792L4.81 13.18a.62.62 0 01.225.693l-1.51 4.648a2.1 2.1 0 003.226 2.343l3.949-2.873a.614.614 0 01.727 0l3.954 2.873a2.092 2.092 0 001.232.408h0a2.1 2.1 0 001.233-.408 2.066 2.066 0 00.761-2.344L17.1 13.872a.619.619 0 01.225-.692l3.954-2.873a2.1 2.1 0 00-1.229-3.791"
                        data-name="Path 6434"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const SendMessageTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.783"
            height="20.846"
            viewBox="0 0 20.783 20.846"
        >
            <g data-name="Group 180023" transform="translate(-349.439 -137.577)">
                <g transform="translate(349.439 137.577)">
                    <path
                        fill="#292d32"
                        d="M5.837-.75h7.609a6.667 6.667 0 014.9 1.686 6.667 6.667 0 011.686 4.9v4.756a8.035 8.035 0 01-.052.985c-.223 2.56-1.539 5.6-6.535 5.6h-.476a.066.066 0 00-.048.024l-1.427 1.9a2.331 2.331 0 01-1.855.99 2.332 2.332 0 01-1.853-.991l-1.409-1.874a.349.349 0 00-.09-.047h-.45c-1.674 0-3.561-.153-4.856-1.284C-.2 14.865-.75 13.18-.75 10.593V5.837A6.921 6.921 0 01.665 1.232 5.932 5.932 0 014.852-.7a8.035 8.035 0 01.985-.05zm7.609 16.15c3.015 0 4.527-1.267 4.759-3.987v-.035a6.267 6.267 0 00.041-.781v-4.76a4.989 4.989 0 00-1.16-3.637 4.989 4.989 0 00-3.639-1.164h-7.61a6.267 6.267 0 00-.781.041h-.035C2.3 1.31 1.033 2.823 1.033 5.837v4.756c0 2.034.356 3.292 1.12 3.959.678.592 1.779.844 3.683.844h.476a1.969 1.969 0 011.469.731l.005.007 1.427 1.9a.578.578 0 00.428.276.576.576 0 00.427-.275l1.428-1.9a1.852 1.852 0 011.474-.737z"
                        transform="translate(.75 .75)"
                    ></path>
                </g>
                <path
                    fill="#292d32"
                    d="M.7 1.878H.684a1.189 1.189 0 010-2.378H.7a1.189 1.189 0 110 2.378z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(363.314 146.4)"
                ></path>
                <path
                    fill="#292d32"
                    d="M.7 1.878H.684a1.189 1.189 0 010-2.378H.7a1.189 1.189 0 110 2.378z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(359.153 146.4)"
                ></path>
                <path
                    fill="#292d32"
                    d="M.7 1.878H.684a1.189 1.189 0 010-2.378H.7a1.189 1.189 0 110 2.378z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(354.992 146.4)"
                ></path>
            </g>
        </svg>
    );
}
export const ViewEstimationFormTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                fill="#292d32"
                data-name="Group 180035"
                transform="translate(-445.9 -205)"
            >
                <path
                    d="M5-.75h8A5.686 5.686 0 0117.362.865 5.869 5.869 0 0118.75 5v10a5.869 5.869 0 01-1.388 4.135A5.686 5.686 0 0113 20.75H5a5.686 5.686 0 01-4.362-1.615A5.869 5.869 0 01-.75 15V5A5.869 5.869 0 01.638.865 5.686 5.686 0 015-.75zm8 20a4.271 4.271 0 003.263-1.135A4.442 4.442 0 0017.25 15V5a4.442 4.442 0 00-.987-3.115A4.271 4.271 0 0013 .75H5a4.271 4.271 0 00-3.263 1.135A4.442 4.442 0 00.75 5v10a4.442 4.442 0 00.987 3.115A4.271 4.271 0 005 19.25z"
                    transform="translate(448.9 207)"
                ></path>
                <path
                    d="M4 4.75H2A2.753 2.753 0 01-.75 2V0A.75.75 0 010-.75.75.75 0 01.75 0v2A1.251 1.251 0 002 3.25h2a.75.75 0 010 1.5z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(460.4 209.5)"
                ></path>
                <path
                    d="M4 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(453.9 218)"
                ></path>
                <path
                    d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(453.9 222)"
                ></path>
            </g>
        </svg>
    );
}
export const InspectionEstimationTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g
                fill="#292d32"
                data-name="Group 176521"
                transform="translate(-685.25 -189.25)"
            >
                <path
                    d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    opacity="0.4"
                    transform="translate(698 193)"
                ></path>
                <path
                    d="M3 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(698 196)"
                ></path>
                <path
                    d="M9.5 19.75A10.246 10.246 0 01-.75 9.5 10.246 10.246 0 019.5-.75a.75.75 0 01.75.75.75.75 0 01-.75.75 8.75 8.75 0 108.75 8.75.75.75 0 011.5 0A10.246 10.246 0 019.5 19.75z"
                    data-name="Vector"
                    transform="translate(686 190)"
                ></path>
                <path
                    d="M2 2.75a.748.748 0 01-.53-.22l-2-2a.75.75 0 010-1.06.75.75 0 011.06 0l2 2A.75.75 0 012 2.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(704 208)"
                ></path>
            </g>
        </svg>
    );
}
export const CommentTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g data-name="Group 176524" transform="translate(-173.25 -460.25)">
                <path
                    fill="#292d32"
                    d="M6-.75h8c4.416 0 6.75 2.334 6.75 6.75v8c0 4.416-2.334 6.75-6.75 6.75H1A1.752 1.752 0 01-.75 19V6C-.75 1.584 1.584-.75 6-.75zm8 20c3.582 0 5.25-1.668 5.25-5.25V6c0-3.582-1.668-5.25-5.25-5.25H6C2.418.75.75 2.418.75 6v13a.253.253 0 00.25.25z"
                    transform="translate(174 461)"
                ></path>
                <g data-name="Group 52" opacity="0.4" transform="translate(0 79)">
                    <path
                        fill="#fff"
                        d="M0 0h7"
                        data-name="Vector"
                        transform="translate(180.5 392)"
                    ></path>
                    <path
                        fill="#292d32"
                        d="M7 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h7a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                        data-name="Vector - Outline"
                        transform="translate(180.5 392)"
                    ></path>
                    <path
                        fill="#fff"
                        d="M0 7V0"
                        data-name="Vector"
                        transform="translate(184 388.5)"
                    ></path>
                    <path
                        fill="#292d32"
                        d="M0 7.75A.75.75 0 01-.75 7V0A.75.75 0 010-.75.75.75 0 01.75 0v7a.75.75 0 01-.75.75z"
                        data-name="Vector - Outline"
                        transform="translate(184 388.5)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const WorkflowStatusIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.489"
            height="20.78"
            viewBox="0 0 21.489 20.78"
        >
            <g
                fill="#292d32"
                data-name="Group 176527"
                transform="translate(-749.256 -509.61)"
            >
                <path
                    d="M6.25-.75A7 7 0 0111.2 11.2a7 7 0 01-9.9-9.9A6.955 6.955 0 016.25-.75zm0 12.5a5.5 5.5 0 003.89-9.39 5.5 5.5 0 00-7.78 7.78 5.464 5.464 0 003.89 1.61z"
                    transform="translate(753.75 514.12)"
                ></path>
                <path
                    d="M6.243 11.26a.748.748 0 01-.278-.054A10.582 10.582 0 01.684 6.625 10.6 10.6 0 01-.661-.1a.75.75 0 01.84-.648A.75.75 0 01.827.1a9.11 9.11 0 001.155 5.774 9.1 9.1 0 004.54 3.94.75.75 0 01-.279 1.447z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(750.007 519.13)"
                ></path>
                <path
                    d="M0 2.87a.749.749 0 01-.591-.288.75.75 0 01.129-1.053 10.7 10.7 0 0113.107-.076.75.75 0 11-.909 1.193 9.2 9.2 0 00-11.274.065A.747.747 0 010 2.87z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(753.85 510.36)"
                ></path>
                <path
                    d="M0 11.26a.75.75 0 01-.7-.472.75.75 0 01.418-.975A9.1 9.1 0 004.26 5.875 9.111 9.111 0 005.416.1a.75.75 0 01.648-.84.75.75 0 01.836.64 10.6 10.6 0 01-1.341 6.726 10.581 10.581 0 01-5.28 4.58.748.748 0 01-.279.054z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(763.75 519.13)"
                ></path>
            </g>
        </svg>
    );
}
export const DivertIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.511"
            height="15.48"
            viewBox="0 0 19.511 15.48"
        >
            <g data-name="Group 179974" transform="translate(5244.349 11635.75)">
                <g
                    fill="#292d32"
                    data-name="Group 179973"
                    transform="translate(-5354.599 -12213)"
                >
                    <path
                        d="M2.55 12.73L0 12.72a.75.75 0 01-.747-.753A.75.75 0 010 11.22l2.55.01a1.956 1.956 0 001.633-.866l6.39-9.58A3.422 3.422 0 0113.468-.75l4.545.02a.75.75 0 01.747.753.751.751 0 01-.753.747l-4.55-.02a1.93 1.93 0 00-1.633.866L5.434 11.2a3.455 3.455 0 01-2.884 1.53z"
                        transform="translate(111 578.01)"
                    ></path>
                    <path
                        d="M5.891 3.37a.749.749 0 01-.609-.312L4.2 1.558A1.927 1.927 0 002.619.75h-.006L0 .76A.75.75 0 01-.75.013.75.75 0 010-.74l2.6-.01A3.428 3.428 0 015.419.683l1.08 1.5a.75.75 0 01-.608 1.187z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(111 578)"
                    ></path>
                    <g data-name="Group 168" opacity="0.4">
                        <path
                            d="M0 2.75a.748.748 0 01-.53-.22.75.75 0 010-1.061l2-2a.75.75 0 011.061 0A.75.75 0 012.53.53l-2 2a.748.748 0 01-.53.22z"
                            data-name="Vector"
                            transform="translate(127 589.98)"
                        ></path>
                        <path
                            d="M3.373 3.37A3.488 3.488 0 01.627 2.029L-.592.46A.75.75 0 01-.46-.592.75.75 0 01.592-.46l1.22 1.57a1.978 1.978 0 001.558.76l4.667-.02a.75.75 0 11.006 1.5z"
                            data-name="Vector"
                            transform="translate(120.971 587.38)"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export const ChangePriorityTrxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g transform="translate(-108 -188)">
                <path
                    fill="#292d32"
                    d="M0 7.75a.75.75 0 01-.653-.38.75.75 0 01.01-.755l1.05-1.75a.75.75 0 011.286.772l-.27.451A6.261 6.261 0 006.25 0a.75.75 0 011.5 0 7.7 7.7 0 01-2.268 5.482A7.7 7.7 0 010 7.75z"
                    opacity="0.4"
                    transform="translate(123 203)"
                ></path>
                <path
                    fill="#292d32"
                    d="M0 7.75A.75.75 0 01-.75 7 7.745 7.745 0 017-.75a.75.75 0 01.653.38.75.75 0 01-.01.755l-1.05 1.75a.75.75 0 11-1.286-.772l.27-.451A6.261 6.261 0 00.75 7a.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(110 190)"
                ></path>
                <path
                    fill="#c00303"
                    stroke="#c00303"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M131 194.5a4.5 4.5 0 11-4.5-4.5 4.5 4.5 0 014.5 4.5z"
                    data-name="Vector"
                ></path>
                <path
                    fill="#09b92d"
                    stroke="#09b92d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M119 206.5a4.5 4.5 0 11-4.5-4.5 4.5 4.5 0 014.5 4.5z"
                    data-name="Vector"
                ></path>
            </g>
        </svg>
    );
}
export const ViewEstimationFormIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g data-name="Group 176524" transform="translate(-678.25 -34.25)">
                <g
                    data-name="vuesax/twotone/document-copy"
                    transform="translate(-7 -155)"
                >
                    <g fill="#292d32">
                        <path
                            d="M9.4 15.75H5.6c-2.259 0-3.794-.483-4.83-1.52S-.75 11.659-.75 9.4V5.6c0-2.259.483-3.794 1.52-4.83S3.341-.75 5.6-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75h-3C2.019.75.75 2.019.75 5.6v3.8c0 3.581 1.269 4.85 4.85 4.85h3.8c3.581 0 4.85-1.269 4.85-4.85v-3a.75.75 0 011.5 0v3c0 2.259-.483 3.794-1.52 4.83s-2.571 1.52-4.83 1.52z"
                            opacity="0.4"
                            transform="translate(686 195)"
                        ></path>
                        <path
                            d="M6.4 7.15H3.2a4.028 4.028 0 01-3.03-.92A4.028 4.028 0 01-.75 3.2V0a.75.75 0 01.463-.693.75.75 0 01.817.163l6.4 6.4a.75.75 0 01-.53 1.28zM.75 1.811V3.2c0 1.992.458 2.45 2.45 2.45h1.389z"
                            data-name="Vector"
                            transform="translate(694.6 195)"
                        ></path>
                        <path
                            d="M0 3.75A.75.75 0 01-.75 3 3.754 3.754 0 013-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75H3A2.253 2.253 0 00.75 3a.75.75 0 01-.75.75z"
                            data-name="Vector"
                            opacity="0.4"
                            transform="translate(691 190)"
                        ></path>
                        <path
                            d="M0 9.75a.75.75 0 010-1.5 2.062 2.062 0 002.06-2.06V0a.75.75 0 011.5 0v6.19A3.564 3.564 0 010 9.75z"
                            data-name="Vector"
                            opacity="0.4"
                            transform="translate(703.19 196)"
                        ></path>
                        <path
                            d="M6 6.75H3a3.817 3.817 0 01-2.874-.876A3.817 3.817 0 01-.75 3V0a.75.75 0 01.463-.693.75.75 0 01.817.163l6 6A.75.75 0 016 6.75zM.75 1.811V3a2.6 2.6 0 00.437 1.813A2.6 2.6 0 003 5.25h1.189z"
                            data-name="Vector"
                            transform="translate(700 190)"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export const InspectionEstimationIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.48"
            height="21.48"
            viewBox="0 0 21.48 21.48"
        >
            <g data-name="Group 176523" transform="translate(2414.75 -9512.25)">
                <g
                    fill="#292d32"
                    data-name="Group 176522"
                    transform="translate(-3100 9067)"
                >
                    <g data-name="Group 567" opacity="0.4">
                        <path
                            d="M2.83 6.35h-1.5A2.118 2.118 0 01-.75 4.2.75.75 0 010 3.45a.75.75 0 01.75.75.626.626 0 00.58.65h1.5a.449.449 0 00.41-.47c0-.346-.062-.37-.292-.453l-2.4-.839A1.8 1.8 0 01-.75 1.22 1.943 1.943 0 011.16-.75h1.51A2.138 2.138 0 014.75 1.4a.75.75 0 01-1.5 0 .633.633 0 00-.6-.65H1.16a.449.449 0 00-.41.47c0 .347.058.367.3.453l2.4.839h.007A1.8 1.8 0 014.74 4.38a1.943 1.943 0 01-1.91 1.97z"
                            transform="translate(692 451.2)"
                        ></path>
                        <path
                            d="M0 1.49A.75.75 0 01-.75.74V0A.75.75 0 010-.75.75.75 0 01.75 0v.74a.75.75 0 01-.75.75z"
                            data-name="Vector"
                            transform="translate(694 456.85)"
                        ></path>
                        <path
                            d="M0 1.53A.75.75 0 01-.75.78V0A.75.75 0 010-.75.75.75 0 01.75 0v.78a.75.75 0 01-.75.75z"
                            data-name="Vector"
                            transform="translate(694 450.41)"
                        ></path>
                    </g>
                    <path
                        d="M7.99-.75A8.74 8.74 0 11-.75 7.99 8.75 8.75 0 017.99-.75zm0 15.98A7.24 7.24 0 10.75 7.99a7.248 7.248 0 007.24 7.24z"
                        data-name="Vector"
                        transform="translate(686 446)"
                    ></path>
                    <path
                        d="M4.05 9.73A5.726 5.726 0 01-.612 7.314a.75.75 0 01.178-1.046.75.75 0 011.046.178A4.2 4.2 0 008.25 4.03 4.229 4.229 0 006.492.609a.75.75 0 01-.17-1.047.75.75 0 011.047-.17A5.7 5.7 0 014.05 9.73z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(696.98 457)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const CopyIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.499"
            viewBox="0 0 21.5 21.499"
        >
            <g
                fill="#292d32"
                data-name="Group 179931"
                transform="translate(-685.25 -189.251)"
            >
                <path
                    d="M9.4 15.75H5.6c-2.259 0-3.794-.483-4.83-1.52S-.75 11.659-.75 9.4V5.6c0-2.259.483-3.794 1.52-4.83S3.341-.75 5.6-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75h-3C2.019.75.75 2.019.75 5.6v3.8c0 3.581 1.269 4.85 4.85 4.85h3.8c3.581 0 4.85-1.269 4.85-4.85v-3a.75.75 0 011.5 0v3c0 2.259-.483 3.794-1.52 4.83s-2.571 1.52-4.83 1.52z"
                    transform="translate(686 195)"
                ></path>
                <path
                    d="M6.4 7.15H3.2a4.028 4.028 0 01-3.03-.92A4.028 4.028 0 01-.75 3.2V0a.75.75 0 01.463-.693.75.75 0 01.817.163l6.4 6.4a.75.75 0 01-.53 1.28zM.75 1.811V3.2c0 1.992.458 2.45 2.45 2.45h1.389z"
                    data-name="Vector"
                    transform="translate(694.6 195)"
                ></path>
                <path
                    d="M5271.19 11136.159a.751.751 0 01.749-.751 2.06 2.06 0 002.06-2.058v-5.441h-2.25a3.193 3.193 0 01-3.748-3.751v-2.25h-5.251a2.256 2.256 0 00-2.25 2.25.749.749 0 11-1.5 0 3.754 3.754 0 013.748-3.748h5.6a.736.736 0 01.2.026.758.758 0 01.732.191l6 6a.756.756 0 01.184.308c0 .01.006.021.009.031v.008c0 .01 0 .019.006.028 0 .012 0 .023.006.035v.033a.669.669 0 010 .094v6.173a3.563 3.563 0 01-3.561 3.559.749.749 0 01-.734-.737zm-1.691-12a1.788 1.788 0 002.25 2.25h1.191l-3.441-3.438z"
                    data-name="Union 392"
                    opacity="0.45"
                    transform="translate(-4568.75 -10931.159)"
                ></path>
            </g>
        </svg>
    );
}
export const SubmitIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.717"
            height="20.717"
            viewBox="0 0 20.717 20.717"
        >
            <g data-name="Group 180162" transform="translate(-366.852 -113.852)">
                <path
                    d="M7.57 8.659L4.629 5.717a.472.472 0 01.006-.666l.559-.559a.464.464 0 01.666-.006l3.546 3.545q.016.016.03.034l.211.213a.453.453 0 010 .637l-3.8 3.8a.452.452 0 01-.637 0l-.53-.53a.456.456 0 010-.639zm4.068 0L8.7 5.717a.472.472 0 010-.666l.559-.559a.464.464 0 01.666-.006l3.546 3.546q.016.016.03.034l.211.213a.453.453 0 010 .637l-3.8 3.8a.452.452 0 01-.637 0l-.53-.53a.456.456 0 010-.639z"
                    data-name="Path 44948"
                    transform="translate(368.704 115.615)"
                ></path>
                <path
                    fill="#292d32"
                    d="M10.609 20.967a10.359 10.359 0 1110.358-10.358 10.37 10.37 0 01-10.358 10.358zm0-19.217a8.859 8.859 0 108.859 8.859 8.869 8.869 0 00-8.859-8.859z"
                    data-name="Path 44947 - Outline"
                    opacity="0.45"
                    transform="translate(366.602 113.602)"
                ></path>
            </g>
        </svg>
    );
}
export const SendToIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.258"
            height="19.265"
            viewBox="0 0 19.258 19.265"
        >
            <g data-name="Group 180034" transform="translate(-771.772 -205.447)">
                <g fill="#292d32" data-name="Group 180159">
                    <path
                        d="M14.829-.75A3.522 3.522 0 0117.835.625c.793 1.1.888 2.732.27 4.6l-2.831 8.495a10.83 10.83 0 01-1.749 3.452 3.146 3.146 0 01-2.425 1.343 3.146 3.146 0 01-2.42-1.343 10.831 10.831 0 01-1.749-3.452l-.721-2.164-2.164-.721C.084 9.516-.749 7.841-.75 6.669s.83-2.851 4.795-4.177l8.491-2.83a7.319 7.319 0 012.293-.412zM11.1 17.015c.722 0 1.824-.99 2.748-3.769l2.83-8.49a3.7 3.7 0 00-.059-3.256 2.14 2.14 0 00-1.79-.75 5.838 5.838 0 00-1.819.334l-8.49 2.83C1.74 4.844.749 5.946.75 6.667s.991 1.819 3.769 2.744l2.52.84a.75.75 0 01.474.474l.84 2.52c.926 2.78 2.027 3.77 2.747 3.77z"
                        transform="translate(772.522 206.197)"
                    ></path>
                    <path
                        d="M0 4.34a.748.748 0 01-.53-.219.75.75 0 010-1.061L3.049-.53a.75.75 0 011.061 0 .75.75 0 010 1.061L.531 4.12A.748.748 0 010 4.34z"
                        data-name="Vector"
                        opacity="0.34"
                        transform="translate(779.514 213.14)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
export const SendBackIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.951"
            height="17.969"
            viewBox="0 0 20.951 17.969"
        >
            <path
                fill="#292d32"
                d="M0 20.51V14.4a7.865 7.865 0 017.856-7.856h4.216V5.16a1.746 1.746 0 012.98-1.234l5.133 5.132a2.619 2.619 0 010 3.7l-5.133 5.132a1.746 1.746 0 01-2.98-1.234v-1.383H6.983a5.243 5.243 0 00-5.237 5.237.873.873 0 01-1.746 0zM13.818 7.417a.873.873 0 01-.873.873H7.856a6.117 6.117 0 00-6.11 6.11v1.5a6.969 6.969 0 015.237-2.369h5.962a.873.873 0 01.873.873v2.257l5.132-5.132a.873.873 0 000-1.234L13.818 5.16z"
                transform="translate(0 -3.414)"
            ></path>
        </svg>
    );
}


