import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FrmInput from "@ipgd-gauge/frm-input";
import { RecoveryTrxIcon } from '../../../icons/EstimationTrx';
import LogIcon from "../../../icons/Log";
import LogChatIcon from '../../../icons/LogChat';
import MinusTrxIcon from '../../../icons/MinusTrx';
import TrxPlusIcon from '../../../icons/TrxPlus';
import XIcon from '../../../icons/X';
import { deleteNResetNote, getCustomer, getTrxSpecialRemarks, getTrxSummary, getValuationAuditLog, postNote } from '../../../util/apis';
import { formatDate, formatDateNTime } from '../../../util/dateUtil';
import { formatMoney, getUrlParam, handleError, isEnglish, isNotEmpty } from '../../../util/util';
import style from './style.module.scss';

const MODE_LOG = "log";

const SummaryHeader = (props) => {
    const { orderInfo } = props;
    const { t } = useTranslation("estimationTransaction");
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        if (orderInfo.atrId) {
            getCustomer(orderInfo.atrId).then(res => {
                setCustomer(res.data)
            })
        }
    }, [orderInfo.atrId])

    return (
        <div className={style.headerContainer}>
            <div className="headerTitlePanel">
                <div className="headerIconPanel">
                    <LogIcon />
                </div>
                <div className="headerLblPanel">
                    <label>{getUrlParam('mode') == MODE_LOG ? t('log') : t('special_remarks')}</label>
                </div>
            </div>
            <div className={style.headerDetails}>
                {/* <div className={style.imgDetails}></div> */}
                <div className={style.orgName}>
                    <label>{isEnglish() ? customer?.officialNameLo || '' : customer?.officialNameFo || ''}</label>
                    <label>{orderInfo?.code}</label>
                </div>
            </div>
        </div>
    )
}


const OrderInfo = (props) => {
    const { orderInfo } = props;

    const { t } = useTranslation("estimationTransaction");

    return (
        <div className={style.orderInfoContainer}>
            <div className={style.sectionTitle}>
                <label>{t('order_info')}</label>
            </div>
            <div className={`container ${style.containerOrder}`}>
                <div className={`row ${style.containerRow}`}>
                    <div className="w50">
                        <label className='form-label'>
                            {t('estimation_num')}
                        </label>
                        <div className={style.formVal}>
                            {orderInfo?.code}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('date_sum')}</label>
                        <div className={style.formVal}>
                            {formatDate(orderInfo.dateTime)}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>
                            {t('order_no')}
                        </label>
                        <div className={style.formVal}>
                            {orderInfo?.approvalNo}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>
                            {t('approval_no')}
                        </label>
                        <label className={style.formVal}>
                            {orderInfo?.orderNo}
                        </label>
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('client_name')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.clientName}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('property_type')}</label>
                        <div className={style.formVal}>
                            {isEnglish() ? orderInfo?.propertyTypeDescLo : orderInfo?.propertyTypeDescFo}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('usage')}</label>
                        <div className={style.formVal}>
                            {isEnglish() ? orderInfo?.propertyUseLo : orderInfo?.propertyUseFo}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>
                            {t('city')}
                        </label>
                        <div className={style.formVal}>
                            {isEnglish() ? orderInfo?.cityDescLo : orderInfo?.cityDescFo}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('town')}</label>
                        <div className={style.formVal}>
                            {isEnglish() ? orderInfo?.districtDescLo : orderInfo?.districtDescFo}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('land_area')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.parcelArea}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('the_surfaces')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.totalBuildArea}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('price_per_meter')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.parcelPricePerM}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('meter_costs')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.priceForMeter}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('property_age')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.propertyAge}
                        </div>
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('type')}</label>
                        <div className={style.formVal}>
                            {isEnglish() ? orderInfo?.buildingTypeLo : orderInfo?.buildingTypeFo}
                        </div>
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('finishing_type')}</label>
                        <div className={style.formVal}>
                            {isEnglish() ? orderInfo?.finishingTypeLo : orderInfo?.finishingTypeFo}
                        </div>
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('estimator_opinion')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.estimatorOpenion}
                        </div>
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('estimation_price')}</label>
                        <div className={` ${style.formVal} ${style.price}`}>
                            {formatMoney(orderInfo?.marketPrice)}
                        </div>
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('special_remarks')}</label>
                        <div className={style.formVal}>
                            {orderInfo?.privateRemarks}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


const LogCard = (props) => {
    const { log, index } = props;

    const chatId = `chat-${log?.id}`;
    const chatDataId = `chat-data-${log?.id}`;

    const AddChat = () => {
        document.getElementById(chatId).classList.toggle(style.inputChatActive);
        document.getElementById(chatDataId).classList.toggle(style.dataMsgActive);
        document.getElementById(`${index}-addBtn`).classList.toggle(style.minusBtn);
    }

    return (
        <div className={style.chatContainer}>
            <div className={style.senderContainer}>
                <div className={style.senderIcon}>
                    <LogChatIcon />
                    {/* <img src={log?.imagePath} /> */}
                </div>
                <div className={style.inputChatContainer}>
                    <div className={style.userInfo}>
                        <div className={style.userInfoName}>
                            <label>{isEnglish() ? log.nameLo : log.nameFo}</label>
                        </div>
                        <div className={style.userInfoDate}>
                            <label>{formatDateNTime(log.dateTime)}</label>
                        </div>
                    </div>
                    <div id={chatId} className={style.inputChat}>
                        <div className={style.titleMsg}>{log.descLo}</div>
                        <div id={chatDataId} className={style.dataMsg}>
                            {log?.details?.map(detail =>
                                <>
                                    {detail}
                                    <br />
                                </>
                            )}
                        </div>
                        {log?.details?.length > 0 &&
                            <button id={`${index}-addBtn`} className={style.plusBtn} onClick={AddChat}>
                                <TrxPlusIcon />
                                <MinusTrxIcon />
                            </button>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}


const Log = (props) => {
    const { id } = props;

    const { t } = useTranslation();

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        getValuationAuditLog(id, isEnglish())
            .then(response => setLogs(response.data))
            .catch(error => handleError(error, null, t));
    }, [i18next.language]);

    return (
        <div className={style.containerLog}>
            <div className={style.sectionTitle}>
                <label>{t('estimationTransaction:log')}</label>
            </div>
            <div className={style.logCardContainer}>
                {logs.map((log, index) => <LogCard log={log} index={index} />)}
            </div>
        </div>
    )
}


const SpecialNotesCard = (props) => {
    const { triId, specialRemark, setSpecialRemarksList } = props;

    const { t } = useTranslation();

    const chatId = `chat-${specialRemark?.id}`;
    const chatDataId = `chat-data-${specialRemark?.id}`;

    const AddChat = () => {
        document.getElementById(chatId).classList.toggle(style.inputChatActive);
        document.getElementById(chatDataId).classList.toggle(style.dataMsgActive);
    }

    const _deleteNResetNote = () => {
        if (isNotEmpty(specialRemark?.id)) {
            deleteNResetNote({ triId: triId, id: specialRemark?.id, status: !specialRemark?.isDeleted })
                .then(response => setSpecialRemarksList(response.data))
                .catch(error => handleError(error, null, t));
        }
    }

    return (
        <div className={style.sendAddNote}>
            <div className={style.senderContainer}>
                <button className={style.deleteBtn} onClick={_deleteNResetNote}>
                    {specialRemark?.isDeleted ? <RecoveryTrxIcon fill="var(--secondary-2)" /> : <XIcon />}</button>
                <div className={style.senderIcon}>
                    <LogChatIcon />
                </div>

                <div className={style.chatDataContainer}>
                    <div className={style.userInfo}>
                        <div className={style.userInfoName}>
                            <label>
                                {isEnglish() ? specialRemark?.userNameLo : specialRemark?.userNameFo}
                            </label>
                        </div>
                        <div className={style.userInfoDate}>
                            <label>{formatDateNTime(specialRemark.dateTime)}</label>
                        </div>
                    </div>
                    <div id={chatId} className={specialRemark?.isDeleted ? style.inputChatDisabled : style.inputChat}>
                        {/* <div className={style.titleMsg}>
                            {isEnglish() ? specialRemark?.userNameLo : specialRemark?.userNameFo}
                        </div> */}
                        <div id={chatDataId} className={specialRemark?.isDeleted ? style.dataMsgDisabled : style.dataMsgActive}>
                            {specialRemark?.note}
                        </div>
                        {/* <button className={style.plusBtn} onClick={AddChat}>
                            <TrxPlusIcon />
                        </button> */}
                    </div>
                </div>

            </div>
        </div>
    );
}


const SpecialNotes = (props) => {
    const { id } = props;
    const { t } = useTranslation();

    const [specialRemarksList, setSpecialRemarksList] = useState([]);
    const [note, setNote] = useState("");

    useEffect(() => {
        if (isNotEmpty(id)) {
            getTrxSpecialRemarks(id)
                .then((response) => setSpecialRemarksList(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, []);

    const _postNote = () => {
        if (isNotEmpty(note)) {
            postNote({ id: id, note: note })
                .then(response => setSpecialRemarksList(response.data))
                .catch(error => handleError(error, null, t))
                .finally(() => setNote(""));
        } else {
            handleError(null, "manadetory_fields", t);
        }
    }

    return (
        <div className={style.containerLog}>
            <div className={style.sectionTitle}>
                <label>{t('estimationTransaction:special_remarks')}</label>
            </div>

            <div className={style.specialNotesContainer}>
                {getUrlParam('mode') == 'note' &&
                    <div className={style.addNote}>
                        <FrmInput
                            id="note-input"
                            value={note}
                            onBlur={value => setNote(value)}
                        />
                        <button className="secondary-button" onClick={_postNote}>{t('add')}</button>
                    </div>
                }
                <div className={style.containerSpecialNotesCard}>
                    {specialRemarksList.map((specialRemark) => <SpecialNotesCard triId={id} specialRemark={specialRemark} setSpecialRemarksList={setSpecialRemarksList} />)}
                </div>
            </div>
        </div>
    )
}


const TrxSummary = () => {
    const id = getUrlParam('id');
    const [orderInfo, setOrderInfo] = useState({});
    const { t } = useTranslation("estimationTransaction");

    useEffect(() => {
        if (isNotEmpty(id)) {
            getTrxSummary(id)
                .then((response) => setOrderInfo(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, []);

    return (
        <div className={style.container}>
            <SummaryHeader orderInfo={orderInfo} />

            <div className={style.infoContainer}>
                <OrderInfo orderInfo={orderInfo} />
                {getUrlParam('mode') == MODE_LOG ? <Log id={id} /> : <SpecialNotes id={id} />}
            </div>
        </div>
    )
}


export default TrxSummary;