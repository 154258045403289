import { formatDate, formatTime, isEnglish } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modal";
import Table from "../../../../components/table";
import CheckMarkIcon from "../../../../icons/CheakMark";
import ClockIcon from "../../../../icons/Clock";
import DatePickerIcon from "../../../../icons/DatePicker";
import XIcon from "../../../../icons/X";
import { getUserLogs } from "../../../../util/apis";
import { FND0042_USERS_DEFINITION_FORM_ID } from "../../../../util/constants/forms";
import style from "./style.module.scss";

const Row = (props) => {
    const { virtualizedStyle, data } = props;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div><div title={data?.sequence}>{data?.sequence}</div></div>
            <div>
                <div title={formatDate(data?.time)}>
                    <span className={style.rowIcon}><DatePickerIcon /></span>
                    {formatDate(data?.time)}
                </div>
            </div>

            <div>
                <div title={formatTime(data?.time)}>
                    <span className={style.rowIcon}><ClockIcon /></span>
                    {formatTime(data?.time)}
                </div>
            </div>
            <div>
                <div title={data?.ip}>{data?.ip}</div>
            </div>
            <div>
                <div title={isEnglish() ? data.statusDescLo : data.statusDescFo} className={data.stat == "102602" ? "fail" : ""}>
                    {data.stat == "102601" ?
                        <CheckMarkIcon />
                        :
                        <span>
                            <XIcon />
                        </span>
                    }
                    {isEnglish() ? data.statusDescLo : data.statusDescFo}
                </div>
            </div>
        </div>
    );
};

const LogDialog = (props, ref) => {

    const { user } = props;

    const dialogRef = useRef();
    const { t } = useTranslation();

    const [logs, setLogs] = useState([]);

    const columns = [
        { title: '#', width: "8%" },
        { title: t('date'), width: "23%" },
        { title: t('time'), width: "23%" },
        { title: t('ip_address'), width: "23%" },
        { title: t('status'), width: "23%" },
    ];

    const open = (id) => {
        setLogs(null);
        dialogRef.current.open();
        getUserLogs(id).then((response) => setLogs(response.data || [])).catch(() => setLogs([]));
    }

    useImperativeHandle(ref, () => ({ open }));

    return (
        <Modal
            id="users-login-log-dialog"
            ref={dialogRef}
            title={t('usersDefinition:users_login_log')}
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={() => setLogs([])}
            withFooter
            withCancelButton
            style={{ "--num-of-logs": logs?.length || 0 }}
            className={style.usersLoginLogDialogContainer}
            isTableModal
        >
            <Table
                id="users-login-log-table"
                columns={columns}
                Row={Row}
                tableStyle={style.usersLoginLogTable}
                rowHeight={40}
                list={logs}
                user={user}
                formId={FND0042_USERS_DEFINITION_FORM_ID}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
            />
        </Modal>
    )
}

export default forwardRef(LogDialog);