import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { checkValidation, handleError, hideWaiting, isAllowedAction, isEmpty, parseNumber, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import SidePanel from "../../../components/side-panel";
import DelegationIcon from "../../../icons/Delegation";
import { getCommonPartiesLov, getDelegation, saveDelegation } from "../../../util/apis";
import { ESTIMATOR_PARTY, INSPECTOR_PARTY, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3232_DELEGATION_FORM_ID } from "../../../util/constants/forms";

const dialogId = "delegation-dialog";

const DelegationDialog = (props, ref) => {
    const { user, delegationTableRef } = props;
    const { t } = useTranslation();
    const dialogRef = useRef();
    const updateRecordRef = useRef(false);

    const [delegation, setDelegation] = useState({ isActive: STATUS_ACTIVE });

    const _handleDataChanged = (name, value) => {
        delegation[name] = value;

        if (name === "fromDateDgr" && parseNumber(value) > parseNumber(delegation?.toDateDgr)) {
            delegation.toDateDgr = null;
        }

        if (name === "toDateDgr" && parseNumber(value) < parseNumber(delegation?.fromDateDgr)) {
            delegation.fromDateDgr = null;
        }

        setDelegation({ ...delegation });
    }

    const _saveDelegation = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }
        showWaiting(`${dialogId}-btn`, true)
        saveDelegation(delegation)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                delegationTableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-btn`))
    }

    const _postClose = () => {
        setDelegation({ isActive: STATUS_ACTIVE })
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _populateRecordData = (id) => {
        if (id) {
            updateRecordRef.current = true;
            getDelegation(id)
                .then((response) => setDelegation(response.data))
                .catch((error) => handleError(error, null, t))
        } else {
            delegation.fromAprId = user.aprId
            setDelegation({ ...delegation })
        }
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            formId={RSM3232_DELEGATION_FORM_ID}
            title={t('delegation')}
            icon={<DelegationIcon />}
            footerLbl={t("save")}
            footerAction={_saveDelegation}
            withFooter={isAllowedAction(user, RSM3232_DELEGATION_FORM_ID, SAVE_ACTION)}
            postClose={_postClose}
            preClose={_preCloseAction}
            nextPreviousAction={_populateRecordData}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${dialogId}-from-lbl`} className='form-label mandatory'>{t('delegation:form')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-from`}
                            key={`from-${delegation.id || 0}`}
                            api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, isEmpty(delegation.fromAprId) ? [] : [delegation.fromAprId], STATUS_ACTIVE, null, 1)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            value={delegation.fromAprId}
                            onChange={e => _handleDataChanged("fromAprId", e.value)}
                            isDisabled
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-to-lbl`} className='form-label mandatory'>{t('delegation:to')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-to`}
                            key={`to-${delegation.id || 0}`}
                            api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, isEmpty(delegation.toAprId) ? [] : [delegation.toAprId], STATUS_ACTIVE, null, 1)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            value={delegation.toAprId}
                            onChange={e => _handleDataChanged("toAprId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-form-date-lbl`} className='form-label mandatory'>{t('delegation:date_form')}</label>
                        <FrmTimeDatePickr
                            id={`${dialogId}-form-date`}
                            value={delegation.fromDateDgr}
                            onChange={(value) => _handleDataChanged("fromDateDgr", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-to-date-lbl`} className='form-label mandatory'>{t('delegation:date_to')}</label>
                        <FrmTimeDatePickr
                            id={`${dialogId}-to-date`}
                            value={delegation.toDateDgr}
                            onChange={(value) => _handleDataChanged("toDateDgr", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-max-amount-lbl`} className='form-label mandatory'>{t('delegation:max_amount')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-max-amount`}
                            value={delegation.maximumAmountAlc}
                            onChange={(value) => _handleDataChanged("maximumAmountAlc", value)}
                            minValue={0}
                        />
                    </div>

                    <div className="w100">
                        <label id={`${dialogId}-status-lbl`} className='form-label mandatory'> {t('delegation:status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            sidePanelMode
                            options={[
                                {
                                    title: t('active'),
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: delegation.isActive == STATUS_ACTIVE
                                },
                                {
                                    title: t('inactive'),
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: delegation.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('delegation:remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-remark`}
                            value={delegation.rem}
                            onBlur={(value) => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(DelegationDialog);