import React from "react";

function PinMapIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.69 52.47">
      <defs>
        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
          <feOffset dx="2.83"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="2.83"></feGaussianBlur>
          <feFlood floodColor="#231f20" floodOpacity="0.31"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#drop-shadow-1)">
        <path
          fill="#fed402"
          d="M4.52 19.4L18.84 48.72 33.14 19.3 18.84 5.08 4.52 19.4z"
        ></path>
        <path
          fill="#fff"
          d="M34.37 18.18L18.84 2.66 3.31 18.18c-.49.49-.62 1.24-.31 1.87l14.38 29.57c.28.57.84.92 1.47.92s1.19-.35 1.47-.92L34.7 20.05c.3-.62.18-1.37-.31-1.86zM18.84 5.07l14.3 14.22-14.3 29.42L4.52 19.4 18.84 5.08z"
        ></path>
        <path
          fill="#f0efef"
          d="M18.84 5.1L18.97 5.23 33.14 19.4 18.84 48.82"
          opacity="0.33"
        ></path>
      </g>
    </svg>
  );
}

export default PinMapIcon;
