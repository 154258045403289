import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import CustomerIcon from "../../../icons/Customer";
import { getBranchesNDivisions, getPropertiesUsersDefinition, getRealEstateUserLOV, savePropertiesUsersDefinition } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, NOTIFICATION_DOMAIN_DOSE_NOT_SEND, PROPORTIES_USERS_TYPES_SUPERVISOR, STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";

const DIALOG_ID = "properties-users-definition-dialog";

const PropertiesUsersDefinitionDialog = (props, ref) => {
    const { user, tableRef } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const multiInsertDialogRef = useRef();
    const staffInsertDialogRef = useRef();
    const updateRecordRef = useRef(false);

    const DEFAULT_DATA = {
        fndUser: {
            sendWfEmail: NOTIFICATION_DOMAIN_DOSE_NOT_SEND,
            sendWfSms: NOTIFICATION_DOMAIN_DOSE_NOT_SEND
        },
        isActive: STATUS_ACTIVE,
        userLevel: CUSTOMER_LEVEL_PREDEFINED,
    }

    const [data, setData] = useState({ ...DEFAULT_DATA });

    useEffect(() => {
        dialogRef?.current?.controlTabVisibility([2], data?.type == PROPORTIES_USERS_TYPES_SUPERVISOR);
    }, [data?.type]);

    useEffect(() => {
        dialogRef?.current?.controlTabVisibility([4], data?.userLevel == CUSTOMER_LEVEL_NEW);
    }, [data?.userLevel]);

    const _okBtnAction = () => {

        if (!checkValidation([
            { id: DIALOG_ID },
            {
                id: `${DIALOG_ID}-mater-table`,
                title: t('usersDefinition:users_definition'),
                data: data?.fndUser?.maters,
                disabledConditions: {
                    fromDgr: (data) => !data?.hasTimeLimitation,
                    toDgr: (data) => !data?.hasTimeLimitation,
                    frmId: () => true,
                }
            }
        ], t)) {
            return
        }

        showWaiting(`${DIALOG_ID}-okButton`, true)
        savePropertiesUsersDefinition(data)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh()
                showSuccessMessage('save_successfully', t);
            })
            .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
            .finally(() => hideWaiting(`${DIALOG_ID}-okButton`));
    }

    const _insertBranchAndDivision = (selectedData) => {
        if (!data.branchesNDivisionsList) {
            data.branchesNDivisionsList = [];
        }

        let newLines = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                rowId: element.rowId,
                id: element.id,
                organizationId: element.organizationId,
                organizationDescLo: element.organizationDescLo,
                organizationDescFo: element.organizationDescFo,
                divisionDescLo: element.divisionDescLo,
                divisionDescFo: element.divisionDescFo,
                branchDescLo: element.branchDescLo,
                branchDescFo: element.branchDescFo
            });
        }

        data.branchesNDivisionsList = [...data.branchesNDivisionsList, ...selectedData];
        setData({ ...data });

        multiInsertDialogRef?.current?.close();
    }

    const _insertStaff = (selectedData) => {
        if (!data.realEstateUserStaffMemberList) {
            data.realEstateUserStaffMemberList = [];
        }

        let staffs = [];
        (selectedData || []).map((data => {
            staffs.push({ memberSsuId: data.id, code: data.code, descLo: data.descLo, descFo: data.descFo, codeDescLo: data.codeDescLo, codeDescFo: data.codeDescFo });
        }))

        data.realEstateUserStaffMemberList = [...data.realEstateUserStaffMemberList, ...staffs];
        setData({ ...data });

        staffInsertDialogRef.current.close();
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getPropertiesUsersDefinition(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setData({ ...DEFAULT_DATA })
        }
    }

    const _postClose = () => {
        setData({ isActive: STATUS_ACTIVE })
        removeAllMandatoryClasses(document.getElementById(DIALOG_ID))
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef?.current?.open();
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false
    }

    const close = () => dialogRef?.current?.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <>
            <ModalWithTabs
                id={DIALOG_ID}
                ref={dialogRef}
                formId={RSM2661_PROPERTIES_USERS_FORM_ID}
                Icon={<CustomerIcon />}
                title={'propertiesUsersDefinition:user_roles_security'}
                labels={
                    ['home', 'propertiesUsersDefinition:branches_divisions', 'propertiesUsersDefinition:staff', 'propertiesUsersDefinition:valuation_default_values', data?.userLevel == CUSTOMER_LEVEL_NEW ? 'usersDefinition:users_definition' : ""]
                }
                withFooter
                hideOkBtn={!(isAllowedAction(user, RSM2661_PROPERTIES_USERS_FORM_ID, SAVE_ACTION))}
                withOkButton
                withCancelButton
                okBtnStyleAction="primary-button"
                cancelBtnStyleAction="cancel-button"
                cancelBtnLabel={t('exit')}
                okBtnAction={_okBtnAction}
                postClose={_postClose}
                nextPreviousAction={(id) => _populateRecordData(id)}
                preClose={_preCloseAction}
            >
                <Tab1 data={data} setData={setData} />
                <Tab2 data={data} setData={setData} user={user} multiInsertDialogRef={multiInsertDialogRef} />
                <Tab3 data={data} setData={setData} user={user} staffInsertDialogRef={staffInsertDialogRef} />
                <Tab4 data={data} setData={setData} />
                <Tab5 data={data} setData={setData} use={user} />
            </ModalWithTabs>

            <MultiInsertDialog
                id="insert-branch-and-division-dialog"
                ref={multiInsertDialogRef}
                formId={RSM2661_PROPERTIES_USERS_FORM_ID}
                title={t('propertiesUsersDefinition:insert_branch_and_division')}
                okBtnAction={_insertBranchAndDivision}
                api={getBranchesNDivisions}
                idName={"rowId"}
                idSelectors={["id", "organizationId"]}
                selectedIds={(data.branchesNDivisionsList || []).map((line) => { return { id: line.id, organizationId: line.organizationId } })}
                searchPlaceholder={t('propertiesUsersDefinition:organization_name') + " , " + t('propertiesUsersDefinition:branch') + " , " + t('propertiesUsersDefinition:division')}
                columns={[
                    { width: "5%" },
                    { label: '#', width: "5%" },
                    { label: t('propertiesUsersDefinition:organization_name'), valueLo: 'organizationDescLo', valueFo: 'organizationDescFo', width: "30%" },
                    { label: t('propertiesUsersDefinition:branch'), valueLo: 'branchDescLo', valueFo: 'branchDescFo', width: "30%" },
                    { label: t('propertiesUsersDefinition:division'), valueLo: 'divisionDescLo', valueFo: 'divisionDescFo', width: "30%" }
                ]}
            />

            <MultiInsertDialog
                id="staff-insert-dialog"
                ref={staffInsertDialogRef}
                formId={RSM2661_PROPERTIES_USERS_FORM_ID}
                title={t('propertiesUsersDefinition:insert_staff')}
                okBtnAction={_insertStaff}
                api={(searchValue) => getRealEstateUserLOV(searchValue, STATUS_ACTIVE, false, false)}
                selectedIds={(data.realEstateUserStaffMemberList || []).map(a => a.memberSsuId)}
                searchPlaceholder={t('propertiesUsersDefinition:code') + " , " + t('propertiesUsersDefinition:name')}
                columns={[
                    { width: "5%" },
                    { label: '#', width: "5%" },
                    { label: t('usersDefinition:code'), value: 'code', width: "45%" },
                    { label: t('propertiesUsersDefinition:name'), valueLo: 'descLo', valueFo: 'descFo', width: "45%" }
                ]}
            />
        </>
    )
}

export default forwardRef(PropertiesUsersDefinitionDialog);