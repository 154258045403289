import React from "react";

export const TrnxMode1 = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g data-name="Group 179870" transform="translate(-1287.25 -145.25)">
                <path
                    fill="#c6cbd3"
                    d="M13 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h6c2.77 0 4.647.586 5.905 1.845S20.75 4.23 20.75 7v6c0 2.77-.586 4.647-1.845 5.905S15.77 20.75 13 20.75zm-6-20c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h6c2.346 0 3.886-.447 4.845-1.405S19.25 15.346 19.25 13V7c0-2.346-.447-3.886-1.405-4.845S15.346.75 13 .75z"
                    transform="translate(1288 146)"
                ></path>
                <g data-name="Group 178453" transform="translate(602 -44)">
                    <path
                        d="M0 20.75A.75.75 0 01-.75 20V0A.75.75 0 010-.75.75.75 0 01.75 0v20a.75.75 0 01-.75.75z"
                        data-name="Vector"
                        fill="#c6cbd3"
                        opacity="0.4"
                        transform="translate(696 190)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export const TrnxMode2 = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g
                fill="#c6cbd3"
                data-name="Group 179871"
                transform="translate(-1326.25 -146.25)"
            >
                <path
                    d="M8 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h6c2.77 0 4.647.586 5.905 1.845S20.75 4.23 20.75 7v2a.75.75 0 01-1.5 0V7c0-2.346-.447-3.886-1.405-4.845S15.346.75 13 .75H7c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h1a.75.75 0 010 1.5z"
                    transform="translate(1327 147)"
                ></path>
                <g data-name="Group 532" opacity="0.4" transform="translate(577 -235)">
                    <path
                        d="M19.97.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h19.97a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                        data-name="Vector"
                        transform="translate(750.029 388.5)"
                    ></path>
                    <path
                        d="M9.97.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h9.97a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                        data-name="Vector"
                        transform="translate(750.029 395.5)"
                    ></path>
                    <path
                        d="M0 20.73a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v19.98a.75.75 0 01-.75.75z"
                        data-name="Vector"
                        transform="translate(756.51 382.01)"
                    ></path>
                    <path
                        d="M0 10.73a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v9.98a.75.75 0 01-.75.75z"
                        data-name="Vector"
                        transform="translate(763.51 382.01)"
                    ></path>
                </g>
                <path
                    d="M4.8 3.95a.75.75 0 01-.75-.75v-.8C4.05 1.282 3.8.75 2.4.75S.75 1.282.75 2.4v.8a.75.75 0 01-.75.75.75.75 0 01-.75-.75v-.8A3.407 3.407 0 01-.134.23 3.079 3.079 0 012.4-.75a3.079 3.079 0 012.534.98A3.407 3.407 0 015.55 2.4v.8a.75.75 0 01-.75.75z"
                    data-name="Vector"
                    transform="translate(1340.6 159)"
                ></path>
                <path
                    d="M6 5.55H2a2.89 2.89 0 01-2.13-.62A2.89 2.89 0 01-.75 2.8V2A2.89 2.89 0 01-.13-.13 2.89 2.89 0 012-.75h4a2.89 2.89 0 012.13.62A2.89 2.89 0 018.75 2v.8a2.89 2.89 0 01-.62 2.13A2.89 2.89 0 016 5.55zM2 .75c-.735 0-.987.1-1.07.18S.75 1.265.75 2v.8c0 .735.1.987.18 1.07s.335.18 1.07.18h4c.735 0 .987-.1 1.07-.18s.18-.335.18-1.07V2c0-.735-.1-.987-.18-1.07S6.735.75 6 .75z"
                    data-name="Vector"
                    transform="translate(1339 162.2)"
                ></path>
            </g>
        </svg>
    );
}
export const OpenCloseMenu = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.41 16.09">
            <path
                fill="#f8f7f8"
                d="M8.51 16.09L0 8.06 7.58 0 8.52 0.89 1.84 8.01 9.41 15.15 8.51 16.09z"
            ></path>
        </svg>
    );
}