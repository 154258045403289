import { formatDate, formatMoney, handleError, isEnglish } from "@ipgd-gauge/utils";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import XIcon from "../../../icons/X";
import { getEstimationTransactionsRegisterList, getEstimationTransactionsRegisterTotals } from "../../../util/apis";
import { RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID } from "../../../util/constants/forms";
import { closeTotalTableSm } from "../../../util/util";
import style from './style.module.scss';

const Row = (props) => {
    const { data } = props;

    return (
        <div className={` reportsRowHeight ${style.estimationTransactionsRegisterRow}`}>
            <div title={isEnglish() ? data?.branchDivisionDescLo : data?.branchDivisionDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:branch_division')}</label>
                <div><label>{isEnglish() ? data?.branchDivisionDescLo : data?.branchDivisionDescFo}</label></div>
            </div>

            <div title={isEnglish() ? data?.estimationFormDescLo : data?.estimationFormDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:workflow')}</label>
                <div><label>{isEnglish() ? data?.estimationFormDescLo : data?.estimationFormDescFo}</label></div>
            </div>

            <div title={isEnglish() ? data?.transactionTypeLo : data?.transactionTypeFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:transaction_type')}</label>
                <div><label>{isEnglish() ? data?.transactionTypeLo : data?.transactionTypeFo}</label></div>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:transaction_code')}</label>
                <div><label>{data?.code}</label></div>
            </div>
            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:status')}</label>
                <div><label>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label></div>
            </div>
            <div title={formatDate(data?.dateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:transaction_date')}</label>
                <div><label>{formatDate(data?.dateTime)}</label></div>
            </div>
            <div title={data?.orderNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:order_num')}</label>
                <div><label>{data?.orderNo}</label></div>
            </div>
            <div title={formatDate(data?.orderDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:order_date')}</label>
                <div><label>{formatDate(data?.orderDateTime)}</label></div>
            </div>
            <div title={data?.approvalNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:approval_num')}</label>
                <div><label>{data?.approvalNo}</label></div>
            </div>
            <div title={data?.refCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:ref_code')}</label>
                <div><label>{data?.refCode}</label></div>
            </div>
            <div title={isEnglish() ? data?.propertyTypeDescLo : data?.propertyTypeDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_type')}</label>
                <div><label>{isEnglish() ? data?.propertyTypeDescLo : data?.propertyTypeDescFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.cityDescLo : data?.cityDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:city')}</label>
                <div><label>{isEnglish() ? data?.cityDescLo : data?.cityDescFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.townDescLo : data?.townDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:town')}</label>
                <div><label>{isEnglish() ? data?.townDescLo : data?.townDescFo}</label></div>
            </div>
            <div title={data?.longitude} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:longitude')}</label>
                <div><label>{data?.longitude}</label></div>
            </div>
            <div title={data?.latitude} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:latitude')}</label>
                <div><label>{data?.latitude}</label></div>
            </div>
            <div title={isEnglish() ? data?.customerNameLo : data?.customerNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:customer')}</label>
                <div><label>{isEnglish() ? data?.customerNameLo : data?.customerNameFo}</label></div>
            </div>
            <div title={data?.clientName} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:client_name')}</label>
                <div><label>{data?.clientName}</label></div>
            </div>
            <div title={data?.ownerName} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:owner_name')}</label>
                <div><label>{data?.ownerName}</label></div>
            </div>
            <div title={data?.mobileNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:mobile')}</label>
                <div><label>{data?.mobileNo}</label></div>
            </div>

            <div title={data?.titleDeedNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:num_t_deed')}</label>
                <div><label>{data?.titleDeedNo}</label></div>
            </div>
            <div title={data?.ownershipType} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:ownership_type')}</label>
                <div><label>{data?.ownershipType}</label></div>
            </div>

            <div title={isEnglish() ? data?.ownershipType2Lo : data?.ownershipType2Fo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:ownership_description')}</label>
                <div><label>{isEnglish() ? data?.ownershipType2Lo : data?.ownershipType2Fo}</label></div>
            </div>

            <div title={data?.allowedFloorsSystem} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:allowed_floors')}</label>
                <div><label>{data?.allowedFloorsSystem}</label></div>
            </div>
            <div title={data?.northBorder} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:northern_border')}</label>
                <div><label>{data?.northBorder}</label></div>
            </div>
            <div title={data?.southBorder} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:southern_border')}</label>
                <div><label>{data?.southBorder}</label></div>
            </div>

            <div title={data?.eastBorder} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:eastern_border')}</label>
                <div><label>{data?.eastBorder}</label></div>
            </div>

            <div title={data?.westBorder} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:western_border')}</label>
                <div><label>{data?.westBorder}</label></div>
            </div>

            <div title={data?.constPermitNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:construction_permit_no')}</label>
                <div><label>{data?.constPermitNo}</label></div>
            </div>

            <div title={formatDate(data?.constPermitDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:construction_permit_date')}</label>
                <div><label>{formatDate(data?.constPermitDgr)}</label></div>
            </div>

            <div title={data?.planNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:plan_no')}</label>
                <div><label>{data?.planNo}</label></div>
            </div>

            <div title={data?.parcelNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:parcel_no')}</label>
                <div><label>{data?.parcelNo}</label></div>
            </div>

            <div title={data?.blockNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:block_no')}</label>
                <div><label>{data?.blockNo}</label></div>
            </div>
            <div title={data?.streetName} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:street_name')}</label>
                <div><label>{data?.streetName}</label></div>
            </div>

            <div title={data?.streetView} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:street_view')}</label>
                <div><label>{data?.streetView}</label></div>
            </div>

            <div title={data?.distanceToDownTown} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:distance_to_down_town')}</label>
                <div><label>{data?.distanceToDownTown}</label></div>
            </div>
            <div title={data?.buildingNo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:building_no')}</label>
                <div><label>{data?.buildingNo}</label></div>
            </div>
            <div title={data?.propertyCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_code')}</label>
                <div><label>{data?.propertyCode}</label></div>
            </div>
            <div title={isEnglish() ? data?.propertyUnoccupationLo : data?.propertyUnoccupationFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_unoccupation')}</label>
                <div><label>{isEnglish() ? data?.propertyUnoccupationLo : data?.propertyUnoccupationFo}</label></div>
            </div>

            <div title={isEnglish() ? data?.propertyOccupatorLo : data?.propertyOccupatorFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_occupator')}</label>
                <div><label>{isEnglish() ? data?.propertyOccupatorLo : data?.propertyOccupatorFo}</label></div>
            </div>


            <div title={isEnglish() ? data?.propertyRangeLo : data?.propertyRangeFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_range')}</label>
                <div><label>{isEnglish() ? data?.propertyRangeLo : data?.propertyRangeFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.propertyUseLo : data?.propertyUseFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_use')}</label>
                <div><label>{isEnglish() ? data?.propertyUseLo : data?.propertyUseFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.steepLevelLo : data?.steepLevelFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:steep_level')}</label>
                <div><label>{isEnglish() ? data?.steepLevelLo : data?.steepLevelFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.isWaterAvailableLo : data?.isWaterAvailableFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:is_water_available')}</label>
                <div><label>{isEnglish() ? data?.isWaterAvailableLo : data?.isWaterAvailableFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.isPhoneAvailableLo : data?.isPhoneAvailableFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:is_phone_available')}</label>
                <div><label>{isEnglish() ? data?.isPhoneAvailableLo : data?.isPhoneAvailableFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.isElectAvailableLo : data?.isElectAvailableFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:is_electricity_available')}</label>
                <div><label>{isEnglish() ? data?.isElectAvailableLo : data?.isElectAvailableFo}</label></div>
            </div>

            <div title={isEnglish() ? data?.isSanitAvailableLo : data?.isSanitAvailableFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:is_sanitation_available')}</label>
                <div><label>{isEnglish() ? data?.isSanitAvailableLo : data?.isSanitAvailableFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.buildingTypeLo : data?.buildingTypeFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:building_type')}</label>
                <div><label>{isEnglish() ? data?.buildingTypeLo : data?.buildingTypeFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.architecturalDesignLo : data?.architecturalDesignFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:architectural_design')}</label>
                <div><label>{isEnglish() ? data?.architecturalDesignLo : data?.architecturalDesignFo}</label></div>
            </div>

            <div title={data?.propertyView} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:property_view')}</label>
                <div><label>{data?.propertyView}</label></div>
            </div>
            <div title={isEnglish() ? data?.buildingConditionLo : data?.buildingConditionFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:building_condition')}</label>
                <div><label>{isEnglish() ? data?.buildingConditionLo : data?.buildingConditionFo}</label></div>
            </div>
            <div title={data?.parcelArea} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:parcel_area')}</label>
                <div><label>{data?.parcelArea}</label></div>
            </div>
            <div title={formatMoney(data?.parcelPricePerM)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:parcel_price_per_meter')}</label>
                <div><label className="amountLbl">{formatMoney(data?.parcelPricePerM)}</label></div>
            </div>
            <div title={formatMoney(data?.parcelTotalPrice)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:parcel_total_price')}</label>
                <div><label className="amountLbl">{formatMoney(data?.parcelTotalPrice)}</label></div>
            </div>
            <div title={data?.totalBuildArea} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_buildings_area')}</label>
                <div><label>{data?.totalBuildArea}</label></div>
            </div>
            <div title={data?.buildMeterValue} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:buildings_value_per_meter')}</label>
                <div><label>{formatMoney(data?.buildMeterValue)}</label></div>
            </div>
            <div title={data?.totalBuildValue} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_buildings_value')}</label>
                <div><label>{formatMoney(data?.totalBuildValue)}</label></div>
            </div>
            <div title={data?.totalBuildWallsArea} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_buildings_and_walls_area')}</label>
                <div><label>{data?.totalBuildWallsArea}</label></div>
            </div>
            <div title={data?.totalBuildWallsValue} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_buildings_and_walls_value')}</label>
                <div><label>{formatMoney(data?.totalBuildWallsValue)}</label></div>
            </div>
            <div title={data?.profitPercentage} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:profit_per')}</label>
                <div><label>{data?.profitPercentage}</label></div>
            </div>
            <div title={data?.profitAmount} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:profit')}</label>
                <div><label>{data?.profitAmount}</label></div>
            </div>

            <div title={data?.totalCost} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_cost')}</label>
                <div><label>{formatMoney(data?.totalCost)}</label></div>
            </div>

            <div title={data?.finalPrice} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:market_price')}</label>
                <div><label>{formatMoney(data?.finalPrice)}</label></div>
            </div>
            <div title={data?.estimatorOpenion} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:estimator_opinion')}</label>
                <div><label>{data?.estimatorOpenion}</label></div>
            </div>
            <div title={data?.privateRemarks} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:estimator_remarks')}</label>
                <div><label>{data?.privateRemarks}</label></div>
            </div>
            <div title={isEnglish() ? data?.estimationReasonLo : data?.estimationReasonFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:estimation_reason')}</label>
                <div><label>{isEnglish() ? data?.estimationReasonLo : data?.estimationReasonFo}</label></div>
            </div>
            <div title={isEnglish() ? data?.estimationBaseLo : data?.estimationBaseFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:estimation_base')}</label>
                <div><label>{isEnglish() ? data?.estimationBaseLo : data?.estimationBaseFo}</label></div>
            </div>

            <div title={data?.assumptions2} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:assumptions')}</label>
                <div><label>{data?.assumptions2}</label></div>
            </div>

            <div title={data?.estimationMethodDesc} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:valuation_approach')}</label>
                <div><label>{data?.estimationMethodDesc}</label></div>
            </div>
            <div title={isEnglish() ? data?.estimationMethodLo : data?.estimationMethodFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:valuation_method')}</label>
                <div><label>{isEnglish() ? data?.estimationMethodLo : data?.estimationMethodFo}</label></div>
            </div>

            <div title={formatDate(data?.creationDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:creation_date')}</label>
                <div><label>{formatDate(data?.creationDateTime)}</label></div>
            </div>
            <div title={isEnglish() ? data?.createdByDescLo : data?.createdByDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:created_by')}</label>
                <div><label>{isEnglish() ? data?.createdByDescLo : data?.createdByDescFo}</label></div>
            </div>
            <div title={formatDate(data?.lastInspDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:inspection_date')}</label>
                <div><label>{formatDate(data?.lastInspDateTime)}</label></div>
            </div>
            <div title={isEnglish() ? data?.firstInspDescLo : data?.firstInspDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:inspector')}</label>
                <div><label>{isEnglish() ? data?.firstInspDescLo : data?.firstInspDescFo}</label></div>
            </div>

            <div title={formatDate(data?.lastInspDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:inspection_auditing_date')}</label>
                <div><label>{formatDate(data?.lastInspDateTime)}</label></div>
            </div>


            <div title={isEnglish() ? data?.firstInspDescLo : data?.firstInspDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:inspection_auditor')}</label>
                <div><label>{isEnglish() ? data?.firstInspDescLo : data?.firstInspDescFo}</label></div>
            </div>
            <div title={formatDate(data?.firstRevDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:revision_date')}</label>
                <div><label>{formatDate(data?.firstRevDateTime)}</label></div>
            </div>
            <div title={isEnglish() ? data?.firstRevDescLo : data?.firstRevDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:reviewed_by')}</label>
                <div><label>{isEnglish() ? data?.firstRevDescLo : data?.firstRevDescFo}</label></div>
            </div>
            <div title={formatDate(data?.lastRevDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:reviewing_auditing_date')}</label>
                <div><label>{formatDate(data?.lastRevDateTime)}</label></div>
            </div>
            <div title={isEnglish() ? data?.lastRevDescLo : data?.lastRevDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:reviewing_auditor')}</label>
                <div><label>{isEnglish() ? data?.lastRevDescLo : data?.lastRevDescFo}</label></div>
            </div>
            <div title={formatDate(data?.approvalDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:approval_date')}</label>
                <div><label>{formatDate(data?.approvalDateTime)}</label></div>
            </div>
            <div title={isEnglish() ? data?.approvedByDescLo : data?.approvedByDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:approved_by')}</label>
                <div><label>{isEnglish() ? data?.approvedByDescLo : data?.approvedByDescFo}</label></div>
            </div>
            <div title={data?.recoveryCount} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:num_recovery')}</label>
                <div><label>{data?.recoveryCount}</label></div>
            </div>
            <div title={isEnglish() ? data?.partyNameLo : data?.partyNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:estimator')}</label>
                <div><label>{isEnglish() ? data?.partyNameLo : data?.partyNameFo}</label></div>
            </div>
            <div title={data?.membershipNum} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:membership_num')}</label>
                <div><label className="amountLbl">{data?.membershipNum}</label></div>
            </div>
            <div title={formatMoney(data?.appOrgFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:approved_revenue')}</label>
                <div><label className="amountLbl">{formatMoney(data?.appOrgFeesAlc)}</label></div>
            </div>
            <div title={formatMoney(data?.notAppOrgFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:not_approved_revenue')}</label>
                <div><label className="amountLbl">{formatMoney(data?.notAppOrgFeesAlc)}</label></div>
            </div>
            <div title={formatMoney(data?.totOrgFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_of_revenues')}</label>
                <div><label className="amountLbl">{formatMoney(data?.totOrgFeesAlc)}</label></div>
            </div>

            <div title={isEnglish() ? data.billingStatusDescLo : data.billingStatusDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:billing_status')}</label>
                <div>
                    <div className={`status-${data.billingStatus}`}>{isEnglish() ? data.billingStatusDescLo : data.billingStatusDescFo}</div>
                </div>
            </div>
            <div title={formatDate(data?.estimationInvoiceDate)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:invoice_approval_date')}</label>
                <div><label>{formatDate(data?.estimationInvoiceDate)}</label></div>
            </div>
            <div title={data?.estimationInvoiceCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:invoice_code')}</label>
                <div><label className="amountLbl">{data?.estimationInvoiceCode}</label></div>
            </div>
            <div title={formatDate(data?.estimationInvoiceDate)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:invoice_date')}</label>
                <div><label>{formatDate(data?.estimationInvoiceDate)}</label></div>
            </div>
            <div title={isEnglish() ? data.invoiceGlobalStatusDescLo : data.invoiceGlobalStatusDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:invoice_status')}</label>
                <div>
                    <div className={`status-${data.estimationInvoiceGlobalStatus}`}> {isEnglish() ? data.invoiceGlobalStatusDescLo : data.invoiceGlobalStatusDescFo}</div>
                </div>
            </div>
            <div title={formatMoney(data?.appEstFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:approved_inspectors_fees')}</label>
                <div><label className="amountLbl">{formatMoney(data?.appEstFeesAlc)}</label></div>
            </div>
            <div title={formatMoney(data?.notAppEstFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:not_approved_inspectors_fees')}</label>
                <div><label className="amountLbl">{formatMoney(data?.notAppEstFeesAlc)}</label></div>
            </div>

            <div title={formatMoney(data?.totEstFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_of_inspectors_fees')}</label>
                <div><label className="amountLbl">{formatMoney(data?.totEstFeesAlc)}</label></div>
            </div>
            <div title={formatMoney(data?.appEmpFee)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:approved_employees_fees')}</label>
                <div><label className="amountLbl">{formatMoney(data?.appEmpFee)}</label></div>
            </div>
            <div title={formatMoney(data?.notAppEmpFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:not_approved_employees_fees')}</label>
                <div><label className="amountLbl">{formatMoney(data?.notAppEmpFeesAlc)}</label></div>
            </div>
            <div title={formatMoney(data?.totEmpFeesAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationTransactionsRegister:total_of_employees_fees')}</label>
                <div><label className="amountLbl">{formatMoney(data?.totEmpFeesAlc)}</label></div>
            </div>
        </div >
    );
};

const Total = ({ totals }) => {
    const { t } = useTranslation("estimationTransactionsRegister");

    return (
        <>
            <div id="estimation-transactions-register-total" className="table-total" >
                <div className="hide-total-table-mobile"><div></div></div>
                <div><div className="tableTotalLabel">{t("total")}</div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:approved_revenue')}</label>
                    <div id="estimation-transactions-approved-revenue" title={formatMoney(totals?.appOrgFeesAlc)}>{formatMoney(totals?.appOrgFeesAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:not_approved_revenue')}</label>
                    <div id="estimation-transactions-register-not-approved-revenue" title={formatMoney(totals?.notAppOrgFeesAlc)}>{formatMoney(totals?.notAppOrgFeesAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:total_of_revenues')}</label>
                    <div id="estimation-transactions-register-total-of-revenues" title={formatMoney(totals?.totOrgFeesAlc)}>{formatMoney(totals?.totOrgFeesAlc)}</div>
                </div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:approved_inspectors_fees')}</label>
                    <div id="estimation-transactions-register-approved-inspectors-fees" title={formatMoney(totals?.appEstFeesAlc)}>{formatMoney(totals?.appEstFeesAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:not_approved_inspectors_fees')}</label>
                    <div id="estimation-transactions-register-not-approved-inspectors-fees" title={formatMoney(totals?.notAppEstFeesAlc)}>{formatMoney(totals?.notAppEstFeesAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:total_of_inspectors_fees')}</label>
                    <div id="estimation-transactions-register-total-of-inspectors-fees" title={formatMoney(totals?.totEstFeesAlc)}>{formatMoney(totals?.totEstFeesAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:approved_employees_fees')}</label>
                    <div id="estimation-transactions-register-approved-employees-fees" title={formatMoney(totals?.appEmpFee)}>{formatMoney(totals?.appEmpFee)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:not_approved_employees_fees')}</label>
                    <div id="estimation-transactions-register-not-approved-employees-fees" title={formatMoney(totals?.notAppEmpFeesAlc)}>{formatMoney(totals?.notAppEmpFeesAlc)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationTransactionsRegister:total_of_employees_fees')}</label>
                    <div id="estimation-transactions-register-total-of-employees-fees" title={formatMoney(totals?.totEmpFeesAlc)}>{formatMoney(totals?.totEmpFeesAlc)}</div>
                </div>
                <button className="closeTotalMobile" onClick={() => closeTotalTableSm('estimation-transactions-register-total')}>
                    <XIcon />
                </button>
            </div>
            <span id="estimation-transactions-register-total-bg" className="totalMobileBg" onClick={() => closeTotalTableSm('estimation-transactions-register-total')}></span>
        </>

    )
}

const EstimationTransactionsRegisterTable = (props) => {
    const { user, tableRef, filterRef } = props;

    const { t } = useTranslation();

    const [list, setList] = useState({});
    const [totals, setTotals] = useState({});

    const columns = [
        { title: t('estimationTransactionsRegister:branch_division'), width: '1%' },
        { title: t('estimationTransactionsRegister:workflow'), width: '1%' },
        { title: t('estimationTransactionsRegister:transaction_type'), width: '1%' },
        { title: t('estimationTransactionsRegister:transaction_code'), width: '1%' },
        { title: t('estimationTransactionsRegister:status'), width: '1%' },

        { title: t('estimationTransactionsRegister:transaction_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:order_num'), width: '1%' },
        { title: t('estimationTransactionsRegister:order_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:approval_num'), width: '1%' },
        { title: t('estimationTransactionsRegister:ref_code'), width: '1%' },

        { title: t('estimationTransactionsRegister:property_type'), width: '1%' },
        { title: t('estimationTransactionsRegister:city'), width: '1%' },
        { title: t('estimationTransactionsRegister:town'), width: '1%' },
        { title: t('estimationTransactionsRegister:longitude'), width: '1%' },
        { title: t('estimationTransactionsRegister:latitude'), width: '1%' },

        { title: t('estimationTransactionsRegister:customer'), width: '2%' },
        { title: t('estimationTransactionsRegister:client_name'), width: '1%' },
        { title: t('estimationTransactionsRegister:owner_name'), width: '2%' },
        { title: t('estimationTransactionsRegister:mobile'), width: '1%' },
        { title: t('estimationTransactionsRegister:num_t_deed'), width: '1%' },

        { title: t('estimationTransactionsRegister:ownership_type'), width: '1%' },
        { title: t('estimationTransactionsRegister:ownership_description'), width: '1%' },
        { title: t('estimationTransactionsRegister:allowed_floors'), width: '1%' },
        { title: t('estimationTransactionsRegister:northern_border'), width: '1%' },
        { title: t('estimationTransactionsRegister:southern_border'), width: '1%' },

        { title: t('estimationTransactionsRegister:eastern_border'), width: '1%' },
        { title: t('estimationTransactionsRegister:western_border'), width: '1%' },
        { title: t('estimationTransactionsRegister:construction_permit_no'), width: '1%' },
        { title: t('estimationTransactionsRegister:construction_permit_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:plan_no'), width: '1%' },

        { title: t('estimationTransactionsRegister:parcel_no'), width: '1%' },
        { title: t('estimationTransactionsRegister:block_no'), width: '1%' },
        { title: t('estimationTransactionsRegister:street_name'), width: '1%' },
        { title: t('estimationTransactionsRegister:street_view'), width: '1%' },
        { title: t('estimationTransactionsRegister:distance_to_down_town'), width: '1%' },

        { title: t('estimationTransactionsRegister:building_no'), width: '1%' },
        { title: t('estimationTransactionsRegister:property_code'), width: '1%' },
        { title: t('estimationTransactionsRegister:property_unoccupation'), width: '1%' },
        { title: t('estimationTransactionsRegister:property_occupator'), width: '1%' },
        { title: t('estimationTransactionsRegister:property_range'), width: '1%' },

        { title: t('estimationTransactionsRegister:property_use'), width: '1%' },
        { title: t('estimationTransactionsRegister:steep_level'), width: '1%' },
        { title: t('estimationTransactionsRegister:is_water_available'), width: '1%' },
        { title: t('estimationTransactionsRegister:is_phone_available'), width: '1%' },
        { title: t('estimationTransactionsRegister:is_electricity_available'), width: '1%' },

        { title: t('estimationTransactionsRegister:is_sanitation_available'), width: '1%' },
        { title: t('estimationTransactionsRegister:building_type'), width: '1%' },
        { title: t('estimationTransactionsRegister:architectural_design'), width: '1%' },
        { title: t('estimationTransactionsRegister:property_view'), width: '1%' },
        { title: t('estimationTransactionsRegister:building_condition'), width: '1%' },

        { title: t('estimationTransactionsRegister:parcel_area'), width: '1%' },
        { title: t('estimationTransactionsRegister:parcel_price_per_meter'), width: '1%' },
        { title: t('estimationTransactionsRegister:parcel_total_price'), width: '1%' },
        { title: t('estimationTransactionsRegister:total_buildings_area'), width: '1%' },
        { title: t('estimationTransactionsRegister:buildings_value_per_meter'), width: '1%' },

        { title: t('estimationTransactionsRegister:total_buildings_value'), width: '1%' },
        { title: t('estimationTransactionsRegister:total_buildings_and_walls_area'), width: '1%' },
        { title: t('estimationTransactionsRegister:total_buildings_and_walls_value'), width: '1%' },
        { title: t('estimationTransactionsRegister:profit_per'), width: '1%' },
        { title: t('estimationTransactionsRegister:profit'), width: '1%' },

        { title: t('estimationTransactionsRegister:total_cost'), width: '1%' },
        { title: t('estimationTransactionsRegister:market_price'), width: '1%' },
        { title: t('estimationTransactionsRegister:estimator_opinion'), width: '1%' },
        { title: t('estimationTransactionsRegister:estimator_remarks'), width: '1%' },
        { title: t('estimationTransactionsRegister:estimation_reason'), width: '1%' },

        { title: t('estimationTransactionsRegister:estimation_base'), width: '1%' },
        { title: t('estimationTransactionsRegister:assumptions'), width: '1%' },
        { title: t('estimationTransactionsRegister:valuation_approach'), width: '1%' },
        { title: t('estimationTransactionsRegister:valuation_method'), width: '1%' },
        { title: t('estimationTransactionsRegister:creation_date'), width: '1%' },

        { title: t('estimationTransactionsRegister:created_by'), width: '1%' },
        { title: t('estimationTransactionsRegister:inspection_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:inspector'), width: '1%' },
        { title: t('estimationTransactionsRegister:inspection_auditing_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:inspection_auditor'), width: '1%' },

        { title: t('estimationTransactionsRegister:revision_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:reviewed_by'), width: '1%' },
        { title: t('estimationTransactionsRegister:reviewing_auditing_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:reviewing_auditor'), width: '1%' },
        { title: t('estimationTransactionsRegister:approval_date'), width: '1%' },

        { title: t('estimationTransactionsRegister:approved_by'), width: '1%' },
        { title: t('estimationTransactionsRegister:num_recovery'), width: '1%' },
        { title: t('estimationTransactionsRegister:estimator'), width: '1%' },
        { title: t('estimationTransactionsRegister:membership_num'), width: '1%' },
        { title: t('estimationTransactionsRegister:approved_revenue'), width: '1%' },

        { title: t('estimationTransactionsRegister:not_approved_revenue'), width: '1%' },
        { title: t('estimationTransactionsRegister:total_of_revenues'), width: '1%' },
        { title: t('estimationTransactionsRegister:billing_status'), width: '1%' },
        { title: t('estimationTransactionsRegister:invoice_approval_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:invoice_code'), width: '1%' },

        { title: t('estimationTransactionsRegister:invoice_date'), width: '1%' },
        { title: t('estimationTransactionsRegister:invoice_status'), width: '1%' },
        { title: t('estimationTransactionsRegister:approved_inspectors_fees'), width: '1%' },
        { title: t('estimationTransactionsRegister:not_approved_inspectors_fees'), width: '1%' },
        { title: t('estimationTransactionsRegister:total_of_inspectors_fees'), width: '1%' },

        { title: t('estimationTransactionsRegister:approved_employees_fees'), width: '1%' },
        { title: t('estimationTransactionsRegister:not_approved_employees_fees'), width: '1%' },
        { title: t('estimationTransactionsRegister:total_of_employees_fees'), width: '1%' }
    ];

    const _fetchData = (page, size) => {
        getEstimationTransactionsRegisterList(page, size, filterRef?.current?.getFilterData())
            .then((response) => setList(response.data))
            .catch((error) => handleError(error, null, t));

        getEstimationTransactionsRegisterTotals(filterRef?.current?.getFilterData())
            .then((response) => setTotals(response.data))
            .catch((error) => handleError(error, null, t));
    }

    return (
        <Table
            id="estimation-transactions-register-table"
            ref={tableRef}
            columns={columns}
            formId={RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID}
            Row={Row}
            tableStyle={style.estimationTransactionsRegisterTable}
            getData={_fetchData}
            rowHeight={80}
            pagingData={list}
            withFilter
            reportsTable
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            Total={(props) => <Total {...props} totals={totals} />}
            tableWidth={1000}
            user={user}
            labelCardWidth="140px"

        />
    );
};

export default EstimationTransactionsRegisterTable;