export const NEW_ACTION = "1";
export const SAVE_ACTION = "2";
export const DELETE_ACTION = "3";
export const PRINT_ACTION = "6";
export const ONLINE_HELP_ACTION = "9";
export const RECORD_HISTORY_ACTION = "10";
export const DELETED_RECORDS_ACTION = "11";
export const APPROVAL_HISTORY_ACTION = "12";
export const ACTIVATE_ACTION = "13";
export const DEACTIVATE_ACTION = "14";
export const LISTING_ACTION = "16";
export const CLEAR_ACTION = "17";
export const UP_ACTION = "18";
export const DOWN_ACTION = "19";
export const USER_MANUEL_ACTION = "25"
export const ABOUT_US_ACTION = "26";
export const SPLITTING_ACTION = "27";
export const VIEW_EDIT_ACTION = "29";
export const INCOMPLETE_ACTION = "30";
export const SUBMIT_ACTION = "31";
export const REVERSE_ACTION = "32";
export const COPY_ACTION = "33";
export const SOURCE_ACTION = "34";
export const ATTACHMENTS_ACTION = "35";
export const CHECK_WF_ACTION = "38";
export const CONFIRM_WF_ACTION = "39";
export const ACTIVATE_WF_ACTION = "40";
export const DEACTIVATE_WF_ACTION = "41";
export const USER_ACTIVATION_ACTION = "42";
export const USER_TERMINATION_ACTION = "43";
export const USER_RESETTING_ACTION = "44";
export const USER_SUSPENSION_ACTION = "45";
export const VIEW_HISTORY_ACTION = "46";
export const CONFIRM_FORM_ACTION = "47";
export const REJECT_ACTION = "48";
export const ASSETS_CARD_ACTIVE_ACTION = "50";
export const ASSETS_CARD_ACTIVE_DEPREICIATION_ACTION = "51";
export const EXPORT_ACTION = "52";
export const OPEN_ACTION = "53";
export const IMPORT_ACTION = "54";
export const EXPORT_TEMPLATE_ACTION = "55";
export const SCM_ACTIVATE_ACTION = "56";
export const SCM_DEACTIVATE_ACTION = "57";
export const PRINT_CUSTOM_REPORT_ACTION = "58";
export const SUBMIT_AND_CONFIRM = "60";
export const UN_POST_ACTION = "61";
export const CANCEL_ACTION = "62";
export const CLOSE_ACTION = "63";
export const RENEW_ACTION = "64";
export const RECORD_HISTORY = "59";
export const GENERATE_AGREEMENT = "67";
export const GENERATE_VALUATION_TRXN = "68";
export const DIVERT_TO_CUSTOMER = "69";
export const GENERATE_CONTRACT = "70";