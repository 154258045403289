import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import SidePanel from "../../../components/side-panel";
import EstimationQuotationIcon from "../../../icons/EstimationQuotation";
import { getOrgnizationById, getQuotationFieldsList, getQuotationType, saveQuotationType } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const dialogId = "estimation-quotations-types-dialog";

const EstimationQuotationsTypesDialog = (props, ref) => {
    const { user, tableRef } = props;

    const { t } = useTranslation();

    const defaultData = { isActive: STATUS_ACTIVE, fields: [] }

    const [data, setData] = useState(defaultData);

    const dialogRef = useRef();
    const fieldsRef = useRef();
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }
        showWaiting(`${dialogId}-btn`, true)
        saveQuotationType(data)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => handleError(error, error?.response?.data?.message.split('#')[0], t))
            .finally(() => hideWaiting(`${dialogId}-btn`))
    }

    const _insertFields = (selectedData) => {
        if (!data.fields) {
            data.fields = [];
        }

        let newLines = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({ urnId: element.id, descLo: element.descLo, descFo: element.descFo });
        }

        data.fields = [...data.fields, ...newLines];
        setData({ ...data });

        fieldsRef?.current?.close();
    }

    const _postClose = () => {
        setData(defaultData)
        tabsRef.current?.changeTab(1);
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _populateRecordData = (id) => {
        if (id) {
            updateRecordRef.current = true;
            getQuotationType(id)
                .then((response) => {
                    getOrgnizationById(response.data.fogId)
                        .then(res => {
                            response.data.orgDescFo = res.data.offecialNameFo;
                            response.data.orgDescLo = res.data.offecialNameLo;
                            setData(response.data);
                        })
                })
                .catch((error) => handleError(error, null, t))
        } else {
            setData({ ...defaultData, orgDescLo: user.orgNameLo, orgDescFo: user.orgNameFo })
        }
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    return (
        <>
            <SidePanel
                id={dialogId}
                ref={dialogRef}
                formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
                title={t('estimationQuotationsTypes:estimation_quotations_types')}
                icon={<EstimationQuotationIcon />}
                postClose={_postClose}
                withFooter={isAllowedAction(user, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, SAVE_ACTION)}
                footerLbl={t("save")}
                footerAction={_saveAction}
                nextPreviousAction={_populateRecordData}
                preClose={_preCloseAction}
            >
                <Tabs
                    id={`${dialogId}-tabs`}
                    ref={tabsRef}
                    labels={['home', 'estimationQuotationsTypes:fields']}
                    tabContentStyle={style.tabContentStyle}
                    tabsButtonCustomStyle={style.estimationTabs}
                >
                    <Tab1 dialogId={dialogId} data={data} setData={setData} />
                    <Tab2 dialogId={dialogId} data={data} setData={setData} user={user} fieldsRef={fieldsRef} />
                </Tabs>
            </SidePanel>

            <MultiInsertDialog
                id='insert-fields'
                ref={fieldsRef}
                formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
                title={t('estimationQuotationsTypes:insert_fields')}
                rowHeight={40}
                api={getQuotationFieldsList}
                okBtnAction={_insertFields}
                selectedIds={(data.fields || []).map((line) => line.urnId)}
                tableStyle={style.estimationQuotationsTypesinsert}
                searchPlaceholder={t('name')}
                columns={[
                    { width: '10%' },
                    { label: '#', width: "5%" },
                    { label: t('name'), valueLo: 'descLo', valueFo: 'descFo', width: "85%" },
                ]}
                isSmallSize
            />
        </>
    )
}

export default forwardRef(EstimationQuotationsTypesDialog);