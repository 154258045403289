import React from "react";

function TransactionsStatisticsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.887"
      height="20.879"
      viewBox="0 0 20.887 20.879"
    >
      <g
        fill="#292d32"
        data-name="Group 176493"
        transform="translate(-301.249 -189.864)"
      >
        <path
          d="M6.32 10.136H3c-2.488 0-3.75-1.41-3.75-4.19v-2.88A4.663 4.663 0 01-.119.371 2.71 2.71 0 012.276-.75a8 8 0 012.215.376 7.793 7.793 0 013.284 1.985A7.792 7.792 0 019.76 4.894c.551 1.884.5 3.173-.163 4.057a3.956 3.956 0 01-3.277 1.185zM2.276.75C1.472.75.75.927.75 3.066v2.88c0 2.24.861 2.69 2.25 2.69h3.32c1.1 0 1.782-.191 2.076-.584.346-.462.32-1.382-.076-2.735a6.511 6.511 0 00-4.252-4.252A6.665 6.665 0 002.276.75z"
          transform="translate(312 190.614)"
        ></path>
        <path
          d="M9.1 18.733a10.192 10.192 0 01-1.639-.133 9.844 9.844 0 01-8.08-8.069A9.851 9.851 0 017.112-.735.75.75 0 018-.15a.75.75 0 01-.585.885 8.337 8.337 0 109.855 9.807.75.75 0 011.471.3 9.834 9.834 0 01-9.641 7.9z"
          data-name="Vector"
          transform="translate(301.999 192.01)"
        ></path>
      </g>
    </svg>
  );
}

export default TransactionsStatisticsIcon;
