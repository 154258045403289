import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import EstimationFormsFieldsBuilderIcon from "../../../icons/EstimationFormsFieldsBuilder";
import { getEstimationLOV, saveEstimationLOV } from "../../../util/apis";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";

const dialogId = "properties-estimation-list-of-value-dialog";

const PropertiesEstimationListOfValuesDialog = (props, ref) => {
    const { user, tableRef } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();

    const [data, setData] = useState({});

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            dialogRef.current.open();
            getEstimationLOV(id)
                .then((response) => setData(response.data))
                .catch((error) => {
                    let message = error?.response?.data?.message;
                    if (message.includes('#')) {
                        message = message.split('#')[0]
                    }
                    handleError(error, message, t)
                })
        }
    }

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId },
        {
            id: `${dialogId}-values`,
            title: t('propertiesEstimationListOfValues:value'),
            data: data?.lines,
            disabledConditions: {
                code: () => true
            }
        },
        ], t)) {
            return
        }
        showWaiting(`${dialogId}-btn`, true)
        saveEstimationLOV(data)
            .then(() => {
                showSuccessMessage('save_successfully', t);
                dialogRef.current.close();
                tableRef?.current?.refresh();
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting(`${dialogId}-btn`));
    }

    const _postClose = () => {
        setData({})
        dialogRef.current?.changeSelectedTab(1);
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const open = (id) => _populateRecordData(id);

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (

        <ModalWithTabs
            id={dialogId}
            ref={dialogRef}
            formId={RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID}
            title={t('propertiesEstimationListOfValues:properties_estimation_list_of_values')}
            Icon={<EstimationFormsFieldsBuilderIcon />}
            okBtnAction={_saveAction}
            hideOkBtn={!(isAllowedAction(user, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, SAVE_ACTION))}
            postClose={_postClose}
            nextPreviousAction={_populateRecordData}
            labels={['home', 'propertiesEstimationListOfValues:value']}
        >
            <Tab1 data={data} setData={setData} />
            <Tab2 data={data} setData={setData} user={user} />
        </ModalWithTabs>
    )
}

export default forwardRef(PropertiesEstimationListOfValuesDialog);