import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";

const dialogId = "properties-estimation-list-of-value-dialog";

const Tab1 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('propertiesEstimationListOfValues');

    const _handleDataChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label className='form-label mandatory'>{t('code')}</label>
                        <FrmInput
                            id={`${dialogId}-code`}
                            value={data?.code}
                            disabled={true}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-status-lbl`} className='form-label'>{t('status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    action: () => _handleDataChange("isActive", STATUS_ACTIVE),
                                    selected: data.isActive == STATUS_ACTIVE
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    action: () => _handleDataChange("isActive", STATUS_IN_ACTIVE),
                                    selected: data.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChange("descLo", value)}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('short_english')}</label>
                        <FrmInput
                            id={`${dialogId}-short-english-name`}
                            value={data?.shDescLo}
                            onBlur={value => _handleDataChange("shDescLo", value)}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChange("descFo", value)}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('short_arabic')}</label>
                        <FrmInput
                            id={`${dialogId}-short-arabic-name`}
                            value={data?.shDescFo}
                            onBlur={value => _handleDataChange("shDescFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-remarks`}
                            value={data?.rem}
                            onBlur={value => _handleDataChange("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Tab1);