import React from "react";

export function TransactionUnFav() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.87"
      height="21.506"
      viewBox="0 0 18.87 21.506"
    >
      <g
        fill="#344767"
        data-name="Group 178402"
        transform="translate(-1467.57 -277.25)"
      >
        <path
          d="M2.75 1.236A8.769 8.769 0 01-.257.7.75.75 0 01-.7-.257.75.75 0 01.257-.7a7.277 7.277 0 004.985 0 .75.75 0 01.958.443.75.75 0 01-.443.957 8.769 8.769 0 01-3.007.536z"
          transform="translate(1474.25 285.05)"
        ></path>
        <path
          d="M3.86-.75h9.64a4.634 4.634 0 014.62 4.606V17.95c0 1.679-.953 2.806-2.372 2.806a3.321 3.321 0 01-1.613-.461l-4.879-2.71h-.007a1.2 1.2 0 00-.561-.119 1.247 1.247 0 00-.573.123L3.234 20.3a3.334 3.334 0 01-1.606.455c-1.422 0-2.378-1.125-2.378-2.8V3.86A4.615 4.615 0 013.86-.75zm11.888 20.006c.787 0 .872-.914.872-1.306V3.86A3.13 3.13 0 0013.5.75H3.86A3.114 3.114 0 00.75 3.86v14.09c0 .391.086 1.3.878 1.3a1.893 1.893 0 00.88-.267l4.878-2.709a2.719 2.719 0 011.3-.312 2.677 2.677 0 011.3.314l4.877 2.708a1.886 1.886 0 00.885.272z"
          data-name="Vector"
          transform="translate(1468.32 278)"
        ></path>
      </g>
    </svg>
  );
}


export function TransactionFav() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.871"
      height="22.503"
      viewBox="0 0 19.871 22.503"
    >
      <g data-name="Group 178570" transform="translate(1996.501 8744.5)">
        <path
          fill="#668fff"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M-1981.114-8722.958l-4.881-2.711h0a1.244 1.244 0 00-.562-.12 1.228 1.228 0 00-.572.125l-4.883 2.709a3.324 3.324 0 01-1.605.457c-1.425 0-2.38-1.125-2.38-2.8v-14.088a4.617 4.617 0 014.612-4.612h9.639a4.632 4.632 0 014.62 4.607v14.088c0 1.68-.952 2.807-2.372 2.807a3.32 3.32 0 01-1.616-.462zm-4.148-4.02h0l4.876 2.709a1.911 1.911 0 00.883.273c.788 0 .874-.913.874-1.309h0c0 .4-.086 1.309-.874 1.309a1.911 1.911 0 01-.883-.273l-4.876-2.709h0l-.036-.019zm-9.237 1.673c0 .391.086 1.3.879 1.3a1.889 1.889 0 00.88-.269l4.876-2.708c.073-.039.148-.073.225-.1-.076.031-.151.066-.225.1l-4.876 2.708a1.889 1.889 0 01-.88.269c-.794.003-.88-.908-.88-1.299zm9-1.784a2.666 2.666 0 00-.562-.163 2.65 2.65 0 01.562.164zm-.689-.182a2.48 2.48 0 000 .001zm-.5-.019h0zm.12 0h0zm0-7.677a8.835 8.835 0 003.008-.531.751.751 0 00.446-.963.749.749 0 00-.962-.446 7.266 7.266 0 01-4.985 0 .748.748 0 00-.961.446.751.751 0 00.445.963h0a8.781 8.781 0 003.008.531z"
          data-name="Union 362"
        ></path>
      </g>
    </svg>
  );
}
