import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import EstimationsHistoryIcon from "../../../icons/EstimationsHistory";
import { getCustomReports, saveCustomReports } from "../../../util/apis";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const PropertiesValuationCustomReportsDialog = (props, ref) => {

    const { user, tableRef } = props;

    const defaultData = {
        organizations: [
            {
                fogId: user?.fogId,
                offecialNameLo: user?.orgNameLo,
                offecialNameFo: user?.orgNameFo,
                organizationCode: user?.orgCode
            }
        ],
    }

    const { t } = useTranslation();
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);
    const dialogRef = useRef();

    const [data, setData] = useState(defaultData);

    const _okBtnAction = () => {
        if (!checkValidation([{ id: "properties-valuation-custom-reports-dialog" }], t)) {
            return
        }
        showWaiting("properties-valuation-custom-reports-dialog-btn", true)
        saveCustomReports(data, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID).then(() => {
            if (!updateRecordRef.current) {
                _populateRecordData(null);
            }
            showSuccessMessage('save_successfully', t);
        })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting("properties-valuation-custom-reports-dialog-btn"));
    }

    const _postClose = () => {
        tableRef?.current?.refresh();
        updateRecordRef.current = false;
        setData({ ...defaultData });
        removeAllMandatoryClasses(document.getElementById("properties-valuation-custom-reports-dialog"));
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getCustomReports(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setData({ ...defaultData })
        }
    }

    const open = (id) => {
        dialogRef?.current?.open();
        _populateRecordData(id);
    }

    const close = () => dialogRef?.current?.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <SidePanel
            id="properties-valuation-custom-reports-dialog"
            ref={dialogRef}
            title={t('customReports:properties_valuation_custom_reports')}
            formId={MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID}
            icon={<EstimationsHistoryIcon />}
            withFooter={(isAllowedAction(user, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, SAVE_ACTION))}
            footerLbl={t("save")}
            footerAction={_okBtnAction}
            postClose={_postClose}
            nextPreviousAction={(id) => _populateRecordData(id)}
        >
            <Tabs
                id="properties-valuation-custom-reports-dialog-tabs"
                ref={tabsRef}
                labels={['home', 'customReports:organization_list']}
                tabContentStyle={style.tabContentStyle}
                tabsButtonCustomStyle={style.customReportTabs}
            >
                <Tab1 data={data} setData={setData} />
                <Tab2 data={data} setData={setData} />
            </Tabs>
        </SidePanel>
    )
}

export default forwardRef(PropertiesValuationCustomReportsDialog);