import React from "react";

function TrxPlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      data-name="Group 180088"
      viewBox="0 0 15 15"
    >
      <g data-name="Group 180089">
        <path
          fill="#d6d6d7"
          d="M7.5 0A7.5 7.5 0 110 7.5 7.5 7.5 0 017.5 0"
          data-name="Path 44888"
        ></path>
        <circle
          cx="7"
          cy="7"
          r="7"
          fill="none"
          data-name="Ellipse 187"
          transform="translate(.5 .5)"
        ></circle>
        <path
          fill="#fff"
          d="M11 7H8V4H7v3H4v1h3v3h1V8h3z"
          data-name="Path 44889"
        ></path>
      </g>
    </svg>
  );
}

export default TrxPlusIcon;