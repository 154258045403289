import { Table as TableComponent } from '@ipgd-gauge/table';
import { forwardRef } from 'react';
import { saveFormsFilter } from '../../store/formsFilter/actions';
import { updateUserTableProfiles } from '../../store/userTableProfiles/actions';
import { deleteUserTableProfiles, getUserTableProfiles, saveUserTableProfiles, updateSelectedUserTableProfiles } from '../../util/apis';
import Waiting from '../waiting';

const Table = (props, ref) => {
    const { id, columns, Row, rowHeight, withCheckbox, cardInMobile, reportsTable, multiLevel, rowActions, getData,
        pagingData, list, tableStyle, noDataFoundLabel, showWating, showNoDataFound, rowNumber, withFilter, Total,
        postSelect, tableWidth, user, formId, getRowHeight, labelCardWidth, withRadioButton, onRowSelected,
        onSearchColumns, originalList } = props;

    return (
        <TableComponent
            id={id}
            ref={ref}
            columns={columns}
            Row={Row}
            rowHeight={rowHeight}
            withCheckbox={withCheckbox}
            cardInMobile={cardInMobile}
            reportsTable={reportsTable}
            multiLevel={multiLevel}
            rowActions={rowActions}
            getData={getData}
            pagingData={pagingData}
            list={list}
            tableStyle={tableStyle}
            noDataFoundLabel={noDataFoundLabel}
            showWating={showWating}
            showNoDataFound={showNoDataFound}
            rowNumber={rowNumber}
            withFilter={withFilter}
            Total={Total}
            postSelect={postSelect}
            tableWidth={tableWidth}
            saveFormsFilter={saveFormsFilter}
            user={user}
            formId={formId}
            withRadioButton={withRadioButton}
            getRowHeight={getRowHeight}
            updateUserTableProfiles={updateUserTableProfiles} //redux function
            deleteUserTableProfiles={deleteUserTableProfiles} //api
            getUserTableProfiles={getUserTableProfiles} //api
            saveUserTableProfiles={saveUserTableProfiles} //api
            updateSelectedUserTableProfiles={updateSelectedUserTableProfiles} //api
            Waiting={Waiting} //component
            labelCardWidth={labelCardWidth}
            onRowSelected={onRowSelected}
            onSearchColumns={onSearchColumns}
            originalList={originalList}
        />
    )
}

export default forwardRef(Table);