import Checkbox from "@ipgd-gauge/checkbox";
import { FrmDecimalInput } from "@ipgd-gauge/decimal-input";
import FrmInput from "@ipgd-gauge/frm-input";
import FrmTextArea from "@ipgd-gauge/frm-text-area";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { checkValidation, handleError, isEmpty, parseNumber, removeAllMandatoryClasses } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from "react-i18next";
import FrmGHDatePicker from "../../../../components/frm-g-h-date";
import SidePanel from "../../../../components/side-panel";
import EstimationsHistoryIcon from "../../../../icons/EstimationsHistory";
import NextArrowTwoIcon from "../../../../icons/NextArrowTwo";
import { getCitiesLov, getCommonPropertyTypes, getRegionsLov, getTownsList } from "../../../../util/apis";
import { DEFAULT_VAT_PERC, ESTIMATION_QUOTATION_APROACH_COST_FIELD, ESTIMATION_QUOTATION_AREA_LEVEL_FIELD, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD, ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD, ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD, ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD, ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD, ESTIMATION_QUOTATION_LICENCE_NUM_FIELD, ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD, ESTIMATION_QUOTATION_NET_PRICE_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD, ESTIMATION_QUOTATION_PRICE_FIELD, ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_PROPERTY_USE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_REM_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD, ESTIMATION_QUOTATION_VAT_FIELD, ESTIMATION_QUOTATION_VAT_PERC_FIELD, STATUS_ACTIVE } from "../../../../util/constants";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../../util/constants/hotkeys";
import style from './style.module.scss';
import { toast } from "react-toastify";

const dialogId = "estimation-quotation-details-dialog";

const DetailsSidePanel = (props, ref) => {
    const { formId, lovs, masterData, saveAction, handleChange } = props

    const { t } = useTranslation();
    const dialogRef = useRef();
    const indexRef = useRef(-1);

    const [data, setData] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const _handleDataChanged = (name, value) => {
        handleChange(data, name, value)
        setData({ ...data });
        if (name == "titleDeedDhi" || name == "titleDeedDgr") {
            document.getElementById(`${dialogId}-buy-instrument-date-container`)?.classList?.remove(style.mandatoryInput);
        }

    }

    const _saveAction = () => {
        const isdateError = masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD) && isEmpty(data?.titleDeedDgr) && isEmpty(data?.titleDeedDhi);
        if (!checkValidation([{ id: dialogId }], t) || isdateError) {
            if (isdateError) {
                handleError(null, 'estimationQuotation:must_be_filled_instrument_date', t);
                document.getElementById(`${dialogId}-buy-instrument-date-container`)?.classList?.add(style.mandatoryInput);
            }
            return;
        }

        saveAction(indexRef.current, data)

        if (indexRef.current == -1) {
            indexRef.current = masterData?.lines?.length || 0;
        }

        if (!masterData.lines || masterData.lines.length <= 1) {
            close()
        }
    }

    const _postClose = () => {
        removeAllMandatoryClasses(document.getElementById(dialogId))
        setIsOpen(false)
    }

    const handleRowIndex = (index) => {
        indexRef.current = index;

        if (index < 0) {
            setData({
                aproachCost: false,
                aproachMarket: false,
                aproachIncome: false,
                methodReplaceCost: false,
                methodComparision: false,
                methodIncomeCaptal: false,
                methodRemainValue: false,
                methodCashFlow: false,
                methodInvestment: false,
                vatPerc: DEFAULT_VAT_PERC,
                vatPercentage: DEFAULT_VAT_PERC
            })
        }
        else {
            setData({ ...masterData.lines[index] });
        }
    }

    const previousAction = () => {
        if (indexRef.current > 0) {
            removeAllMandatoryClasses(document.getElementById(dialogId))
            handleRowIndex(indexRef.current - 1)
        }
    }

    const nextAction = () => {
        if (indexRef.current < (masterData.lines.length - 1)) {
            removeAllMandatoryClasses(document.getElementById(dialogId))
            handleRowIndex(indexRef.current + 1)
        }
    }

    useHotkeys(SAVE_ACTION_SHORT_CUT, _saveAction);

    const open = (index) => {
        setIsOpen(true);
        handleRowIndex(index)
        dialogRef.current.open();
        document.getElementById(`${dialogId}-buy-instrument-date-container`)?.classList?.remove(style.mandatoryInput);
    }

    const close = () => {
        dialogRef.current.close();
        document.getElementById(`${dialogId}-buy-instrument-date-container`)?.classList?.remove(style.mandatoryInput);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            title={t('estimationQuotation:details')}
            formId={formId}
            icon={<EstimationsHistoryIcon />}
            customFooter={
                <div className={style.footer}>
                    {
                        masterData?.lines?.length > 1 &&
                        <button className={style.prevBtn} disabled={indexRef.current <= 0} onClick={previousAction} data-hotkey={SAVE_ACTION_SHORT_CUT}>
                            <span>
                                <NextArrowTwoIcon />
                            </span>
                            <label>{t('previous')}</label>
                        </button>
                    }
                    <button onClick={_saveAction} className={` ${masterData?.lines?.length > 1 ? style.okBtn : style.okBtnDef} hotkeysBtn`} data-hotkey={SAVE_ACTION_SHORT_CUT}>
                        {t('ok')}
                    </button>
                    {
                        masterData?.lines?.length > 1 &&
                        <button className={style.nextBtn} disabled={indexRef.current >= (masterData?.lines.length - 1)} onClick={nextAction}>
                            <span>
                                <NextArrowTwoIcon />
                            </span>
                            <label>{t('next')}</label>
                        </button>
                    }
                </div>
            }
            postClose={_postClose}
            withFooter
            className={style.estimationsHistorySidePanel}
        >
            <div className="container">
                <div className="row">
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DESCRIPTION_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-description-lbl`} className={`form-label`}>{t('estimationQuotation:desc')}</label>
                            <FrmTextArea
                                id={`${dialogId}-description`}
                                value={data.description}
                                className={style.typeText}
                                onBlur={value => _handleDataChanged('description', value)}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-deed-number-lbl`} className={`form-label ${isOpen ? 'mandatory' : ''}`}>{t("estimationQuotation:instrument#")}</label>
                            <FrmInput
                                id={`${dialogId}-deed-number`}
                                value={formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? data.deedNum : data.deedNumber}
                                onBlur={value => _handleDataChanged(formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? 'deedNum' : 'deedNumber', value)}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD) &&

                        <div id={`${dialogId}-buy-instrument-date-container`} className="w100">
                            <label id={`${dialogId}-buy-instrument-date-custom`} className={`form-label ${isOpen ? 'mandatory' : ''}`}>{t('estimationQuotation:instrument_date')}</label>
                            <FrmGHDatePicker
                                id={`${dialogId}-buy-instrument-date`}
                                disabledHijri={false}
                                onGregorianChange={(value) => _handleDataChanged("titleDeedDgr", value)}
                                onHijriChange={(value) => _handleDataChanged("titleDeedDhi", value)}
                                gregorianDate={data?.titleDeedDgr}
                                hijriDate={data?.titleDeedDhi}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_NUM_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-license-number-lbl`} className={`form-label`}>{t('estimationQuotation:license_number')}</label>
                            <FrmInput
                                id={`${dialogId}-license-number`}
                                value={data.licenceNum}
                                onBlur={value => _handleDataChanged('licenceNum', value)}

                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-license-date-d-h-lbl`} className={`form-label `}>{t('estimationQuotation:license_date')}</label>
                            <FrmGHDatePicker
                                id={`${dialogId}-license-date-d-h`}
                                gregorianDate={data?.licenceDateDgr}
                                onGregorianChange={(value) => _handleDataChanged("licenceDateDgr", value)}
                                hijriDate={data?.licenceDateDhi}
                                disabledHijri={false}
                                onHijriChange={(value) => _handleDataChanged("licenceDateDhi", value)}

                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-property-description-lbl`} className={`form-label `}>{t('estimationQuotation:property_description')}</label>
                            <FrmInput
                                id={`${dialogId}-property-description`}
                                value={data?.propertyDescription}
                                onBlur={(val) => _handleDataChanged("propertyDescription", val)}

                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-region-lbl`} className={`form-label ${isOpen ? 'mandatory' : ''}`}>{t("estimationQuotation:region")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-region`}
                                value={data.frsId}
                                onChange={value => _handleDataChanged("frsId", value)}
                                api={() => getRegionsLov(isEmpty(data.frsId) ? [] : [data.frsId], STATUS_ACTIVE, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-city-lbl`} className={`form-label ${isOpen ? 'mandatory' : ''}`}>{t("estimationQuotation:city")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-city`}
                                key={`${dialogId}-city-${data.frsId}`}
                                value={data.ecdId}
                                onChange={value => _handleDataChanged("ecdId", value)}
                                defualtValueId={data.ecdId}
                                defualtValueApi={() => getCitiesLov(isEmpty(data.ecdId) ? [] : [data.ecdId])}
                                api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, data.frsId, searchValue)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isAsync={true}
                                isDisabled={!data.frsId}
                                portalTarget={document.body}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-town-lbl`} className={`form-label ${isOpen ? 'mandatory' : ''}`}>{t("estimationQuotation:town")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-town`}
                                key={`${dialogId}-town-${data.ecdId}`}
                                value={data.criId}
                                onChange={value => _handleDataChanged("criId", value)}
                                api={(searchKey) => getTownsList(searchKey, null, data.ecdId, STATUS_ACTIVE, true, null, true)}
                                defualtValueId={data.criId}
                                defualtValueApi={() => getTownsList(null, isEmpty(data.criId) ? [] : [data.criId], data.ecdId, STATUS_ACTIVE, true, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isAsync
                                isDisabled={!data.ecdId}
                                portalTarget={document.body}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-property-type-lbl`} className={`form-label ${isOpen ? 'mandatory' : ''}`}>{t("estimationQuotation:property_type")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-property-type`}
                                value={data.myoId}
                                api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, true, false, false, false, true, isEmpty(data.myoId) ? [] : [data.myoId])}
                                onChange={value => _handleDataChanged("myoId", value)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_AREA_LEVEL_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-area-level-lbl`} className={`form-label`}>{t("estimationQuotation:area_level")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-area-level`}
                                value={data.areaLevel}
                                options={lovs.areaLevels}
                                onChange={value => _handleDataChanged("areaLevel", value)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}
                            />
                        </div>
                    }
                    {
                        ((masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD))) &&
                        <div className="w50">
                            <label id={`${dialogId}-area-from-lbl`} className={`form-label`}>{t("estimationQuotation:area_from")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-area-from`}
                                value={data.landAreaFrom || ''}
                                onBlur={value => _handleDataChanged("landAreaFrom", value)}
                                decimalScale={-1}
                                allowDecimals={true}
                                withoutFormat
                                nullable
                            />
                        </div>
                    }
                    {
                        masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) &&
                        <div className="w50">
                            <label id={`${dialogId}-area-to-lbl`} className={`form-label`}>{t("estimationQuotation:area_to")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-area-to`}
                                value={data.landAreaTo || ''}
                                onBlur={value => _handleDataChanged("landAreaTo", value)}
                                decimalScale={-1}
                                allowDecimals={true}
                                withoutFormat
                                nullable
                            />
                        </div>
                    }
                    {
                        ((masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD))) &&
                        <div className="w50">
                            <label id={`${dialogId}-num-of-floor-from-lbl`} className={`form-label`}>{t("estimationQuotation:no_of_floors_from")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-num-of-floor-from`}
                                value={formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? data.noOfFloorsFrom : data.numberOfFloorsFrom || ''}
                                onBlur={value => _handleDataChanged(formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? "noOfFloorsFrom" : "numberOfFloorsFrom", value)}
                                decimalScale={-1}
                                allowDecimals={true}
                                withoutFormat
                                nullable
                            />
                        </div>
                    }
                    {
                        ((masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD))) &&
                        <div className="w50">
                            <label id={`${dialogId}-num-of-floor-to-lbl`} className={`form-label`}>{t("estimationQuotation:no_of_floors_to")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-num-of-floor-to`}
                                value={formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? data.noOfFloorsTo : data.numberOfFloorsTo || ''}
                                onBlur={value => _handleDataChanged(formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? "noOfFloorsTo" : "numberOfFloorsTo", value)}
                                decimalScale={-1}
                                allowDecimals={true}
                                withoutFormat
                                nullable
                            />
                        </div>

                    }
                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-tri-id-lbl`} className={`form-label `}>{t("estimationQuotation:evaluation_approch")} :</label>
                            <div className={style.checkboxContainer}>
                                <div>
                                    <Checkbox
                                        id={`${dialogId}-cost-approch`}
                                        label={t('estimationQuotation:cost_approch')}
                                        isChecked={data?.aproachCost}
                                        setIsChecked={value => _handleDataChanged("aproachCost", value)}

                                    />
                                </div>
                                <div>
                                    <Checkbox
                                        id={`${dialogId}-market-approch`}
                                        label={t('estimationQuotation:market_approch')}
                                        isChecked={data?.aproachMarket}
                                        setIsChecked={value => _handleDataChanged("aproachMarket", value)}
                                    />
                                </div>
                                <div>
                                    <Checkbox
                                        id={`${dialogId}-income-approch`}
                                        label={t('estimationQuotation:income_approch')}
                                        isChecked={data?.aproachIncome}
                                        setIsChecked={value => _handleDataChanged("aproachIncome", value)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {(data?.aproachIncome || data?.aproachMarket || data?.aproachCost) && masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-tri-id-lbl`} className={`form-label `}>{t("estimationQuotation:evaluation_methods")} :</label>
                            <div className={style.checkboxContainer}>
                                {data.aproachCost &&
                                    <div>
                                        <Checkbox
                                            id={`${dialogId}-replacement-cost`}
                                            label={t('estimationQuotation:replacement_cost')}
                                            isChecked={data?.methodReplaceCost}
                                            setIsChecked={value => _handleDataChanged("methodReplaceCost", value)}
                                        />
                                    </div>
                                }
                                {data.aproachMarket &&
                                    <div>
                                        <Checkbox
                                            id={`${dialogId}-comparison`}
                                            label={t('estimationQuotation:comparison')}
                                            isChecked={data?.methodComparision}
                                            setIsChecked={value => _handleDataChanged("methodComparision", value)}
                                        />
                                    </div>
                                }
                                {data.aproachIncome &&
                                    <div>
                                        <Checkbox
                                            id={`${dialogId}-income-capitalization-investment`}
                                            label={t('estimationQuotation:income_capitalization_investment')}
                                            isChecked={data?.methodIncomeCaptal}
                                            setIsChecked={value => _handleDataChanged("methodIncomeCaptal", value)}
                                            disabled={!data.aproachIncome}

                                        />
                                    </div>
                                }
                                {data.aproachIncome &&
                                    <div>
                                        <Checkbox
                                            id={`${dialogId}-remaining-method`}
                                            label={t('estimationQuotation:remaining_method')}
                                            isChecked={data?.methodRemainValue}
                                            setIsChecked={value => _handleDataChanged("methodRemainValue", value)}
                                            disabled={!data.aproachIncome}

                                        />
                                    </div>
                                }
                                {data.aproachIncome &&
                                    <div>
                                        <Checkbox
                                            id={`${dialogId}-discounted-cashflows`}
                                            label={t('estimationQuotation:discounted_cashflows')}
                                            isChecked={data?.methodCashFlow}
                                            setIsChecked={value => _handleDataChanged("methodCashFlow", value)}
                                            disabled={!data.aproachIncome}

                                        />
                                    </div>
                                }
                                {data.aproachIncome &&
                                    <div>
                                        <Checkbox
                                            id={`${dialogId}-profits`}
                                            label={t('estimationQuotation:profits')}
                                            isChecked={data?.methodInvestment}
                                            setIsChecked={value => _handleDataChanged("methodInvestment", value)}
                                            disabled={!data.aproachIncome}

                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-estimation-reason-lbl`} className={`form-label`}>{t("estimationQuotation:evaluation_purpose")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-estimation-reason`}
                                value={data.estimationReason}
                                options={lovs.estimationReason}
                                onChange={value => _handleDataChanged("estimationReason", value)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}

                            />
                        </div>
                    }
                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-value-assumption-lbl`} className={`form-label`}>{t("estimationQuotation:value_assumption")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-value-assumption`}
                                value={data.valueAssumption}
                                options={lovs.valueAssumption}
                                onChange={value => _handleDataChanged("valueAssumption", value)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}

                            />
                        </div>
                    }
                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_USE_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-property-use-lbl`} className={`form-label`}>{t("estimationQuotation:usage_type")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-property-use`}
                                value={data.propertyUse}
                                options={lovs.propertyUse}
                                onChange={value => _handleDataChanged("propertyUse", value)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}

                            />
                        </div>
                    }
                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-estimation-base-lbl`} className={`form-label`}>{t("estimationQuotation:estimation_base")}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-estimation-base`}
                                value={data.estimationBase}
                                options={lovs.estimationBase}
                                onChange={value => _handleDataChanged("estimationBase", value)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}

                            />
                        </div>
                    }


                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PRICE_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-price-lbl`} className={`form-label`}>{t("estimationQuotation:price")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-price`}
                                value={data.price || ''}
                                onBlur={value => _handleDataChanged("price", value)}
                                allowDecimals={true}
                                nullable
                                minValue={0}
                            />
                        </div>
                    }


                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_PERC_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-vat-percentage-lbl`} className={`form-label`}>{t("estimationQuotation:vat_perc")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-vat-percentage`}
                                value={formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? data.vatPerc : data.vatPercentage}
                                onBlur={value => _handleDataChanged(formId == RSM3272_EVALUATION_CONTRACTS_FORM_ID ? 'vatPerc' : 'vatPercentage', value)}
                                minValue={0}
                                allowDecimals={true}
                                nullable
                            />
                        </div>
                    }


                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-vat-lbl`} className={`form-label`}>{t("estimationQuotation:vat")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-vat`}
                                value={parseNumber(data.vat)}
                                onBlur={value => _handleDataChanged("vat", value)}
                                allowDecimals={true}
                                minValue={0}
                                nullable
                            />
                        </div>
                    }

                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NET_PRICE_FIELD) &&

                        <div className="w100">
                            <label id={`${dialogId}-net-price-lbl`} className='form-label'>{t("estimationQuotation:total_price")}</label>
                            <FrmDecimalInput
                                id={`${dialogId}-net-price`}
                                value={data.netPrice}
                                allowDecimals={true}
                                minValue={0}
                                disabled={true}
                                nullable
                            />
                        </div>
                    }

                    {masterData?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REM_FIELD) &&
                        <div className="w100">
                            <label id={`${dialogId}-rem-lbl`} className='form-label'>{t("estimationQuotation:remarks")}</label>
                            <FrmTextArea
                                id={`${dialogId}-rem`}
                                value={data.rem}
                                onBlur={value => _handleDataChanged('rem', value)}
                                className={style.typeText}
                            />
                        </div>
                    }
                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(DetailsSidePanel);