import React from "react";

function XIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "10.707"}
      height={props.height || "10.707"}
      viewBox="0 0 10.707 10.707"
    >
      <g
        fill="none"
        stroke="#f60a20"
        strokeWidth="1"
        data-name="Group 172849"
        transform="translate(-88.146 -35.146)"
      >
        <path
          d="M10 0L0 10"
          data-name="Line 673"
          transform="translate(88.5 35.5)"
        ></path>
        <path
          d="M10 0L0 10"
          data-name="Line 674"
          transform="rotate(90 31.5 67)"
        ></path>
      </g>
    </svg>
  );
}


export default XIcon;
