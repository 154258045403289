import React from 'react';
import { Paginate as GPaginate } from "@ipgd-gauge/table"

const Paginate = (props) => {
    const { fetchData, pageSize } = props;
    const handlePageChange = (page) => {
        if (fetchData) {
            fetchData(page.selected, pageSize)
        }
    }

    return (
        <GPaginate
            {...props}
            handlePageClick={handlePageChange}
        />
    )
}

export default Paginate; 
