import { EditBtn } from "@ipgd-gauge/table";
import { handleError, isAllowedAction, isEnglish } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { getEstimationLOVList } from "../../../util/apis";
import { VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, dialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesEstimationListOfValuesRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesEstimationListOfValues:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data?.descLo : data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('name')}</label>
                <label>{isEnglish() ? data?.descLo : data.descFo}</label>
            </div>

            <div title={data?.shDescLo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesEstimationListOfValues:short_english')}</label>
                <label>{data?.shDescLo}</label>
            </div>

            <div title={data?.shDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesEstimationListOfValues:short_arabic')}</label>
                <label>{data?.shDescFo}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('propertiesEstimationListOfValues:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>
            <div className={`containerButtonTable ${style.propertiesEstimationBtnStyle}`}>
                {isAllowedAction(user, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, VIEW_EDIT_ACTION) &&
                    <EditBtn onClick={() => dialogRef?.current?.open(data.id)} />
                }
            </div>
        </div>
    );
};

const PropertiesEstimationListOfValuesTable = (props) => {
    const { user, tableRef, searchTextRef, dialogRef } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesEstimationListOfValues:code'), width: "10%" },
        { title: t('name'), width: "20%" },
        { title: t('propertiesEstimationListOfValues:short_english'), width: "23%" },
        { title: t('propertiesEstimationListOfValues:short_arabic'), width: "23%" },
        { title: t('propertiesEstimationListOfValues:status'), width: "10%" },
        { title: "", width: "9%" }
    ];

    const [data, setData] = useState();

    const _fetchData = (page, size) => {
        getEstimationLOVList(page, size, searchTextRef.current.getSearchText())
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t));
    }

    return (
        <div className={style.propertiesEstimationListOfValuesTableContainer}>

            <Slisting
                id="properties-estimation-list-of-values-table"
                ref={tableRef}
                formId={RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID}
                columns={columns}
                Row={Row}
                tableStyle={style.propertiesEstimationListOfValuesTable}
                getData={_fetchData}
                pagingData={data}
                rowActions={{ user, dialogRef }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                viewAction={(id) => dialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="90px"
            />
        </div>
    );
};

export default PropertiesEstimationListOfValuesTable;