import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { encryptText, isEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../../components/code-input";
import UploadImg from "../../../../components/upload-img";
import { getCategories, getFinancialGroups, getReports } from "../../../../util/apis";
import { CUSTOMER_FORM_LARGE_LOGO, CUSTOMER_FORM_SMALL_LOGO, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../../util/constants";
import { RSM3009_CREATE_CUSTOMER_RSM_FORM_ID } from "../../../../util/constants/forms";
import style from './style.module.scss';

const CATEGORY_CONDITION = "dependsOnUserOrg";
const Logos = forwardRef((props, ref) => {
    const { customer } = props;

    const { t } = useTranslation('customers');

    const bigLogoRef = useRef(null);
    const smallLogoRef = useRef(null);

    const uploadImages = async (transactionId) => {
        await bigLogoRef.current.uploadImages(transactionId);
        await smallLogoRef.current.uploadImages(transactionId);
    }

    useImperativeHandle(ref, () => ({ uploadImages }))

    return (
        <div className={`w100 ${style.step3}`}>
            <UploadImg
                ref={smallLogoRef}
                title={t("small_logo")}
                key={customer?.imgKey}
                masterId={customer?.id}
                formId={CUSTOMER_FORM_SMALL_LOGO}
                inSidePanel
            />

            <UploadImg
                ref={bigLogoRef}
                title={t("big_logo")}
                key={customer?.imgKey + 1}
                masterId={customer?.id}
                formId={CUSTOMER_FORM_LARGE_LOGO}
                inSidePanel
            />
        </div>
    )
})

const Tab1 = (props) => {

    const { tab, customer, handleDataChanged, logosTabRef } = props;
    const { t } = useTranslation('customers');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`container ${style.containerCustomersDialog}`}>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <CodeInput
                            id="code"
                            value={customer.code}
                            onBlur={(value) => handleDataChanged("code", value)}
                            column="ATR_CODE"
                            formId={RSM3009_CREATE_CUSTOMER_RSM_FORM_ID}
                        />
                    </div>

                    <div className="w100">
                        <label id="customer-status-lbl" data-label={encryptText("ATR_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="customer-status"
                            sidePanelMode
                            options={[
                                {
                                    title: t('active'),
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: customer.isActive == STATUS_ACTIVE
                                },
                                {
                                    title: t('inactive'),
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: customer.isActive == STATUS_IN_ACTIVE
                                },
                            ]}
                        />
                    </div>

                    <div className="w100">
                        <label id="english_name_customer-lbl" data-label={encryptText("ATR_OFFICIAL_NAME_LO")} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id="english_name_customer"
                            value={customer.officialNameLo}
                            onBlur={(value) => handleDataChanged("officialNameLo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="arabic_name_customer-lbl" data-label={encryptText("ATR_OFFICIAL_NAME_FO")} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id="arabic_name_customer"
                            value={customer.officialNameFo}
                            onBlur={(value) => handleDataChanged("officialNameFo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="id-number-customer-lbl" className='form-label'>{t('id_number')}</label>
                        <FrmInput
                            id="id-number-customer"
                            value={customer.idCardNum}
                            onBlur={(value) => handleDataChanged("idCardNum", value)}
                        />
                    </div>

                    <div className="w100">
                        <label data-label={encryptText("ATR_MOBILE_NUM")} className='form-label' >{t('mobile_num')}</label>
                        <FrmInput
                            id="customer_mobile"
                            type="number"
                            value={customer.mobileNum}
                            onBlur={(value) => handleDataChanged("mobileNum", value)}
                        />
                    </div>

                    <div className="w100">
                        <label data-label={encryptText("ATR_EMAIL")} className='form-label'>{t('email')}</label>
                        <FrmInput
                            id="email_customer"
                            value={customer.email}
                            onBlur={(value) => handleDataChanged("email", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="commercial-register-customer-lbl" className='form-label'>{t('commercial_register')}</label>
                        <FrmInput
                            id="commercial-register-customer"
                            value={customer.comerceRegNum}
                            onBlur={(value) => handleDataChanged("comerceRegNum", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="financial-group-lbl" data-label={encryptText("ATR_CIF_ID")} data-info={encryptText("CIF&&descLo&&descFo")} className='form-label mandatory'>{t('financial_group')}</label>
                        <FrmSelectMenu
                            id="financial-group"
                            key={`financial-group-${customer?.id || ''}`}
                            api={() => getFinancialGroups(false, STATUS_ACTIVE, isEmpty(customer.cifId) ? [] : [customer.cifId])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={customer.cifId}
                            onChange={e => handleDataChanged("cifId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w100">
                        <label id="category-lbl" data-label={encryptText("ATR_STR_ID")} data-info={encryptText("STR&&descLo&&descFo")} className='form-label mandatory'>{t('category')}</label>
                        <FrmSelectMenu
                            id="category"
                            key={`category-${customer?.id}`}
                            api={() => getCategories(null, CATEGORY_CONDITION, false, null, STATUS_ACTIVE, isEmpty(customer.strId) ? [] : [customer.strId])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={customer.strId}
                            onChange={e => handleDataChanged("strId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w100">
                        <label data-label={encryptText("ATR_TAX_NUMBER")} className='form-label'>{t('tax_id')}</label>
                        <FrmInput
                            id="customer_tax_id"
                            value={customer.taxNumber}
                            onBlur={(value) => handleDataChanged("taxNumber", value)}
                        />
                    </div>

                    <div className="w100">

                        <label data-label={encryptText("ATR_ERI_ID")} data-info={encryptText("ERI&&name&&name")} className='form-label'>{t('reports')}</label>
                        <FrmSelectMenu
                            id="reports"
                            key={`reports-${customer?.id}`}
                            api={() => getReports([customer.eriId])}
                            descLo={['name']}
                            descFo={['name']}
                            value={customer.eriId}
                            onChange={e => handleDataChanged("eriId", e.value)}
                            isClearable={true}
                            portalTarget={document.body}
                        />
                    </div>

                    <Logos ref={logosTabRef} customer={customer} />
                </div>
            </div>
        </div>
    )
}

export default Tab1;