import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../../util/constants/forms";
import OrganizationList from "../../../common/dialogs/organization-list";
import { ACTION_SET_PROPERTIES_POLICY } from "./reducer";

const Tab2 = (props) => {
    const { tab, data, dispatchPropertyPolicy } = props;

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className="paddingFrm tabsBg">
                <OrganizationList
                    data={data}
                    setData={(data) => dispatchPropertyPolicy({ type: ACTION_SET_PROPERTIES_POLICY, value: data })}
                    formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                />
            </div>
        </div>
    )
}

export default Tab2;