import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import validator from 'validator';
import ModalWithTabs from "../../../components/modal-with-tabs";
import CustomerIcon from "../../../icons/Customer";
import { getPropertyPartyDefinition, savePropertyPartyDefinition } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";
import Tab6 from "./Tab6";
import Tab7 from "./Tab7";

const PropertiesPartiesDefinitionDialog = (props, ref) => {

    const { propertiesPartiesDefinitionTableRef } = props;

    const { t } = useTranslation();
    const attachmentsImgRef = useRef();
    const dialogRef = useRef();
    const updateRecordRef = useRef(false);
    const user = useSelector(state => state.persistedReducer.userReducer.user);
    const [party, setParty] = useState({});

    const _handleDataChanged = (name, value) => {
        party[name] = value;
        if (name === "araId") {
            party.araId = value.value;
            party.type = value.type;
        }
        setParty({ ...party });
    }

    const _handleAddressDataChanged = (name, value) => {
        if (!party.address) {
            party.address = {};
        }
        party.address[name] = value;
        if (name == 'fcoId') {
            party.address.ecdId = null;
            party.address.criId = null;
        } else if (name == 'ecdId') {
            party.address.criId = null;
        }
        setParty({ ...party });
    }

    const _saveAction = () => {
        // if (isNotEmpty(party.mobile1)) {
        //     if (!validator.isMobilePhone(party.mobile1 || "", ['ar-SA', 'ar-JO'])) {
        //         handleError(null, "propertiesPartiesDefinition:not_valid", t, [{ name: 'lbl', value: t('propertiesPartiesDefinition:mobile_1') }]);
        //         return;
        //     }
        // }
        // if (isNotEmpty(party.mobile2)) {
        //     if (!validator.isMobilePhone(party.mobile2 || "", ['ar-SA', 'ar-JO'])) {
        //         handleError(null, "propertiesPartiesDefinition:not_valid", t, [{ name: 'lbl', value: t('propertiesPartiesDefinition:mobile_2') }]);
        //         return;
        //     }
        // }
        if (!checkValidation([{
            id: 'properties-parties-definition-dialog'
        }, {
            id: "banks-table",
            title: t('propertiesPartiesDefinition:banks'),
            data: party.banks,
        }, {
            id: "locations-table",
            title: t('propertiesPartiesDefinition:locations'),
            data: party.locations,
        }], t)) {
            return;
        }
        // if (isNotEmpty(party.phoneNum)) {
        //     if (!validator.isMobilePhone(party.phoneNum || "", ['ar-SA', 'ar-JO'])) {
        //         handleError(null, "propertiesPartiesDefinition:not_valid", t, [{ name: 'lbl', value: t('propertiesPartiesDefinition:phone') }]);
        //         return;
        //     }
        // }
        // if (isNotEmpty(party.emailAddress)) {
        //     if (!validator.isEmail(party.emailAddress || "")) {
        //         handleError(null, "email_not_valid", t);
        //         return;
        //     }
        // }
        showWaiting('properties-parties-definition-dialog-okButton', true)
        savePropertyPartyDefinition(party)
            .then(async (response) => {
                await attachmentsImgRef?.current?.uploadImages(response.data.id);
                showSuccessMessage("save_successfully", t);
                dialogRef.current.changeSelectedTab(1);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
            })
            .catch((error) => handleError(null, error?.response?.data?.message?.split('#'), t))
            .finally(() => {
                hideWaiting('properties-parties-definition-dialog-okButton')
            })
    }

    const _postClose = () => {
        setParty({});
        dialogRef.current?.changeSelectedTab(1);
        removeAllMandatoryClasses(document.getElementById("properties-parties-definition-dialog"))
    }

    const _newAction = () => {
        const newData = {
            isActive: STATUS_ACTIVE,
            allLoactions: '1',
            general: false,
            organizations: [
                {
                    fogId: user?.fogId,
                    offecialNameLo: user?.orgNameLo,
                    offecialNameFo: user?.orgNameFo,
                    organizationCode: user?.orgCode
                }
            ],
            banks: [],
            locations: []
        }
        setParty({ ...newData });
    }

    const _populateRecordData = (partyId) => {
        if (partyId) {
            updateRecordRef.current = true;
            getPropertyPartyDefinition(partyId)
                .then((response) => {
                    setParty(response.data);
                })
                .catch((error) => {
                    handleError(error, null, t);
                })
        } else {
            _newAction();
        }
    }

    const open = (partyId) => {
        _populateRecordData(partyId)
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({
        open,
        close,
    }))

    const _preCloseAction = () => {
        updateRecordRef.current = false
        propertiesPartiesDefinitionTableRef?.current?.refresh()
    }
    return (
        <ModalWithTabs
            id="properties-parties-definition-dialog"
            ref={dialogRef}
            formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
            title={'propertiesPartiesDefinition:inspectors_employees_definition'}
            Icon={<CustomerIcon />}
            labels={[
                'home',
                'propertiesPartiesDefinition:organization_list',
                'propertiesPartiesDefinition:personal_data',
                'propertiesPartiesDefinition:address',
                'propertiesPartiesDefinition:banks',
                'propertiesPartiesDefinition:locations',
                'propertiesPartiesDefinition:attachments'
            ]}
            okBtnAction={_saveAction}
            hideOkBtn={!isAllowedAction(getUser(), RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, SAVE_ACTION)}
            postClose={_postClose}
            preClose={_preCloseAction}
            nextPreviousAction={_populateRecordData}
        >
            <Tab1 data={party} handleDataChanged={_handleDataChanged} />
            <Tab2 data={party} setData={setParty} />
            <Tab3 data={party} handleDataChanged={_handleDataChanged} />
            <Tab4 data={party} handleDataChanged={_handleAddressDataChanged} />
            <Tab5 user={user} data={party} setData={setParty} />
            <Tab6 user={user} data={party} handleDataChanged={_handleDataChanged} setData={setParty} />
            <Tab7 data={party} attachmentsImgRef={attachmentsImgRef} />
        </ModalWithTabs>
    )
}

export default forwardRef(PropertiesPartiesDefinitionDialog);