import React from "react";

function CustomerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.625"
      height="21.31"
      viewBox="0 0 20.625 21.31"
    >
      <path
        fill="#292d32"
        d="M2.148 19.8A4.309 4.309 0 010 16.254a4.328 4.328 0 012.147-3.568 9.911 9.911 0 015.43-1.5 9.831 9.831 0 015.414 1.5 4.309 4.309 0 012.148 3.55 4.328 4.328 0 01-2.147 3.564 9.838 9.838 0 01-5.422 1.51 9.835 9.835 0 01-5.422-1.51zm.832-5.869a2.884 2.884 0 00-1.48 2.32 2.873 2.873 0 001.481 2.3A8.32 8.32 0 007.57 19.81a8.327 8.327 0 004.588-1.253 2.891 2.891 0 001.482-2.318 2.877 2.877 0 00-1.48-2.3 8.328 8.328 0 00-4.583-1.247 8.4 8.4 0 00-4.597 1.243zM16.011 18.9a.749.749 0 01.581-.886 4.113 4.113 0 001.656-.731l.006-.005a1.947 1.947 0 00.87-1.535 1.945 1.945 0 00-.87-1.535 4.185 4.185 0 00-1.64-.724.75.75 0 11.329-1.463 5.671 5.671 0 012.218.994 3.452 3.452 0 011.468 2.732 3.452 3.452 0 01-1.467 2.733 5.6 5.6 0 01-2.26 1.006.758.758 0 01-.154.016.75.75 0 01-.737-.602zm-2.072-9.07A.751.751 0 0114.6 9a1.961 1.961 0 01.365 0 2.751 2.751 0 00-.15-5.5.75.75 0 010-1.5 4.251 4.251 0 01.158 8.5.687.687 0 01-.122-.005.386.386 0 00-.084 0h-.084a.751.751 0 01-.744-.662zm-6.729.54a5.172 5.172 0 01-5-5.18 5.19 5.19 0 115.376 5.18h-.021a.681.681 0 01-.075 0 1.1 1.1 0 00-.188 0h-.07zm.372-1.5a3.686 3.686 0 10-.362 0c.06 0 .122-.006.183-.006s.121.001.179.005z"
        data-name="Union 20"
      ></path>
    </svg>
  );
}

export default CustomerIcon;