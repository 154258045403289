import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";

const dialogId = "estimation-messages-templates-dialog";

const ToUsersListRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div><div> <label>{data?.sequence}</label></div></div>
            <div> <div><label>{data?.code}</label> </div></div>
            <div> <div><label>{isEnglish() ? data?.descLo : data?.descFo}</label></div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

const ToUsersListTable = (props) => {
    const { data, setData, user } = props;

    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data.messageTemplateToUser) {
            data.messageTemplateToUser = [];
        }

        deleteItemByIndex(data.messageTemplateToUser, index);
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: <span data-property="code" className="mandatory">{t('code')}</span>, width: "30%" },
        { title: <span data-property={isEnglish() ? "descLo" : "descFo"} className="mandatory">{t('name')}</span>, width: "55%" },
        { title: "", width: "10%" }
    ];

    return (
        <Table
            id={`${dialogId}-to-users-list-table`}
            columns={columns}
            list={data?.messageTemplateToUser || []}
            Row={ToUsersListRow}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            tableStyle={style.toUsersListTable}
            rowHeight={40}
            user={user}
            formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
        />
    );
}


const Tab4 = (props) => {
    const { tab, data, setData, user, toUserListRef } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.messageTemplateToUser = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 4 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('estimationMessagesTemplates:insert')}
                            onClick={() => toUserListRef.current.open()}>

                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('estimationMessagesTemplates:insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('estimationMessagesTemplates:delete_all')}
                            onClick={_deleteAllAction}
                            disabled={(data?.messageTemplateToUser || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('estimationMessagesTemplates:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>

            <ToUsersListTable data={data} setData={setData} user={user} />
        </div>
    )
}

export default Tab4;