import React from "react";

function TimerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="237.748"
      height="205.687"
      data-name="Group 180171"
      viewBox="0 0 197.748 175.687"
    >
      <path
        fill="#c7ceff"
        fillRule="evenodd"
        d="M328.818 969.417c37.852 0 68.583 3.33 68.583 7.43s-30.731 7.43-68.583 7.43-68.583-3.33-68.583-7.43 30.731-7.43 68.583-7.43"
        data-name="Path 44819"
        transform="translate(-228.458 -808.589)"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M237.762 319.528a21.252 21.252 0 00-39.479 2.535 11.548 11.548 0 00-10.983 11.686c.006.6 62.98.414 62.98 0 .035-9.683-3.289-14.309-10.947-14.309a14.018 14.018 0 00-1.572.088"
        data-name="Path 44820"
        transform="translate(-173.24 -307.299)"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M536.693 328.681a30.7 30.7 0 0157.025 3.662 16.68 16.68 0 0115.863 16.879c-.009.871-90.97.6-90.97 0-.053-13.986 4.749-20.669 15.81-20.669a20.161 20.161 0 012.271.128"
        data-name="Path 44821"
        transform="translate(-424.074 -310.113)"
      ></path>
      <path
        fill="#c7ceff"
        d="M136.435 548.084a1.71 1.71 0 01-1.212-.5l-1.442-1.442-1.442 1.442a1.714 1.714 0 01-2.424-2.425l1.442-1.442-1.442-1.442a1.714 1.714 0 012.424-2.424l1.442 1.442 1.442-1.442a1.714 1.714 0 012.425 2.424l-1.442 1.442 1.442 1.442a1.715 1.715 0 01-1.212 2.927m189.01-36.946a1.708 1.708 0 01-1.212-.5l-1.442-1.442-1.441 1.442a1.714 1.714 0 01-2.424-2.425l1.441-1.442-1.441-1.442a1.714 1.714 0 012.424-2.425l1.441 1.442 1.442-1.442a1.714 1.714 0 012.424 2.425l-1.441 1.442 1.441 1.442a1.715 1.715 0 01-1.212 2.927m-171.865-54.5a1.709 1.709 0 01-1.212-.5l-1.442-1.442-1.442 1.442a1.714 1.714 0 01-2.424-2.425l1.442-1.442-1.442-1.442a1.714 1.714 0 012.424-2.425l1.442 1.442 1.442-1.442a1.714 1.714 0 012.425 2.425l-1.442 1.442 1.442 1.442a1.715 1.715 0 01-1.212 2.927"
        data-name="Path 44822"
        transform="translate(-129.411 -413.75)"
      ></path>
      <path
        fill="#fff"
        d="M408.837 488.627a5.357 5.357 0 013.1-4.123c8.623-4.42 25.759-13.246 31.006-22.954 8.585-15.885 4.8-31.064 2.008-38.3l-1.079-2.8a4.073 4.073 0 00-3.258-1.614H372.49a4.074 4.074 0 00-3.258 1.613l-1.079 2.8c-2.787 7.241-6.577 22.42 2.008 38.3 5.247 9.708 22.381 18.533 31.005 22.954a4.293 4.293 0 010 8.245c-8.623 4.42-25.758 13.246-31.005 22.952-8.585 15.885-4.8 31.066-2.008 38.3l1.079 2.806a4.077 4.077 0 003.258 1.612h68.119a4.079 4.079 0 003.258-1.612l1.079-2.806c2.787-7.239 6.577-22.42-2.008-38.3-5.247-9.707-22.382-18.534-31.006-22.952a5.358 5.358 0 01-3.1-4.122"
        data-name="Path 44823"
        transform="translate(-307.676 -391.738)"
      ></path>
      <path
        fill="#7819a5"
        d="M455.023 847.392c-2.5-2.194-14.959-4.005-18.391-7.011s-15.366-13.774-20.749-13.774c-4.629 0-10.915 12.443-16.735 15.1-5.4 2.461-18.826 6.33-21.952 11.285a47.936 47.936 0 002.464 8.915l.961 2.5a2.2 2.2 0 001.2.427h68.119a2.2 2.2 0 001.2-.427l.961-2.5a47.148 47.148 0 003.082-14.425c-.1-.055-.166-.085-.166-.085"
        data-name="Path 44824"
        opacity="0.22"
        transform="translate(-317.01 -700.468)"
      ></path>
      <path
        fill="#7819a5"
        d="M447.846 577.29c-7.916 0-17.073 7.549-21.912 7.549-4.473 0-16.891-5.277-22.013-6.829a26.516 26.516 0 00-13.45.079c.348.746.718 1.494 1.124 2.245 4.935 9.133 21.877 17.816 30.019 21.988a7.861 7.861 0 014.321 5.7 7.861 7.861 0 014.321-5.7c8.142-4.172 25.083-12.855 30.019-21.988q.383-.708.732-1.415a54.6 54.6 0 00-13.159-1.629"
        data-name="Path 44825"
        opacity="0.22"
        transform="translate(-327.059 -511.628)"
      ></path>
      <path
        fill="#7819a5"
        d="M451.04 949.294a50.229 50.229 0 01-1.956 6.327L448 958.43a4.081 4.081 0 01-3.255 1.609h-68.119a4.083 4.083 0 01-3.256-1.609l-1.079-2.81a50.046 50.046 0 01-1.957-6.327z"
        data-name="Path 44826"
        transform="translate(-311.814 -793.354)"
      ></path>
      <path
        fill="#7819a5"
        d="M370.334 429.575h80.706a50.346 50.346 0 00-1.956-6.328L448 420.44a4.08 4.08 0 00-3.256-1.612h-68.119a4.081 4.081 0 00-3.255 1.612l-1.079 2.807a50.127 50.127 0 00-1.957 6.328"
        data-name="Path 44827"
        transform="translate(-311.814 -391.738)"
      ></path>
      <path
        fill="#49508f"
        d="M435.221 554.8H367.1a5.77 5.77 0 01-4.743-2.47 1.7 1.7 0 01-.114-.241l-1.079-2.806c-3.01-7.818-6.74-23.379 2.1-39.735 5.53-10.232 23.227-19.3 31.732-23.663a2.643 2.643 0 000-5.193c-8.5-4.359-26.2-13.429-31.732-23.664a1.715 1.715 0 013.017-1.631c5.025 9.3 22.082 18.042 30.279 22.243a7.108 7.108 0 014.025 5.55 1.678 1.678 0 010 .2 7.109 7.109 0 01-4.025 5.549c-8.2 4.2-25.255 12.946-30.279 22.242-8.187 15.147-4.715 29.6-1.917 36.873l1.011 2.631a2.673 2.673 0 001.725.688h68.119a2.677 2.677 0 001.726-.688l1.01-2.63c2.8-7.269 6.272-21.725-1.915-36.874a26.243 26.243 0 00-5.493-6.608 1.715 1.715 0 012.311-2.534 29.6 29.6 0 016.2 7.511c8.84 16.358 5.109 31.917 2.1 39.736l-1.077 2.8a1.691 1.691 0 01-.115.241 5.774 5.774 0 01-4.744 2.47m-28.678-65.675a1.7 1.7 0 01-.78-.189 7.11 7.11 0 01-4.026-5.549 1.678 1.678 0 010-.2 7.108 7.108 0 014.026-5.55c8.2-4.2 25.254-12.946 30.279-22.243 8.187-15.147 4.714-29.6 1.917-36.873l-1.011-2.63a2.673 2.673 0 00-1.726-.69H367.1a2.67 2.67 0 00-1.725.689l-1.012 2.631a1.715 1.715 0 01-3.2-1.231l1.079-2.805a1.7 1.7 0 01.114-.24 5.77 5.77 0 014.744-2.472h68.119a5.77 5.77 0 014.744 2.473 1.743 1.743 0 01.115.24l1.078 2.8c3.01 7.819 6.739 23.379-2.1 39.736-5.53 10.233-23.226 19.3-31.731 23.664a2.643 2.643 0 000 5.193 1.715 1.715 0 01-.782 3.241"
        data-name="Path 44828"
        transform="translate(-302.288 -386.394)"
      ></path>
    </svg>
  );
}

export default TimerIcon;
