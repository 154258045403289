
import Rating from 'react-rating';
import EmptyRatingIcon from "../../icons/EmptyRating";
import FullRatingIcon from "../../icons/FullRating";
import { isEnglish } from '../../util/util';
import style from './style.module.scss';

const GRating = (props) => {

    const { value, onChange, readonly, fractions, starNumber } = props

    return (
        <div className={style.ratingContainer}>
            <Rating
                fractions={fractions ? fractions : "2"}
                direction={isEnglish() ? 'ltr' : 'rtl'}
                initialRating={value}
                emptySymbol={<EmptyRatingIcon />}
                fullSymbol={<FullRatingIcon />}
                onChange={(value) => onChange(value)}
                readonly={readonly}
                stop={starNumber || 10}
            />
        </div>
    );
}

export default GRating