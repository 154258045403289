import Checkbox from "@ipgd-gauge/checkbox";
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValues, getRealEstateUserLOV } from "../../../util/apis";
import { DOMAIN_ALERT_TYPE, DOMAIN_UPDATED_FIELDS, DOMAIN_WORKFLOW_PRIORITIES, STATUS_ACTIVE, STATUS_IN_ACTIVE, UPDATED_FIELDS_SPECIFIC_FIELDS } from "../../../util/constants";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const dialogId = "estimation-messages-templates-dialog";
const Tab1 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('estimationMessagesTemplates');

    const _handleDataChanged = (name, value) => {
        if (name == "updatedFields" && value != UPDATED_FIELDS_SPECIFIC_FIELDS) {
            data.fields = []
        }
        if (name == "toOthers" && !value) {
            data.messageTemplateToUser = []
        }
        if (name == "ccOthers" && !value) {
            data.messageTemplateCCUser = []
        }

        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id={`${dialogId}-code`}
                            value={data?.code}
                            onBlur={(value) => _handleDataChanged("code", value)}
                            column="MTO_CODE"
                            formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-accounts-groups-status-lbl`} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="accounts-groups-status"
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${dialogId}-sender-lbl`} className='form-label mandatory'>{t('sender')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-sender`}
                            key={`${dialogId}-sender-${data.id}`}
                            value={data.ssuId}
                            onChange={value => _handleDataChanged("ssuId", value.value)}
                            defualtValueId={data.ssuId}
                            defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, isEmpty(data?.ssuId) ? [] : [data.ssuId], true)}
                            api={(searchValue) => getRealEstateUserLOV(searchValue, STATUS_ACTIVE, false, false, null, null, null, null, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isAsync={true}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${dialogId}-message-type-lbl`} className='form-label mandatory'>{t('message_type')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-message-type`}
                            api={() => getDomainValues(DOMAIN_ALERT_TYPE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data.type}
                            onChange={value => _handleDataChanged("type", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${dialogId}-priority-lbl`} className='form-label mandatory'>{t('priority')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-priority`}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data.priority}
                            api={() => getDomainValues(DOMAIN_WORKFLOW_PRIORITIES)}
                            onChange={value => _handleDataChanged("priority", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${dialogId}-updated-fields-lbl`} className='form-label mandatory'>{t('field_to_be_update')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-updated-fields`}
                            api={() => getDomainValues(DOMAIN_UPDATED_FIELDS)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data.updatedFields}
                            onChange={value => _handleDataChanged("updatedFields", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className={`form-label ${style.sendLbl} `}>{t('to')}</label>
                        <div className={style.checkboxContainer}>
                            <Checkbox
                                id={`${dialogId}-to-coordinator`}
                                label={t('coordinator')}
                                isChecked={data?.toCoordinator}
                                setIsChecked={value => _handleDataChanged("toCoordinator", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-to-inspector`}
                                label={t('inspector')}
                                isChecked={data?.toInspector}
                                setIsChecked={value => _handleDataChanged("toInspector", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-to-customer`}
                                label={t('customer')}
                                isChecked={data?.toCustomer}
                                setIsChecked={value => _handleDataChanged("toCustomer", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-to-estimator`}
                                label={t('estimator')}
                                isChecked={data?.toEstimator}
                                setIsChecked={value => _handleDataChanged("toEstimator", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-to-reviewer`}
                                label={t('reviewer')}
                                isChecked={data?.toReviewer}
                                setIsChecked={value => _handleDataChanged("toReviewer", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-to-other`}
                                label={t('other')}
                                isChecked={data?.toOthers}
                                setIsChecked={value => _handleDataChanged("toOthers", value)}
                            />
                        </div>
                    </div>
                    <div className="w100">
                        <label className={`form-label ${style.sendLbl} `}>{t('cc')}</label>
                        <div className={style.checkboxContainer}>
                            <Checkbox
                                id={`${dialogId}-cc-coordinator`}
                                label={t('coordinator')}
                                isChecked={data?.ccCoordinator}
                                setIsChecked={value => _handleDataChanged("ccCoordinator", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-cc-inspector`}
                                label={t('inspector')}
                                isChecked={data?.ccInspector}
                                setIsChecked={value => _handleDataChanged("ccInspector", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-cc-customer`}
                                label={t('customer')}
                                isChecked={data?.ccCustomer}
                                setIsChecked={value => _handleDataChanged("ccCustomer", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-cc-estimator`}
                                label={t('estimator')}
                                isChecked={data?.ccEstimator}
                                setIsChecked={value => _handleDataChanged("ccEstimator", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-cc-reviewer`}
                                label={t('reviewer')}
                                isChecked={data?.ccReviewer}
                                setIsChecked={value => _handleDataChanged("ccReviewer", value)}
                            />
                            <Checkbox
                                id={`${dialogId}-cc-other`}
                                label={t('other')}
                                isChecked={data?.ccOthers}
                                setIsChecked={value => _handleDataChanged("ccOthers", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;