export const DefinitionsModul = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g
                fill="#292d32"
                data-name="Group 179968"
                transform="translate(5017.75 11620.75)"
            >
                <path
                    d="M8 8.75H4C.582 8.75-.75 7.418-.75 4V0a.75.75 0 01.463-.693.75.75 0 01.817.163l8 8A.75.75 0 018 8.75zM.75 1.811V4c0 2.582.668 3.25 3.25 3.25h2.189z"
                    transform="translate(-5005 -11620)"
                ></path>
                <g data-name="Group 179967" transform="translate(-5191 -11810)">
                    <path
                        d="M13 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h5a.75.75 0 01.75.75.75.75 0 01-.75.75H7c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h6c2.346 0 3.886-.447 4.845-1.405S19.25 15.346 19.25 13V8a.75.75 0 011.5 0v5c0 2.77-.586 4.647-1.845 5.905S15.77 20.75 13 20.75z"
                        data-name="Vector"
                        transform="translate(174 190)"
                    ></path>
                    <path
                        d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(179 201)"
                    ></path>
                    <path
                        d="M4 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(179 205)"
                    ></path>
                </g>
            </g>
        </svg>
    )
}
export const TransactionsModul = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.5"
            height="20.147"
            viewBox="0 0 19.5 20.147"
        >
            <g data-name="Group 179966" transform="translate(4885.75 11641.055)">
                <g
                    fill="#292d32"
                    data-name="Group 179965"
                    transform="translate(-5506 -12023)"
                >
                    <path
                        d="M8.4-.75a3.832 3.832 0 011.527.29l5.9 2.619c1.5.66 1.721 1.571 1.721 2.055s-.224 1.4-1.722 2.056L9.919 8.89a3.831 3.831 0 01-1.519.29 3.832 3.832 0 01-1.526-.29L.971 6.27C-.526 5.61-.75 4.7-.75 4.215S-.526 2.82.972 2.159L6.871-.46A3.831 3.831 0 018.4-.75zm0 8.43a2.393 2.393 0 00.913-.16l5.9-2.621c.609-.269.829-.556.829-.685s-.22-.416-.828-.684L9.311.91A2.393 2.393 0 008.4.75a2.393 2.393 0 00-.913.16L1.579 3.53C.97 3.8.75 4.087.75 4.215s.22.416.828.684l5.9 2.621a2.393 2.393 0 00.922.16z"
                        transform="translate(621.395 382.695)"
                    ></path>
                    <path
                        d="M9 6.093a2.765 2.765 0 01-1.118-.237L1.1 2.835A3.319 3.319 0 01-.75 0 .75.75 0 010-.75.75.75 0 01.75 0a1.819 1.819 0 00.95 1.464l6.795 3.021a1.237 1.237 0 001.007 0l6.798-3.02A1.819 1.819 0 0017.25 0a.75.75 0 011.5 0 3.32 3.32 0 01-1.85 2.836l-6.785 3.019A2.7 2.7 0 019 6.093z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(621 391)"
                    ></path>
                    <path
                        d="M9 6.093a2.765 2.765 0 01-1.118-.237L1.1 2.835A3.112 3.112 0 01-.75 0 .75.75 0 010-.75.75.75 0 01.75 0a1.61 1.61 0 00.956 1.465l6.789 3.02a1.237 1.237 0 001.007 0l6.798-3.02A1.61 1.61 0 0017.25 0a.75.75 0 011.5 0 3.112 3.112 0 01-1.844 2.835l-6.791 3.02A2.7 2.7 0 019 6.093z"
                        data-name="Vector"
                        opacity="0.4"
                        transform="translate(621 396)"
                    ></path>
                </g>
            </g>
        </svg>
    )
}
export const ReportsModul = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.5"
            height="21.45"
            viewBox="0 0 23.5 21.45"
        >
            <g
                fill="#088587"
                data-name="Group 178503"
                transform="translate(-4531.246 -2873.3)"
            >
                <path
                    d="M6.453-.75h12.3a2.977 2.977 0 012.76 1.836 2.954 2.954 0 01.149 1.889v.006l-3.37 13.528a2.974 2.974 0 01-2.908 2.282H2.243a2.959 2.959 0 01-2.4-1.219 2.989 2.989 0 01-.453-2.684L3.6 1.367A3.018 3.018 0 016.453-.75zM20.2 2.622a1.466 1.466 0 00-.067-.939l-.007-.017A1.482 1.482 0 0018.753.75h-12.3A1.507 1.507 0 005.03 1.811L.819 15.333a1.5 1.5 0 00.229 1.349 1.473 1.473 0 001.2.608h13.14a1.479 1.479 0 001.45-1.133v-.008z"
                    transform="translate(4531.997 2875.96)"
                ></path>
                <path
                    d="M4.78 16.75H0A.75.75 0 01-.75 16a.75.75 0 01.75-.75h4.78a1.448 1.448 0 001.07-.464 1.466 1.466 0 00.392-1.114L5.252.054a.75.75 0 01.694-.8.75.75 0 01.8.694l.99 13.62a2.992 2.992 0 01-.791 2.244 2.933 2.933 0 01-2.165.938z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(4547 2878)"
                ></path>
                <path
                    d="M0 5.07a.752.752 0 01-.176-.021.75.75 0 01-.554-.9L.311-.176a.75.75 0 01.9-.554.75.75 0 01.554.9L.729 4.5A.75.75 0 010 5.07z"
                    data-name="Vector"
                    transform="translate(4540.68 2874.06)"
                ></path>
                <path
                    d="M0 5.09a.753.753 0 01-.159-.017.75.75 0 01-.574-.892l.94-4.34A.75.75 0 011.1-.733a.75.75 0 01.574.892L.733 4.5A.75.75 0 010 5.09z"
                    data-name="Vector"
                    transform="translate(4547.38 2874.05)"
                ></path>
                <path
                    d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(4538.7 2884)"
                ></path>
                <path
                    d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(4537.7 2888)"
                ></path>
            </g>
        </svg>
    )
}