import Checkbox from "@ipgd-gauge/checkbox";
import { FrmDecimalInput } from '@ipgd-gauge/decimal-input';
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValues, getDomainValuesWithCondition } from "../../../util/apis";
import { DOMAIN_ADDITIONAL_ITEMS_SOURCES, STATUS_ACTIVE, STATUS_ESTIMATOR_FEES, STATUS_INSPECTOR_FEES, STATUS_IN_ACTIVE, STATUS_ORGANIZATION_ESTIMATION_FEES } from "../../../util/constants";
import { RSM2877_ADDITIONAL_ITEMS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const dialogId = "additional-items-dialog";

const Tab1 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('additionalItems');

    const _handleDataChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    // const isRefund = useMemo(() => data.id && data.refund, [data.id]);

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id={`${dialogId}-tab-1-code`}
                            value={data.code}
                            onBlur={value => _handleDataChange("code", value)}
                            column="CDA_CODE"
                            formId={RSM2877_ADDITIONAL_ITEMS_FORM_ID}
                        />
                    </div>
                    <div className="w25">
                        <label id={`${dialogId}-accounts-groups-status-lbl`} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id={`${dialogId}-accounts-groups-status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChange("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChange("isActive", STATUS_IN_ACTIVE),
                                },
                            ]} />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-tab-1-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChange("descLo", value)}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-tab-1-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChange("descFo", value)}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-tab-1-type-lbl`} className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-tab-1-type`}
                            value={data.type}
                            api={() => getDomainValuesWithCondition(DOMAIN_ADDITIONAL_ITEMS_SOURCES, true, [STATUS_ORGANIZATION_ESTIMATION_FEES, STATUS_INSPECTOR_FEES, STATUS_ESTIMATOR_FEES])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChange("type", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-remarks`}
                            value={data?.rem}
                            onBlur={value => _handleDataChange("rem", value)}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${dialogId}-tab-1-vat-perc-lbl`} className='form-label mandatory'>{t('vat_num')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-tab-1-vat-perc`}
                            value={data?.vatPerc}
                            onBlur={value => _handleDataChange("vatPerc", value)}
                            allowDecimals={true}
                            minValue={0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tab1;