import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmTextArea from "@ipgd-gauge/frm-text-area";
import { FrmSelectMenu, SelectMenu } from "@ipgd-gauge/select-menu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AttentionIcon from "../../../../icons/Attention";
import CriticalPriorityIcon from "../../../../icons/CriticalPriority";
import HighPriorityIcon from "../../../../icons/HighPriority";
import InformationIcon from "../../../../icons/Information";
import LowPriorityIcon from "../../../../icons/LowPriority";
import NormalPriorityIcon from "../../../../icons/NormalPriority";
import RemindIcon from "../../../../icons/Remind";
import TrackingIcon from "../../../../icons/Tracking";
import UpdateIcon from "../../../../icons/Update";
import { getRealEstateUserLOV, getTrxForSelect } from "../../../../util/apis";
import { ALERT_TYPE_ALERT, ALERT_TYPE_FOLLOW_UP, ALERT_TYPE_INFORMATION, ALERT_TYPE_REMINDER, ALERT_TYPE_UPDATE_DATA, MSG_RECEIVER_TYPE_COORDINATOR, MSG_RECEIVER_TYPE_CUSTOMER, MSG_RECEIVER_TYPE_ESTIMATOR, MSG_RECEIVER_TYPE_INSPECTOR, MSG_RECEIVER_TYPE_REVIEWED_BY, STATUS_ACTIVE, WORKFLOW_PRIORITIES_CRITICAL, WORKFLOW_PRIORITIES_HIGH, WORKFLOW_PRIORITIES_LOW, WORKFLOW_PRIORITIES_NORMAL } from "../../../../util/constants";
import style from './style.module.scss';
import { isEmpty } from "@ipgd-gauge/utils";

const Tab1 = (props) => {

    const { tab, message, handleDataChanged, fromTransactions } = props;
    const { t } = useTranslation();

    const defaultOptions = [
        { id: MSG_RECEIVER_TYPE_COORDINATOR, descLo: 'Coordinator', descFo: 'المنسق' },
        { id: MSG_RECEIVER_TYPE_INSPECTOR, descLo: 'Inspector', descFo: 'المعاين' },
        { id: MSG_RECEIVER_TYPE_CUSTOMER, descLo: 'Customer', descFo: 'العميل' },
        { id: MSG_RECEIVER_TYPE_ESTIMATOR, descLo: 'Estimator', descFo: 'المقيم' },
        { id: MSG_RECEIVER_TYPE_REVIEWED_BY, descLo: 'Reviewer', descFo: 'المراجع' },
    ]

    const [userOptins, setUserOptins] = useState(defaultOptions);

    useEffect(() => {
        getRealEstateUserLOV(null, STATUS_ACTIVE, false, false)
            .then((response) => setUserOptins([...defaultOptions, ...response.data]))
            .catch((error) => console.error("🚀 ~ error", error))
    }, [])

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className={`row  rowSmModel ${style.rowContainer}`} style={{ marginBottom: '0' }}>
                    <div className="w100">
                        <div className={style.optionsMsg}>
                            <button className={message?.type == ALERT_TYPE_REMINDER ? style.active : ''} onClick={() => handleDataChanged("type", ALERT_TYPE_REMINDER)}> <span><RemindIcon /></span>  {t('remind')}</button>
                            <button className={message?.type == ALERT_TYPE_UPDATE_DATA ? style.active : ''} onClick={() => handleDataChanged("type", ALERT_TYPE_UPDATE_DATA)}> <span><UpdateIcon /></span>  {t('update')}</button>
                            <button className={message?.type == ALERT_TYPE_INFORMATION ? style.active : ''} onClick={() => handleDataChanged("type", ALERT_TYPE_INFORMATION)}> <span><InformationIcon /></span>  {t('information')}</button>
                            <button className={message?.type == ALERT_TYPE_ALERT ? style.active : ''} onClick={() => handleDataChanged("type", ALERT_TYPE_ALERT)}> <span><AttentionIcon /></span>  {t('alert')}</button>
                            <button className={message?.type == ALERT_TYPE_FOLLOW_UP ? style.active : ''} onClick={() => handleDataChanged("type", ALERT_TYPE_FOLLOW_UP)}> <span><TrackingIcon /></span> {t('follow_up')}</button>
                        </div>
                    </div>
                    <div className="w100">
                        <div className={style.msgSelect}>
                            <div>
                                <label id="receiver-to-lbl" className='form-label mandatory'>{t('to')} :</label>
                            </div>
                            <SelectMenu

                                id="receiver-to"
                                portalTarget={document.body}
                                isMulti={true}
                                value={message.receiverSsuId}
                                options={userOptins}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("receiverSsuId", e)}
                            />
                        </div>
                    </div>
                    <div className="w100">
                        <div className={style.msgSelect}>
                            <div>
                                <label className='form-label'>{t('cc')} :</label>
                            </div>
                            <SelectMenu

                                portalTarget={document.body}
                                isMulti={true}
                                value={message.ccSsuId}
                                options={userOptins}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("ccSsuId", e)}
                            />
                        </div>
                    </div>
                    <div className="w100">
                        <label className={`form-label `}>{t('application')}</label>
                        <FrmSelectMenu
                            id="transaction"
                            isAsync={true}
                            key={`transaction-${message.id || 0}`}
                            value={message.triId}
                            api={(searchValue) => getTrxForSelect(searchValue)}
                            defualtValueApi={() => getTrxForSelect(null, isEmpty(message?.triId) ? [] : [message?.triId])}
                            defualtValueId={message.triId}
                            isClearable={true}
                            descLo={['code']}
                            descFo={['code']}
                            onChange={value => handleDataChanged("application", value)}
                            portalTarget={document.body}
                            isDisabled={fromTransactions}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('due_date')}</label>
                        <FrmTimeDatePickr
                            id="due-date"
                            value={message.dueDateTime}
                            onChange={(value) => handleDataChanged("dueDateTime", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('priority')}</label>
                        <div className={style.optionsMsg}>
                            <button className={`${style.lowPriority} ${message?.priority == WORKFLOW_PRIORITIES_LOW ? style.activePriority : ''}`} onClick={() => handleDataChanged("priority", WORKFLOW_PRIORITIES_LOW)}> <span><LowPriorityIcon /></span>  {t(`inbox:domain_${WORKFLOW_PRIORITIES_LOW}`)}</button>
                            <button className={`${style.normalPriority} ${message?.priority == WORKFLOW_PRIORITIES_NORMAL ? style.activePriority : ''}`} onClick={() => handleDataChanged("priority", WORKFLOW_PRIORITIES_NORMAL)}> <span><NormalPriorityIcon /> </span> {t(`inbox:domain_${WORKFLOW_PRIORITIES_NORMAL}`)}</button>
                            <button className={`${style.highPriority} ${message?.priority == WORKFLOW_PRIORITIES_HIGH ? style.activePriority : ''}`} onClick={() => handleDataChanged("priority", WORKFLOW_PRIORITIES_HIGH)}> <span> <HighPriorityIcon /></span> {t(`inbox:domain_${WORKFLOW_PRIORITIES_HIGH}`)}</button>
                            <button className={`${style.criticalPriority} ${message?.priority == WORKFLOW_PRIORITIES_CRITICAL ? style.activePriority : ''}`} onClick={() => handleDataChanged("priority", WORKFLOW_PRIORITIES_CRITICAL)}> <span><CriticalPriorityIcon /></span>  {t(`inbox:domain_${WORKFLOW_PRIORITIES_CRITICAL}`)}</button>
                        </div>
                    </div>
                    <div className="w100">
                        <label id="remarks-lbl" className='form-label mandatory'>{t('type_text')}</label>
                        <FrmTextArea
                            id="remarks"
                            value={message.text}
                            className={style.typeText}
                            onChange={(e) => handleDataChanged("text", e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;