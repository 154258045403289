import React from "react";

function MapPinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67.248"
      height="41.519"
      data-name="Group 178488"
      viewBox="0 0 67.248 41.519"
    >
      <path
        fill="#912a9a"
        d="M60.913 12.5H28.6a15.936 15.936 0 00-16.1 15.762 15.936 15.936 0 0016.1 15.762h9.214l6.863 6.718 6.863-6.718h9.368a15.936 15.936 0 0016.1-15.762A15.936 15.936 0 0060.913 12.5"
        data-name="Path 44745"
        transform="translate(-11.134 -11.134)"
      ></path>
      <path
        fill="#fff"
        d="M33.547 41.519l-7.421-7.263h-8.657A17.321 17.321 0 010 17.128 17.321 17.321 0 0117.469 0h32.31a17.321 17.321 0 0117.469 17.128 17.321 17.321 0 01-17.469 17.128h-8.811zM17.469 2.732a14.587 14.587 0 00-14.738 14.4 14.587 14.587 0 0014.738 14.4h9.771l6.307 6.168 6.306-6.172h9.925a14.587 14.587 0 0014.738-14.4 14.587 14.587 0 00-14.738-14.4z"
        data-name="Path 44746"
      ></path>
      <path
        fill="#fff"
        d="M109.909 99.511L103.873 95a.71.71 0 00-.856 0l-6.036 4.6a.71.71 0 00-.28.565v8.435a.71.71 0 00.71.71h12.072a.71.71 0 00.71-.71v-8.52a.711.711 0 00-.285-.569m-1.136 8.381h-2.929v-5.245h-4.793v5.245h-2.928v-7.371l5.33-4.065 5.322 3.979z"
        data-name="Path 44747"
        transform="translate(-69.823 -84.492)"
      ></path>
    </svg>
  );
}

export default MapPinIcon;
