import React from "react";

function DashHomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.465"
      height="21.502"
      viewBox="0 0 21.465 21.502"
    >
      <g data-name="Group 179946" transform="translate(4938.75 11029.75)">
        <g
          fill="#292d32"
          data-name="Group 179945"
          transform="translate(-5560 -11283)"
        >
          <path
            d="M2.67 5.91a.751.751 0 01-.1-.006.751.751 0 01-.122.006A3.331 3.331 0 115.91 2.58v.005A3.335 3.335 0 012.7 5.91zM2.58.75a1.831 1.831 0 00-.126 3.658 1.379 1.379 0 01.242 0A1.831 1.831 0 002.58.75z"
            opacity="0.4"
            transform="translate(635.328 254)"
          ></path>
          <path
            d="M1.061 5.776a6.985 6.985 0 01-1.155-.1A.75.75 0 01.154 4.2a4.886 4.886 0 003.4-.6 1.383 1.383 0 00.724-1.09 1.382 1.382 0 00-.724-1.086A4.9 4.9 0 00.129.829.75.75 0 01-.739.22a.75.75 0 01.61-.869 6.449 6.449 0 014.514.824A2.855 2.855 0 015.778 2.51a2.854 2.854 0 01-1.392 2.334 6.038 6.038 0 01-3.325.932z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(636.938 261.5)"
          ></path>
          <path
            d="M2.68 5.91a.751.751 0 01-.1-.006.751.751 0 01-.122.006A3.335 3.335 0 01-.75 2.585V2.58a3.33 3.33 0 113.457 3.33zM2.585 4.4c.041 0 .081 0 .121.005a1.835 1.835 0 10-.242 0c.036-.005.08-.005.121-.005z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(623.478 254)"
          ></path>
          <path
            d="M3.967 5.776A6.04 6.04 0 01.64 4.843 2.853 2.853 0 01-.75 2.51 2.854 2.854 0 01.641.176a6.449 6.449 0 014.516-.825A.75.75 0 114.9.829a4.9 4.9 0 00-3.426.6A1.38 1.38 0 00.75 2.51a1.382 1.382 0 00.724 1.09 4.886 4.886 0 003.4.6.75.75 0 01.248 1.48 6.986 6.986 0 01-1.155.096z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(622 261.5)"
          ></path>
          <g>
            <path
              d="M2.67 5.91a.751.751 0 01-.1-.006.751.751 0 01-.122.006A3.331 3.331 0 115.91 2.58v.005A3.321 3.321 0 012.692 5.91zM2.575 4.4c.042 0 .083 0 .124.006a1.829 1.829 0 10-.245 0c.04-.006.08-.006.121-.006z"
              data-name="Vector"
              transform="translate(629.33 261.47)"
            ></path>
            <path
              d="M3.971-.75a6.041 6.041 0 013.323.921A2.854 2.854 0 018.685 2.5a2.854 2.854 0 01-1.391 2.339 6.034 6.034 0 01-3.326.928 6.034 6.034 0 01-3.327-.929A2.853 2.853 0 01-.75 2.5 2.854 2.854 0 01.641.171a6.08 6.08 0 013.33-.921zm0 5.018a4.592 4.592 0 002.493-.676A1.382 1.382 0 007.185 2.5a1.382 1.382 0 00-.724-1.086A4.6 4.6 0 003.971.75a4.637 4.637 0 00-2.5.67A1.38 1.38 0 00.75 2.5a1.382 1.382 0 00.724 1.086 4.593 4.593 0 002.493.681z"
              data-name="Vector"
              transform="translate(628.032 268.985)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DashHomeIcon;