import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modal";
import style from './style.module.scss';

const SpecialPeriodModeulDialog = (props) => {
    const { specialPeriodModeulDialogRef, filterData, setFilterData, okAction, formId, id } = props;
    const { t } = useTranslation("transactionsStatistics");

    const _handleFilterDataChange = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const _okAction = () => {
        if (okAction) {
            okAction();
        }
    }

    return (
        <Modal
            id={`${id}-specialPeriodModeulDialog`}
            ref={specialPeriodModeulDialogRef}
            title={t('choose_period')}
            okBtnLabel={t("search")}
            okBtnAction={_okAction}
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.specialPeriodModeulDialog}
            formId={formId}
            withFooter
            withOkButton
            withCancelButton
            smallSize
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label className='form-label'>{t('from')}</label>
                        <FrmTimeDatePickr
                            id="transactions-statistics-filter-from-date"
                            value={filterData.fromDate || null}
                            onChange={(value) => _handleFilterDataChange("fromDate", value)}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('to')}</label>
                        <FrmTimeDatePickr
                            id="transactions-statistics-filter-to-date"
                            value={filterData.toDate || null}
                            onChange={(value) => _handleFilterDataChange("toDate", value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(SpecialPeriodModeulDialog);
