import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { checkValidation, encryptText, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import SidePanel from "../../../components/side-panel";
import { getDivision, saveDivision } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM2667_DIVISIONS_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { NEW_ACTION } from "../../../util/constants/actions";

const commonId = "division-definition-dialog-";
const DivisionDefinitionDialog = (props, ref) => {
    const { tableRef, user } = props;
    const { t } = useTranslation();
    const dialogRef = useRef();
    const updateRecordRef = useRef(false);

    const [data, setData] = useState({ isActive: STATUS_ACTIVE });

    const _handleDataChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _okBtnAction = () => {
        if (!checkValidation([{ id: "division-definition-dialog" }], t)) {
            return;
        }
        showWaiting("division-definition-dialog-btn", true)
        saveDivision(data)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => handleError(error, error.response.data?.message.split('#'), t))
            .finally(() => hideWaiting("division-definition-dialog-btn"))
    }
    const close = () => {
        dialogRef?.current?.close();
    }


    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getDivision(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t))
        } else {
            setData({ isActive: STATUS_ACTIVE })
        }
    }

    const open = (id) => {
        _populateRecordData(id)
        dialogRef.current.open();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _preCloseAction = () => {
        updateRecordRef.current = false
    }

    const _postClose = () => {
        setData({ isActive: STATUS_ACTIVE })
        removeAllMandatoryClasses(document.getElementById('division-definition-dialog'))
    }

    return (
        <SidePanel
            id="division-definition-dialog"
            ref={dialogRef}
            formId={RSM2667_DIVISIONS_DEFINITIONS_FORM_ID}
            footerAction={_okBtnAction}
            okBtnAction={_okBtnAction}
            title={t('divisionDefinition:division_definition')}
            withFooter={isAllowedAction(user, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, NEW_ACTION)}
            footerLbl={t('save')}
            preClose={_preCloseAction}
            postClose={_postClose}
            nextPreviousAction={_populateRecordData}
            showLog
            user={user}
            masterId={data.id}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <CodeInput
                            id={`${commonId}code`}
                            value={data?.code}
                            onBlur={(value) => _handleDataChange("code", value)}
                            column="DSV_CODE"
                            formId={RSM2667_DIVISIONS_DEFINITIONS_FORM_ID}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${commonId}status-lbl`} data-label={encryptText("DSV_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'>{t('status')}</label>
                        <RadioOptions
                            sidePanelMode
                            id={`${commonId}status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChange("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChange("isActive", STATUS_IN_ACTIVE),
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label id={`${commonId}english-name-lbl`} data-label={encryptText("DSV_DESC_LO")} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${commonId}english-name`}
                            value={data?.descLo}
                            onBlur={(value) => _handleDataChange("descLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${commonId}arabic-name-lbl`} data-label={encryptText("DSV_DESC_FO")} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${commonId}arabic-name`}
                            value={data?.descFo}
                            onBlur={(value) => _handleDataChange("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("DSV_REM")} className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${commonId}remarks`}
                            value={data?.rem}
                            onBlur={(value) => _handleDataChange("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(DivisionDefinitionDialog);