import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getCountriesLov, getDomainValues, getRegionsLov } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_DOMAIN } from "../../../util/constants";
import { FND2319_CITIES_DEFINITION_FORM_ID } from "../../../util/constants/forms";

const CitiesFilter = (props, ref) => {
    const { citiesTableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        let data = Object.assign(filterData, {});
        return data;
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    return (
        <AdvanceFilter
            id="citiesFilter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => citiesTableRef?.current?.refresh()}
            numOfRows={2}
            formId={FND2319_CITIES_DEFINITION_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('country')}</label>
                            <FrmSelectMenu
                                id="countries-filter"
                                isMulti={true}
                                value={filterData.countries}
                                api={() => getCountriesLov(filterData.countries)}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={value => _handleDataChanged("countries", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('region')}</label>
                            <FrmSelectMenu
                                id="region-filter"
                                isMulti={true}
                                value={filterData.regions}
                                api={() => getRegionsLov(filterData.regions, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("regions", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('status')}</label>
                            <StatusSelectMenu
                                id="status-cities-filter"
                                value={filterData.status}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isClearable={true}
                                onChange={e => _handleDataChanged("status", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </AdvanceFilter>
    )
}

export default forwardRef(CitiesFilter);