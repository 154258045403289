
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import FavMessagesIcon from "../../icons/FavMessages";
import OpeningFavIcon from "../../icons/OpeningFav";
import { changeFavoriteCount, changeFavoriteForms } from "../../store/user/actions";
import { saveFavoriteForms } from "../../util/apis";
import { getFormUrl, goToForm } from "../../util/formUtil";
import { getFormAttributeByPath, isEnglish, isNotEmpty } from "../../util/util";
import style from './style.module.scss';

const Card = (props) => {
    const { data, isFav, onFavChange } = props;
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.persistedReducer.userReducer.user);
    const [favBtn, setFavBtn] = useState(isFav);
    const dispatch = useDispatch();

    useEffect(() => {
        setFavBtn(isFav)
    }, [isFav])

    const _saveFavoriteForms = (e) => {
        e.stopPropagation();
        if (isNotEmpty(data?.dfrId)) {
            dispatch(changeFavoriteForms(data?.dfrId));

            saveFavoriteForms(data?.dfrId).then(response => {
                dispatch(changeFavoriteCount(response.data));
            });

            onFavChange(data, isFav);
        }
    }

    const getBackgroundModule = (module) => {
        switch (parseInt(module)) {
            case 1:
                return 'var(--module-color-definitions)';
            case 2:
                return 'var(--module-color-gl)';
            case 3:
                return 'var(--module-color-ap)';
            case 4:
                return 'var(--module-color-ar)';
            default:
                return ''
        }
    }

    return (
        <div className={style.cardContainer} onClick={() => goToForm(currentUser, null, navigate, `${data?.dfrId}`, null, null, null, false, true)}>
            <div className={style.imgContainer} style={{ backgroundColor: getBackgroundModule(data?.moduleId) }}>
            </div>
            <div className={style.cardContent}>
                <div className={style.formTitle}>
                    <div className={style.formName}>
                        <span style={{ "--module-color": getBackgroundModule(data?.moduleId) }}>
                            {getFormAttributeByPath(getFormUrl(`${data?.dfrId}`, true)).icon}
                        </span>
                        <label title={isEnglish() ? data?.formDescLo : data?.formDescFo}>
                            {isEnglish() ? data?.formDescLo : data?.formDescFo}
                        </label>
                    </div>
                    <div className={style.favBtn}>
                        {favBtn &&
                            <button onClick={(e) => _saveFavoriteForms(e)}>
                                <FavMessagesIcon />
                            </button>
                            ||
                            <button onClick={(e) => _saveFavoriteForms(e)}>
                                <OpeningFavIcon />
                            </button>
                        }
                    </div>
                </div>
                <div className={style.crumbs}>
                    <div title={isEnglish() ? data?.moduleDescLo : data?.moduleDescFo}>
                        <label>{isEnglish() ? data?.moduleDescLo : data?.moduleDescFo}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Card