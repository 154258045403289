import CodeInputComponent from "@ipgd-gauge/code-input";
import { isCodeFieldEnabled } from "../../util/apis";

const CodeInput = (props) => {

    const { id, name, className, placeholder, value, onClick, onChange, onBlur, onFocus, maxValue, minValue, onKeyDown, readOnly, ref, title, autoFocus, dir, ariaLabel, selectWhenFocus, column, formId } = props;

    return (
        <CodeInputComponent
            id={id}
            name={name}
            className={className}
            placeholder={placeholder}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            maxValue={maxValue}
            minValue={minValue}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            ref={ref}
            title={title}
            autoFocus={autoFocus}
            dir={dir}
            ariaLabel={ariaLabel}
            selectWhenFocus={selectWhenFocus}
            column={column}
            formId={formId}
            isCodeFieldEnabledApi={isCodeFieldEnabled}
        />
    )
}

export default CodeInput;