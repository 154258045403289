import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import RotateOptionIcon from "../../icons/RotateOption";
import TimerIcon from "../../icons/Time";
import { userLogout } from "../../store/user/actions";
import { destroyAllCookie } from "../../util/cookies";
import { isEnglish } from "../../util/util";
import style from './style.module.scss';

const ExpiredContaer = (props) => {
    const { countDwon } = props;
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const _handleLogout = async () => {
        try {
            window.location.reload(true);
        } catch (e) {
            console.error("🚀 ~ file: index.js ~ line 56 ~ Header ~ e", e);
        }
    }

    return (
        <div id="expired" className={` ${style.expiredContaer} ${countDwon ? style.expiredActive : ""}  `}>
            <div className={style.sessionValidity}>
                <div className={style.expiredIcons}>
                    <RotateOptionIcon />
                </div>
                <div className={style.expiredLbl}>
                    <label>
                        {t('session_expired')}
                    </label>
                </div>
                <div className={style.counterLbl}>
                    <label>
                        {t('please_login_again')}
                    </label>
                </div>
                <div className={style.expiredContaerBtn}>
                    <button className="primary-button"
                        onClick={_handleLogout}>
                        {t('continue')}
                    </button>
                </div>
            </div>
        </div>
    )
}


const CountDwon = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { setCountDwon, countDwon, closeTimeOut, setCloseTimeOut, onContinue, time } = props;
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(time)
    const [key,setKey] = useState(0)
    useImperativeHandle(ref, () => ({
        startCounter: () => setIsPlaying(true)
    }));

    return (

        <div className={style.sessionValidity}>
            <div className={style.icons}>
                <TimerIcon />
            </div>
            <div>
                <div className={style.counterLbl}>
                    <label>
                        {t('session_will_expire_in')}
                    </label>
                </div>
                <div id="counterNum" className={style.counterNum}>
                    <CountdownCircleTimer
                        isPlaying={isPlaying}
                        duration={duration}
                        key={key}
                        colors={['#004777', '#F7B801', '#A30000', '#A30000', '#F7B801']}
                        colorsTime={[time, time / 2, time / 3, 10, 0]}
                        size={80}
                        strokeWidth={4}
                        isSmoothColorTransition={true}
                        onComplete={() => {
                            setCountDwon(true)
                        }}
                    >
                        {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                </div>
            </div>
            <div className={style.sessionValidityBtn}>
                <button className="primary-button"
                    onClick={() => {
                        setCloseTimeOut(!closeTimeOut)
                        document.getElementById("session_timeout_dialog").style.display = "none"
                        setCountDwon(false)
                        setDuration(time)
                        setIsPlaying(false)
                        setKey(key+1)
                        onContinue()
                    }}>
                    {t('continue')}
                </button>
            </div>
        </div>
    )
})

const SessionTimeOut = (props, ref) => {
    const [countDwon, setCountDwon] = useState(false);
    const [closeTimeOut, setCloseTimeOut] = useState(false);
    const countDownRef = useRef()
    const { time, onContinue } = props
    const open = () => {
        console.log("🚀 ~ file: index.js:109 ~ open ~ {",)
        document.getElementById("session_timeout_dialog").style.display = "block"
        countDownRef.current.startCounter()
    }

    useImperativeHandle(ref, () => ({
        open,
        setCountDwon
    }));

    return (
        <div id="session_timeout_dialog" className={` ${style.mainCountDwon} ${isEnglish()?style.fontEn:style.fontAr} ${closeTimeOut ? style.closeCountDwon : ""}  `}>
            <div className="openBlackScreen"></div>
            <div className={style.countDwonContaer}>
                <CountDwon
                    time={time}
                    ref={countDownRef}
                    onContinue={onContinue}
                    countDwon={countDwon}
                    setCountDwon={setCountDwon}
                    closeTimeOut={closeTimeOut}
                    setCloseTimeOut={setCloseTimeOut} />
                <ExpiredContaer countDwon={countDwon} />
            </div>
        </div>
    )
}
export default forwardRef(SessionTimeOut)