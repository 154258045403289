import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { ESTIMATION_QUOTATION_APROACH_COST_FIELD, ESTIMATION_QUOTATION_AREA_LEVEL_FIELD, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD, ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD, ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD, ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD, ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD, ESTIMATION_QUOTATION_LICENCE_NUM_FIELD, ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD, ESTIMATION_QUOTATION_NET_PRICE_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD, ESTIMATION_QUOTATION_PRICE_FIELD, ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_PROPERTY_USE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_REM_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD, ESTIMATION_QUOTATION_VAT_FIELD, ESTIMATION_QUOTATION_VAT_PERC_FIELD } from '../../../util/constants';
import { formatDate, formatMoney, isEnglish, parseNumber } from '@ipgd-gauge/utils';
import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";

const Card = (props) => {
    const { data, estimationQuotation, deleteRow, index, editRow, lovs } = props
    const { t } = useTranslation();

    const evaluationApproch = (data) => {
        let label = "";
        if (data.aproachCost) {
            label += `${t("estimationQuotation:cost_approch")}  ,`
        }
        if (data.aproachMarket) {
            label += `${t("estimationQuotation:market_approch")}  ,`
        }
        if (data.aproachIncome) {
            label += `${t("estimationQuotation:income_approch")}  ,`
        }
        return label.length > 0 ? label.substring(0, label.length - 1) : "";
    }

    const evaluationMethods = (data) => {
        let label = "";
        if (data.methodReplaceCost) {
            label += `${t("estimationQuotation:replacement_cost")}  ,`
        }
        if (data.methodComparision) {
            label += `${t("estimationQuotation:comparison")}  ,`
        }
        if (data.methodIncomeCaptal) {
            label += `${t("estimationQuotation:income_capitalization")}  ,`
        }
        if (data.methodRemainValue) {
            label += `${t("estimationQuotation:remaining_method")}  ,`
        }
        if (data.methodCashFlow) {
            label += `${t("estimationQuotation:discounted_cashflows")}  ,`
        }
        if (data.methodInvestment) {
            label += `${t("estimationQuotation:profits")} ,`
        }
        return label.length > 0 ? label.substring(0, label.length - 1) : "";
    }
    return (
        <div className={style.cardPanel} id = {`estimation-quotation-card-${index}`}>
            <div className={style.card}>
                <div>
                    <label>#</label>
                    <div title={data.sequence}>
                        {data.sequence}
                    </div>
                </div>
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DESCRIPTION_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:desc")}</label>
                        <div title={data.description} className={style.cardDesc}>
                            {data?.description}
                        </div>
                    </div>
                }

                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:instrument#")}</label>
                        <div title={data.deedNumber}>
                            {data?.deedNumber}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:instrument_date")}</label>
                        <div>
                            <label title={formatDate(isEnglish() ? data.titleDeedDgr : data?.titleDeedDhi)}>{formatDate(isEnglish() ? data.titleDeedDgr : data?.titleDeedDhi)}</label>
                            {data.titleDeedDhi &&
                                <span>-</span>
                            }
                            <label title={formatDate(isEnglish() ? data.titleDeedDhi : data?.titleDeedDgr)}>{formatDate(isEnglish() ? data.titleDeedDhi : data?.titleDeedDgr)}</label>
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_NUM_FIELD) &&
                    <div>
                        <label>{t('estimationQuotation:license_number')}</label>
                        <div title={data.licenceNum}>
                            {data?.licenceNum}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD) &&
                    <div>
                        <label>{t('estimationQuotation:license_date')}</label>
                        <div>
                            <label title={formatDate(isEnglish() ? data.licenceDateDgr : data?.licenceDateDhi)}>{formatDate(isEnglish() ? data.licenceDateDgr : data?.licenceDateDhi)}</label>
                            {data.licenceDateDhi &&
                                <span>-</span>
                            }
                            <label title={formatDate(isEnglish() ? data.licenceDateDhi : data?.licenceDateDgr)}>{formatDate(isEnglish() ? data.licenceDateDhi : data?.licenceDateDgr)}</label>
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD) &&
                    <div>
                        <label>{t('estimationQuotation:property_description')}</label>
                        <div title={data.propertyDescription}>
                            {data?.propertyDescription}
                        </div>
                    </div>
                }


                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:region")}</label>
                        <div title={data.frsId}>
                            {isEnglish() ? data.regionData?.descLo : data.regionData?.descFo}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:city")}</label>
                        <div title={data.ecdId}>
                            {isEnglish() ? data.cityData?.descLo : data.cityData?.descFo}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:town")}</label>
                        <div title={data.criId}>
                            {isEnglish() ? data.townData?.descLo : data.townData?.descFo}
                        </div>
                    </div>
                }

                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:property_type")}</label>
                        <div title={data.myoId}>
                            {isEnglish() ? data.propertyTypeData?.descLo : data.propertyTypeData?.descFo}
                        </div>
                    </div>
                }

                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_AREA_LEVEL_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:area_level")}</label>
                        <div title={data.areaLevel}>
                            {isEnglish() ? data.areaLevelData?.descLo : data.areaLevelData?.descFo}

                        </div>
                    </div>
                }

                {
                    (estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) || estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD)) &&
                    <div>
                        <label>{t("estimationQuotation:area_from_to")}</label>
                        <div title={` ${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) ? data.landAreaFrom || '' : ''} ${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) && estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) ? '-' : ''}  ${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) ? data.landAreaTo || '' : ''}`}>
                            <label>
                                {`${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) ? data.landAreaFrom || '' : ''}`}
                            </label>
                            <span>{estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) && estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) ? ' - ' : ''}</span>
                            <label>
                                {`${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) ? data.landAreaTo || '' : ''}`}
                            </label>
                        </div>
                    </div>
                }


                {
                    (estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) || estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD)) &&
                    <div>
                        <label>{t("estimationQuotation:no_of_floors_from_to")}</label>
                        <div title={` ${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) ? data.numberOfFloorsFrom || '' : ''} ${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) && estimationQuotation?.quotationType?.fields?.includes("NO_OF_FLOORS_TO_FIELD ") ? '-' : ''}  ${estimationQuotation?.quotationType?.fields?.includes("NO_OF_FLOORS_TO_FIELD ") ? data.numberOfFloorsTo || '' : ''}`}>
                            <label>
                                {`${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) ? data.numberOfFloorsFrom || '' : ''}`}
                            </label>
                            <span>{estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) && estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD) ? ' - ' : ''}</span>
                            <label>
                                {`${estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD) ? parseNumber(data.numberOfFloorsTo) || '' : ''}`}
                            </label>
                        </div>
                    </div>
                }
                {estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) &&
                    <div className={style.checkboxLbl}>
                        <label className='form-label'>{t("estimationQuotation:evaluation_approch")}</label>
                        <div>
                            {evaluationApproch(data)}
                        </div>
                    </div>
                }

                {estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) &&

                    <div className={style.checkboxLbl}>
                        <label className='form-label'>{t("estimationQuotation:evaluation_methods")}</label>
                        <div>
                            {evaluationMethods(data)}
                        </div>
                    </div>
                }

                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:evaluation_purpose")}</label>
                        <div title={data?.estimationReason}>
                            {isEnglish() ? data.estimationReasonData?.descLo : data.estimationReasonData?.descFo}
                        </div>
                    </div>
                }
                {estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:value_assumption")}</label>
                        <div title={data?.valueAssumption}>
                            {isEnglish() ? data.valueAssumptionData?.descLo : data.valueAssumptionData?.descFo}

                        </div>
                    </div>
                }
                {estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_USE_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:usage_type")}</label>
                        <div title={data?.propertyUse}>
                            {isEnglish() ? data.propertyUseData?.descLo : data.propertyUseData?.descFo}

                        </div>
                    </div>
                }
                {estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:estimation_base")}</label>
                        <div title={data?.estimationBase}>
                            {isEnglish() ? data.estimationBaseData?.descLo : data.estimationBaseData?.descFo}

                        </div>
                    </div>
                }
                {estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PRICE_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:price")}</label>
                        <div title={formatMoney(data.price)}>
                            {formatMoney(data.price)}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_PERC_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:vat_perc")}</label>
                        <div title={formatMoney(data.vatPercentage)}>
                            {formatMoney(data.vatPercentage)}
                        </div>
                    </div>
                }

                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:vat")}</label>
                        <div title={formatMoney(data.vat)}>
                            {formatMoney(data.vat)}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NET_PRICE_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:total_price")}</label>
                        <div title={formatMoney(data.netPrice)}>
                            {formatMoney(data.netPrice)}
                        </div>
                    </div>
                }
                {
                    estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REM_FIELD) &&
                    <div>
                        <label>{t("estimationQuotation:remarks")}</label>
                        <div title={data.rem} className={style.cardDesc}>
                            {data.rem}
                        </div>
                    </div>
                }
            </div>
            <div className={style.cardFooter}>
                <EditBtn onClick={() => editRow(index)} />
                <DeleteBtn onClick={() => deleteRow(index)} />
            </div>
        </div >
    )
}
export default Card;