function SuccessStatusIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.562"
        height="17.557"
        viewBox="0 0 17.562 17.557"
      >
        <g data-name="Group 179874" transform="translate(-930.253 -319.828)">
          <g data-name="Group 178490">
            <path
              fill="#0bc03d"
              d="M-4689.845-9880.809l2.242-.132 1.626-1.251 1.273-.542 1.605 1.045 1.4.748 2.375.28.689.973v.934l.188 1.346.86 1.2.9 1.082v.916l-.356.748-.729.953-.861.729.188 1.214-.187 1.665-1.306.8h-1.626l-1.233.485-.6.58-1.309.729-1.273-.262-1.157-1.159-.989-.634-1.906.261-.8-.8-.224-.974-.3-2.132-1.009-.97-.578-1.233v-1.325l1.233-.9.5-1.4.148-1.85.372-.524z"
              data-name="Path 44712"
              transform="translate(5623.853 10203.134)"
            ></path>
          </g>
          <g data-name="Group 178422" transform="translate(930.253 319.828)">
            <path
              fill="#fff"
              d="M1.83 4.426a.612.612 0 01-.434-.18L-.572 2.27a.612.612 0 010-.866.612.612 0 01.866 0L1.83 2.946 5.34-.57a.612.612 0 01.866 0 .612.612 0 010 .866l-3.943 3.95a.612.612 0 01-.433.18z"
              transform="translate(5.956 6.932)"
            ></path>
            <path
              fill="#0bc03d"
              d="M8.027-.75a2.21 2.21 0 011.426.505L10.748.87a1.232 1.232 0 00.629.235h1.388a2.19 2.19 0 012.188 2.188v1.388a1.254 1.254 0 00.234.628L16.3 6.6a2.25 2.25 0 010 2.851l-1.11 1.29a1.232 1.232 0 00-.235.629v1.388a2.19 2.19 0 01-2.188 2.188h-1.39a1.254 1.254 0 00-.628.234L9.458 16.3a2.186 2.186 0 01-1.425.509 2.21 2.21 0 01-1.426-.509l-1.29-1.11a1.232 1.232 0 00-.629-.235H3.27a2.19 2.19 0 01-2.188-2.188v-1.4a1.271 1.271 0 00-.232-.627l-1.1-1.3a2.251 2.251 0 010-2.831l1.1-1.3a1.271 1.271 0 00.232-.627v-1.4A2.19 2.19 0 013.27 1.1h1.413A1.254 1.254 0 005.31.863L6.6-.242A2.185 2.185 0 018.027-.75zM8.662.69a1 1 0 00-.635-.215.975.975 0 00-.627.214L6.111 1.79a2.416 2.416 0 01-1.428.532H3.27a.964.964 0 00-.963.963v1.4A2.45 2.45 0 011.79 6.1L.684 7.4a1.037 1.037 0 000 1.251l1.1 1.3a2.45 2.45 0 01.517 1.412v1.4a.964.964 0 00.963.963h1.419a2.415 2.415 0 011.43.533L7.4 15.367a1 1 0 00.635.215.975.975 0 00.628-.214l1.288-1.109a2.414 2.414 0 011.428-.532h1.388a.964.964 0 00.963-.963v-1.389a2.415 2.415 0 01.533-1.43l1.106-1.285a1.04 1.04 0 000-1.262L14.26 6.109a2.414 2.414 0 01-.532-1.428V3.293a.964.964 0 00-.963-.963h-1.388a2.415 2.415 0 01-1.429-.53z"
              data-name="Vector"
              transform="translate(.751 .75)"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
  
  export default SuccessStatusIcon;