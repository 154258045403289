import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deletePropertyPartyCategory, getPropertyPartyCategoryList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { dialogRef, deleteAction, user } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesPartiesCategoriesRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesCategories:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data?.descLo : data?.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesCategories:name')}</label>
                <label>{isEnglish() ? data?.descLo : data?.descFo}</label>
            </div>

            <div title={isEnglish() ? data?.typeDescLo : data?.typeDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesCategories:type')}</label>
                <label>{isEnglish() ? data?.typeDescLo : data?.typeDescFo}</label>
            </div>

            <div title={data.isGeneral ? t('propertiesPartiesCategories:all_organization') : t('propertiesPartiesCategories:specific_organization')} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesCategories:organization')}</label>
                <label>{data.isGeneral ? t('all_organization') : t('specific_organization')}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('propertiesPartiesCategories:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data?.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const InspectorsAndEmployeesCategoriesTable = (props) => {
    const { searchTextRef, filterRef, dialogRef, tableRef, user } = props;

    const { t } = useTranslation();

    const [list, setList] = useState({});

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPartiesCategories:code'), width: "10%" },
        { title: t('propertiesPartiesCategories:name'), width: "20%" },
        { title: t('propertiesPartiesCategories:type'), width: "20%" },
        { title: t('propertiesPartiesCategories:organization'), width: "15%" },
        { title: t('propertiesPartiesCategories:status'), width: "15%" },
        { title: "", width: "15%" }
    ];


    const newAction = () => {
        dialogRef?.current?.open()
    }

    const _deleteAction = (id) => {
        if (isNotEmpty(id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return deletePropertyPartyCategory(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    }).catch((error) => handleError(error, "delete_fields", t));
            });
        }
    }
    return (
        <div className={style.propertiesPartiesCategoriesTableContainer}>
            <Slisting
                id="properties-parties-categories-table"
                formId={RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID}
                user={user}
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.propertiesPartiesCategoriesTable}
                pagingData={list}
                getData={(page, size) => {
                    getPropertyPartyCategoryList(page, size, searchTextRef.current.getSearchText(), filterRef.current?.getFilterData())
                        .then((response) => setList(response.data))
                        .catch((error) => handleError(error, null, t));
                }}
                rowActions={{ tableRef: tableRef, dialogRef: dialogRef, deleteAction: _deleteAction, user: user }}
                rowHeight={80}
                withFilter={true}
                showWating={(data) => {
                    return data == null
                }}
                showNoDataFound={(data) => {
                    return (data && data.length == 0)
                }}
                newAction={newAction}
                deleteAction={deletePropertyPartyCategory}
                viewAction={(id) => dialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="90px"
            />
        </div>
    );
};

export default InspectorsAndEmployeesCategoriesTable;
