import { formatDate, formatMoney, handleError, isEnglish } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PrintDialog from "../../../components/print-dialog";
import Slisting from "../../../components/slisting";
import { deleteCreditNotesCustomersEstimation, getCreditNotesCustomersEstimation } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Actions from "./actions";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, printDialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.creditNotesforCustomersEstimationRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:notes_num')}</label>
                <label>{data.code}</label>
            </div>

            <div title={formatDate(data.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:notes_date')}</label>
                <label>{formatDate(data.dateDgr)} </label>
            </div>

            <div title={isEnglish() ? data.customerNameLo : data.customerNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:customer')}</label>
                <label>{isEnglish() ? data.customerNameLo : data.customerNameFo}</label>
            </div>

            <div title={`${isEnglish() ? data.branchLo : data.branchFo} - ${isEnglish() ? data.divisionLo : data.divisionFo}`} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:branch_division')}</label>
                <label>{`${isEnglish() ? data.branchLo : data.branchFo} - ${isEnglish() ? data.divisionLo : data.divisionFo}`}</label>
            </div>

            <div title={formatMoney(data.invoiceNetAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:net_price')}</label>
                <label>{formatMoney(data.invoiceNetAlc)}</label>
            </div>

            <div title={data.invoiceCode} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:invoice_number')}</label>
                <label>{data.invoiceCode}</label>
            </div>

            <div title={isEnglish() ? data.statusLo : data.statusFo} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:status')}</label>
                <label className={`status-${data.globalStatus}`} >{isEnglish() ? data.statusLo : data.statusFo}</label>
            </div>

            <div>
                <Actions user={user} data={data} tableRef={tableRef} printDialogRef={printDialogRef} />
            </div>
        </div>
    );
};

const CreditNotesforCustomersEstimationTable = (props) => {

    const { tableRef, filtersRef, searchTextRef } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const printDialogRef = useRef();

    const user = getUser();

    const [list, setList] = useState();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('creditNotesForCustomersEstimation:notes_num'), width: '14%' },
        { title: t('creditNotesForCustomersEstimation:notes_date'), width: '10%' },
        { title: t('creditNotesForCustomersEstimation:customer'), width: '10%' },
        { title: t('creditNotesForCustomersEstimation:branch_division'), width: '14%' },
        { title: t('creditNotesForCustomersEstimation:net_price'), width: '12%' },
        { title: t('creditNotesForCustomersEstimation:invoice_number'), width: '12%' },
        { title: t('creditNotesForCustomersEstimation:status'), width: '11%' },
        { title: "", width: '12%' },
    ];

    const _fetchData = (page, size) => {
        const filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            ...filtersRef.current.getFilterData(),
        }

        getCreditNotesCustomersEstimation(page, size, filterData)
            .then((response) => setList(response.data))
            .catch((error) => handleError(error, null, t))
    }

    return (
        <>
            <Slisting
                id="credit-notes-for-customers-estimation-table"
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.creditNotesforCustomersEstimationTable}
                getData={_fetchData}
                rowHeight={80}
                rowActions={{ user, tableRef, printDialogRef }}
                pagingData={list}
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                newAction={() => navigate("/credit-notes-for-customer-estimation")}
                deleteAction={deleteCreditNotesCustomersEstimation}
                viewAction={(id) => navigate(`/credit-notes-for-customer-estimation?id=${id}`)}
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                cardInMobile
                labelCardWidth="130px"
            />

            <PrintDialog
                ref={printDialogRef}
                id="credit-notes-for-customers-estimation-print-dialog"
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                user={user}
            />
        </>
    );
};

export default CreditNotesforCustomersEstimationTable;