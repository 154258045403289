import PageTabs from '@ipgd-gauge/pages-tabs';
import { isAllowedForm } from "@ipgd-gauge/utils";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MsgsCount from "../../components/msgs-count";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID, RSM3234_PROPERTIES_MAP_FORM_ID, RSM3246_INBOX_FORM_ID, RSM3268_CUSTOMER_DUES_FOLLOW_UP, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP } from "../../util/constants/forms";
import CustomerDuesFollowUp from "../customer-dues-follow-up";
import EstimationMap from "../estimation-map";
import EstimationTransactions from "../estimation-transactions";
import Inbox from "../inbox";
import InspectorsEmployeesDuesFollowUp from "../inspectors-employees-dues-follow-up";
import style from "./style.module.scss";

const Home = () => {
    const { t } = useTranslation();
    const tabsRef = useRef();
    const user = useSelector(state => state.persistedReducer.userReducer.user);

    useEffect(() => {
        _setActiveTab();
        tabsRef.current.controlTabVisibility(0, isAllowedForm(user, RSM3230_VALUATION_TRANSACTIONS_FORM_ID));
        tabsRef.current.controlTabVisibility(1, isAllowedForm(user, RSM3234_PROPERTIES_MAP_FORM_ID));
        tabsRef.current.controlTabVisibility(2, isAllowedForm(user, RSM3246_INBOX_FORM_ID));
        tabsRef.current.controlTabVisibility(3, isAllowedForm(user, RSM3268_CUSTOMER_DUES_FOLLOW_UP));
        tabsRef.current.controlTabVisibility(4, isAllowedForm(user, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP));
    }, [user]);

    const _setActiveTab = () => {
        if (isAllowedForm(user, RSM3230_VALUATION_TRANSACTIONS_FORM_ID)) {
            return;
        } else if (isAllowedForm(user, RSM3234_PROPERTIES_MAP_FORM_ID)) {
            tabsRef.current.setAvtiveTab(1);
        } else if (isAllowedForm(user, RSM3246_INBOX_FORM_ID)) {
            tabsRef.current.setAvtiveTab(2);
        } else if (isAllowedForm(user, RSM3268_CUSTOMER_DUES_FOLLOW_UP)) {
            tabsRef.current.setAvtiveTab(3);
        } else if (isAllowedForm(user, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP)) {
            tabsRef.current.setAvtiveTab(4);
        }
    }

    return (
        <PageTabs
            id={'home-tabs'}
            ref={tabsRef}
            containerStyle={style.tabsContainer}
            headers={[
                {
                    id: 'transactions',
                    title: t('estimation_transactions'),
                    color: 'var(--secondary-2)'
                },
                {
                    id: 'estimation-map',
                    title: t('estimation_map'),
                    color: 'var(--secondary-2)'
                },
                {
                    id: 'home-inbox',
                    title: <div className={style.inboxTitle}><span>{t('inbox')}</span><div><MsgsCount showAll /></div></div>,
                    color: 'var(--secondary-2)'
                },
                {
                    id: 'customer-follow-up',
                    title: t('customerDuesFollowUp:customer_dues_follow_up'),
                    color: 'var(--secondary-2)'
                },
                {
                    id: 'inspectors-estimators-dues-follow-up',
                    title: t('customerDuesFollowUp:inspectors_employees_dues_follow_up'),
                    color: 'var(--secondary-2)'
                },
            ]}
        >
            {isAllowedForm(user, RSM3230_VALUATION_TRANSACTIONS_FORM_ID) &&
                <div><EstimationTransactions isTab /></div>
            }
            {isAllowedForm(user, RSM3234_PROPERTIES_MAP_FORM_ID) &&
                <div><EstimationMap isTab /></div>
            }
            {isAllowedForm(user, RSM3246_INBOX_FORM_ID) &&
                <div><Inbox isTab /></div>
            }
            {isAllowedForm(user, RSM3268_CUSTOMER_DUES_FOLLOW_UP) &&
                <div><CustomerDuesFollowUp isTab /></div>
            }
            {isAllowedForm(user, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP) &&
                <div><InspectorsEmployeesDuesFollowUp isTab /></div>
            }
        </PageTabs>
    )
}

export default Home;