import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, formatDate, formatMoney, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteDelegation, getDelegations } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3232_DELEGATION_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, delegationDialogRef, deleteDelegation } = rowActions;
    const { t } = useTranslation("delegation");

    return (
        <div className={`${style.delegationRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={isEnglish() ? data.toAprNameLo : data.toAprNameFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('delegation:to')}</label>
                <label>{isEnglish() ? data.toAprNameLo : data.toAprNameFo}</label>
            </div>

            <div title={formatDate(data.fromDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('delegation:date_form')}</label>
                <label>{formatDate(data.fromDateDgr)}</label>
            </div>

            <div title={formatDate(data.toDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('delegation:date_to')}</label>
                <label>{formatDate(data.toDateDgr)}</label>
            </div>

            <div title={formatDate(data.entryDateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('delegation:entry_date')}</label>
                <label>{formatDate(data.entryDateDgr)}</label>
            </div>

            <div title={formatMoney(data.maximumAmountAlc)} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('delegation:max_amount')}</label>
                <label>{formatMoney(data.maximumAmountAlc)}</label>
            </div>

            <div title={data.rem} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('delegation:remarks')}</label>
                <label>{data.rem}</label>
            </div>

            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('delegation:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3232_DELEGATION_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => delegationDialogRef?.current?.open(data.id)} />
                    }
                    {isAllowedAction(user, RSM3232_DELEGATION_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteDelegation(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const DelegationTable = (props) => {
    const { user, delegationTableRef, searchTextRef, delegationDialogRef } = props;
    const { t } = useTranslation();

    const [delegations, setDelegations] = useState();

    const columns = [
        { title: '#', width: "3%" },
        { title: t('delegation:to'), width: "12%" },
        { title: t('delegation:date_form'), width: "12%" },
        { title: t('delegation:date_to'), width: "12%" },
        { title: t('delegation:entry_date'), width: "12%" },
        { title: t('delegation:max_amount'), width: "12%" },
        { title: t('delegation:remarks'), width: "10%" },
        { title: t('delegation:status'), width: "12%" },
        { title: "", width: "15%" },
    ];

    const _fetchData = (page, size) => {
        getDelegations(page, size, searchTextRef?.current?.getSearchText())
            .then((response) => setDelegations(response.data))
            .catch((error) => handleError(null, null, t))
    }

    const _deleteDelegation = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteDelegation(id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    delegationTableRef?.current?.refresh();
                })
                .catch((error) => {
                    handleError(error, "delete_fields", t);
                })
        });
    }

    return (
        <Slisting
            id="delegation-table"
            ref={delegationTableRef}
            formId={RSM3232_DELEGATION_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.delegationTable}
            rowHeight={80}
            pagingData={delegations}
            getData={_fetchData}
            rowActions={{ user, delegationDialogRef, deleteDelegation: _deleteDelegation, }}
            cardInMobile
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            newAction={() => delegationDialogRef.current?.open()}
            deleteAction={deleteDelegation}
            viewAction={(id) => delegationDialogRef.current?.open(id)}
            labelCardWidth="80px"
        />
    );
};

export default DelegationTable;