import { DateFromToInput } from "@ipgd-gauge/date-picker";
import FrmButtonsOptions from "@ipgd-gauge/frm-button-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getCommonCustomers, getDomainValues, getRealEstateUserLOV, getRegionsLov, getTaskDescriptionLOV } from "../../../util/apis";
import { DOMAIN_EXECUTED_NOT_EXECUTED, FORM_BTNS_OPTIONS_WITH_CHECH, POLICY_LVL_SELF_SERVICE, USERS_TYPES_CUSTOMER } from "../../../util/constants";
import { RSM3252_TASKS_LIST_FORM_ID } from "../../../util/constants/forms";

const TasksListFilter = (props, ref) => {
    const { user, tasksListTableRef } = props;

    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const options = [
        { id: '0', descLo: t('all'), descFo: t('all') },
        { id: '1', descLo: t('yes'), descFo: t('yes') },
        { id: '2', descLo: t('no'), descFo: t('no') },
    ];

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="tasksListFilter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tasksListTableRef?.current?.refresh()}
            formId={RSM3252_TASKS_LIST_FORM_ID}
            getDefaultFilter={() => ({ overdueTasks: "0" })}
            numOfRows={5}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('tasksList:status')}</label>
                            <FrmSelectMenu
                                id="countries-filter"
                                value={filterData.status}
                                onChange={value => _handleDataChanged("status", value.value)}
                                api={() => getDomainValues(DOMAIN_EXECUTED_NOT_EXECUTED)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isClearable={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:users')}</label>
                            <FrmSelectMenu
                                id="users"
                                value={filterData.users}
                                onChange={value => _handleDataChanged("users", value)}
                                isAsync={true}
                                defualtValueId={filterData.users}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.users, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:executor')}</label>
                            <FrmSelectMenu
                                id="executor"
                                value={filterData.executors}
                                onChange={value => _handleDataChanged("executors", value)}
                                isAsync={true}
                                defualtValueId={filterData.executors}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.executors, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:task_description')}</label>
                            <FrmSelectMenu
                                id="task_description"
                                value={filterData.taskDescs}
                                onChange={value => _handleDataChanged("taskDescs", value)}
                                api={() => getTaskDescriptionLOV(filterData.taskDescs)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:customer')}</label>
                            <FrmSelectMenu
                                id="customer"
                                value={filterData.customers}
                                onChange={value => _handleDataChanged("customers", value)}
                                defualtValueId={filterData.customers}
                                defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, [], true)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isMulti={true}
                                isAsync={true}
                                isDisabled={user.type == USERS_TYPES_CUSTOMER && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:region')}</label>
                            <FrmSelectMenu
                                id="region"
                                isMulti={true}
                                value={filterData.regions}
                                onChange={value => _handleDataChanged("regions", value)}
                                api={() => getRegionsLov(filterData.regions)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:task_date_from_to')}</label>
                            <DateFromToInput
                                id="task-date-from-to"
                                fromValue={filterData?.taskFromDate}
                                toValue={filterData?.taskToDate}
                                fromOnChange={(value) => _handleDataChanged("taskFromDate", value)}
                                toOnChange={(value) => _handleDataChanged("taskToDate", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:execution_date_from_to')}</label>
                            <DateFromToInput
                                id="execution-date-from-to"
                                fromValue={filterData?.exeFromDate}
                                toValue={filterData?.exeToDate}
                                fromOnChange={(value) => _handleDataChanged("exeFromDate", value)}
                                toOnChange={(value) => _handleDataChanged("exeToDate", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('tasksList:overdue_tasks')}</label>
                            <FrmButtonsOptions
                                id="overdue-tasks"
                                type={FORM_BTNS_OPTIONS_WITH_CHECH}
                                className="options-btn-33"
                                handleSelect={value => _handleDataChanged("overdueTasks", value)}
                                value={filterData.overdueTasks}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(TasksListFilter);