import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../util/hooks/useWindowSize';
import { getDashboardColor, isEnglish, parseNumber } from '../../util/util';

const BarWithLineChart = (props) => {
    const { idName, labelNameLo, selectedId, labelNameFo, valueName, list, onSelect, onLegendClick, includedIds, selectedColor, fullScreen } = props;

    const [windowSize] = useWindowSize();

    const chartRef = useRef();

    const [chartList, setChartList] = useState([])

    const { t } = useTranslation()

    useEffect(() => {
        // if (fullScreen) {
        //     setChartList(chartList)
        // }

        if (selectedId && selectedId.current && typeof selectedId.current == "object" && selectedId.current?.length > 0) {
            const otherList = chartList
            let otherTotal = 0
            for (let i = 0; i < otherList.length; i++) {
                otherTotal += otherList[i].count
            }
            const othersObj = {
                count: otherTotal,
                descLo: `${t("other")} )${otherTotal})`,
                descFo: `${t("other")} )${otherTotal}(`,
                id: -1
            }
            setChartList([othersObj])

        }

        else if (chartList.length > 10) {
            const otherList = chartList.slice(9)
            let otherTotal = 0
            for (let i = 0; i < otherList.length; i++) {
                otherTotal += otherList[i].count
            }
            const othersObj = {
                count: otherTotal,
                descLo: `${t("other")} )${otherTotal})`,
                descFo: `${t("other")} )${otherTotal}(`,
                id: -1
            }
            setChartList([...list.slice(0, 9), othersObj])
        }

        else {
            setChartList(list)
        }

        const chart = chartRef.current.chartInstance;
        if (chart.ctx !== null) {
            chart.update();
        }
    }, [selectedColor?.datasetColor, list, fullScreen])

    const labelColor = '#6e6b7b';
    const tooltipShadow = 'rgba(0, 0, 0, 0.25)';
    const gridLineColor = 'rgba(200, 200, 200, 0.2)';

    const options = {
        elements: {
            rectangle: {
                borderWidth: 2,
                borderSkipped: 'bottom',
                position: windowSize.width <= 1200 ? 'bottom' : isEnglish() ? 'left' : 'right',
                rtl: !isEnglish(),
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        rtl: !isEnglish(),
        plugins: {
            legend: {
                display: true,
                position: windowSize.width <= 1200 ? 'bottom' : isEnglish() ? 'left' : 'right',
                rtl: !isEnglish(),
            }
        },

        legend: {
            display: false,
            position: 'bottom',
            rtl: !isEnglish(),
            labels: {
                bodyFontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi',
                boxWidth: 15,
                boxHeight: 3,
                usePointStyle: true,
            },
            onClick(evt, legendItem) {
                var index = legendItem.index;
                var chart = chartRef.current.chartInstance;
                includedIds.current = [];
                let meta = null;
                for (let i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
                    meta = chart.getDatasetMeta(i);
                    if (meta.data[index]) {
                        meta.data[index].hidden = !meta.data[index].hidden;
                    }
                }

                const ids = chartList?.map(element => element[idName]) || [];
                for (let i = 0; i < ids.length; i++) {
                    if (!meta.data[i].hidden) {
                        if (ids[i] == -1) {
                            const othersIds = list.slice(9).map(element => element[idName])
                            includedIds.current = includedIds.current.concat(othersIds);
                        }
                        includedIds.current.push(ids[i]);
                    }
                }
                if (chartList.length == includedIds.current?.length) {
                    includedIds.current = null
                }
                chart.update();
                if (onLegendClick) {
                    onLegendClick(chart, evt, legendItem)
                }
            },
        },
        tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'red',
            backgroundColor: '#fff',
            titleFontColor: '#000',
            bodyFontColor: '#000',
            bodyFontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi',
            titleFontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi',
            callbacks: {
                label: (tooltipItem, data) => {
                    return `${data.labels[tooltipItem.index]}`;
                },
                title: function () { }
            }
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    gridLines: {
                        display: true,
                        color: gridLineColor,
                        zeroLineColor: gridLineColor
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        fontColor: labelColor,
                        reverse: false/*intlContext.locale === 'ar'*/,
                        fontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi'
                    }
                }
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    gridLines: {
                        position: 'left',
                        color: gridLineColor,
                        zeroLineColor: gridLineColor
                    },
                    ticks: {
                        // stepSize: 100,
                        // min: 0,
                        // max: 40000,
                        beginAtZero: true,
                        fontColor: labelColor,
                        fontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi',
                        fontWeight: 500
                        // callback(value, index, values) {
                        //     return numFormatter(value)
                        // }
                    }
                }
            ]
        }
    }

    const data = {
        labels: chartList?.map(element => isEnglish() ? element[labelNameLo] : element[labelNameFo]) || [],
        datasets: [{
            // label: 'My First Dataset',
            data: chartList?.map(element => element[valueName]) || [],
            backgroundColor: getDashboardColor(Number(selectedColor?.datasetColor)),
            borderColor: getDashboardColor(Number(selectedColor?.datasetColor)),
            borderWidth: 1
        }]

    }

    const _onElementsClick = (event) => {
        if (event && event.length > 0) {
            let index = parseNumber(event[0]["_index"]);
            if (event[0] && index >= 0 && onSelect) {
                if (chartList[index][idName] == -1) {
                    onSelect(list.slice(9).map(element => element[idName]))
                }
                else {
                    onSelect(chartList[index][idName]);
                }
            }
        }
    }

    return (
        <div>
            <Bar
                ref={chartRef}
                data={data}
                options={options}
                height={220}
                getElementAtEvent={(event) => _onElementsClick(event)}
            />
        </div>
    )
}
export default BarWithLineChart;