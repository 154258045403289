import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import { getCommonBranches, getCommonDivisionsList } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { ACTION_SET_HEADER_VALUE } from "./reducer";

const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnBranchCondition';
const ID = "credit-notes-for-customer-estimation";

const BranchDivision = (props) => {
    const { user, creditNote, dispatchCreditNote } = props;
    const { t } = useTranslation();

    const _handleChange = (name, value) => {
        dispatchCreditNote({ type: ACTION_SET_HEADER_VALUE, name, value })
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('creditNotesForCustomersEstimation:branch_division')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w50">
                        <label id={`${ID}-branch-lbl`} className='form-label mandatory'>{t('creditNotesForCustomersEstimation:branch')}</label>
                        <FrmSelectMenu
                            id={`${ID}-branch`}
                            key={`${ID}-branch-${creditNote?.id}`}
                            value={creditNote?.header?.mbnId}
                            api={() => getCommonBranches(null, isEmpty(creditNote?.header?.mbnId) ? [] : [creditNote?.header?.mbnId], STATUS_ACTIVE, BRANCH_CONDITION, false, user.estimationInvoiceLsoId)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleChange("mbnId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${ID}-division-lbl`} className='form-label mandatory'>{t('creditNotesForCustomersEstimation:division')}</label>
                        <FrmSelectMenu
                            id={`${ID}-division`}
                            key={creditNote?.header?.mbnId}
                            value={creditNote?.header?.dsvId}
                            api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false, null, null, creditNote?.header?.mbnId, STATUS_ACTIVE, isEmpty(creditNote?.header?.dsvId) ? [] : [creditNote?.header?.dsvId])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => _handleChange("dsvId", e.value)}
                            isDisabled={!creditNote?.header?.mbnId}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchDivision;