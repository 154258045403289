import React from "react";

function CitiesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#088587"
        data-name="Group 177927"
        transform="translate(-255.25 -308.25)"
      >
        <path
          d="M4.7 16.75H2.15a2.64 2.64 0 01-2.9-2.9V2.15a2.64 2.64 0 012.9-2.9h4.3a2.64 2.64 0 012.9 2.9V4a.75.75 0 11-1.5 0V2.15c0-1.021-.379-1.4-1.4-1.4h-4.3c-1.021 0-1.4.379-1.4 1.4v11.7c0 1.021.379 1.4 1.4 1.4H4.7a.75.75 0 010 1.5z"
          opacity="0.4"
          transform="translate(256 309)"
        ></path>
        <path
          d="M2.42-.75h5.84a3.169 3.169 0 012.34.828 3.194 3.194 0 01.82 2.342v11.16a3.194 3.194 0 01-.819 2.342 3.169 3.169 0 01-2.341.828H2.42a3.184 3.184 0 01-2.344-.826A3.184 3.184 0 01-.75 13.58V2.42A3.184 3.184 0 01.076.076 3.184 3.184 0 012.42-.75zm5.84 16c1.195 0 1.66-.468 1.66-1.67V2.42c0-1.2-.465-1.67-1.66-1.67H2.42C1.218.75.75 1.218.75 2.42v11.16c0 1.2.468 1.67 1.67 1.67z"
          data-name="Vector"
          transform="translate(260.7 313)"
        ></path>
        <path
          d="M6.45 16.75H3.97a.75.75 0 010-1.5h2.48c1.021 0 1.4-.379 1.4-1.4V2.15c0-1.021-.379-1.4-1.4-1.4h-4.3c-1.021 0-1.4.379-1.4 1.4V4a.75.75 0 01-.75.75A.75.75 0 01-.75 4V2.15a2.64 2.64 0 012.9-2.9h4.3a2.64 2.64 0 012.9 2.9v11.7a2.64 2.64 0 01-2.9 2.9z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(267.4 309)"
        ></path>
        <path
          d="M4 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(264 318)"
        ></path>
        <path
          d="M4 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(264 321)"
        ></path>
        <path
          d="M0 3.75A.75.75 0 01-.75 3V0A.75.75 0 010-.75.75.75 0 01.75 0v3a.75.75 0 01-.75.75z"
          data-name="Vector"
          transform="translate(266 326)"
        ></path>
      </g>
    </svg>
  );
}

export default CitiesIcon;