import UploadImg from "../../../../components/upload-img";

import { IMAGE_TYPE_VALUATION_CONTRACT } from "../../../../util/constants";

const Tab2 = (props) => {
    const { tab, data, attachmentsImgRef, handleDataChanged } = props;
    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className="paddingFrm tabsBg">
                <UploadImg
                    ref={attachmentsImgRef}
                    isMulti={true}
                    masterId={data.id}
                    formId={IMAGE_TYPE_VALUATION_CONTRACT}
                    onFilesChangeLocally={(files) => handleDataChanged("numberOfAttachments", files?.length || 0)}
                    onRetrieve={(files) => handleDataChanged("numberOfAttachments", files?.length || 0)}
                />
            </div>
        </div>
    )
}

export default Tab2;