

import React from "react";

function UpdateArrowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64.239"
      height="71.193"
      viewBox="0 0 64.239 71.193"
    >
      <g
        fill="#81c548"
        data-name="Group 178512"
        transform="translate(-77.951 -64.448)"
      >
        <path
          d="M162.672 132.312l-13.667-13.667a1.694 1.694 0 00-2.391 0l-13.464 13.464a1.689 1.689 0 002.389 2.389l10.582-10.581v27.017a1.689 1.689 0 003.379 0v-27.018l10.784 10.784a1.689 1.689 0 002.389-2.389"
          data-name="Path 6263"
          transform="translate(-37.841 -33.703)"
        ></path>
        <path
          d="M162.37 161.362h-42.378a10.943 10.943 0 01-10.931-10.931V101.1a10.943 10.943 0 0110.931-10.931h42.378A10.943 10.943 0 01173.3 101.1v49.332a10.943 10.943 0 01-10.931 10.931M119.992 92.7a8.406 8.406 0 00-8.4 8.4v49.332a8.406 8.406 0 008.4 8.4h42.378a8.406 8.406 0 008.4-8.4V101.1a8.406 8.406 0 00-8.4-8.4z"
          data-name="Path 6264"
          transform="translate(-31.11 -25.721)"
        ></path>
      </g>
    </svg>
  );
}

export default UpdateArrowIcon;