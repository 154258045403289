import UtilModalWithTabs from "@ipgd-gauge/modal-with-tabs";
import { forwardRef, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { saveCurrentRecordId, saveSelectedRecords } from "../../store/formSelectedRecords/actions";
import { SAVE_ACTION_SHORT_CUT } from "../../util/constants/hotkeys";
import { useHotkeys } from 'react-hotkeys-hook';
import LogDateDialog from "../../views/common/dialogs/log-date";

const ModalWithTabs = (props, ref) => {
    const { id, formId, title, Icon, labels, className, okBtnAction, cancelBtnAction, preOpen, postOpen, preClose, postClose, children, hideOkBtn, customComponents, customStyle, nextPreviousAction, largeSize , showLog , masterId , user} = props;

    const selectedIds = useSelector(state => state.persistedReducer.formSelectedRecords[formId]?.selectedIds || []);

    let dispatch = useDispatch();
    useHotkeys(okBtnAction ? SAVE_ACTION_SHORT_CUT : "", okBtnAction ? okBtnAction : "");

    const logDialogRef = useRef();

    return (
        <>
            <UtilModalWithTabs
                id={id}
                ref={ref}
                title={title}
                Icon={Icon}
                labels={labels}
                className={className}
                okBtnAction={okBtnAction}
                cancelBtnAction={cancelBtnAction}
                preOpen={preOpen}
                postOpen={postOpen}
                preClose={preClose}
                postClose={postClose}
                children={children}
                hideOkBtn={hideOkBtn}
                customComponents={customComponents}
                customStyle={customStyle}
                nextPreviousAction={nextPreviousAction}
                selectedIds={selectedIds}
                largeSize={largeSize}
                formId={formId}
                clearedSelectedRecords={() => dispatch(saveSelectedRecords(formId, []))}
                changeCurrentRecordId={(newId) => dispatch(saveCurrentRecordId(formId, newId))}
                saveHotKeyShortCut={SAVE_ACTION_SHORT_CUT}
                showLog={showLog}
                masterId={masterId}
                showLogAction={() => logDialogRef?.current?.open(masterId)}
            />
            {showLog &&
                <LogDateDialog ref={logDialogRef} user={user} formId={formId} />
            }
        </>
    );
}

export default forwardRef(ModalWithTabs);