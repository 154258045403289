import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValues } from "../../../util/apis";
import { USERS_CURRENT_STATUS_DOMAIN, USER_STATUS_DOMAIN } from "../../../util/constants";
import { FND0042_USERS_DEFINITION_FORM_ID } from "../../../util/constants/forms";

const dialogId = "users-definition-dialog";

const Tab1 = (props) => {
    const { tab, data, handleDataChanged } = props;

    const { t } = useTranslation('usersDefinition');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`container`}>
                <div className='row rowSmModel'>
                    <div className="w33">
                        <CodeInput
                            id={`${dialogId}-code`}
                            value={data?.code}
                            onBlur={(value) => handleDataChanged("code", value)}
                            column="FUS_CODE"
                            formId={FND0042_USERS_DEFINITION_FORM_ID}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('current_status')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-current-status`}
                            value={data.currStatus}
                            api={() => getDomainValues(USERS_CURRENT_STATUS_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isDisabled={true}
                            onChange={value => handleDataChanged("currStatus", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('status')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-status`}
                            value={data?.isActive}
                            api={() => getDomainValues(USER_STATUS_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isDisabled={true}
                            onChange={value => handleDataChanged("isActive", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data?.nameLo}
                            onBlur={(value) => handleDataChanged("nameLo", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data?.nameFo}
                            onBlur={(value) => handleDataChanged("nameFo", value)}
                        />
                    </div>
                    <div className="w33"></div>
                    <div className="w33">
                        <label id={`${dialogId}-user-name-lbl`} className='form-label mandatory'>{t('user_name')}</label>
                        <FrmInput
                            id={`${dialogId}-user-name`}
                            value={data?.username}
                            onBlur={(value) => handleDataChanged("username", value)}
                            disabled={isNotEmpty(data?.id)}
                        />
                    </div>
                    {isEmpty(data?.id) &&
                        <>
                            <div className="w33">
                                <label id={`${dialogId}-password-lbl`} className='form-label mandatory'>{t('password')}</label>
                                <FrmInput
                                    id={`${dialogId}-password`}
                                    type="password"
                                    value={data?.pass}
                                    onBlur={(value) => handleDataChanged("pass", value)}
                                />
                            </div>
                            <div className="w33">
                                <label id={`${dialogId}-confirm-password-lbl`} className='form-label mandatory'>{t('confirm_password')}</label>
                                <FrmInput
                                    id={`${dialogId}-confirm-password`}
                                    value={data?.confirmPass}
                                    type="password"
                                    onBlur={(value) => handleDataChanged("confirmPass", value)}
                                />
                            </div>
                        </>
                    }
                    <div className="w33">
                        <label id={`${dialogId}-email-lbl`} className='form-label mandatory'>{t('email')}</label>
                        <FrmInput
                            id={`${dialogId}-email`}
                            value={data?.email}
                            onBlur={(value) => handleDataChanged("email", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id={`${dialogId}-mobile-number-lbl`} className='form-label mandatory'>{t('mobile_num')}</label>
                        <FrmInput
                            id={`${dialogId}-mobile-number`}
                            value={data?.mobile}
                            onBlur={(value) => handleDataChanged("mobile", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id={`${dialogId}-phone-lbl`} className='form-label'>{t('phone')}</label>
                        <FrmInput
                            id={`${dialogId}-phone`}
                            value={data?.landPhone}
                            onBlur={(value) => handleDataChanged("landPhone", value)}
                        />
                    </div>

                    <div className="w33">
                        <label className='form-label'>{t('whats_app_num')}</label>
                        <FrmInput
                            id={`${dialogId}-whats-app-number`}
                            value={data?.whatsapp}
                            onBlur={(value) => handleDataChanged("whatsapp", value)}
                        />
                    </div>

                    <div className="w33">
                        <label className='form-label'>{t('ext')}</label>
                        <FrmInput
                            id={`${dialogId}-land-phone-ext`}
                            value={data?.landPhoneExt}
                            onBlur={(value) => handleDataChanged("landPhoneExt", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;