import React from "react";

function CustomerDashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.14"
      height="21.31"
      viewBox="0 0 15.14 21.31"
    >
      <g
        fill="#292d32"
        data-name="Group 179955"
        transform="translate(-112.595 -253.25)"
      >
        <path
          d="M4.6 9.62h-.075a1.105 1.105 0 00-.187 0 .751.751 0 01-.093 0A5.17 5.17 0 01-.75 4.44a5.19 5.19 0 115.375 5.18zM4.44.75a3.687 3.687 0 00-.185 7.369 2.613 2.613 0 01.362 0A3.686 3.686 0 004.44.75z"
          opacity="0.4"
          transform="translate(115.56 254)"
        ></path>
        <path
          d="M6.828-.75a9.832 9.832 0 015.414 1.5A4.312 4.312 0 0114.39 4.3a4.327 4.327 0 01-2.148 3.567A9.833 9.833 0 016.82 9.372 9.833 9.833 0 011.4 7.866a4.311 4.311 0 01-2.15-3.55A4.327 4.327 0 011.4.749 9.909 9.909 0 016.828-.75zM6.82 7.872a8.326 8.326 0 004.588-1.253A2.889 2.889 0 0012.89 4.3 2.874 2.874 0 0011.41 2 8.327 8.327 0 006.828.75 8.4 8.4 0 002.229 2 2.886 2.886 0 00.75 4.314a2.874 2.874 0 001.48 2.3 8.327 8.327 0 004.59 1.258z"
          data-name="Vector"
          transform="translate(113.345 265.188)"
        ></path>
      </g>
    </svg>
  );
}

export default CustomerDashIcon;