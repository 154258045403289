import React from "react";

function CreateAgreementIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g
                fill="#292d32"
                data-name="Group 180032"
                transform="translate(-902.257 -136.25)"
            >
                <path
                    d="M6-.75h8c4.416 0 6.75 2.334 6.75 6.75v8c0 4.416-2.334 6.75-6.75 6.75H1A1.752 1.752 0 01-.75 19V6C-.75 1.584 1.584-.75 6-.75zm8 20c3.582 0 5.25-1.668 5.25-5.25V6c0-3.582-1.668-5.25-5.25-5.25H6C2.418.75.75 2.418.75 6v13a.253.253 0 00.25.25z"
                    opacity="0.46"
                    transform="translate(903.007 137)"
                ></path>
                <g data-name="Group 52" transform="translate(729.007 -245)">
                    <path
                        d="M0 0h7"
                        data-name="Vector"
                        transform="translate(180.5 392)"
                    ></path>
                    <path
                        d="M7 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h7a.75.75 0 01.75.75.75.75 0 01-.75.75z"
                        data-name="Vector - Outline"
                        transform="translate(180.5 392)"
                    ></path>
                    <path
                        d="M0 7V0"
                        data-name="Vector"
                        transform="translate(184 388.5)"
                    ></path>
                    <path
                        d="M0 7.75A.75.75 0 01-.75 7V0A.75.75 0 010-.75.75.75 0 01.75 0v7a.75.75 0 01-.75.75z"
                        data-name="Vector - Outline"
                        transform="translate(184 388.5)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default CreateAgreementIcon;