import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import { getDomainValuesWithCondition, getPartiesDefinitionCategories, getPartiesList1942 } from "../../../util/apis";
import { DOMAIN_PARTY_TYPE, PROPORTIES_PARTIES_TYPE, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR, PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_INSPECTOR, SYSTEM_DIMENSIONS_CUSTOMER_DOMAIN, SYSTEM_DIMENSIONS_PROP_PARTY_DOMAIN } from "../../../util/constants";

const commonId = "custom-reports-";

const Tab1 = (props) => {
    const { tab, data, setData } = props;
    const { t } = useTranslation('customReports');

    const _handleDataChanged = (name, value) => {
        if (name == "per1FromDateDgr" && data.per1ToDateDgr && data.per1ToDateDgr < value) {
            data.per1ToDateDgr = null;
        }

        if (name == "per2FromDateDgr" && data.per2ToDateDgr && data.per2ToDateDgr < value) {
            data.per2ToDateDgr = null;
        }

        if (name == "per1ToDateDgr" && data.per2FromDateDgr && data.per2FromDateDgr < value) {
            data.per2FromDateDgr = null;
            data.per2ToDateDgr = null;
        }
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${commonId}description-lbl`} className='form-label mandatory'>{t('description')}</label>
                        <FrmInput
                            id={`${commonId}description`}
                            value={data?.desc}
                            onBlur={(value) => _handleDataChanged("desc", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('from_date_period_1')}</label>
                        <FrmTimeDatePickr
                            id={`${commonId}from_date_period_1`}
                            value={data?.per1FromDateDgr}
                            onChange={(value) => _handleDataChanged("per1FromDateDgr", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('to_date_period_1')}</label>
                        <FrmTimeDatePickr
                            id={`${commonId}to_date_period_1`}
                            value={data?.per1ToDateDgr}
                            minDate={data?.per1FromDateDgr}
                            onChange={(value) => _handleDataChanged("per1ToDateDgr", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('from_date_period_2')}</label>
                        <FrmTimeDatePickr
                            id={`${commonId}from_date_period_2`}
                            value={data?.per2FromDateDgr}
                            onChange={(value) => _handleDataChanged("per2FromDateDgr", value)}
                            minDate={data?.per1ToDateDgr}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('to_date_period_2')}</label>
                        <FrmTimeDatePickr
                            id={`${commonId}to_date_period_2`}
                            value={data?.per2ToDateDgr}
                            minDate={data?.per2FromDateDgr}
                            onChange={(value) => _handleDataChanged("per2ToDateDgr", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('party_type')}</label>
                        <FrmSelectMenu
                            id={`${commonId}party_type`}
                            api={() => getDomainValuesWithCondition(DOMAIN_PARTY_TYPE, true, [SYSTEM_DIMENSIONS_CUSTOMER_DOMAIN, SYSTEM_DIMENSIONS_PROP_PARTY_DOMAIN])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data?.partyType}
                            onChange={(value) => _handleDataChanged("partyType", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('prop_party_type')}</label>
                        <FrmSelectMenu
                            id={`${commonId}prop_party_type`}
                            api={() => getDomainValuesWithCondition(PROPORTIES_PARTIES_TYPE, true, [PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_INSPECTOR, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data?.propPartyType}
                            onChange={(value) => _handleDataChanged("propPartyType", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('inspectors_and_employees_categories')}</label>
                        <FrmSelectMenu
                            id={`${commonId}inspectors-and-employees-categories`}
                            api={() => getPartiesDefinitionCategories()}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data?.araId}
                            onChange={(value) => _handleDataChanged("araId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('party')}</label>
                        <FrmSelectMenu
                            id={`${commonId}party`}
                            key={`${commonId}party-${data?.partyType}`}
                            api={() => getPartiesList1942(data.partyType)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data?.gprId}
                            onChange={(value) => _handleDataChanged("gprId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${commonId}remarks`}
                            value={data?.rem}
                            onBlur={(value) => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;