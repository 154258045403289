import { RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID } from "../../../util/constants/forms";
import OrganizationList from "../../common/dialogs/organization-list";

const Tab2 = (props) => {
    const { tab, data, setData } = props;

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <OrganizationList
                data={data}
                setData={setData}
                formId={RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID}
            />
        </div>
    );
}

export default Tab2;