import { confirmationDialog, deleteItemByIndex, parseNumber } from '@ipgd-gauge/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/table';
import AddHeaderBtnIcon from '../../../../icons/AddHeaderBtn';
import CardIcon from '../../../../icons/Card';
import DeleteTowIcon from '../../../../icons/Delete2';
import ListIcon from '../../../../icons/List';
import { getCommonPropertyTypes, getDomainValues, getEstimationDomains, getRegionsLov } from '../../../../util/apis';
import { AREA_LEVEL_DOMAIN, ESTIMATION_QUOTATION_APROACH_COST_FIELD, ESTIMATION_QUOTATION_AREA_LEVEL_FIELD, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD, ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD, ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD, ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD, ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD, ESTIMATION_QUOTATION_LICENCE_NUM_FIELD, ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD, ESTIMATION_QUOTATION_NET_PRICE_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD, ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD, ESTIMATION_QUOTATION_PRICE_FIELD, ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_PROPERTY_USE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_REM_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD, ESTIMATION_QUOTATION_VAT_FIELD, ESTIMATION_QUOTATION_VAT_PERC_FIELD, STATUS_ACTIVE } from '../../../../util/constants';
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from '../../../../util/constants/forms';
import { getDynamicColumnsTableWidth, getDynamicTableColumns } from '../../../../util/util';
import DetailsSidePanel from '../../../common/dialogs/estimation-quotation-contract-detail-dilaog/DetailsSidePanel';
import style from "../style.module.scss";
import Card from './Card';
import Row from './Row';

const ID = "valation-contract";

const Details = (props) => {
    const { valuationContract, handleDataChanged, user, setValuationContract } = props;
    const { t } = useTranslation();
    const dialogRef = useRef();
    const [lovs, setLovs] = useState({
        areaLevels: [],
        regions: [],
        estimationReason: [],
        valueAssumption: [],
        propertyUse: [],
        estimationBase: []
    });

    const [cardMode, setCardMode] = useState(false);

    useEffect(() => {
        Promise.all([getRegionsLov(null, STATUS_ACTIVE, true),
        getDomainValues(AREA_LEVEL_DOMAIN),
        getEstimationDomains("1019"),
        getEstimationDomains("1071"),
        getEstimationDomains("1018"),
        getEstimationDomains("1026"),
        getCommonPropertyTypes(null, null, true, false, false, false, true, [])])
            .then((response) => {
                lovs.regions = response[0].data;
                lovs.areaLevels = response[1].data;
                lovs.estimationReason = response[2].data;
                lovs.valueAssumption = response[3].data;
                lovs.propertyUse = response[4].data;
                lovs.estimationBase = response[5].data;
                lovs.propertyTypes = response[6].data;
                for (let index = 0; index < valuationContract.lines.length; index++) {
                    const line = valuationContract.lines[index];
                    const region = lovs.regions.find(region => region.id == line.frsId);
                    const areaLevel = lovs.areaLevels.find(region => region.id == line.areaLevel);
                    const estimationReason = lovs.estimationReason.find(region => region.id == line.estimationReason);
                    const valueAssumption = lovs.valueAssumption.find(region => region.id == line.valueAssumption);
                    const propertyUse = lovs.propertyUse.find(region => region.id == line.propertyUse);
                    const estimationBase = lovs.estimationBase.find(region => region.id == line.estimationBase);
                    const propertyType = lovs.propertyTypes.find(region => region.id == line.myoId);
                    line.regionData = region;
                    line.areaLevelData = areaLevel;
                    line.estimationReasonData = estimationReason;
                    line.valueAssumptionData = valueAssumption;
                    line.propertyUseData = propertyUse;
                    line.estimationBaseData = estimationBase;
                    line.propertyTypeData = propertyType;
                    line.propertyUseData = propertyUse;
                }
                setValuationContract({ ...valuationContract })
                setLovs({ ...lovs });
            })
            .catch((err) => {
                console.log("🚀 ~ useEffect ~ err:", err)

            });
    }, [])


    const columns = [
        { title: '#', width: '1%' },
        { title: t("valuationContracts:desc"), width: '4%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DESCRIPTION_FIELD) },
        { title: <span data-property="deedNum" className="mandatory">{t("valuationContracts:instrument#")}</span>, width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD) },

        { title: <span data-property="titleDeedDgr" className="mandatory">{t("valuationContracts:instrument_date")}</span>, width: '5%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD) },
        { title: t("valuationContracts:license_number"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_NUM_FIELD) },
        { title: t("valuationContracts:license_date"), width: '5%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD) },
        { title: t("valuationContracts:property_description"), width: '4%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD) },

        { title: <span data-property="frsId" className="mandatory">{t("valuationContracts:region")}</span>, width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD) },
        { title: <span data-property="ecdId" className="mandatory">{t("valuationContracts:city")}</span>, width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD) },
        { title: <span data-property="criId" className="mandatory">{t("valuationContracts:town")}</span>, width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD) },
        { title: <span data-property="myoId" className="mandatory">{t("valuationContracts:property_type")}</span>, width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD) },
        { title: t("valuationContracts:area_level"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_AREA_LEVEL_FIELD) },
        { title: t("valuationContracts:area_from"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD) },
        { title: t("valuationContracts:area_to"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD) },
        { title: t("valuationContracts:no_of_floors_from"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD) },
        { title: t("valuationContracts:no_of_floors_to"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD) },

        { title: t('valuationContracts:cost_approch'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) },
        { title: t('valuationContracts:market_approch'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) },
        { title: t('valuationContracts:income_approch'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_APROACH_COST_FIELD) },
        { title: t('valuationContracts:replacement_cost'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('valuationContracts:comparison'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('valuationContracts:income_capitalization_investment'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('valuationContracts:remaining_method'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('valuationContracts:discounted_cashflows'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t('valuationContracts:profits'), width: '2%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD) },
        { title: t("valuationContracts:evaluation_purpose"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD) },
        { title: t("valuationContracts:value_assumption"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD) },
        { title: t("valuationContracts:usage_type"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_USE_FIELD) },
        { title: t("valuationContracts:estimation_base"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD) },
        { title: t("valuationContracts:price"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PRICE_FIELD) },
        { title: t("valuationContracts:vat_perc"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_PERC_FIELD) },
        { title: t("valuationContracts:vat"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_VAT_FIELD) },
        { title: t("valuationContracts:total_price"), width: '3%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_NET_PRICE_FIELD) },
        { title: t("valuationContracts:remarks"), width: '4%', hidden: !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REM_FIELD) },
        { title: "", width: '4%' }
    ]

    const _deleteRow = (index) => {
        if (!valuationContract.lines) {
            valuationContract.lines = []
        }

        deleteItemByIndex(valuationContract.lines, index);
        handleDataChanged("lines", [...valuationContract.lines])
    }


    const _deleteAllLines = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => handleDataChanged("lines", []));
    }

    const editRow = (index) => {
        dialogRef.current.open(index)
    }

    const handleLineChange = (line, name, value) => {
        line[name] = value;
        if (name == "vatPerc" || name == "price") {
            line.vat = ((parseNumber(line.vatPerc)) / 100) * (parseNumber(line.price));
            line.netPrice = (parseNumber(line.vat) || 0) + (parseNumber(line.price) || 0);
        } else if (name == "frsId") {
            line.ecdId = null;
            line.frsId = value.id;
            line.regionData = value;
            line.cityData = null;
            line.townData = null;
            line.criId = null;
        } else if (name == "ecdId") {
            line.ecdId = value.id;
            line.cityData = value;
            line.criId = null;
            line.townData = null;

        } else if (name == "criId") {
            // line.ecdId = value.id;
            line.townData = value;
            line.criId = value.id;;
        }
        else if (name == "areaLevel") {
            line.areaLevel = value.id;
            line.areaLevelData = value

        }
        else if (name == "estimationReason") {
            line.estimationReason = value.id;
            line.estimationReasonData = value
        }
        else if (name == "valueAssumption") {
            line.valueAssumption = value.id;
            line.valueAssumptionData = value
        }
        else if (name == "propertyUse") {
            line.propertyUse = value.id;
            line.propertyUseData = value
        }
        else if (name == "estimationBase") {
            line.estimationBase = value.id;
            line.estimationBaseData = value
        }
        else if (name == "myoId") {
            line.myoId = value.id;
            line.propertyTypeData = value
        }
        else if (name == "aproachIncome") {
            line.methodInvestment = false;
            line.methodCashFlow = false;
            line.methodRemainValue = false;
            line.methodIncomeCaptal = false;
        }
        else if (name == "aproachMarket") {
            line.methodComparision = false;
        }
        else if (name == "aproachCost") {
            line.methodReplaceCost = false;

        }

        if (name == "price" || name == "vat" || name == "vatPerc") {
            line.netPrice = (parseNumber(line.vat) || 0) + (parseNumber(line.price) || 0);
        }
    }

    const handleRowChange = (index, name, value) => {
        if (valuationContract.lines) {
            handleLineChange(valuationContract.lines[index], name, value)
            handleDataChanged("lines", [...valuationContract.lines])
        }
    }

    const _getTableWidth = () => {
        return getDynamicColumnsTableWidth(columns)
    }


    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('valuationContracts:details')}
                    </label>
                    <div className={`tableHeaderActionsTable`}>
                        <button
                            onClick={() => dialogRef?.current?.open(-1)}
                        >
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t("add")}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllLines}
                            disabled={valuationContract.lines?.length == 0}
                            title={t('delete_all')}
                        >
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('delete_all')}
                            </label>
                        </button>
                        <button title={cardMode ? t("view_mode_listing") : t("view_mode_card")} className={style.cardBtn} onClick={() => setCardMode(!cardMode)}>
                            {cardMode &&
                                <ListIcon />
                                ||
                                <CardIcon />
                            }
                        </button>
                    </div>
                </div>
            </div>
            <Table
                id={`${ID}-details-table-${valuationContract?.umyId}-${valuationContract?.quotationType?.fields}`}
                key={valuationContract?.quotationType?.fields}
                columns={getDynamicTableColumns(columns)}
                Row={Row}
                rowHeight={40}
                list={valuationContract.lines || []}
                rowActions={{ handleRowChange, dialogRef, editRow, lovs, deleteRow: _deleteRow, valuationContract }}
                tableStyle={cardMode ? style.hideElement : style.tableStyle}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                user={user}
                tableWidth={_getTableWidth()}
            />
            {cardMode &&
                <div className={style.cardContainer}>
                    {valuationContract?.lines?.map((card, index) => {
                        return (
                            <>
                                <Card lovs={lovs} editRow={editRow} index={index} deleteRow={_deleteRow} valuationContract={valuationContract} data={card} />
                            </>
                        )
                    })}
                </div>
            }
            <DetailsSidePanel formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID} masterData={valuationContract} ref={dialogRef} saveAction={(index, data) => {
                if (index < 0) {
                    if (!valuationContract.lines) {
                        valuationContract.lines = []
                    }
                    valuationContract.lines.push({ ...data })
                }
                else {
                    valuationContract.lines[index] = { ...data }
                }
                setValuationContract({ ...valuationContract })
            }}
                handleChange={handleLineChange} lovs={lovs} />
        </div>
    );
}
export default Details;