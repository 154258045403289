import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import UploadImg from "../../../components/upload-img";
import { getDomainValues, getPartiesList216 } from "../../../util/apis";
import { GAUGE_USER_SUPPLIER_DOMAIN, GAUGE_USER_SUPPLIER_EMPLOYEE_DOMAIN, GAUGE_USER_SUPPLIER_GAUGE_USER_DOMAIN, GAUGE_USER_SUPPLIER_PARTY_DOMAIN, NOTIFICATION_DOMAIN, SYSTEM_DIMENSIONS_CUSTOMER_DOMAIN, SYSTEM_DIMENSIONS_EMPLOYEE_DOMAIN, SYSTEM_DIMENSIONS_PROP_PARTY_DOMAIN, SYSTEM_DIMENSIONS_SUPPLIER_DOMAIN, USER_DEFINITION_SIGNATURE, USER_DEFINITION_STAMP } from "../../../util/constants";
import style from './style.module.scss';

const dialogId = "users-definition-dialog";

const Tab2 = (props) => {
    const { tab, data, handleDataChanged, signatureImageRef, stampImageRef } = props;

    const { t } = useTranslation('usersDefinition');

    const _getPartyType = () => {
        const partyTypes = [];
        if (data?.type == GAUGE_USER_SUPPLIER_PARTY_DOMAIN) {
            partyTypes.push(SYSTEM_DIMENSIONS_SUPPLIER_DOMAIN);
            partyTypes.push(SYSTEM_DIMENSIONS_CUSTOMER_DOMAIN);
            partyTypes.push(SYSTEM_DIMENSIONS_PROP_PARTY_DOMAIN);
        } else if (data?.type == GAUGE_USER_SUPPLIER_EMPLOYEE_DOMAIN) {
            partyTypes.push(SYSTEM_DIMENSIONS_EMPLOYEE_DOMAIN);
        }
        return partyTypes;
    }

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className={style.settingSection}>
                <div className='container'>
                    <div className='row rowSmModel'>
                        <div className="w100">
                            <label className='form-label'>{t('user_type')}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-user-type`}
                                value={data.type}
                                api={() => getDomainValues(GAUGE_USER_SUPPLIER_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChanged("type", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w100">
                            <label className='form-label'>{t('target')}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-target`}
                                key={`target${data.type || ''}-${data.id || ''}`}
                                value={data.gprId}
                                api={() => getPartiesList216(_getPartyType(), null, isEmpty(data.gprId) ? [] : [data.gprId])}
                                descLo={data?.type == GAUGE_USER_SUPPLIER_PARTY_DOMAIN ? ['code', 'descLo'] : ['descLo']}
                                descFo={data?.type == GAUGE_USER_SUPPLIER_PARTY_DOMAIN ? ['code', 'descFo'] : ['descFo']}
                                onChange={value => handleDataChanged("gprId", value.value)}
                                isDisabled={isEmpty(data.type) || data.type == GAUGE_USER_SUPPLIER_GAUGE_USER_DOMAIN}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label id={`${dialogId}-wf-email-lbl`} className='form-label mandatory'>{t('wf_email')}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-wf-email`}
                                value={data.sendWfEmail}
                                api={() => getDomainValues(NOTIFICATION_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChanged("sendWfEmail", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label id={`${dialogId}-wf-sms-lbl`} className='form-label mandatory'>{t('wf_sms')}</label>
                            <FrmSelectMenu
                                id={`${dialogId}-wf-sms`}
                                value={data.sendWfSms}
                                api={() => getDomainValues(NOTIFICATION_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChanged("sendWfSms", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className={`row rowSmModel ${style.containerUploudImg}`}>
                        <div className="w50">
                            <UploadImg
                                ref={signatureImageRef}
                                masterId={data?.id}
                                formId={USER_DEFINITION_SIGNATURE}
                            />
                        </div>
                        <div className="w50">
                            <UploadImg
                                ref={stampImageRef}
                                masterId={data?.id}
                                formId={USER_DEFINITION_STAMP}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab2;