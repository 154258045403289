import ActionsButton from "@ipgd-gauge/actions-button";
import { addUrlParam, checkValidation, confirmationDialog, handleError, hideActionWaiting, isAllowedAction, isNotEmpty, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../../icons/AddBtn";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import IncompleteIcon from "../../../icons/Incomplete";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import SaveIcon from "../../../icons/Save";
import { confirmEstimationInvoice, deleteEstimationInvoice, rejectEstimationInvoice, saveEstimationInvoice } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, INCOMPLETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import { ESTIMATION_FEES, ITEM } from "./Details";
import { ACTION_SET_INVOICE } from "./reducer";

const Actions = (props) => {

    const { user, invoiceState, dispatchInvoice, printDialogRef, newAction, fromSaveActionRef } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [actions, setActions] = useState([]);

    const _saveAction = (withSubmit, id) => {
        if (withSubmit && !checkValidation([{ id: 'customers-estimation-container' }], t)) {
            return
        }

        // let header = Object.assign({}, invoiceState.invoice.header);

        showActionWaiting(id);
        let data = { ...invoiceState.invoice.header, lines: invoiceState.invoice.lines }

        saveEstimationInvoice(data, withSubmit)
            .then((response) => {
                fromSaveActionRef.current = true;
                let invoiceData = { header: response.data, lines: response.data.lines };
                invoiceData.lines.map((line) => {
                    if (line.source == ESTIMATION_FEES) {
                        line.applicationNum = line.transaction.code
                    }
                    if (line.source == ITEM) {
                        line.descLo = line.item?.code + ', ' + line.item?.descLo
                        line.descFo = line.item?.code + ', ' + line.item?.descFo
                    }
                })

                dispatchInvoice({ value: invoiceData, type: ACTION_SET_INVOICE })
                addUrlParam("id", response.data.id);
                showSuccessMessage('save_successfully', t);
            })
            .catch((error) => handleError(error, error?.response?.data?.message.split('#')[0], t))
            .finally(() => hideActionWaiting(id))
    }

    const _confirmInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return confirmEstimationInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/customers-estimation-invoices`);
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }

    const _rejectInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectEstimationInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/customers-estimation-invoices`);
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }

    const _deleteInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimationInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/customers-estimation-invoices`);
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }


    const _isAllowedSaveAction = () => {
        const status = invoiceState?.invoice?.header?.globalStatus;
        return (status == GLOBAL_STATUS_INCOMPLETE || status == GLOBAL_STATUS_SUBMITTED) && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, SAVE_ACTION);
    }
    useEffect(() => {
        let temp = [];

        const status = invoiceState?.invoice?.header?.globalStatus;


        if ((status == GLOBAL_STATUS_INCOMPLETE && isNotEmpty(invoiceState?.invoice?.header?.id)) && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, DELETE_ACTION)) {
            temp.push({
                id: "delete-customer-estimation-invoice",
                label: t('customersEstimationInvoices:delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                icon: <DeleteTowIcon />,
                action: _deleteInvoice
            });
        }
        if (status == GLOBAL_STATUS_SUBMITTED && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, REJECT_ACTION)) {
            temp.push({
                id: "reject-customer-estimation-invoice",
                label: t('customersEstimationInvoices:reject'),
                color: "var(--action-delete-bg)",
                icon: <RejectIcon />,
                action: _rejectInvoice
            });
        }
        if (newAction && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, NEW_ACTION)) {
            temp.push({
                id: "new-customer-estimation-invoice",
                label: t('customersEstimationInvoices:new'),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
                action: () => newAction()
            });
        }
        if (isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, PRINT_ACTION)) {
            temp.push({
                id:
                    "print-customer-estimation-invoice",
                label: t('customersEstimationInvoices:print'),
                color: "var(--pageination)",
                action: () => printDialogRef.current?.open([invoiceState?.invoice?.header?.id]),
                icon: <PrinterIcon />
            });
        }

        if (status == GLOBAL_STATUS_SUBMITTED) {
            if (isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, CONFIRM_FORM_ACTION)) {
                temp.push({
                    id: "approve-customer-estimation-invoice",
                    label: t('customersEstimationInvoices:confirm'),
                    color: "var(--action-save)",
                    icon: <ApproveIcon />,
                    action: _confirmInvoice
                });
            }

        }
        
         if (status == GLOBAL_STATUS_INCOMPLETE && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, INCOMPLETE_ACTION)) {
             temp.push({
                 id: "draft-customer-estimation-invoice",
                 label: t('customersEstimationInvoices:draft'),
                 icon: <IncompleteIcon />,
                 action: () => _saveAction(false, "draft-customer-estimation-invoice")
             });
         }

        if (_isAllowedSaveAction()) {
            temp.push({
                id: "save-customer-estimation-invoice",
                label: t('customersEstimationInvoices:save'),
                hotkey: SAVE_ACTION_SHORT_CUT,
                color: "var(--action-save)",

                icon: <SaveIcon />,
                action: () => _saveAction(true, "save-customer-estimation-invoice")
            });
        }

    
        setActions([...temp]);
    }, [i18next.language, invoiceState]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction(true, "save-customer-estimation-invoice");
        }
    }, { enableOnFormTags: true });

    return <ActionsButton actions={actions} />
}

export default Actions;