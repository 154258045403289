import React from "react";

function DeleteAttachmentIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31.901"
            height="35.173"
            viewBox="0 0 31.901 35.173"
        >
            <g
                fill="#ed0101"
                data-name="Group 178573"
                transform="translate(-227.25 -245.25)"
            >
                <path
                    d="M.476 2.522A1.227 1.227 0 01.357.074l3.338-.327a98.3 98.3 0 019.837-.5c5.44 0 11 .277 16.513.824a1.227 1.227 0 01-.245 2.445A165.52 165.52 0 0013.532 1.7a95.858 95.858 0 00-9.594.485L.6 2.516a1.351 1.351 0 01-.124.006z"
                    transform="translate(228 251.694)"
                ></path>
                <path
                    d="M11.927 6.563a1.227 1.227 0 01-1.208-1.023l-.36-2.127a3.165 3.165 0 00-.384-1.331A2.384 2.384 0 008.346 1.7H4.06a2.452 2.452 0 00-1.63.36 3.218 3.218 0 00-.383 1.34l-.36 2.143a1.227 1.227 0 11-2.42-.406l.36-2.143C-.107 1.411.257-.75 4.06-.75h4.286c3.821 0 4.19 2.264 4.434 3.762l.359 2.119a1.228 1.228 0 01-1.211 1.432z"
                    data-name="Vector"
                    opacity="0.34"
                    transform="translate(236.998 246)"
                ></path>
                <path
                    d="M16.935 22.742H6.432c-2.512 0-4.12-.572-5.063-1.8a6.96 6.96 0 01-1.053-3.905L-.747.556A1.227 1.227 0 011.7.4l1.065 16.472a5.236 5.236 0 00.55 2.577c.428.557 1.476.839 3.117.839h10.5c1.64 0 2.689-.282 3.117-.839a5.25 5.25 0 00.551-2.584L21.665.4a1.227 1.227 0 012.449.158L23.051 17.03A6.971 6.971 0 0122 20.944c-.945 1.227-2.554 1.798-5.065 1.798z"
                    data-name="Vector"
                    transform="translate(231.518 257.68)"
                ></path>
                <path
                    d="M5.925 1.7H.477a1.227 1.227 0 110-2.454h5.448a1.227 1.227 0 110 2.454z"
                    data-name="Vector"
                    opacity="0.34"
                    transform="translate(239.992 269.721)"
                ></path>
                <path
                    d="M8.657 1.7H.477a1.227 1.227 0 010-2.454h8.18a1.227 1.227 0 010 2.454z"
                    data-name="Vector"
                    opacity="0.34"
                    transform="translate(238.634 263.178)"
                ></path>
            </g>
        </svg>
    );
}

export default DeleteAttachmentIcon;
