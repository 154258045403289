
export const CondominiumsIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="67.248"
            height="41.52"
            viewBox="0 0 67.248 41.52"
        >
            <g data-name="Group 180051" transform="translate(5251 13031.771)">
                <g data-name="Group 178488" transform="translate(-5251 -13031.77)">
                    <path
                        fill={props.isHistory ? "#B57A4D" : "#1a00be"}
                        d="M60.913 12.5H28.6a15.936 15.936 0 00-16.1 15.762 15.936 15.936 0 0016.1 15.762h9.214l6.863 6.718 6.863-6.718h9.368a15.936 15.936 0 0016.1-15.762A15.936 15.936 0 0060.913 12.5"
                        data-name="Path 44745"
                        transform="translate(-11.134 -11.134)"
                    ></path>
                    <path
                        fill="#fff"
                        d="M33.547 41.519l-7.421-7.263h-8.657A17.321 17.321 0 010 17.128 17.321 17.321 0 0117.469 0h32.31a17.321 17.321 0 0117.469 17.128 17.321 17.321 0 01-17.469 17.128h-8.811zM17.469 2.732a14.587 14.587 0 00-14.738 14.4 14.587 14.587 0 0014.738 14.4h9.771l6.307 6.168 6.306-6.172h9.925a14.587 14.587 0 0014.738-14.4 14.587 14.587 0 00-14.738-14.4z"
                        data-name="Path 44746"
                    ></path>
                </g>
                <g fill="#fff" data-name="Group 180048">
                    <path
                        d="M.537 15.4a.537.537 0 110-1.074h.179V3.4a3.527 3.527 0 01.757-2.376A3.318 3.318 0 014.118 0h7.16a3.315 3.315 0 012.644 1.025A3.523 3.523 0 0114.68 3.4v10.923h.178a.537.537 0 110 1.074zM2.286 1.727A2.506 2.506 0 001.79 3.4v10.922h11.815V3.4a2.5 2.5 0 00-.5-1.674 2.307 2.307 0 00-1.832-.653H4.118a2.31 2.31 0 00-1.832.654zM4.77 11.876a.627.627 0 110-1.255h5.855a.627.627 0 010 1.255zm0-3.551a.627.627 0 010-1.255h5.855a.627.627 0 010 1.255zm0-3.551a.627.627 0 010-1.255h5.855a.627.627 0 010 1.255z"
                        data-name="Union 393"
                        transform="translate(-5225.074 -13022.646)"
                    ></path>
                    <path
                        d="M14.858 15.646H.537a.787.787 0 01-.071-1.571V3.4A3.767 3.767 0 011.284.862 3.555 3.555 0 014.117-.25h7.16A3.553 3.553 0 0114.111.862 3.763 3.763 0 0114.93 3.4v10.676a.787.787 0 01-.072 1.57zM.537 14.573a.287.287 0 100 .574h14.321a.287.287 0 100-.574h-.428V3.4a3.284 3.284 0 00-.7-2.212A3.08 3.08 0 0011.278.25H4.117a3.082 3.082 0 00-2.455.939A3.288 3.288 0 00.966 3.4v11.173zm13.318 0H1.54V3.4a2.742 2.742 0 01.56-1.836 2.542 2.542 0 012.017-.74h7.16a2.54 2.54 0 012.021.74 2.738 2.738 0 01.557 1.836zm-11.815-.5h11.315V3.4a2.267 2.267 0 00-.435-1.51 2.077 2.077 0 00-1.642-.567H4.117a2.079 2.079 0 00-1.642.567A2.27 2.27 0 002.04 3.4zm8.585-1.945H4.77a.877.877 0 110-1.755h5.855a.877.877 0 110 1.755zM4.77 10.872a.377.377 0 100 .755h5.855a.377.377 0 100-.755zm5.855-2.3H4.77a.877.877 0 110-1.755h5.855a.877.877 0 110 1.755zM4.77 7.32a.377.377 0 000 .755h5.855a.377.377 0 100-.755zm5.855-2.3H4.77a.877.877 0 110-1.755h5.855a.877.877 0 110 1.755zM4.77 3.769a.377.377 0 000 .755h5.855a.377.377 0 100-.755z"
                        data-name="Union 393 - Outline"
                        transform="translate(-5225.074 -13022.646)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export const LandIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="67.248"
            height="41.52"
            viewBox="0 0 67.248 41.52"
        >
            <g data-name="Group 180050" transform="translate(5167 13031.771)">
                <g data-name="Group 178488" transform="translate(-5167 -13031.77)">
                    <path
                        fill={props.isHistory ? "#A14600" : "green"}
                        d="M60.913 12.5H28.6a15.936 15.936 0 00-16.1 15.762 15.936 15.936 0 0016.1 15.762h9.214l6.863 6.718 6.863-6.718h9.368a15.936 15.936 0 0016.1-15.762A15.936 15.936 0 0060.913 12.5"
                        data-name="Path 44745"
                        transform="translate(-11.134 -11.134)"
                    ></path>
                    <path
                        fill="#fff"
                        d="M33.547 41.519l-7.421-7.263h-8.657A17.321 17.321 0 010 17.128 17.321 17.321 0 0117.469 0h32.31a17.321 17.321 0 0117.469 17.128 17.321 17.321 0 01-17.469 17.128h-8.811zM17.469 2.732a14.587 14.587 0 00-14.738 14.4 14.587 14.587 0 0014.738 14.4h9.771l6.307 6.168 6.306-6.172h9.925a14.587 14.587 0 0014.738-14.4 14.587 14.587 0 00-14.738-14.4z"
                        data-name="Path 44746"
                    ></path>
                </g>
                <g
                    fill="#fff"
                    data-name="Group 180044"
                    transform="translate(3.862 1.291)"
                >
                    <g data-name="Group 180043" transform="translate(-5146 -13025)">
                        <path
                            d="M10.054 16.143H5.339c-2.177 0-3.651-.461-4.64-1.449S-.75 12.23-.75 10.054V5.339C-.75 3.163-.289 1.688.7.7S3.163-.75 5.339-.75h4.714c2.177 0 3.647.461 4.641 1.45s1.449 2.463 1.449 4.64v4.714c0 2.177-.461 3.651-1.449 4.64s-2.464 1.449-4.64 1.449zM5.339.429c-1.844 0-3.053.351-3.807 1.1S.429 3.5.429 5.339v4.714c0 1.844.351 3.053 1.1 3.807s1.963 1.1 3.807 1.1h4.714c1.844 0 3.053-.351 3.807-1.1s1.1-1.963 1.1-3.807V5.339c0-1.844-.351-3.053-1.1-3.807S11.9.429 10.054.429z"
                            transform="translate(.998 .998)"
                        ></path>
                        <path
                            d="M10.052 16.389H5.337c-2.247 0-3.777-.484-4.815-1.522S-1 12.3-1 10.052V5.337C-1 3.091-.516 1.56.522.522S3.091-1 5.337-1h4.714c2.249 0 3.778.484 4.816 1.522s1.522 2.569 1.522 4.815v4.714c0 2.247-.484 3.777-1.522 4.815s-2.567 1.523-4.815 1.523zM5.337-.5C3.231-.5 1.812-.066.873.873S-.5 3.231-.5 5.337v4.714c0 2.107.437 3.525 1.377 4.465s2.358 1.377 4.465 1.377h4.714c2.107 0 3.525-.437 4.465-1.377s1.377-2.358 1.377-4.465V5.337c0-2.107-.437-3.525-1.377-4.465S12.158-.5 10.052-.5zm4.714 15.714H5.337c-1.914 0-3.179-.374-3.982-1.177S.179 11.966.179 10.052V5.337c0-1.914.374-3.179 1.177-3.982S3.424.179 5.337.179h4.714c1.914 0 3.179.374 3.982 1.177s1.177 2.068 1.177 3.982v4.714c0 1.914-.374 3.179-1.177 3.982s-2.067 1.177-3.981 1.177zM5.337.675C3.564.675 2.41 1 1.706 1.706S.675 3.564.675 5.337v4.714c0 1.774.328 2.927 1.032 3.631s1.858 1.032 3.631 1.032h4.714c1.774 0 2.927-.328 3.631-1.032s1.032-1.858 1.032-3.631V5.337c0-1.774-.328-2.927-1.032-3.631S11.825.675 10.052.675z"
                            data-name="Vector - Outline"
                            transform="translate(1 1)"
                        ></path>
                    </g>
                    <path
                        d="M3.728 16.143a.59.59 0 01-.572-.448L-.733-.019A.589.589 0 01-.3-.733.589.589 0 01.411-.3L4.3 15.412a.59.59 0 01-.572.731z"
                        data-name="Vector"
                        transform="translate(-5139.502 -13024.002)"
                    ></path>
                    <path
                        d="M3.726 16.389a.836.836 0 01-.812-.636L-.976.039A.832.832 0 01-.88-.6a.832.832 0 01.516-.38A.84.84 0 01-.162-1a.836.836 0 01.812.636l3.89 15.715a.837.837 0 01-.612 1.014.84.84 0 01-.202.024zM-.162-.5a.343.343 0 00-.082.01.339.339 0 00-.21.155.339.339 0 00-.039.258L3.4 15.634a.34.34 0 00.331.259.344.344 0 00.082-.01.341.341 0 00.249-.413L.169-.245A.34.34 0 00-.162-.5z"
                        data-name="Vector - Outline"
                        transform="translate(-5139.5 -13024)"
                    ></path>
                    <path
                        d="M-.161 2.613a.59.59 0 01-.565-.424.589.589 0 01.4-.731L7.162-.726a.589.589 0 01.731.4.589.589 0 01-.4.731L0 2.589a.59.59 0 01-.161.024z"
                        data-name="Vector"
                        transform="translate(-5145.002 -13015.972)"
                    ></path>
                    <path
                        d="M-.163 2.859a.842.842 0 01-.8-.6A.838.838 0 01-.4 1.218L7.091-.967A.838.838 0 017.326-1a.842.842 0 01.8.6A.838.838 0 017.56.641L.072 2.826a.838.838 0 01-.235.033zM7.326-.5a.342.342 0 00-.1.014l-7.484 2.18a.342.342 0 00-.232.423.343.343 0 00.327.246.342.342 0 00.1-.014L7.421.165a.342.342 0 00.232-.423.343.343 0 00-.327-.242z"
                        data-name="Vector - Outline"
                        transform="translate(-5145 -13015.97)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export const ApartmentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="67.248"
            height="41.52"
            viewBox="0 0 67.248 41.52"
        >
            <g data-name="Group 178488" transform="translate(0 .001)">
                <path
                    fill="#912a9a"
                    d="M60.913 12.5H28.6a15.936 15.936 0 00-16.1 15.762 15.936 15.936 0 0016.1 15.762h9.214l6.863 6.718 6.863-6.718h9.368a15.936 15.936 0 0016.1-15.762A15.936 15.936 0 0060.913 12.5"
                    data-name="Path 44745"
                    transform="translate(-11.134 -11.134)"
                ></path>
                <path
                    fill="#fff"
                    d="M33.547 41.519l-7.421-7.263h-8.657A17.321 17.321 0 010 17.128 17.321 17.321 0 0117.469 0h32.31a17.321 17.321 0 0117.469 17.128 17.321 17.321 0 01-17.469 17.128h-8.811zM17.469 2.732a14.587 14.587 0 00-14.738 14.4 14.587 14.587 0 0014.738 14.4h9.771l6.307 6.168 6.306-6.172h9.925a14.587 14.587 0 0014.738-14.4 14.587 14.587 0 00-14.738-14.4z"
                    data-name="Path 44746"
                ></path>
                <path
                    fill="#fff"
                    d="M109.909 99.511L103.873 95a.71.71 0 00-.856 0l-6.036 4.6a.71.71 0 00-.28.565v8.435a.71.71 0 00.71.71h12.072a.71.71 0 00.71-.71v-8.52a.711.711 0 00-.285-.569m-1.136 8.381h-2.929v-5.245h-4.793v5.245h-2.928v-7.371l5.33-4.065 5.322 3.979z"
                    data-name="Path 44747"
                    transform="translate(-69.823 -84.492)"
                ></path>
            </g>
        </svg>
    );
}



