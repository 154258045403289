import React from "react";

function ReOrderIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.775"
            height="32.775"
            viewBox="0 0 32.775 32.775"
        >
            <g
                fill="#1eacae"
                data-name="Group 178576"
                transform="translate(-1.906 -1.905)"
            >
                <path
                    d="M5.2-.75h4.111c4.056 0 5.945 1.889 5.945 5.945v5.64c0 4.056-1.889 5.945-5.945 5.945H5.2c-4.056 0-5.945-1.889-5.945-5.945V5.2C-.75 1.139 1.139-.75 5.2-.75zm4.111 15.244c2.8 0 3.659-.855 3.659-3.659V5.2c0-2.8-.855-3.659-3.659-3.659H5.2c-2.8 0-3.659.855-3.659 3.659v5.64c0 2.8.855 3.659 3.659 3.659z"
                    transform="translate(19.425 2.655)"
                ></path>
                <path
                    d="M5.2-.75h4.111c4.056 0 5.945 1.889 5.945 5.945v5.64c0 4.056-1.889 5.945-5.945 5.945H5.2c-4.056 0-5.945-1.889-5.945-5.945V5.2C-.75 1.139 1.139-.75 5.2-.75zm4.111 15.244c2.8 0 3.659-.855 3.659-3.659V5.2c0-2.8-.855-3.659-3.659-3.659H5.2c-2.8 0-3.659.855-3.659 3.659v5.64c0 2.8.855 3.659 3.659 3.659z"
                    data-name="Vector"
                    transform="translate(2.656 17.899)"
                ></path>
                <path
                    d="M.393 12.208a1.143 1.143 0 01-.98-1.732l1.6-2.668a1.143 1.143 0 011.961 1.177l-.412.687A9.545 9.545 0 009.921.393a1.143 1.143 0 012.287 0A11.738 11.738 0 018.75 8.75a11.738 11.738 0 01-8.357 3.458z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(22.474 22.473)"
                ></path>
                <path
                    d="M.393 12.208A1.143 1.143 0 01-.75 11.064 11.806 11.806 0 0111.064-.75a1.143 1.143 0 01.98 1.732l-1.6 2.668a1.143 1.143 0 11-1.961-1.177l.417-.687a9.545 9.545 0 00-7.359 9.279 1.143 1.143 0 01-1.148 1.143z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(2.656 2.655)"
                ></path>
            </g>
        </svg>
    );
}

export default ReOrderIcon;
