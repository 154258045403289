import React from "react";

function WorkflowProcedureMessagesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.511"
      height="15.48"
      viewBox="0 0 19.511 15.48"
    >
      <g data-name="Group 179974" transform="translate(5244.349 11635.75)">
        <g
          fill="#292d32"
          data-name="Group 179973"
          transform="translate(-5354.599 -12213)"
        >
          <path
            d="M2.55 12.73L0 12.72a.75.75 0 01-.747-.753A.75.75 0 010 11.22l2.55.01a1.956 1.956 0 001.633-.866l6.39-9.58A3.422 3.422 0 0113.468-.75l4.545.02a.75.75 0 01.747.753.751.751 0 01-.753.747l-4.55-.02a1.93 1.93 0 00-1.633.866L5.434 11.2a3.455 3.455 0 01-2.884 1.53z"
            transform="translate(111 578.01)"
          ></path>
          <path
            d="M5.891 3.37a.749.749 0 01-.609-.312L4.2 1.558A1.927 1.927 0 002.619.75h-.006L0 .76A.75.75 0 01-.75.013.75.75 0 010-.74l2.6-.01A3.428 3.428 0 015.419.683l1.08 1.5a.75.75 0 01-.608 1.187z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(111 578)"
          ></path>
          <g data-name="Group 168" opacity="0.4">
            <path
              d="M0 2.75a.748.748 0 01-.53-.22.75.75 0 010-1.061l2-2a.75.75 0 011.061 0A.75.75 0 012.53.53l-2 2a.748.748 0 01-.53.22z"
              data-name="Vector"
              transform="translate(127 589.98)"
            ></path>
            <path
              d="M3.373 3.37A3.488 3.488 0 01.627 2.029L-.592.46A.75.75 0 01-.46-.592.75.75 0 01.592-.46l1.22 1.57a1.978 1.978 0 001.558.76l4.667-.02a.75.75 0 11.006 1.5z"
              data-name="Vector"
              transform="translate(120.971 587.38)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WorkflowProcedureMessagesIcon;