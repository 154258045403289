import { EditBtn } from "@ipgd-gauge/table";
import { handleError, isAllowedAction, isEnglish } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { getValuationFieldList } from "../../../util/apis";
import { VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, dialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.updateValuationFieldsRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={isEnglish() ? data?.blockDescLo : data?.blockTypeDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('updateValuationFields:block')}</label>
                <label>{isEnglish() ? data?.blockDescLo : data?.blockDescFo}</label>
            </div>

            <div title={isEnglish() ? data?.descLo : data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('updateValuationFields:system_name')}</label>
                <label>{isEnglish() ? data?.descLo : data.descFo}</label>
            </div>

            <div title={isEnglish() ? data?.userDescLo : data.userDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('updateValuationFields:user_name')}</label>
                <label>{isEnglish() ? data?.userDescLo : data.userDescFo}</label>
            </div>

            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('updateValuationFields:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>
            <div>
                <div className={`containerButtonTable ${style.updateValuationFieldsBtnStyle}`}>
                    {isAllowedAction(user, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const UpdateValuationFieldsTable = (props) => {
    const { user, tableRef, searchTextRef, filterRef, dialogRef } = props;

    const { t } = useTranslation();

    const [valuationFields, setValuationFields] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('updateValuationFields:block'), width: "18%" },
        { title: t('updateValuationFields:system_name'), width: "28%" },
        { title: t('updateValuationFields:user_name'), width: "28%" },
        { title: t('updateValuationFields:status'), width: "11%" },
        { title: "", width: "10%" }
    ];

    const _fetchData = (page, size) => {
        getValuationFieldList(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setValuationFields(response.data))
            .catch((error) => handleError(error, null, t));
    }

    return (
        <div className={style.updateValuationFieldsTableContainer}>
            <Slisting
                id="update-valuation-fields-table"
                ref={tableRef}
                formId={RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID}
                columns={columns}
                Row={Row}
                tableStyle={style.updateValuationFieldsTable}
                getData={_fetchData}
                pagingData={valuationFields}
                rowActions={{ user, dialogRef }}
                cardInMobile
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                viewAction={(id) => dialogRef.current?.open(id)}
                labelCardWidth="120px"
            />
        </div>
    );
};

export default UpdateValuationFieldsTable;