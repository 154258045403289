import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteBranch, getBranchList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2669_BRANCHES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { t } = useTranslation();
    const { data, rowActions } = props;
    const { dialogRef, deleteAction, user } = rowActions;

    return (
        <div className={`${style.branchesDefinitionRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('branchesDefinition:code')}</label>
                <label>{data.code}</label>
            </div>

            <div title={isEnglish() ? data.descLo : data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('branchesDefinition:name')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>

            <div title={data?.divisionCount} className="cardRowMode">
                <label className="nameLblMobile">{t('branchesDefinition:num_divisions')}</label>
                <label>{data?.divisionCount}</label>
            </div>

            <div className="cardRowMode">
                <label className="nameLblMobile">{t('branchesDefinition:remarks')}</label>
                <label className="cardRowDesc" title={data?.rem}>
                    {data?.rem}
                </label>
            </div>

            <div className="cardRowStatusMode">
                <label className="nameLblMobile">{t('branchesDefinition:status')}</label>
                <label title={isEnglish() ? data.statusDescLo : data.statusDescFo} className={`status-${data.isActive}`}>
                    {isEnglish() ? data.statusDescLo : data.statusDescFo}
                </label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM2669_BRANCHES_DEFINITIONS_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef.current.open(data.id)} />}

                    {isAllowedAction(user, RSM2669_BRANCHES_DEFINITIONS_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data?.id)} />}
                </div>
            </div>
        </div>
    );
};

const BranchesDefinitionTable = (props) => {

    const { filtersRef, tableRef, searchTextRef, dialogRef, newAction, user } = props;
    const { t } = useTranslation();

    const [branches, setBranches] = useState();
    const columns = [
        { title: '#', width: "5%" },
        { title: t('branchesDefinition:code'), width: "10%" },
        { title: t('branchesDefinition:name'), width: "20%" },
        { title: t('branchesDefinition:num_divisions'), width: "17%" },
        { title: t('branchesDefinition:remarks'), width: "17%" },
        { title: t('branchesDefinition:status'), width: "11%" },
        { title: "", width: "20%" },
    ];
    const _deleteAction = (id) => {
        if (isNotEmpty(id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return deleteBranch(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch(() => handleError(null, "delete_fields", t))
            });
        }
    }

    return (
        <>
            <div className={style.branchesDefinitionTableContainer}>
                <Slisting
                    id="branches-definition"
                    ref={tableRef}
                    columns={columns}
                    Row={Row}
                    tableStyle={style.branchesDefinitionTable}
                    getData={(page, size) => {
                        getBranchList(page, size, searchTextRef.current.getSearchText(), filtersRef.current?.getFilterData())
                            .then((response) => {
                                setBranches(response.data);
                            })
                            .catch((error) => {
                                console.log("🚀 ~ error", error)
                            })
                    }}
                    rowActions={{ tableRef: tableRef, dialogRef: dialogRef, deleteAction: _deleteAction, user: user }}
                    rowHeight={80}
                    pagingData={branches}
                    withFilter
                    showWating={(data) => {
                        return data == null
                    }}
                    showNoDataFound={(data) => {
                        return (data && data.length == 0)
                    }}
                    newAction={newAction}
                    deleteAction={deleteBranch}
                    viewAction={(id) => dialogRef.current?.open(id)}
                    cardInMobile
                    labelCardWidth="115px"
                    formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                    user={user}
                />
            </div>
        </>
    );
};

export default BranchesDefinitionTable;
