import Checkbox from "@ipgd-gauge/checkbox";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../../../components/decimal-input";
import { getAdditionalItemsLov, getEvaluationPolicyOrganizations } from "../../../../../util/apis";
import { POLICY_LVL_SELF_SERVICE, STATUS_ACTIVE, STATUS_ESTIMATOR_FEES, STATUS_ORGANIZATION_ESTIMATION_FEES } from "../../../../../util/constants";
import { ACTION_SET_RESTICTION_POLICY_VALUE } from "../reducer";
import style from '../style.module.scss';

const DISABLE_DATE = "117201";
const CHECK_MOBILE_NUMBER_OF_DIGITS = "117207";
const DEFAULT_ORGANIZATION = "117209";
const ESTIMATION_FEES_ITEM = "117210";
const DEFAULT_VAT_PERCENTAGE = "117211";
const QUOTATION_VALIDITY_PERIOD_DAYS = "117212";
const ESTIMATION_RESTRICTION_REPORT = "117213";
const COMPARISON_CONFIGURATION_ZOOM = "117214";
const COMPARISON_CONFIGURATION_HAS_DISTANCE_LIMITATION = "117215";
const DISTANCE_COMPARISON_CONFIGURATION_DISTANCE_KILOMETERS = "117216";
const HIDE_ESTIMATION_VALUE_FROM_ESTIMATION_LINK = "117217";
const ESTIMATION_SESSION_TIMEOUT = "117218";
const ESTIMATOR_FEES = "117219";
const SHOW_INSPECTOR_ON_ESTIMATORS_LOOKUP = "117220";
const DISABLE_INVOICE_AMOUNT_ON_ESTIMATION_INVOICES = "117221";
const CAN_UPDATE_ALL_THE_COPIED_TRANSACTIONS = "117225";
const IGNORE_PLANED_AND_ESTIMATION_DATA = "117226";

const RestrictionPolicy = (props) => {
    const { data, dispatchPropertyPolicy } = props;

    const { t } = useTranslation('propertiesPolicies');

    const _handleDataChange = (name, value) => {
        dispatchPropertyPolicy({ type: ACTION_SET_RESTICTION_POLICY_VALUE, name, value })
    }

    const isDisabled = (option) => {
        return !data?.function?.restrictionList?.includes(option);
    }

    return (
        <div className={style.restrictionPolicyPanel}>
            <div className={`frm-title-panel-border-toggle ${style.restrictionTitle}`}>
                <div>
                    <label className="frm-title-form-border">
                        {t('customersEstimationInvoices:options')}
                    </label>
                </div>
            </div>
            <div className={style.restrictionPolicyContainer}>
                <div>
                    <div className='container'>
                        <div className='row rowSmModel'>
                            {data?.function?.restrictionList?.includes(DISABLE_DATE) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="disable_date"
                                            label={t('disable_date')}
                                            isChecked={data.restrictionPolicy?.optn117201}
                                            setIsChecked={(value) => _handleDataChange("optn117201", value)}
                                            disabled={isDisabled(DISABLE_DATE)}
                                        />
                                    </div>
                                </div>
                            }

                            {data?.function?.restrictionList?.includes(CHECK_MOBILE_NUMBER_OF_DIGITS) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="restrict_mobile_digits"
                                            label={t('restrict_mobile_digits')}
                                            isChecked={data.restrictionPolicy.optn117207}
                                            setIsChecked={(value) => _handleDataChange("optn117207", value)}
                                            disabled={isDisabled(CHECK_MOBILE_NUMBER_OF_DIGITS)}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(SHOW_INSPECTOR_ON_ESTIMATORS_LOOKUP) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="show_inspector_on"
                                            label={t('show_inspector_on')}
                                            isChecked={data.restrictionPolicy.optn117220}
                                            setIsChecked={(value) => _handleDataChange("optn117220", value)}
                                            disabled={isDisabled(SHOW_INSPECTOR_ON_ESTIMATORS_LOOKUP)}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(HIDE_ESTIMATION_VALUE_FROM_ESTIMATION_LINK) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="hide_estimation_value_from_estimation_link"
                                            label={t('hide_estimation_value_from_estimation_link')}
                                            isChecked={data.restrictionPolicy.optn117217}
                                            setIsChecked={(value) => _handleDataChange("optn117217", value)}
                                            disabled={isDisabled(HIDE_ESTIMATION_VALUE_FROM_ESTIMATION_LINK)}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(DISABLE_INVOICE_AMOUNT_ON_ESTIMATION_INVOICES) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="disable_invoice_amount_on_estimation_invoices"
                                            label={t('disable_invoice_amount_on_estimation_invoices')}
                                            isChecked={data.restrictionPolicy.optn117221}
                                            setIsChecked={(value) => _handleDataChange("optn117221", value)}
                                            disabled={isDisabled(DISABLE_INVOICE_AMOUNT_ON_ESTIMATION_INVOICES)}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(ESTIMATION_RESTRICTION_REPORT) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="estimation_restriction_report"
                                            label={t('estimation_restriction_report')}
                                            isChecked={data.restrictionPolicy.optn117213}
                                            setIsChecked={(value) => _handleDataChange("optn117213", value)}
                                            disabled={isDisabled(ESTIMATION_RESTRICTION_REPORT)}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(COMPARISON_CONFIGURATION_HAS_DISTANCE_LIMITATION) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="has_distance_limitation"
                                            label={t('has_distance_limitation')}
                                            isChecked={data.restrictionPolicy.optn117215}
                                            setIsChecked={(value) => _handleDataChange("optn117215", value)}
                                            disabled={isDisabled(COMPARISON_CONFIGURATION_HAS_DISTANCE_LIMITATION)}
                                        />
                                    </div>
                                </div>
                            }

                            {(!isDisabled(DISTANCE_COMPARISON_CONFIGURATION_DISTANCE_KILOMETERS) && data.restrictionPolicy.optn117215) &&
                                <div className="w100">
                                    <label className='form-label'>{t('distance_kilometers')}</label>
                                    <FrmDecimalInput
                                        nullable
                                        id="distance_kilometers"
                                        value={data.restrictionPolicy.optn117216}
                                        onBlur={(value) => _handleDataChange('optn117216', value)}
                                        disabled={isDisabled(DISTANCE_COMPARISON_CONFIGURATION_DISTANCE_KILOMETERS) || !data.restrictionPolicy.optn117215}
                                    />
                                </div>
                            }

                            {data.usageLevel == POLICY_LVL_SELF_SERVICE &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="show-all-in-map"
                                            label={t('show_all_in_map')}
                                            isChecked={data.restrictionPolicy.showAllInMap}
                                            setIsChecked={(value) => _handleDataChange("showAllInMap", value)}
                                            disabled={data.usageLevel != POLICY_LVL_SELF_SERVICE}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(CAN_UPDATE_ALL_THE_COPIED_TRANSACTIONS) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        <Checkbox
                                            id="update-on-evaluation-policy"
                                            label={t('update_on_evaluation_policy')}
                                            isChecked={data.restrictionPolicy.optn117225}
                                            setIsChecked={(value) => _handleDataChange("optn117225", value)}
                                        />
                                    </div>
                                </div>
                            }

                            {!isDisabled(IGNORE_PLANED_AND_ESTIMATION_DATA) &&
                                <div className="w100">
                                    <div className={style.checkboxContainer}>
                                        {/* <label className='form-label'>{t('ignore_planed_and_estimation_date')}</label> */}
                                        <Checkbox
                                            id="ignore-planed-and-estimation-date"
                                            label={t('ignore_planed_and_estimation_date')}
                                            isChecked={data.restrictionPolicy.optn117226}
                                            setIsChecked={(value) => _handleDataChange("optn117226", value)}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='container'>
                        <div className='row rowSmModel'>

                            {!isDisabled(DEFAULT_ORGANIZATION) &&
                                <div className="w100">
                                    <label className='form-label'>{t('default_estimation_organization')}</label>
                                    <FrmSelectMenu
                                        id="default_estimation_organization"
                                        value={data.restrictionPolicy.optn117209}
                                        api={getEvaluationPolicyOrganizations}
                                        descLo={['offecialNameLo']}
                                        descFo={['offecialNameFo']}
                                        onChange={e => _handleDataChange("optn117209", e.value)}
                                        isDisabled={isDisabled(DEFAULT_ORGANIZATION)}
                                        portalTarget={document.body}
                                    />
                                </div>
                            }

                            {data?.restrictionPolicy?.optn117207 &&
                                <div className="w100">
                                    <label id="mobile-no-of-digits-lbl" className='form-label mandatory'>{t('mobile_no_of_digits')}</label>
                                    <FrmDecimalInput
                                        nullable
                                        id="mobile-no-of-digits"
                                        value={data.restrictionPolicy.mobileNumDigits}
                                        onBlur={(value) => _handleDataChange('mobileNumDigits', value)}
                                        disabled={!data.restrictionPolicy.optn117207}
                                    />
                                </div>
                            }

                            {!isDisabled(DEFAULT_VAT_PERCENTAGE) &&
                                <div className="w100">
                                    <label className='form-label'>{t('default_vat')}</label>
                                    <FrmDecimalInput
                                        id="default_vat"
                                        nullable
                                        value={data.restrictionPolicy.defaultVatPerc}
                                        onBlur={(value) => _handleDataChange('defaultVatPerc', value)}
                                        maxValue={100}
                                        disabled={isDisabled(DEFAULT_VAT_PERCENTAGE)}
                                    />
                                </div>
                            }

                            {!isDisabled(QUOTATION_VALIDITY_PERIOD_DAYS) &&
                                <div className="w100">
                                    <label className='form-label'>{t('quotation_validity_period_days')}</label>
                                    <FrmDecimalInput
                                        id="quotation_validity_period_days"
                                        value={data.restrictionPolicy.quotationValidtyDays}
                                        nullable
                                        onBlur={(value) => _handleDataChange('quotationValidtyDays', value)}
                                        disabled={isDisabled(QUOTATION_VALIDITY_PERIOD_DAYS)}
                                    />
                                </div>
                            }

                            {!isDisabled(ESTIMATION_SESSION_TIMEOUT) &&
                                <div className="w100">
                                    <label className='form-label'>{t('estimation_session_timeout')}</label>
                                    <FrmDecimalInput
                                        nullable
                                        id="estimation_session_timeout"
                                        value={data.restrictionPolicy.estimationSessionTimeout}
                                        onBlur={(value) => _handleDataChange('estimationSessionTimeout', value)}
                                        disabled={isDisabled(ESTIMATION_SESSION_TIMEOUT)}
                                    />
                                </div>
                            }

                            {!isDisabled(COMPARISON_CONFIGURATION_ZOOM) &&
                                <div className="w100">
                                    <label className='form-label'>{t('zoom')}</label>
                                    <FrmDecimalInput
                                        nullable
                                        id="zoom"
                                        value={data.restrictionPolicy.optn117214}
                                        onBlur={(value) => _handleDataChange('optn117214', value)}
                                        disabled={isDisabled(COMPARISON_CONFIGURATION_ZOOM)}
                                    />
                                </div>
                            }

                            {!isDisabled(ESTIMATION_FEES_ITEM) &&
                                <div className="w100">
                                    <label className='form-label'>{t('organization_estimation_fees')}</label>
                                    <FrmSelectMenu
                                        id="organization_estimation_fees"
                                        key={`organization_estimation_fees_${data?.id}`}
                                        value={data.restrictionPolicy.organizationFeesCdaId}
                                        api={() => getAdditionalItemsLov(null, isEmpty(data.restrictionPolicy.organizationFeesCdaId) ? [] : [data.restrictionPolicy.organizationFeesCdaId], STATUS_ACTIVE, [STATUS_ORGANIZATION_ESTIMATION_FEES])}
                                        descLo={['descLo']}
                                        descFo={['descFo']}
                                        onChange={e => _handleDataChange("organizationFeesCdaId", e.value)}
                                        portalTarget={document.body}
                                        isDisabled={isDisabled(ESTIMATION_FEES_ITEM)}
                                    />
                                </div>
                            }

                            {!isDisabled(ESTIMATOR_FEES) &&
                                <div className="w100">
                                    <label className='form-label'>{t('estimator_fees')}</label>
                                    <FrmSelectMenu
                                        id="estimator_fees"
                                        key={`estimator_fees_${data?.id || ''}`}
                                        value={data.restrictionPolicy.estimatorFeesCdaId}
                                        api={() => getAdditionalItemsLov(null, isEmpty(data.restrictionPolicy.estimatorFeesCdaId) ? [] : [data.restrictionPolicy.estimatorFeesCdaId], STATUS_ACTIVE, [STATUS_ESTIMATOR_FEES])}
                                        descLo={['descLo']}
                                        descFo={['descFo']}
                                        onChange={e => _handleDataChange("estimatorFeesCdaId", e.value)}
                                        portalTarget={document.body}
                                        isDisabled={isDisabled(ESTIMATOR_FEES)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RestrictionPolicy;