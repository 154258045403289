import { useTranslation } from "react-i18next";
import UploadImg from "../../../components/upload-img";
import { PROPERTIES_PARTIES_DEFINITION } from "../../../util/constants";
import style from './style.module.scss';

const Tab7 = (props) => {
    const { tab, data, attachmentsImgRef } = props;
    const { t } = useTranslation();

    return (
        <div className={tab == 7 ? 'active' : ''}>
            <div className='container' >
                <UploadImg
                    ref={attachmentsImgRef}
                    isMulti={true}
                    customStyle={style.uploudSignatur}
                    title={t("upload")}
                    masterId={data.id}
                    formId={PROPERTIES_PARTIES_DEFINITION}
                />
            </div>
        </div>
    )
}

export default Tab7;