import { useEffect, useState } from "react";


const Image = (props) => {

    const { src, alt, className, isCustomer } = props;
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false)
    }, [src])

    return (
        <img
            src={error || !src ? isCustomer ? './images/avatar.png' : './images/no-image-found.png' : src}
            alt={alt || ''}
            className={className || ''}
            onError={() => setError(true)}
        />
    )
}

export default Image;