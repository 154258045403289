import { formatDate, formatMoney, handleError } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PrintDialog from "../../../components/print-dialog";
import Slisting from "../../../components/slisting";
import { deleteEstimationInvoice, getEstimationInvoices } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Actions from "./actions";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, printDialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.customersEstimationInvoicesRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:invoice_num')}</label>
                <label>{data.code}</label>
            </div>

            <div title={formatDate(data.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:invoice_date')}</label>
                <label>{formatDate(data.dateDgr)}</label>
            </div>

            <div title={i18next.language == "en" ? data.customerNameLo : data.customerNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:customer')}</label>
                <label>{i18next.language == "en" ? data.customerNameLo : data.customerNameFo}</label>
            </div>

            <div title={`${i18next.language == "en" ? data.branchLo : data.branchFo} - ${i18next.language == "en" ? data.divisionLo : data.divisionFo}`} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:branch_division')}</label>
                <label>{`${i18next.language == "en" ? data.branchLo : data.branchFo} - ${i18next.language == "en" ? data.divisionLo : data.divisionFo}`}</label>
            </div>

            <div title={formatMoney(data.invoiceNetAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:net_price')}</label>
                <label>{formatMoney(data.invoiceNetAlc)}</label>
            </div>

            <div title={data.rem} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:remarks')}</label>
                <label>{data.rem}</label>
            </div>

            <div title={i18next.language == "en" ? data.statusLo : data.statusFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:status')}</label>
                <label className={`status-${data.globalStatus}`}>{i18next.language == "en" ? data.statusLo : data.statusFo}</label>
            </div>

            <div>
                <Actions user={user} data={data} tableRef={tableRef} printDialogRef={printDialogRef} />
            </div>
        </div>
    );
};

const CustomersEstimationInvoicesTable = (props) => {

    const { customersEstimationInvoicesTableRef, searchTextRef, filtersRef } = props;

    const { t } = useTranslation();
    const user = getUser();
    const printDialogRef = useRef();
    const navigate = useNavigate();

    const [estimationInvoices, setEstimationInvoices] = useState();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('customersEstimationInvoices:invoice_num'), width: '14%' },
        { title: t('customersEstimationInvoices:invoice_date'), width: '10%' },
        { title: t('customersEstimationInvoices:customer'), width: '10%' },
        { title: t('customersEstimationInvoices:branch_division'), width: '14%' },
        { title: t('customersEstimationInvoices:net_price'), width: '12%' },
        { title: t('customersEstimationInvoices:remarks'), width: '12%' },
        { title: t('customersEstimationInvoices:status'), width: '11%' },
        { title: "", width: '12%' },
    ];

    const _fetchData = (pageNumber, pageSize) => {
        const filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            ...filtersRef.current.getFilterData(),
        }
        getEstimationInvoices(pageNumber, pageSize, filterData)
            .then((response) => {
                setEstimationInvoices(response.data)
            })
            .catch((error) => handleError(error, null, t))
    }

    return (
        <>
            <Slisting
                id="customers-estimation-invoices-table"
                ref={customersEstimationInvoicesTableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.customersEstimationInvoicesTable}
                getData={_fetchData}
                rowHeight={80}
                rowActions={{ user, tableRef: customersEstimationInvoicesTableRef, printDialogRef }}
                pagingData={estimationInvoices}
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                newAction={() => navigate("/customers-estimation-invoice")}
                deleteAction={deleteEstimationInvoice}
                viewAction={(id) => navigate(`/customers-estimation-invoice?id=${id}`)}
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                withPrintAction
                cardInMobile
                labelCardWidth="100px"
            />

            <PrintDialog
                ref={printDialogRef}
                id="customer-estimation-invoice-print-dialog"
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                user={user}
            />
        </>
    );
};

export default CustomersEstimationInvoicesTable;