import React from "react";

export function RotatIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.283"
            height="27.394"
            viewBox="0 0 27.283 27.394"
        >
            <g
                fill="#1eacae"
                data-name="Group 178579"
                transform="translate(-407.999 -325.999)"
            >
                <path
                    d="M-9760.043-8227.845a.949.949 0 01.677-1.163 11.743 11.743 0 008.744-11.35 11.751 11.751 0 00-11.737-11.74 13.617 13.617 0 00-8.857 3.264 16.275 16.275 0 00-2.047 2.031h3.851a.95.95 0 01.949.952.951.951 0 01-.949.952h-5.636a.954.954 0 01-.952-.952v-.072-5.855a.952.952 0 01.952-.952.951.951 0 01.952.952v3.047a17.912 17.912 0 011.609-1.519 15.669 15.669 0 0110.128-3.751 13.545 13.545 0 019.664 4 13.557 13.557 0 013.978 9.645 13.648 13.648 0 01-10.163 13.189.908.908 0 01-.242.032.952.952 0 01-.921-.71z"
                    data-name="Union 365"
                    transform="translate(10184 8580)"
                ></path>
                <path
                    d="M11.994 13.812h-.067a13.568 13.568 0 01-3.98-.891.952.952 0 01.691-1.774 11.666 11.666 0 003.422.766.952.952 0 01-.066 1.9zM5 11.092a.948.948 0 01-.592-.207A13.705 13.705 0 011.633 7.9.952.952 0 013.2 6.828 11.8 11.8 0 005.6 9.4a.952.952 0 01-.6 1.692zM.766 4.9a.952.952 0 01-.909-.671A13.63 13.63 0 01-.75.2a.952.952 0 011.9 0 11.729 11.729 0 00.522 3.469A.952.952 0 01.766 4.9z"
                    opacity="0.4"
                    transform="translate(408.75 339.581)"
                ></path>
            </g>
        </svg>
    );
}

export function RotatImageIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.283"
            height="27.25"
            viewBox="0 0 27.283 27.25"
        >
            <g
                fill="#a7a7a7"
                data-name="Group 178577"
                transform="translate(-611.585 -470.359)"
            >
                <circle
                    cx="2"
                    cy="2"
                    r="2"
                    data-name="Ellipse 184"
                    transform="translate(623.198 482)"
                ></circle>
                <path
                    d="M-9760.043-8227.845a.949.949 0 01.677-1.163 11.743 11.743 0 008.744-11.35 11.751 11.751 0 00-11.737-11.74 13.617 13.617 0 00-8.857 3.264 16.249 16.249 0 00-1.925 1.889h3.729a.95.95 0 01.949.952.95.95 0 01-.949.952h-5.636a.948.948 0 01-.68-.287l-.007-.006-.02-.021-.009-.01-.014-.017-.011-.013-.014-.018-.008-.01-.017-.022-.007-.01-.014-.021-.006-.009-.015-.023v-.008l-.016-.028-.031-.064a.83.83 0 01-.039-.107 1.027 1.027 0 01-.026-.111v-.037V-8245.941v-5.821a.952.952 0 01.952-.952.951.951 0 01.952.952v3.047a17.912 17.912 0 011.609-1.519 15.669 15.669 0 0110.128-3.751 13.545 13.545 0 019.664 4 13.557 13.557 0 013.978 9.645 13.648 13.648 0 01-10.163 13.189.908.908 0 01-.242.032.952.952 0 01-.939-.726z"
                    data-name="Union 363"
                    transform="translate(10387.586 8724.359)"
                ></path>
                <path
                    d="M11.994 13.812h-.067a13.568 13.568 0 01-3.98-.891.952.952 0 01.691-1.774 11.666 11.666 0 003.422.766.952.952 0 01-.066 1.9zM5 11.092a.948.948 0 01-.592-.207A13.705 13.705 0 011.633 7.9.952.952 0 013.2 6.828 11.8 11.8 0 005.6 9.4a.952.952 0 01-.6 1.692zM.766 4.9a.952.952 0 01-.909-.671A13.63 13.63 0 01-.75.2a.952.952 0 011.9 0 11.729 11.729 0 00.522 3.469A.952.952 0 01.766 4.9z"
                    opacity="0.4"
                    transform="translate(612.339 483.798)"
                ></path>
            </g>
        </svg>
    );
}
