import NextPreviousButtons from '@ipgd-gauge/next-prev-records-panel';
import { useDispatch } from "react-redux";
import { saveCurrentRecordId } from '../../store/formSelectedRecords/actions';
import { getFormId } from "../../util/formUtil";

const NextPrevBtns = (props) => {
    const { customStyle, nextPreviousAction, selectedIdsList, selectedId } = props;

    const dispatch = useDispatch();
    const formId = getFormId();

    return (
        <NextPreviousButtons
            customStyle={customStyle}
            nextPreviousAction={nextPreviousAction}
            formId={formId}
            changeCurrentRecordId={(newId) => {
                dispatch(saveCurrentRecordId(formId, newId))
            }}
            selectedIdsList={selectedIdsList} //optional
            selectedId={selectedId} //optional
        />
    );
};

export default NextPrevBtns;