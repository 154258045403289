import SidePanelComponent from "@ipgd-gauge/side-panel";
import { forwardRef, useRef } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from "react-redux";
import { saveCurrentRecordId, saveSelectedRecords } from "../../store/formSelectedRecords/actions";
import { SAVE_ACTION_SHORT_CUT } from "../../util/constants/hotkeys";
import LogDateDialog from "../../views/common/dialogs/log-date";

const SidePanel = (props, ref) => {

    const { id, title, formId, children, preClose, cancelBtnAction, postClose, className, width, customHeader, contentStyle, withFooter, footerLbl, footerAction, footerStyle, icon, customFooter, preOpen, nextPreviousAction, showLog, user, masterId } = props

    const dispatch = useDispatch();
    useHotkeys(footerAction ? SAVE_ACTION_SHORT_CUT : "", footerAction ? footerAction : "");

    const logDialogRef = useRef();

    return (
        <>
            <SidePanelComponent
                ref={ref}
                id={id}
                title={title}
                formId={formId}
                children={children}
                preClose={preClose}
                cancelBtnAction={cancelBtnAction}
                postClose={postClose}
                className={className}
                width={width}
                customHeader={customHeader}
                contentStyle={contentStyle}
                withFooter={withFooter}
                footerLbl={footerLbl}
                footerAction={footerAction}
                footerStyle={footerStyle}
                icon={icon}
                customFooter={customFooter}
                preOpen={preOpen}
                nextPreviousAction={nextPreviousAction}
                changeCurrentRecordId={(newId) => dispatch(saveCurrentRecordId(formId, newId))}
                clearSelectedRecords={() => dispatch(saveSelectedRecords(formId, []))}
                saveHotKeyShortCut={SAVE_ACTION_SHORT_CUT}
                showLog={showLog}
                masterId={masterId}
                showLogAction={() => logDialogRef?.current?.open(masterId)}
            />
            {showLog &&
                <LogDateDialog ref={logDialogRef} user={user} formId={formId} />
            }
        </>
    )
}

export default forwardRef(SidePanel);