import Modal from "@ipgd-gauge/modal";
import Spinner from "@ipgd-gauge/spinner";
import { formatDateNTime, handleError, hideWaiting, isEnglish, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GRating from "../../../components/g-rating";
import NoDataFound from "../../../components/no-data-found";
import Waiting from "../../../components/waiting";
import { RecoveryTrxIcon } from "../../../icons/EstimationTrx";
import LogChatIcon from "../../../icons/LogChat";
import PlusIcon from "../../../icons/PlusIcon";
import XIcon from "../../../icons/X";
import { deleteTransactionRate, getRatingLogForTransaction, undoDeleteTransactionRate } from "../../../util/apis";
import style from './style.module.scss';

const SpecialNotesCard = (props) => {
    const { rate, deleteRate, undoDeleteRate } = props;

    const _executeAction = () => {
        if (rate.isDeleted == 1) {
            undoDeleteRate(rate.id);
        } else {
            deleteRate(rate.id);
        }
    }

    return (
        <div className={style.sendAddNote}>
            <div className={style.senderContainer}>
                <button className={style.deleteBtn} onClick={_executeAction}>
                    {rate.isDeleted == 1 ? <RecoveryTrxIcon fill="var(--secondary-2)" /> : <XIcon />}
                    <Spinner id={rate.isDeleted == 1 ? `undo-delete-btn-spinner-${rate.id}` : `delete-btn-spinner-${rate.id}`} className={style.deleteSpinner} />
                </button>
                <div className={style.senderIcon}>
                    <LogChatIcon />
                </div>

                <div className={style.chatDataContainer}>
                    <div className={style.userInfo}>
                        <div className={style.userInfoName}>
                            <label>
                                {isEnglish() ? rate.descLo : rate.descFo}
                            </label>
                        </div>
                        <div className={style.userInfoDate}>
                            <label>{formatDateNTime(rate.estimationDateTime)}</label>
                        </div>
                    </div>
                    <div>
                        <div className={`${style.specialNotesRating}${rate.isDeleted == 1 ? " deleted" : ""}`}>
                            <div></div>
                            <GRating
                                value={rate.rate}
                                readonly
                            />
                        </div>
                    </div>
                    <div className={rate.isDeleted == 1 ? style.inputChatDisabled : style.inputChat}>
                        <div className={rate.isDeleted == 1 ? style.dataMsgDisabled : style.dataMsgActive}>
                            {rate.comments}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const RatesLogDialog = (props, ref) => {
    const { tableRef, estimationRatingDialogRef } = props;
    const ratesLogDialogRef = useRef();
    const transactionIdRef = useRef(null);
    const makeRefreshForTableRef = useRef(false);
    const { t } = useTranslation();

    const [rates, setRates] = useState(null);

    const _deleteRate = (rateId) => {
        showWaiting(`delete-btn-spinner-${rateId}`, true);
        makeRefreshForTableRef.current = true;
        deleteTransactionRate(rateId)
            .then(() => {
                hideWaiting(`delete-btn-spinner-${rateId}`);
                rates.forEach(rate => {
                    if (rate.id == rateId) {
                        rate.isDeleted = 1;
                    }
                });
                setRates([...rates]);
            })
            .catch(() => {
                hideWaiting(`delete-btn-spinner-${rateId}`);
                handleError(null, 'delete_fields', t);
            });
    }

    const _undoDeleteRate = (rateId) => {
        showWaiting(`undo-delete-btn-spinner-${rateId}`, true);
        makeRefreshForTableRef.current = true;
        undoDeleteTransactionRate(rateId)
            .then(() => {
                hideWaiting(`undo-delete-btn-spinner-${rateId}`);
                rates.forEach(rate => {
                    if (rate.id == rateId) {
                        rate.isDeleted = 0;
                    }
                });
                setRates([...rates]);
            })
            .catch(() => {
                hideWaiting(`undo-delete-btn-spinner-${rateId}`);
                handleError(null, null, t);
            });
    }

    const _preClose = () => {
        if (makeRefreshForTableRef.current) {
            tableRef?.current?.refresh();
        }
    }

    const _postClose = () => {
        setRates(null);
        transactionIdRef.current = null;
        makeRefreshForTableRef.current = false;
    }

    const _getData = () => {
        setRates(null);
        getRatingLogForTransaction(transactionIdRef.current)
            .then((response) => setRates(response.data))
            .catch(() => setRates([]));
    }

    const refresh = () => {
        makeRefreshForTableRef.current = true;
        _getData();
    }

    const open = (transactionId) => {
        transactionIdRef.current = transactionId;
        _getData();
        ratesLogDialogRef?.current?.open();
    }

    const close = () => {
        ratesLogDialogRef?.current?.close();
    }

    useImperativeHandle(ref, () => ({ open, close, refresh }));

    return (
        <Modal
            id="rates-log-dialog"
            ref={ratesLogDialogRef}
            title={t('estimationTransaction:rates_log')}
            withFooter="true"
            withCancelButton="true"
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.estimationTransactionDialog}
            preClose={_preClose}
            postClose={_postClose}
            smallSize
        >
            <div className={style.containerSpecialNotesCard}>
                {rates == null ?
                    <Waiting />
                    :
                    rates?.length == 0 ?
                        <NoDataFound />
                        :
                        <>
                            {rates.map(rate => <SpecialNotesCard rate={rate} deleteRate={_deleteRate} undoDeleteRate={_undoDeleteRate} />)}
                            <div className={style.addRateContainer}>
                                <button
                                    className="primary-button"
                                    onClick={() => estimationRatingDialogRef?.current?.open(transactionIdRef.current, true)}
                                >
                                    <span><PlusIcon strokeWidth="1.25" /></span>
                                    {t('add_rate')}
                                </button>
                            </div>
                        </>
                }
            </div>
        </Modal>
    )
}

export default forwardRef(RatesLogDialog);