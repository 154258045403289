import React from "react";

function OpeningFavIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.16"
      height="21.28"
      viewBox="0 0 22.16 21.28"
    >
      <path
        fill="#212121"
        d="M172.627 21.875a2.09 2.09 0 01-1.232-.408l-3.954-2.873a.614.614 0 00-.727 0l-3.954 2.873a2.1 2.1 0 01-3.226-2.343l1.51-4.648a.62.62 0 00-.225-.693l-3.954-2.872a2.1 2.1 0 011.235-3.792h4.887a.617.617 0 00.589-.428l1.51-4.648a2.1 2.1 0 013.987 0l1.51 4.648a.617.617 0 00.589.428h4.887a2.1 2.1 0 011.233 3.791l-3.954 2.873a.619.619 0 00-.225.692l1.51 4.648a2.067 2.067 0 01-.761 2.344 2.1 2.1 0 01-1.233.408M167.077 17a2.089 2.089 0 011.232.4l3.953 2.873a.62.62 0 00.953-.693l-1.51-4.648a2.092 2.092 0 01.761-2.343l3.954-2.873a.618.618 0 00-.364-1.119h-4.886a2.092 2.092 0 01-1.993-1.448L167.666 2.5a.619.619 0 00-1.177 0l-1.51 4.649a2.092 2.092 0 01-1.994 1.451H158.1a.618.618 0 00-.364 1.119l3.954 2.873a2.092 2.092 0 01.761 2.343l-1.51 4.648a.619.619 0 00.953.692l3.953-2.873a2.089 2.089 0 011.232-.4"
        data-name="Path 6433"
        transform="translate(-155.997 -.595)"
      ></path>
    </svg>
  );
}

export default OpeningFavIcon;