import ActionsButton from "@ipgd-gauge/actions-button";
import Image from '@ipgd-gauge/image';
import { addUrlParam, deleteUrlParam, fixImagePath, formatMoney, getUrlParam, handleError, isEmpty, parseNumber } from '@ipgd-gauge/utils';
import i18next from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import FormTitle from '../../components/form-title';
import Map from '../../components/map';
import MapCard from '../../components/map-card';
import NoDataFound from '../../components/no-data-found';
import QuickSearch from '../../components/quick-search';
import Waiting from '../../components/waiting';
import ChevronLeft from '../../icons/ChevronLeft';
import ChevronRight from '../../icons/ChevronRight';
import FiltersIcon from '../../icons/Filters';
import ListIcon from '../../icons/List';
import LocationMapIcon from '../../icons/LocationMap';
import { CondominiumsIcon, LandIcon } from '../../icons/MapPinType';
import MarkerIcon from '../../icons/Marker';
import PinMapIcon from '../../icons/PinMap';
import SettingsBtnIcon from "../../icons/SettingsBtn";
import UserMapIcon from '../../icons/UserMap';
import { executeListAction, getEstimationTrxsMapList } from '../../util/apis';
import { ACTION_PRINT_PDF, PROPERTY_TYPE_FLAT, PROPERTY_TYPE_GENERAL, PROPERTY_TYPE_LAND } from '../../util/constants';
import { RSM3234_PROPERTIES_MAP_FORM_ID } from '../../util/constants/forms';
import { getToken, getUser } from "../../util/cookies";
import { formatDateNTime } from '../../util/dateUtil';
import SummaryDialog from '../common/dialogs/summary-dialog';
import ChooseReport from "../estimation-transactions/dialogs/ChooseReport";
import MapsFilter from './Filters';
import ComparisonConfigurationDialog from './dialog/ComparisonConfigurationDialog';
import style from './style.module.scss';

const ORDER_BY_OLDEST = 'OLDEST';
const ORDER_BY_CHEAPEST = 'CHEAPEST';
const ORDER_BY_HIGHEST = 'HIGHEST';

const CardsList = (props) => {
    const { mapData, getData, orderBy, setOrderBy, showWaiting, mapRef, formCardsRef, triIdRef, isTab } = props;
    const { t } = useTranslation('estimationMap');
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const _handlePageClick = (event) => {
        if (event && event.selected >= 0) {
            getData(event.selected);
            document.getElementById(`map-cards`)?.scrollTo(0, 0);
        }
    }

    const _handleCardClick = (data) => {
        formCardsRef.current = true;
        let pin = { lat: data.latitude, lng: data.longitude, zoom: 10 }
        mapRef.current.setPin(pin);
    }

    return (
        <div className={`form-templates-panel ${style.mapTemplates}`}>
            <div className='container'>
                <div className={style.orderByButtonContainer} >
                    <button
                        key={'newest'}
                        className={!orderBy ? style.activeOrderByButton : style.inactiveOrderByButton}
                        onClick={() => setOrderBy()}
                    >
                        {t('newest')}
                    </button>
                    <button
                        key={'oldest'}
                        className={orderBy == ORDER_BY_OLDEST ? style.activeOrderByButton : style.inactiveOrderByButton}
                        onClick={() => setOrderBy(ORDER_BY_OLDEST)}
                    >
                        {t('oldest')}
                    </button>
                    <button
                        key={'cheapest'}
                        className={orderBy == ORDER_BY_CHEAPEST ? style.activeOrderByButton : style.inactiveOrderByButton}
                        onClick={() => setOrderBy(ORDER_BY_CHEAPEST)}
                    >
                        {t('cheapest')}
                    </button>
                    <button
                        key={'highest'}
                        className={orderBy == ORDER_BY_HIGHEST ? style.activeOrderByButton : style.inactiveOrderByButton}
                        onClick={() => setOrderBy(ORDER_BY_HIGHEST)}
                    >
                        {t('highest')}
                    </button>
                </div>
                <div className={` ${style.cardsContainer} ${isTab ? style.cardsContainerTab : ""}`} id='map-cards'>
                    {showWaiting ?
                        <Waiting />
                        :
                        mapData?.list?.length > 0 ?
                            mapData?.list?.map((data, index) => {
                                const mapCardId = `pin-map-card-${data.estimationTrxId ? data.estimationTrxId : data.estimationHistoryId}`;
                                return (
                                    <div key={index}
                                        className={`w100 ${triIdRef?.current && index == 0 ? style.comperdCardContainer : style.cardContainer}`}
                                        onClick={() => _handleCardClick(data)}
                                        onMouseOver={() => document.getElementById(mapCardId).classList.add('active')}
                                        onMouseLeave={() => document.getElementById(mapCardId).classList.remove('active')}
                                    >
                                        <div className={style.sectionOne}>
                                            <div className={style.bgImage}>
                                                <div className={style.cardImg}>
                                                    <Image src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.image)}`} />
                                                </div>
                                            </div>
                                            <div title={i18next.language == 'en' ? data.descLo : data.descFo} className={` ${style.statusCard} ${data?.propertyType == PROPERTY_TYPE_GENERAL ? style.office : ""} ${data?.propertyType == PROPERTY_TYPE_FLAT ? style.appar : ""} `}>{i18next.language == 'en' ? data.descLo : data.descFo}</div>
                                            <div title={formatDateNTime(data.dateTime)} className={style.dateCard}> {formatDateNTime(data.dateTime)}</div>
                                            <div className={style.contentCard}>
                                                <div className={` ${style.detailsMapsCard} ${style.UserMap}`}>
                                                    <div title={data.code}>
                                                        <label className={` ${style.mainText} ${style.codeCard}`} >{data.code}</label>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <UserMapIcon />
                                                        </span>
                                                        <label title={i18next.language === 'en' ? data.officialNameLo : data.officialNameFo} className={style.mainText} >{i18next.language === 'en' ? data.officialNameLo : data.officialNameFo}</label>
                                                    </div>
                                                </div>
                                                <div className={style.detailsMapsCard}>
                                                    <div>
                                                        <label title={formatMoney(data.evaluationPrice)} className={style.price} >{formatMoney(data.evaluationPrice)}</label>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <LocationMapIcon />
                                                        </span>
                                                        <label title={i18next.language == 'en' ? data.addressLo : data.addressFo} className={style.locationLbl}>{i18next.language == 'en' ? data.addressLo : data.addressFo}</label>
                                                    </div>
                                                </div>
                                                <div className={style.landsDetailsMapsCard}>
                                                    <div>
                                                        <label title={`${t('land')} ${data.landArea} ${i18next.language == "en" ? 'M' : 'م'}`}>{`${t('land')} ${data.landArea} ${i18next.language == "en" ? 'M' : 'م'}`} <sup>2</sup></label>
                                                        <label title={` ${formatMoney(data.parcelPricePerM)} ${(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}`}>{` ${formatMoney(data.parcelPricePerM)} ${(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}`} </label>
                                                    </div>
                                                    <div>
                                                        <label title={`${t('building')} ${data.buildingArea} ${i18next.language == "en" ? 'M' : 'م'}`}>
                                                            {`${t('building')} ${data.buildingArea} ${i18next.language == "en" ? 'M' : 'م'}`}
                                                            <sup>2</sup>
                                                        </label>
                                                        <label title={` ${formatMoney(data.buildMeterValue)} ${(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}`}>
                                                            {` ${formatMoney(data.buildMeterValue)} ${(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}`}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label title={`${t('parcel_no')} ${data.parcelNo || ''}`}>{`${t('parcel_no')} ${data.parcelNo || ''}`}</label>
                                                        <label title={`${t('plan_no')} ${data.planNo || ''}`}>{`${t('plan_no')} ${data.planNo || ''}`}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className={style.containerNoDataFound}>
                                <NoDataFound />
                            </div>
                    }
                </div>
                <label id='total-items-map-data' title={mapData.totalItems} style={{ display: 'none' }}>{mapData.totalItems}</label>
                {mapData?.totalItems >= 0 &&
                    <ReactPaginate
                        previousLabel={<ChevronLeft />}
                        nextLabel={<ChevronRight />}
                        breakLabel={<label>. . .</label>}
                        breakClassName={style.breakLabel}
                        className={style.pagination}
                        pageCount={mapData.totalPages}
                        onPageChange={_handlePageClick}
                        activeClassName={style.activeButton}
                        previousLinkClassName={style.next_prev_button}
                        nextLinkClassName={style.next_prev_button}
                        disabledClassName={style.next_prev_disabled}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={0}
                        initialPage={0}
                        forcePage={mapData.currentPage}
                        renderOnZeroPageCount={null}
                    />
                }
            </div>
        </div>
    )
}

const MapPinCard = (props) => {
    const { user, data, triIdRef, chooseReportDialogRef } = props;
    const mapCardId = `pin-map-card-${data.estimationTrxId ? data.estimationTrxId : data.estimationHistoryId}`;

    return (
        <div
            className={style.estimationMapPin}
            onMouseOver={() => document.getElementById(mapCardId).classList.add('active')}
            onMouseLeave={() => document.getElementById(mapCardId).classList.remove('active')}
        >
            <div>
                {triIdRef.current && triIdRef.current == data.estimationTrxId &&
                    <span className={style.starPinIcon}>
                        <PinMapIcon />
                    </span>
                }
                {triIdRef.current != data.estimationTrxId && data.propertyType == PROPERTY_TYPE_LAND &&
                    <LandIcon />
                }
                {triIdRef.current != data.estimationTrxId && data.propertyType != PROPERTY_TYPE_LAND &&
                    <CondominiumsIcon />
                }
                {data.estimationHistoryId && data.propertyType == PROPERTY_TYPE_LAND &&
                    <LandIcon isHistory={data.estimationHistoryId} />
                }
                {data.estimationHistoryId && data.propertyType != PROPERTY_TYPE_LAND &&
                    <CondominiumsIcon isHistory={data.estimationHistoryId} />
                }
            </div>
            <MapCard
                id={mapCardId}
                data={data}
                withPrintAction={user.estimationPolicy?.actions?.indexOf(`${ACTION_PRINT_PDF}`) !== -1}
                chooseReportDialogRef={chooseReportDialogRef} />
        </div>
    )
}

const EstimationMap = ({ isTab }) => {
    const { t } = useTranslation("estimationMap");

    const searchTextRef = useRef();
    const filterDialogRef = useRef(null);
    const mapRef = useRef(null);
    const firstTimeRef = useRef(true);
    const enablePaginationRef = useRef(false);
    const formCardsRef = useRef(false);
    const intervalRef = useRef();
    const triIdRef = useRef();
    const comparisonConfigurationDialogRef = useRef();
    const summaryDialogRef = useRef();
    const chooseReportDialogRef = useRef();
    const user = getUser();

    const [mapData, setMapData] = useState({});
    const [mapInfo, setMapInfo] = useState({});
    const [orderBy, setOrderBy] = useState();
    const [comperMode, setComperMode] = useState(false);
    const [showWaiting, setShowWaiting] = useState(true);

    const _getMapData = (pageNumber) => {
        if (!triIdRef.current) {
            setShowWaiting(true);
        }
        let mapData = mapRef.current?.getMapData();
        let data = {
            searchValue: searchTextRef?.current?.getSearchText(),
            orderBy: orderBy,
            triId: triIdRef.current,
            trxKey: triIdRef.current ? (getUrlParam('k') || null) : null,
            zoom: mapData?.zoom || 4,
            minLat: mapData?.bounds?.sw?.lat,
            minLng: mapData?.bounds?.sw?.lng,
            maxLat: mapData?.bounds?.ne?.lat,
            maxLng: mapData?.bounds?.ne?.lng,
            ...filterDialogRef?.current?.getFilterData(),
            mapPage: true
        }

        getEstimationTrxsMapList(pageNumber, 50, data)
            .then((response) => {
                setMapData(response.data || {});
                setComperMode(triIdRef.current && !comperMode);
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => setShowWaiting(false));
    }

    useEffect(() => {
        if (Object.keys(mapInfo).length > 0 && !firstTimeRef.current) {
            _getMapData(0);
        }
    }, [orderBy]);

    // useEffect(() => {
    //     if (Object.keys(mapInfo).length > 0) {
    //         if (firstTimeRef.current) {
    //             let comparisonId = getUrlParam("comp");
    //             if (comparisonId) {
    //                 triIdRef.current = comparisonId;
    //             }
    //             firstTimeRef.current = false;
    //             _getMapData(0);
    //         } else if (!formCardsRef.current) {
    //             clearTimeout(intervalRef.current)
    //             intervalRef.current = setTimeout(() => _getMapData(0), 1000)
    //         } else {
    //             formCardsRef.current = false;
    //         }
    //     }
    // }, [mapInfo]);

    useEffect(() => {
        if (Object.keys(mapInfo).length > 0) {
            if (!formCardsRef.current && !firstTimeRef.current) {
                clearTimeout(intervalRef.current)
                intervalRef.current = setTimeout(() => _getMapData(0), 1000)
            } else {
                formCardsRef.current = false;
            }
        }
    }, [mapInfo]);

    const _doComparisonAction = (data) => {
        triIdRef.current = data.triId;
        let mapData = mapRef.current?.getMapData();
        if (triIdRef.current) {
            setShowWaiting(true);
            addUrlParam("comp", triIdRef.current);
            if (parseFloat(data.lat).toFixed(6) == parseFloat(mapData.center.lat).toFixed(6) && parseFloat(data.lng).toFixed(6) == parseFloat(mapData.center.lng).toFixed(6) && data.zoom == mapData.zoom) {
                _getMapData(0);
            } else {
                mapRef.current.setPin({ lat: data.lat, lng: data.lng, zoom: parseNumber(data.zoom || 10) });
                if (parseFloat(data.lat).toFixed(6) == parseFloat(mapData.center.lat).toFixed(6) && parseFloat(data.lng).toFixed(6) == parseFloat(mapData.center.lng).toFixed(6) && data.zoom != mapData.zoom) {
                    setMapInfo({ ...mapInfo, zoom: data.zoom })
                }
            }
        } else {
            deleteUrlParam("comp");
            _getMapData(0);
        }
    }

    const _smCards = () => {
        document.getElementById('estimation-map').classList.toggle(style.estimationMapContainerSm);
        document.getElementById('listAndMapBtn').classList.toggle(style.listAndMapBtn);
    }

    const _fetchData = (page) => {
        if (!firstTimeRef.current && enablePaginationRef.current) {
            _getMapData(page)
        } else {
            enablePaginationRef.current = true;
        }
    }

    const _filterAction = () => {
        let comparisonId = getUrlParam("comp");
        if (comparisonId) {
            triIdRef.current = comparisonId;
        }
        _getMapData(0)
        firstTimeRef.current = false;
    }

    const _onExecutePrintAction = (printedSuccessfully, transactionId, taskId) => {
        if (!printedSuccessfully || isEmpty(transactionId) || isEmpty(taskId)) {
            return;
        }
        return executeListAction({ action: ACTION_PRINT_PDF, trnxId: transactionId, token: getToken(), sseId: taskId })
            .catch(() => { });
    }

    return (
        <>
            <div className="headerPagesPanel">
                {!isTab ? <FormTitle formId={RSM3234_PROPERTIES_MAP_FORM_ID} /> : <div></div>}
                <div className={`headerSearchPanel ${style.headerSearchSm}`}>
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={`${t('code')}, ${t('latitude')}, ${t('longitude')}, ${t('note')}`}
                        search={() => _getMapData(0)}
                        formId={RSM3234_PROPERTIES_MAP_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterDialogRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                    <button id='listAndMapBtn' className={style.toggleBtnSm} onClick={_smCards}>
                        <MarkerIcon />
                        <ListIcon />
                    </button>
                </div>
            </div>

            <div id="estimation-map" className={isTab ? style.estimationMapTabContainer : style.estimationMapContainer}>
                <CardsList
                    filterDialogRef={filterDialogRef}
                    mapData={mapData}
                    getData={_fetchData}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showWaiting={showWaiting}
                    mapRef={mapRef}
                    formCardsRef={formCardsRef}
                    triIdRef={triIdRef}
                    isTab={isTab}
                />

                <div className={`form-templates-panel ${style.tempMap}`}>
                    <div className={`container ${style.mainMap}`}>
                        <Map
                            withSearch
                            value={""}
                            onPinClick={(item) => summaryDialogRef.current.open(item.estimationTrxId, item.estimationHistoryId)}
                            ref={mapRef}
                            data={mapData}
                            MapPinCard={(props) => <MapPinCard {...props} user={user} triIdRef={triIdRef} chooseReportDialogRef={chooseReportDialogRef} />}
                            handleChange={(e) => setMapInfo(e)}
                        />
                    </div>
                </div>
            </div>

            <SummaryDialog ref={summaryDialogRef} formId={RSM3234_PROPERTIES_MAP_FORM_ID} withView />

            <MapsFilter
                ref={filterDialogRef}
                searchAction={_filterAction}
            />

            <ComparisonConfigurationDialog
                comparisonConfigurationDialogRef={comparisonConfigurationDialogRef}
                comparisonAction={_doComparisonAction}
            />

            <ActionsButton actions={[{ id: "compare-map-action", icon: <SettingsBtnIcon fill="var(--secondary)" strokeWidth="2.8" width="13" height="13" />, label: t('comparison'), action: () => comparisonConfigurationDialogRef.current.open() }]} />
            <ChooseReport ref={chooseReportDialogRef} onComplete={_onExecutePrintAction} />
        </>
    );
}

export default EstimationMap;