import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import HijriDate from '../../../components/hijri-date';
import { getCountriesLov, getDomainValues } from "../../../util/apis";
import { DOMAIN_GENDER, STATUS_ACTIVE } from "../../../util/constants";

const Tab3 = (props) => {
    const { tab, data, handleDataChanged } = props;
    const { t } = useTranslation('propertiesPartiesDefinition');

    return (
        <div className={tab == 3 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w33">
                        <label className='form-label'>{t('personal_id')}</label>
                        <FrmInput
                            id="personal_id"
                            value={data?.partyIdCardNum}
                            onBlur={(value) => handleDataChanged('partyIdCardNum', value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('issue_place')}</label>
                        <FrmInput
                            id="issue_place"
                            value={data.idCardIssuePlace}
                            onBlur={(value) => handleDataChanged('idCardIssuePlace', value)}
                        />
                    </div>
                    <div className="w33"></div>
                    <div className="w33">
                        <label className='form-label'>{t('issue_date_g')}</label>
                        <FrmTimeDatePickr
                            id="issue_date_g"
                            value={data.idCardIssueDateDgr}
                            onChange={(value) => handleDataChanged("idCardIssueDateDgr", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('issue_date_h')}</label>
                        <HijriDate
                            id="issue_date_h"
                            hijriDate={data.idCardIssueDateDhi}
                            gregorianDate={data.idCardIssueDateDgr}
                            onChange={(val) => handleDataChanged('idCardIssueDateDhi', val)}
                            disabled
                        />
                    </div>
                    <div className="w33"></div>
                    <div className="w33">
                        <label className='form-label'>{t('expiry_date_g')}</label>
                        <FrmTimeDatePickr
                            id="expiry_date_g"
                            value={data.idCardExpiryDateDgr}
                            onChange={(value) => handleDataChanged("idCardExpiryDateDgr", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('expiry_date_h')}</label>
                        <HijriDate
                            id="expiry_date_h"
                            hijriDate={data.idCardExpiryDateDhi}
                            gregorianDate={data.idCardExpiryDateDgr}
                            onChange={(val) => handleDataChanged('idCardExpiryDateDhi', val)}
                            disabled
                        />
                    </div>
                    <div className="w33"></div>
                    <div className="w33">
                        <label className='form-label'>{t('date_of_birth_g')}</label>
                        <FrmTimeDatePickr
                            id="date_of_birth_g"
                            value={data.dateOfBirthDgr}
                            onChange={(value) => handleDataChanged("dateOfBirthDgr", value)}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('date_of_birth_h')}</label>
                        <HijriDate
                            id="date_of_birth_h"
                            hijriDate={data.dateOfBirthDhi}
                            gregorianDate={data.dateOfBirthDgr}
                            onChange={(val) => handleDataChanged('dateOfBirthDhi', val)}
                            disabled
                        />
                    </div>
                    <div className="w33"></div>
                    <div className="w33">
                        <label className='form-label'>{t('nationality')}</label>
                        <FrmSelectMenu
                            id="nationality"
                            value={data.fcoId}
                            api={() => getCountriesLov([data.fcoId], STATUS_ACTIVE)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            onChange={value => handleDataChanged("fcoId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33">
                        <label className='form-label'>{t('gender')}</label>
                        <FrmSelectMenu
                            id="gender"
                            value={data.gender}
                            api={() => getDomainValues(DOMAIN_GENDER)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("gender", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33"></div>
                    <div className="w100">
                        <label className='form-label'>{t('works_at')}</label>
                        <FrmInput
                            id="works_at"
                            value={data.worksAt}
                            onBlur={(value) => handleDataChanged('worksAt', value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab3;