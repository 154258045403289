import React from "react";

function RotateOptionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.257"
      height="33.233"
      viewBox="0 0 34.257 33.233"
    >
      <g data-name="Group 175576" transform="translate(-72.872 -1033.75)">
        <g
          fill="#ab8860"
          stroke="#fff"
          strokeWidth="0.5"
          data-name="Group 175574"
          transform="translate(-61.619)"
        >
          <path
            d="M151.401 1054.981a4.435 4.435 0 114.435-4.435 4.44 4.44 0 01-4.435 4.435m0-6.079a1.644 1.644 0 101.644 1.644 1.646 1.646 0 00-1.644-1.644"
            data-name="Path 6317"
          ></path>
          <path
            d="M166.367 1063.942h-4.076a16.874 16.874 0 10-27.55-13.063c0 .334.031.659.05.987h2.789c-.024-.328-.05-.653-.05-.987a14.088 14.088 0 1120.968 12.281v-7.142a1.4 1.4 0 10-2.791 0v9.319a1.4 1.4 0 001.4 1.4h9.265a1.4 1.4 0 100-2.791"
            data-name="Path 6318"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default RotateOptionIcon;