import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { FND2621_TOWNS_DEFINITION_FORM_ID } from "../../util/constants/forms";
import TownDialog from "./dialogs/index";
import TownsTable from "./listing";
import TownsFilter from "./listing/Filters";

const Towns = () => {
    const { t } = useTranslation('towns');

    const searchTextRef = useRef();
    const filtersRef = useRef();
    const townsTableRef = useRef();
    const townsDialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={FND2621_TOWNS_DEFINITION_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('towns:city') + ' , ' + t('towns:code') + ' , ' + t('towns:name')}
                        search={() => townsTableRef.current.refresh()}
                        formId={FND2621_TOWNS_DEFINITION_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>
            <TownsTable
                filtersRef={filtersRef}
                townsTableRef={townsTableRef}
                searchTextRef={searchTextRef}
                townsDialogRef={townsDialogRef}
            />
            <TownsFilter
                townsTableRef={townsTableRef}
                ref={filtersRef}
            />
            <TownDialog
                ref={townsDialogRef}
                townsTableRef={townsTableRef}
            />
        </>
    )
}

export default Towns;