import React from "react";

function RejectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.6"
      height="20.6"
      viewBox="0 0 20.6 20.6"
    >
      <g data-name="Group 180027" transform="translate(-618.66 -136.3)">
        <g
          fill="#292d32"
          data-name="Group 179986"
          transform="translate(5559.899 12944.899)"
        >
          <path
            d="M6.771-.75h5.557A4 4 0 0114.868.3L18.8 4.232a4 4 0 011.05 2.539v5.557a4 4 0 01-1.05 2.54L14.868 18.8a4 4 0 01-2.539 1.054H6.771A4 4 0 014.232 18.8L.3 14.868a4 4 0 01-1.05-2.539V6.771A4 4 0 01.3 4.232L4.232.3A4 4 0 016.771-.75zm5.557 19.163a2.648 2.648 0 001.523-.633l3.928-3.928a2.648 2.648 0 00.633-1.523V6.771a2.648 2.648 0 00-.633-1.523L13.852 1.32a2.648 2.648 0 00-1.523-.633H6.771a2.648 2.648 0 00-1.523.633L1.32 5.248a2.648 2.648 0 00-.633 1.523v5.557a2.648 2.648 0 00.633 1.523l3.928 3.929a2.648 2.648 0 001.523.633z"
            transform="translate(-4940.49 -12807.85)"
          ></path>
          <path
            d="M-.031 14.235a.716.716 0 01-.508-.21.719.719 0 010-1.016L13.009-.54a.719.719 0 011.016 0 .719.719 0 010 1.016L.477 14.025a.716.716 0 01-.508.21z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(-4937.673 -12805.033)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default RejectIcon;
