import React from "react";

function LogeDateIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.96 25.83">
      <clipPath>
        <path fill="none" d="M0.48 0.49H23.96V25.83H0.48z"></path>
      </clipPath>
      <g className="cls-3">
        <g fill="#292d32">
          <path d="M9.66 17.31c-.41 0-.75.34-.75.75s.34.75.75.75h5c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-5z"></path>
          <path d="M18.41 14.06c0-.41-.34-.75-.75-.75h-8c-.41 0-.75.34-.75.75s.34.75.75.75h8c.41 0 .75-.34.75-.75"></path>
          <path d="M22.51 5.92c-.91-.99-2.22-1.47-4.26-1.59h-3.84c-.41 0-.75.34-.75.75s.34.75.75.75h3.78c1.18-.07 2.33.32 3.22 1.1.71.77 1.05 2.12 1.05 4.14v7.26h-2.25c-1.86-.22-3.53 1.12-3.75 2.97-.03.26-.03.52 0 .78v2.25h-5.25c-2.2 0-3.57-.4-4.31-1.25-.66-.76-.94-1.96-.94-4.02v-4.63c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4.63c0 1.73.16 3.68 1.31 5.01 1.05 1.2 2.78 1.76 5.44 1.76h6s.03 0 .05-.01c.07 0 .13-.02.19-.04.03 0 .05-.02.07-.03a.66.66 0 00.2-.14l6-6.01s.01-.02.02-.03c.05-.06.09-.12.13-.19.01-.02.01-.04.02-.06.02-.07.04-.14.04-.21V11.06c0-2.44-.46-4.08-1.45-5.15"></path>
        </g>
      </g>
      <path
        fill="#292d32"
        d="M9.65.8A6.497 6.497 0 004.69.27a6.444 6.444 0 00-3.41 2.39v-.44c0-.35-.29-.64-.64-.64S0 1.87 0 2.22v2.35c0 .35.29.64.64.64h2.35c.35 0 .64-.29.64-.64s-.29-.64-.64-.64h-1a5.262 5.262 0 014.23-2.62c1.41-.09 2.76.38 3.81 1.31 1.05.93 1.68 2.22 1.77 3.62a5.22 5.22 0 01-1.31 3.81 5.264 5.264 0 01-9.2-3.17.646.646 0 00-1.13-.37c-.11.13-.17.3-.15.47.23 3.41 3.09 6.08 6.5 6.08 1.74 0 3.38-.68 4.61-1.91s1.91-2.87 1.91-4.61c0-2.39-1.3-4.58-3.39-5.72z"
      ></path>
      <path
        fill="#292d32"
        d="M6.47 2.91c-.32 0-.59.26-.59.59v3.6c0 .21.11.41.3.51L8.7 9.05c.09.05.19.08.29.08a.596.596 0 00.57-.75.592.592 0 00-.28-.36L7.06 6.75V3.49c0-.33-.26-.59-.59-.59z"
      ></path>
    </svg>
  );
}

export default LogeDateIcon;
