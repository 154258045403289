import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import LocalCurrencyInput from "@ipgd-gauge/local-currency";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import ExchangeRateInput from "../../../components/exchange-rate";
import ForeignCurrencyInput from "../../../components/foreign-currency";
import HijriDate from "../../../components/hijri-date";
import { getCommonBranches, getCommonCustomers, getCommonDivisionsList, getCurrenciesLov, getDomainValues } from "../../../util/apis";
import { DATE_LEVEL_DOMAIN, DATE_LEVEL_DOMAIN_ALL, DOMAIN_CUSTOMER_ACCOUNT_TYPE, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import { ACTION_SET_HEADER_VALUE } from "./reducer";
import style from './style.module.scss';

const ID = "customers-estimation-invoice";
const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnBranchCondition';

const Header = (props) => {
    const { user, invoice, dispatchInvoice } = props;

    const { t } = useTranslation("customersEstimationInvoices");

    const customerRef = useRef(true)

    const _handleChange = (name, value) => {
        dispatchInvoice({
            type: ACTION_SET_HEADER_VALUE,
            name: name,
            value: value
        })
    }

    const userPolicy = user.estimationInvoiceLsoId;

    return (
        <>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('header')}
                        </label>
                        <div>
                            <label className={`status-${invoice?.header?.globalStatus}`} >{t(`status_${invoice.header.globalStatus}`)}</label>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w25">
                            <CodeInput
                                id={`${ID}-code`}
                                value={invoice?.header?.code || ''}
                                onBlur={(value) => _handleChange("code", value)}
                                column="ISN_CODE"
                                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                            />
                        </div>
                        <div className="w25">
                            <label id={`${ID}-date-dgr-lbl`} className='form-label mandatory'>{t('date_g')}</label>
                            <FrmTimeDatePickr
                                id={`${ID}-date-dgr`}
                                value={invoice?.header?.dateDgr}
                                onChange={(value) => _handleChange("dateDgr", value)}
                            />
                        </div>

                        <div className="w25">
                            <label className='form-label'>{t('date_h')}</label>
                            <HijriDate
                                id={`${ID}-date-dhi`}
                                hijriDate={invoice?.header?.dateDhi}
                                gregorianDate={invoice?.header?.dateDgr}
                                onChange={(value) => _handleChange("dateDhi", value)}
                            />
                        </div>
                        <div className="w25">
                            <label className='form-label'>{t('ref_code')}</label>
                            <FrmInput
                                id={`${ID}-ref-code`}
                                value={invoice?.header?.refCode || ''}
                                onBlur={(value) => _handleChange("refCode", value)}
                            />
                        </div>

                        <div className="w100">
                            <label className='form-label'>{t('remarks')}</label>
                            <FrmInput
                                id={`${ID}-remark`}
                                value={invoice?.header?.rem || ''}
                                onBlur={(value) => _handleChange("rem", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('branch_division')}
                        </label>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w50">
                            <label id={`${ID}-branch-lbl`} className='form-label mandatory'>{t('branch')}</label>
                            <FrmSelectMenu
                                id={`${ID}-branch`}
                                key={`${ID}-branch-${invoice?.id}`}
                                value={invoice?.header?.mbnId}
                                api={() => getCommonBranches(null, isEmpty(invoice?.header?.mbnId) ? [] : [invoice?.header?.mbnId], STATUS_ACTIVE, BRANCH_CONDITION, false, userPolicy)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("mbnId", value.value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label id={`${ID}-division-lbl`} className='form-label mandatory'>{t('division')}</label>
                            <FrmSelectMenu
                                id={`${ID}-division`}
                                key={`division-${invoice?.header?.mbnId}`}
                                value={invoice?.header?.dsvId}
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false, null, null, invoice?.header?.mbnId, STATUS_ACTIVE, isEmpty(invoice?.header?.dsvId) ? [] : [invoice?.header?.dsvId])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleChange("dsvId", e.value)}
                                isDisabled={!invoice?.header?.mbnId}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('customer')}
                        </label>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w50">
                            <label id={`${ID}-customer-lbl`} className='form-label mandatory'>{t('customer')}</label>
                            <FrmSelectMenu
                                id={`${ID}-customer`}
                                value={invoice?.header?.atrId}
                                onChange={(e) => _handleChange("atrId", e)}
                                api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, true, false, false, [], false)}
                                defualtValueApi={() => getCommonCustomers(isEmpty(invoice?.header?.atrId) ? [] : [invoice?.header?.atrId], STATUS_ACTIVE, null, true, false, false, [], false)}
                                defualtValueId={invoice?.header?.atrId}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                onDataRetrieved={(list) => {
                                    const id = getUrlParam("id");
                                    if (isNotEmpty(id) && invoice?.header?.atrId && customerRef.current) {
                                        list?.forEach((element) => {
                                            if (element?.id == invoice?.header?.atrId) {
                                                _handleChange("customerData", { ...element })
                                                return;
                                            }
                                        })
                                    }
                                    if (customerRef.current && invoice?.header?.atrId) {
                                        customerRef.current = false;
                                    }
                                }}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label id={`${ID}-account-type-lbl`} className='form-label mandatory'>{t('account_type')}</label>
                            <FrmSelectMenu
                                id={`${ID}-account-type`}
                                value={invoice?.header?.accountType}
                                api={() => getDomainValues(DOMAIN_CUSTOMER_ACCOUNT_TYPE)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("accountType", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('financial_statements')}
                        </label>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w25">
                            <label className='form-label'>{t('currency')}</label>
                            <FrmSelectMenu
                                id={`${ID}-currency`}
                                key={`${ID}-currency-${invoice?.id}`}
                                api={() => getCurrenciesLov(invoice?.header?.fcuId)}
                                value={invoice?.header?.fcuId}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={(e) => _handleChange("fcuId", e.value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w25">
                            <label className='form-label'>{t('ex_rate')}</label>
                            <ExchangeRateInput
                                id={`${ID}-ex-rate`}
                                value={invoice?.header?.exchngRate || ''}
                                onBlur={(value) => _handleChange("exchngRate", value)}
                                allowDecimals={true}
                                currencyId={invoice?.header?.fcuId}
                                transactionId={invoice.header?.id}
                                transactionDate={invoice?.header?.dateDgr}
                                organizationCurrency={user?.fcuId}
                            />
                        </div>

                        <div className={`w25 ${isLocalCurrency(invoice?.header?.fcuId) ? style.hideFc : ''}`} >
                            <label className={`form-label`}>{t('paid_amount_afc')}</label>
                            <ForeignCurrencyInput
                                id={`${ID}-amount-afc`}
                                value={invoice?.header?.paidAmountAfc}
                                onBlur={(value) => _handleChange("paidAmountAfc", value)}
                                changeAlcAmount={(value) => _handleChange("paidAmountAlc", value)}
                                currencyId={invoice?.header?.fcuId}
                                exchngRate={invoice?.header?.exchngRate || ''}
                            />
                        </div>

                        <div className="w25">
                            <label className='form-label'>{t('paid_amount_alc')}</label>
                            <LocalCurrencyInput
                                id={`${ID}-amount-alc`}
                                value={invoice?.header?.paidAmountAlc}
                                onBlur={(value) => _handleChange("paidAmountAlc", value)}
                            />
                        </div>

                        <div className="w25">
                            <label className='form-label'>{t('required_date')}</label>
                            <FrmSelectMenu
                                id={`${ID}-required_date`}
                                api={() => getDomainValues(DATE_LEVEL_DOMAIN)}
                                value={invoice?.header?.billingDateMode}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={(e) => _handleChange("billingDateMode", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null&&
                            < div className="w25">
                                <label className='form-label'>{t('required_date_from_g')}</label>
                                <FrmTimeDatePickr
                                    id={`${ID}-billing-period-from-dgr`}
                                    value={invoice?.header?.billingPeriodFromDgr}
                                    onChange={value => _handleChange("billingPeriodFromDgr", value)}
                                />
                            </div>
                        }
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null &&

                            <div className="w25">
                                <label className='form-label'>{t('required_date_to_g')}</label>
                                <FrmTimeDatePickr
                                    id={`${ID}-billing-period-to-dgr`}
                                    value={invoice?.header?.billingPeriodToDgr}
                                    onChange={value => _handleChange("billingPeriodToDgr", value)}
                                />
                            </div>
                        }
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null && 

                            <div className="w25">
                                <label className='form-label'>{t('required_date_from_h')}</label>
                                <HijriDate
                                    id={`${ID}-billing-period-from-dhi`}
                                    hijriDate={invoice?.header?.billingPeriodFromDhi}
                                    gregorianDate={invoice?.header?.billingPeriodFromDgr}
                                    onChange={(value) => _handleChange("billingPeriodFromDhi", value)}
                                />
                            </div>
                        }
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null &&

                            <div className="w25">
                                <label className='form-label'>{t('required_date_to_h')}</label>
                                <HijriDate
                                    id={`${ID}-billing-period-to-dhi`}
                                    hijriDate={invoice?.header?.billingPeriodToDhi}
                                    gregorianDate={invoice?.header?.billingPeriodToDgr}
                                    onChange={(value) => _handleChange("billingPeriodToDhi", value)}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div >
        </>
    );
}

export default Header;