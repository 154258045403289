import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import TownsIcon from "../../../icons/Towns";
import { getDistrict, saveDistrict } from "../../../util/apis";
import { LIMIT_LAT, LIMIT_LNG, STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { FND2621_TOWNS_DEFINITION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const COMMON_ID = 'towns-dialog';

const TownDialog = (props, ref) => {
    const { townsTableRef } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const mapRef = useRef(null);
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);
    const user = getUser();

    const [district, setDistrict] = useState({ isActive: STATUS_ACTIVE, });

    const _handleDataChanged = (name, value) => {
        if (name == "longitude" && Math.abs(value) > LIMIT_LNG) {
            value = (value < 0 ? -1 : 1) * LIMIT_LNG
        } else if (name == "latitude" && Math.abs(value) > LIMIT_LAT) {
            value = (value < 0 ? -1 : 1) * LIMIT_LAT
        }
        if (name == "longitude" || name == "latitude") {
            value = Number(value).toFixed(6);
        }
        district[name] = value;
        if (district.latitude && district.longitude) {
            mapRef.current.changePinLocation(district.latitude, district.longitude)
        }
        setDistrict({ ...district });
    }

    const _saveDistrict = () => {
        if (!checkValidation([{ id: COMMON_ID }], t)) {
            return
        }
        showWaiting(`${COMMON_ID}-btn`, true);

        saveDistrict(district)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                showSuccessMessage("save_successfully", t);
                townsTableRef?.current?.refresh();
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${COMMON_ID}-btn`, true))
    }

    const _populateRecordData = (districtId) => {
        if (districtId) {
            updateRecordRef.current = true;
            getDistrict(districtId)
                .then((response) => {
                    setDistrict(response.data);
                    if (response.data.latitude && response.data.longitude) {
                        mapRef?.current?.setPin({ lat: response.data.latitude, lng: response.data.longitude })
                    }
                })
                .catch((error) => {
                    handleError(error, null, t);
                })
        } else {
            setDistrict({ isActive: STATUS_ACTIVE })
        }
    }

    const _postCloseAction = () => {
        setDistrict({ isActive: STATUS_ACTIVE });
        removeAllMandatoryClasses(document.getElementById(COMMON_ID));
    }

    const open = (districtId) => {
        _populateRecordData(districtId);
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({
        open,
        close,
    }))

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    return (
        <SidePanel
            id={COMMON_ID}
            ref={dialogRef}
            formId={FND2621_TOWNS_DEFINITION_FORM_ID}
            title={t('towns')}
            icon={<TownsIcon />}
            okBtnAction={_saveDistrict}
            footerAction={_saveDistrict}
            withFooter={isAllowedAction(user, FND2621_TOWNS_DEFINITION_FORM_ID, SAVE_ACTION)}
            footerLbl={t("save")}
            postClose={_postCloseAction}
            preClose={_preCloseAction}
            nextPreviousAction={_populateRecordData}
            showLog
            user={user}
            masterId={district.id}
        >
            <Tabs
                id="towns-dialog-tabs"
                ref={tabsRef}
                labels={['home', 'address']}
                tabContentStyle={style.tabContentStyle}
                tabsButtonCustomStyle={style.townsTabs}
            >
                <Tab1 handleDataChanged={_handleDataChanged} district={district} />
                <Tab2 handleDataChanged={_handleDataChanged} district={district} mapRef={mapRef} />
            </Tabs>
        </SidePanel>
    )
}

export default forwardRef(TownDialog);