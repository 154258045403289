import React from "react";

function DownLoadAllIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.501"
      height="21.501"
      viewBox="0 0 21.501 21.501"
    >
      <g
        fill={props.fill||"#292d32"}
        data-name="Group 176491"
        transform="translate(-301.25 -189.25)"
      >
        <g data-name="Group 630" opacity="0.4">
          <path
            d="M0 6.75a.749.749 0 01-.287-.057A.75.75 0 01-.75 6V0A.75.75 0 010-.75.75.75 0 01.75 0v4.189l.72-.72A.75.75 0 012.53 4.53l-2 2a.75.75 0 01-.53.22z"
            transform="translate(309 199)"
          ></path>
          <path
            d="M2 2.75a.748.748 0 01-.53-.22l-2-2a.75.75 0 010-1.06.75.75 0 011.06 0l2 2A.75.75 0 012 2.75z"
            data-name="Vector"
            transform="translate(307 203)"
          ></path>
        </g>
        <path
          d="M-599.251-9036.5c-2.769 0-4.647-.587-5.9-1.847s-1.846-3.135-1.846-5.9v-6c0-2.771.586-4.646 1.846-5.906s3.135-1.844 5.9-1.844h4.991a.681.681 0 01.16.016l.031.007h.01l.024.007h.015l.019.006.018.007.016.007.021.009.012.006.025.012h.007l.03.017a.775.775 0 01.171.139l7.952 7.952a.75.75 0 01.128.153l.012.02.005.01.009.017.013.024.005.011.009.021v.011l.009.022v.009c0 .01.008.021.011.031 0 .011.006.021.009.033a.741.741 0 01.023.2v4.982c0 2.769-.586 4.646-1.846 5.9s-3.135 1.847-5.9 1.847zm-4.844-18.6c-.959.959-1.4 2.5-1.4 4.845v6c0 2.346.446 3.885 1.4 4.845s2.5 1.4 4.844 1.4h6c2.346 0 3.885-.445 4.844-1.4s1.4-2.5 1.4-4.845v-4.251h-3.251c-3.417 0-4.749-1.332-4.749-4.751v-3.249h-4.251c-2.342.006-3.878.452-4.842 1.411zm10.6 1.844c0 2.582.668 3.251 3.249 3.251h2.189l-5.438-5.44z"
          data-name="Union 17"
          transform="translate(908.25 9247.25)"
        ></path>
      </g>
    </svg>
  );
}

export default DownLoadAllIcon;
