import React from "react";

function FinancialIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="18.5"
      viewBox="0 0 21.5 18.5"
    >
      <g
        fill="#fff"
        data-name="Group 178517"
        transform="translate(-173.25 -254.75)"
      >
        <path
          d="M15 17.75H5a5.869 5.869 0 01-4.135-1.388A5.686 5.686 0 01-.75 12V5A5.686 5.686 0 01.865.638 5.869 5.869 0 015-.75h10A5.869 5.869 0 0119.135.638 5.686 5.686 0 0120.75 5v7a5.686 5.686 0 01-1.615 4.362A5.869 5.869 0 0115 17.75zM5 .75a4.442 4.442 0 00-3.115.987A4.271 4.271 0 00.75 5v7a4.271 4.271 0 001.135 3.263A4.442 4.442 0 005 16.25h10a4.442 4.442 0 003.115-.987A4.271 4.271 0 0019.25 12V5a4.271 4.271 0 00-1.135-3.263A4.442 4.442 0 0015 .75z"
          transform="translate(174 255.5)"
        ></path>
        <path
          d="M3-.75A3.75 3.75 0 11-.75 3 3.754 3.754 0 013-.75zm0 6A2.25 2.25 0 10.75 3 2.253 2.253 0 003 5.25z"
          data-name="Vector"
          transform="translate(181 261)"
        ></path>
        <path
          d="M0 5.75A.75.75 0 01-.75 5V0A.75.75 0 010-.75.75.75 0 01.75 0v5a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(177.5 261.5)"
        ></path>
        <path
          d="M0 5.75A.75.75 0 01-.75 5V0A.75.75 0 010-.75.75.75 0 01.75 0v5a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(190.5 261.5)"
        ></path>
      </g>
    </svg>
  );
}

export default FinancialIcon;