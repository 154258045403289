import React from "react";

function UpdateIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.148"
      height="24.149"
      data-name="Group 175735"
      viewBox="0 0 24.148 24.149"
    >
      <g data-name="Group 175735">
        <path
          fill={props.color || "#b8bfc9"}
          d="M12.074 22.157A10.084 10.084 0 1119.2 4.951l.167.167-3.033 3.028h7.813V.337L20.77 3.714l-.17-.167a12.069 12.069 0 10-8.529 20.6 12.089 12.089 0 0012.072-11.84h-1.989a10.1 10.1 0 01-10.08 9.849"
          data-name="Path 6451"
        ></path>
      </g>
    </svg>
  );
}

export default UpdateIcon;