import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from "@ipgd-gauge/spinner";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, formatDate, formatMoney, handleError, hideWaiting, isEmpty, isEnglish, isNotEmpty, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import ExportIcon from "../../../icons/Export";
import RecalculateIcon from "../../../icons/Recalculate";
import { exportEstimatorInvoice, getAdditionalItemsLov, getCommonCustomers, getCommonPartiesLov, getCommonPropertyTypes, getEstimationDomains, getEstimationTranactions, recalculateEstimatorInvoice } from "../../../util/apis";
import { DATE_LEVEL_DOMAIN_ALL, DATE_LEVEL_DOMAIN_APPROVAL_DATE, DATE_LEVEL_DOMAIN_TRANSACTION_DATE, DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES, ESTIMATION_DOMAINS_STAGES, ESTIMATION_DOMAINS_TRANSACTION_TYPE, ESTIMATOR_PARTY, INSPECTOR_PARTY, INVOICE_LEVEL_SPECIFIC_PARTIES, PROPORTIES_PARTIES_TYPE_OWNER, STATUS_ACTIVE, STATUS_ESTIMATOR_FEES, STATUS_INSPECTOR_FEES } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import { ACTION_ADD_LINES, ACTION_DELETE_ALL_LINES, ACTION_DELETE_LINE, ACTION_RECALCULATE, ACTION_SET_FILTERED_LINES, ACTION_SET_LINE_VALUE } from "./reducer";
import style from './style.module.scss';

export const ESTIMATION_FEES = 128601;
export const ITEM = 128602;

const AdvanceFiltersPanel = forwardRef((props, ref) => {
    const { billingDateMode, billingPeriodFromDgr, billingPeriodToDgr } = props;

    const { t } = useTranslation("estimatorsInspectorsInvoices");
    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        if (name != 'billingInfo') {
            filterData[name] = value;
        } else {
            filterData[name] = value;
            if (billingDateMode == DATE_LEVEL_DOMAIN_APPROVAL_DATE) {
                filterData.approvalFromDate = billingPeriodFromDgr;
                filterData.approvalToDate = billingPeriodToDgr;
                filterData.transactionFromDate = null;
                filterData.transactionToDate = null;
            } else if (billingDateMode == DATE_LEVEL_DOMAIN_TRANSACTION_DATE) {
                filterData.transactionFromDate = billingPeriodFromDgr;
                filterData.transactionToDate = billingPeriodToDgr;
                filterData.approvalFromDate = null;
                filterData.approvalToDate = null;
            } else if (billingDateMode == DATE_LEVEL_DOMAIN_ALL) {
                filterData.approvalFromDate = null;
                filterData.approvalToDate = null;
                filterData.transactionFromDate = null;
                filterData.transactionToDate = null;
            }
        }
        setFilterData({ ...filterData });
    }

    useEffect(() => {
        _handleDataChanged('billingInfo');
    }, [billingDateMode, billingPeriodFromDgr, billingPeriodToDgr])

    const getFilterData = () => {
        return filterData;
    }

    const clearFilterData = () => {
        const tempFilter = {};
        if (billingDateMode == DATE_LEVEL_DOMAIN_APPROVAL_DATE) {
            tempFilter.approvalFromDate = billingPeriodFromDgr;
            tempFilter.approvalToDate = billingPeriodToDgr;
            tempFilter.transactionFromDate = null;
            tempFilter.transactionToDate = null;
        } else if (billingDateMode == DATE_LEVEL_DOMAIN_TRANSACTION_DATE) {
            tempFilter.transactionFromDate = billingPeriodFromDgr;
            tempFilter.transactionToDate = billingPeriodToDgr;
            tempFilter.approvalFromDate = null;
            tempFilter.approvalToDate = null;
        } else if (billingDateMode == DATE_LEVEL_DOMAIN_ALL) {
            tempFilter.approvalFromDate = null;
            tempFilter.approvalToDate = null;
            tempFilter.transactionFromDate = null;
            tempFilter.transactionToDate = null;
        }
        setFilterData({ ...tempFilter })
    }
    useImperativeHandle(ref, () => ({
        getFilterData,
        clearFilterData
    }))

    return (
        <div className={`container ${style.advanceFiltersPanelContainer}`}>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('party')}</label>
                    <FrmSelectMenu
                        id="party"
                        isMulti={true}
                        value={filterData.parties}
                        api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.parties, null, null, 1)}
                        descLo={['nameLo']}
                        descFo={['nameFo']}
                        onChange={e => _handleDataChanged("parties", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('customer')}</label>
                    <FrmSelectMenu
                        id="customer"
                        value={filterData.customers}
                        onChange={e => _handleDataChanged("customers", e)}
                        defualtValueId={filterData.customers}
                        defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, [], true)}
                        api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        isMulti={true}
                        isAsync={true}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('approval_date_from')}</label>
                    <FrmTimeDatePickr
                        id="Approval-Date-From"
                        value={filterData.approvalFromDate}
                        onChange={value => _handleDataChanged("approvalFromDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('approval_date_to')}</label>
                    <FrmTimeDatePickr
                        id="Approval-Date-To"
                        value={filterData.approvalToDate}
                        onChange={value => _handleDataChanged("approvalToDate", value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('order_date_from')}</label>
                    <FrmTimeDatePickr
                        id="Order-From-Date"
                        value={filterData.orderFromDate}
                        onChange={value => _handleDataChanged("orderFromDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('order_date_to')}</label>
                    <FrmTimeDatePickr
                        id="Order-To-Date"
                        value={filterData.orderToDate}
                        onChange={value => _handleDataChanged("orderToDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('transaction_date_from')}</label>
                    <FrmTimeDatePickr
                        id="Transaction-From-Date"
                        value={filterData.transactionFromDate}
                        onChange={value => _handleDataChanged("transactionFromDate", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('transaction_date_to')}</label>
                    <FrmTimeDatePickr
                        id="Transaction-To-Date"
                        value={filterData.transactionToDate}
                        onChange={value => _handleDataChanged("transactionToDate", value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('city')}</label>
                    <FrmInput
                        id="city"
                        value={filterData.city}
                        onBlur={(value) => _handleDataChanged("city", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('town')}</label>
                    <FrmInput
                        id="towns"
                        value={filterData.town}
                        onBlur={(value) => _handleDataChanged("town", value)}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('transaction_type')}</label>
                    <FrmSelectMenu
                        id="Transaction-Type"
                        isMulti={true}
                        value={filterData.trxTypes}
                        api={() => getEstimationDomains(ESTIMATION_DOMAINS_TRANSACTION_TYPE)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleDataChanged("trxTypes", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('property_type')}</label>
                    <FrmSelectMenu
                        id="property_type"
                        isMulti={true}
                        value={filterData.propertyTypes}
                        api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, true, false, false, false, true, filterData.propertyTypes)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => _handleDataChanged("propertyTypes", value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w25">
                    <label className='form-label'>{t('stage')}</label>
                    <FrmSelectMenu
                        id="Stage"
                        isMulti={true}
                        value={filterData.stages}
                        api={() => getEstimationDomains(ESTIMATION_DOMAINS_STAGES)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={e => _handleDataChanged("stages", e)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w25">
                    <label className='form-label'>{t('inspector')}</label>
                    <FrmSelectMenu
                        id="Inspector"
                        key="Inspector"
                        isMulti={true}
                        value={filterData.inspectorIds}
                        api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.inspectorIds, null, null, 1)}
                        descLo={['nameLo']}
                        descFo={['nameFo']}
                        onChange={e => _handleDataChanged("inspectorIds", e)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
        </div>
    )
})

const HeaderLines = (props) => {

    const { invoice, dispatchInvoice } = props;
    const { t } = useTranslation();
    const insertItemDialog = useRef();
    const insertTransactionDialog = useRef();

    const _insertTransactions = (selectedData) => {
        let rows = [];
        selectedData.map(data => {
            let row = {};
            row.source = ESTIMATION_FEES;
            row.ksrId = data.taskId == -1 ? null : data.taskId;
            row.triId = data.id;
            row.dueAmountAlc = data.execFeesAlc;
            row.dueAmountAfc = data.execFeesAfc;
            row.invoiceAmountAlc = data.execFeesAlc;
            row.invoiceAmountAfc = data.execFeesAfc;
            row.approvalDateDgr = data.approvalDateTime;
            row.aprId = data.partyId;
            row.cdaId = invoice?.header?.defaultCdaId;
            row.descLo = invoice?.header?.defaultCdaCode + ', ' + invoice?.header?.defaultCdaDescLo;
            row.descFo = invoice?.header?.defaultCdaCode + ', ' + invoice?.header?.defaultCdaDescLo;
            row.vatPerc = invoice?.header?.defaultCdaVatPerc || 0;
            row.party = {};
            row.party.code = data.partyCode;
            row.party.nameLo = data.partyNameLo;
            row.party.nameFo = data.partyNameFo;
            row.transaction = {};
            row.transaction.code = data.code;
            row.transaction.refCode = data.refCode;
            row.transaction.trnxStatusLo = data.trnxStatusLo;
            row.transaction.trnxStatusFo = data.trnxStatusFo;
            row.transaction.orderNo = data.orderNo;
            row.transaction.orderDateTime = data.orderDateTime;
            row.transaction.dateTime = data.dateTime;
            row.transaction.cityDescLo = data.cityDescLo;
            row.transaction.cityDescFo = data.cityDescFo;
            row.transaction.districtDescLo = data.districtDescLo;
            row.transaction.districtDescFo = data.districtDescFo;
            row.transaction.ownerName = data.ownerName;
            row.transaction.officialNameLo = data.officialNameLo;
            row.transaction.officialNameFo = data.officialNameFo;
            row.transaction.customersCategoryLo = data.customersCategoryLo;
            row.transaction.customersCategoryFo = data.customersCategoryFo;
            row.transaction.ownerCardNum = data.ownerCardNum;
            row.transaction.propertyTypeLo = data.propertyTypeLo;
            row.transaction.propertyTypeFo = data.propertyTypeFo;
            row.transaction.propertyCode = data.propertyCode;
            row.transaction.titleDeedNo = data.titleDeedNo;
            row.transaction.parcelArea = data.parcelArea;
            row.transaction.totalBuildArea = data.totalBuildArea;
            row.transaction.noOfFloors = data.noOfFloors;
            row.transaction.finalPrice = data.finalPrice;
            row.transaction.stageDescLo = data.stageDescLo;
            row.transaction.stageDescFo = data.stageDescFo;
            row.transaction.trnxStatusLo = data.trnxStatusLo;
            row.transaction.trnxStatusFo = data.trnxStatusFo;
            row.transaction.orgFeesAlc = data.orgFeesAlc;
            row.transaction.orgFeesAfc = data.orgFeesAfc;
            row.transaction.clientName = data.clientName;
            row.transaction.inspectorNameLo = data.inspectorNameLo;
            row.transaction.inspectorNameFo = data.inspectorNameFo;
            rows.push(row);
        })

        dispatchInvoice({ value: rows, type: ACTION_ADD_LINES })
        insertTransactionDialog.current.close();
    }

    const _insertItem = (selectedData) => {
        let rows = [];
        selectedData.map(data => {
            let row = {};
            row.source = ITEM;
            row.cdaId = data.id
            row.codeDescLo = data.codeDescLo || ''
            row.codeDescFo = data.codeDescFo || ''
            row.descLo = data.code + ', ' + data.descLo
            row.descFo = data.code + ', ' + data.descFo
            row.vatPerc = data.vatPerc
            rows.push(row);
        })
        dispatchInvoice({ value: rows, type: ACTION_ADD_LINES })
        insertItemDialog.current.close();
    }

    const _recalculateAction = () => {
        let data = {};
        for (let index = 0; index < invoice.lines.length; index++) {
            const element = invoice.lines[index];
            if (isNotEmpty(element.triId) && isNotEmpty(element.aprId)) {
                data[index] = { triId: element.triId, aprId: element.aprId };
            }
        }

        showWaiting("recalculate-button-spinner-id", true)
        recalculateEstimatorInvoice(data)
            .then(response => dispatchInvoice({ type: ACTION_RECALCULATE, value: response.data }))
            .catch(error => handleError(error, null, t))
            .finally(() => hideWaiting("recalculate-button-spinner-id"))
    }

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => dispatchInvoice({ type: ACTION_DELETE_ALL_LINES }));
    }

    return (
        <>
            <div className={style.headerLines}>
                <div></div>
                <div className={style.headerBtn}>
                    <button
                        className="tableHeaderActionsBtn"
                        onClick={() => insertTransactionDialog.current.open()}
                        disabled={!invoice?.header?.dateDgr || !invoice?.header?.dsvId || !invoice?.header?.invoiceLevel || (invoice?.header?.invoiceLevel == INVOICE_LEVEL_SPECIFIC_PARTIES && invoice?.parties?.length == 0)}
                    >
                        <span>
                            <AddHeaderBtnIcon />
                        </span>
                        <label>
                            {t('estimatorsInspectorsInvoices:insert_transactions')}
                        </label>
                    </button>

                    <button
                        className={`tableHeaderActionsBtn ${style.marBtn}`}
                        onClick={() => insertItemDialog.current.open()}
                        disabled={!invoice?.header?.dateDgr || !invoice?.header?.mbnId || !invoice?.header?.invoiceLevel}
                    >
                        <span>
                            <AddHeaderBtnIcon />
                        </span>
                        <label>
                            {t('estimatorsInspectorsInvoices:insert_item')}
                        </label>
                    </button>

                    <button
                        className="tableHeaderActionsBtn"
                        onClick={_recalculateAction}
                        disabled={(invoice.lines || []).length === 0}>
                        <Spinner id="recalculate-button-spinner-id" className={style.spinner} />
                        <span>
                            <RecalculateIcon />
                        </span>
                        <label>
                            {t('estimatorsInspectorsInvoices:recalculate')}
                        </label>
                    </button>
                    <button
                        className={`tableHeaderActionsBtn ${style.marLeft}`}
                        onClick={() => exportEstimatorInvoice(invoice.lines, 'estimators-and-inspectors-invoices', "inspector-invoices-spinner-id")}
                        disabled={(invoice.lines || []).length === 0}>
                        <Spinner id="inspector-invoices-spinner-id" className={style.spinner} />
                        <span>
                            <ExportIcon />
                        </span>
                        <label>
                            {t('estimatorsInspectorsInvoices:export')}
                        </label>
                    </button>

                    <button
                        className="tableHeaderDeleBtn"
                        onClick={_deleteAllAction}
                        disabled={invoice?.lines?.length == 0}
                    >
                        <span>
                            <DeleteTowIcon />
                        </span>
                        <label>
                            {t('estimatorsInspectorsInvoices:delete_all')}
                        </label>
                    </button>
                </div>
            </div>
            <MultiInsertDialog
                id='estimator_insert_item'
                ref={insertItemDialog}
                title={t('estimatorsInspectorsInvoices:insert_item')}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                rowHeight={45}
                okBtnAction={_insertItem}
                api={(searchKey) => getAdditionalItemsLov(searchKey, null, STATUS_ACTIVE, [STATUS_INSPECTOR_FEES, STATUS_ESTIMATOR_FEES, DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES])}
                searchPlaceholder={t('estimatorsInspectorsInvoices:code') + " , " + t('estimatorsInspectorsInvoices:name')}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('estimatorsInspectorsInvoices:code'), value: 'code', width: '25%' },
                    { label: t('estimatorsInspectorsInvoices:name'), valueLo: 'descLo', valueFo: 'descFo', width: '65%' },
                ]}
                isSmallSize
            />
            <MultiInsertDialog
                id='estimator_insert_transactions'
                ref={insertTransactionDialog}
                title={t('estimatorsInspectorsInvoices:insert_transactions')}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                okBtnAction={_insertTransactions}
                selectedIds={invoice?.lines.map((line) => line.triId)}
                api={getEstimationTranactions}
                tableStyle={style.insertTransactionsTable}
                rowHeight={45}
                apiParams={{
                    partiesIds: invoice?.parties?.map(party => party.aprId) || [],
                    branchId: invoice.header.mbnId,
                    divisionId: invoice.header.dsvId,
                    partyLevel: invoice.header.invoiceLevel
                }}
                searchPlaceholder={t('estimatorsInspectorsInvoices:application_num') + " , " + t('estimatorsInspectorsInvoices:ref_code') + " , " + t('estimatorsInspectorsInvoices:owner') + " , " + t('estimatorsInspectorsInvoices:client_name') + " , " + t('estimatorsInspectorsInvoices:order_num')}
                columns={[
                    { width: '2%' },
                    { label: '#', width: '2%' },
                    { label: t('estimatorsInspectorsInvoices:party_code'), value: 'partyCode', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:party_name'), valueLo: 'partyNameLo', valueFo: 'partyNameFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:application_num'), value: 'code', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:approval_date'), value: 'approvalDateTime', isDate: true, width: '3%' },
                    { label: t('estimatorsInspectorsInvoices:due_amount_alc'), value: 'execFeesAlc', isMoney: true, width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:transaction_type'), valueLo: 'typeDescLo', valueFo: 'typeDescFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:ref_code'), value: 'refCode', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:order_num'), value: 'orderNo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:order_date'), value: 'orderDateTime', isDate: true, width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:application_date'), value: 'dateTime', isDate: true, width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:city'), valueLo: 'cityDescLo', valueFo: 'cityDescFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:town'), valueLo: 'districtDescLo', valueFo: 'districtDescFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:owner'), value: 'ownerName', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:customer'), valueLo: 'officialNameLo', valueFo: 'officialNameFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:customer_category'), valueLo: 'customersCategoryLo', valueFo: 'customersCategoryFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:client_name'), value: 'clientName', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:owner_id'), value: 'ownerCardNum', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:property_type'), valueLo: 'propertyTypeLo', valueFo: 'propertyTypeFo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:title_deed_no'), value: 'titleDeedNo', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:land_area'), value: 'parcelArea', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:buildding_area'), value: 'totalBuildArea', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:number_of_floors'), value: 'noOfFloors', width: '4%' },
                    { label: t('estimatorsInspectorsInvoices:estimation_price'), value: 'finalPrice', isMoney: true, width: '2%' },
                    { label: t('estimatorsInspectorsInvoices:stage'), valueLo: 'stageDescLo', valueFo: 'stageDescFo', width: '2%' },
                    { label: t('estimatorsInspectorsInvoices:status'), valueLo: 'trnxStatusLo', valueFo: 'trnxStatusFo', width: '2%' },
                    { label: t('estimatorsInspectorsInvoices:revenue_alc'), value: 'orgFeesAlc', isMoney: true, width: '3%' },
                ]}
                AdvanceFilters={AdvanceFiltersPanel}
                AdvanceFiltersProps={{
                    billingDateMode: invoice?.header?.billingDateMode,
                    billingPeriodFromDgr: invoice?.header?.billingPeriodFromDgr,
                    billingPeriodToDgr: invoice?.header?.billingPeriodToDgr,

                }}
                tableWidth={250}
            />
        </>
    )
}

const InvoicesListRow = (props) => {

    const { t } = useTranslation("estimatorsInspectorsInvoices");
    const { data, rowActions, virtualizedStyle, index } = props;
    const { handleChange, handelDeleteLine, currencyId } = rowActions;

    const _handleChange = (name, value) => {
        handleChange(name, value, index);
    }

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${style.invoicesListRow}`} style={virtualizedStyle}>
            <div title={data.sequence}> <div><label>{data.sequence}</label></div></div>
            {/* <div>
                <label title={`${data?.party?.code ? data?.party?.code + '-' : ''} ${isEnglish() ? data?.party?.nameLo || '' : data?.party?.nameFo || ''}`}>
                    {`${data?.party?.code ? data?.party?.code + '-' : ''} ${isEnglish() ? data?.party?.nameLo || '' : data?.party?.nameFo || ''}`}
                </label>
            </div> */}
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`party-${index}`}
                        value={data.aprId}
                        onChange={value => _handleChange("party", value)}
                        api={() => getCommonPartiesLov(null, [PROPORTIES_PARTIES_TYPE_OWNER], null, null, isEmpty(data.aprId) ? [] : [data.aprId], null, null, 1, null)}
                        descLo={['code', 'nameLo']}
                        descFo={['code', 'nameFo']}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            {/* <div> <label>{isEnglish() ? data?.party?.nameLo : data?.party?.nameFo}</label></div> */}
            <div><div> <label title={t(`source_${data.source}`)}>{t(`source_${data.source}`)}</label></div></div>
            <div>
                <div>
                    <label title={(isEnglish() ? data?.descLo : data?.descFo) || ''}>
                        {(isEnglish() ? data?.descLoof : data?.descFo) || ''}
                    </label>
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`desc-${index}`}
                        value={data.desc || ''}
                        onBlur={(value) => _handleChange("desc", value)}
                    />
                </div>
            </div>
            <div>  <div><label title={data?.transaction?.code}>{data?.transaction?.code}</label></div></div>
            <div> <div><label title={data.dueAmountAlc}>{data.dueAmountAlc}</label></div></div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`invoice-amount-fc-${index}`}
                        Placeholder={""}
                        value={data.invoiceAmountAfc || ''}
                        onBlur={(value) => _handleChange("invoiceAmountAfc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`invoice-amount-lc-${index}`}
                        placeholder={""}
                        value={data.invoiceAmountAlc || ''}
                        onBlur={(value) => _handleChange("invoiceAmountAlc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                        withoutFormat
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`discount-fc-${index}`}
                        placeholder={""}
                        value={data.discountAfc || ''}
                        onBlur={(value) => _handleChange("discountAfc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`discount-lc-${index}`}
                        placeholder={""}
                        value={data.discountAlc || ''}
                        onBlur={(value) => _handleChange("discountAlc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`vat-perc-${index}`}
                        placeholder={""}
                        value={data.vatPerc || ''}
                        onBlur={(value) => _handleChange("vatPerc", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`vat-amount-fc-${index}`}
                        placeholder={""}
                        value={data.vatAmountAfc || ''}
                        onBlur={(value) => _handleChange("vatAmountAfc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`vat-amount-lc-${index}`}
                        placeholder={""}
                        value={data.vatAmountAlc || ''}
                        onBlur={(value) => _handleChange("vatAmountAlc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`net-fc-${index}`}
                        placeholder={""}
                        value={data.netAfc || ''}
                        onBlur={(value) => _handleChange("netAfc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                        disabled={true}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`net-lc-${index}`}
                        placeholder={""}
                        value={data.netAlc || ''}
                        onBlurAlc={(value) => _handleChange("netAlc", value)}
                        allowDecimals={true}
                        currentCurrency={currencyId}
                        disabled={true}
                    />
                </div>
            </div>
            <div><div> <label title={formatDate(data.approvalDateDgr)}>{formatDate(data.approvalDateDgr)}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.trnxStatusLo : data?.transaction?.trnxStatusFo}>{isEnglish() ? data?.transaction?.trnxStatusLo : data?.transaction?.trnxStatusFo}</label></div></div>
            <div><div> <label title={data?.transaction?.refCode}>{data?.transaction?.refCode}</label></div></div>
            <div><div> <label title={data?.transaction?.orderNo}>{data?.transaction?.orderNo}</label></div></div>
            <div><div> <label title={formatDate(data?.transaction?.orderDateTime)}>{formatDate(data?.transaction?.orderDateTime)}</label></div></div>
            <div><div> <label title={formatDate(data?.transaction?.dateTime)}>{formatDate(data?.transaction?.dateTime)}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.cityDescLo : data?.transaction?.cityDescFo}>{isEnglish() ? data?.transaction?.cityDescLo : data?.transaction?.cityDescFo}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.districtDescLo : data?.transaction?.districtDescFo}>{isEnglish() ? data?.transaction?.districtDescLo : data?.transaction?.districtDescFo}</label></div></div>
            <div><div> <label title={data?.transaction?.ownerName}>{data?.transaction?.ownerName}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.officialNameLo : data?.transaction?.officialNameFo}>{isEnglish() ? data?.transaction?.officialNameLo : data?.transaction?.officialNameFo}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.customersCategoryLo : data?.transaction?.customersCategoryFo}>{isEnglish() ? data?.transaction?.customersCategoryLo : data?.transaction?.customersCategoryFo}</label></div></div>
            <div><div> <label title={data?.transaction?.clientName}>{data?.transaction?.clientName}</label></div></div>
            <div><div> <label title={data?.transaction?.ownerCardNum}>{data?.transaction?.ownerCardNum}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.propertyTypeLo : data?.transaction?.propertyTypeFo}>{isEnglish() ? data?.transaction?.propertyTypeLo : data?.transaction?.propertyTypeFo}</label></div></div>
            <div><div> <label title={data?.transaction?.propertyCode}>{data?.transaction?.propertyCode}</label></div></div>
            <div><div> <label title={data?.transaction?.titleDeedNo}>{data?.transaction?.titleDeedNo}</label></div></div>
            <div><div> <label title={data?.transaction?.parcelArea}>{data?.transaction?.parcelArea}</label></div></div>
            <div><div> <label title={data?.transaction?.totalBuildArea}>{data?.transaction?.totalBuildArea}</label></div></div>
            <div><div> <label title={data?.transaction?.noOfFloors}>{data?.transaction?.noOfFloors}</label></div></div>
            <div><div> <label title={formatMoney(data?.transaction?.finalPrice)}>{formatMoney(data?.transaction?.finalPrice)}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.stageDescLo : data?.transaction?.stageDescFo}>{isEnglish() ? data?.transaction?.stageDescLo : data?.transaction?.stageDescFo}</label> </div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.trnxStatusLo : data?.transaction?.trnxStatusFo}>{isEnglish() ? data?.transaction?.trnxStatusLo : data?.transaction?.trnxStatusFo}</label></div></div>
            <div><div> <label title={isEnglish() ? data?.transaction?.inspectorNameLo : data?.transaction?.inspectorNameLo}>{isEnglish() ? data?.transaction?.inspectorNameLo : data?.transaction?.inspectorNameLo}</label></div></div>
            <div><div> <label title={formatMoney(data?.transaction?.orgFeesAlc)}>{formatMoney(data?.transaction?.orgFeesAlc)}</label></div></div>
            <div><div className="deleteBtnSm"><DeleteBtn onClick={() => handelDeleteLine(index)} /></div></div>
        </div>
    );
};

const InvoicesList = (props) => {
    const { invoice, dispatchInvoice, user } = props;
    const { t } = useTranslation("estimatorsInspectorsInvoices");

    const columns = [
        { title: '#', width: "1%" },
        { title: <span data-property="aprId" className="mandatory" >{t('estimatorsInspectorsInvoices:party')}</span>, width: "5%", searchColumns: "party.code,party.nameLo,party.nameFo" },
        { title: t('estimatorsInspectorsInvoices:source'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:item_Code_name'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:description'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:application_num'), width: "2%", searchColumns: "transaction.code" },
        { title: t('estimatorsInspectorsInvoices:due_amount'), width: "2%" },
        { title: t('customersEstimationInvoices:invoice_amount_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%' },
        { title: t('customersEstimationInvoices:invoice_amount_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%' },
        { title: t('customersEstimationInvoices:discount_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%' },
        { title: t('customersEstimationInvoices:discount_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%' },
        { title: t('estimatorsInspectorsInvoices:vat_per'), width: "2%" },
        { title: t('customersEstimationInvoices:vat_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%' },
        { title: t('customersEstimationInvoices:vat_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%' },
        { title: t('customersEstimationInvoices:net_fc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '0%' : '2%' },
        { title: t('customersEstimationInvoices:net_lc'), width: isLocalCurrency(invoice?.header?.fcuId) ? '4%' : '2%' },
        { title: t('estimatorsInspectorsInvoices:approval_date'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:transaction_type'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:ref_code'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:order_num'), width: "2%", searchColumns: "transaction.orderNo" },
        { title: t('estimatorsInspectorsInvoices:order_date'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:application_date'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:city'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:town'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:owner'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:customer'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:customer_category'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:client_name'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:owner_id'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:property_type'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:property_code'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:title_deed_num'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:land_area'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:building_area'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:number_of_floors'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:estimation_price'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:stage'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:trnx_status'), width: "3%" },
        { title: t('estimatorsInspectorsInvoices:inspector'), width: "2%" },
        { title: t('estimatorsInspectorsInvoices:revenue'), width: "3%" },
        { title: "", width: "2%" },
    ];

    const _handelDeleteLine = (index) => {
        dispatchInvoice({ index: index, type: ACTION_DELETE_LINE })
    }

    const _handleChange = (name, value, index) => {
        dispatchInvoice({ name, value, index, exchngRate: invoice?.header?.exchngRate, type: ACTION_SET_LINE_VALUE })
    }

    return (
        <Table
            id="invoices-list-table"
            columns={columns}
            Row={InvoicesListRow}
            tableStyle={style.invoicesListTable}
            rowHeight={45}
            tableWidth={490}
            rowActions={{
                handleChange: _handleChange,
                handelDeleteLine: _handelDeleteLine,
                currencyId: invoice?.header?.fcuId,
            }}
            list={invoice.filteredLines ? invoice.filteredLines : invoice.lines}
            user={user}
            formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
            onSearchColumns={(filteredList) => dispatchInvoice({ value: filteredList, type: ACTION_SET_FILTERED_LINES })}
            originalList={invoice.lines}
        />
    );
}

const Details = (props) => {

    const { user, invoice, dispatchInvoice } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('estimatorsInspectorsInvoices:details')}
                    </label>
                </div>
            </div>

            <HeaderLines
                invoice={invoice}
                dispatchInvoice={dispatchInvoice}
            />

            <InvoicesList
                invoice={invoice}
                dispatchInvoice={dispatchInvoice}
                user={user}
            />
        </div>
    )
}

export default Details;