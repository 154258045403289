import React from "react";

function AddBtnIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.991"
      height="21.991"
      viewBox="0 0 21.991 21.991"
    >
      <g
        fill="#fff"
        data-name="Group 178409"
        transform="rotate(180 10.995 10.995)"
      >
        <rect
          width="2.476"
          height="21.991"
          data-name="Rectangle 161788"
          rx="1.238"
          transform="rotate(90 6.116 15.874)"
        ></rect>
        <rect
          width="2.476"
          height="21.991"
          data-name="Rectangle 161789"
          rx="1.238"
          transform="translate(9.758)"
        ></rect>
      </g>
    </svg>
  );
}

export default AddBtnIcon;