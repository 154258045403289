import { hideActionWaiting, hideWaiting, showActionWaiting, showWaiting } from '@ipgd-gauge/utils';
import axios from 'axios';

const config = {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    timeout: 10 * 60 * 60 * 1000,
    validateStatus: status => {
        return status >= 200 && status < 400;
    },
};

const textBodyConfig = {
    headers: { 'Content-Type': 'text/plain' },
    withCredentials: true,
    timeout: 10 * 60 * 60 * 1000,
    validateStatus: status => {
        return status >= 200 && status < 400;
    },
};

const blobConfig = {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
    withCredentials: true,
    timeout: 10 * 60 * 60 * 1000,
    validateStatus: status => {
        return status >= 200 && status < 400;
    },
};

export const setAuthorizationHeader = token => {
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        blobConfig.headers.Authorization = `Bearer ${token}`;
        textBodyConfig.headers.Authorization = `Bearer ${token}`;
    } else {
        config.headers.Authorization = ``;
        blobConfig.headers.Authorization = ``;
        textBodyConfig.headers.Authorization = ``;
    }
};

export const loginLogoPath = (isEng) => {
    return `apis/utils/login-logo/${isEng}`;
}

export const headerLogoPath = (isEng) => {
    return `apis/utils/header-logo/${isEng}`;
}

/** --------- User -------- **/
export const loginUser = (userData) => {
    return axios.post(`apis/users/authenticate`, userData, config);
}

export const getOrgnizationById = (fogId) => {
    return axios.get(`apis/users/org/${fogId}`, config);
}

export const updateUserProfile = (data) => {
    return axios.post(`apis/users/update-profile`, data, config);
}

export const changePassword = (data) => {
    return axios.post(`apis/users/change-password`, data, config);
}

export const changeFndPassword = (data) => {
    return axios.post(`apis/users/change-fnd-password`, data, config);
}

export const getUserLogs = (userId) => {
    return axios.get(`apis/users/user-logs/${userId}`, config);
}

export const logoutUser = () => {
    return axios.post(`apis/users/logout`, {}, config);
}

export const changeLanguage = (isEn) => {
    return axios.put(`apis/users/change-language/${isEn}`, {}, config);
}

export const getFilters = (formId) => {
    return axios.get(`apis/users/user-saved-filter/${formId}`, config);
}

export const saveFilter = (filter) => {
    return axios.put(`apis/users/user-saved-filter`, filter, config);
}

export const updateFilter = (data) => {
    return axios.post(`apis/users/user-saved-filter`, data, config);
}

export const deleteFilter = (id) => {
    return axios.delete(`apis/users/user-saved-filter/${id}`, config);
}

export const deleteUserTableProfiles = (id) => {
    return axios.delete(`/apis/users/delete-user-table-profiles/${id}`, config);
}

export const saveUserTableProfiles = (data) => {
    return axios.put(`/apis/users/save-user-table-profiles`, data, config);
}

export const getSelectedtUserTableProfiles = () => {
    return axios.get(`/apis/users/selected-user-table-profiles`, config);
}

export const getUserTableProfiles = (formId, tableId) => {
    return axios.get(`/apis/users/user-table-profiles/${formId}/${tableId}`, config);
}

export const updateSelectedUserTableProfiles = (data) => {
    return axios.put(`/apis/users/update-selected-user-table-profiles`, data, config);
}

export const getUsersLog = (pageNumber, pageSize, filterData) => {
    return axios.post(`apis/users/users-log?pageNumber=${pageNumber}&pageSize=${pageSize}`, filterData, config);
}

export const getLogs = (id, pageNumber, pageSize) => {
    return axios.get(`apis/users/logs?id=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`, config);
}

export const getUser = (id) => {
    return axios.get(`apis/users/${id}`, config);
}

export const getUserList = (page, size, filterData) => {
    return axios.post(`apis/users?page=${page}&size=${size}`, filterData, config);
}

export const saveUser = (user) => {
    return axios.put(`apis/users`, user, config);
}

export const deleteUser = (id) => {
    return axios.delete(`apis/users/${id}`, config);
}

export const updateUserStatus = (id, status) => {
    return axios.put(`apis/users/update-status/${id}/${status}`, {}, config);
}

export const resetLoginCount = (id) => {
    return axios.put(`apis/users/reset-login-count/${id}`, {}, config);
}

export const saveUserUsedForms = (formId) => {
    return axios.post(`/apis/users/user-used-forms/${formId}`, {}, config);
}

export const saveFavoriteForms = (formId) => {
    return axios.post(`/apis/users/favorite-forms/${formId}`, {}, config);
}

export const getFavoriteForms = (filterData) => {
    return axios.post(`/apis/users/get-favorite-forms`, filterData, config);
}

export const getLastVisitedForms = (filterData) => {
    return axios.post(`/apis/users/last-visited-forms`, filterData, config);
}

export const isCodeFieldEnabled = (formId, columnName) => {
    return axios.get(`/apis/users/is-code-field-enabled/${formId}/${columnName}`, config);
}

export const resetUserPassword = (userId) => {
    return axios.put(`/apis/users/reset-user-password/${userId}`, {}, config);
}

/** --------- User -------- **/

export const getCurrentTime = () => {
    return axios.get(`apis/utils/current-time`, config);
}

/** ----- Customer Apis ----- **/
export const getCustomers = (page, size, searchKey, data) => {
    return axios.post(`apis/customer?page=${page}&size=${size}&searchKey=${searchKey}`, data, config);
}

export const getCustomer = (customerId) => {
    return axios.get(`apis/customer/${customerId}`, config);
}

export const saveCustomer = (data) => {
    return axios.put(`apis/customer/save-customer`, data, config);
}

export const deleteCustomer = (customerId) => {
    return axios.delete(`apis/customer/${customerId}`, config);
}
/** ----- Customer Apis ----- **/

/** ----- District Apis ----- **/
export const getDistricts = (page, size, searchKey, data) => {
    return axios.post(`apis/district?page=${page}&size=${size}&searchKey=${searchKey}`, data, config);
}
export const getDistrict = (districtId) => {
    return axios.get(`apis/district/${districtId}`, config);
}

export const saveDistrict = (data) => {
    return axios.put(`apis/district/save-district`, data, config);
}
export const deleteDistrict = (districtId) => {
    return axios.delete(`apis/district/${districtId}`, config);
}
/** ----- City Apis ----- **/

/** ----- Attachments Apis ----- **/
export const saveAttachment = (id, formId, file) => {
    try {
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        );
        return axios.post(`apis/attachments/add-attacment/${id}/${formId}`, formData, config);
    } catch (error) {
        console.log("🚀 ~ error", error)
    }
}

export const deleteAttachment = (id, formId) => {
    return axios.delete(`apis/attachments/img/${id}/${formId}`, config);
}
/** ----- Attachments Apis ----- **/

export const getCities = (page, size, data) => {
    return axios.post(`apis/city?page=${page}&size=${size}`, data, config);
}

export const getCity = (cityId) => {
    return axios.get(`apis/city/${cityId}`, config);
}

export const saveCity = (data) => {
    return axios.put(`apis/city/save-city`, data, config);
}

export const deleteCity = (cityId) => {
    return axios.delete(`apis/city/${cityId}`, config);
}

/********************************* LOV APIs *********************************/
export const getCommonPartiesLov = (listOfTypes, partyType, dependsOnLso, lsoId, ids, status, searchValue, orgLevel, fogIds) => {
    const body = {
        listOfTypes: listOfTypes || false,
        partyType: partyType || [],
        dependsOnLso: dependsOnLso || false,
        lsoId: lsoId || null,
        ids: ids || [],
        status: status || null,
        searchValue: searchValue || null,
        orgLevel: orgLevel || null,
        fogIds: fogIds || []
    };
    return axios.post(`/apis/lov/parties-lov`, body, config);
}

export const getCommonDivisionsList = (searchValue, queryCondition, general, organizationIds, policyId, branchId, isActive, ids) => {
    return axios.post(`/apis/lov/divisions-list`, { searchValue: searchValue, queryCondition: queryCondition, general: general || false, organizationIds: organizationIds, policyId: policyId, branchId: branchId, isActive: isActive, ids: ids }, config);
}

export const getPartiesDefinitionCategories = (status, isGeneral, fogIds, dependOnFogId, searchValue, ids, isPropertyValuationForm) => {
    return axios.post(`apis/lov/property-party-categories`, {
        status: status,
        isGeneral: isGeneral,
        fogIds: fogIds || [],
        dependOnFogId: dependOnFogId || false,
        searchValue: searchValue,
        ids: ids || [],
        isPropertyValuationForm: isPropertyValuationForm || false
    }, config);
}

export const getCountriesLov = (ids, status) => {
    return axios.post(`/apis/lov/countries-lov`, { status, ids: ids || [] }, config);
}

export const getRegionsLov = (ids, status, isPolicy, fcoId, searchValue) => {
    return axios.post(`/apis/lov/regions-lov`, { searchValue, isPolicy: isPolicy || false, fcoId, status, ids: ids || [] }, config);
}

export const getCodingStyleManagementForms = () => {
    return axios.get(`/apis/lov/get-coding-style-management-forms`, config);
}

export const getFinancialLocationsList = (searchValue, status, ids) => {
    return axios.post(`/apis/lov/financial-locations-list`, { searchValue: searchValue, isActive: status, ids: ids }, config);
}

export const getRealEstateUserLOV = (searchValue, isActive, isDependOnBranchDivision, isPolicyIdMandetory, userTypes, lsoId, mbnId, dsvId, ids, isAsync) => {
    return axios.post(`/apis/lov/real-estate-user`, { searchValue: searchValue, isActive: isActive, isDependOnBranchDivision: isDependOnBranchDivision, isPolicyIdMandetory: isPolicyIdMandetory, userTypes: userTypes || [], lsoId: lsoId, mbnId: mbnId, dsvId: dsvId, ids: ids || [], isAsync: isAsync || false }, config);
}
export const getCitiesLov = (ids, status, countryId, regionId, searchValue, isPolicy, isRegionActive) => {
    return axios.post(`/apis/lov/cities-lov`, { ids: ids || [], status, countryId, regionId, searchValue, isPolicy: isPolicy || false, isRegionActive: isRegionActive || false }, config);
}

export const getEvaluationPolicyFunctions = (id) => {
    return axios.get(`/apis/lov/evaluation-policy-functions?id=${id}`, config);
}

export const getExpenseAccountList = (fogId, accountId) => {
    return axios.post(`/apis/lov/expense-account-list`, { fogId: fogId, accountId: accountId }, config);
}

export const getCategories = (searchValue, queryCondition, general, organizationIds, isActive, ids) => {
    return axios.post(`apis/lov/categories`, { searchValue: searchValue, queryCondition: queryCondition, general: general, organizationIds: organizationIds, isActive: isActive, ids: ids || [] }, config);
}

export const getFinancialIntegration = (data) => {
    return axios.post(`/apis/lov/financial-integration`, data, config);
}


export const getFinancialGroups = (isFilter, isActive, ids) => {
    return axios.post(`apis/lov/financial-groups`, { isFilter: isFilter, isActive: isActive, ids: ids || [] }, config);
}

export const getReports = (ids) => {
    return axios.post(`apis/lov/reports`, { ids: ids || [] }, config);
}

export const getCurrenciesLov = (id) => {
    return axios.post(`apis/lov/currencies-list`, { id: id }, config);
}

export const getTownsList = (searchKey, ids, cityId, isActive, isUserPolicy, policyId, isRegionAndCityActive) => {
    return axios.post(`apis/lov/towns-list`, { searchValue: searchKey, ids: ids || [], cityId: cityId, isActive: isActive, isUserPolicy: isUserPolicy || false, policyId: policyId, isRegionAndCityActive: isRegionAndCityActive || false }, config);
}
export const getFndUsers = (ids, userStatus, hasRsmUser) => {
    return axios.post(`apis/lov/fnd-users`, { ids: ids || [], userStatus, hasRsmUser: hasRsmUser || false }, config);
}

export const getEstimationQuotationsLov = (searchValue, ids) => {
    return axios.post(`apis/lov/estimation-quotations`, { searchValue: searchValue, ids: ids || [] }, config)
}

export const getCommonPropertyTypes = (status, searchValue, dependOnUserOrganization, dependOnCustomOrganization, isGeneral, customOrganiztionIds, dependOnPolicy, ids) => {
    return axios.post(`apis/lov/property-types`, {
        status: status || null,
        searchValue: searchValue || null,
        dependOnUserOrganization: dependOnUserOrganization || false,
        dependOnCustomOrganization: dependOnCustomOrganization || false,
        isGeneral: isGeneral || false,
        customOrganiztionIds: customOrganiztionIds || [],
        dependOnPolicy: dependOnPolicy || false,
        ids: ids || []

    }, config);
}

export const getValuationFieldBlocks = () => {
    return axios.get(`apis/lov/blocks`, config);
}

export const getQuotationFieldsList = (searchValue) => {
    return axios.post(`apis/lov/quotation-fields-list`, { searchValue: searchValue }, config);
}

export const getEvaluationFunctions = (ids, searchValue, isActive, excludeUsedIds, isDependOnPolicy, fnoId) => {
    return axios.post(`apis/lov/evaluation-functions-list`, { ids: ids || [], searchValue: searchValue, isActive: isActive, excludeUsedIds, isDependOnPolicy: isDependOnPolicy || false, fnoId: fnoId }, config)
}
export const getRsmStatus = () => {
    return axios.get(`apis/lov/status`, config);
}

export const getValuationReports = (formId) => {
    return axios.get(`apis/lov/valuation-reports/${formId}`, config);
}

export const getActionList = (isMain) => {
    return axios.get(`apis/lov/action-list?isMain=${isMain}`, config);
}

export const getTaskDescriptionLOV = (ids, isActive) => {
    return axios.post(`apis/lov/task-description-lov`, { ids: ids || [], isActive: isActive }, config);
}

export const getCommonCustomers = (ids, status, searchValue, isDependOnUserFogId, isDependOnCustomFogId, isGeneral, customFogIds, isDependOnPolicyId, policyId) => {
    return axios.post(`apis/lov/common-customers`, {
        ids: ids || [],
        status: status || null,
        searchValue: searchValue || null,
        isDependOnUserFogId: isDependOnUserFogId || false,
        isDependOnCustomFogId: isDependOnCustomFogId || false,
        isGeneral: isGeneral || false,
        customFogIds: customFogIds || [],
        isDependOnPolicyId: isDependOnPolicyId || false,
        policyId: policyId
    }, config);
}

export const getTrxForSelect = (searchValue, ids, stage) => {
    return axios.post(`apis/lov/trx-for-select`, { searchValue: searchValue, ids: ids || [], stage: stage }, config);
}

export const getCommonBranches = (searchValue, ids, isActive, queryCondition, isGeneral, policyId, organizationIds) => {
    return axios.post(`apis/lov/common-branches-lov`, { searchValue: searchValue, ids: ids || [], isActive: isActive, queryCondition: queryCondition, isGeneral: isGeneral || false, policyId: policyId, organizationIds: organizationIds }, config)
}

export const getEstimationsQuotationTypes = (id) => {
    return axios.post(`apis/lov/types`, { id: id }, config);
}

export const getAdditionalItemsLov = (searchValue, ids, isActive, types) => {
    return axios.post(`apis/lov/additional-items`, { searchValue: searchValue, ids: ids, isActive: isActive, types: types || [] }, config)
}

export const getPartiesList = (type, id) => {
    return axios.get(`apis/lov/parties-list?type=${type}&id=${id}`, config);
}

export const getMatersList = (id) => {
    return axios.get(`apis/lov/maters-list?id=${id}`, config);
}

export const getOrganizationsList = (searchValue, formId) => {
    return axios.post(`apis/lov/organizations-list`, { formId: formId, searchValue: searchValue }, config);
}

export const getPartiesList216 = (partyTypes, isFilter, ids) => {
    return axios.post(`apis/lov/parties-list-216`, { partyTypes: partyTypes || [], isFilter: isFilter || false, ids: ids || [] }, config);
}

export const getPartiesList1942 = (partyType) => {
    return axios.get(`apis/lov/parties-list-1942?partyType=${partyType}`, config);
}

export const getCoordinatorsForSelect = () => {
    return axios.get(`apis/lov/coordinators-for-select`, config);
}

export const getEvaluationEstimationStatuses = (ids, isActive, searchValue) => {
    return axios.post(`apis/lov/valuation-trnx-statuses-list`, { ids: ids || [], isActive: isActive, searchValue: searchValue }, config);
}

export const getPoliciesList = (formId, id, status, functionFormId) => {
    return axios.post(`apis/lov/policies-list`, { formId, id, status, functionFormId }, config);
}

export const getPartiesList3792 = (type) => {
    return axios.get(`apis/lov/parties-list-3792?type=${type}`, config);
}

export const getRsmEstimator = () => {
    return axios.get(`apis/lov/estimator`, config);
}

export const getInvoices = (customerId, globalStatus, execludeIds, ids) => {
    return axios.post(`apis/lov/invoices-list`, { customerId: customerId, globalStatus: globalStatus, execludeIds: execludeIds || false, ids: ids }, config);
}

/********************************* LOV APIs *********************************/

/** ----- Util Apis ----- **/
export const getLicense = () => {
    return axios.get(`apis/utils/license`, config);
}

export const getDefaultFormValues = (formId) => {
    return axios.get(`/apis/utils/get-default-form-values/${formId}`, config);
}

export const getDomainValues = (domId) => {
    return axios.get(`apis/utils/domain-values/${domId}`, config);
}
export const getDomainValuesWithCondition = (domId, isIn, ids) => {
    return axios.post(`/apis/utils/domain-values-with-condition/${domId}`, { in: isIn, ids: ids }, config);
}
export const getDomainValue = (id) => {
    return axios.get(`apis/utils/domain-value/${id}`, config);
}
export const getEstimationDomains = (code) => {
    return axios.get(`apis/utils/estimation-domains?code=${code}`, config);
}

export const getUserDashboardColors = (chartName) => {
    return axios.get(`apis/utils/user-dashboard-colors/${chartName}`, config);
}
export const saveUserDashboardColors = (data) => {
    return axios.put(`apis/utils/user-dashboard-colors`, data, config);
}

export const printValuationReport = (data) => {
    return axios.post(`apis/utils/print-valuation-report`, data, blobConfig)
}

export const convertHijriDateToGregorianDate = (hijriDate) => {
    return axios.get(`apis/utils/convert-hijri-date-to-gregorian-date/${hijriDate}`, config);
}

export const getTaskDashboard = (filterData) => {
    return axios.post(`apis/not-executed-tasks/task-dashboard`, filterData, config);
}

export const getTrnxsStatistics = (filterData) => {
    return axios.post(`apis/transactions-statistics/trnxs-statistics`, filterData, config);
}

export const getCustomersDashboard = (filterData) => {
    return axios.post(`apis/transactions-statistics/customers-dashboard`, filterData, config);
}

export const getInspectorsDashboard = (filterData) => {
    return axios.post(`apis/transactions-statistics/inspectors-dashboard`, filterData, config);
}

export const getPropertyTypesDashboard = (filterData) => {
    return axios.post(`apis/transactions-statistics/property-types-dashboard`, filterData, config);
}

export const getEstimatorDashboard = (filterData) => {
    return axios.post(`apis/transactions-statistics/estimator-dashboard`, filterData, config);
}

export const getTrxStatusDashboard = (filterData) => {
    return axios.post(`apis/transactions-statistics/trx-status-dashboard`, filterData, config);
}

export const getRegionsDashboard = (filterData) => {
    return axios.post(`apis/transactions-statistics/regions-dashboard`, filterData, config);
}

export const getNumberOfDelayedTasksDashboard = (filterData) => {
    return axios.post(`apis/not-executed-tasks/number-of-delayed-task-dashboard`, filterData, config);
}

export const getTaskDescriptionDashboard = (filterData) => {
    return axios.post(`apis/not-executed-tasks/task-description-dashboard`, filterData, config);
}

export const getPropertyTypeDashboard = (filterData) => {
    return axios.post(`apis/not-executed-tasks/property-types-dashboard`, filterData, config);
}

export const convertGregorianDateToHijri = (gregorianDate) => {
    return axios.get(`apis/utils/convert-gregorian-date-to-hijri/${gregorianDate}`, config);
}

export const getCurrencyExchangeRate = (orgCurrency, otherCurrency, date) => {
    return axios.get(`/apis/utils/currency-exchange-rate?orgCurrency=${orgCurrency}&otherCurrency=${otherCurrency}&date=${date}`, config)
}

export const getUserAuditLogs = (formId, transactionId) => {
    return axios.get(`/apis/utils/user-adit-log/${formId}/${transactionId}`, config);
}

export const getEstimationTransactionsRegisterList = (pageNumber, pageSize, filterData) => {
    return axios.post(`apis/estimation-transactions-register?pageNumber=${pageNumber}&pageSize=${pageSize}`, filterData, config);
}

export const getEstimationTransactionsRegisterTotals = (filterData) => {
    return axios.post(`apis/estimation-transactions-register/totals`, filterData, config);
}

export const getTasksFollowUpDashboardUsers = (filterData) => {
    return axios.post(`apis/tasks-statistics/users-dashboard`, filterData, config);
}

export const getTasksFollowUpDashboardTasksDescription = (filterData) => {
    return axios.post(`apis/tasks-statistics/tasks-description-dashboard`, filterData, config);
}

export const getTasksFollowUpDashboardCustomers = (filterData) => {
    return axios.post(`apis/tasks-statistics/customers-dashboard`, filterData, config);
}

export const getTasksFollowUpDashboardRegions = (filterData) => {
    return axios.post(`apis/tasks-statistics/regions-dashboard`, filterData, config);
}

export const convertHijriToMilliseconds = (year, month, day) => {
    return axios.get(`apis/utils/convert-hijri-to-millisecondes?year=${year}&month=${month}&day=${day}`, config);
}
/** ----- Estimation Print Out ----- **/
export const getEstimationPrintOuts = (pageNumber, pageSize, searchValue) => {
    return axios.get(`apis/estimation-print-out?pageNumber=${pageNumber}&pageSize=${pageSize}&searchValue=${searchValue}`, config);
}

export const saveEstimationPrintOut = (estimationPrintOut) => {
    return axios.put(`apis/estimation-print-out/save-report`, estimationPrintOut, config);
}
export const deleteEstimationPrintOut = (reportId) => {
    return axios.delete(`apis/estimation-print-out/${reportId}`, config);
}

export const getEstimationPrintOut = (reportId) => {
    return axios.get(`apis/estimation-print-out/${reportId}`, config);
}

/** ----- Estimation Report ----- **/
export const getEstimationReports = (reportNumber, pageNumber, pageSize, filterData) => {
    return axios.post(`apis/estimation-report/reports/${reportNumber}?pageNumber=${pageNumber}&pageSize=${pageSize}`, filterData, config);
}


export const getEstimationReportsTotals = (reportNumber, filterData) => {
    console.log("🚀 ~ file: apis.js:332 ~ getEstimationReportsTotals ~ `apis/estimation-report/reports-totals/${reportNumber}`", `apis/estimation-report/reports-totals/${reportNumber}`)
    return axios.post(`apis/estimation-report/reports-totals/${reportNumber}`, filterData, config);
}
/** ----- Estimation Report ----- **/

/** ----- Message ----- **/
export const getInboxMessages = (pageNumber, pageSize, data) => {
    return axios.post(`apis/message/inbox-messages?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const getOutboxMessages = (pageNumber, pageSize, data) => {
    return axios.post(`apis/message/outbox-messages?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const getMessage = (messageId) => {
    return axios.get(`apis/message/${messageId}`, config);
}

export const getTasksMonitoring = (pageNumber, pageSize, data) => {
    return axios.post(`apis/message/tasks?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const TasksMonitoringExportExcel = (data, waitingBtnId) => {
    exportFile(`apis/message/export-excel`, data, `tasks-monitoring.xlsx`, waitingBtnId)
}

export const getTask = (messageId) => {
    return axios.get(`apis/message/task/${messageId}`, config);
}

export const saveTaskDiversion = (data) => {
    return axios.post(`apis/message/save-task-diversion`, data, config);
}

export const getInboxMessageDetails = (messageId) => {
    return axios.get(`apis/message/inbox-details/${messageId}`, config);
}

export const favInboxMessage = (messageId) => {
    return axios.post(`apis/message/inbox-fav/${messageId}`, {}, config);
}

export const favOutboxMessage = (messageId) => {
    return axios.post(`apis/message/outbox-fav/${messageId}`, {}, config);
}

export const updateMsgReadStatus = (data) => {
    return axios.post(`apis/message/update-read-status`, data, config);
}

export const sendMessage = (data) => {
    return axios.post(`apis/message/send`, data, config);
}

export const getMessageFields = (formId, searchValue, id) => {
    return axios.get(`apis/message/message-fields?formId=${formId || null}&searchValue=${searchValue}&id=${id || []}`, config);
}

export const getUnreadedMessagesCount = () => {
    return axios.get(`apis/message/unreaded-messages-count`, config);
}

export const getUserMessage = () => {
    return axios.get(`apis/message/user-message`, config);
}
/** ----- Message ----- **/
/** --------- Estimations Quotation -------- **/
export const getEstimationsQuotationList = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/estimation-quotation?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const getEstimationsQuotation = (id) => {
    return axios.get(`apis/estimation-quotation/${id}`, config);
}

export const saveEstimationsQuotation = (estimationsQuotation, withSubmit) => {
    return axios.put(`apis/estimation-quotation/${withSubmit}`, estimationsQuotation, config);
}

export const deleteEstimationsQuotation = (id) => {
    return axios.delete(`apis/estimation-quotation/${id}`, config);
}

export const approveEstimationsQuotation = (id, status) => {
    return axios.get(`apis/estimation-quotation/approve/${id}/${status}`, config);
}

export const unPostEstimationsQuotation = (id) => {
    return axios.put(`apis/estimation-quotation/un-post/${id}`, {}, config);
}

export const rejectEstimationsQuotation = (id, status) => {
    return axios.get(`apis/estimation-quotation/reject/${id}/${status}`, config);
}

export const createAgreement = (id) => {
    return axios.get(`apis/estimation-quotation/create-agreement/${id}`, config);
}

export const createTransaction = (data) => {
    return axios.post(`apis/estimation-quotation/create-transaction`, data, config);
}

export const createContract = (id) => {
    return axios.post(`apis/estimation-quotation/create-contract/${id}`, {}, config);
}

export const linkWithCustomer = (data) => {
    return axios.post(`apis/estimation-quotation/link-with-customer`, data, config);
}

export const createNLinkCustomer = (data, id) => {
    return axios.post(`apis/estimation-quotation/create-link-customer/${id}`, data, config);
}

/** --------- Estimations Quotation -------- **/

/** ----- Delegation ----- **/
export const getDelegations = (pageNumber, pageSize, searchValue) => {
    return axios.post(`apis/delegation?pageNumber=${pageNumber}&pageSize=${pageSize}&searchValue=${searchValue}`, {}, config);
}

export const saveDelegation = (data) => {
    return axios.put(`apis/delegation/save`, data, config);
}

export const getDelegation = (id) => {
    return axios.get(`apis/delegation/${id}`, config);
}

export const deleteDelegation = (id) => {
    return axios.delete(`apis/delegation/${id}`, config);
}
/** ----- Delegation ----- **/

/** --------- Estimations History -------- **/
export const getEstimationsHistorys = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/estimation-history?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const getEstimationsHistory = (id) => {
    return axios.get(`apis/estimation-history/${id}`, config);
}

export const saveEstimationsHistory = (estimationsHistory) => {
    return axios.put(`apis/estimation-history`, estimationsHistory, config);
}

export const deleteEstimationsHistory = (id) => {
    return axios.delete(`apis/estimation-history/${id}`, config);
}

export const getEstimationHistoryForSummary = (id) => {
    return axios.get(`apis/estimation-history/estimation-history-for-summary/${id}`, config);
}
/** --------- Estimations History -------- **/

/** ----- Map ----- **/
export const getEstimationTrxsMapList = (pageNumber, pageSize, data) => {
    return axios.post(`apis/map/trx-map?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const getTransactionForMapCompare = (searchValue, id) => {
    return axios.get(`apis/map/trx-for-map-compare?searchValue=${searchValue}&id=${id}`, config);
}
/** ----- Map ----- **/

/** ----- TRX ----- **/
export const sortAttachments = (data) => {
    return axios.post(`apis/valuation-trx/sort-attachments`, data, config);
}

export const executeTrxAction = (data) => {
    return axios.post(`apis/valuation-trx/execute-action`, data, config);
}

export const rotateAttachments = (data) => {
    return axios.post(`apis/valuation-trx/rotate-attachments`, data, config)
}

export const updateAttachmentType = (data) => {
    return axios.post(`apis/valuation-trx/attachment-type`, data, config)
}

export const updateAttachmentFlag = (data) => {
    return axios.post(`apis/valuation-trx/attachment-flag`, data, config)
}

export const getTransactionForCompare = (data) => {
    return axios.post(`apis/valuation-trx/compare-transaction`, data, config)
}

export const fillSimilars = (data) => {
    return axios.post(`apis/valuation-trx/fill-similars`, data, config)
}

export const deleteSimilars = (data) => {
    return axios.post(`apis/valuation-trx/delete-similars`, data, config)
}

export const populateTransaction = (data) => {
    return axios.post(`apis/valuation-trx/populate-trx`, data, config);
}

export const changeTransactionValue = (data) => {
    return axios.post(`apis/valuation-trx/change-trx-value`, data, config);
}

export const changeUnitDetailValue = (data) => {
    return axios.post(`apis/valuation-trx/change-unit-detail-value`, data, config);
}

export const fillDefaultDescription = (data) => {
    return axios.post(`apis/valuation-trx/fill-default-description`, data, config);
}
/** ----- TRX ----- **/


/** --------- Est trx block -------- **/
export const getEstBlocks = (data) => {
    return axios.post(`apis/valuation-trx/trx-blocks`, data, config);
}

export const getEstBlockFeilds = (blockId, data) => {
    return axios.post(`apis/valuation-trx/trx-block-feilds/${blockId}`, data, config);
}

export const getEstFeildDetails = (feildId) => {
    return axios.get(`apis/valuation-trx/trx-feild-details/${feildId}`, config);
}

export const getEstTrxLovData = (data) => {
    return axios.post(`apis/valuation-trx/trx-lov-data`, data, config);
}
export const saveValutionTrx = (data) => {
    return axios.put(`apis/valuation-trx/save-valution-trx`, data, config);
}

export const getActions = (key) => {
    return axios.get(`apis/valuation-trx/trx-actions/${key}`, config);
}

export const getSelfConstruction = (data) => {
    return axios.post(`apis/valuation-trx/self-construction`, data, config);
}

export const addSelfConstruction = (data) => {
    return axios.post(`apis/valuation-trx/add-self-construction`, data, config);
}

export const deleteSelfConstruction = (data) => {
    return axios.post(`apis/valuation-trx/delete-self-construction`, data, config);
}

export const deleteUnitsDetail = (data) => {
    return axios.post(`apis/valuation-trx/delete-units-details`, data, config);
}

export const getUnitsDetails = (data) => {
    return axios.post(`apis/valuation-trx/units-details`, data, config);
}

export const addUnitsDetail = (data) => {
    return axios.post(`apis/valuation-trx/add-units-detail`, data, config);
}

export const getTrxSummary = (id) => {
    return axios.get(`apis/valuation-trx/summary/${id}`, config);
}

export const getTrxSpecialRemarks = (id) => {
    return axios.get(`apis/valuation-trx/special-remarks/${id}`, config);
}

export const postNote = (data) => {
    return axios.post(`apis/valuation-trx/note`, data, config);
}

export const deleteNResetNote = (data) => {
    return axios.post(`apis/valuation-trx/delete-reset-note`, data, config);
}

export const getValuationAuditLog = (trnxId, isLocal) => {
    return axios.get(`apis/valuation-trx/valuation-audit-log?trnxId=${trnxId}&isLocal=${isLocal ? "1" : "0"}`, config);
}

/** --------- Est trx block -------- **/
/** --------- Est trx block -------- **/
/** ----- Transaction List ----- **/
export const getEstimationTransactionList = (pageNumber, pageSize, data) => {
    return axios.post(`apis/transaction-list?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const getEstimationTransaction = (triId) => {
    return axios.get(`apis/transaction-list/estimation-transaction/${triId}`, config);
}

export const getMessages = (trnxId) => {
    return axios.get(`apis/transaction-list/messages/${trnxId}`, config);
}

export const favEstimationTransaction = (trnxId) => {
    return axios.post(`apis/transaction-list/fav/${trnxId}`, {}, config);
}

export const executeListAction = (params) => {
    return axios.post(`apis/transaction-list/execute-action`, params, config);
}

export const getWorkflowStatus = (triId) => {
    return axios.get(`apis/transaction-list/workflow-status/${triId}`, config);
}

export const getTaskStatusList = (triId) => {
    return axios.get(`apis/transaction-list/task-status-list/${triId}`, config)
}

export const getEstimationRating = (triId) => {
    return axios.get(`apis/transaction-list/rate/${triId}`, config)
}

export const addQrCodeImage = (trxId, number, file) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("trxId", trxId);
        formData.append("number", number);
        return axios.post(`apis/transaction-list/add-qrcode-image`, formData, config)
    } catch (error) {
        console.log('🚀 ~ error', error)
    }
}

export const deleteQrCodeImage = (triId) => {
    return axios.delete(`apis/transaction-list/delete-qrcode-image/${triId}`, config)
}

export const getRatingLogForTransaction = (triId) => {
    return axios.get(`apis/transaction-list/rating-log/${triId}`, config);
}

export const deleteTransactionRate = (rateId) => {
    return axios.put(`apis/transaction-list/delete-trx-rate/${rateId}`, {}, config);
}

export const undoDeleteTransactionRate = (rateId) => {
    return axios.put(`apis/transaction-list/undo-delete-trx-rate/${rateId}`, {}, config);
}
/** ----- Transaction List ----- **/


export const deleteImages = (id, formId) => {
    return axios.delete(`apis/attachments/img/${id}/${formId}`, config);
}

export const getAttachments = (trxId, formId) => {
    return axios.get(`apis/attachments/attachments/${trxId}/${formId}`, config);
}


export const downloadAttachment = (id, formId, imageName) => {
    exportFile(`apis/attachments/download-attachment/${id}/${formId}`, {}, imageName)
}

export const addAttachment = (trxId, formId, file, type) => {
    try {
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append("file", file, file.name);
        return axios.post(`apis/attachments/add-attacment/${trxId}/${formId}/${type}`, formData, config)
    } catch (error) {
        console.log('🚀 ~ error', error)
    }
}

export const addTrxAttachment = (trxKey, file, type) => {
    if (!file) {
        return;
    }
    const formData = new FormData();
    formData.append("file", file, file.name);
    return axios.post(`apis/valuation-trx/add-attachment/${trxKey}/${type}`, formData, config)
}

export const getTrxAttachment = (trxKey) => {
    return axios.get(`apis/valuation-trx/get-attachments/${trxKey}`, config);
}

export const deleteTrxAttachment = (data) => {
    return axios.post(`apis/valuation-trx/delete-attachments`, data, config);
}

/********************* EstimationForms *********************/
export const getEstimationForm = (id) => {
    return axios.get(`apis/estimation-forms/${id}`, config);
}

export const getEstimationFormList = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/estimation-forms?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const saveEstimationForm = (data) => {
    return axios.put(`apis/estimation-forms`, data, config);
}

export const deleteEstimationForm = (id) => {
    return axios.delete(`apis/estimation-forms/${id}`, config);
}

export const getEstimationFields = (id) => {
    return axios.get(`apis/estimation-forms/fields?id=${id}`, config);
}

export const getEstimationFieldsRefCode = () => {
    return axios.get(`apis/estimation-forms/refcode-fields`, config);
}

export const saveEstimationFields = (fnoId, data) => {
    return axios.put(`apis/estimation-forms/fields/${fnoId}`, data, config);
}

export const exportValuationFormLines = (id, execlName, waitingBtnId) => {
    exportFile(`apis/estimation-forms/export-lines/${id}`, null, `${execlName}.txt`, waitingBtnId);
}

export const importValuationFormLines = (id, file) => {
    try {
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append("file", file, file.name);
        return axios.post(`apis/estimation-forms/import-lines/${id}`, formData, config)
    } catch (error) {
        console.log('🚀 ~ error', error)
    }
}
/********************* EstimationForms *********************/

/********************* Estimation Transactions Statuses *********************/
export const getEstimationTransactionsStatuses = (id) => {
    return axios.get(`apis/estimation-transactions-statuses/${id}`, config);
}

export const getEstimationTransactionsStatusesList = (pageNumber, pageSize, filterData) => {
    return axios.post(`apis/estimation-transactions-statuses?pageNumber=${pageNumber}&pageSize=${pageSize}`, filterData, config);
}

export const saveEstimationTransactionsStatuses = (data) => {
    return axios.put(`apis/estimation-transactions-statuses`, data, config);
}

export const deleteEstimationTransactionsStatuses = (id) => {
    return axios.delete(`apis/estimation-transactions-statuses/${id}`, config);
}
/********************* Estimation Transactions Statuses *********************/

/********************* Estimation Message Templt *********************/
export const getEstimationMessageTemplt = (id) => {
    return axios.get(`apis/estimation-message-templates/${id}`, config);
}

export const getEstimationMessageTempltList = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/estimation-message-templates?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const saveEstimationMessageTemplt = (data) => {
    return axios.put(`apis/estimation-message-templates`, data, config);
}

export const deleteEstimationMessageTemplt = (id) => {
    return axios.delete(`apis/estimation-message-templates/${id}`, config);
}
/********************* Estimation Message Templt *********************/

const exportFile = (url, params, fileName, waitingBtnId, onComplete, onError, waitingActonBtnId) => {
    let finishedSuccessfully = false;
    try {
        if (waitingBtnId) {
            showWaiting(waitingBtnId, false, true)
        } else if (waitingActonBtnId) {
            showActionWaiting(waitingActonBtnId, true);
        }
        const link = document.createElement('a');
        axios.post(url, params, blobConfig)
            .then((response) => {
                finishedSuccessfully = true;
                if (fileName.includes(".pdf")) {
                    let url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    window.open(url + "#" + fileName);
                }
                else {
                    let url = URL.createObjectURL(new Blob([response.data]))
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((error) => {
                if (onError) {
                    onError(error);
                }
            })
            .finally(() => {
                if (onComplete) {
                    onComplete(finishedSuccessfully);
                }
                if (waitingBtnId) {
                    hideWaiting(waitingBtnId)
                } else if (waitingActonBtnId) {
                    hideActionWaiting(waitingActonBtnId);
                }
            });
    } catch (error) {
        console.error(error)
        if (onComplete) {
            onComplete(finishedSuccessfully);
        }
    }

}
/*******File exporting  */
export const printSelectedTransactions = (data, onComplete, onError) => {
    exportFile(`apis/transaction-list/print-all`, data, "test.zip", "print-transactions-dialog-okButton", onComplete, onError);
}

export const downloadReports = (formId, waitingActonBtnId) => {
    exportFile(`apis/estimation-print-out/download-reports/${formId}`, {}, "reports.zip", waitingActonBtnId)
}

export const downloadReportsSetup = (waitingActonBtnId) => {
    exportFile(`apis/estimation-print-out/download-setup`, {}, "KindaAddInSetup.rar", waitingActonBtnId)
}

export const printValuationTrxWord = (data, reportName, onComplete) => {
    exportFile(`apis/valuation-trx/print-valuation-word`, data, `${reportName}.docx`, null, onComplete)
}

export const prepareValuationPdfPrintLink = (data) => {
    return axios.post(`apis/valuation-trx/prepare-valuation-pdf-print-link`, data, config)
}

export const exportTransactionDataToExcel = (params, execlName, waitingActionBtnId) => {
    exportFile(`apis/transaction-list/export-transactions`, params, `${execlName}.xlsx`, null, null, null, waitingActionBtnId);
}


export const exportTransactionReportToExcel = (report, params, execlName, waitingActionBtnId) => {
    exportFile(`apis/estimation-report/export/${report}`, params, `${execlName}.xlsx`, null, null, null, waitingActionBtnId)
}

export const exportEstimationInvoiceFollowUpToExcel = (params, execlName, waitingBtnId) => {
    exportFile(`apis/estimation-invoices-follow-up/export-excel`, params, `${execlName}.xlsx`, waitingBtnId)
}

export const exportEstimationTransactionsRegisterToExcel = (params, execlName, waitingBtnId) => {
    exportFile(`apis/estimation-transactions-register/export-excel`, params, `${execlName}.xlsx`, waitingBtnId)
}

export const downloadAttachmentsCompress = (data, fileName) => {
    exportFile(`apis/valuation-trx/download-attachments`, data, `${fileName}.zip`)
}

export const downloadTransactionAttachment = (data, name) => {
    exportFile(`apis/valuation-trx/download-attachment`, data, name)
}

export const getEstimationFieldsList = (searchKey) => {
    return axios.get(`apis/estimation-message-templates/estimation-fields-list?searchKey=${searchKey}`, config);
}

export const getEstimationDictionaryList = (searchKey) => {
    return axios.get(`apis/estimation-message-templates/estimation-dictionary-list?searchKey=${searchKey}`, config);
}
/************************************************************* */
/********************* Estimation Message Templt *********************/

export const rotateImages = (ids) => {
    return axios.get(`apis/attachments/rotate-imgs`, { ids: ids }, config);
}

// export const downloadAttachments = (ids) => {
//     return axios.post(`apis/attachments/download-attachments`, { triId: ids }, config);
// }


/********************* Workflow Procedure And Messages *********************/
export const getActionMessageTask = (id) => {
    return axios.get(`apis/workflow-procedure-and-messages/${id}`, config);
}

export const getActionMessageTaskList = (pageNumber, pageSize, searchKey) => {
    return axios.get(`apis/workflow-procedure-and-messages?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, config);
}

export const saveActionMessageTask = (data) => {
    return axios.put(`apis/workflow-procedure-and-messages`, data, config);
}

export const deleteActionMessageTask = (id) => {
    return axios.delete(`apis/workflow-procedure-and-messages/${id}`, config);
}

/********************* Workflow Procedure And Messages *********************/

/********************* Customers Estimation Invoices *********************/
export const getEstimationInvoices = (pageNumber, pageSize, data) => {
    return axios.post(`apis/estimation-invoice?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const deleteEstimationInvoice = (id) => {
    return axios.delete(`apis/estimation-invoice/delete-invoice/${id}`, config);
}

export const saveEstimationInvoice = (data, withSubmit) => {
    return axios.put(`apis/estimation-invoice/save-invoice/${withSubmit}`, data, config);
}

export const getEstimationInvoice = (id) => {
    return axios.get(`apis/estimation-invoice/${id}`, config);
}

export const getTranactionsList = (searchValue, data) => {
    return axios.post(`apis/estimation-invoice/transactions-list?q=${searchValue}`, data, config);
}

export const rejectEstimationInvoice = (id) => {
    return axios.put(`apis/estimation-invoice/reject/${id}`, {}, config);
}

export const confirmEstimationInvoice = (id) => {
    return axios.put(`apis/estimation-invoice/confirm/${id}`, {}, config);
}

export const unpostEstimationInvoice = (id) => {
    return axios.put(`apis/estimation-invoice/unpost/${id}`, {}, config);
}

export const printEstimationInvoice = (id) => {
    return axios.post(`apis/estimation-invoice/print/${id}`, {}, config);
}

export const exportCustomersEstimationInvoices = (lines, execlName, waitingBtnId) => {
    exportFile(`apis/estimation-invoice/export`, lines, `${execlName}.xlsx`, waitingBtnId);
}

export const recalculateInvoice = (atrId, data) => {
    return axios.post(`apis/estimation-invoice/recalculate/${atrId}`, data, config);
}
/********************* Customers Estimation Invoices *********************/

/******************************* Estimation invoice register */
export const getEstimationInvoiceRegisterList = (pageSize, pageNumber, searchKey, filterData) => {
    return axios.post(`apis/estimation-invoice-register?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchKey}`, filterData, config);
}

export const getEstimationInvoiceRegisterTotals = (filterData) => {
    return axios.post(`apis/estimation-invoice-register/totals`, filterData, config);
}

export const exportEstimationInvoiceRegister = (filterData, execlName, waitingBtnId) => {
    exportFile(`apis/estimation-invoice-register/export`, filterData, `${execlName}.xlsx`, waitingBtnId)
}

/********************* Price Agreement *********************/
export const getPriceAgreement = (id) => {
    return axios.get(`apis/price-agreements/${id}`, config);
}

export const getPriceAgreementList = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/price-agreements?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const savePriceAgreement = (data) => {
    return axios.put(`apis/price-agreements`, data, config);
}

export const deletePriceAgreement = (id) => {
    return axios.delete(`apis/price-agreements/${id}`, config);
}

export const getPartyFilter = () => {
    return axios.get(`apis/price-agreements/party-filter`, config);
}
/********************* Price Agreement *********************/


/********************* Customers Estimator Invoices *********************/
export const getEstimatorInvoices = (pageNumber, pageSize, data) => {
    return axios.post(`apis/estimator-invoice?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const getEstimationTranactions = (searchValue, data) => {
    return axios.post(`apis/estimator-invoice/estimation-tranactions?q=${searchValue}`, data, config);
}

export const deleteEstimatorInvoice = (id) => {
    return axios.delete(`apis/estimator-invoice/delete-invoice/${id}`, config);
}

export const saveEstimatorInvoice = (data, withSubmit) => {
    return axios.put(`apis/estimator-invoice/save-invoice/${withSubmit}`, data, config);
}

export const getEstimatorInvoice = (id) => {
    return axios.get(`apis/estimator-invoice/${id}`, config);
}

export const getEstimatorInvoiceParties = (searchValue, fogId, partyType, groups, id) => {
    return axios.post(`apis/estimator-invoice/parties-list`, { searchValue: searchValue, fogId: fogId, partyType: partyType || [], groups: groups || [], id: id }, config);
}

export const rejectEstimatorInvoice = (id) => {
    return axios.put(`apis/estimator-invoice/reject/${id}`, {}, config);
}

export const confirmEstimatorInvoice = (id) => {
    return axios.put(`apis/estimator-invoice/confirm/${id}`, {}, config);
}

export const unpostEstimatorInvoice = (id) => {
    return axios.put(`apis/estimator-invoice/unpost/${id}`, {}, config);
}

export const printEstimatorInvoice = (id) => {
    return axios.post(`apis/estimator-invoice/print/${id}`, {}, config);
}

export const exportEstimatorInvoice = (lines, execlName, waitingBtnId) => {
    exportFile(`apis/estimator-invoice/export`, lines, `${execlName}.xlsx`, waitingBtnId);
}

export const recalculateEstimatorInvoice = (data) => {
    return axios.post(`apis/estimator-invoice/recalculate`, data, config);
}
/********************* Customers Estimation Invoices *********************/

/********************* Estimation Fees & Commissions Calculation *********************/
export const calculateEstimationFeesNCommissions = (data) => {
    return axios.put(`apis/estimation-fees-and-commissions`, data, config);
}
/********************* Estimation Fees & Commissions Calculation *********************/

/********************* Quotation Type *********************/
export const getQuotationType = (id) => {
    return axios.get(`apis/quotation-type/${id}`, config);
}

export const getQuotationTypeList = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/quotation-type?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const saveQuotationType = (data) => {
    return axios.put(`apis/quotation-type`, data, config);
}

export const deleteQuotationType = (id) => {
    return axios.delete(`apis/quotation-type/${id}`, config);
}

/********************* update valuation feilds *********************/
export const getValuationField = (id) => {
    return axios.get(`apis/valuation-field/${id}`, config);
}

export const getValuationFieldList = (pageNumber, pageSize, searchKey, filterData) => {
    return axios.post(`apis/valuation-field?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, filterData, config);
}

export const saveValuationField = (data) => {
    return axios.put(`apis/valuation-field`, data, config);
}

/********************* update valuation feilds *********************/
/********************* Properties Policies *********************/
export const getPropertiesPolicies = (pageNumber, pageSize, data) => {
    return axios.post(`apis/evaluation-policy/properties-policies?pageNumber=${pageNumber}&pageSize=${pageSize}`, data, config);
}

export const getEvaluationPolicyFunction = (id) => {
    return axios.get(`apis/evaluation-policy/functions/${id}`, config);
}

export const getEvaluationPolicyActions = (searchValue, functionId) => {
    return axios.get(`apis/evaluation-policy/actions/${functionId}?searchValue=${searchValue}`, config);
}

export const getEvaluationPolicyRegions = (searchValue) => {
    return axios.get(`apis/evaluation-policy/regions?searchValue=${searchValue}`, config);
}

export const getEvaluationPolicyCities = (searchValue) => {
    return axios.get(`apis/evaluation-policy/cities?searchValue=${searchValue}`, config);
}

export const getEvaluationPolicyPropertiesParties = (general, organizationIds, searchValue, isPropertyValuationForm) => {
    return axios.post(`apis/evaluation-policy/properties-parties`, { general: general || false, organizationIds: organizationIds || [], searchValue: searchValue, isPropertyValuationForm: isPropertyValuationForm || false }, config);
}

export const getEvaluationPolicySecurityForms = (searchValue) => {
    return axios.get(`apis/evaluation-policy/security-forms?searchValue=${searchValue}`, config);
}

export const getEvaluationPolicyOrganizations = () => {
    return axios.get(`apis/evaluation-policy/organizations`, config);
}

export const savePropertyPolicy = (data) => {
    return axios.put(`apis/evaluation-policy/save`, data, config);
}

export const getPropertyPolicy = (id) => {
    return axios.get(`apis/evaluation-policy/${id}`, config);
}

export const deletePropertyPolicy = (id) => {
    return axios.delete(`apis/evaluation-policy/${id}`, config);
}
/********************* Properties Policies *********************/

/********************* Properties Estimation List of Values *********************/
export const getEstimationLOV = (id) => {
    return axios.get(`apis/estimation-lov/${id}`, config);
}

export const getEstimationLOVList = (pageNumber, pageSize, searchKey) => {
    return axios.get(`apis/estimation-lov?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}`, config);
}

export const saveEstimationLOV = (data) => {
    return axios.put(`apis/estimation-lov`, data, config);
}
/********************* Properties Estimation List of Values *********************/

/********************* Estimation Invoices Follow Up *********************/
export const getEstimationInvoicesFollowUpList = (pageNumber, pageSize, filterData) => {
    return axios.post(`apis/estimation-invoices-follow-up?pageNumber=${pageNumber}&pageSize=${pageSize}`, filterData, config);
}

export const getEstimationInvoicesFollowUpTotals = (filterData) => {
    return axios.post(`apis/estimation-invoices-follow-up/totals`, filterData, config);
}
/********************* Estimation Invoices Follow Up *********************/

/******************* Porperties & Valuation Custom Reports *******************/

export const getCustomReports = (id) => {
    return axios.get(`api/call-reports/${id}`, config)
}

export const saveCustomReports = (data, sourceForm) => {
    return axios.put(`api/call-reports/${sourceForm}`, data, config)
}

export const deleteCustomReports = (id) => {
    return axios.delete(`api/call-reports/${id}`, config)
}

export const getCustomReportsList = (page, size, searchKey, sourceForm) => {
    return axios.post(`api/call-reports/${sourceForm}?page=${page}&size=${size}&searchKey=${searchKey}`, {}, config)
}

/************************** properties parties definition **************************/
export const getPropertiesPartiesDefinitions = (page, size, filterData) => {
    return axios.post(`apis/parties-definition/parties?page=${page}&size=${size}`, filterData, config);
}

export const getPropertyPartyDefinition = (id) => {
    return axios.get(`apis/parties-definition/party/${id}`, config);
}

export const deletePropertyPartyDefinition = (id) => {
    return axios.delete(`apis/parties-definition/${id}`, config);
}

export const savePropertyPartyDefinition = (data) => {
    return axios.put(`apis/parties-definition/save`, data, config);
}
/************************** properties parties definition **************************/

/********************* Properties Users Definition *********************/
export const getPropertiesUsersDefinition = (id) => {
    return axios.get(`apis/properties-users-definition/${id}`, config);
}

export const getPropertiesUsersDefinitionList = (page, size, searchKey, filterData) => {
    return axios.post(`apis/properties-users-definition?page=${page}&size=${size}&searchKey=${searchKey}`, filterData, config);
}

export const savePropertiesUsersDefinition = (data) => {
    return axios.put(`apis/properties-users-definition`, data, config);
}

export const deletePropertiesUsersDefinition = (id) => {
    return axios.delete(`apis/properties-users-definition/${id}`, config);
}

export const getBranchesNDivisions = (searchKey) => {
    return axios.get(`apis/properties-users-definition/branches-and-divisions?searchKey=${searchKey}`, config);
}
/********************* Properties Users Definition *********************/

/************************** Property Type **************************/
export const getPropertyType = (id) => {
    return axios.get(`apis/property-type/${id}`, config);
}

export const getPropertyTypeList = (page, size, searchKey, filterData) => {
    return axios.post(`apis/property-type?page=${page}&size=${size}&searchKey=${searchKey}`, filterData, config);
}

export const savePropertyType = (data) => {
    return axios.put(`apis/property-type`, data, config);
}
export const deletePropertyType = (id) => {
    return axios.delete(`apis/property-type/${id}`, config);
}
/************************** Property Type **************************/

/************************** Property Party Category **************************/
export const getPropertyPartyCategory = (id) => {
    return axios.get(`/apis/property-party-category/${id}`, config);
}

export const getPropertyPartyCategoryList = (page, size, searchKey, filterData) => {
    return axios.post(`/apis/property-party-category?page=${page}&size=${size}&searchKey=${searchKey}`, filterData, config);
}

export const savePropertyPartyCategory = (financialPeriods) => {
    return axios.put(`/apis/property-party-category`, financialPeriods, config);
}

export const deletePropertyPartyCategory = (id) => {
    return axios.delete(`/apis/property-party-category/${id}`, config);
}
/************************** Property Party Category **************************/

/************************** ControlWithExcelFields **************************/
export const getControlWithExcelField = (id) => {
    return axios.get(`apis/control-with-excel-fields/${id}`, config);
}

export const getControlWithExcelFieldsList = (page, size, searchValue) => {
    return axios.post(`apis/control-with-excel-fields?page=${page}&size=${size}`, { searchValue }, config);
}

export const saveControlWithExcelFields = (data) => {
    return axios.put(`apis/control-with-excel-fields`, data, config);
}
/************************** ControlWithExcelFields **************************/

/************************** ImportantLinks **************************/
export const getImportantLink = (id) => {
    return axios.get(`apis/important-links/${id}`, config);
}

export const getImportantLinksList = (page, size, filterData) => {
    return axios.post(`apis/important-links?page=${page}&size=${size}`, filterData, config);
}

export const saveImportantLink = (data) => {
    return axios.put(`apis/important-links`, data, config);
}

export const deleteImportantLinks = (id) => {
    return axios.delete(`/apis/important-links/${id}`, config);
}
/************************** ImportantLinks **************************/
/********************************* branch ***********************************/
export const getBranch = (id) => {
    return axios.get(`/api/branches/${id}`, config);
}

export const saveBranch = (data) => {

    return axios.put(`/api/branches`, data, config)
}

export const deleteBranch = (id) => {

    return axios.delete(`/api/branches/${id}`, config)
}

export const getBranchList = (page, size, searchKey, filterData) => {

    return axios.post(`/api/branches?page=${page}&size=${size}&searchKey=${searchKey}`, filterData, config)
}

/********************************* branch ***********************************/

/************************** Division **************************/
export const getDivision = (id) => {
    return axios.get(`/apis/divisions/${id}`, config);
}

export const getDivisionList = (page, size, searchKey, filterData) => {
    return axios.post(`/apis/divisions?page=${page}&size=${size}&searchKey=${searchKey}`, filterData, config);
}

export const saveDivision = (data) => {
    return axios.put(`/apis/divisions`, data, config);
}

export const deleteDivision = (id) => {
    return axios.delete(`/apis/divisions/${id}`, config);
}
/************************** Division **************************/


/*************************** Customer Dues Followup  ***********************************/

export const getCustomerDuesFollowupList = (page, size, filters) => {
    return axios.post(`/apis/customer-dues-follow-up?page=${page}&size=${size}`, filters, config);
}


/*************************** Customer Dues Followup  ***********************************/

/************************** Contract Add Item **************************/
export const getContractAddItem = (id) => {
    return axios.get(`/apis/contract-add-item/${id}`, config);
}

export const getContractAddItemList = (page, size, searchKey, filterData) => {
    return axios.post(`/apis/contract-add-item?page=${page}&size=${size}&searchKey=${searchKey}`, filterData, config);
}

export const saveContractAddItem = (data) => {
    return axios.put(`/apis/contract-add-item`, data, config);
}

export const deleteContractAddItem = (id) => {
    return axios.delete(`/apis/contract-add-item/${id}`, config);
}

export const getLocationGroups = (searchKey) => {
    return axios.get(`/apis/contract-add-item/location-groups?searchKey=${searchKey}`, config);
}

export const getLocations = (searchKey) => {
    return axios.get(`/apis/contract-add-item/locations?searchKey=${searchKey}`, config);
}
/************************** Contract Add Item **************************/

/*************************** inspectors-employees-dues-follow-up  ***********************************/

export const getInspectorsEmployeesDuesFollowUpList = (page, size, filters) => {
    return axios.post(`/apis/inspectors-employees-dues-follow-up?page=${page}&size=${size}`, filters, config);
}

/*************************** inspectors-employees-dues-follow-up  ***********************************/
/************************** Valuation Contracts **************************/

export const getValuationContractsList = (pageNumber, pageSize, filterData) => {
    return axios.post(`apis/valuation-contracts?pageNumber=${pageNumber}&pageSize=${pageSize}`, filterData, config);
}


export const getValuationContracts = (id) => {
    return axios.get(`apis/valuation-contracts/${id}`, config);
}

export const saveValuationContracts = (valuationContract, withSubmit) => {
    return axios.put(`apis/valuation-contracts/${withSubmit}`, valuationContract, config);
}

export const deleteValuationContracts = (id) => {
    return axios.delete(`apis/valuation-contracts/${id}`, config);
}


export const approveValuationContract = (id) => {
    return axios.get(`apis/valuation-contracts/approve/${id}`, config);
}

export const rejectValuationContract = (id) => {
    return axios.get(`apis/valuation-contracts/reject/${id}`, config);
}


/************************** Valuation Contracts **************************/
/********************* credit Notes for Customers Estimation *********************/
export const getCreditNotesCustomersEstimation = (page, size, data) => {
    return axios.post(`apis/credit-notes-customers-estimation?page=${page}&size=${size}`, data, config);
}

export const deleteCreditNotesCustomersEstimation = (id) => {
    return axios.delete(`apis/credit-notes-customers-estimation/${id}`, config);
}

export const saveCreditNoteCustomerEstimation = (data, withSubmit) => {
    return axios.put(`apis/credit-notes-customers-estimation/save/${withSubmit}`, data, config);
}

export const getCreditNoteCustomerEstimation = (id) => {
    return axios.get(`apis/credit-notes-customers-estimation/${id}`, config);
}

export const confirmCreditNoteCustomerEstimation = (id) => {
    return axios.put(`apis/credit-notes-customers-estimation/confirm/${id}`, {}, config);
}

export const rejectCreditNoteCustomerEstimation = (id) => {
    return axios.put(`apis/credit-notes-customers-estimation/reject/${id}`, {}, config);
}

export const unpostCreditNoteCustomerEstimation = (id) => {
    return axios.put(`apis/credit-notes-customers-estimation/unpost/${id}`, {}, config);
}

export const getTranactionsListForCreditNotes = (searchValue, data) => {
    return axios.post(`apis/credit-notes-customers-estimation/transactions-list?q=${searchValue}`, data, config);
}

export const getTransactionList = (searchValue, data) => {
    return axios.post(`apis/credit-notes-customers-estimation/transaction-list`, { searchValue, ...data }, config);
}

export const exportCreditNoteCustomerEstimation = (lines, execlName, waitingBtnId) => {
    exportFile(`apis/credit-notes-customers-estimation/export`, lines, `${execlName}.xlsx`, waitingBtnId);
}

/********************* credit Notes for Customers Estimation *********************/