import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, isAllowedAction, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import EditTableIcon from "../../../icons/EditTable";
import { resetLoginCount, resetUserPassword, updateUserStatus } from "../../../util/apis";
import { USER_STATUS_ACTIVE, USER_STATUS_SUSPENDED, USER_STATUS_TERMINATED } from "../../../util/constants";
import { ACTIVATE_ACTION, USER_RESETTING_ACTION, USER_SUSPENSION_ACTION, USER_TERMINATION_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { FND0042_USERS_DEFINITION_FORM_ID } from "../../../util/constants/forms";

const Actions = (props) => {

    const { user, data, tableRef, dialogRef } = props;
    const { t } = useTranslation()

    const _updateStatus = (status) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return updateUserStatus(data?.id, status)
                .then(() => {
                    tableRef.current.refresh();
                    showSuccessMessage(null, t);
                })
                .catch(error => handleError(error, null, t));
        });
    }

    const _resetLoginCount = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return resetLoginCount(data?.id)
                .then(() => {
                    tableRef.current.refresh();
                    showSuccessMessage(null, t);
                })
                .catch(error => handleError(error, null, t));
        });
    }

    const _resetPasswordAction = () => {
        if (isNotEmpty(data?.id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return resetUserPassword(data?.id)
                    .then(() => showSuccessMessage(null, t))
                    .catch((error) => handleError(error, null, t))
            });
        }
    }

    const _getActions = () => {
        const actions = [];
        if (isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, VIEW_EDIT_ACTION)) {
            actions.push({
                id: "action1",
                label: t(`edit`),
                action: () => dialogRef?.current?.open(data?.id),
                icon: <EditTableIcon />,
            });
        }

        if (data?.isActive == USER_STATUS_ACTIVE && isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, USER_SUSPENSION_ACTION)) {
            actions.push({
                id: "action2",
                label: t(`usersDefinition:suspend`),
                action: () => _updateStatus(USER_STATUS_SUSPENDED)
            });
        }

        if (data?.isActive == USER_STATUS_SUSPENDED && isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, ACTIVATE_ACTION)) {
            actions.push({
                id: "action3",
                label: t(`usersDefinition:activate`),
                action: () => _updateStatus(USER_STATUS_ACTIVE)
            });
        }

        if (data?.isActive == USER_STATUS_ACTIVE && isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, USER_TERMINATION_ACTION)) {
            actions.push({
                id: "action4",
                label: t(`usersDefinition:terminate`),
                action: () => _updateStatus(USER_STATUS_TERMINATED)
            });
        }

        if (data?.isActive != USER_STATUS_TERMINATED) {
            if (isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, USER_RESETTING_ACTION)) {
                actions.push({
                    id: "action5",
                    label: t(`usersDefinition:reset_number_of_login_attempts`),
                    action: () => _resetLoginCount()
                });
            }

            if (isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, USER_RESETTING_ACTION)) {
                actions.push({
                    id: "action6",
                    label: t(`usersDefinition:reset_password`),
                    action: _resetPasswordAction,
                });
            }
        }

        return actions;
    }

    return (<TableActionsButton buttons={_getActions()} />)
}

export default Actions;