import Image from '@ipgd-gauge/image';
import { formatMoney, isAllowedForm, isEnglish } from '@ipgd-gauge/utils';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import NoDataFound from '../../../components/no-data-found';
import Paginate from '../../../components/paginate';
import Waiting from '../../../components/waiting';
import { getInspectorsEmployeesDuesFollowUpList } from '../../../util/apis';
import { PROPERTIES_PARTIES_DEFINITION, STATUS_ACTIVE } from '../../../util/constants';
import { RSM3268_CUSTOMER_DUES_FOLLOW_UP } from '../../../util/constants/forms';
import { getUser } from '../../../util/cookies';
import style from './style.module.scss';

const Card = (props) => {
    const { t } = useTranslation();
    const { data, isTrnx, navigate, user, serverUrl } = props

    const invoiceCreateAction = () => {
        if (data.notInvoiced <= 0 && isAllowedForm(user, RSM3268_CUSTOMER_DUES_FOLLOW_UP)) {
            navigate(`/estimators-inspectors-invoice${data.lastInvoiceId ? `?id=${data.lastInvoiceId}` : data.isActive == STATUS_ACTIVE ? `?partyId=${data?.partyId}` : ''}`)
        }
    }

    return (
        <div
            className={` ${style.cardContainer} ${data.notInvoiced <= 0 ? style.createInvoice : ""}`}
            title={data.notInvoiced <= 0 ? t('customerDuesFollowUp:invoice_creation') : ""}
        >
            {data.notInvoiced <= 0 && isAllowedForm(user, RSM3268_CUSTOMER_DUES_FOLLOW_UP) &&
                <button className={style.executeAction}  onClick={invoiceCreateAction}>
                    {t('customerDuesFollowUp:invoice_creation')}
                </button>
            }
            <div className={style.userInfoCard}>
                <div className={style.imgContainer}>
                    <Image  isCustomer src={`${serverUrl}/apis/attachments/img-attachment/${data.partyId}/${PROPERTIES_PARTIES_DEFINITION}`} />
                </div>
                <div className={style.userName}>
                    <label>{isEnglish() ? data.partyNameLo : data.partyNameFo}</label>
                </div>
            </div>
            <div className={style.cardDetails}>
                <div>
                    <label className={style.cardLbl}>{isTrnx ? t('customerDuesFollowUp:no_trxs') : t('customerDuesFollowUp:total_incomes')}</label>
                    <div className={style.cardVal}>
                        <label>
                            {formatMoney(data.trxns, isTrnx ? "0" : 2)}
                        </label>
                    </div>
                </div>
                <div>
                    <label className={style.cardLbl}>{t('customerDuesFollowUp:invoiced')}</label>
                    <div className={style.cardVal}>
                        <label>
                            {formatMoney(data.invoiced, isTrnx ? "0" : 2)}
                        </label>
                    </div>
                </div>
                <div>
                    <label className={style.cardLbl}>{t('customerDuesFollowUp:not_invoiced')}</label>
                    <div className={style.cardVal}>
                        <label>
                            {formatMoney(data.notInvoiced, isTrnx ? "0" : 2)}
                        </label>
                    </div>
                </div>
                <div>
                    <label className={style.cardLbl}>{t('customerDuesFollowUp:in_progress')}</label>
                    <div className={style.cardVal}>
                        <label>
                            {formatMoney(data.inProgress, isTrnx ? "0" : 2)}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Lines = (props, ref) => {
    const navigate = useNavigate();
    const { isTrnx, filterRef, isTab } = props
    const { t } = useTranslation();
    const user = getUser();
    const [data, setData] = useState(null);
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const fetchData = (page, size, isTrx) => {
        if (filterRef?.current) {
            setData(null)
            const filters = filterRef?.current?.getFilterData() || {};
            filters.isTrnx = isTrx == null ? (isTrnx == null ? true : isTrnx) : isTrx;
            getInspectorsEmployeesDuesFollowUpList(page || "0", size || "24", filters).then(res => {
                setData(res.data)
            })
        }
    }
    useImperativeHandle(ref, () => ({
        refresh: fetchData
    }))

    return (
        <>
            {data == null &&
                <Waiting />}
            <div className={`${style.cardPanel} ${isTab? style.cardPanelTab :''}`}>
                {data && data.list && data.list.length == 0 &&
                    <NoDataFound />}
                {data && data.list && data.list.length > 0 && data.list?.map((option, index) => {
                    return (
                        <Card user={user} serverUrl={serverUrl} key={index} data={option} isTrnx={isTrnx} navigate={navigate} />
                    );
                })}
            </div>
            <div className={style.paginate}>
                <Paginate fetchData={fetchData} totalItems={data?.totalItems} totalPages={data?.totalPages} currentPage={data?.currentPage} />
            </div>
        </>
    )
}
export default forwardRef(Lines);