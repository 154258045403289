import React from "react";

function FavouriteImgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.589"
      height="25.663"
      viewBox="0 0 28.589 25.663"
    >
      <path
        fill="#a7a7a7"
        d="M13.544 24.913a3.594 3.594 0 01-1.151-.175 22.136 22.136 0 01-8.069-5.307A16.694 16.694 0 01-.75 7.68 8.42 8.42 0 017.641-.75a8.259 8.259 0 015.906 2.45 8.372 8.372 0 0114.292 5.98 16.694 16.694 0 01-5.074 11.751 22.136 22.136 0 01-8.065 5.308 3.594 3.594 0 01-1.156.174zM7.641 1.245a6.423 6.423 0 00-6.4 6.436c0 9.1 8.737 14.127 11.8 15.172h.01a1.962 1.962 0 00.985 0h.01c3.061-1.045 11.8-6.075 11.8-15.172a6.378 6.378 0 00-11.5-3.86 1 1 0 01-1.6 0 6.3 6.3 0 00-5.105-2.576z"
        transform="translate(.75 .75)"
      ></path>
    </svg>
  );
}

export default FavouriteImgIcon;
