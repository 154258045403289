import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues, getPartiesList216 } from "../../../util/apis";
import { STATUS_DOMAIN, USER_TYPE_DOMAIN } from "../../../util/constants";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";

const PropertiesUsersDefinitionFilter = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();
    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {})

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            id="properties-users-definition-filter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            tableRef={tableRef}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={2}
            formId={RSM2661_PROPERTIES_USERS_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('propertiesUsersDefinition:type')}</label>
                            <FrmSelectMenu
                                id="properties-users-definition-filter-type"
                                value={filterData.type}
                                isClearable={true}
                                isMulti={true}
                                api={() => getDomainValues(USER_TYPE_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("type", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('propertiesUsersDefinition:target')}</label>
                            <FrmSelectMenu
                                id="properties-users-definition-filter-target"
                                value={filterData.target}
                                isClearable={true}
                                isMulti={true}
                                api={() => getPartiesList216(null, true, filterData.target)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("target", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('propertiesUsersDefinition:status')}</label>
                            <StatusSelectMenu
                                id="properties-users-definition-filter-target"
                                value={filterData.status}
                                isClearable={true}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </AdvanceFilter>
    )
}

export default forwardRef(PropertiesUsersDefinitionFilter);