import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues, getDomainValuesWithCondition } from "../../../util/apis";
import { PROPORTIES_PARTIES_TYPE, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR, PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_INSPECTOR, STATUS_DOMAIN } from "../../../util/constants";
import { RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID } from "../../../util/constants/forms";

const InspectorsAndEmployeesCategoriesFilter = (props, ref) => {
    const { tableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return Object.assign(filterData, {});
    }
    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    return (
        <AdvanceFilter
            id="properties-parties-categories-filter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={1}
            hideMoreOptions
            formId={RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('propertiesPartiesCategories:status')}</label>
                            <StatusSelectMenu
                                id="properties-users-definition-filter-status"
                                value={filterData.status}
                                isClearable={true}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('propertiesPartiesCategories:type')}</label>
                            <FrmSelectMenu
                                id="properties-users-definition-filter-type"
                                value={filterData.type}
                                isClearable={true}
                                isMulti={true}
                                api={() => getDomainValuesWithCondition(PROPORTIES_PARTIES_TYPE, true, [PROPORTIES_PARTIES_TYPE_INSPECTOR, PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("type", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </AdvanceFilter>
    )
}

export default forwardRef(InspectorsAndEmployeesCategoriesFilter);