import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { checkValidation, handleError, hideWaiting, isEmpty, isNotEmpty, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modal";
import { getCommonCustomers, linkWithCustomer } from "../../../../util/apis";
import { STATUS_ACTIVE } from "../../../../util/constants";
import style from './style.module.scss';

const dialogId = "create-transaction-dialog";
const CreateTransactionDialog = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();

    const [data, setData] = useState({ id: null, atrId: null });

    const dialogRef = useRef();

    const open = (id) => {
        if (isNotEmpty(id)) {
            dialogRef.current.open();
            setData({ id: id, atrId: null });
        }
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _linkWithCustomer = () => {

        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }
        showWaiting(`${dialogId}-okButton`, true)
        linkWithCustomer(data)
            .then(() => {
                tableRef?.current?.refresh();
                close();
                showSuccessMessage('action_performed_successfully', t);
            })
            .catch(error => handleError(error, null, t))
            .finally(() => hideWaiting(`${dialogId}-okButton`))
    }

    return (
        <Modal
            id={dialogId}
            ref={dialogRef}
            title={t('estimationQuotation:link_with_customer')}
            withFooter="true"
            okBtnLabel={t("estimationQuotation:save")}
            withOkButton="true"
            withCancelButton="true"
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('estimationQuotation:exit')}
            className={style.createTransactionDialog}
            okBtnAction={_linkWithCustomer}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${dialogId}-customer-lbl`} className='form-label mandatory'>{t('estimationQuotation:customer')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-customer`}
                            value={data?.atrId}
                            onChange={value => {
                                data.atrId = value.value;
                                setData({ ...data });
                            }}
                            defualtValueId={data?.atrId}
                            defualtValueApi={() => getCommonCustomers(isEmpty(data?.atrId) ? [] : [data?.atrId], STATUS_ACTIVE, null, true, false, false, [], false)}
                            api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, true, false, false, [], false)}
                            descLo={['code', 'descLo']}
                            descFo={['code', 'descFo']}
                            isAsync={true}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(CreateTransactionDialog);
