import React from "react";

function NotExecutedTasksIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.484"
      height="25.341"
      viewBox="0 0 23.484 25.341"
    >
      <g data-name="Group 179930" transform="translate(4674 10935)">
        <g data-name="Group 179928" transform="translate(-4674 -10935)">
          <g fill="#292d32" data-name="Group 179929">
            <g data-name="Group 179933">
              <path
                d="M12.656 6.33a6.328 6.328 0 10-6.325 6.327 6.344 6.344 0 006.325-6.327M1.1 6.33a5.226 5.226 0 115.227 5.227A5.226 5.226 0 011.1 6.33"
                data-name="Path 43625"
                opacity="0.47"
              ></path>
              <path
                d="M6.448 7.634a.7.7 0 00.7-.7v-3.51a.7.7 0 00-1.394 0v3.518a.7.7 0 00.7.692"
                data-name="Path 43626"
              ></path>
              <path
                d="M6.455 9.927a.877.877 0 000-1.754h-.006a.877.877 0 000 1.754z"
                data-name="Path 43627"
              ></path>
            </g>
            <path
              d="M9.184 16.822a.75.75 0 000 1.5h5a.75.75 0 000-1.5z"
              data-name="Path 43628"
            ></path>
            <path
              d="M17.934 13.572a.75.75 0 00-.75-.75h-8a.75.75 0 000 1.5h8a.75.75 0 00.75-.75"
              data-name="Path 43629"
            ></path>
            <path
              d="M22.034 5.426c-.911-.985-2.224-1.475-4.259-1.585h-3.842a.75.75 0 000 1.5h3.781a4.484 4.484 0 013.219 1.1c.708.766 1.052 2.119 1.052 4.136v7.26h-2.25a3.383 3.383 0 00-3.75 3.75v2.25h-5.25c-2.2 0-3.567-.4-4.31-1.249-.659-.756-.94-1.959-.94-4.021v-4.628a.75.75 0 00-1.5 0v4.632c0 1.73.156 3.683 1.31 5.007 1.048 1.2 2.777 1.763 5.44 1.763h6c.017 0 .031-.009.048-.01a.727.727 0 00.194-.039.649.649 0 00.074-.026.742.742 0 00.2-.139l.009-.006 6-6c.009-.009.012-.021.02-.03a.74.74 0 00.126-.187c.01-.021.015-.043.023-.064a.761.761 0 00.042-.208c0-.015.008-.027.008-.041V10.58c0-2.445-.461-4.083-1.45-5.154"
              data-name="Path 43630"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NotExecutedTasksIcon;