import moment from 'moment';
export const formatDate = (time) => {
    if (time) {
        function join(t, a, s) {
            function format(m) {
                let f = new Intl.DateTimeFormat('en', m);
                return f.format(t).padStart(2, '0');
            }
            return a.map(format).join(s);
        }

        let a = [{ day: 'numeric' }, { month: 'numeric' }, { year: 'numeric' }];
        return join(time, a, '/');
    }

    return '';
}
export const formatDateNTime = (time) => {
    if (time) {
        let dateTime = formatDate(time);
        let date = new Date(Number(time));
        return `${dateTime} - ${`${date.getHours()}`.padStart(2, "0")}:${`${date.getMinutes()}`.padStart(2, "0")}`;
    }

    return '';
}

export const getEndOfTimeDay = (time) => {
    const dayInMs = 24 * 60 * 60 * 1000;
    return (time + dayInMs - 1)

}


export const getCurrentDay = (currentDate) => {
    const start = moment(currentDate).startOf('day').valueOf();
    const end = moment(currentDate).endOf('day').valueOf();

    return [start, end];
}

export const getCurrentWeek = (currentDate) => {
    const start = moment(currentDate).startOf('week').valueOf();
    const end = moment(currentDate).endOf('week').valueOf();

    return [start, end];
}

export const getCurrentMonth = (currentDate) => {
    const start = moment(currentDate).startOf('month').valueOf();
    const end = moment(currentDate).endOf('month').valueOf();

    return [start, end];
}

export const getCurrentYear = (currentDate) => {
    const start = moment(currentDate).startOf('year').valueOf();
    const end = moment(currentDate).endOf('year').valueOf();

    return [start, end];
}

export const getPreviousDay = (currentDate) => {
    var date = new Date(currentDate);
    date.setDate(date.getDate() - 1);

    return getCurrentDay(date.getTime());
}

export const getPreviousWeek = (currentDate) => {
    var date = new Date(currentDate);
    date.setDate(date.getDate() - 7);

    return getCurrentWeek(date.getTime());
}

export const getPreviousMonth = (currentDate) => {
    var date = new Date(currentDate);
    date.setMonth(date.getMonth() - 1);

    return getCurrentMonth(date.getTime());
}

export const getPreviousYear = (currentDate) => {
    var date = new Date(currentDate);
    date.setFullYear(date.getFullYear() - 1);

    return getCurrentYear(date.getTime());
}