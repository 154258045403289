import React from "react";

function PriceAgreementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.49"
      height="21.503"
      viewBox="0 0 18.49 21.503"
    >
      <g
        fill="#292d32"
        data-name="Group 179943"
        transform="translate(4676.25 11037.75)"
      >
        <path
          d="M8.495 20.753a2.612 2.612 0 01-2.063-1.1L5.419 18.3a1.065 1.065 0 00-.848-.467 1.1 1.1 0 00-.793.379 3.474 3.474 0 01-2.412 1.332 1.857 1.857 0 01-1.644-1.017A4.524 4.524 0 01-.75 16.3V5.04C-.75 2.682-.418 1.366.395.5 1.42-.6 3.079-.75 4.72-.75h7.56c2.19 0 3.422.34 4.254 1.174.862.865 1.212 2.2 1.206 4.618V16.31a4.525 4.525 0 01-.47 2.222 1.852 1.852 0 01-1.642 1.017 3.483 3.483 0 01-2.415-1.335 1.1 1.1 0 00-.795-.38 1.065 1.065 0 00-.848.467l-1.009 1.349a2.614 2.614 0 01-2.066 1.103zm-3.923-4.42A2.563 2.563 0 016.62 17.4l1.011 1.351a1.117 1.117 0 00.864.5 1.12 1.12 0 00.867-.505l1.007-1.346a2.506 2.506 0 013.939-.212c.747.8 1.2.86 1.319.86.36 0 .612-.715.612-1.738V5.04c0-1.949-.225-3.012-.768-3.557C14.959.969 14 .75 12.28.75H4.72c-1.758 0-2.72.231-3.23.771S.75 3.128.75 5.04V16.3c0 1.026.254 1.743.617 1.743.121 0 .572-.062 1.316-.856a2.576 2.576 0 011.889-.854z"
          transform="translate(-4675.5 -11037)"
        ></path>
        <path
          d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(-4671 -11032)"
        ></path>
        <path
          d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(-4670 -11028)"
        ></path>
      </g>
    </svg>
  );
}

export default PriceAgreementIcon;