import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getDomainValues, getPartiesDefinitionCategories } from "../../../util/apis";
import { DOMAIN_PROPORTIES_PARTIES_TYPE, STATUS_DOMAIN } from "../../../util/constants";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import StatusSelectMenu from "../../../components/status-select-menu";

const PropertiesPartiesDefinitionFilter = (props, ref) => {
    const { propertiesPartiesDefinitionTableRef } = props;

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});
    const advanceFilterRef = useRef();

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return Object.assign(filterData, {});
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    return (
        <AdvanceFilter
            id="propertiesPartiesDefinitionFilter"
            formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => propertiesPartiesDefinitionTableRef?.current?.refresh()}
            numOfRows={2}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('propertiesPartiesDefinition:status')}</label>
                            <StatusSelectMenu
                                id="status"
                                value={filterData.status}
                                isClearable={true}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('propertiesPartiesDefinition:category')}</label>
                            <FrmSelectMenu
                                id="category"
                                value={filterData.categories}
                                onChange={value => _handleDataChanged("categories", value)}
                                api={() => getPartiesDefinitionCategories(null, true, [], false, null, filterData.categories)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                isClearable={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('propertiesPartiesDefinition:type')}</label>
                            <FrmSelectMenu
                                id="type"
                                isMulti={true}
                                value={filterData.types}
                                isClearable={true}
                                api={() => getDomainValues(DOMAIN_PROPORTIES_PARTIES_TYPE)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("types", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(PropertiesPartiesDefinitionFilter);