import React from "react";

function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.13"
      height="2"
      viewBox="0 0 16.13 2"
    >
      <path
        fill="none"
        stroke="#707070"
        strokeWidth="2"
        d="M16.13 0L0 0"
        data-name="Line 136"
        transform="translate(0 1)"
      ></path>
    </svg>
  );
}

export default MinusIcon;
