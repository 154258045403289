import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import AttentionIcon from "../../icons/Attention";
import InformationIcon from "../../icons/Information";
import RemindIcon from "../../icons/Remind";
import TrackingIcon from "../../icons/Tracking";
import UpdateIcon from "../../icons/Update";
import { ALERT_TYPE_ALERT, ALERT_TYPE_FOLLOW_UP, ALERT_TYPE_INFORMATION, ALERT_TYPE_REMINDER, ALERT_TYPE_UPDATE_DATA } from "../../util/constants";
import style from './style.module.scss';

const MessageTypesSelectMenu = (props) => {
    const { id, key, value, api, options, descLo, descFo, onChange, isClearable, isMulti, isDisabled } = props;

    // const _getMessageIcons = (valueId) => {
    //     if (valueId == ALERT_TYPE_ALERT) {
    //         return <AttentionIcon color="var(--red)" />
    //     } else if (valueId == ALERT_TYPE_REMINDER) {
    //         return <RemindIcon color="#fed049" />
    //     } else if (valueId == ALERT_TYPE_INFORMATION) {
    //         return <InformationIcon color="var(--primary-color)" />
    //     } else if (valueId == ALERT_TYPE_FOLLOW_UP) {
    //         return <TrackingIcon color="var(--secondary-1)" />
    //     } else if (valueId == ALERT_TYPE_UPDATE_DATA) {
    //         return <UpdateIcon color="var(--secondary-2)" />
    //     }
    // }

    const MyOption = (props) => {
        const { value, label } = props;

        return (
            <div className={style.optionContainer}>
                {/* {_getMessageIcons(value)} */}
                {value == ALERT_TYPE_REMINDER &&
                    <RemindIcon color="#fed049" />
                }
                {value == ALERT_TYPE_UPDATE_DATA &&
                    <UpdateIcon color="var(--secondary-2)" />
                }
                {value == ALERT_TYPE_INFORMATION &&
                    <InformationIcon color="var(--primary-color)" />
                }
                {value == ALERT_TYPE_ALERT &&
                    <AttentionIcon color="var(--red)" />
                }
                {value == ALERT_TYPE_FOLLOW_UP &&
                    <TrackingIcon color="var(--secondary-1)" />
                }
                <span>{label}</span>
            </div>
        )
    }

    return (
        <FrmSelectMenu
            id={id}
            key={key}
            value={value}
            api={api}
            options={options}
            OptionComponent={MyOption}
            descLo={descLo}
            descFo={descFo}
            onChange={onChange}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
            portalTarget={document.body}
        />
    )
}

export default MessageTypesSelectMenu;