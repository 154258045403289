import { isAllowedForm } from "@ipgd-gauge/utils";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { FND0042_USERS_DEFINITION_FORM_ID, FND0133_USERS_LOGIN_LOG_FORM_ID, FND2319_CITIES_DEFINITION_FORM_ID, FND2621_TOWNS_DEFINITION_FORM_ID, FND3168_REPORTS_DESIGN_FORM_ID, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, RSM2659_PROPERTIES_TYPES_FORM_ID, RSM2661_PROPERTIES_USERS_FORM_ID, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, RSM2669_BRANCHES_DEFINITIONS_FORM_ID, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, RSM2877_ADDITIONAL_ITEMS_FORM_ID, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, RSM3007_PRICE_AGREEMENT_FORM_ID, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID, RSM3190_EVALUATION_QUOTATIONS_FORM_ID, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, RSM3230_VALUATION_TRANSACTIONS_FORM_ID, RSM3232_DELEGATION_FORM_ID, RSM3234_PROPERTIES_MAP_FORM_ID, RSM3236_VALUATION_HISTORY_FORM_ID, RSM3240_NOT_EXECUTED_TASKS_FORM_ID, RSM3242_TRANSACTIONS_STATISTICS_FORM_ID, RSM3244_TASKS_STATISTICS_FORM_ID, RSM3246_INBOX_FORM_ID, RSM3248_OUTBOX_FORM_ID, RSM3250_VALUATION_REPORTS_FORM_ID, RSM3252_TASKS_LIST_FORM_ID, RSM3266_IMPORTATNT_LINKS, RSM3272_EVALUATION_CONTRACTS_FORM_ID, RSM3274_CONTROL_WITH_EXCEL_FIELDS, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../util/constants/forms";
import MAIN from "../views";
import AdditionalItems from "../views/additional-items";
import BranchesDefinition from "../views/branches-definition";
import Cities from "../views/cities";
import ControlWithExcelFields from "../views/control-with-excel-fields";
import CustomerDuesFollowUp from "../views/customer-dues-follow-up";
import Customers from "../views/customers";
import CustomersEstimationInvoices from "../views/customers-estimation-invoices";
import CustomersEstimationInvoice from "../views/customers-estimation-invoices/invoices";
import Delegation from "../views/delegation";
import DivisionDefinition from "../views/division-definition";
import EstimationFeesCommissionsCalculation from "../views/estimation-fees-commissions-calculation";
import EstimationInvoicesFollowUp from "../views/estimation-invoices-follow-up";
import EstimationInvoicesRegister from "../views/estimation-invoices-register";
import EstimationMap from "../views/estimation-map";
import EstimationMessagesTemplates from "../views/estimation-messages-templates";
import EstimationQuotations from "../views/estimation-quotations";
import EstimationQuotationsTypes from "../views/estimation-quotations-types";
import EstimationQuotation from "../views/estimation-quotations/estimation-quotation";
import EstimationReports from "../views/estimation-reports";
import EstimationTransactions from "../views/estimation-transactions";
import EstimationTransactionsRegister from "../views/estimation-transactions-register";
import EstimationTransactionsStatuses from "../views/estimation-transactions-statuses";
import EstimationTrx from "../views/estimation-transactions/estimation-trx";
import TrxSummary from "../views/estimation-transactions/trx-summary";
import EstimationsHistory from "../views/estimations-history";
import EstimatorsInspectorsInvoices from "../views/estimators-inspectors-invoices";
import EstimatorsInspectorsInvoice from "../views/estimators-inspectors-invoices/estimators-inspectors-invoice";
import PropertiesPolicies from "../views/evaluation-policies";
import EvaluationPolicy from "../views/evaluation-policies/evaluation-policy";
import ImportantLinks from "../views/important-links";
import Inbox from "../views/inbox";
import InspectorsAndEmployeesCategories from "../views/inspectors-and-employees-categories";
import InspectorsEmployeesDuesFollowUp from "../views/inspectors-employees-dues-follow-up";
import NotExecutedTasks from "../views/not-executed-tasks";
import Outbox from "../views/outbox";
import PriceAgreements from "../views/price-agreements";
import PriceAgreement from "../views/price-agreements/price-agreement";
import Profile from "../views/profile";
import PropertiesEstimationListOfValues from "../views/properties-estimation-list-of-values";
import PropertiesPartiesDefinition from "../views/properties-parties-definition";
import PropertiesTypes from "../views/properties-types";
import PropertiesUsersDefinition from "../views/properties-users-definition";
import PropertiesValuationCustomReports from "../views/properties-valuation-custom-reports";
import ReportsDesign from "../views/reports-design";
import TasksList from "../views/tasks-list";
import TasksStatistics from "../views/tasks-statistics";
import Test from "../views/test";
import Towns from "../views/towns";
import TransactionsStatistics from "../views/transactions-statistics";
import UpdateValuationFields from "../views/update-valuation-fields";
import UsersDefinition from "../views/users-definition";
import UsersLog from "../views/users-login-log";
import ValuationContracts from "../views/valuation-contracts";
import ValuationContract from "../views/valuation-contracts/valuation-contract";
import EstimationFormsFieldsBuilder from "../views/valuation-forms";
import CreditNotesforCustomersEstimation from "../views/credit-notes-for-customers-estimation";
import CreditNotesforCustomerEstimation from "../views/credit-notes-for-customers-estimation/credit-notes-for-customer-estimation";
import EstimationFormField from "../views/valuation-forms/frm";
import WorkflowProcedureMessages from "../views/workflow-procedure-and-messages";
import Home from "../views/home";
import MyFavorite from "../views/my-favorites";

const Router = () => {

    const user = useSelector(state => state.persistedReducer.userReducer.user);

    return (
        <Routes>
            <Route path="/" element={<MAIN />} >
                {<Route path='/Home' element={<Home />} />}
                {<Route path='/my-favorites' element={<MyFavorite />} />}
                {isAllowedForm(user, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID) && <Route path='/customers' element={<Customers />} />}
                {isAllowedForm(user, FND2621_TOWNS_DEFINITION_FORM_ID) && <Route path='/towns' element={<Towns />} />}
                {isAllowedForm(user, FND2319_CITIES_DEFINITION_FORM_ID) && <Route path='/cities' element={<Cities />} />}
                {isAllowedForm(user, FND0133_USERS_LOGIN_LOG_FORM_ID) && <Route path='/users-login-log' element={<UsersLog />} />}
                {isAllowedForm(user, FND3168_REPORTS_DESIGN_FORM_ID) && <Route path='/reports-design' element={<ReportsDesign />} />}
                {isAllowedForm(user, RSM3250_VALUATION_REPORTS_FORM_ID) && <Route path='/estimation-reports' element={<EstimationReports />} />}
                {isAllowedForm(user, RSM3252_TASKS_LIST_FORM_ID) && <Route path='/tasks-list' element={<TasksList />} />}
                {isAllowedForm(user, RSM3190_EVALUATION_QUOTATIONS_FORM_ID) && <Route path='/estimation-quotations' element={<EstimationQuotations />} />}
                {isAllowedForm(user, RSM3190_EVALUATION_QUOTATIONS_FORM_ID) && <Route path='/estimation-quotation' element={<EstimationQuotation />} />}
                {isAllowedForm(user, RSM3232_DELEGATION_FORM_ID) && <Route path='/delegation' element={<Delegation />} />}
                {isAllowedForm(user, RSM3236_VALUATION_HISTORY_FORM_ID) && <Route path='/estimations-history' element={<EstimationsHistory />} />}
                {isAllowedForm(user, RSM3240_NOT_EXECUTED_TASKS_FORM_ID) && <Route path='/not-executed-tasks' element={<NotExecutedTasks />} />}
                {isAllowedForm(user, RSM3242_TRANSACTIONS_STATISTICS_FORM_ID) && <Route path='/transactions-statistics' element={<TransactionsStatistics />} />}
                {isAllowedForm(user, RSM3244_TASKS_STATISTICS_FORM_ID) && <Route path='/tasks-statistics' element={<TasksStatistics />} />}
                {isAllowedForm(user, RSM3234_PROPERTIES_MAP_FORM_ID) && <Route path='/estimation-map' element={<EstimationMap />} />}
                {isAllowedForm(user, RSM3246_INBOX_FORM_ID) && <Route path='/inbox' element={<Inbox />} />}
                {isAllowedForm(user, RSM3248_OUTBOX_FORM_ID) && <Route path='/outbox' element={<Outbox />} />}
                {isAllowedForm(user, RSM3230_VALUATION_TRANSACTIONS_FORM_ID) && <Route path='/estimation-trx' element={<EstimationTrx />} />}
                {isAllowedForm(user, RSM3230_VALUATION_TRANSACTIONS_FORM_ID) && <Route path='/estimation-transactions' element={<EstimationTransactions />} />}
                <Route path='/trx-summary' element={<TrxSummary />} />
                {isAllowedForm(user, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID) && <Route path='/estimation-transactions-statuses' element={<EstimationTransactionsStatuses />} />}
                {isAllowedForm(user, RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID) && <Route path='/estimation-fees-commissions-calculation' element={<EstimationFeesCommissionsCalculation />} />}
                {isAllowedForm(user, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID) && <Route path='/estimation-invoices-follow-up' element={<EstimationInvoicesFollowUp />} />}
                {isAllowedForm(user, RSM3007_PRICE_AGREEMENT_FORM_ID) && <Route path='/price-agreements' element={<PriceAgreements />} />}
                {isAllowedForm(user, RSM3007_PRICE_AGREEMENT_FORM_ID) && <Route path='/price-agreement' element={<PriceAgreement />} />}
                {isAllowedForm(user, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID) && <Route path='/estimation-messages-templates' element={<EstimationMessagesTemplates />} />}
                {isAllowedForm(user, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID) && <Route path='/valuation-forms' element={<EstimationFormsFieldsBuilder />} />}
                {isAllowedForm(user, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID) && <Route path='/valuation-form' element={<EstimationFormField />} />}
                {isAllowedForm(user, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID) && <Route path='/workflow-procedure-and-messages' element={<WorkflowProcedureMessages />} />}
                {isAllowedForm(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID) && <Route path='/customers-estimation-invoice' element={<CustomersEstimationInvoice />} />}
                {isAllowedForm(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID) && <Route path='/customers-estimation-invoices' element={<CustomersEstimationInvoices />} />}
                {isAllowedForm(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID) && <Route path='/estimators-inspectors-invoices' element={<EstimatorsInspectorsInvoices />} />}
                {isAllowedForm(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID) && <Route path='/estimators-inspectors-invoice' element={<EstimatorsInspectorsInvoice />} />}
                {isAllowedForm(user, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID) && <Route path='/estimation-invoices-register' element={<EstimationInvoicesRegister />} />}
                {isAllowedForm(user, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID) && <Route path='/estimation-quotations-types' element={<EstimationQuotationsTypes />} />}
                {isAllowedForm(user, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID) && <Route path='/update-valuation-fields' element={<UpdateValuationFields />} />}
                {isAllowedForm(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID) && <Route path='/evaluation-policies' element={<PropertiesPolicies />} />}
                {isAllowedForm(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID) && <Route path='/evaluation-policy' element={<EvaluationPolicy />} />}
                {isAllowedForm(user, RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID) && <Route path='/estimation-transactions-register' element={<EstimationTransactionsRegister />} />}
                {isAllowedForm(user, RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID) && <Route path='/properties-parties-definition' element={<PropertiesPartiesDefinition />} />}
                {isAllowedForm(user, RSM2661_PROPERTIES_USERS_FORM_ID) && <Route path='/properties-users-definition' element={<PropertiesUsersDefinition />} />}
                {isAllowedForm(user, RSM2659_PROPERTIES_TYPES_FORM_ID) && <Route path='/properties-types' element={<PropertiesTypes />} />}
                {isAllowedForm(user, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID) && <Route path='/inspectors-and-employees-categories' element={<InspectorsAndEmployeesCategories />} />}
                {<Route path='/customer-dues-follow-up' element={<CustomerDuesFollowUp />} />}
                {<Route path='/inspectors-employees-dues-follow-up' element={<InspectorsEmployeesDuesFollowUp />} />}
                <Route path='/test' element={<Test />} />
                {/* ****************************PRO************************************ */}
                {isAllowedForm(user, RSM2669_BRANCHES_DEFINITIONS_FORM_ID) && <Route path='/branches-definition' element={<BranchesDefinition />} />}
                {isAllowedForm(user, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID) && <Route path='/division-definition' element={<DivisionDefinition />} />}
                {/* <Route path='/financial-periods' element={<FinancialPeriods />} /> */}
                {/* <Route path='/fiscal-periods' element={<FiscalPeriods />} /> */}
                {/* <Route path='/notification-channels' element={<NotificationChannels />} /> */}
                {/* <Route path='/branches-inter-current-accounts-setup' element={<BranchesAccountsSetup />} /> */}
                {/* <Route path='/banks-accounts-definition' element={<BanksAccountsDefinition />} /> */}
                {isAllowedForm(user, FND0042_USERS_DEFINITION_FORM_ID) && <Route path='/users-definition' element={<UsersDefinition />} />}
                {/* <Route path='/closing-vouchers' element={<ClosingVouchers />} /> */}
                {/* <Route path='/properties-notifications-setup' element={<PropertiesNotificationsSetup />} /> */}
                {isAllowedForm(user, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID) && <Route path='/properties-valuation-custom-reports' element={<PropertiesValuationCustomReports />} />}
                {/* <Route path='/custom-financial-reports' element={<CustomFinancialReports/>} /> */}
                {isAllowedForm(user, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID) && <Route path='/properties-estimation-list-of-values' element={<PropertiesEstimationListOfValues />} />}
                <Route path='/profile' element={<Profile />} />
                {isAllowedForm(user, RSM3274_CONTROL_WITH_EXCEL_FIELDS) && <Route path='/control-with-excel-fields' element={<ControlWithExcelFields />} />}
                {isAllowedForm(user, RSM2877_ADDITIONAL_ITEMS_FORM_ID) && <Route path='/additional-items' element={<AdditionalItems />} />}
                {/* {isAllowedForm(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID) &&  */}
                {isAllowedForm(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID) && <Route path='/valuation-contracts' element={<ValuationContracts />} />}
                {isAllowedForm(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID) && <Route path='/valuation-contract' element={<ValuationContract />} />}

                {isAllowedForm(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID) && <Route path='/credit-notes-for-customers-estimation' element={<CreditNotesforCustomersEstimation />} />}
                {isAllowedForm(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID) && <Route path='/credit-notes-for-customer-estimation' element={<CreditNotesforCustomerEstimation />} />}

                {isAllowedForm(user, RSM3266_IMPORTATNT_LINKS) &&
                    <Route path='/important-links' element={<ImportantLinks />} />
                }
            </Route>
        </Routes>
    )
}

export default Router;