import { formatDate, formatMoney, handleError } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PrintDialog from "../../../components/print-dialog";
import Slisting from "../../../components/slisting";
import { deleteEstimatorInvoice, getEstimatorInvoices } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Actions from "./actions";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, printDialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.estimatorsInspectorsInvoicesRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimatorsInspectorsInvoices:invoice_num')}</label>
                <label>{data.code}</label>
            </div>

            <div title={formatDate(data.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimatorsInspectorsInvoices:invoice_date')}</label>
                <label>{formatDate(data.dateDgr)}</label>
            </div>

            <div title={`${i18next.language == 'en' ? data.branchLo : data.branchFo} - ${i18next.language == 'en' ? data.divisionLo : data.divisionFo}`} className="cardRowMode">
                <label className="nameLblMobile">{t('estimatorsInspectorsInvoices:branch_division')}</label>
                <label>{`${i18next.language == 'en' ? data.branchLo : data.branchFo} - ${i18next.language == 'en' ? data.divisionLo : data.divisionFo}`}</label>
            </div>

            <div title={formatMoney(data.invoiceNetAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimatorsInspectorsInvoices:net_price')}</label>
                <label>{formatMoney(data.invoiceNetAlc)}</label>
            </div>

            <div title={data.rem} className="cardRowMode">
                <label className="nameLblMobile">{t('estimatorsInspectorsInvoices:remarks')}</label>
                <label>{data.rem}</label>
            </div>

            <div title={i18next.language == 'en' ? data.statusLo : data.statusFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('estimatorsInspectorsInvoices:status')}</label>
                <label className={`status-${data.globalStatus}`}>{i18next.language == 'en' ? data.statusLo : data.statusFo}</label>
            </div>

            <div>
                <Actions
                    user={user}
                    data={data}
                    tableRef={tableRef}
                    printDialogRef={printDialogRef}
                />
            </div>
        </div>
    );
};

const EstimatorsInspectorsInvoicesTable = (props) => {

    const { estimatorsInspectorsInvoicesTableRef, searchTextRef, filtersRef } = props;
    const user = getUser();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const printDialogRef = useRef();

    const [estimatorInvoices, setEstimatorInvoices] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('estimatorsInspectorsInvoices:invoice_num'), width: "15%" },
        { title: t('estimatorsInspectorsInvoices:invoice_date'), width: "13%" },
        { title: t('estimatorsInspectorsInvoices:branch_division'), width: "15%" },
        { title: t('estimatorsInspectorsInvoices:net_price'), width: "13%" },
        { title: t('estimatorsInspectorsInvoices:remarks'), width: "13%" },
        { title: t('estimatorsInspectorsInvoices:status'), width: "13%" },
        { title: "", width: "13%" },
    ];

    const _fetchData = (pageNumber, pageSize) => {
        const filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            ...filtersRef.current.getFilterData(),
        }
        getEstimatorInvoices(pageNumber, pageSize, filterData)
            .then((response) => setEstimatorInvoices(response.data))
            .catch((error) => handleError(error, null, t))
    }

    return (
        <>
            <Slisting
                id="estimators-inspectors-invoices-table"
                ref={estimatorsInspectorsInvoicesTableRef}
                columns={columns}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                Row={Row}
                tableStyle={style.estimatorsInspectorsInvoicesTable}
                getData={_fetchData}
                pagingData={estimatorInvoices}
                rowActions={{ user, tableRef: estimatorsInspectorsInvoicesTableRef, printDialogRef }}
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={() => navigate("/estimators-inspectors-invoice")}

                deleteAction={deleteEstimatorInvoice}
                viewAction={(id) => navigate(`/estimators-inspectors-invoice?id=${id}`)}
                user={user}
                withPrintAction
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                cardInMobile
                labelCardWidth="100px"
            />

            <PrintDialog
                id="estimators-inspectors-invoices-print-dialog"
                ref={printDialogRef}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                user={user}
            />
        </>
    );
};

export default EstimatorsInspectorsInvoicesTable;