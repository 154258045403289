import React from "react";

function NewWordIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.33px" height="14.3px" viewBox="0 0 14.33 14.3">
      <defs>
        <linearGradient
          id="word"
          x1="425.57"
          x2="426.22"
          y1="74.31"
          y2="73.18"
          gradientTransform="matrix(14.33 0 0 -14.3 -6839.83 -229.22)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2368c4"></stop>
          <stop offset="0.5" stopColor="#1a5dbe"></stop>
          <stop offset="1" stopColor="#1146ac"></stop>
        </linearGradient>
      </defs>
      <path
        fill="url(#word)"
        d="M13.13 0H1.19C.53 0 0 .53 0 1.19v11.92c0 .66.54 1.19 1.19 1.19h11.94c.66 0 1.19-.53 1.19-1.19V1.19c0-.66-.53-1.19-1.19-1.19zm-2.81 11.02H8.59L7.35 5.89c-.04-.15-.08-.34-.12-.58a4.5 4.5 0 01-.08-.52h-.02c-.01.13-.04.31-.08.56-.04.25-.08.43-.1.55l-1.16 5.11H4.03L2.2 3.27h1.5l1.13 5.42c.03.15.06.29.08.44.02.18.04.34.05.48h.03c.01-.13.03-.29.07-.47.03-.18.06-.34.09-.47l1.26-5.41h1.62l1.3 5.33c.08.33.13.66.16 1h.02c.02-.33.07-.65.14-.97l1.04-5.36h1.48l-1.82 7.75z"
      ></path>
    </svg>
  );
}

export default NewWordIcon;