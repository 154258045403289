import React from "react";

function DeleteIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || "17"}
            height={props.height || "17"}
            viewBox="0 0 83.397 78.8"
        >
            <path
                d="M80.176 20.139L63.343 3.306a11.318 11.318 0 00-15.987 0L3.637 47.026a12.452 12.452 0 000 17.59L17.822 78.8h57.267a2.723 2.723 0 100-5.445h-32.46l37.556-37.647a11.032 11.032 0 00-.009-15.569M34.938 73.355H20.077L7.488 60.765a7 7 0 010-9.89l11.787-11.787 24.953 24.954zM76.33 31.862L48.074 60.187 23.125 35.238 51.207 7.156a5.866 5.866 0 018.286 0l16.832 16.833a5.578 5.578 0 010 7.873"
                data-name="Path 38422"
            ></path>
        </svg>
    );
}

export default DeleteIcon;