import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import ExchangeRateInput from "../../../../components/exchange-rate";
import { getCommonBranches, getCurrenciesLov, getEstimationsQuotationTypes } from "../../../../util/apis";
import { STATUS_ACTIVE } from "../../../../util/constants";
import { isOrgCurrency } from "../../../../util/util";

const BRANCH_CONDITION = 'dependsOnPolicyId';

const BranchAndPolicy = (props) => {

    const { currentUser, valuationContract, handleDataChanged } = props;

    const { t } = useTranslation("valuationContracts");

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('branch_and_policy')}
                    </label>
                </div>

            </div>
            <div className="container">
                <div className="row">
                    <div className="w50">
                        <label id="valuation-contract-branch-lbl" className='form-label mandatory'>{t('branch')}</label>
                        <FrmSelectMenu
                            id="valuation-contract-branch"
                            key={`valuation-contract-branch-${valuationContract?.id}`}
                            api={() => getCommonBranches(null, isEmpty(valuationContract.mbnId) ? [] : [valuationContract.mbnId], STATUS_ACTIVE, BRANCH_CONDITION)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={valuationContract.mbnId}
                            onChange={value => handleDataChanged("mbnId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label id="valuation-contract-type-lbl" className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id="valuation-contract-type"
                            key={`valuation-contract-type-${valuationContract.id}`}
                            value={valuationContract.umyId}
                            api={() => getEstimationsQuotationTypes(valuationContract.umyId)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("umyId", value)}
                            onDataRetrieved={(list) => {
                                const trxId = getUrlParam("id");
                                if (trxId && valuationContract.umyId) {
                                    list?.forEach((d) => {
                                        if (d.id == valuationContract.umyId) {
                                            handleDataChanged("quotationType", { ...d, value: d.id })
                                            return
                                        }
                                    })
                                }
                            }}
                            portalTarget={document.body}
                            isDisabled={valuationContract?.lines?.length > 0}
                        />
                    </div>

                    <div className="w25">
                        <label id="currency-lbl" className='form-label mandatory'>{t('currency')}</label>
                        <FrmSelectMenu
                            id="currency"
                            api={getCurrenciesLov}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={valuationContract.fcuId}
                            onChange={value => handleDataChanged("fcuId", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w25">
                        <label id="ex-rate-lbl" className={`form-label ${!isOrgCurrency(currentUser, valuationContract.fcuId) ? 'mandatory' : ''}`}>{t('ex_rate')}</label>
                        <ExchangeRateInput
                            id="ex-rate"
                            value={valuationContract?.exchngRate}
                            onBlur={(value) => handleDataChanged("exchngRate", value)}
                            currencyId={valuationContract.fcuId}
                            transactionId={valuationContract?.id}
                            transactionDate={valuationContract?.dateDgr}
                            organizationCurrency={currentUser.fcuId}
                        />
                    </div>
                    {valuationContract.mmaId &&
                        <div className="w25">
                            <label className={`form-label`}>{t('quotation_num')}</label>
                            <FrmInput
                                id={`quotation-number`}
                                value={valuationContract.quotationNumber}
                                disabled
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default BranchAndPolicy;