import UploadImg from "../../../components/upload-img";
import { USER_DEFINITION_PROFILE_IMAGE } from "../../../util/constants";

const Tab4 = (props) => {
    const { tab, userProfileRef, data } = props;

    return (
        <div className={tab == 4 ? 'active' : ''}>
            <section>
                <UploadImg
                    ref={userProfileRef}
                    key={`users-definition-${data?.id}`}
                    masterId={data?.id}
                    formId={USER_DEFINITION_PROFILE_IMAGE}
                />
            </section>
        </div>
    )
}
export default Tab4;