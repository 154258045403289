import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";

const StaffRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div><div>{data?.sequence}</div></div>
            <div>
                <div title={data?.code}>
                    <label>{data?.code}</label>
                </div>
            </div>
            <div>
                <div title={isEnglish() ? data?.descLo : data?.descFo}>
                    <label>{isEnglish() ? data?.descLo : data?.descFo}</label>
                </div>
            </div>
            <div> <div><DeleteBtn onClick={() => handleDeleteLine(index)} /></div></div>
        </div>
    );
};

const StaffTable = (props) => {
    const { user, data, setData } = props;
    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data.realEstateUserStaffMemberList) {
            data.realEstateUserStaffMemberList = [];
        }

        deleteItemByIndex(data.realEstateUserStaffMemberList, index);
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: t('code'), width: "40%" },
        { title: t('name'), width: "40%" },
        { title: "", width: "15%" }
    ];

    return (
        <Table
            id="branches-divisions-table"
            columns={columns}
            list={data?.realEstateUserStaffMemberList || []}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            Row={StaffRow}
            rowHeight={40}
            tableStyle={style.staffTable}
            user={user}
            formId={RSM2661_PROPERTIES_USERS_FORM_ID}
        />
    );
}

const Tab3 = (props) => {
    const { tab, data, setData, user, staffInsertDialogRef } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.realEstateUserStaffMemberList = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 3 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            onClick={() => staffInsertDialogRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('propertiesUsersDefinition:insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllAction}
                            title={t('propertiesUsersDefinition:delete_all')}
                            disabled={(data?.realEstateUserStaffMemberList || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('propertiesUsersDefinition:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            <StaffTable user={user} data={data} setData={setData} />
        </div>
    )
}

export default Tab3;