import FrmBtnsOptions from "@ipgd-gauge/frm-button-options";
import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValues, getDomainValuesWithCondition, getFndUsers, getPartiesList3792 } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, FORM_BTNS_OPTIONS_WITH_CHECH, NOTIFICATION_DOMAIN, NOTIFICATION_DOMAIN_DONT_SEND, PROPORTIES_USERS_TYPES_SUPERVISOR, STATUS_ACTIVE, STATUS_IN_ACTIVE, USERS_TYPES_INVESTOR, USERS_TYPES_OWNER, USER_STATUS_ACTIVE, USER_TYPE_DOMAIN } from "../../../util/constants";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";

const dialogId = "properties-users-definition-dialog";

const Tab1 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('propertiesUsersDefinition');

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        if (name == "userLevel") {
            data.fndUser = {
                username: null,
                mobile: null,
                whatsapp: null,
                email: null,
                fusId: null,
                sendWfEmail: NOTIFICATION_DOMAIN_DONT_SEND,
                sendWfSms: NOTIFICATION_DOMAIN_DONT_SEND
            }
            if (value == CUSTOMER_LEVEL_NEW) {
                data.fusId = null;
                data.fndUser.maters = [];
            }
        } else if (name == "user") {
            data.fndUser = {
                username: value.nickname,
                mobile: value.mobile,
                whatsapp: value.whatsapp,
                email: value.email,
                sendWfEmail: value.sendWfEmail,
                sendWfSms: value.sendWfSms,
            };
            data.fusId = value.value;
            data.descLo = value.nameLo;
            data.descFo = value.nameFo;
        } else if (name === "type") {
            if (value != PROPORTIES_USERS_TYPES_SUPERVISOR) {
                data.realEstateUserStaffMemberList = [];
            }
            data.gprId = null;
        } else if (name === "gprId") {
            data.gprId = value.value;
            if (data.userLevel == CUSTOMER_LEVEL_NEW) {
                data.fndUser.email = value.email;
                data.fndUser.mobile = value.mobile;
                data.descLo = value.descLo;
                data.descFo = value.descFo;
            }
        }
        setData({ ...data });
    }

    const handleFndDataChanged = (name, value) => {
        if (!data.fndUser) {
            data.fndUser = {}
        }
        data.fndUser[name] = value
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label className='form-label mandatory'>{t('user_level')}</label>
                        <FrmBtnsOptions
                            id="properties-users-definition-customer-level"
                            type={FORM_BTNS_OPTIONS_WITH_CHECH}
                            className="options-btn-50"
                            value={data.userLevel}
                            handleSelect={value => _handleDataChanged("userLevel", value)}
                            options={[
                                { id: CUSTOMER_LEVEL_NEW, descLo: t('new'), descFo: t('new') },
                                { id: CUSTOMER_LEVEL_PREDEFINED, descLo: t('predefined'), descFo: t('predefined') },
                            ]}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-user-lbl`} className='form-label mandatory'>{t('user')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-user`}
                            value={data?.fusId}
                            key={data?.id}
                            api={() => getFndUsers(isEmpty(data?.fusId) ? [] : [data?.fusId], USER_STATUS_ACTIVE)}
                            descLo={["descLo"]}
                            descFo={["descFo"]}
                            onChange={value => _handleDataChanged("user", value)}
                            isDisabled={data.userLevel != CUSTOMER_LEVEL_PREDEFINED}
                            onDataRetrieved={(list) => {
                                if (isNotEmpty(data.id) && data?.fusId && !data?.fndUser?.username) {
                                    list?.forEach((element) => {
                                        if (element?.id == data?.fusId) {
                                            _handleDataChanged("fndUser", { ...element, username: element.nickname })
                                            return;
                                        }
                                    })
                                }
                            }}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-type-lbl`} className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-type`}
                            value={data.type}
                            api={() => getDomainValuesWithCondition(USER_TYPE_DOMAIN, false, [USERS_TYPES_OWNER, USERS_TYPES_INVESTOR])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("type", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-target-lbl`} className='form-label mandatory'>{t('target')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-target`}
                            key={`${dialogId}-target-${data.type}`}
                            value={data.gprId}
                            api={() => getPartiesList3792(data.type)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("gprId", value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-user-name-lbl`} className='form-label mandatory'>{t('user_name')}</label>
                        <FrmInput
                            id={`${dialogId}-user-name`}
                            value={data?.fndUser?.username}
                            onBlur={(value) => handleFndDataChanged("username", value)}
                            disabled={isNotEmpty(data?.id) || data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-password-lbl`} className='form-label mandatory'>{t('password')}</label>
                        <FrmInput
                            id={`${dialogId}-password`}
                            value={data?.fndUser?.pass}
                            type="password"
                            onBlur={(value) => handleFndDataChanged("pass", value)}
                            disabled={isNotEmpty(data?.id) || data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-confirm-password-lbl`} className='form-label mandatory'>{t('confirm_password')}</label>
                        <FrmInput
                            id={`${dialogId}-confirm-password`}
                            value={data?.fndUser?.confirmPass}
                            type="password"
                            onBlur={(value) => handleFndDataChanged("confirmPass", value)}
                            disabled={isNotEmpty(data?.id) || data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-email-lbl`} className='form-label mandatory'>{t('email')}</label>
                        <FrmInput
                            id={`${dialogId}-email`}
                            value={data?.fndUser?.email}
                            onBlur={(value) => handleFndDataChanged("email", value)}
                            disabled={data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-mobile-number-lbl`} className='form-label mandatory'>{t('mobile_num')}</label>
                        <FrmInput
                            id={`${dialogId}-mobile-number`}
                            value={data?.fndUser?.mobile}
                            onBlur={(value) => handleFndDataChanged("mobile", value)}
                            disabled={data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label'>{t('whats_app_num')}</label>
                        <FrmInput
                            id={`${dialogId}-whats-app-number`}
                            value={data?.fndUser?.whatsapp}
                            onBlur={(value) => handleFndDataChanged("whatsapp", value)}
                            disabled={data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-wf-email-lbl`} className='form-label mandatory'>{t('wf_email')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-wf-email`}
                            value={data?.fndUser?.sendWfEmail}
                            api={() => getDomainValues(NOTIFICATION_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleFndDataChanged("sendWfEmail", value.value)}
                            portalTarget={document.body}
                            isDisabled={data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${dialogId}-wf-sms-lbl`} className='form-label mandatory'>{t('wf_sms')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-wf-sms`}
                            value={data?.fndUser?.sendWfSms}
                            api={() => getDomainValues(NOTIFICATION_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleFndDataChanged("sendWfSms", value.value)}
                            portalTarget={document.body}
                            isDisabled={data.userLevel == CUSTOMER_LEVEL_PREDEFINED}
                        />
                    </div>

                    <div className="w50">
                        <CodeInput
                            id={`${dialogId}-code`}
                            formId={RSM2661_PROPERTIES_USERS_FORM_ID}
                            column="SSU_CODE"
                            value={data?.code}
                            onBlur={value => _handleDataChanged("code", value)}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="status"
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChanged("descFo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-remarks`}
                            value={data?.rem}
                            onBlur={value => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;