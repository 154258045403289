import { confirmationDialog, handleError, isNotEmpty, parseNumber } from '@ipgd-gauge/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDomainValues } from '../../../../../util/apis';
import { CUSTOMER_LEVEL_DOMAIN, POLICY_LVL_GENERAL } from '../../../../../util/constants';
import { RSM3005_PROPERTIES_ESTIMATION } from '../../../../../util/constants/forms';
import { ACTION_DELETE_ALL_LINES_PROPERTIES_POLICY_DETAIL_LINES, ACTION_DELETE_LINE_PROPERTIES_POLICY_DETAIL_LINES, ACTION_SET_PROPERTIES_POLICY_VALUE } from '../reducer';
import style from '../style.module.scss';
import Actions from './Actions';
import Branches from './Branches';
import Cities from './Cities';
import CustomerCategories from './CustomerCategories';
import Customers from './Customers';
import Divisions from './Divisions';
import EstimationStatuses from './EstimationStatuses';
import FormAndPolicy from './FormAndPolicy';
import Header from './Header';
import MenuForms from './MenuForms';
import PartiesCategories from './PartiesCategories';
import PropertiesParties from './PropertiesParties';
import PropertiesTypes from './PropertiesTypes';
import Regions from './Regions';
import RestrictionPolicy from './RestrictionPolicy';
import Workflow from './Workflow';

const Tab1 = (props) => {
    const { tab, data, dispatchPropertyPolicy, user } = props;

    const { t } = useTranslation();

    const [lov, setLov] = useState({ customerLevel: [] });

    useEffect(() => {
        getDomainValues(CUSTOMER_LEVEL_DOMAIN)
            .then(response => {
                lov.customerLevel = response.data || [];
                setLov({ ...lov });
            })
            .catch(error => handleError(error, null, t));
    }, []);

    const _handleDataChanged = (name, value) => {
        dispatchPropertyPolicy({ type: ACTION_SET_PROPERTIES_POLICY_VALUE, name, value, user });
    }

    const _handleDeleteLine = (index, name) => {
        dispatchPropertyPolicy({ type: ACTION_DELETE_LINE_PROPERTIES_POLICY_DETAIL_LINES, name, index });
    }

    const _handleDeleteAllLines = (name) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            dispatchPropertyPolicy({ type: ACTION_DELETE_ALL_LINES_PROPERTIES_POLICY_DETAIL_LINES, name });
        });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`toggle-mode ${style.toggleMode}`}>
                <Header data={data} handleDataChanged={_handleDataChanged} />
                <FormAndPolicy data={data} handleDataChanged={_handleDataChanged} />

                {(data?.ffrId == RSM3005_PROPERTIES_ESTIMATION && isNotEmpty(data.ipeId)) &&
                    <Actions
                        user={user}
                        data={data}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allBranches) == 1 && isNotEmpty(data?.ipeId) &&
                    <Branches
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allDivisions) == 1 && isNotEmpty(data?.ipeId) &&
                    <Divisions
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allPropertyTypes) == 1 &&
                    <PropertiesTypes
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allLocation) == 1 &&
                    <>
                        <Regions
                            user={user}
                            data={data}
                            lov={lov}
                            handleDataChanged={_handleDataChanged}
                            handleDeleteLine={_handleDeleteLine}
                            handleDeleteAllLines={_handleDeleteAllLines}
                            dispatchPropertyPolicy={dispatchPropertyPolicy}
                        />

                        <Cities
                            user={user}
                            data={data}
                            lov={lov}
                            handleDataChanged={_handleDataChanged}
                            handleDeleteLine={_handleDeleteLine}
                            handleDeleteAllLines={_handleDeleteAllLines}
                            dispatchPropertyPolicy={dispatchPropertyPolicy}
                        />
                    </>
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allRsmParties) == 1 &&
                    <>
                        <PartiesCategories
                            user={user}
                            data={data}
                            lov={lov}
                            handleDataChanged={_handleDataChanged}
                            handleDeleteLine={_handleDeleteLine}
                            handleDeleteAllLines={_handleDeleteAllLines}
                            dispatchPropertyPolicy={dispatchPropertyPolicy}
                        />

                        <PropertiesParties
                            user={user}
                            data={data}
                            lov={lov}
                            handleDataChanged={_handleDataChanged}
                            handleDeleteLine={_handleDeleteLine}
                            handleDeleteAllLines={_handleDeleteAllLines}
                            dispatchPropertyPolicy={dispatchPropertyPolicy}
                        />
                    </>
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allCustCat) == 1 &&
                    <CustomerCategories
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allCustomers) == 1 &&
                    <Customers
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.allEstimationForms) == 1 &&
                    <Workflow
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.usageLevel == POLICY_LVL_GENERAL && parseNumber(data?.function?.estimationFormSec) == 1 &&
                    <MenuForms
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {parseNumber(data?.function?.estimationStatuses) == 1 &&
                    <EstimationStatuses
                        user={user}
                        data={data}
                        lov={lov}
                        handleDataChanged={_handleDataChanged}
                        handleDeleteLine={_handleDeleteLine}
                        handleDeleteAllLines={_handleDeleteAllLines}
                        dispatchPropertyPolicy={dispatchPropertyPolicy}
                    />
                }

                {data?.function?.policyRestrictions &&
                    <RestrictionPolicy data={data} dispatchPropertyPolicy={dispatchPropertyPolicy} />
                }
            </div>
        </div>
    );
}

export default Tab1;