import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../../../components/code-input";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../../../util/constants";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../../../util/constants/forms";

const commonId = "evaluation-policies-"

const Header = (props) => {
    const { data, handleDataChanged } = props;
    const { t } = useTranslation('propertiesPolicies');

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('customersEstimationInvoices:header')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id={`evaluation-policies-code-lbl`}
                            value={data?.code}
                            onBlur={(value) => handleDataChanged("code", value)}
                            column={"LSO_CODE"}
                            formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${commonId}status-lbl`} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id={`${commonId}status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${commonId}english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${commonId}english-name`}
                            value={data.descLo}
                            onBlur={(value) => handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${commonId}arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${commonId}arabic-name`}
                            value={data.descFo}
                            onBlur={(value) => handleDataChanged("descFo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${commonId}remarks`}
                            value={data.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;