import React from "react";

function NoDataFoundIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.64 30.2">
      <path d="M9.39 5.17H16.57V6.17H9.39z" fill="#aec7e8"></path>
      <path d="M9.39 8.3H21.61V9.3H9.39z" fill="#aec7e8"></path>
      <path d="M9.39 12.06H21.61V13.06H9.39z" fill="#aec7e8"></path>
      <path d="M9.39 16.05H21.61V17.05H9.39z" fill="#aec7e8"></path>
      <path
        d="M9.39 20.04H17.450000000000003V21.04H9.39z"
        fill="#aec7e8"
      ></path>
      <path
        d="M19.98 25.71l-14.2 2.07a.902.902 0 01-1.02-.77L2.48 9.38c-.06-.47.26-.91.72-1l2.34-.44V6.42l-3.48.64c-.67.13-1.13.78-1.04 1.47l2.6 19.52c.05.35.23.67.52.88.28.21.62.3.99.25l15.75-2.37a1.306 1.306 0 001.09-1.58l-.02-.11H20.7a.89.89 0 01-.7.58z"
        fill="none"
      ></path>
      <path
        d="M20.96 4.72h2.89L20.83 1.7v2.89c0 .07.06.12.12.12z"
        fill="#aec7e8"
      ></path>
      <path
        d="M5.54 23.52V7.95l-2.34.44c-.47.09-.78.52-.72 1l2.29 17.63c.06.5.52.84 1.02.77l14.2-2.07c.34-.05.59-.28.7-.58H7.15c-.89 0-1.61-.72-1.61-1.61z"
        fill="#dfedf9"
      ></path>
      <path
        d="M25.23 4.69L20.95.41c-.26-.26-.61-.41-.99-.41H7.15c-.89 0-1.61.72-1.61 1.61v3.81l-3.67.68C.67 6.33-.14 7.46.02 8.67l2.6 19.52c.08.62.4 1.17.91 1.55.41.31.89.47 1.39.47.12 0 .23 0 .35-.03l15.75-2.37c.63-.1 1.2-.45 1.56-.98.34-.5.47-1.11.37-1.7h1.08c.89 0 1.61-.72 1.61-1.61V5.67c0-.37-.15-.72-.41-.99zm-4.39-2.98l3.02 3.02h-2.89c-.07 0-.12-.05-.12-.12v-2.9zm.92 24.55c-.21.3-.52.5-.89.55L5.12 29.18a1.33 1.33 0 01-1.51-1.13L1.01 8.54c-.09-.69.37-1.33 1.04-1.47l3.48-.64v17.09c0 .89.72 1.61 1.61 1.61h14.79l.02.11c.08.36 0 .73-.2 1.03zm2.88-2.74c0 .34-.27.61-.61.61H7.15c-.34 0-.61-.27-.61-.61V1.61c0-.34.27-.61.61-.61h12.69v3.6c0 .62.5 1.12 1.12 1.12h3.68v17.8z"
        fill="#aec7e8"
      ></path>
    </svg>
  );
}

export default NoDataFoundIcon;
