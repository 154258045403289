import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { handleError } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { getDomainValues, printSelectedTransactions } from "../../../util/apis";
import { DOMAIN_DOCUMENTS_TYPES } from "../../../util/constants";
import style from './style.module.scss';

const PrintTransactionsDialog = (props, ref) => {

    const { t } = useTranslation("estimationTransaction");
    const printTransactionsDialogRef = useRef();

    const [data, setData] = useState({ withAttachments: false, trxIds: [] });

    const _onPrintCompleted = (isCompleted) => {
        if (isCompleted) {
            close();
        }
    }

    const _onPrintError = (error) => handleError(null, 'estimationTransaction:bulk_printing_in_process', t);

    const _printAction = () => printSelectedTransactions({ ...data }, _onPrintCompleted, _onPrintError);

    const _handleChange = (name, value) => {
        data[name] = value;
        if (name === "withAttachments" && !value) {
            data.types = [];
        }
        setData({ ...data })
    }

    const open = (ids) => {
        data.trxIds = [...ids];
        setData({ ...data });
        printTransactionsDialogRef.current?.open();
    }

    const close = () => printTransactionsDialogRef.current?.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <Modal
            id="print-transactions-dialog"
            ref={printTransactionsDialogRef}
            title={t('print_transactions')}
            withFooter
            okBtnLabel={t("print")}
            withOkButton
            okBtnAction={_printAction}
            withCancelButton
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={` fitHeightDialog ${style.printDialog}`}
            postClose={() => setData({ withAttachments: false, trxIds: [] })}
            smallSize
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label className='form-label mandatory'> {t('with_attachments')}</label>
                        <RadioOptions
                            id="with_attachments"
                            options={[
                                {
                                    title: t('yes'),
                                    action: () => _handleChange("withAttachments", true),
                                    selected: data.withAttachments
                                },
                                {
                                    title: t('no'),
                                    action: () => _handleChange("withAttachments", false),
                                    selected: !data.withAttachments
                                },
                            ]} />
                    </div>
                    <div className="w75"></div>
                    {data.withAttachments &&
                        <div className="w100">
                            <label className='form-label'>{t('type_attachment')}</label>
                            <FrmSelectMenu
                                id="type_attachment"
                                isMulti={true}
                                value={data.type}
                                api={() => getDomainValues(DOMAIN_DOCUMENTS_TYPES)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("types", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(PrintTransactionsDialog);