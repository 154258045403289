import React from "react";

function SelectLocation() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33.726"
            height="25.771"
            viewBox="0 0 33.726 25.771"
        >
            <path
                d="M33.632 29.67H1.31a.6.6 0 01-.579-.958l5.384-10.248a1.973 1.973 0 011.585-.958h1.889a21.7 21.7 0 002.151 2.528h-3.6l-3.729 7.108h26.121L26.8 20.034h-3.06a21.629 21.629 0 002.151-2.53h1.349a1.976 1.976 0 011.588.96l5.384 10.248a.6.6 0 01-.579.958zm-8.4-18.276c0 6.023-4.964 6.588-7.079 12.456a.415.415 0 01-.812 0c-2.117-5.868-7.081-6.433-7.081-12.456a7.487 7.487 0 1114.974 0zm-4.243-.011a3.251 3.251 0 10-3.251 3.251 3.251 3.251 0 003.247-3.251z"
                transform="translate(-.608 -3.907)"
            ></path>
        </svg>
    );
}

export default SelectLocation;
