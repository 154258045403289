import React from "react";

function TownsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.107"
      viewBox="0 0 21.5 21.107"
    >
      <g
        fill="#088587"
        data-name="Group 178505"
        transform="translate(-4532.25 -4233.652)"
      >
        <path
          d="M0 4.5a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v3.75A.75.75 0 010 4.5z"
          opacity="0.4"
          transform="translate(4540 4250.25)"
        ></path>
        <g data-name="Group 178504">
          <path
            d="M20 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h20a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(4533 4254)"
          ></path>
          <path
            d="M.01 20.358a.75.75 0 01-.75-.75l-.01-14.94A2.75 2.75 0 01.471 2.385l4-2.671a2.744 2.744 0 013.055 0l4 2.669a2.73 2.73 0 011.224 2.285l.01 14.919a.75.75 0 01-.749.75l-12 .02zM6 .75a1.236 1.236 0 00-.692.21l-4 2.671A1.251 1.251 0 00.75 4.668l.01 14.188 10.5-.018-.01-14.17a1.235 1.235 0 00-.55-1.035L6.7.961A1.238 1.238 0 006 .75z"
            data-name="Vector"
            transform="translate(4534 4234.402)"
          ></path>
          <path
            d="M0 4.76a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v4.01a.75.75 0 01-.75.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(4550.98 4250)"
          ></path>
          <path
            d="M2-.75A2.753 2.753 0 014.75 2v2a2.75 2.75 0 01-5.5 0V2A2.753 2.753 0 012-.75zm0 6A1.251 1.251 0 003.25 4V2a1.25 1.25 0 00-2.5 0v2A1.251 1.251 0 002 5.25z"
            data-name="Vector"
            transform="translate(4549 4244)"
          ></path>
          <path
            d="M12 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h12a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(4534 4246)"
          ></path>
          <path
            d="M1.5-.75A2.25 2.25 0 11-.75 1.5 2.253 2.253 0 011.5-.75zm0 3a.75.75 0 10-.75-.75.751.751 0 00.75.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(4538.5 4239.5)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default TownsIcon;