import { checkValidation, confirmationDialog, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import { getContractAddItem, getExpenseAccountList, getLocationGroups, getLocations, saveContractAddItem } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2877_ADDITIONAL_ITEMS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { getFormId } from "../../../util/formUtil";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import style from './style.module.scss';

const dialogId = "additional-items-dialog";

const AdditionalItemsDialog = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();

    const user = getUser();
    const formId = getFormId();

    const dialogRef = useRef();
    const locationsDialogRef = useRef();
    const locationGroupsDialogRef = useRef();
    const updateRecordRef = useRef(false);

    const [data, setData] = useState({});
    const [expenseAccount, setExpenseAccount] = useState({
        gstLocation: [],
        gstGroups: []
    });
    const itemsId = (data.locations || []).map((id) => id.gstId)
    const itemsGroupId = (data.locationGroups || []).map((id) => id.gstId)
    useEffect(() => {
        Promise.all([getExpenseAccountList(null, itemsId[0]), getExpenseAccountList(null, itemsGroupId[0])])
            .then((response) => {
                expenseAccount.gstLocation = response[0].data;
                expenseAccount.gstGroups = response[1].data;
            })
            .catch(error => handleError(error, null, t));
    }, []);

    const close = () => {
        dialogRef?.current?.closeIfCan();
    }

    const _newAction = () => {
        let newData = {
            isActive: STATUS_ACTIVE,
            vatPerc: 0,
            locations: [],
            locationGroups: [],
        }

        setData({ ...newData });
        removeAllMandatoryClasses(document.getElementById("additional-items-dialog"))
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getContractAddItem(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction();
        }
    }

    const open = (id) => {
        dialogRef?.current?.open();
        _populateRecordData(id)
    }


    const _okBtnAction = () => {
        if (!checkValidation([{ id: "additional-items-dialog" },
        {
            id: "location-groups-financial-table",
            title: t('additionalItems:location_groups_financial_integration'),
            data: data.locationGroups,
        },
        {
            id: "locations-financial-integration-table",
            title: t('additionalItems:locations_financial_integration'),
            data: data.locations,
        }], t)) {
            return;
        }
        showWaiting(`${dialogId}-okButton`, true)
        saveContractAddItem(data)
            .then(() => {
                showSuccessMessage('save_successfully', t);
                dialogRef.current.changeSelectedTab(1);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting(`${dialogId}-okButton`));
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false
        tableRef?.current?.refresh()
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _insertLocationGroups = (selectedData) => {
        if (!data.locationGroups) {
            data.locationGroups = [];
        }

        let newLines = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                ddvId: element.typeId,
                lgrId: element.lgrId,
                groupCode: element.code,
                groupDescLo: element.codeDescLo,
                groupDescFo: element.codeDescFo,
                typeDescLo: element.typeDescLo,
                typeDescFo: element.typeDescFo
            });
        }

        data.locationGroups = [...data.locationGroups, ...newLines];
        setData({ ...data });

        locationGroupsDialogRef?.current?.close();
    }

    const _insertLocations = (selectedData) => {
        if (!data.locations) {
            data.locations = [];
        }

        let newLines = [];
        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                ddvId: element.typeId,
                floId: element.floId,
                groupCode: element.code,
                groupDescLo: element.codeNameLo,
                groupDescFo: element.codeNameFo,
                typeDescLo: element.typeDescLo,
                typeDescFo: element.typeDescFo
            });
        }

        data.locations = [...data.locations, ...newLines];
        setData({ ...data });

        locationsDialogRef?.current?.close();
    }

    const _handleDeleteAllLines = (name) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data[name] = [];
            setData({ ...data });
        });
    }

    const _handleDeleteLine = (index, name) => {
        if (!data[name]) {
            data[name] = [];
        }

        data[name].splice(index, 1);
        setData({ ...data });
    }

    const _handleLineDataChange = (index, name, value) => {
        data[name][index].gstId = value;
        setData({ ...data });
    }

    return (
        <>
            <ModalWithTabs
                id={dialogId}
                ref={dialogRef}
                title={'additionalItems:additional_items'}
                labels={['home#1', 'additionalItems:location_groups_financial_integration', 'additionalItems:locations_financial_integration']}
                className={style.additionalItemsDialog}
                okBtnAction={_okBtnAction}
                preClose={_preCloseAction}
                postClose={_newAction}
                nextPreviousAction={(id) => _populateRecordData(id)}
                hideOkBtn={!isAllowedAction(user, RSM2877_ADDITIONAL_ITEMS_FORM_ID, SAVE_ACTION)}
            >
                <Tab1
                    data={data}
                    setData={setData}
                />

                <Tab2
                    data={data}
                    expenseAccount={expenseAccount}
                    locationGroupsDialogRef={locationGroupsDialogRef}
                    handleDeleteAllLines={_handleDeleteAllLines}
                    handleDeleteLine={_handleDeleteLine}
                    handleLineDataChange={_handleLineDataChange}
                    user={user}
                    formId={formId}
                />

                <Tab3
                    data={data}
                    expenseAccount={expenseAccount}
                    locationsDialogRef={locationsDialogRef}
                    handleDeleteAllLines={_handleDeleteAllLines}
                    handleDeleteLine={_handleDeleteLine}
                    handleLineDataChange={_handleLineDataChange}
                    user={user}
                    formId={formId}
                />
            </ModalWithTabs>

            <MultiInsertDialog
                id={`${dialogId}-location-groups`}
                ref={locationGroupsDialogRef}
                tableStyle={style.additionalItemsMultiInsart}
                title={t('additionalItems:insert_location_groups')}
                okBtnAction={_insertLocationGroups}
                api={getLocationGroups}
                selectedIds={(data.locationGroups || []).map((line) => line.lgrId)}
                searchPlaceholder={t('additionalItems:code') + " , " + t('additionalItems:name') + " , " + t('additionalItems:type')}
                formId={RSM2877_ADDITIONAL_ITEMS_FORM_ID}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('additionalItems:group'), valueLo: 'codeDescLo', valueFo: 'codeDescFo', width: '45%' },
                    { label: t('additionalItems:type'), valueLo: 'typeDescLo', valueFo: 'typeDescFo', width: '45%' }
                ]}
            />

            <MultiInsertDialog
                id={`${dialogId}-locations`}
                ref={locationsDialogRef}
                title={t('additionalItems:insert_locations')}
                tableStyle={style.additionalItemsMultiInsart}
                okBtnAction={_insertLocations}
                api={getLocations}
                selectedIds={(data.locations || []).map((line) => line.floId)}
                searchPlaceholder={t('additionalItems:code') + " , " + t('additionalItems:name') + " , " + t('additionalItems:type')}
                formId={RSM2877_ADDITIONAL_ITEMS_FORM_ID}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('additionalItems:location'), valueLo: 'codeNameLo', valueFo: 'codeNameFo', width: '45%' },
                    { label: t('additionalItems:type'), valueLo: 'typeDescLo', valueFo: 'typeDescFo', width: '45%' }
                ]}
            />
        </>
    )
}

export default forwardRef(AdditionalItemsDialog);