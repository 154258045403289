import React from "react";

function DateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="21.5"
      viewBox="0 0 19.5 21.5"
    >
      <g
        data-name="Group 179470"
        transform="translate(-6650.25 -3208.25)"
      >
        <path
          d="M5.75 21.5a5.688 5.688 0 01-4.362-1.615A5.87 5.87 0 010 15.75v-8.5a5.87 5.87 0 011.388-4.135A5.335 5.335 0 015 1.53V.75a.75.75 0 011.5 0v.75H13V.75a.75.75 0 011.5 0v.78a5.336 5.336 0 013.613 1.585A5.87 5.87 0 0119.5 7.25v8.5a5.87 5.87 0 01-1.388 4.135A5.69 5.69 0 0113.75 21.5zM2.487 4.135A4.445 4.445 0 001.5 7.25v8.5a4.445 4.445 0 00.986 3.115A4.272 4.272 0 005.75 20h8a4.27 4.27 0 003.263-1.135A4.441 4.441 0 0018 15.75v-8.5a4.441 4.441 0 00-.987-3.115 3.833 3.833 0 00-2.513-1.1v.715a.75.75 0 01-1.5 0V3H6.5v.75a.75.75 0 01-1.5 0v-.714a3.835 3.835 0 00-2.513 1.099z"
          data-name="Union 137"
          transform="translate(6650.25 3208.25)"
        ></path>
        <path
          d="M17 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h17a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          opacity="0.4"
          transform="translate(6651.5 3216.09)"
        ></path>
        <path
          d="M.5 1.5H.495a1 1 0 110-2H.5a1 1 0 010 2z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(6663.2 3220.2)"
        ></path>
        <path
          d="M.5 1.5H.495a1 1 0 110-2H.5a1 1 0 010 2z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(6663.2 3223.2)"
        ></path>
        <path
          d="M.5 1.5H.495a1 1 0 110-2H.5a1 1 0 010 2z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(6659.501 3220.2)"
        ></path>
        <path
          d="M.5 1.5H.495a1 1 0 110-2H.5a1 1 0 010 2z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(6659.501 3223.2)"
        ></path>
        <path
          d="M.5 1.5H.495a1 1 0 110-2H.5a1 1 0 010 2z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(6655.8 3220.2)"
        ></path>
        <path
          d="M.009 1H0a1 1 0 01-1-1 1 1 0 011-1h.009a1 1 0 011 1 1 1 0 01-1 1z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(6656.294 3223.7)"
        ></path>
      </g>
    </svg>
  );
}

export default DateIcon;