import React from "react";

function ViewDashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.755"
      height="25.755"
      viewBox="0 0 25.755 25.755"
    >
      <g data-name="Group 176756" transform="translate(-44.113 -224.676)">
        <path
          fill="#ab8860"
          d="M15.766 25.005h-1.213a.75.75 0 110-1.5h1.213c5.642 0 7.739-2.1 7.739-7.739V8.489c0-5.642-2.1-7.739-7.739-7.739H8.489C2.848.75.75 2.848.75 8.489v1.189a.75.75 0 01-.75.75.75.75 0 01-.75-.75V8.489C-.75 2.013 2.013-.75 8.489-.75h7.277c6.476 0 9.239 2.763 9.239 9.239v7.277c0 6.476-2.763 9.239-9.239 9.239z"
          opacity="0.51"
          transform="translate(44.863 225.426)"
        ></path>
        <g data-name="Group 668" transform="translate(58.203 230.253)">
          <g data-name="Group 667">
            <path
              fill="#ab8860"
              d="M0 6.838a.748.748 0 01-.53-.219.75.75 0 010-1.061L4.268.75H1.213A.75.75 0 01.463 0a.75.75 0 01.75-.75h4.863a.75.75 0 01.693.463.75.75 0 01-.162.817L.531 6.618a.748.748 0 01-.531.22z"
              data-name="Vector"
            ></path>
          </g>
          <path
            fill="#ab8860"
            d="M0 5.613a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v4.863a.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(6.076)"
          ></path>
        </g>
        <path
          fill="#ab8860"
          d="M3.82-.75h3.275A4.626 4.626 0 0110.558.357a4.626 4.626 0 011.107 3.463v3.275a4.626 4.626 0 01-1.107 3.464 4.626 4.626 0 01-3.464 1.107H3.82a4.626 4.626 0 01-3.463-1.108A4.626 4.626 0 01-.75 7.095V3.82A4.626 4.626 0 01.357.357 4.626 4.626 0 013.82-.75zm3.275 10.915A3.343 3.343 0 009.5 9.5a3.343 3.343 0 00.667-2.4V3.82a3.343 3.343 0 00-.667-2.4A3.343 3.343 0 007.095.75H3.82a3.343 3.343 0 00-2.4.667A3.343 3.343 0 00.75 3.82v3.275a3.343 3.343 0 00.667 2.4 3.343 3.343 0 002.4.667z"
          data-name="Vector"
          transform="translate(44.863 238.766)"
        ></path>
      </g>
    </svg>
  );
}

export default ViewDashIcon;