import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, isEmpty, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { getCitiesLov, getCountriesLov, getDomainValues, getRegionsLov, getTownsList } from "../../../util/apis";
import { DOMAIN_LOCATION_LEVEL, LOCATION_LEVEL_CITY, LOCATION_LEVEL_COUNTRY, LOCATION_LEVEL_REGION, LOCATION_LEVEL_TOWN, STATUS_ACTIVE } from "../../../util/constants";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Tab6 = (props) => {
    const { tab, user, data, handleDataChanged, setData } = props;
    const { t } = useTranslation();

    const _addLine = () => {
        if (!data.locations) {
            data.locations = [];
        }
        data.locations.push({});
        setData({ ...data });
    }

    const _deleteAllLocations = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.locations = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 6 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <div className={style.installations}>
                            <RadioOptions
                                id="accounts-groups-status"
                                options={[
                                    {
                                        id: 'all_locations',
                                        title: t('propertiesPartiesDefinition:all_locations'),
                                        selected: data.allLoactions == "1",
                                        action: () => handleDataChanged('allLoactions', '1')
                                    },
                                    {
                                        id: 'specific_locations',
                                        title: t('propertiesPartiesDefinition:specific_locations'),
                                        selected: data.allLoactions == "0",
                                        action: () => handleDataChanged('allLoactions', '0')
                                    },
                                ]} />
                        </div>
                    </div>
                    <div className={`w50 insertBtn`}>
                        <button
                            className="primary-button"
                            onClick={_addLine}
                            disabled={data.allLoactions == '1'}
                            title={t('add')}
                        >
                            {t('add')}
                        </button>
                        <button
                            title={t('delete_all')}
                            className="delete-button"
                            onClick={_deleteAllLocations}
                            disabled={(data.locations || []).length === 0}
                        >
                            {t('delete_all')}
                        </button>
                    </div>
                </div>
            </div>
            {data.allLoactions == '0' && <LocationTable user={user} data={data} setData={setData} />}
        </div>
    )
}

const LocationTable = (props) => {
    const { user, data, setData } = props;
    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "5%" },
        { title: <span data-property="locationLevel" className='mandatory'>{t('propertiesPartiesDefinition:level')}</span>, width: "40%" },
        { title: <span data-property="targetId" className='mandatory'>{t('propertiesPartiesDefinition:location')}</span>, width: "40%" },
        { title: '', width: "15%" },
    ]

    const _handleDeleteLine = (index) => {
        if (!data.locations) {
            data.locations = [];
        }

        deleteItemByIndex(data.locations, index);
        setData({ ...data });
    }

    const _handleChange = (index, name, value) => {
        let location = data.locations[index];
        location[name] = value;
        if (name == 'locationLevel') {
            location.targetId = null;
        }
        data.locations[index] = location;
        setData({ ...data })
    }

    return (
        <>
            <div className={style.locationTableContainer}>
                <Table
                    id="locations-table"
                    columns={columns}
                    Row={LocationRow}
                    tableStyle={style.locationTable}
                    list={data.locations || []}
                    rowActions={{ handleDeleteLine: _handleDeleteLine, handleChange: _handleChange }}
                    rowHeight={50}
                    user={user}
                    formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                />
            </div>
        </>
    );
}

const LocationRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props

    const _handleDataChanged = (name, value) => {
        rowActions?.handleChange(index, name, value);
    }

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div><div>{data.sequence}</div></div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`location-level-${index}`}
                        value={data.locationLevel}
                        api={() => getDomainValues(DOMAIN_LOCATION_LEVEL)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => _handleDataChanged("locationLevel", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`location-target-${index}`}
                        key={data.locationLevel}
                        value={data.targetId}
                        isAsync={data.locationLevel == LOCATION_LEVEL_CITY || data.locationLevel == LOCATION_LEVEL_TOWN}
                        defualtValueApi={() => data.locationLevel == LOCATION_LEVEL_CITY ? getCitiesLov(isEmpty(data.targetId) ? [] : [data.targetId]) : getTownsList(null, isEmpty(data.targetId) ? [] : [data.targetId], STATUS_ACTIVE)}
                        defualtValueId={data?.targetId}
                        api={(searchValue, defaultId) =>
                            data.locationLevel == LOCATION_LEVEL_COUNTRY ?
                                getCountriesLov([data.targetId], STATUS_ACTIVE) :
                                data.locationLevel == LOCATION_LEVEL_REGION ?
                                    getRegionsLov([data.targetId], STATUS_ACTIVE) :
                                    data.locationLevel == LOCATION_LEVEL_CITY ?
                                        getCitiesLov(null, STATUS_ACTIVE, null, null, searchValue) :
                                        getTownsList(searchValue, null, null, STATUS_ACTIVE)
                        }
                        descLo={(data.locationLevel == LOCATION_LEVEL_COUNTRY || data.locationLevel == LOCATION_LEVEL_TOWN) ? ['nameLo'] : ['descLo']}
                        descFo={(data.locationLevel == LOCATION_LEVEL_COUNTRY || data.locationLevel == LOCATION_LEVEL_TOWN) ? ['nameFo'] : ['descFo']}
                        onChange={value => _handleDataChanged("targetId", value.value)}
                        isDisabled={!data.locationLevel}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => rowActions?.handleDeleteLine(index)} />
                </div>
            </div>
        </div >
    );
};

export default Tab6;