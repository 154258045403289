import React from "react";

function DownloadIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31.175"
            height="31.891"
            data-name="Group 178574"
            viewBox="0 0 31.175 31.891"
        >
            <g fill="#1facae" opacity="0.55" transform="translate(0 10.518)">
                <path
                    d="M19.529 20.081H9.6c-3.716 0-6.227-.78-7.9-2.454s-2.45-4.184-2.45-7.9v-.2c0-3.34.622-5.673 1.958-7.342C2.558.5 4.647-.457 7.593-.731A.812.812 0 017.744.885C2.8 1.346.874 3.77.874 9.528v.2c0 6.365 2.366 8.731 8.731 8.731h9.924c6.365 0 8.731-2.366 8.731-8.731v-.2c0-5.8-1.959-8.225-6.987-8.658a.812.812 0 11.139-1.618C24.4-.489 26.525.457 27.9 2.146c1.356 1.671 1.988 4.017 1.988 7.383v.2c0 3.716-.78 6.227-2.454 7.9s-4.189 2.452-7.905 2.452z"
                    transform="translate(1.021 1.021)"
                ></path>
                <path
                    d="M19.549 20.373H9.625c-3.793 0-6.364-.8-8.092-2.533S-1 13.54-1 9.747v-.2c0-3.4.641-5.791 2.017-7.511C2.416.289 4.566-.7 7.589-.98h.1A1.077 1.077 0 018.767 0a1.084 1.084 0 01-.977 1.176c-2.451.229-4.073.938-5.1 2.234S1.165 6.667 1.165 9.549v.2c0 3.18.6 5.265 1.9 6.561s3.381 1.9 6.561 1.9h9.924c3.18 0 5.265-.6 6.561-1.9s1.9-3.381 1.9-6.561v-.2c0-2.9-.492-4.862-1.548-6.171s-2.7-2-5.191-2.218a1.075 1.075 0 01-.735-.382 1.075 1.075 0 01-.25-.79A1.089 1.089 0 0121.362-1h.094c3.069.268 5.251 1.247 6.67 3 1.4 1.722 2.048 4.122 2.048 7.553v.2c0 3.793-.8 6.364-2.533 8.092s-4.299 2.528-8.092 2.528zM7.69-.444h-.051C4.77-.174 2.742.748 1.44 2.376s-1.9 3.9-1.9 7.173v.2c0 3.64.755 6.089 2.375 7.709s4.07 2.375 7.709 2.375h9.924c3.64 0 6.089-.755 7.709-2.375s2.375-4.07 2.375-7.709v-.2c0-3.3-.612-5.592-1.927-7.212S24.325-.206 21.41-.457a.544.544 0 00-.586.493.542.542 0 00.493.586c2.651.228 4.42 1 5.566 2.417s1.668 3.479 1.668 6.51v.2c0 3.333-.654 5.54-2.058 6.944s-3.61 2.058-6.944 2.058H9.625c-3.333 0-5.54-.654-6.944-2.058S.624 13.08.624 9.747v-.2c0-3.011.521-5.069 1.64-6.477S5.131.88 7.74.637a.542.542 0 00.488-.589.538.538 0 00-.538-.492z"
                    data-name="Vector - Outline"
                    transform="translate(1 1)"
                ></path>
            </g>
            <g fill="#1facae" data-name="Group 654" transform="translate(9.406)">
                <path
                    d="M.062 20.508A.812.812 0 01-.75 19.7V.062a.812.812 0 111.624 0V19.7a.812.812 0 01-.812.808z"
                    data-name="Vector"
                    transform="translate(6.127 1.021)"
                ></path>
                <path
                    d="M.082 20.8A1.084 1.084 0 01-1 19.717V.082A1.084 1.084 0 01.082-1 1.084 1.084 0 011.165.082v19.635A1.084 1.084 0 01.082 20.8zm0-21.258a.542.542 0 00-.541.54v19.635a.542.542 0 00.541.541.542.542 0 00.541-.541V.082a.542.542 0 00-.541-.541z"
                    data-name="Vector - Outline"
                    transform="translate(6.106 1)"
                ></path>
                <path
                    d="M5.169 5.981a.809.809 0 01-.574-.238L-.512.636A.812.812 0 01.636-.512l4.533 4.533L9.7-.512A.812.812 0 0110.85.636L5.743 5.743a.809.809 0 01-.574.238z"
                    data-name="Vector"
                    transform="translate(1.021 17.256)"
                ></path>
                <path
                    d="M5.189 6.272a1.075 1.075 0 01-.765-.317L-.683.848A1.075 1.075 0 01-1 .082a1.075 1.075 0 01.317-.765A1.075 1.075 0 01.082-1a1.075 1.075 0 01.765.317l4.342 4.341L9.531-.683a1.082 1.082 0 011.531 0 1.075 1.075 0 01.317.765 1.075 1.075 0 01-.317.765L5.955 5.955a1.075 1.075 0 01-.766.317zM.082-.459A.538.538 0 00-.3-.3a.542.542 0 000 .765l5.107 5.107a.541.541 0 00.765 0L10.679.465a.542.542 0 000-.765.541.541 0 00-.765 0L5.189 4.424.465-.3a.538.538 0 00-.383-.159z"
                    data-name="Vector - Outline"
                    transform="translate(1 17.236)"
                ></path>
            </g>
        </svg>
    );
}

export default DownloadIcon;
