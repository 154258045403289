import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { ACTION_INCOMPLETE } from "../../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const NumberOfCopies = (props, ref) => {
    const { user, trxId, code, batchNum, okAction, postClose } = props;
    const { t } = useTranslation("estimationTransaction");
    const dialogRef = useRef();
    const actionRef = useRef();
    const [data, setData] = useState({ numOfCopies: 1, withCode: "0", updateAllCopies: "0" });

    const _handleChangeData = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const open = (action) => {
        actionRef.current = action;
        _handleChangeData('action', action);
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    const _postClose = () => {
        actionRef.current = ''
        setData({ numOfCopies: 1, withCode: "0", updateAllCopies: "0" })
        if (postClose) {
            postClose();
        }
    }

    useImperativeHandle(ref, () => ({
        open,
        close
    })
    )

    return (
        <Modal
            id="number-of-copies-dialog"
            ref={dialogRef}
            title=
            {t(`${data.action == ACTION_INCOMPLETE ? "incomplete_save" : "estimation_saving"}`)}
            okBtnAction={() => {
                if (okAction) {
                    okAction(actionRef.current, data);
                }
            }}
            okBtnLabel={t("save")}
            withFooter
            withOkButton
            withCancelButton
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={` fitHeightDialog ${style.incompleteSaveDialog}`}
            preClose={_postClose}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    {/* {trxId < 0 && */}
                    <div className="w100">
                        <label className='form-label'>{t('number_of_copies')}</label>
                        <FrmInput
                            id="number-of-copies"
                            type="number"
                            minValue={1}
                            value={data.numOfCopies}
                            onChange={(e) => _handleChangeData("numOfCopies", e.target.value)}
                        />
                    </div>
                    {/* } */}
                </div>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        {/* {!code && */}
                        <>
                            <label className='form-label'> {t('code')}</label>
                            <RadioOptions
                                sidePanelMode
                                id="customer-status"
                                options={[
                                    {
                                        title: t('with'),
                                        action: () => _handleChangeData("withCode", "1"),
                                        selected: data.withCode == "1"
                                    },
                                    {
                                        title: t('without'),
                                        action: () => _handleChangeData("withCode", "0"),
                                        selected: data.withCode == "0"
                                    },
                                ]}
                            />
                        </>
                        {/* } */}
                    </div>
                    <div className="w50">
                        {/* {batchNum && user?.estimationPolicy?.osmUpdateAllTransactionCopies && */}
                        <>
                            <label className='form-label'> {t('update_all_copies')}</label>
                            <RadioOptions
                                sidePanelMode
                                id="customer-status"
                                options={[
                                    {
                                        title: t('with'),
                                        action: () => _handleChangeData("updateAllCopies", "1"),
                                        selected: data.updateAllCopies == "1"
                                    },
                                    {
                                        title: t('without'),
                                        action: () => _handleChangeData("updateAllCopies", "0"),
                                        selected: data.updateAllCopies == "0"
                                    },
                                ]}
                            />
                        </>
                        {/* } */}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(NumberOfCopies);
