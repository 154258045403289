import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getCitiesLov } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { FND2621_TOWNS_DEFINITION_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import { encryptText, isEmpty } from "@ipgd-gauge/utils";

const Tab1 = (props) => {

    const { tab, handleDataChanged, district } = props;
    const { t } = useTranslation("towns");

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`container  ${style.summaryContainer} `}>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <CodeInput
                            id="code"
                            column="CRI_CODE"
                            formId={FND2621_TOWNS_DEFINITION_FORM_ID}
                            value={district.code}
                            onBlur={(value) => handleDataChanged("code", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="town-status-lbl" data-label={encryptText("CRI_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="town-status"
                            sidePanelMode
                            options={[
                                {
                                    id: "active",
                                    title: t('active'),
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: district.isActive == STATUS_ACTIVE
                                },
                                {
                                    id: "inactive",
                                    title: t('inactive'),
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: district.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>

                    <div className="w100">
                        <label id="english-name-district-lbl" data-label={encryptText("CRI_DESC_LO")} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id="english-name-district"
                            value={district.descLo}
                            onBlur={(value) => handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="arabic-name-district-lbl" data-label={encryptText("CRI_DESC_FO")} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id="arabic-name-district"
                            value={district.descFo}
                            onBlur={(value) => handleDataChanged("descFo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="cities-lbl" data-label={encryptText("CRI_ECD_ID")} data-info={encryptText("ECD&&descLo&&descFo")} className='form-label mandatory'>{t('city')}</label>
                        <FrmSelectMenu
                            id="cities"
                            key={`city-${district.id || 0}`}
                            value={district.ecdId}
                            defualtValueId={district.ecdId}
                            defualtValueApi={() => getCitiesLov(isEmpty(district.ecdId) ? [] : [district.ecdId])}
                            api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, null, searchValue)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => handleDataChanged("ecdId", e.value)}
                            isAsync={true}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'  data-info={encryptText("CRI_REM")} >{t('remarks')}</label>
                        <FrmInput
                            id="district-remarks"
                            value={district.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;