import React from "react";

function LangIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#fff"
        data-name="Group 178467"
        transform="translate(-236.25 -66.25)"
      >
        <path
          d="M4.281 5.02a.75.75 0 01-.671-.414L2.14 1.674.671 4.606a.75.75 0 01-1.007.334.75.75 0 01-.335-1.006l2.14-4.27A.75.75 0 012.14-.75a.75.75 0 01.671.414l2.14 4.27a.75.75 0 01-.67 1.086z"
          transform="translate(249.78 79.4)"
        ></path>
        <path
          d="M3.52.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h3.52a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          transform="translate(250.17 82.91)"
        ></path>
        <path
          d="M5.08 10.91A5.824 5.824 0 119.2 9.2a5.837 5.837 0 01-4.12 1.71zm0-10.16a4.33 4.33 0 104.33 4.33A4.335 4.335 0 005.08.75z"
          data-name="Vector"
          transform="translate(246.84 76.84)"
        ></path>
        <path
          d="M3.02-.75h3.92a3.837 3.837 0 012.86 1 3.706 3.706 0 01.91 2.782v3.9a3.761 3.761 0 01-.91 2.82 3.637 3.637 0 01-2.713.96H2.9C.548 10.711-.75 9.368-.75 6.93V3.01A3.4 3.4 0 013.02-.75zm4.066 9.962A2.233 2.233 0 008.725 8.7a2.37 2.37 0 00.485-1.742V3.02v-.019a2.306 2.306 0 00-.484-1.707A2.44 2.44 0 006.94.75H3.02C1.408.75.75 1.4.75 3.01v3.92c0 1.62.624 2.281 2.155 2.281h4.053z"
          data-name="Vector"
          transform="translate(237 67)"
        ></path>
        <path
          d="M7 7.75a7.7 7.7 0 01-5.482-2.268A7.7 7.7 0 01-.75 0 .75.75 0 010-.75.75.75 0 01.75 0a6.261 6.261 0 004.827 6.087l-.27-.451a.75.75 0 111.286-.772l1.05 1.75A.75.75 0 017 7.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(237 80)"
        ></path>
        <path
          d="M7 7.75A.75.75 0 016.25 7 6.261 6.261 0 001.423.913l.27.451a.75.75 0 11-1.286.772L-.643.386a.75.75 0 01-.01-.756A.75.75 0 010-.75a7.7 7.7 0 015.482 2.268A7.7 7.7 0 017.75 7a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(250 67)"
        ></path>
      </g>
    </svg>
  );
}

export default LangIcon;