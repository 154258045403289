import { fixImagePath, isEnglish } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import LogIcon from "../../../icons/Log";
import { getUsersLog } from "../../../util/apis";
import { FND0133_USERS_LOGIN_LOG_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { formatDateNTime } from "../../../util/dateUtil";
import UserLoginLogs from "../dialog";
import style from './style.module.scss';
import Image from "@ipgd-gauge/image";

const Row = (props) => {
    const { data, rowActions } = props;
    const { userLoginLogsRef, serverUrl } = rowActions;
    const { t } = useTranslation("");

    return (
        <div className={style.usersLogTableRow}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>
            <div title={isEnglish() ? data.descLo : data.descFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('usersLoginLog:user')}</label>
                <div title={data.clientName} className={style.userContainer}>
                    <span>
                        <Image
                            className="tableImg" src={data.imagePath ? `${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.imagePath)}` : './images/avatar.png'}
                        />
                    </span>
                    <label>{isEnglish() ? data.descLo : data.descFo}</label>
                </div>
            </div>

            {/* <div title={isEnglish() ? data.descLo : data.descFo} className="overflowTableField">{isEnglish() ? data.descLo : data.descFo}</div> */}
            <div title={formatDateNTime(data.lastLoginDate)} className="cardRowMode">
                <label className="nameLblMobile">{t('usersLoginLog:last_login_date')}</label>
                {formatDateNTime(data.lastLoginDate) &&
                    <label>{formatDateNTime(data.lastLoginDate)}</label>
                    ||
                    <label title={t('not_found')}>{t('not_found')}</label>
                }
            </div>
            {/* <div title={formatDateNTime(data.lastLoginDate)}>{formatDateNTime(data.lastLoginDate)}</div> */}
            <div title={data.lastLoginIp} className="cardRowMode">
                <label className="nameLblMobile">{t('usersLoginLog:user')}</label>
                {data.lastLoginIp &&
                    <label>{data.lastLoginIp}</label>
                    ||
                    <label title={t('not_found')}>{t('not_found')}</label>
                }
            </div>

            {/* <div title={data.lastLoginIp}>{data.lastLoginIp}</div> */}
            <div>
                <button className={style.logBtn} onClick={() => userLoginLogsRef.current.open(data.userId)}>
                    <LogIcon />
                </button>
            </div>
        </div>
    );
};

const UsersLogTable = (props) => {
    const { usersLogTableRef, searchTextRef, filterRef } = props;

    const { t } = useTranslation("usersLoginLog");
    const userLoginLogsRef = useRef();
    const user = getUser();

    const [log, setLog] = useState({});
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    const columns = [
        { title: '#', width: "5%" },
        { title: t('user'), width: "35%" },
        { title: t('last_login_date'), width: "30%" },
        { title: t('last_login_ip'), width: "20%" },
        { title: "", width: "10%" },
    ];

    const _fetchData = (page, size) => {
        const filterData = {
            searchValue: searchTextRef.current?.getSearchText(),
            ...filterRef?.current?.getFilterData(),
        }
        getUsersLog(page, size, filterData)
            .then((response) => setLog(response.data))
            .catch((error) => console.log("🚀 ~ error", error))
    }

    return (
        <>
            <Slisting
                id="users-log-table-table"
                ref={usersLogTableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.usersLogTable}
                rowHeight={80}
                withFilter
                getData={_fetchData}
                pagingData={log}
                rowActions={{ userLoginLogsRef, serverUrl }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                formId={FND0133_USERS_LOGIN_LOG_FORM_ID}
                user={user}
                cardInMobile
                labelCardWidth="90px"
            />

            <UserLoginLogs ref={userLoginLogsRef} user={user} />
        </>
    );
};

export default UsersLogTable;