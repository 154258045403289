import React from "react";

function LocationMapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.279"
      height="10.914"
      data-name="Group 176646"
      viewBox="0 0 9.279 10.914"
    >
      <path
        fill="#1facae"
        d="M4.639 0a4.636 4.636 0 00-3.526 7.65s1.977 2.256 2.68 2.927a1.232 1.232 0 001.692 0c.8-.764 2.678-2.924 2.678-2.924A4.636 4.636 0 004.639 0m0 .819a3.817 3.817 0 012.9 6.3s-1.924 2.2-2.623 2.867a.4.4 0 01-.562 0c-.584-.557-2.617-2.86-2.623-2.867a3.817 3.817 0 012.9-6.3m0 1.092a2.729 2.729 0 102.738 2.728 2.735 2.735 0 00-2.73-2.729m0 .819a1.91 1.91 0 11-1.91 1.91 1.9 1.9 0 011.91-1.91m-.285.863a.27.27 0 00-.064.013A1.092 1.092 0 105.674 4.99a.271.271 0 00-.263-.35h-.5a.273.273 0 01-.273-.273v-.5a.275.275 0 00-.285-.276"
        data-name="Path 3234"
      ></path>
    </svg>
  );
}

export default LocationMapIcon;