import React from "react";

function EstimationMessagesTemplatesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="18.5"
      viewBox="0 0 21.5 18.5"
    >
      <g
        fill="#292d32"
        data-name="Group 179970"
        transform="translate(5130.75 11646.25)"
      >
        <path
          d="M15 17.75H5a.75.75 0 010-1.5h10a4.442 4.442 0 003.115-.987A4.271 4.271 0 0019.25 12V5a4.271 4.271 0 00-1.135-3.263A4.442 4.442 0 0015 .75H5a4.442 4.442 0 00-3.115.987A4.271 4.271 0 00.75 5a.75.75 0 01-.75.75A.75.75 0 01-.75 5 5.686 5.686 0 01.865.638 5.869 5.869 0 015-.75h10A5.869 5.869 0 0119.135.638 5.686 5.686 0 0120.75 5v7a5.686 5.686 0 01-1.615 4.362A5.869 5.869 0 0115 17.75z"
          transform="translate(-5130 -11645.5)"
        ></path>
        <path
          d="M4.995 3.865a3.761 3.761 0 01-2.342-.778L-.469.585A.75.75 0 01-.585-.469.75.75 0 01.469-.585l3.12 2.5a2.4 2.4 0 002.814 0l3.129-2.5a.75.75 0 011.054.118.75.75 0 01-.118 1.053l-3.13 2.5a3.761 3.761 0 01-2.343.779z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(-5125 -11640)"
        ></path>
        <path
          d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(-5130 -11632.5)"
        ></path>
        <path
          d="M3 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(-5130 -11636.5)"
        ></path>
      </g>
    </svg>
  );
}

export default EstimationMessagesTemplatesIcon;