import { ACTION_RESET_DATA, ACTION_SET_BLOCK_FEILDS, ACTION_SET_TRNX_VAL, ACTION_UPDATE_DATA } from '../actions';

const estTrnxReducer = (state = { data: {}, blocks: {}, forceUpdate: 0 }, action) => {
    switch (action.type) {
        case ACTION_SET_TRNX_VAL:
            state.forceUpdate = state.forceUpdate + 1;
            state.data[action.name] = action.value;
            return { ...state };

        case ACTION_SET_BLOCK_FEILDS:
            let blocksData = { ...state };
            blocksData.blocks[action.blockId] = action.feilds;
            return blocksData;

        case ACTION_UPDATE_DATA:
            let data = { ...state.data, ...action.value };
            state.data = data;
            state.forceUpdate = state.forceUpdate + 1;
            return { ...state };

        case ACTION_RESET_DATA:
            state.data = {};
            return { ...state };

        default:
            return state;
    }
};

export default estTrnxReducer;