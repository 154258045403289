import { deleteUrlParam, getUrlParam, handleError, isNotEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import XIcon from "../../../icons/X";
import { getDomainValue, getDomainValues, getEstimationDomains, getPriceAgreement } from "../../../util/apis";
import { AGREEMENT_LEVEL_DOMAIN_CUSTOM, AGREEMENT_LEVEL_DOMAIN_CUSTOM_PROPERTY_TYPE, AREA_LEVEL_DOMAIN, ESTIMATION_DOMAINS_STAGES, ESTIMATION_DOMAINS_TRANSACTION_TYPE, INVOICE_CREATION_END_MONTH, LOCATION_LEVEL_DOMAIN, PARTY_LEVEL_CUSTOMER, SIGN_DOMAIN, STATUS_ACTIVE, STATUS_DOMAIN } from "../../../util/constants";
import { getUser } from "../../../util/cookies";
import { closeSummary } from "../../../util/util";
import Actions from "./actions";
import CustomFees from "./CustomFees";
import Home from "./Home";
import LocationFessUpon from "./LocationFessUpon";
import Party from "./Party";
import PriceAgreementComponent from "./PriceAgreementComponent";
import PropertyTypeFeesUpon from "./PropertyTypeFeesUpon";
import style from './style.module.scss';
import Summary from "./summary";
import Validity from "./Validity";

const PriceAgreement = () => {
    const { t } = useTranslation();
    const id = getUrlParam('id');
    const fromSaveActionRef = useRef(false);

    const isRetrieve = useRef(true);

    const [data, setData] = useState({});

    const currentUser = getUser();

    const [lov, setLov] = useState({
        status: [],
        sign: [],
        locationLevel: [],
        areaLevel: [],
        countries: [],
        regions: [],
        cities: [],
    });

    useEffect(() => {
        Promise.all([
            getDomainValues(STATUS_DOMAIN),
            getDomainValues(SIGN_DOMAIN),
            getDomainValues(LOCATION_LEVEL_DOMAIN),
            getDomainValues(AREA_LEVEL_DOMAIN),
            getEstimationDomains(ESTIMATION_DOMAINS_TRANSACTION_TYPE),
            getEstimationDomains(ESTIMATION_DOMAINS_STAGES),
            getEstimationDomains("5009"),
            getEstimationDomains("5010"),
            getEstimationDomains("5011"),
            getEstimationDomains("5012")])
            .then((response) => {
                lov.status = response[0].data || [];
                lov.sign = response[1].data || [];
                lov.locationLevel = response[2].data || [];
                lov.areaLevel = response[3].data || [];
                lov.estimationDomains1048 = response[4].data || [];
                lov.estimationDomains1064 = response[5].data || [];
                lov.estimationDomains5009 = response[6].data || [];
                lov.estimationDomains5010 = response[7].data || [];
                lov.estimationDomains5011 = response[8].data || [];
                lov.estimationDomains5012 = response[9].data || [];
                setLov({ ...lov });
            }).catch((error) => handleError(error, null, t));
    }, []);

    useEffect(() => {
        if (isNotEmpty(id) && !fromSaveActionRef.current) {
            getPriceAgreement(id)
                .then((response) => setData({ ...response.data }))
                .catch(() => _newAction());
        } else if (!fromSaveActionRef.current) {
            _newAction();
        }
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
        }
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
        }
    }, [id])

    const _newAction = async () => {
        const levelRes = await getDomainValue(AGREEMENT_LEVEL_DOMAIN_CUSTOM_PROPERTY_TYPE);
        const levelData = levelRes.data;
        const tempData = {
            isActive: STATUS_ACTIVE,
            fcuId: currentUser?.fcuId,
            exchngRate: 1,
            defaultPriceAlc: 0,
            defaultPriceAfc: 0,
            day: 0,
            party: PARTY_LEVEL_CUSTOMER,
            level: AGREEMENT_LEVEL_DOMAIN_CUSTOM_PROPERTY_TYPE,
            invoiceCreation: INVOICE_CREATION_END_MONTH,
            levelData: levelData
        };
        setData({ ...tempData });
        deleteUrlParam('id');
    }

    const _handleDataChange = (name, value) => {
        data[name] = value;

        if (name == "validityToDhi" || name == "validityFromDhi") {
            if (isNotEmpty(id) && isRetrieve.current && (!data.partyData || !data.levelData || !data.currencyData)) {
                isRetrieve.current = false;
                return
            }
        }

        if (name === 'gprId') {
            data.gprId = value.value;
            data.partyData = value;
        }
        else if (name === "party") {
            data.gprId = null;
            data.partyData = null;

            if (value == PARTY_LEVEL_CUSTOMER) {
                (data?.priceListAgreementFieldsCustoms || []).forEach(element => {
                    element.atrId = null;
                });
            }

        } else if (name === "level") {
            data.level = value.id;
            data.levelData = value;
            data.percentage = "0";
            data.defaultPriceAfc = "0";
            data.defaultPriceAlc = "0";
            data.priceListAgreementCustPropertyTypes = [];
            data.priceListAgreementCustomLocations = [];
            data.priceListAgreementFieldsCustoms = [];
        }
        else if (name == "fcuId") {
            data.fcuId = value.id;
            data.currencyData = value;
        }

        setData({ ...data });
    }

    return (
        <>
            <div className={style.container} >
                <div className={style.linesContainer} >
                    <div id='price-agreements' className={`toggle-mode ${style.toggleMode}`}>
                        <Home data={data} handleDataChange={_handleDataChange} />
                        <Party data={data} handleDataChange={_handleDataChange} />
                        <Validity data={data} handleDataChange={_handleDataChange} />
                        <PriceAgreementComponent data={data} handleDataChange={_handleDataChange} currentUser={currentUser} />

                        {data?.level == AGREEMENT_LEVEL_DOMAIN_CUSTOM_PROPERTY_TYPE &&
                            <>
                                <PropertyTypeFeesUpon
                                    data={data}
                                    setData={setData}
                                    lov={lov}
                                    currentUser={currentUser}
                                />

                                <LocationFessUpon
                                    data={data}
                                    setData={setData}
                                    handleDataChange={_handleDataChange}
                                    lov={lov}
                                    currentUser={currentUser}
                                />
                            </>
                        }

                        {data?.level == AGREEMENT_LEVEL_DOMAIN_CUSTOM &&
                            <CustomFees
                                data={data}
                                setData={setData}
                                lov={lov}
                                currentUser={currentUser}
                            />
                        }
                    </div>
                </div>

                <div id='price-agreement-summary' className="mobileSummary">
                    <span className='customHeaderSummarySm'>
                        <label>{t('summary')}</label>
                        <button onClick={() => closeSummary('price-agreement-summary')}> <XIcon /> </button>
                    </span>
                    <Summary data={data} />
                </div>
                <div id='summary-bg-mobile' onClick={() => closeSummary('price-agreement-summary')} className='mobileBg'></div>

            </div>

            <Actions
                currentUser={currentUser}
                data={data}
                setData={setData}
                newAction={_newAction}
                fromSaveActionRef={fromSaveActionRef}
            />
        </>
    );
}

export default forwardRef(PriceAgreement);