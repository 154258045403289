import React from "react";

function ChevronRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
    >
      <defs>
        <clipPath id="ChevronRight">
          <path
            fill="rgba(22,22,52,0)"
            d="M5.209 7.8a.635.635 0 010-.943l2.352-2.2H.714A.692.692 0 010 4a.691.691 0 01.714-.667h6.847l-2.352-2.2a.636.636 0 010-.943.753.753 0 011.011 0l3.571 3.338.025.025.012.013.011.012.01.012.01.012.01.013A.633.633 0 0110 4a.644.644 0 01-.223.484L6.22 7.8a.751.751 0 01-1.011 0z"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 178464" transform="rotate(180 5 4)">
        <path
          fill="rgba(22,22,52,0)"
          d="M5.209 7.8a.635.635 0 010-.943l2.352-2.2H.714A.692.692 0 010 4a.691.691 0 01.714-.667h6.847l-2.352-2.2a.636.636 0 010-.943.753.753 0 011.011 0l3.571 3.338.025.025.012.013.011.012.01.012.01.012.01.013A.633.633 0 0110 4a.644.644 0 01-.223.484L6.22 7.8a.751.751 0 01-1.011 0z"
          data-name="Mask"
        ></path>
        <g clipPath="url(#ChevronRight)" data-name="Mask Group 51">
          <g data-name="Black Base-900" transform="translate(-4.167 -5)">
            <path
              fill="#0a1f44"
              d="M0 0H20V20H0z"
              data-name="Black / Black Base-900"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ChevronRight;
