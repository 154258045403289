import React from "react";

function PhoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.33 24.33">
      <path
        fill="#606060"
        d="M26.21 17.78c-.17-.26-1.3-.81-1.3-.81-.45-.22-3.76-1.84-4.38-2.07-.53-.19-1.14-.41-1.68.4-.41.61-1.61 2.01-2.01 2.45-.27.31-.53.41-1.14.11-.1-.05-3.05-1.31-5.08-3.11-1.8-1.59-3.05-3.56-3.51-4.33-.28-.47-.1-.69.25-1.04.21-.21 1.15-1.34 1.22-1.44.15-.23.41-.77.41-.77.27-.53.1-.99-.05-1.3-.11-.21-1.8-4.3-1.97-4.68C6.5.04 5.98-.02 5.49 0 5.25.02 3 .03 2.29.81l-.13.13C1.46 1.69 0 3.24 0 6.23c0 .69.11 1.41.34 2.21.42 1.44 1.23 3.02 2.31 4.46.02.03 1.67 2.31 2.49 3.26 2.6 2.99 5.53 5.2 8.49 6.36 3.78 1.48 5.4 1.82 6.32 1.82.4 0 1.25-.2 1.43-.22 1.16-.1 3.9-1.53 4.48-3.14.55-1.52.56-2.85.35-3.19z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
