import GHDatePicker from "@ipgd-gauge/frm-g-h-date";
import { convertGregorianDateToHijri, convertHijriDateToGregorianDate, convertHijriToMilliseconds } from "../../util/apis";

const FrmGHDatePicker = (props) => {
    const { id, placeholder, onGregorianChange, onHijriChange, gregorianDate, hijriDate, disabled, disabledHijri } = props;

    return (
        <GHDatePicker
            id={id}
            placeholder={placeholder}
            onGregorianChange={onGregorianChange}
            onHijriChange={onHijriChange}
            gregorianDate={gregorianDate}
            hijriDate={hijriDate}
            disabledGregorian={disabled}
            disabledHijri={disabledHijri == null ? true : disabledHijri}
            convertGregorianDateToHijri={convertGregorianDateToHijri}
            convertHijriDateToGregorianDate={convertHijriDateToGregorianDate}
            convertHijriToMilliseconds={convertHijriToMilliseconds}
        />
    )
}

export default FrmGHDatePicker;