import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getDomainValues } from "../../../util/apis";
import { STATUS_DOMAIN } from "../../../util/constants";
import { RSM2659_PROPERTIES_TYPES_FORM_ID } from "../../../util/constants/forms";
import StatusSelectMenu from "../../../components/status-select-menu";

const PropertiesTypesFilter = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();
    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return Object.assign(filterData, {});
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({ open, getFilterData }));

    return (
        <AdvanceFilter
            id="properties-types-filter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={1}
            formId={RSM2659_PROPERTIES_TYPES_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('propertiesTypes:status')}</label>
                            <StatusSelectMenu
                                id="properties-types-filter-status"
                                value={filterData.status}
                                isClearable={true}
                                api={() => getDomainValues(STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(PropertiesTypesFilter);