import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import MessageTypesSelectMenu from "../../../components/message-type-select-menu";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues, getRealEstateUserLOV } from "../../../util/apis";
import { DOMAIN_ALERT_TYPE, DOMAIN_MESSAGE_STATUS, DOMAIN_PROGRESS_STATUS, DOMAIN_PROGRESS_STATUS_NOT_STARTED } from "../../../util/constants";
import { RSM3246_INBOX_FORM_ID } from "../../../util/constants/forms";
import { isEmpty } from "@ipgd-gauge/utils";
const InboxFilter = (props, ref) => {

    const { inboxTableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const flagList = [
        { label: t('inbox:unfavourite'), value: "0" },
        { label: t('inbox:favourite'), value: "1" },
    ]

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const _getDefaultFilter = () => {
        return { progressStatuses: [DOMAIN_PROGRESS_STATUS_NOT_STARTED] }
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }))

    return (
        <AdvanceFilter
            id="inboxFilter"
            ref={advanceFilterRef}
            filterData={filterData}
            getDefaultFilter={_getDefaultFilter}
            setFilterData={setFilterData}
            searchAction={() => inboxTableRef?.current?.refresh()}
            formId={RSM3246_INBOX_FORM_ID}
            numOfRows={5}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row advanceFilterContainer filter-panel-row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('inbox:message_type')}</label>
                            <MessageTypesSelectMenu
                                id="message-type-filter"
                                isMulti={true}
                                value={filterData.types}
                                api={() => getDomainValues(DOMAIN_ALERT_TYPE)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleDataChanged("types", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('inbox:sender')}</label>
                            <FrmSelectMenu
                                id="sender-filter"
                                value={filterData.senderId}
                                defualtValueId={filterData.senderId}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, isEmpty(filterData.senderId) ? [] : [filterData.senderId], true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isAsync={true}
                                onChange={e => _handleDataChanged("senderId", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('inbox:progress_status')}</label>
                            <StatusSelectMenu
                                id="progress-status-filter"
                                isMulti={true}
                                value={filterData.progressStatuses}
                                api={() => getDomainValues(DOMAIN_PROGRESS_STATUS)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleDataChanged("progressStatuses", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('inbox:status')}</label>
                            <FrmSelectMenu
                                id="status-filter"
                                isClearable
                                value={filterData.status}
                                api={() => getDomainValues(DOMAIN_MESSAGE_STATUS)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleDataChanged("status", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('inbox:flaged')}</label>
                            <FrmSelectMenu
                                id="flaged-filter"
                                isClearable
                                options={flagList}
                                value={filterData.flag}
                                idName="value"
                                descLo={['label']}
                                descFo={['label']}
                                onChange={e => _handleDataChanged("flag", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('inbox:from_date')}</label>
                            <FrmTimeDatePickr
                                id="from-date"
                                enableTime={true}
                                value={filterData.fromDate}
                                onChange={value => _handleDataChanged("fromDate", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('inbox:to_date')}</label>
                            <FrmTimeDatePickr
                                id="to-date"
                                enableTime={true}
                                value={filterData.toDate}
                                onChange={value => _handleDataChanged("toDate", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(InboxFilter);