import React from "react";

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.32"
      height="22.595"
      viewBox="0 0 32.32 22.595"
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2.122 11.993l9.1 9.1L30.197 2.119"
        data-name="Path 6250"
      ></path>
    </svg>
  );
}

export default SuccessIcon;