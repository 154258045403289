import MapComponent from '@ipgd-gauge/map';
import { forwardRef } from "react";

const Map = (props, ref) => {

    const { handleChange, key, value, MapPinCard, data, onChange, withSearch, withPin, zoom, onPinClick, onPinDblClick, mapStyle, handleZoomChange, disableChangeOnMove } = props;

    const MAP_KEY = JSON.parse(sessionStorage.getItem("systemConfig"))?.mapKey;

    return (
        <MapComponent
            ref={ref}
            handleChange={handleChange}
            key={key}
            value={value}
            MapPinCard={MapPinCard}
            data={data}
            onChange={onChange}
            withSearch={withSearch}
            withPin={withPin}
            zoom={zoom}
            onPinClick={onPinClick}
            onPinDblClick={onPinDblClick}
            mapStyle={mapStyle}
            mapKey={MAP_KEY}
            handleZoomChange={handleZoomChange}
            disableChangeOnMove={disableChangeOnMove}
        />
    )
}

export default forwardRef(Map);