import { formatDateNTime, formatMoney, isEnglish, isNotEmpty } from '@ipgd-gauge/utils';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import LocationMapIcon from '../../icons/LocationMap';
import PrinterIcon from '../../icons/Printer';
import UserMapIcon from '../../icons/UserMap';
import XIcon from '../../icons/X';
import { ACTION_PRINT_PDF, PROPERTY_TYPE_FLAT, PROPERTY_TYPE_GENERAL, PROPERTY_TYPE_LAND } from '../../util/constants';
import style from './style.module.scss';

const MapCard = (props) => {
    const { id, data, withCancelButton, cancelButtonAction, showAlways, withPrintAction, chooseReportDialogRef } = props;
    const { t } = useTranslation(["estimationMap"]);

    const _showPrintAction = () => {
        if (withPrintAction && isNotEmpty(data.estimationTrxId)) {
            return true;
        }

        return false;
    }

    const _printAction = (e) => {
        e.stopPropagation();
        chooseReportDialogRef?.current?.open(ACTION_PRINT_PDF, data.estimationTrxId, null, data.code)
    }

    return (
        <div id={id} className={`${style.mapCardContainer}${_showPrintAction() ? ' withActions' : ''}${showAlways ? ' active' : ''}`}>
            {withCancelButton &&
                <button
                    className={style.cancelBtn}
                    onClick={() => {
                        if (cancelButtonAction) {
                            cancelButtonAction();
                        }
                    }}
                >
                    <XIcon />
                </button>
            }
            <div className={style.containerInfoCard}>
                <div className={` ${style.typeContainer} ${data?.propertyType == PROPERTY_TYPE_GENERAL ? style.office : ""} ${data?.propertyType == PROPERTY_TYPE_FLAT ? style.appar : ""} ${data.estimationHistoryId ? style.estimationHistoryIcon : ""} ${data.propertyType == PROPERTY_TYPE_LAND ? style.green : ""} `}>
                    <label title={isEnglish() ? data?.descLo : data?.descFo}>{isEnglish() ? data?.descLo : data?.descFo}</label>
                </div>
                <div title={formatDateNTime(data.dateTime)} className={style.dateCard}> {formatDateNTime(data.dateTime)}</div>
            </div>
            <div className={style.mapCardDetails}>
                <div className={style.mapCardHeader}>
                    <label title={data.code} className={style.mapCardCode}>{data.code}</label>
                    <label title={formatMoney(data.evaluationPrice)} className={style.mapCardEvaluationPrice}>{formatMoney(data.evaluationPrice)}</label>
                </div>
                <div className={style.userMapCard}>
                    <span>
                        <UserMapIcon />
                    </span>
                    <label title={i18next.language === 'en' ? data.officialNameLo : data.officialNameFo} style={{ fontWeight: '500' }}>{i18next.language === 'en' ? data.officialNameLo : data.officialNameFo}</label>
                </div>
                <div className={style.locationMapCard}>
                    <span>
                        <LocationMapIcon />
                    </span>
                    <label title={i18next.language == 'en' ? data.addressLo : data.addressFo} className={style.locationLbl}>{i18next.language == 'en' ? data.addressLo : data.addressFo}</label>
                </div>
                <div className={style.contentMapCardContainer}>
                    <div>
                        <div className={style.contentMapCard}>
                            <div className={style.pricesContainer}>
                                <label className={style.lblMapCardDetails}>
                                    {t('land')}
                                </label>
                                <div className={style.landAreaContainer} title={`${data.landArea} ${i18next.language == "en" ? 'M2' : 'م2'} `}>
                                    <label className={style.valMapCardDetails}>{data.landArea}</label>
                                    <span className={style.currency}>{i18next.language == "en" ? 'M2' : 'م2'}</span>
                                </div>
                            </div>
                            <div title={`${formatMoney(data.parcelPricePerM)} ${(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}`} className={style.measurePrice}>
                                <label>{formatMoney(data.parcelPricePerM)}</label>
                                <span className={style.currency}>{(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}</span>
                            </div>
                        </div>

                        <div className={style.contentMapCard}>
                            <div className={style.pricesContainer}>
                                <label className={style.lblMapCardDetails}>
                                    {t('building')}
                                </label>
                                <div className={style.landAreaContainer}>
                                    <label title={data.buildingArea} className={style.valMapCardDetails}>{data.buildingArea}</label>
                                    <span title={i18next.language == "en" ? 'M2' : 'م2'} className={style.currency}>{i18next.language == "en" ? 'M2' : 'م2'}</span>
                                </div>
                            </div>
                            <div className={style.measurePrice} title={` ${formatMoney(data.buildMeterValue)} ${(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''} `}>
                                <label>{formatMoney(data.buildMeterValue)}</label>
                                <span className={style.currency}>{(i18next.language == 'en' ? data.shDescLo : data.shDescFo) || ''}</span>
                            </div>
                        </div>
                    </div>
                    {_showPrintAction() &&
                        <div className={style.actions}>
                            <button
                                onClick={_printAction}
                                title={t('print')}
                            >
                                <PrinterIcon />
                            </button>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default MapCard;