import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID } from "../../../util/constants/forms";

const Tab1 = (props) => {
    const { tab, dialogId, data, setData } = props;
    const { t } = useTranslation('estimationQuotationsTypes');

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${dialogId}-organization-lbl`} className='form-label mandatory'>{t('organization')}</label>
                        <FrmInput
                            id={`${dialogId}-organization`}
                            value={isEnglish() ? data?.orgDescLo : data?.orgDescFo}
                            disabled
                        />
                    </div>
                    <div className="w100">
                        <CodeInput
                            id={`${dialogId}-code`}
                            formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
                            column="UMY_CODE"
                            onBlur={(value) => _handleDataChanged("code", value)}
                            value={data?.code}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            sidePanelMode
                            options={[
                                {
                                    id: 'active',
                                    title: t('active'),
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: data.isActive == STATUS_ACTIVE
                                },
                                {
                                    id: 'inactive',
                                    title: t('inactive'),
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: data.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={data.descLo}
                            onBlur={(value) => _handleDataChanged("descLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={data.descFo}
                            onBlur={(value) => _handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-remarks`}
                            value={data.rem}
                            onBlur={(value) => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;