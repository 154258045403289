import UtilUploadImg from "@ipgd-gauge/upload-img";
import "lightgallery.js/dist/css/lightgallery.css";
import { forwardRef } from 'react';
import { deleteAttachment, downloadAttachment, getAttachments, saveAttachment } from '../../util/apis';

const UploadImg = (props, ref) => {
    const { key, isMulti, masterId, formId, isProfile, onChange, title, note, accept, mandatory, customStyle, disableEdit, onFilesChangeLocally, onDeleteRetrievedFiles, onRetrieve, withNameInput, canDelete, inSidePanel } = props;

    return (
        <UtilUploadImg
            ref={ref}
            isMulti={isMulti}
            masterId={masterId}
            formId={formId}
            isProfile={isProfile}
            onChange={onChange}
            title={title}
            note={note}
            accept={accept}
            mandatory={mandatory}
            customStyle={customStyle}
            disableEdit={disableEdit}
            deleteAttachment={deleteAttachment}
            downloadAttachment={downloadAttachment}
            getAttachments={getAttachments}
            saveAttachment={saveAttachment}
            onFilesChangeLocally={onFilesChangeLocally}
            onDeleteRetrievedFiles={onDeleteRetrievedFiles}
            onRetrieve={onRetrieve}
            withNameInput={withNameInput}
            canDelete={canDelete}
            inSidePanel={inSidePanel}
            key={key}
        />
    );
}

export default forwardRef(UploadImg);