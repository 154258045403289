import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteCustomer, getCustomers } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3009_CREATE_CUSTOMER_RSM_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {

    const { t } = useTranslation();
    const { data, rowActions } = props;
    const user = getUser();

    return (
        <div className={`${style.customersTableRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>
            <div title={data.code} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('customers:code')}</label>
                <label>{data.code}</label>
            </div>
            <div title={isEnglish() ? data.officialNameLo : data.officialNameFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('customers:name')}</label>
                <label>{isEnglish() ? data.officialNameLo : data.officialNameFo}</label>
            </div>
            <div title={data.mobileNum} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('customers:mobile_num')}</label>
                <label>{data.mobileNum}</label>
            </div>
            <div title={data.email} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('customers:email')}</label>
                <label>{data.email}</label>
            </div>
            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('customers:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => rowActions?.customersDialogRef?.current?.open(data.id)} />
                    }
                    {isAllowedAction(user, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => rowActions?.deleteCustomer(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const CustomersTable = (props) => {

    const { customersTableRef, searchTextRef, filtersRef, customersDialogRef } = props;
    const { t } = useTranslation();

    const [customers, setCustomers] = useState();

    const _deleteCustomer = (customerId) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteCustomer(customerId)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    customersTableRef?.current?.refresh();
                })
                .catch((error) => {
                    handleError(error, "delete_fields", t);
                })
        });
    }

    const columns = [
        { title: '#', width: "3%" },
        { title: t('customers:code'), width: "12%" },
        { title: t('customers:name'), width: "19%" },
        { title: t('customers:mobile_num'), width: "19%" },
        { title: t('customers:email'), width: "19%" },
        { title: t('customers:status'), width: "11%" },
        { title: "", width: "17%" },
    ];

    const _fetchData = (page, size) => {
        getCustomers(page, size, searchTextRef?.current?.getSearchText(), filtersRef.current?.getFilterData())
            .then((response) => {
                setCustomers(response.data);
            })
            .catch((error) => {
                handleError(error, null, t)
            })
    }

    return (
        <div className={style.customersTableContainer}>
            <Slisting
                id="customersTableRefTable"
                ref={customersTableRef}
                formId={RSM3009_CREATE_CUSTOMER_RSM_FORM_ID}
                columns={columns}
                Row={Row}
                withFilter={true}
                tableStyle={style.customersTable}
                pagingData={customers}
                getData={_fetchData}
                showNoDataFound={(data) => data && data.length == 0}
                rowActions={{ customersDialogRef, deleteCustomer: _deleteCustomer }}
                showWating={(data) => data == null}
                newAction={() => customersDialogRef?.current?.open()}
                deleteAction={deleteCustomer}
                viewAction={(id) => customersDialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="80px"
            />
        </div>
    );
};

export default CustomersTable;