import Image from "@ipgd-gauge/image";
import { fixImagePath, isEnglish } from '@ipgd-gauge/utils';
import Dropdown from "rc-dropdown";
import Menu, { MenuItem } from 'rc-menu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DateIcon from '../../../../icons/Date';
import ImportantIcon from '../../../../icons/Important';
import InformationIcon from "../../../../icons/Information";
import UnauthorizedIcon from "../../../../icons/UnauthorizedIcon";
import { getDomainValue, getEstTrxLovData, getEstimationTransactionsStatuses } from '../../../../util/apis';
import { formatDateNTime } from '../../../../util/dateUtil';
import style from './style.module.scss';

const Title = (props) => {
    const { getTrxKey } = props;
    const value = useSelector(state => state.estTrnxReducer.data);
    const numOfAttachments = useSelector(state => state.estTrnxReducer.data.TRI_NUM_OF_ATTACH);
    const { t } = useTranslation('estimationTransaction');
    let [status, setStatus] = useState({});
    let [customer, setCustomer] = useState({});
    const showUserNotAuthorizedAlert = useSelector(state => state.estTrnxReducer.data.SHOW_USER_NOT_AUTHORIZED_ALERT);
    const usersWorkingOnTrx = useSelector(state => state.estTrnxReducer.data.USERS_WORKING_ON_TRX);
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;

    useEffect(() => {
        if (value.TRI_OAE_ID) {
            getEstimationTransactionsStatuses(value.TRI_OAE_ID).then(async (res) => {
                const data = res.data;
                if (data) {
                    const colorDomain = await getDomainValue(data.color);
                    if (colorDomain.data) {
                        data.colorValue = colorDomain.data?.descLo;
                    }
                    setStatus(data);
                }
            })
        } else if (value.TRI_STATUS) {
            getDomainValue(value.TRI_STATUS)
                .then((response) => {
                    setStatus(response.data)
                })
                .catch(() => setStatus({}))
        } else {
            setStatus({});
        }
    }, [value.TRI_OAE_ID, value.TRI_STATUS])

    useEffect(() => {
        if (value.TRI_ATR_ID) {
            getEstTrxLovData({ fieldName: 'TRI_ATR_ID', key: getTrxKey(), ids: [value.TRI_ATR_ID] }).then(res => {
                if (res.data?.length) {
                    setCustomer(res.data[0])
                }
            })
        }
    }, [value.TRI_ATR_ID])

    const _getStatusTxtColor = (status) => {
        switch (status) {
            case 'white':
                return '#000';
            case 'black':
                return '#fff';
            default:
                return '#fff';
        }
    }

    const _goToAttachmentBlock = () => {
        const target = document.getElementById(`block-0`);
        if (target) {
            target.parentNode.scrollTop = target.offsetTop - 140;
        }
    }

    const users = () => {
        return (
            <Menu className={style.usersMenu} direction={isEnglish() ? "ltr" : "rtl"}>
                {usersWorkingOnTrx.map((user, index) => {
                    return <MenuItem key={index} dir={isEnglish() ? "ltr" : "rtl"}>
                        <div key={index} className={style.userRow}>
                            <div style={{ margin: isEnglish() ? "0 5px 0 0" : "0 0 0 5px" }} >
                                <Image
                                    src={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(user.imagePath)}`}
                                    altSrc="./images/avatar.png"
                                />
                            </div>
                            {isEnglish() ? user.nameLo : user.nameFo}
                        </div>
                    </MenuItem>
                })}
            </Menu>
        )
    }

    return (
        <div>
            <div className={`frm-display-panel-header ${style.titleHeader}`}>
                <div className={style.headerTitleInfo}>
                    <div className={style.titleImg}>
                        <Image
                            src={customer?.smallLogo ? `${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(customer.smallLogo)}` : null}
                            altSrc="./images/avatar.png"
                        />
                    </div>
                    <div className={style.titleName}>
                        <label className={style.name}>{isEnglish() ? customer?.descLo || '' : customer?.descFo || ''}</label>
                        <label className={style.parcode}>{`${value.TRI_CODE ? `# ${value.TRI_CODE}` : ''}`}</label>
                    </div>
                </div>
                <div>
                    <div className={style.dateCard}>
                        <label>{formatDateNTime(value.TRI_DATE_TIME || value.TRI_ENTRY_DATE_TIME)}</label>
                        <DateIcon />
                    </div>
                    <div className={style.valCardContainer}>
                        {showUserNotAuthorizedAlert &&
                            <div className={style.userNotAuthorizedAlert} ><UnauthorizedIcon /> {t('user_not_authorized')}</div>
                        }
                        {usersWorkingOnTrx?.length > 1 &&
                            <div className={style.workinAlert}>
                                <Dropdown
                                    animation="slide-up"
                                    trigger={['click']}
                                    overlay={users}
                                    align={{ offset: [0, 0] }}
                                >
                                    <button>
                                        <InformationIcon />
                                        {t('action_being_performed_by', { "usersNumber": (usersWorkingOnTrx?.length - 1) || 0 })}
                                    </button>
                                </Dropdown>
                            </div>
                        }
                        <button
                            onClick={_goToAttachmentBlock}
                            className={style.numberOfAttachments}
                        >
                            <label>{numOfAttachments || 0}</label>
                            <span>
                                <ImportantIcon />
                            </span>
                        </button>
                        <button className={style.newTransactionBtn} style={{ background: status?.colorValue || '#63B3D3', color: _getStatusTxtColor(status?.colorValue) }}>
                            {status?.id ? (isEnglish() ? status?.descLo || '' : status?.descFo || '') : t('incomplete')}
                        </button>
                    </div>
                </div>
            </div>
            {usersWorkingOnTrx?.length > 1 &&
                <div className={style.workinAlertSm}>
                    <Dropdown
                        animation="slide-up"
                        trigger={['click']}
                        overlay={users}
                        align={{ offset: [0, 0] }}
                    >
                        <button>
                            <InformationIcon />
                            {t('action_being_performed_by', { "usersNumber": (usersWorkingOnTrx?.length - 1) || 0 })}
                        </button>
                    </Dropdown>
                </div>
            }
            {showUserNotAuthorizedAlert &&
                <div className={style.userNotAuthorizedAlertSm} ><UnauthorizedIcon /> {t('user_not_authorized')}</div>
            }
        </div>
    )
}

export default Title;