import React from "react";

function DropdownTree(props) {
  return (
    <svg
      id={props.id ? props.id : ''}
      xmlns="http://www.w3.org/2000/svg"
      width="12.545"
      height="7.973"
      viewBox="0 0 12.545 7.973"
    >
      <path
        fill="#94a3b8"
        fillRule="evenodd"
        d="M2419.324 34.35a1.265 1.265 0 000 1.691l5.166 5.581a1.051 1.051 0 001.566 0l5.165-5.581a1.265 1.265 0 000-1.691 1.048 1.048 0 00-1.565 0l-4.384 4.735-4.383-4.735a1.049 1.049 0 00-1.565 0"
        data-name="Path 38438"
        transform="translate(-2419 -34)"
      ></path>
    </svg>
  );
}

export default DropdownTree;
