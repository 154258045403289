import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3246_INBOX_FORM_ID } from "../../util/constants/forms";
import InboxTable from "./listing";
import InboxFilter from "./listing/Filter";

const Inbox = ({ isTab }) => {

    const { t } = useTranslation('inbox');
    const searchTextRef = useRef();
    const inboxTableRef = useRef();
    const filterDialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                {!isTab ?
                    <FormTitle formId={RSM3246_INBOX_FORM_ID} />
                    : <div></div>
                }
                <div className="headerSearchPanel">
                    <QuickSearch
                        id="inbox"
                        ref={searchTextRef}
                        placeholder={`${t('code')} , ${t('trnx_code')} , ${t('content')}`}
                        search={() => inboxTableRef.current.refresh()}
                        formId={RSM3246_INBOX_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterDialogRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <InboxTable isTab={isTab} inboxTableRef={inboxTableRef} searchTextRef={searchTextRef} filterDialogRef={filterDialogRef} />

            <InboxFilter ref={filterDialogRef} inboxTableRef={inboxTableRef} />
        </>
    )
}

export default Inbox;