import React from "react";

function RegionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.746"
      height="21.495"
      viewBox="0 0 18.746 21.495"
    >
      <g
        fill="#292d32"
        data-name="Group 179957"
        transform="translate(5110.373 11037.75)"
      >
        <path
          d="M3.12 6.99a3.87 3.87 0 113.87-3.87 3.874 3.874 0 01-3.87 3.87zm0-6.24a2.37 2.37 0 102.37 2.37A2.373 2.373 0 003.12.75z"
          opacity="0.4"
          transform="translate(-5104.12 -11031.81)"
        ></path>
        <path
          d="M8.62-.75a9.749 9.749 0 015.649 1.766 8.815 8.815 0 013.466 5.319 11.06 11.06 0 01-.977 7.421 19.047 19.047 0 01-4.015 5.326 5.943 5.943 0 01-8.249 0A19.087 19.087 0 01.487 13.75a11.083 11.083 0 01-.976-7.426 8.815 8.815 0 013.467-5.311A9.744 9.744 0 018.62-.75zm0 20A4.414 4.414 0 0011.7 18c2.675-2.568 5.637-6.618 4.569-11.335A7.56 7.56 0 008.62.75 7.559 7.559 0 00.974 6.656C-.093 11.371 2.864 15.428 5.533 18a4.44 4.44 0 003.089 1.245z"
          data-name="Vector"
          transform="translate(-5109.623 -11037)"
        ></path>
      </g>
    </svg>
  );
}

export default RegionIcon;