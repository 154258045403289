import React from "react";

function DetailsImgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.086"
      height="22.084"
      data-name="Group 175705"
      viewBox="0 0 22.086 22.084"
    >
      <defs>
        <clipPath id="DetailsImg-path">
          <path
            fill="none"
            d="M0 0H22.086V22.085H0z"
            data-name="Rectangle 161697"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#DetailsImg-path)" data-name="Group 175338">
        <path
          fill="#e2a203"
          d="M18.852 3.235a11.04 11.04 0 100 15.618 11.041 11.041 0 000-15.618"
          data-name="Path 5285"
        ></path>
        <path
          fill="#fff"
          d="M8.591 12.4a2.75 2.75 0 00-.268-1.638A6.979 6.979 0 007.4 9.438a10.361 10.361 0 01-.975-1.288A2.8 2.8 0 016 6.6a2.742 2.742 0 01.81-2.092 3.039 3.039 0 012.175-.769 3.551 3.551 0 011.745.42 5.042 5.042 0 011.333 1.066l-.841.769a2.666 2.666 0 00-2.058-1.039 1.615 1.615 0 00-1.3.511 1.814 1.814 0 00-.439 1.226A2.207 2.207 0 007.848 8q.42.59.948 1.208a5.827 5.827 0 01.878 1.387A3.251 3.251 0 019.88 12.4zm-.152 3.445a1.081 1.081 0 01-.313-.8 1.137 1.137 0 01.313-.824A1.023 1.023 0 019.2 13.9a1 1 0 01.752.322 1.152 1.152 0 01.3.824 1.1 1.1 0 01-.3.8 1.009 1.009 0 01-.752.313 1.037 1.037 0 01-.761-.313"
          data-name="Path 5286"
          transform="translate(2.28 1.422)"
        ></path>
      </g>
    </svg>
  );
}

export default DetailsImgIcon;