import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import { getCitiesLov, getCommonCustomers, getCommonPropertyTypes, getEstimationDomains, getTownsList } from "../../../util/apis";
import { DOMAIN_USAGE, STATUS_ACTIVE } from "../../../util/constants";

const dialogId = "estimations-history-dialog";

const Tab1 = (props) => {
    const { tab, state, handleDataChanged } = props;
    const { t } = useTranslation('estimationsHistory');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${dialogId}-desc-lbl`} className='form-label mandatory'>{t('desc')}</label>
                        <FrmInput
                            id={`${dialogId}-desc`}
                            value={state?.desc}
                            onBlur={(value) => handleDataChanged("desc", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('ref_num')}</label>
                        <FrmInput
                            id={`${dialogId}-ref-number`}
                            value={state?.refNumber}
                            onBlur={(value) => handleDataChanged("refNumber", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-date-lbl`} className='form-label mandatory'>{t('date')}</label>
                        <FrmTimeDatePickr
                            id={`${dialogId}-date`}
                            value={state?.entryDateTime}
                            onChange={(value) => handleDataChanged("entryDateTime", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('city')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-city`}
                            key={`${dialogId}-city-${state.id}`}
                            descLo={['regionsCitiesLo']}
                            descFo={['regionsCitiesFo']}
                            api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, null, null, searchValue, false, true)}
                            defualtValueId={state.ecdId}
                            defualtValueApi={() => getCitiesLov(isEmpty(state.ecdId) ? [] : [state.ecdId], null, null, null, null, false, true)}
                            value={state?.ecdId}
                            onChange={value => handleDataChanged("ecdId", value.value)}
                            isAsync
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('new_edit_town')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-town`}
                            key={`town-${state?.ecdId}`}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            api={(searchValue) => getTownsList(searchValue, null, state?.ecdId, STATUS_ACTIVE, false, null, true)}
                            defualtValueId={state.criId}
                            defualtValueApi={() => getTownsList(null, isEmpty(state.criId) ? [] : [state.criId], state?.ecdId, STATUS_ACTIVE, false, null, true)}
                            value={state?.criId}
                            onChange={value => handleDataChanged("criId", value.value)}
                            isAsync
                            isDisabled={!state?.ecdId}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('customer')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-customer`}
                            value={state?.atrId}
                            onChange={value => handleDataChanged("atrId", value.value)}
                            defualtValueId={state?.atrId}
                            defualtValueApi={() => getCommonCustomers(isEmpty(state?.atrId) ? [] : [state?.atrId], STATUS_ACTIVE, null, false, false, false, [], false)}
                            api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, false, false, false, [], false)}
                            descLo={['code', 'descLo']}
                            descFo={['code', 'descFo']}
                            isAsync={true}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-property-type-lbl`} className='form-label mandatory'>{t('property_type')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-property-type`}
                            key={`${dialogId}-property-type-${state.id || ''}`}
                            value={state?.myoId}
                            onChange={value => handleDataChanged("myoId", value.value)}
                            api={() => getCommonPropertyTypes(STATUS_ACTIVE, null, false, false, false, [], false, isEmpty(state?.myoId) ? [] : [state?.myoId])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('usage')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-usage`}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            api={() => getEstimationDomains(DOMAIN_USAGE)}
                            value={state?.usage}
                            onChange={value => handleDataChanged("usage", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('parcel_num')}</label>
                        <FrmInput
                            id={`${dialogId}-parcel-number`}
                            value={state.parcelNumber || null}
                            onBlur={value => handleDataChanged("parcelNumber", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('plan_num')}</label>
                        <FrmInput
                            id={`${dialogId}-plan-number`}
                            value={state.planNumber}
                            onBlur={value => handleDataChanged("planNumber", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-price-lbl`} className='form-label mandatory'>{t('price')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-price`}
                            value={state?.price}
                            onBlur={value => handleDataChanged("price", value)}
                            allowDecimals
                            minValue={0}
                            nullable
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('land_area')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-land-area`}
                            value={state?.landArea}
                            onBlur={value => handleDataChanged("landArea", value)}
                            allowDecimals
                            minValue={0}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('building_area')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-building-area`}
                            value={state?.buildingArea}
                            onBlur={value => handleDataChanged("buildingArea", value)}
                            allowDecimals
                            minValue={0}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('land_price_per_meter')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-price-per-meter`}
                            value={state?.landPricePerMeter}
                            onBlur={value => handleDataChanged("landPricePerMeter", value)}
                            allowDecimals
                            minValue={0}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('building_price_per_meter')}</label>
                        <FrmDecimalInput
                            id={`${dialogId}-building-meter`}
                            value={state?.buildingPricePerMeter}
                            onBlur={value => handleDataChanged("buildingPricePerMeter", value)}
                            allowDecimals
                            minValue={0}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;