import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import style from "./style.module.scss";

const StatusSelectMenu = (props) => {
    const { id, key, value, api, options, descLo, descFo, onChange, isClearable, isMulti, isDisabled } = props;

    const MyOption = (props) => {
        const { value, label } = props;
        return (
            <div className={style.optionContainer}>
                <div className={`dot-status-${value}`}></div>
                <span>{label}</span>
            </div>
        )
    }

    return (
        <FrmSelectMenu
            id={id}
            key={key}
            value={value}
            api={api}
            options={options}
            OptionComponent={MyOption}
            descLo={descLo}
            descFo={descFo}
            onChange={onChange}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
            portalTarget={document.body}
        />
    )
}

export default StatusSelectMenu;