import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteActionMessageTask, getActionMessageTaskList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, deleteAction, dialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.workflowProcedureMessagesRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.workflowCode} className="cardRowMode">
                <label className="nameLblMobile">{t('workflowProcedureMessages:form_code')}</label>
                <label>{data?.workflowCode}</label>
            </div>

            <div title={isEnglish() ? data?.workflowDescLo : data?.workflowDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('workflowProcedureMessages:form_name')}</label>
                <label>{isEnglish() ? data?.workflowDescLo : data?.workflowDescFo}</label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const WorkflowProcedureMessagesTable = (props) => {
    const { tableRef, searchTextRef, dialogRef } = props;

    const { t } = useTranslation();
    const user = getUser();

    const [data, setData] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('workflowProcedureMessages:form_code'), width: "30%" },
        { title: t('workflowProcedureMessages:form_name'), width: "45%" },
        { title: "", width: "20%" },
    ];

    const _fetchData = (page, size) => {
        getActionMessageTaskList(page, size, searchTextRef.current.getSearchText())
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (isNotEmpty(id)) {
                return deleteActionMessageTask(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, "delete_fields", t));
            }
        });
    }

    return (
        <Slisting
            id="workflow-procedure-messages"
            ref={tableRef}
            formId={RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.workflowProcedureMessages}
            getData={_fetchData}
            pagingData={data}
            rowActions={{ user, deleteAction: _deleteAction, dialogRef }}
            cardInMobile
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            newAction={() => dialogRef.current?.open()}
            deleteAction={deleteActionMessageTask}
            viewAction={(id) => dialogRef.current?.open(id)}
            labelCardWidth="80px"
        />
    );
};

export default WorkflowProcedureMessagesTable;