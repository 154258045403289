import React from "react";

function DifinitionModuleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="21.5"
      data-name="Group 178516"
      viewBox="0 0 19.5 21.5"
    >
      <path
        fill="#fff"
        d="M0 .75h7A.75.75 0 007.75 0 .75.75 0 007-.75H0A.75.75 0 00-.75 0 .75.75 0 000 .75z"
        opacity="0.4"
        transform="translate(6.75 10.95)"
      ></path>
      <path
        fill="#fff"
        d="M0 .75h4.38A.75.75 0 005.13 0a.75.75 0 00-.75-.75H0A.75.75 0 00-.75 0 .75.75 0 000 .75z"
        data-name="Vector"
        opacity="0.4"
        transform="translate(9.37 14.95)"
      ></path>
      <path
        fill="#fff"
        d="M2 4.75h4a3.083 3.083 0 001.791-.376A2.626 2.626 0 008.75 2a2.626 2.626 0 00-.959-2.374A3.083 3.083 0 006-.75H2a3.083 3.083 0 00-1.791.376A2.626 2.626 0 00-.75 2a3.083 3.083 0 00.376 1.791A2.626 2.626 0 002 4.75zm4-4a2.1 2.1 0 01.959.124C7.147 1 7.25 1.4 7.25 2s-.1 1-.291 1.126A2.1 2.1 0 016 3.25H2c-.6 0-1-.1-1.126-.291A2.1 2.1 0 01.75 2c0-.6.1-1 .291-1.126A2.1 2.1 0 012 .75z"
        data-name="Vector"
        transform="translate(5.75 .75)"
      ></path>
      <path
        fill="#fff"
        d="M6 18.73h6c2.663 0 4.391-.558 5.439-1.756 1.155-1.32 1.311-3.265 1.311-4.994v-6c0-2.437-.461-4.07-1.45-5.141-.913-.988-2.227-1.478-4.26-1.588a.75.75 0 00-.789.708.75.75 0 00.708.789A4.5 4.5 0 0116.2 1.856c.708.766 1.052 2.115 1.052 4.124v6c0 2.055-.281 3.254-.939 4.006-.745.849-2.113 1.244-4.313 1.244H6c-2.2 0-3.568-.4-4.311-1.244-.658-.752-.939-1.951-.939-4.006v-6c0-2.012.344-3.362 1.052-4.127A4.507 4.507 0 015.04.749a.75.75 0 00.709-.789.75.75 0 00-.789-.709C2.925-.639 1.612-.151.7.834-.289 1.9-.75 3.539-.75 5.98v6c0 1.729.156 3.674 1.311 4.994C1.609 18.172 3.337 18.73 6 18.73z"
        data-name="Vector"
        transform="translate(.75 2.77)"
      ></path>
    </svg>
  );
}

export default DifinitionModuleIcon;