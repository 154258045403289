import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ExchangeRateInput from "../../../components/exchange-rate";
import { getCurrenciesLov, getInvoices } from "../../../util/apis";
import { GLOBAL_STATUS_CONFIRMED } from "../../../util/constants";
import { ACTION_SET_HEADER_VALUE } from "./reducer";

const ID = "credit-notes-for-customer-estimation";

const Invoice = (props) => {
    const { user, creditNote, dispatchCreditNote } = props;
    const { t } = useTranslation();
    const invoiceRef = useRef(true);

    const _handleChange = (name, value) => {
        dispatchCreditNote({ type: ACTION_SET_HEADER_VALUE, name, value })
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('creditNotesForCustomersEstimation:invoice')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label className='form-label'>{t('creditNotesForCustomersEstimation:currency')}</label>
                        <FrmSelectMenu
                            id={`${ID}-currency`}
                            key={`${ID}-currency-${creditNote?.id}`}
                            api={() => getCurrenciesLov(creditNote?.header?.fcuId)}
                            value={creditNote?.header?.fcuId}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={(e) => _handleChange("fcuId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label'>{t('creditNotesForCustomersEstimation:ex_rate')}</label>
                        <ExchangeRateInput
                            id={`${ID}-ex-rate`}
                            value={creditNote?.header?.exchngRate || ''}
                            onBlur={(value) => _handleChange("exchngRate", value)}
                            allowDecimals={true}
                            currencyId={creditNote?.header?.fcuId}
                            transactionId={creditNote.header?.id}
                            transactionDate={creditNote?.header?.dateDgr}
                            organizationCurrency={user?.fcuId}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('creditNotesForCustomersEstimation:invoice_number')}</label>
                        <FrmSelectMenu
                            id={`${ID}-invoice-number`}
                            key={creditNote?.header?.atrId}
                            api={() => getInvoices(creditNote?.header?.atrId, GLOBAL_STATUS_CONFIRMED, true, isEmpty(creditNote?.header?.isnId) ? [] : [creditNote?.header?.isnId])}
                            value={creditNote?.header?.isnId}
                            descLo={['code']}
                            descFo={['code']}
                            onChange={(e) => _handleChange("isnId", e)}
                            isDisabled={!creditNote?.header?.atrId}
                            portalTarget={document.body}
                            onDataRetrieved={(list) => {
                                const id = getUrlParam("id");
                                if (isNotEmpty(id) && creditNote?.header?.isnId && invoiceRef.current) {
                                    list?.forEach((element) => {
                                        if (element?.id == creditNote?.header?.isnId) {
                                            _handleChange("invoiceData", { ...element })
                                            return;
                                        }
                                    })
                                }
                                if (invoiceRef.current && creditNote?.header?.isnId) {
                                    invoiceRef.current = false;
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice;