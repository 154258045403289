import React from "react";

function FullScreenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.5"
      height="25.5"
      viewBox="0 0 25.5 25.5"
    >
      <g
        fill="#7819a5"
        data-name="Group 178815"
        transform="translate(-6605.75 685.25)"
      >
        <path
          d="M8.077 9.058H.231a.981.981 0 01-.981-.981V.231a.981.981 0 111.962 0V7.1h6.865a.981.981 0 010 1.962z"
          transform="translate(6606.5 -668.808)"
        ></path>
        <path
          d="M8.077 9.058a.981.981 0 01-.977-.981V1.212H.231a.981.981 0 110-1.962h7.846a.981.981 0 01.981.981v7.846a.981.981 0 01-.981.981z"
          data-name="Vector"
          transform="translate(6622.192 -684.5)"
        ></path>
        <g data-name="Group 178814">
          <path
            d="M.231 11.019a.981.981 0 01-.694-1.674L9.345-.463A.981.981 0 1110.732.924L.924 10.732a.978.978 0 01-.693.287z"
            data-name="Vector"
            transform="translate(6620.231 -684.5)"
          ></path>
          <path
            d="M.231 11.019a.981.981 0 01-.694-1.674L9.345-.463A.981.981 0 1110.732.924L.924 10.732a.978.978 0 01-.693.287z"
            data-name="Vector"
            transform="translate(6606.5 -670.769)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default FullScreenIcon;