import React from "react";

function PrevFullImgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.375"
      height="18.375"
      viewBox="0 0 18.375 18.375"
    >
      <path
        fill="#797979"
        d="M2.719 7.008V1.922A.982.982 0 013.7.937h5.089a.492.492 0 01.493.493v1.64a.49.49 0 01-.493.492H5.344v3.446a.49.49 0 01-.492.492H3.211a.492.492 0 01-.492-.492M9.282 18.82v-1.64a.492.492 0 00-.493-.493H5.344v-3.445a.49.49 0 00-.492-.492H3.211a.492.492 0 00-.492.492v5.086a.98.98 0 00.985.984h5.085a.49.49 0 00.493-.492m5.25-17.39v1.64a.488.488 0 00.492.492h3.445v3.446a.492.492 0 00.492.492H20.6a.49.49 0 00.492-.492V1.922a.982.982 0 00-.982-.985h-5.086a.49.49 0 00-.492.493M20.6 12.75h-1.639a.492.492 0 00-.492.492v3.445h-3.445a.49.49 0 00-.492.493v1.64a.488.488 0 00.492.492h5.086a.98.98 0 00.984-.984v-5.086a.49.49 0 00-.492-.492"
        data-name="Path 44847"
        transform="translate(-2.719 -.937)"
      ></path>
    </svg>
  );
}

export default PrevFullImgIcon;