import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RotateOptionIcon from '../../../icons/RotateOption';
import ServiceExpiryWarningIcon from '../../../icons/ServiceExpiryWarning';
import { LICENSE_WARNING_DAYS } from '../../../util/constants';
import style from './style.module.scss';

const DeniedServiceWarning = (props, ref) => {
    const { t } = useTranslation();

    const [closeWorning, setCloseWorning] = useState(true);
    const [days, setDays] = useState();
    useImperativeHandle(ref, () => ({
        open: (days) => {
            setCloseWorning(false)
            setDays(Math.ceil(Number.parseFloat(days)))
        }
    }));

    return (
        <div className={` ${style.mainCountDwon} ${closeWorning ? style.closeCountDwon : ""}  `}>
            <div className="openBlackScreen"></div>
            <div className={style.countDwonContaer}>
                <div className={style.deniedService}>
                    <div className={style.icons}>
                        <ServiceExpiryWarningIcon />
                    </div>
                    <div className={style.worningLbl}>
                        <label>
                            {t("service_warning")}
                            {
                                days >= 3 &&
                                <span>{days}</span>
                            }
                            { t(`days_${days > 3 ? "3" : days}`)}
                        </label>
                    </div>
                    <div className={style.counterLbl}>
                        <label>
                            {t("service_warning_desc")}
                        </label>
                    </div>
                    <div className={style.worningContaerBtn}>
                        <button className="primary-button" onClick={() => setCloseWorning(!closeWorning)}>
                            {t('continue')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default forwardRef(DeniedServiceWarning)