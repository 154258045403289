import React from "react";

function UserDashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.68"
      height="21.5"
      viewBox="0 0 18.68 21.5"
    >
      <g
        fill="#292d32"
        data-name="Group 179956"
        transform="translate(4607.34 11416.75)"
      >
        <path
          d="M5-.75A5.75 5.75 0 11-.75 5 5.757 5.757 0 015-.75zm0 10A4.25 4.25 0 10.75 5 4.255 4.255 0 005 9.25z"
          transform="translate(-4603 -11416)"
        ></path>
        <path
          d="M17.18 7.75a.75.75 0 01-.75-.75c0-3.446-3.517-6.25-7.84-6.25S.75 3.554.75 7a.75.75 0 01-.75.75A.75.75 0 01-.75 7a7.111 7.111 0 012.794-5.533A10.357 10.357 0 018.59-.75a10.357 10.357 0 016.546 2.217A7.111 7.111 0 0117.93 7a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(-4606.59 -11403)"
        ></path>
      </g>
    </svg>
  );
}

export default UserDashIcon;