import React from "react";

function LowPriorityIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29 29"
        >
            <g
                fill="#38cd00"
                data-name="Group 178499"
                transform="translate(-9277 -3709)"
            >
                <circle
                    cx="14.5"
                    cy="14.5"
                    r="14.5"
                    data-name="Ellipse 42"
                    opacity="0.13"
                    transform="translate(9277 3709)"
                ></circle>
                <path
                    d="M4.613 14.541V3.121L1.561 6.172A.914.914 0 01.268 4.88L4.876.272Q4.9.246 4.929.223L4.944.21 4.962.2l.016-.017.017-.012.016-.012.017-.011.008-.005.009-.006.01-.006h.006l.014-.012A.908.908 0 015.481 0h.091A.911.911 0 016.2.291l4.584 4.589a.914.914 0 11-1.292 1.292L6.44 3.121v11.42a.914.914 0 01-1.828 0z"
                    data-name="Union 7"
                    transform="rotate(-180 4648.575 1865.613)"
                ></path>
            </g>
        </svg>
    );
}

export default LowPriorityIcon;
