import { isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import DetailsCard from "./Card";
import style from './style.module.scss';

const YES = 190, NO = 191;

const YesNoBtn = (props) => {
    const { feild, handleChange, inCard, inTable } = props;
    const { t } = useTranslation();

    const value = useSelector(state => state.estTrnxReducer.data[feild.internalName]);

    return (
        <DetailsCard feild={feild} inCard={inCard} inTable={inTable}>
            <div className={style.evaluationBtn}>
                <button className={!isEmpty(value) && Number(value) == YES ? style.evaluationBtnActive : ""}
                    onClick={() => !isEmpty(value) && Number(value) == YES ? {} : handleChange(feild.internalName, YES)}
                    disabled={!feild.enabled} >{t('yes')}</button>
                <button className={!isEmpty(value) && Number(value) == NO ? style.evaluationBtnActive : ""}
                    onClick={() => !isEmpty(value) && Number(value) == NO ? {} : handleChange(feild.internalName, NO)}
                    disabled={!feild.enabled} >{t('no')}</button>
            </div>
        </DetailsCard>
    )
}

export default YesNoBtn;