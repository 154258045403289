import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import { RSM3274_CONTROL_WITH_EXCEL_FIELDS } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import ControlWithExcelFieldsDialog from "./dialogs/index";
import ControlWithExcelFieldsTable from "./listing/index";

const ControlWithExcelFields = () => {
    const { t } = useTranslation("controlWithExcelFields");

    const user = getUser();
    const searchTextRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3274_CONTROL_WITH_EXCEL_FIELDS} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('internal_name') + ' , ' + t('english_name') + ' , ' + t('arabic_name')}
                        search={() => tableRef.current.refresh()}
                        formId={RSM3274_CONTROL_WITH_EXCEL_FIELDS}
                    />
                </div>
            </div>

            <ControlWithExcelFieldsTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                dialogRef={dialogRef}
            />

            <ControlWithExcelFieldsDialog
                ref={dialogRef}
                user={user}
                tableRef={tableRef}
            />

        </>
    );
}

export default ControlWithExcelFields;