import React from "react";

function WaitingStatusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.272"
      height="14.496"
      viewBox="0 0 15.272 14.496"
    >
      <g data-name="Group 179873" transform="translate(-1020.049 -319.828)">
        <path
          fill="#d1af00"
          d="M7.638 14.5a.785.785 0 01-.086 0H3.333a3.254 3.254 0 01-2.979-1.427 2.666 2.666 0 01-.343-1.592L0 11.462l.013-.023a4.333 4.333 0 01.553-1.672l2.216-4.008 2.089-3.768A3.293 3.293 0 017.638 0 3.292 3.292 0 0110.4 1.991l2.086 3.771 1.025 1.854L14.7 9.772a3.283 3.283 0 01.213 3.308 3.252 3.252 0 01-2.972 1.42zm6.378-1.986a1.44 1.44 0 00.153-.408z"
          data-name="Union 356"
          transform="translate(1020.049 319.828)"
        ></path>
        <g
          fill="#fff"
          data-name="Group 178425"
          transform="translate(1026.824 324.045)"
        >
          <path
            d="M-.108 4.818a.642.642 0 01-.642-.642V-.108A.642.642 0 01-.108-.75a.642.642 0 01.642.642v4.284a.642.642 0 01-.642.642z"
            transform="translate(.969 .75)"
          ></path>
          <path
            d="M-.136.713h-.007A.857.857 0 01-1-.143.857.857 0 01-.143-1h.008a.857.857 0 01.857.857.857.857 0 01-.858.856z"
            data-name="Vector"
            transform="translate(1 7.639)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default WaitingStatusIcon;