import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { useTranslation } from "react-i18next";
import HijriDate from "../../../components/hijri-date";

const COMMON_ID = "price-agreements";

const Validity = (props) => {
    const { data, handleDataChange } = props;

    const { t } = useTranslation('priceAgreement');

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('validity')}
                    </label>
                </div>
            </div>

            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label id={`${COMMON_ID}-home-validity-from-dgr-lbl`} className='form-label mandatory'>{t('validity_from_g')}</label>
                        <FrmTimeDatePickr
                            id={`${COMMON_ID}-home-validity-from-dgr`}
                            value={data.validityFromDgr}
                            onChange={(value) => handleDataChange("validityFromDgr", value)}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${COMMON_ID}-home-validity-to-dgr-lbl`} className='form-label mandatory'>{t('to_g')}</label>
                        <FrmTimeDatePickr
                            id={`${COMMON_ID}-home-validity-to-dgr`}
                            value={data.validityToDgr}
                            onChange={(value) => handleDataChange("validityToDgr", value)}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label mandatory'>{t('from_h')}</label>
                        <HijriDate
                            id={`${COMMON_ID}-home-validity-from-dhi`}
                            hijriDate={data.validityFromDhi}
                            gregorianDate={data.validityFromDgr}
                            onChange={(value) => handleDataChange("validityFromDhi", value)}
                            disabled
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label mandatory'>{t('to_h')}</label>
                        <HijriDate
                            id={`${COMMON_ID}-home-validity-to-dhi`}
                            hijriDate={data.validityToDhi}
                            gregorianDate={data.validityToDgr}
                            onChange={(value) => handleDataChange("validityToDhi", value)}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Validity;