import React from "react";

function InboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.52"
      height="21.5"
      viewBox="0 0 21.52 21.5"
    >
      <g
        fill="#088587"
        data-name="Group 178019"
        transform="translate(-252.23 -454.25)"
      >
        <g data-name="Group 601" opacity="0.4" transform="translate(-305 267)">
          <path
            d="M0 7.75a.749.749 0 01-.287-.057A.75.75 0 01-.75 7V0A.75.75 0 010-.75.75.75 0 01.75 0v5.189l.72-.72A.75.75 0 012.53 5.53l-2 2a.75.75 0 01-.53.22z"
            transform="translate(568 188)"
          ></path>
          <path
            d="M2 2.75a.748.748 0 01-.53-.22l-2-2a.75.75 0 010-1.06.75.75 0 011.06 0l2 2A.75.75 0 012 2.75z"
            data-name="Vector"
            transform="translate(566 193)"
          ></path>
        </g>
        <path
          d="M11.79 4.75H8.26A2.738 2.738 0 015.8 3.225L4.629.885A.238.238 0 004.41.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4.41a1.736 1.736 0 011.561.965l1.17 2.34a1.246 1.246 0 001.119.7h3.53a1.246 1.246 0 001.119-.7l1.17-2.34A1.758 1.758 0 0115.64-.75H20a.75.75 0 01.75.75.75.75 0 01-.75.75h-4.36a.25.25 0 00-.219.135l-1.17 2.34A2.738 2.738 0 0111.79 4.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(252.98 466)"
        ></path>
        <path
          d="M13 18.62H7c-2.77 0-4.647-.586-5.905-1.845S-.75 13.64-.75 10.87v-4A8.871 8.871 0 01.564 1.59 6.092 6.092 0 014.891-.742a.75.75 0 01.851.633.75.75 0 01-.633.851C1.972 1.2.75 2.921.75 6.87v4c0 2.346.447 3.886 1.405 4.845S4.654 17.12 7 17.12h6c2.346 0 3.886-.447 4.845-1.405s1.405-2.5 1.405-4.845v-4c0-3.949-1.222-5.667-4.359-6.128a.75.75 0 01-.633-.851.75.75 0 01.851-.633 6.092 6.092 0 014.327 2.332 8.871 8.871 0 011.314 5.28v4c0 2.77-.586 4.647-1.845 5.905S15.77 18.62 13 18.62z"
          data-name="Vector"
          transform="translate(253 457.13)"
        ></path>
      </g>
    </svg>
  );
}

export default InboxIcon;