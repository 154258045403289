import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { useTranslation } from "react-i18next";
import LocationSelectMenu from "../../../components/location-select-menu";
import Table from "../../../components/table";
import Waiting from "../../../components/waiting";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import { getCommonDivisionsList, getFinancialLocationsList, getRealEstateUserLOV } from "../../../util/apis";
import { STATUS_ACTIVE } from "../../../util/constants";
import { RSM2669_BRANCHES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { confirmationDialog, isEnglish } from "../../../util/util";
import style from './style.module.scss';

const formId = "branches-definition";
const tabId = formId + '-tab-2-';
const DIVISION_CONDITION = 'activeDivisions';

const Tab2 = (props) => {
    const { tab, data, setData, divisionRef, user } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.divisions = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className={`w100 tableHeaderActions`}>
                        <button
                            title={t('insert')}
                            onClick={() => divisionRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('delete_all')}
                            onClick={_deleteAllAction}
                            disabled={(data?.divisions || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>{t('delete_all')}</label>
                        </button>
                    </div>
                </div>
            </div>

            <DivisionsTable data={data} setData={setData} user={user} />
        </div>
    )
}


const DivisionsTable = (props) => {
    const { data, setData, user } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "3%" },
        { title: <span data-property="dsvId" className="mandatory">{t('branchesDefinition:division')}</span>, width: "13%" },
        { title: <span data-property="floId" className="mandatory">{t('branchesDefinition:location')}</span>, width: "16%" },
        { title: <span data-property="fogId" className="mandatory">{t('branchesDefinition:organization')}</span>, width: "20%" },
        { title: <span data-property="descLo" className="mandatory">{t('branchesDefinition:english_description')}</span>, width: "12%" },
        { title: <span data-property="descFo" className="mandatory">{t('branchesDefinition:arabic_description')}</span>, width: "12%" },
        { title: t('branchesDefinition:user_list'), width: "15%" },
        { title: "", width: "9%" },
    ];

    const _handleDeleteLine = (index) => {
        if (!data.divisions) {
            data.divisions = [];
        }

        data.divisions.splice(index, 1);
        setData({ ...data });
    }

    const _handleLineChange = (index, name, value) => {
        data.divisions[index][name] = value;
        setData({ ...data });
    }

    return (
        <div className={style.divisionsTableContainer}>
            <Table
                id={tabId + "divisions-table"}
                columns={columns}
                Row={DivisionsRow}
                tableStyle={style.divisionsTable}
                rowHeight={40}
                list={data.divisions || []}
                rowActions={{
                    handleLineChange: _handleLineChange,
                    handleDeleteLine: _handleDeleteLine,
                    user: user,
                }}
                user={user}
                formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                Waiting={Waiting}
            />
        </div>
    );
}


const DivisionsRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleLineChange, handleDeleteLine, user } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div title={data?.sequence}><div> {data?.sequence}</div></div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${tabId}divisions-table-${index}-division`}
                        key={`${tabId}divisions-table-${index}-division-${data?.id}`}
                        value={data.dsvId}
                        api={(searchValue) => getCommonDivisionsList(searchValue, DIVISION_CONDITION, false, null, null, null, STATUS_ACTIVE, [data.dsvId])}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        onChange={value => handleLineChange(index, "dsvId", value.value)}
                        isDisabled
                        portalTarget={document.body}
                        menuWidth="250px"
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <LocationSelectMenu
                        id={`${tabId}divisions-table-${index}-location`}
                        key={`${tabId}divisions-table-${index}-location-${data?.id}`}
                        value={data.floId}
                        api={() => getFinancialLocationsList(null, STATUS_ACTIVE, [data.floId])}
                        descLo={['code', 'nameLo']}
                        descFo={['code', 'nameFo']}
                        onChange={value => handleLineChange(index, "floId", value.value)}
                        portalTarget={document.body}
                        menuWidth="250px"
                    />
                </div>
            </div>

            <div>
                <div title={`${user?.orgCode} - ${isEnglish() ? user?.orgNameLo : user?.orgNameFo}`}>
                    {`${user?.orgCode} - ${isEnglish() ? user?.orgNameLo : user?.orgNameFo}`}
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${tabId}divisions-table-${index}-desc-lo`}
                        value={data?.descLo}
                        onBlur={value => handleLineChange(index, "descLo", value)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${tabId}divisions-table-${index}-desc-fo`}
                        value={data?.descFo}
                        onBlur={value => handleLineChange(index, "descFo", value)}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${tabId}divisions-table-${index}-users`}
                        key={`${tabId}divisions-table-${index}-users-${data?.id}`}
                        value={data.userIds}
                        isMulti={true}
                        isAsync={true}
                        defualtValueId={data.userIds}
                        defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, data.userIds, true)}
                        api={(searchValue) => getRealEstateUserLOV(searchValue, STATUS_ACTIVE, false, false, null, null, null, null, null, true)}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        onChange={value => handleLineChange(index, "userIds", value)}
                        portalTarget={document.body}
                        menuWidth="250px"
                    />
                </div>
            </div>
            <div>
                <div className="containerButtonTable deleteBtnSm">
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};


export default Tab2;