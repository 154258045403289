import React from "react";

function UserProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#fff"
        data-name="Group 178466"
        transform="translate(-236.25 -21.25)"
      >
        <path
          d="M3.4 7.3a.749.749 0 01-.1-.007.348.348 0 00-.044 0 .751.751 0 01-.119.005 4.03 4.03 0 11.291 0zM3.28.75a2.526 2.526 0 00-.142 5.05 1.754 1.754 0 01.286 0A2.526 2.526 0 003.28.75z"
          transform="translate(243.72 26.23)"
        ></path>
        <path
          d="M6.74 7.315A10.7 10.7 0 01-.507 4.5a.75.75 0 01-.239-.632A4.422 4.422 0 011.351.743 9.834 9.834 0 016.747-.75a9.756 9.756 0 015.38 1.492 4.424 4.424 0 012.1 3.124.75.75 0 01-.239.632A10.7 10.7 0 016.74 7.315zM.818 3.665a9.207 9.207 0 005.922 2.15 9.206 9.206 0 005.922-2.15 3.232 3.232 0 00-1.37-1.678A8.249 8.249 0 006.747.75a8.325 8.325 0 00-4.562 1.24A3.226 3.226 0 00.818 3.665z"
          data-name="Vector"
          transform="translate(240.26 35.435)"
        ></path>
        <path
          d="M10-.75a10.75 10.75 0 017.6 18.35A10.75 10.75 0 012.4 2.4 10.68 10.68 0 0110-.75zm0 20A9.25 9.25 0 10.75 10 9.26 9.26 0 0010 19.25z"
          data-name="Vector"
          opacity="0.5"
          transform="translate(237 22)"
        ></path>
      </g>
    </svg>
  );
}

export default UserProfileIcon;