import FrmInput from "@ipgd-gauge/frm-input";
import { checkValidation, handleError, hideWaiting, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../components/modal";
import { changeFndPassword, changePassword } from "../../util/apis";
import style from './style.module.scss';

const ForgetPasswordDialog = (props, ref) => {
    const { withCurrentPassword } = props;

    const dialogRef = useRef();
    const { t } = useTranslation();

    const [data, setData] = useState({})

    const _handleChange = (name, value) => {
        data[name] = value;
        setData({ ...data })
    }

    const validatePassword = () => {
        return (data.newPassword && data.confirmPass && data.confirmPass == data.newPassword &&
            (/[a-zA-Z]/g.test(data.newPassword) && /[0-9]/g.test(data.newPassword)) &&
            data.newPassword.length > 6)
    }

    const _thenAction = (response) => {
        if (response.data) {
            showSuccessMessage("password_updated", t);
            dialogRef.current.close()
        }
    }

    const _catchAction = (error) => {
        let message = error?.response?.data?.message;
        if (message.includes("#") && message.split("#").length > 1 && message.split("#")[0] == "USER_ERR_7") {
            message = 'invalid_current_password'
        } else {
            message = null;
        }
        handleError(null, message, t)
    }

    const _handleUpdatePassword = () => {
        if (!checkValidation([{ id: "forget-password-dialog" }], t)) {
            return
        }
        if (validatePassword()) {
            showWaiting("forget-password-dialog-okButton", true)
            let tempData = Object.assign({}, data);
            tempData.checkCurrentPassword = withCurrentPassword ? true : false

            if (withCurrentPassword) {
                changePassword(tempData)
                    .then((response) => _thenAction(response))
                    .catch(error => _catchAction(error))
                    .finally(() => hideWaiting("forget-password-dialog-okButton"))
            } else {
                changeFndPassword(tempData)
                    .then((response) => _thenAction(response))
                    .catch(error => _catchAction(error))
                    .finally(() => hideWaiting("forget-password-dialog-okButton"))
            }
        } else {
            handleError(null, 'password_err', t)
        }
    }

    const open = (userId) => {
        _handleChange("userId", userId);
        dialogRef.current.open();
    }

    useImperativeHandle(ref, () => ({ open }))

    return (
        <Modal
            id="forget-password-dialog"
            ref={dialogRef}
            title={<>{t('change_password')}</>}
            className={style.modalForgetPassword}
            withFooter
            withOkButton
            okBtnAction={_handleUpdatePassword}
            okBtnStyleAction="confirm-button"
            withCancelButton
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={() => setData({})}
            smallSize
        >
            <div className={`container  ${style.summaryContainer} `}>
                <div className='row  rowSmModel'>
                    {withCurrentPassword &&
                        <div className="w100">
                            <label id="current_password-lbl" className='form-label mandatory'>{t('current_password')}</label>
                            <FrmInput
                                id="current_password"
                                type="password"
                                value={data.currentPassword}
                                onBlur={(value) => _handleChange("currentPassword", value)}
                            />
                        </div>
                    }
                    <div className="w100">
                        <label id="new_password-lbl" className='form-label mandatory'>{t('new_password')}</label>
                        <FrmInput
                            id="new_password"
                            type="password"
                            value={data.newPassword}
                            onBlur={(value) => _handleChange("newPassword", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id="confirm_password-lbl" className='form-label mandatory'>{t('confirm_password')}</label>
                        <FrmInput
                            id="confirm_password"
                            type="password"
                            value={data.confirmPass}
                            onBlur={(value) => _handleChange("confirmPass", value)}
                        />
                    </div>
                    <div className="w100">

                        <div className={style.passwordReco}>
                            {/* <div className={data.newPassword ? (data.newPassword.includes(data.username) ? style.error : style.success) : style.passwordRecoLabel}>
                                <div></div>
                                <label>{t('not_match_your_login_name')}</label>
                            </div> */}
                            <div className={data.newPassword ? (data.newPassword.length < 6 ? style.error : style.success) : style.passwordRecoLabel} >
                                <div></div>
                                <label>{t('at_least_6_characters_long')} </label>
                            </div>
                            <div className={data.newPassword ? ((/[a-zA-Z]/g.test(data.newPassword) && /[0-9]/g.test(data.newPassword)) ?
                                style.success : style.error) : style.passwordRecoLabel}>
                                <div></div>
                                <label>{t('contain_characters_and_numbers')}</label>
                            </div>
                            <div className={data.confirmPass ? (data.confirmPass == data.newPassword ? style.success : style.error) : style.passwordRecoLabel}>
                                <div></div>
                                <label>{t('new_password_equal_confirmed_one')}</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(ForgetPasswordDialog);