import React from "react";

function ReportsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.401"
      height="22.401"
      viewBox="0 0 22.401 22.401"
    >
      <path
        fill="#fff"
        d="M5.973-.75h11.481A2.779 2.779 0 0120.03.964a2.757 2.757 0 01.139 1.763v.006l-3.146 12.626a2.776 2.776 0 01-2.715 2.13H2.044A2.762 2.762 0 01-.2 16.351a2.79 2.79 0 01-.423-2.505l3.93-12.62A2.817 2.817 0 015.973-.75zM18.809 2.4a1.368 1.368 0 00-.063-.876l-.007-.016A1.383 1.383 0 0017.454.65H5.973a1.406 1.406 0 00-1.329.99L.715 14.262a1.4 1.4 0 00.213 1.259 1.375 1.375 0 001.116.568h12.264a1.381 1.381 0 001.354-1.058v-.007z"
        transform="translate(.981 3.746)"
      ></path>
      <path
        fill="#fff"
        d="M4.412 15.584H-.05a.7.7 0 01-.7-.7.7.7 0 01.7-.7h4.462a1.352 1.352 0 001-.433 1.369 1.369 0 00.366-1.04L4.852 0A.7.7 0 015.5-.748a.7.7 0 01.749.648l.924 12.713a2.793 2.793 0 01-.738 2.094 2.738 2.738 0 01-2.023.877z"
        data-name="Vector"
        opacity="0.85"
        transform="translate(14.984 5.65)"
      ></path>
      <path
        fill="#fff"
        d="M-.049 4.682a.7.7 0 01-.164-.02.7.7 0 01-.517-.844L.24-.214a.7.7 0 01.844-.517.7.7 0 01.516.845L.631 4.146a.7.7 0 01-.68.536z"
        data-name="Vector"
        transform="translate(9.085 1.973)"
      ></path>
      <path
        fill="#fff"
        d="M-.049 4.7A.7.7 0 01-.2 4.685a.7.7 0 01-.536-.832L.143-.2a.7.7 0 01.833-.534.7.7 0 01.536.834L.634 4.149a.7.7 0 01-.683.551z"
        data-name="Vector"
        transform="translate(15.339 1.963)"
      ></path>
      <path
        fill="#fff"
        d="M7.417.65H-.05a.7.7 0 01-.7-.7.7.7 0 01.7-.7h7.467a.7.7 0 01.7.7.7.7 0 01-.7.7z"
        data-name="Vector"
        opacity="0.4"
        transform="translate(7.237 11.251)"
      ></path>
      <path
        fill="#fff"
        d="M7.417.65H-.05a.7.7 0 01-.7-.7.7.7 0 01.7-.7h7.467a.7.7 0 01.7.7.7.7 0 01-.7.7z"
        data-name="Vector"
        opacity="0.4"
        transform="translate(6.304 14.984)"
      ></path>
    </svg>
  );
}

export default ReportsIcon;