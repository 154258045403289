import React from "react";

function EstimationReportsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.49"
      height="20.5"
      viewBox="0 0 21.49 20.5"
    >
      <g
        fill="#088587"
        data-name="Group 177926"
        transform="translate(-252.234 -768.752)"
      >
        <path
          d="M6.141-.75A11.159 11.159 0 018.7-.411l1.668.39c2.35.55 3.827 1.423 4.65 2.75s.949 3.035.4 5.382l-.98 4.18a8.112 8.112 0 01-1.931 4 4.908 4.908 0 01-3.66 1.384c-.263 0-.541-.014-.826-.041a11.322 11.322 0 01-1.73-.288l-1.678-.4C2.277 16.392.8 15.518-.017 14.2s-.951-3.035-.4-5.378l.98-4.19a11.135 11.135 0 01.8-2.357A4.917 4.917 0 016.141-.75zm2.71 16.927c2.235 0 3.431-1.225 4.13-4.228l.98-4.181c.453-1.933.385-3.283-.213-4.248s-1.781-1.627-3.717-2.08l-1.67-.39a9.709 9.709 0 00-2.22-.3 3.417 3.417 0 00-3.434 2.175 9.68 9.68 0 00-.686 2.045l-.98 4.189c-.885 3.775.141 5.432 3.923 6.329l1.68.4a9.825 9.825 0 001.506.252h.012c.238.025.472.037.689.037z"
          transform="translate(257.969 769.502)"
        ></path>
        <path
          d="M6.371 17.152c-3.347 0-4.622-2.734-5.291-4.791L-.2 8.411c-.721-2.235-.736-3.9-.049-5.255S1.8.836 4.034.116L5.61-.4h.008a10.71 10.71 0 011.257-.337.75.75 0 01.736.283.75.75 0 01.076.785A9.68 9.68 0 007 2.372l-.98 4.189c-.885 3.775.141 5.432 3.923 6.329l1.68.4a9.825 9.825 0 001.506.252.75.75 0 01.361 1.369 10.076 10.076 0 01-2.538 1.172l-1.574.517a9.872 9.872 0 01-3.007.552zM5.785 1.119L4.5 1.542c-1.831.59-2.914 1.319-3.41 2.294s-.453 2.282.138 4.114l1.279 3.95c.866 2.666 1.986 3.753 3.864 3.753a8.42 8.42 0 002.542-.476l1.576-.519.169-.056L9.6 14.35c-2.341-.556-3.813-1.43-4.635-2.753s-.951-3.035-.4-5.378l.98-4.19q.113-.486.24-.91z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(252.986 772.1)"
        ></path>
        <path
          d="M4.851 1.98a.751.751 0 01-.185-.023L-.184.727a.75.75 0 01-.543-.911.75.75 0 01.911-.543L5.034.5a.75.75 0 01-.183 1.48z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(263.64 775.53)"
        ></path>
        <path
          d="M2.9 1.49a.751.751 0 01-.186-.023l-2.9-.74a.75.75 0 01-.541-.912.75.75 0 01.912-.542l2.9.74A.75.75 0 012.9 1.49z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(262.66 779.4)"
        ></path>
      </g>
    </svg>
  );
}

export default EstimationReportsIcon;