import React from "react";

function ServiceExpiryWarningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="164.061"
      height="151.514"
      data-name="Group 180170"
      viewBox="0 0 164.061 151.514"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H164.061V151.514H0z"
            data-name="Rectangle 164124"
          ></path>
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            fill="none"
            d="M0 0H164.06V151.513H0z"
            data-name="Rectangle 164123"
          ></path>
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            fill="none"
            d="M0 0H114.02V93.506H0z"
            data-name="Rectangle 164121"
          ></path>
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            fill="none"
            d="M0 0H71.628V52.968H0z"
            data-name="Rectangle 164122"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Group 179889">
        <g data-name="Group 179888">
          <g clipPath="url(#clip-path-2)" data-name="Group 179887">
            <path
              fill="#fff"
              d="M19.347 151.516a19.319 19.319 0 01-16.889-28.7L65.141 9.943a19.319 19.319 0 0133.779 0l62.68 112.874a19.32 19.32 0 01-16.89 28.7z"
              data-name="Path 44829"
              transform="translate(0 -.003)"
            ></path>
            <path
              fill="#ffd008"
              d="M31.376 150.166L94.06 37.292a15.355 15.355 0 0126.848 0l62.684 112.874a15.355 15.355 0 01-13.424 22.81H44.8a15.355 15.355 0 01-13.424-22.81"
              data-name="Path 44830"
              transform="translate(-25.454 -25.427)"
            ></path>
            <g
              data-name="Group 179883"
              opacity="0.21"
              transform="translate(25.372 54.043)"
              style={{ mixBlendMode: "multiply", isolation: "isolate" }}
            >
              <g data-name="Group 179882">
                <g clipPath="url(#clip-path-3)" data-name="Group 179881">
                  <path
                    fill="#ffaf10"
                    d="M250.634 489.071q25.785-25.7 51.465-51.508l-20.514-36.94L238.4 443.8l-47.11 47.117-3.211 3.211h57.46c1.7-1.689 3.4-3.369 5.1-5.058"
                    data-name="Path 44831"
                    transform="translate(-188.079 -400.623)"
                  ></path>
                </g>
              </g>
            </g>
            <g
              data-name="Group 179886"
              opacity="0.43"
              transform="translate(88.462 94.581)"
              style={{ mixBlendMode: "multiply", isolation: "isolate" }}
            >
              <g data-name="Group 179885">
                <g clipPath="url(#clip-path-4)" data-name="Group 179884">
                  <path
                    fill="#ffaf10"
                    d="M708.7 701.129q-11.3 11.342-22.607 22.683-15.142 15.161-30.321 30.285h56.252a15.355 15.355 0 0013.424-22.81z"
                    data-name="Path 44832"
                    transform="translate(-655.769 -701.129)"
                  ></path>
                </g>
              </g>
            </g>
            <path
              d="M507.783 458.017l-4.027-44.558v-.84a3.867 3.867 0 013.867-3.867h20.415a3.867 3.867 0 013.867 3.867v.84l-4.115 44.565a3.692 3.692 0 01-3.676 3.353H511.46a3.692 3.692 0 01-3.677-3.36m-.638 21.009v-9.5a2.968 2.968 0 012.968-2.968h15.34a2.968 2.968 0 012.968 2.968v9.5a2.968 2.968 0 01-2.968 2.968h-15.34a2.968 2.968 0 01-2.968-2.968"
              data-name="Path 44833"
              transform="translate(-435.8 -353.613)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ServiceExpiryWarningIcon;
