import { DeleteBtn, EditBtn } from '@ipgd-gauge/table';
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from '@ipgd-gauge/utils';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router';
import Slisting from "../../../components/slisting";
import { deletePriceAgreement, getPriceAgreementList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from '../../../util/constants/actions';
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { t } = useTranslation();
    const { deleteAction, user, navigate } = rowActions;

    return (
        <div className={`${style.priceAgreementRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('priceAgreement:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data?.partyTypeDescLo : data?.partyTypeDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('priceAgreement:party_level')}</label>
                <label>{isEnglish() ? data?.partyTypeDescLo : data?.partyTypeDescFo}</label>
            </div>

            <div title={isEnglish() ? data?.partyDescLo : data?.partyDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('priceAgreement:party_name')}</label>
                <label>{isEnglish() ? data?.partyDescLo : data?.partyDescFo}</label>
            </div>

            <div title={data?.quotationCode || t('not_found')} className="cardRowMode">
                <label className="nameLblMobile">{t('priceAgreement:quotation_num')}</label>
                <label>{data?.quotationCode || t('not_found')}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('priceAgreement:status')}</label>
                <label className={`status-${data?.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3007_PRICE_AGREEMENT_FORM_ID, VIEW_EDIT_ACTION) && <EditBtn onClick={() => navigate(`/price-agreement?id=${data.id}`)} />}
                    {isAllowedAction(user, RSM3007_PRICE_AGREEMENT_FORM_ID, DELETE_ACTION) && <DeleteBtn onClick={() => deleteAction(data.id)} />}
                </div>
            </div>
        </div>
    );
};

const PriceAgreementTable = (props) => {
    const { tableRef, searchTextRef, filterRef } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const user = getUser();

    const [data, setData] = useState();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('priceAgreement:code'), width: '10%' },
        { title: t('priceAgreement:party_level'), width: '24%' },
        { title: t('priceAgreement:party_name'), width: '24%' },
        { title: t('priceAgreement:quotation_num'), width: '8%' },
        { title: t('status'), width: '15%' },
        { title: '', width: '14%' },
    ];

    const _fetchData = (page, size) => {
        getPriceAgreementList(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t));
    };

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (isNotEmpty(id)) {
                return deletePriceAgreement(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, "delete_fields", t));
            }
        });
    }

    return (
        <Slisting
            id="price-Agreement-Table"
            ref={tableRef}
            formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.priceAgreementTable}
            getData={_fetchData}
            pagingData={data}
            rowActions={{ deleteAction: _deleteAction, user, navigate }}
            withFilter
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            deleteAction={deletePriceAgreement}
            newAction={() => navigate("/price-agreement")}
            viewAction={(id) => navigate(`/price-agreement?id=${id}`)}
            cardInMobile
            labelCardWidth="80px"
        />
    );
};

export default PriceAgreementTable;