import FrmInput from "@ipgd-gauge/frm-input";
import Spinner from "@ipgd-gauge/spinner";
import { formatMoney, handleError, hideWaiting, isEnglish, parseNumber, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Tree, { renderers as Renderers, selectors } from "react-virtualized-tree";
import { FrmDecimalInput } from "../../../../components/decimal-input";
import LoadingSpinner from "../../../../components/loading-spinner";
import DropdownTree from "../../../../icons/DropdownTree";
import MinusIcon from "../../../../icons/Minus";
import PlusIcon from "../../../../icons/PlusIcon";
import { addSelfConstruction, deleteSelfConstruction, getSelfConstruction } from "../../../../util/apis";
import InputNumber from "./InputNumber";
import InputText from "./InputText";
import SelectMenu from "./SelectMenu";
import YesNoBtn from "./YesNoBtn";
import style from './style.module.scss';

const DESCRIPTION_COLUMN = 'DESCRIPTION';
const COST_PERC_COLUMN = 'COSTPERCVALUE';
const COST_COLUMN = 'COSTVALUE';
const PROGRESS_PERC_COLUMN = 'PROGRESSPERCENTAGE';
const TOTAL_PROGRESS_PERC_COLUMN = 'TOTALPROGRESSPERCENTAGE';
const PROGRESS_VALUE_COLUMN = 'PROGRESSVALUE';
const DURATION_COLUMN = 'DURATION';
const REMARKS_COLUMN = 'REMARKS';

const InputSell = (props) => {
    const { inputKey, columnName, handleChange, disabled, numbers, allowDecimals, decimalScale } = props;
    const value = useSelector(state => state.estTrnxReducer.data[`SELF_CONSTRUCTION_${inputKey}_${columnName}`]);
    const [data, setData] = useState(value);

    useEffect(() => {
        setData(value);
    }, [value])

    const _handleOnBlur = (name, inputValue) => {
        if (value != inputValue) {
            handleChange(name, inputValue)
        }
    }

    if (numbers) {
        return (
            <FrmDecimalInput
                id={`${columnName}_${inputKey}`}
                value={data}
                // onChange={(e) => setData(e.target.value)}
                onBlur={(value) => _handleOnBlur(`SELF_CONSTRUCTION_${inputKey}_${columnName}`, value)}
                nullable={true}
                allowDecimals={allowDecimals}
                decimalScale={decimalScale}
                disabled={disabled}
            />
        )
    } else {
        return (
            <FrmInput
                id={`${columnName}_${inputKey}`}
                value={data}
                // onChange={(e) => setData(e.target.value)}
                onBlur={(value) => _handleOnBlur(`SELF_CONSTRUCTION_${inputKey}_${columnName}`, value)}
                onClick={(e) => e.stopPropagation()}
                disabled={disabled}
            />
        )
    }
}

const TreeRenderer = (props) => {
    const { node, onChange, getData, selectedNode, index,
        handleSelectNode, marginLeft, handleChange, getTrxKey,
        newNodeKeyRef } = props;


    const { getNodeRenderOptions } = selectors;
    const { isExpanded, hasChildren } = getNodeRenderOptions(node);
    const { description, costValue, progressPercentage, duration, remarks, key } = node;


    const _updateNode = (node) => {
        if (!selectedNode || selectedNode?.key != key) {
            handleSelectNode(node);
        }
    }

    const AddChild = () => {
        showWaiting(`add-sub-self-construction-btn-spinner-id-${index}`, true);
        document.getElementById(`add-sub-self-construction-btn-spinner-id-${index}`).style.display = "flex";
        addSelfConstruction({ key: getTrxKey(), parentId: key })
            .then(() => {
                newNodeKeyRef.current = (isExpanded || !node?.children || node?.children?.length == 0) ? key : '';
                getData();
            })
            .finally(() => {
                hideWaiting(`add-sub-self-construction-btn-spinner-id-${index}`)
                document.getElementById(`add-sub-self-construction-btn-spinner-id-${index}`).style.display = "none"
            })
    }

    useEffect(() => {
        if (newNodeKeyRef.current == key) {
            _expandNode(node);
            newNodeKeyRef.current = "";
        }
    }, [key])

    const _expandNode = (node) => {
        if (!selectedNode || selectedNode?.key != key) {
            handleSelectNode(node);
        }
        onChange(
            {
                node: {
                    ...node,
                    state: { ...getNodeRenderOptions(node), expanded: !isExpanded },
                },
                type: 2,
            }, index
        )
    }

    const _handleClick = (e) => {
        if (e.detail == 1) {
            _updateNode(node)
        } else if (e.detail == 2) {
            _expandNode(node)
        }
    }

    const selectParent = (node, parentId) => {
        if (node?.key == parentId) {
            handleSelectNode(node)
        }
        else {
            if (node?.children?.length > 0) {
                node?.children?.forEach(child => {
                    selectParent(child, parentId)
                })
            }
        }
    }

    const _deleteAction = () => {
        showWaiting(`remove-sub-self-construction-btn-spinner-id-${index}`, true);
        document.getElementById(`remove-sub-self-construction-btn-spinner-id-${index}`).style.display = "flex";

        deleteSelfConstruction({ key: getTrxKey(), selfConstructionKey: key }).then(() => {
            getData();
            handleChange(`SELF_CONSTRUCTION_${key}_${DESCRIPTION_COLUMN}`, description);
        })
            .finally(() => {
                hideWaiting(`remove-sub-self-construction-btn-spinner-id-${index}`)
                document.getElementById(`remove-sub-self-construction-btn-spinner-id-${index}`).style.display = "none"
            })
    }

    return (
        <div className={style.classificationsMainBody}>
            <div className={style.classification_block}
                style={{
                    paddingRight: isEnglish() ? 0 : marginLeft,
                    paddingLeft: isEnglish() ? marginLeft : 0
                }} >
                <div className={`${style.classification} ${!isExpanded ? style.rotate : ''}`}>
                    {hasChildren &&
                        <button className={style.classification_btn} onClick={() => { _expandNode(node) }}>
                            <DropdownTree id={key + 'arrow-class'} />
                        </button>
                    }
                </div>
                <div className={`${style.classification_main} ${(selectedNode && (selectedNode?.key == key || selectedNode?.parent == key)) ? style.selected : ''}`}>
                    <div className={style.classificationsInputBody} onClick={(e) => _handleClick(e)}>
                        <InputSell
                            inputKey={key}
                            columnName={DESCRIPTION_COLUMN}
                            handleChange={handleChange}
                            numbers={false}
                        />
                    </div>
                </div>
            </div>

            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={COST_PERC_COLUMN}
                        handleChange={handleChange}
                        numbers={true}
                        allowDecimals={true}
                        decimalScale={2}
                    />
                </div>
            </div>

            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={COST_COLUMN}
                        handleChange={handleChange}
                        numbers={true}
                        allowDecimals={true}
                    />
                </div>
            </div>
            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={PROGRESS_PERC_COLUMN}
                        handleChange={handleChange}
                        numbers={true}
                        allowDecimals={true}
                    />
                </div>
            </div>
            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={TOTAL_PROGRESS_PERC_COLUMN}
                        handleChange={handleChange}
                        numbers={true}
                        allowDecimals={true}
                        disabled={true}
                    />
                </div>
            </div>
            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={PROGRESS_VALUE_COLUMN}
                        handleChange={handleChange}
                        numbers={true}
                        disabled={true}
                        allowDecimals={true}
                    />
                </div>
            </div>
            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={DURATION_COLUMN}
                        handleChange={handleChange}
                    // numbers={true}
                    />
                </div>
            </div>
            <div>
                <div className={style.classificationsInputBody}>
                    <InputSell
                        inputKey={key}
                        columnName={REMARKS_COLUMN}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div>
                <div className={style.containerAddBtn}>
                    <button onClick={() => AddChild(node)}>
                        <PlusIcon />
                        <div className={style.selfConstructionSpinnerContainer} id={`add-sub-self-construction-btn-spinner-id-${index}`}>
                            <LoadingSpinner className={style.selfConstructionSpinner} />
                        </div>
                    </button>
                    <button
                        onClick={() => node?.children?.length > 0 ? {} : _deleteAction()}
                        className={node?.children?.length > 0 ? style.disabledDeleBtn : ""}>
                        <MinusIcon />
                        <div className={style.selfConstructionSpinnerContainer} id={`remove-sub-self-construction-btn-spinner-id-${index}`}>
                            <LoadingSpinner className={style.selfConstructionSpinner} />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

const TotalPanel = (props) => {

    const { nodes } = props

    const value = useSelector(state => state.estTrnxReducer.data/*  */);
    const [totals, setTotals] = useState({});

    useEffect(() => {
        recalculate();
    }, [value, nodes])

    const recalculate = () => {
        let costPercentage = 0;
        let costValue = 0;
        let overall = 0;
        let progressValues = 0;
        nodes.map((node) => {
            costPercentage = costPercentage + parseNumber(value[[`SELF_CONSTRUCTION_${node.key}_${COST_PERC_COLUMN}`]]);
            costValue = costValue + parseNumber(value[[`SELF_CONSTRUCTION_${node.key}_${COST_COLUMN}`]]);
            overall = overall + parseNumber(value[[`SELF_CONSTRUCTION_${node.key}_${TOTAL_PROGRESS_PERC_COLUMN}`]]);
            progressValues = progressValues + parseNumber(value[[`SELF_CONSTRUCTION_${node.key}_${PROGRESS_VALUE_COLUMN}`]]);
        })
        totals.costPercentage = parseFloat(costPercentage).toFixed(2);
        totals.costValue = parseFloat(costValue).toFixed(2);
        totals.overall = parseFloat(overall).toFixed(2);
        totals.progressValues = parseFloat(progressValues).toFixed(2);
        setTotals({ ...totals })
    }

    return (
        <div className={`table-total ${style.selfConstructionTableTotal}`} >
            <div></div>
            <div>{formatMoney(totals.costPercentage)}</div>
            <div>{formatMoney(totals.costValue)}</div>
            <div></div>
            <div>{formatMoney(totals.overall)}</div>
            <div>{formatMoney(totals.progressValues)}</div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
};

const SelfConstructionTree = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { selectedNode, setSelectedNode, currentLocation, handleChange, getTrxKey } = props;
    const { Expandable } = Renderers;

    const treeRef = useRef();
    const newNodeKeyRef = useRef();

    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        _getData();
    }, [])

    const reExpandNodes = (data, node) => {
        for (let index = 0; index < data.length; index++) {
            if (node[index]?.state) {
                if (node[index]?.state) {
                    let noteState = Object.assign({}, node[index].state);
                    data[index].state = noteState;
                }
                if (node[index]?.children?.length > 0) {
                    if (node[index]?.children?.length > 0) {
                        reExpandNodes(data[index].children, node[index].children)
                    }
                }
            }
        }
        return data;
    }

    const _handleAddRow = () => {
        showWaiting("add-self-construction-btn-spinner-id", true);
        addSelfConstruction({ key: getTrxKey() })
            .then((response) => {
                let newNode = { id: null, key: response.data };
                nodes.push(newNode)
                setNodes([...nodes])
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting("add-self-construction-btn-spinner-id"))
    }

    const expandNode = (node) => {
        node.state = {}
        node.state.expanded = true
        if (node.children) {
            node.children.forEach(child => {
                expandNode(child)
            })
        }
    }

    const _getNumOfChildren = (node) => {
        let numOfRows = 0;
        if (node?.state?.expanded) {
            numOfRows = node?.children?.length;
            node?.children.map(child => {
                numOfRows += _getNumOfChildren(child);
            })
        }
        return numOfRows;
    }

    const getNumOfRows = () => {
        let numOfRows = nodes.length;
        nodes.map(node => {
            numOfRows += _getNumOfChildren(node);
        })
        return numOfRows;
    }

    const _getData = () => {
        getSelfConstruction({ key: getTrxKey() })
            .then((response) => {
                let tempNodes = response.data;
                tempNodes.map((n) => {
                    n.id = n.key
                })
                if (tempNodes.length > 0) {
                    tempNodes = reExpandNodes(tempNodes, nodes);
                }
                setNodes(tempNodes)
            })
            .catch((error) => {
                handleError(error, null, t);
            })
    }

    const _handleSelectNode = (node) => {
        setSelectedNode(node)
    }

    const refresh = () => {
        _getData();
    }

    useImperativeHandle(ref, () => ({
        refresh
    }));

    return (
        <div className={style.classifications_labels}>
            <div className={style.classifications_labels_head}>
                <div className={`table_search_panel ${style.tableSearch}`}>
                    <button
                        className="secondary-button"
                        onClick={_handleAddRow}
                    >
                        <Spinner id="add-self-construction-btn-spinner-id" className={style.spinnerButton} />
                        {t('add')}
                    </button>
                </div>
            </div>
            <div className={style.containerClassificationsTable}>
                <div className={style.header}>
                    <div>
                        <label>{t('estimationTransaction:desc')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:cost_per')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:cost_value')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:Progress_per')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:overall_per')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:progress_value')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:duration_tbl')}</label>
                    </div>
                    <div>
                        <label>{t('estimationTransaction:remarks')}</label>
                    </div>
                    <div></div>
                </div>
                <div id="selfConstructionTable" className={style.classifications_labels_body} style={{ height: `${(getNumOfRows() * 40) + 1}px` }}>
                    <Tree
                        ref={treeRef}
                        onChange={(nodes) => setNodes(nodes)} nodes={nodes}
                    >
                        {({ style, node, ...rest }) => (
                            <div
                                style={{
                                    ...style,
                                    margin: 0,
                                    height: '40px',
                                }}
                            >
                                <Expandable node={{}} {...rest}>
                                    <TreeRenderer
                                        // height={height}
                                        nodes={nodes}
                                        setNodes={setNodes}
                                        getData={_getData}
                                        selectedNode={selectedNode}
                                        index={rest.index}
                                        onChange={rest.onChange}
                                        node={node}
                                        handleSelectNode={_handleSelectNode}
                                        currentLocation={currentLocation}
                                        marginLeft={style.marginLeft}
                                        handleChange={handleChange}
                                        getTrxKey={getTrxKey}
                                        treeRef={treeRef}
                                        newNodeKeyRef={newNodeKeyRef}
                                    />
                                </Expandable>
                            </div>
                        )}
                    </Tree>
                </div>
            </div>
            {nodes?.length > 0 && <TotalPanel nodes={nodes} />}
        </div>
    )
});

const SelfConstructionFeilds = (props) => {
    const { user, block, handleChange, getTrxKey, displayMode } = props;

    const [feilds, setFeilds] = useState([]);
    const selfConstructionFeilds = useSelector(state => state.estTrnxReducer.blocks[block.id]);

    useEffect(() => {
        if (selfConstructionFeilds) {
            setFeilds(selfConstructionFeilds);
        }
    }, [selfConstructionFeilds])

    const getFeild = (name) => {
        return feilds.find(({ internalName }) => internalName == name);
    }

    return (
        <div className={`form-templates-panel ${style.formTemplates}`}>
            <div className={`row ${style.row}`}>
                <div className="w100">
                    {(getFeild("LDI_STAGE")?.id ||
                        getFeild("LDI_VISIT_NUMBER")?.id ||
                        getFeild("LDI_SELF_CONST_PARENT_TRI_ID")?.id ||
                        getFeild("LDI_SELF_CONST_PROJECT_COST") ||
                        getFeild("LDI_SELF_CONST_PROG_VALUE") ||
                        getFeild("LDI_SELF_CONST_REMAIN") ||
                        getFeild("LDI_SELF_CONST_ALL_PROG_PERC") ||
                        getFeild("LDI_SELF_CONST_REMAIN_DUR") ||
                        getFeild("LDI_FIELD_261_BOOLEAN") ||
                        getFeild("LDI_FIELD_262_BOOLEAN") ||
                        getFeild("LDI_FIELD_261_LOV") ||
                        getFeild("LDI_FIELD_262_LOV") ||
                        getFeild("LDI_FIELD_261_TEXT") ||
                        getFeild("LDI_FIELD_262_TEXT")
                    ) &&

                        <div className={style.selfConstPanel}>
                            <div className={`row ${style.selfConstRow}`}>
                                {getFeild("LDI_STAGE")?.id &&
                                    <div className="w33">
                                        <SelectMenu
                                            handleChange={handleChange}
                                            getTrxKey={getTrxKey}
                                            feild={getFeild("LDI_STAGE")} />
                                    </div>}

                                {getFeild("LDI_VISIT_NUMBER")?.id &&
                                    <div className="w33">
                                        <SelectMenu
                                            handleChange={handleChange}
                                            getTrxKey={getTrxKey}
                                            feild={getFeild("LDI_VISIT_NUMBER")} />
                                    </div>}

                                {getFeild("LDI_SELF_CONST_PARENT_TRI_ID")?.id &&
                                    <div className="w33">
                                        <SelectMenu
                                            handleChange={handleChange}
                                            getTrxKey={getTrxKey}
                                            feild={getFeild("LDI_SELF_CONST_PARENT_TRI_ID")} />
                                    </div>}

                                {getFeild("LDI_SELF_CONST_PROJECT_COST")?.id &&
                                    <div className="w33">
                                        <InputNumber
                                            user={user}
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_SELF_CONST_PROJECT_COST")} />
                                    </div>}

                                {getFeild("LDI_SELF_CONST_PROG_VALUE")?.id &&
                                    <div className="w33">
                                        <InputText
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_SELF_CONST_PROG_VALUE")} />
                                    </div>}

                                {getFeild("LDI_SELF_CONST_REMAIN")?.id &&
                                    <div className="w33">
                                        <InputText
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_SELF_CONST_REMAIN")} />
                                    </div>}

                                {getFeild("LDI_SELF_CONST_ALL_PROG_PERC")?.id &&
                                    <div className="w33">
                                        <InputText
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_SELF_CONST_ALL_PROG_PERC")} />
                                    </div>}

                                {getFeild("LDI_SELF_CONST_REMAIN_DUR")?.id &&
                                    <div className="w33">
                                        <InputText
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_SELF_CONST_REMAIN_DUR")} />
                                    </div>}

                                {getFeild("LDI_FIELD_261_BOOLEAN")?.id &&
                                    <div className="w33">
                                        <YesNoBtn
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_FIELD_261_BOOLEAN")} />
                                    </div>}

                                {getFeild("LDI_FIELD_262_BOOLEAN")?.id &&
                                    <div className="w33">
                                        <YesNoBtn
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_FIELD_262_BOOLEAN")} />
                                    </div>}

                                {getFeild("LDI_FIELD_261_LOV")?.id &&
                                    <div className="w33">
                                        <SelectMenu
                                            handleChange={handleChange}
                                            getTrxKey={getTrxKey}
                                            feild={getFeild("LDI_FIELD_261_LOV")} />
                                    </div>}

                                {getFeild("LDI_FIELD_262_LOV")?.id &&
                                    <div className="w33">
                                        <SelectMenu
                                            handleChange={handleChange}
                                            getTrxKey={getTrxKey}
                                            feild={getFeild("LDI_FIELD_262_LOV")} />
                                    </div>}

                                {getFeild("LDI_FIELD_261_TEXT")?.id &&
                                    <div className="w33">
                                        <InputText
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_FIELD_261_TEXT")} />
                                    </div>}

                                {getFeild("LDI_FIELD_262_TEXT")?.id &&
                                    <div className="w33">
                                        <InputText
                                            handleChange={handleChange}
                                            feild={getFeild("LDI_FIELD_262_TEXT")} />
                                    </div>}

                                {getFeild("LDI_ENTRY_METHOD")?.id &&
                                    <div className="w33">
                                        <SelectMenu
                                            handleChange={handleChange}
                                            getTrxKey={getTrxKey}
                                            feild={getFeild("LDI_ENTRY_METHOD")} />
                                    </div>}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

const SelfConstructionBlock = (props) => {
    const { user, block, handleChange, getTrxKey, selfConstructionTreeRef, displayMode } = props;
    const [selectedNode, setSelectedNode] = useState();

    return (
        <div id={`block-content-${block.id}`} className={`block-content ${style.selfConstructionBlock}`}>
            <SelfConstructionFeilds user={user} block={block} handleChange={handleChange} getTrxKey={getTrxKey} displayMode={displayMode} />
            <SelfConstructionTree
                ref={selfConstructionTreeRef}
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
                getTrxKey={getTrxKey}
                handleChange={handleChange}
            />
        </div>
    );
}

export default SelfConstructionBlock;