import React from "react";

function MinusTrxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <g data-name="Group 179879" transform="translate(8943 6923)">
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          data-name="Ellipse 179"
          transform="translate(-8943 -6923)"
        ></circle>
        <path
          fill="#fff"
          d="M11 .5H0v-1h11z"
          data-name="Line 104"
          transform="translate(-8937 -6911.5)"
        ></path>
      </g>
    </svg>
  );
}

export default MinusTrxIcon;
