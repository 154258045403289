import ActionsButton from "@ipgd-gauge/actions-button";
import { addUrlParam, checkValidation, confirmationDialog, handleError, hideActionWaiting, isAllowedAction, isNotEmpty, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../../icons/AddBtn";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import IncompleteIcon from "../../../icons/Incomplete";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import SaveActionIcon from "../../../icons/SaveAction";
import { confirmEstimatorInvoice, deleteEstimatorInvoice, rejectEstimatorInvoice, saveEstimatorInvoice } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import { ESTIMATION_FEES, ITEM } from "./Details";
import { ACTION_SET_INVOICE } from "./reducer";

const Actions = (props) => {
    const { user, invoiceState, dispatchInvoice, printDialogRef, newAction } = props;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [actions, setActions] = useState([]);

    const _saveAction = (withSubmit, id) => {
        let header = invoiceState.invoice.header;
        if (withSubmit && !checkValidation([{ id: "estimators-inspectors-invoice" },
        {
            id: "invoices-list-table",
            title: t('propertiesPolicies:actions'),
            data: invoiceState.invoice.lines,
        },
        ], t)) {
            return
        }
        showActionWaiting(id);
        let data = { ...header, lines: invoiceState.invoice.lines, parties: invoiceState.invoice.parties }
        saveEstimatorInvoice(data, withSubmit)
            .then((response) => {
                let invoiceData = { header: response.data, lines: response.data.lines || [], parties: response.data.parties || [] };
                invoiceData.header.defaultCdaDescLo = invoiceData.header.defaultCdaItem?.descLo;
                invoiceData.header.defaultCdaDescFo = invoiceData.header.defaultCdaItem?.descFo;
                invoiceData.header.defaultCdaCode = invoiceData.header.defaultCdaItem?.code;
                invoiceData.lines.map((line) => {
                    if (line.source == ESTIMATION_FEES) {
                        line.descLo = invoiceData?.header?.defaultCdaItem.code + ', ' + invoiceData?.header?.defaultCdaItem.descLo
                        line.descFo = invoiceData?.header?.defaultCdaItem.code + ', ' + invoiceData?.header?.defaultCdaItem.descFo
                    }
                    if (line.source == ITEM) {
                        line.descLo = line?.item?.code + ', ' + line?.item?.descLo
                        line.descFo = line?.item?.code + ', ' + line?.item?.descFo
                    }
                })
                dispatchInvoice({ value: invoiceData, type: ACTION_SET_INVOICE })
                addUrlParam("id", response.data.id);
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideActionWaiting(id));
    }

    const _confirmInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return confirmEstimatorInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/estimators-inspectors-invoices`);
                    showSuccessMessage(null, t);
                })
                .catch((error) => {
                    let message = error?.response?.data?.message;
                    if (message.includes('#')) {
                        message = message.split('#')[0]
                    }
                    handleError(error, message, t)
                });
        });
    }

    const _rejectInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectEstimatorInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/estimators-inspectors-invoices`);
                    showSuccessMessage(null, t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _deleteInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimatorInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/estimators-inspectors-invoices`);
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _isAllowedSaveAction = () => {
        const status = invoiceState?.invoice?.header?.globalStatus;
        return (status == GLOBAL_STATUS_INCOMPLETE || status == GLOBAL_STATUS_SUBMITTED) &&
            isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, SAVE_ACTION);
    }

    useEffect(() => {
        const temp = [];
        const status = invoiceState?.invoice?.header?.globalStatus;


        if (status == GLOBAL_STATUS_INCOMPLETE && isNotEmpty(invoiceState?.invoice?.header?.id) && (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, DELETE_ACTION))) {
            temp.push({
                id: "delete-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:delete'),
                icon: <DeleteTowIcon />,
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action: _deleteInvoice
            });
        }
        if (status == GLOBAL_STATUS_SUBMITTED && isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, REJECT_ACTION)) {
            temp.push({
                id: "reject-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:reject'),
                icon: <RejectIcon />,
                color: "var(--action-delete-bg)",
                action: _rejectInvoice
            });
        }
        if (newAction && isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, NEW_ACTION)) {
            temp.push({
                id: "new-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:new'),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
                action: () => newAction()
            });
        }
        if (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, PRINT_ACTION)) {
            temp.push({
                id: "print-inspector-estimator-invoice",
                label: t('customersEstimationInvoices:print'),
                color: "var(--pageination)",
                icon: <PrinterIcon />,
                action: () => printDialogRef?.current?.open([invoiceState?.invoice?.header?.id])
            });
        }

        if (status == GLOBAL_STATUS_SUBMITTED && isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, CONFIRM_FORM_ACTION)) {
            temp.push({
                id: "approve-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:confirm'),
                color: "var(--action-save)",
                icon: <ApproveIcon />,
                action: _confirmInvoice
            });
        }

        if (status == GLOBAL_STATUS_INCOMPLETE && (isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, SAVE_ACTION))) {
            temp.push({
                id: "draft-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:draft'),
                icon: <IncompleteIcon />,
                color: "var(--action-incomplete)",
                action: () => _saveAction(false, "draft-inspector-estimator-invoice")
            });
        }

        if (_isAllowedSaveAction()) {
            temp.push({
                id: "save-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:save'),
                color: "var(--action-save)",
                hotkey: SAVE_ACTION_SHORT_CUT,
                icon: <SaveActionIcon />,
                action: () => _saveAction(true, "save-inspector-estimator-invoice")
            });
        }

   
        setActions([...temp]);
    }, [i18next.language, invoiceState]);


    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction(true, "save-inspector-estimator-invoice");
        }
    }, { enableOnFormTags: true });

    return (
        <ActionsButton actions={actions} />
    )
}

export default Actions;