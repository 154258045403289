import React from "react";

function UserRolesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.535"
      height="17.499"
      viewBox="0 0 17.535 17.499"
    >
      <path
        fill="#4fd64f"
        d="M1.715 16.267a3.17 3.17 0 01.009-5.637 8.691 8.691 0 018.882 0 3.175 3.175 0 010 5.637 8.633 8.633 0 01-8.891 0zm11.218-.736a3.11 3.11 0 001.065-2.03 3.439 3.439 0 00-1.46-2.957 7.192 7.192 0 00-2.012-1.1 6.831 6.831 0 015.86 1.024 2.481 2.481 0 01-.383 4.383 6.106 6.106 0 01-2.9.685zM6.29 8.302h-.044a.694.694 0 00-.191 0 4.133 4.133 0 11.253 0zm4.339-.953a4 4 0 001.313-3.324 4.072 4.072 0 00-.674-1.91 3.181 3.181 0 011.112-.306 3.142 3.142 0 01.656 6.249h-.045a.4.4 0 00-.146.018h-.18a3.111 3.111 0 01-2.036-.726z"
        data-name="Union 33"
      ></path>
    </svg>
  );
}

export default UserRolesIcon;
