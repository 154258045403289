import { decryptText, isEnglish, isNotEmpty } from '@ipgd-gauge/utils';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from "../../../../components/table";
import style from './style.module.scss';

const _fillLable = (data, labelElement) => {
    if (labelElement.childElementCount > 0) {
        labelElement.childNodes.forEach(node => {
            if (node.nodeName == "#text") {
                data.label = node.data;
            }
        })
    } else {
        data.label = labelElement.textContent;
    }
}

const _fillDoaminData = (data, element) => {
    data.newValue = isEnglish() ? element[`${element.columnName}_NEW`]?.descLo : element[`${element.columnName}_NEW`]?.descFo;
    const oldValue = element[`${element.columnName}_OLD`];
    if (oldValue) {
        data.oldValue = isEnglish() ? oldValue.descLo : oldValue.descFo;
    }
}

const _fillForeignData = (dataInfo, data, element) => {
    const informations = dataInfo.split("&&");
    data.newValue = element[`${informations[0]}_NEW`][informations[isEnglish() ? 1 : 2]];
    const oldValue = element[`${informations[0]}_OLD`];
    if (oldValue) {
        data.oldValue = oldValue[informations[isEnglish() ? 1 : 2]];
    }
}

const _getData = (element) => {
    const data = {
        label: ' - ',
        oldValue: element.oldValue,
        newValue: element.newValue,
    }
    const selectedLabels = document.querySelectorAll("[data-label]");
    selectedLabels.forEach(selectedLabel => {
        if (decryptText(selectedLabel.getAttribute("data-label")) == element.columnName) {
            _fillLable(data, selectedLabel);
            let dataInfo = selectedLabel.getAttribute("data-info");
            if (isNotEmpty(dataInfo)) {
                dataInfo = decryptText(dataInfo);
                if (dataInfo === "DOMAIN") {
                    _fillDoaminData(data, element);
                } else {
                    _fillForeignData(dataInfo, data, element);
                }
            }
            return;
        }
    })
    return data;
}

const ActionDetailsRow = (props) => {
    const { data, virtualizedStyle, index } = props;
    const formattedData = _getData(data);

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div title={index + 1}><div>{index + 1}</div></div>
            <div title={formattedData?.label}><div>{formattedData?.label}</div></div>
            <div title={formattedData?.oldValue}><div>{formattedData?.oldValue}</div></div>
            <div title={formattedData?.newValue}><div>{formattedData?.newValue}</div></div>
        </div>
    );
};

const ActionDetailsTable = forwardRef((props, ref) => {
    const { user, formId, logs } = props;
    const { t } = useTranslation();

    const [index, setIndex] = useState(-1);

    const columns = [
        { title: '#', width: '10%' },
        { title: t('field_desc'), width: '30%' },
        { title: t('old_value'), width: '30%' },
        { title: t('new_value'), width: '30%' },
    ];

    const changeSelectedIndex = (index) => {
        if (logs?.length == 0 || index > logs?.length - 1) {
            setIndex(-1);
        } else {
            setIndex(index);
        }
    }

    useImperativeHandle(ref, () => ({ changeSelectedIndex }))

    return (
        <div className={style.actionDetailsTableContainer}>
            <Table
                id={`log-table-details-${formId}`}
                formId={formId}
                columns={columns}
                Row={ActionDetailsRow}
                tableStyle={style.actionDetailsTable}
                rowHeight={40}
                list={index >= 0 && logs.length > 0 ? logs[index]?.details || [] : []}
                user={user}
            />
        </div>
    )
});

const ActionDetails = (props) => {

    const { user, formId, logs, detailsTableRef } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('action_details')}
                    </label>
                </div>
            </div>
            <ActionDetailsTable
                ref={detailsTableRef}
                user={user}
                formId={formId}
                logs={logs}
            />
        </div>
    )
}

export default ActionDetails;