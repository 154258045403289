import SlistingComponent from "@ipgd-gauge/slisting";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveCurrentRecordId, saveSelectedRecords } from "../../store/formSelectedRecords/actions";
import { saveFormsFilter } from "../../store/formsFilter/actions";
import { updateUserTableProfiles } from "../../store/userTableProfiles/actions";
import { deleteUserTableProfiles, getUserTableProfiles, getValuationReports, printValuationReport, saveUserTableProfiles, updateSelectedUserTableProfiles } from "../../util/apis";
import { getUser } from "../../util/cookies";
import Waiting from "../waiting";

const Slisting = (props, ref) => {

    const { id, formId, columns, Row, rowHeight, cardInMobile, reportsTable, multiLevel, rowActions, getData, pagingData, tableStyle, noDataFoundLabel, showWating, showNoDataFound, rowNumber, withFilter, Total, postSelect, listingActions, deleteAction, viewAction, newAction, withPrintAction, exportExcelAction, hideDeleteCondition, labelCardWidth, printAction } = props;

    const dispatch = useDispatch();
    const user = getUser();

    const currentId = useSelector(state => state.persistedReducer.formSelectedRecords[formId]?.currentId)

    return (
        <SlistingComponent
            ref={ref}
            id={id}
            columns={columns}
            Row={Row}
            rowHeight={rowHeight}
            cardInMobile={cardInMobile}
            reportsTable={reportsTable}
            multiLevel={multiLevel}
            rowActions={rowActions}
            getData={getData}
            pagingData={pagingData}
            tableStyle={tableStyle}
            noDataFoundLabel={noDataFoundLabel}
            showWating={showWating}
            showNoDataFound={showNoDataFound}
            rowNumber={rowNumber}
            withFilter={withFilter}
            Total={Total}
            postSelect={postSelect}
            listingActions={listingActions}
            deleteAction={deleteAction}
            viewAction={viewAction}
            newAction={newAction}
            withPrintAction={withPrintAction}
            exportExcelAction={exportExcelAction}
            user={user}
            formId={formId}
            clearSelectedRecords={() => dispatch(saveSelectedRecords(formId, []))}
            saveFormsFilter={saveFormsFilter}
            updateUserTableProfiles={updateUserTableProfiles}
            deleteUserTableProfiles={deleteUserTableProfiles}
            getUserTableProfiles={getUserTableProfiles}
            saveUserTableProfiles={saveUserTableProfiles}
            updateSelectedUserTableProfiles={updateSelectedUserTableProfiles}
            Waiting={Waiting}
            getReportsForSelect={getValuationReports}
            printReport={printValuationReport}
            changeCurrentRecordId={(newId) => dispatch(saveCurrentRecordId(formId, newId))}
            isTransactionForm={true}
            changeSelectedRecords={(newIds) => dispatch(saveSelectedRecords(formId, [...newIds]))}
            currentId={currentId}
            hideDeleteCondition={hideDeleteCondition}
            labelCardWidth={labelCardWidth}
            printAction={printAction}
        />
    )
}

export default forwardRef(Slisting);