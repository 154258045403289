import Checkbox from "@ipgd-gauge/checkbox";
import FrmTimeDatePickr from "@ipgd-gauge/date-picker/dist/FrmTimeDatePickr";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, handleError, isEnglish } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import { getCodingStyleManagementForms, getDomainValues, getMatersList } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_DOMAIN } from "../../../util/constants";
import { FND0042_USERS_DEFINITION_FORM_ID, RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const dialogId = "properties-users-definition-dialog";

const Row = (props) => {
    const { data, index, rowActions, virtualizedStyle, } = props;
    const { handleDataChange, deleteLine, lov } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
             <div><div title={data.sequence}>{data.sequence}</div></div>

            <div>
                <div className="inputRow" >
                    <FrmSelectMenu
                        id={`${dialogId}-role-${index}`}
                        value={data?.fmaId}
                        onChange={(value) => handleDataChange(index, "fmaId", value.value)}
                        api={() => getMatersList(data?.fmaId)}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        portalTarget={document.body}
                        menuWidth='250px'
                    />
                </div>
            </div>

            <div>
                <div className={`inputRow ${style.timeLimitationCheckbox}`}>
                    <Checkbox
                        id={`${dialogId}-has-time-limitation-${index}`}
                        isChecked={data?.hasTimeLimitation}
                        setIsChecked={(value) => handleDataChange(index, "hasTimeLimitation", value)}
                        styleClass={style.check}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow" >
                    <FrmTimeDatePickr
                        id={`${dialogId}-from-date-dgr-${index}`}
                        value={data.fromDgr}
                        onChange={(value) => handleDataChange(index, "fromDgr", value)}
                        maxDate={data.toDgr}
                        disabled={!data?.hasTimeLimitation}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow" >
                    <FrmTimeDatePickr
                        id={`${dialogId}-to-date-dgr-${index}`}
                        value={data.toDgr}
                        onChange={(value) => handleDataChange(index, "toDgr", value)}
                        minDate={data.toDgr}
                        disabled={!data?.hasTimeLimitation}
                    />
                </div>
            </div>

            <div>
                <div className="inputRow" >
                    <FrmSelectMenu
                        id={`${dialogId}-status-${index}`}
                        value={data?.isActive}
                        onChange={(value) => handleDataChange(index, "isActive", value.value)}
                        options={lov?.status || []}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        portalTarget={document.body}
                        menuWidth="250px"
                    />
                </div>
            </div>

            <div>
                <div className="inputRow" >
                    <FrmSelectMenu
                        id={`${dialogId}-form-${index}`}
                        value={data?.frmId}
                        onChange={(value) => handleDataChange(index, "frmId", value.value)}
                        options={lov?.forms || []}
                        descLo={['code', 'descLo']}
                        descFo={['code', 'descFo']}
                        isDisabled={true}
                        portalTarget={document.body}
                        menuWidth="250px"
                    />
                </div>
            </div>

            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => deleteLine(index)} />
                </div>
            </div>
        </div>
    );
}

const MaterTable = (props) => {
    const { user, data, setData, lov } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "3%" },
        { title: <span data-property="fmaId" className="mandatory">{t('usersDefinition:role')}</span>, width: "21%" },
        { title: <span data-property="hasTimeLimitation" className="mandatory">{t('usersDefinition:has_limitation')}</span>, width: "8%" },
        { title: <span data-property="fromDgr" className="mandatory">{t('usersDefinition:from_date')}</span>, width: "14%" },
        { title: <span data-property="toDgr" className="mandatory">{t('usersDefinition:to_date')}</span>, width: "14%" },
        { title: <span data-property="isActive" className="mandatory">{t('usersDefinition:status')}</span>, width: "9%" },
        { title: <span data-property="frmId" className="mandatory">{t('usersDefinition:source')}</span>, width: "23%" },
        { title: '', width: "8%" }
    ];

    const _deleteLine = (index) => {
        if (!data?.fndUser?.maters) {
            data.fndUser.maters = [];
        }

        deleteItemByIndex(data.fndUser?.maters, index)
        setData({ ...data });
    }

    const _handleDataChange = (index, key, value) => {
        data.fndUser.maters[index][key] = value;

        if (key === "hasTimeLimitation") {
            data.fndUser.maters[index]['fromDgr'] = null;
            data.fndUser.maters[index]['toDgr'] = null;
        }

        setData({ ...data });
    }

    return (
        <Table
            id={`${dialogId}-mater-table`}
            columns={columns}
            Row={Row}
            tableStyle={style.materTable}
            rowHeight={40}
            list={data?.fndUser?.maters || []}
            rowActions={{ handleDataChange: _handleDataChange, deleteLine: _deleteLine, lov }}
            formId={RSM2661_PROPERTIES_USERS_FORM_ID}
            user={user}
        />
    );
}

const Tab5 = (props) => {
    const { tab, data, setData, user } = props;

    const { t } = useTranslation();
    const [lov, setLov] = useState({ forms: [], status: [] });

    useEffect(() => {
        Promise.all([getCodingStyleManagementForms(), getDomainValues(STATUS_DOMAIN)])
            .then((response) => {
                lov.forms = response[0].data;
                lov.status = response[1].data;
                setLov({ ...lov });
            })
            .catch((error) => handleError(error, null, t));
    }, []);

    const _addNewLine = () => {
        if (!data?.fndUser?.maters) {
            data.fndUser.maters = [];
        }

        data.fndUser.maters = [...data.fndUser.maters, { frmId: FND0042_USERS_DEFINITION_FORM_ID, isActive: STATUS_ACTIVE, hasTimeLimitation: false }];
        setData({ ...data });
    }

    const _DeleteAllLines = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.fndUser.maters = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 5 ? 'active' : ''}>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className={`w100 tableHeaderActions`}>
                        <button title={t('add')} onClick={_addNewLine}>
                            <span><AddHeaderBtnIcon /></span>
                            <label>{t('add')}</label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            title={t('delete_all')}
                            onClick={_DeleteAllLines}
                            disabled={(data?.fndUser?.maters || []).length === 0}>
                            <span><DeleteTowIcon /></span>
                            <label>{t('delete_all')}</label>
                        </button>
                    </div>
                </div>
            </div>

            <MaterTable user={user} data={data} setData={setData} lov={lov} />
        </div>
    )
}

export default Tab5;