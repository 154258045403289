import React from "react";

function EditTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.773"
      height="16.773"
      viewBox="0 0 16.773 16.773"
    >
      <g
        fill="#088587"
        data-name="Group 178480"
        transform="translate(-3224.964 -3007.263)"
      >
        <path
          d="M10.6 16.578H5.976C1.789 16.578 0 14.789 0 10.6V5.976C0 1.789 1.789 0 5.976 0h1.542A.583.583 0 018.1.578a.583.583 0 01-.578.578H5.976c-3.555 0-4.819 1.265-4.819 4.819V10.6c0 3.555 1.265 4.819 4.819 4.819H10.6c3.555 0 4.819-1.265 4.819-4.819V9.06a.578.578 0 011.157 0v1.54c.002 4.189-1.787 5.978-5.976 5.978z"
          transform="translate(3224.964 3007.458)"
        ></path>
        <path
          d="M1.7 12.871a1.726 1.726 0 01-1.218-.478 1.735 1.735 0 01-.455-1.5l.327-2.325a2.674 2.674 0 01.671-1.342L7.1 1.151c1.534-1.534 3.092-1.534 4.626 0A3.219 3.219 0 0112.868 3.7a3.43 3.43 0 01-1.141 2.07l-6.076 6.076a2.674 2.674 0 01-1.342.671l-2.321.332a1.286 1.286 0 01-.288.022zm6.223-10.9L1.842 8.044a1.569 1.569 0 00-.347.686l-.332 2.321a.605.605 0 00.131.524.605.605 0 00.524.131l2.321-.332a1.529 1.529 0 00.686-.347L10.9 4.952a2.366 2.366 0 00.8-1.365 2.14 2.14 0 00-.8-1.627c-1.232-1.233-2.08-.886-2.982.008z"
          data-name="Vector"
          transform="translate(3228.859 3007.263)"
        ></path>
        <path
          d="M4.385 4.971a.5.5 0 01-.154-.023A6.12 6.12 0 01.021.738a.586.586 0 01.4-.717.574.574 0 01.709.4A4.954 4.954 0 004.54 3.83a.582.582 0 01.4.717.567.567 0 01-.555.424z"
          data-name="Vector"
          transform="translate(3234.92 3009.103)"
        ></path>
      </g>
    </svg>
  );
}

export default EditTableIcon;