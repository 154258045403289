import { ACTION_CLEAR_SELECTED_RECORDS, ACTION_SAVE_CURRENT_RECORD_ID, ACTION_SAVE_SELECTED_RECORDS } from "../actions";

const formSelectedRecords = (state = {}, action) => {
    switch (action.type) {
        case ACTION_SAVE_SELECTED_RECORDS:
            if (!state[action.formId]) {
                state[action.formId] = { selectedIds: null, currentId: null };
            }
            state[action.formId].selectedIds = action.data;
            state[action.formId].currentId = action.data[0];
            return { ...state };

        case ACTION_SAVE_CURRENT_RECORD_ID:
            state[action.formId].currentId = action.id;
            return { ...state };

        case ACTION_CLEAR_SELECTED_RECORDS:
            state[action.formId] = {};
            return { ...state };

        default:
            return state;
    }
};

export default formSelectedRecords;