import React from "react";

function UsersLoginLogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="21.497"
      viewBox="0 0 19.5 21.497"
    >
      <g
        fill="#088587"
        data-name="Group 178502"
        transform="translate(-558.25 -253.25)"
      >
        <path
          d="M8.995 20.747a2.746 2.746 0 01-1.942-.794l-1.71-1.69a2.262 2.262 0 00-1.593-.653H3a3.739 3.739 0 01-3.75-3.72V2.97A3.739 3.739 0 013-.75h12a3.739 3.739 0 013.75 3.72v10.91A3.744 3.744 0 0115 17.61h-.76a2.23 2.23 0 00-1.59.65l-1.713 1.693a2.746 2.746 0 01-1.942.794zM3 .75A2.238 2.238 0 00.75 2.97v10.92A2.238 2.238 0 003 16.11h.75A3.77 3.77 0 016.4 17.2l1.707 1.687a1.272 1.272 0 001.776 0l1.71-1.69a3.717 3.717 0 012.647-1.087H15a2.243 2.243 0 002.25-2.23V2.97A2.238 2.238 0 0015 .75z"
          transform="translate(559 254)"
        ></path>
        <path
          d="M2.03 4.65h-.179a2.7 2.7 0 112.8-2.7 2.673 2.673 0 01-2.6 2.7zm-.13-1.5h.122A1.171 1.171 0 003.15 1.957V1.95a1.2 1.2 0 10-1.25 1.2z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(566.04 257.05)"
        ></path>
        <path
          d="M3.751-.75a5.774 5.774 0 013.16.875 2.742 2.742 0 011.333 2.24A2.739 2.739 0 016.915 4.6a5.744 5.744 0 01-3.167.884A5.744 5.744 0 01.581 4.6 2.74 2.74 0 01-.75 2.365 2.739 2.739 0 01.58.127 5.812 5.812 0 013.751-.75zm0 4.737a4.3 4.3 0 002.333-.631 1.269 1.269 0 00.664-.992 1.269 1.269 0 00-.665-.992A4.327 4.327 0 003.751.75a4.368 4.368 0 00-2.338.624 1.268 1.268 0 00-.663.991 1.269 1.269 0 00.665.992 4.3 4.3 0 002.332.63z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(564.252 263.21)"
        ></path>
      </g>
    </svg>
  );
}

export default UsersLoginLogIcon;