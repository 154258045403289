import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3266_IMPORTATNT_LINKS } from "../../util/constants/forms";
import ImportantLinksDialog from "./dialog";
import ImportantLinksTable from "./listing";
import ImportantLinksFilter from "./listing/filter";

const ImportantLinks = () => {
    const { t } = useTranslation("importantLinks");

    const searchTextRef = useRef();
    const tableRef = useRef();
    const filterRef = useRef();
    const dialogRef = useRef();

    const _openFilter = () => filterRef.current?.open()

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3266_IMPORTATNT_LINKS} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('description') + ' , ' + t('remark')}
                        search={() => tableRef.current.refresh()}
                        formId={RSM3266_IMPORTATNT_LINKS}
                    />
                    <button className="headerPanelBtn" onClick={_openFilter}>
                        <FiltersIcon />
                    </button>
                </div>

            </div>

            <ImportantLinksTable
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
                dialogRef={dialogRef}
            />

            <ImportantLinksFilter
                tableRef={tableRef}
                ref={filterRef}
            />

            <ImportantLinksDialog
                ref={dialogRef}
                tableRef={tableRef}
            />

        </>
    );
}

export default ImportantLinks;