import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import EstimationMessagesTemplatesIcon from "../../../icons/EstimationMessagesTemplatesIcon";
import { getEstimationFieldsList, getEstimationMessageTemplt, getRealEstateUserLOV, saveEstimationMessageTemplt } from "../../../util/apis";
import { ALERT_TYPE_UPDATE_DATA, STATUS_ACTIVE, TASK_SUBJECT_TYPE_DOMAIN_FIELD, TASK_SUBJECT_TYPE_DOMAIN_TEXT, UPDATED_FIELDS_ALL_FIELDS, UPDATED_FIELDS_SPECIFIC_FIELDS, WORKFLOW_PRIORITIES_LOW } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";
import style from './style.module.scss';

const dialogId = "estimation-messages-templates-dialog";

const EstimationMessagesTemplatesDialog = (props, ref) => {
    const { tableRef, user } = props;
    const formId = RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID;

    const defaultData = {
        isActive: STATUS_ACTIVE,
        type: ALERT_TYPE_UPDATE_DATA,
        priority: WORKFLOW_PRIORITIES_LOW,
        updatedFields: UPDATED_FIELDS_ALL_FIELDS
    }

    const { t } = useTranslation();
    const dialogRef = useRef();
    const fieldsListRef = useRef();
    const toUserListRef = useRef();
    const ccUserListRef = useRef();
    const updateRecordRef = useRef(false);

    const [data, setData] = useState({});

    useEffect(() => {
        dialogRef.current.controlTabVisibility([1], data.updatedFields == UPDATED_FIELDS_SPECIFIC_FIELDS);
        dialogRef.current.controlTabVisibility([3], data.toOthers);
        dialogRef.current.controlTabVisibility([4], data.ccOthers);
    }, [data?.updatedFields, data?.toOthers, data?.ccOthers])

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId },
        {
            id: `${dialogId}-fields-table`,
            title: t('estimationMessagesTemplates:fields'),
            data: data?.fields,
        },
        {
            id: `${dialogId}-message-body-table`,
            title: t('estimationMessagesTemplates:message_body'),
            data: data?.estimationMessageBodys,
            disabledConditions: {
                text: (data) => data.type != TASK_SUBJECT_TYPE_DOMAIN_TEXT,
                sdiId: (data) => data.type != TASK_SUBJECT_TYPE_DOMAIN_FIELD,
            }
        },
        {
            id: `${dialogId}-to-users-list-table`,
            title: t('estimationMessagesTemplates:to_users_list'),
            data: data?.messageTemplateToUser,
        },
        {
            id: `${dialogId}-cc-users-list-table`,
            title: t('estimationMessagesTemplates:cc_users_list'),
            data: data?.messageTemplateCCUser,
        }
        ], t)) {
            return;
        }

        showWaiting(`${dialogId}-okButton`, true);

        saveEstimationMessageTemplt(data)
            .then(() => {
                dialogRef.current.changeSelectedTab(1);
                showSuccessMessage('save_successfully', t);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh();
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-okButton`, true))
    }

    const _insertFields = (selectedData) => {
        let newData = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newData.push({ bisId: element.id, descLo: element.descLo, descFo: element.descFo, blockDescLo: element.blockDescLo, blockDescFo: element.blockDescFo });
        }

        data.fields = [...(data?.fields || []), ...newData];
        setData({ ...data });

        fieldsListRef.current.close();
    }

    const _insertToUser = (selectedData) => {
        let newData = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newData.push({ ssuId: element.id, code: element.code, descLo: element.descLo, descFo: element.descFo });
        }

        data.messageTemplateToUser = [...(data?.messageTemplateToUser || []), ...newData];
        setData({ ...data });

        toUserListRef.current.close();
    }

    const _insertCCUser = (selectedData) => {
        let newData = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newData.push({ ssuId: element.id, code: element.code, descLo: element.descLo, descFo: element.descFo });
        }

        data.messageTemplateCCUser = [...(data?.messageTemplateCCUser || []), ...newData];
        setData({ ...data });

        ccUserListRef.current.close();
    }

    const _postClose = () => {
        setData(defaultData)
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getEstimationMessageTemplt(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setData({ ...defaultData })
        }
    }

    const open = (id) => {
        dialogRef.current.open();
        _populateRecordData(id);
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <>
            <ModalWithTabs
                id={dialogId}
                ref={dialogRef}
                formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
                title={'estimationMessagesTemplates:estimation_messages_templates'}
                Icon={<EstimationMessagesTemplatesIcon />}
                labels={['home', 'estimationMessagesTemplates:fields', 'estimationMessagesTemplates:message_body', 'estimationMessagesTemplates:to_users_list', 'estimationMessagesTemplates:cc_users_list']}
                okBtnAction={_saveAction}
                postClose={_postClose}
                preClose={_preCloseAction}
                hideOkBtn={!(isAllowedAction(user, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, SAVE_ACTION))}
                nextPreviousAction={_populateRecordData}
            >
                <Tab1 data={data} setData={setData} />
                <Tab2 data={data} setData={setData} user={user} fieldsListRef={fieldsListRef} />
                <Tab3 data={data} setData={setData} user={user} />
                <Tab4 data={data} setData={setData} user={user} toUserListRef={toUserListRef} />
                <Tab5 data={data} setData={setData} user={user} ccUserListRef={ccUserListRef} />
            </ModalWithTabs>

            {data.updatedFields == UPDATED_FIELDS_SPECIFIC_FIELDS &&
                <MultiInsertDialog
                    id='fields-insert-dialog'
                    ref={fieldsListRef}
                    formId={formId}
                    tableStyle={style.estimationMessagesTemplatesInsart}
                    title={t('estimationMessagesTemplates:fields_list')}
                    okBtnAction={_insertFields}
                    api={getEstimationFieldsList}
                    searchPlaceholder={t('estimationMessagesTemplates:block_name') + ' , ' + t('name')}
                    columns={[{ label: '#', width: '10%' },
                    { label: t('estimationMessagesTemplates:block_name'), valueLo: 'blockDescLo', valueFo: 'blockDescFo', width: '45%' },
                    { label: t('name'), valueLo: 'descLo', valueFo: 'descFo', width: '45%' }]}
                    isSmallSize
                />
            }

            {/* {data.toOthers && */}
            <MultiInsertDialog
                id='to-user-insert-dialog'
                ref={toUserListRef}
                formId={formId}
                tableStyle={style.estimationMessagesTemplatesInsart}
                title={t('estimationMessagesTemplates:users_list')}
                okBtnAction={_insertToUser}
                api={(searchValue) => getRealEstateUserLOV(searchValue, STATUS_ACTIVE, false, false)}
                searchPlaceholder={t('code') + ' , ' + t('name')}
                columns={[{ label: '#', width: '10%' },
                { label: t('code'), value: 'code', width: '45%' },
                { label: t('name'), valueLo: 'descLo', valueFo: 'descFo', width: '45%' }]}
                isSmallSize
            />
            {/* } */}

            {data.ccOthers &&
                <MultiInsertDialog
                    id='cc-user-insert-dialog'
                    ref={ccUserListRef}
                    formId={formId}
                    tableStyle={style.estimationMessagesTemplatesInsart}
                    title={t('estimationMessagesTemplates:users_list')}
                    okBtnAction={_insertCCUser}
                    api={(searchValue) => getRealEstateUserLOV(searchValue, STATUS_ACTIVE, false, false)}
                    searchPlaceholder={t('code') + ' , ' + t('name')}
                    columns={[{ label: '#', width: '10%' },
                    { label: t('code'), value: 'code', width: '45%' },
                    { label: t('name'), valueLo: 'descLo', valueFo: 'descFo', width: '45%' }]}
                />
            }
        </>
    )
}

export default forwardRef(EstimationMessagesTemplatesDialog);