import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues, getEvaluationPolicyFunctions } from "../../../util/apis";
import { POLICY_LVL_DOMAIN, STATUS_DOMAIN } from "../../../util/constants";

const PropertiesPoliciesFilter = (props, ref) => {
    const { propertiesPoliciesTableRef } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation("propertiesPolicies");

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        let data = Object.assign(filterData, {});
        return data;
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    return (
        <AdvanceFilter
            id="propertiesPoliciesFilter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => propertiesPoliciesTableRef?.current?.refresh()}
            numOfRows={2}
            hideMoreOptions
        >
            <div className="container">
                <div className="row advanceFilterContainer filter-panel-row rowSmModel">
                    <div className="w50">
                        <label className='form-label'>{t('status')}</label>
                        <StatusSelectMenu
                            id="status"
                            value={filterData.status}
                            api={() => getDomainValues(STATUS_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("status", value.value)}
                            isClearable={true}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('policy_level')}</label>
                        <FrmSelectMenu
                            id="policy_level"
                            value={filterData.policyLevel}
                            api={() => getDomainValues(POLICY_LVL_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("policyLevel", value.value)}
                            isClearable={true}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('function')}</label>
                        <FrmSelectMenu
                            id="function"
                            isMulti
                            value={filterData.functionIds}
                            api={getEvaluationPolicyFunctions}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("functionIds", value)}
                            portalTarget={document.body}
                        />

                    </div>
                </div>
            </div >
        </AdvanceFilter>
    )
}

export default forwardRef(PropertiesPoliciesFilter);