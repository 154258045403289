import { useTranslation } from 'react-i18next';
import Image from '../image';
import style from './style.module.scss'
import NoDataFoundIcon from '../../icons/NoDataFound';

const NoDataFound = () => {

    const { t } = useTranslation();

    return (
        <div className={style.noDataFoundContainer}>
            <div>
                <NoDataFoundIcon />
                <label>{t('no_data_found')}</label>
            </div>
        </div>
    )

}

export default NoDataFound;