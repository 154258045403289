import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../components/side-panel";
import InboxIcon from "../../../../icons/Inbox";
import { getMessage, getMessageFields, sendMessage } from "../../../../util/apis";
import { ALERT_TYPE_INFORMATION, ALERT_TYPE_UPDATE_DATA, UPDATED_FIELDS_ALL_FIELDS, WORKFLOW_PRIORITIES_LOW } from "../../../../util/constants";
import MultiInsertDialog from "../multi-insert-dialog";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const MsgDialog = (props, ref) => {
    const { tableRef, fromTransactions, formId, postClose } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const tabsRef = useRef();
    const fieldsListDialogRef = useRef();

    const defaultData = {
        type: ALERT_TYPE_INFORMATION,
        priority: WORKFLOW_PRIORITIES_LOW
    }

    const [message, setMessage] = useState({});
    const [withSave, setWithSave] = useState(true);

    const _handleDataChanged = (name, value) => {
        message[name] = value;
        if (name === 'type') {
            if (value === ALERT_TYPE_UPDATE_DATA) {
                if (!message.updatedFields) {
                    message.updatedFields = UPDATED_FIELDS_ALL_FIELDS;
                    message.specifiedFields = null;
                }
            } else {
                message.updatedFields = null;
                message.specifiedFields = null;
            }
        } else if (name === 'updatedFields') {
            message.specifiedFields = null;
        } else if (name === "application") {
            message.triId = value.value;
            message.fnoId = value.fnoId;
        }
        setMessage({ ...message });
    }

    const _postClose = () => {
        setMessage({});
        setWithSave(true);
        tabsRef.current?.changeTab(1);
        removeAllMandatoryClasses(document.getElementById("message-dialog"));
        if (postClose) {
            postClose();
        }
    }

    const _populateRecordData = (msgId, triId, fnoId) => {
        if (msgId) {
            setWithSave(false);
            getMessage(msgId)
                .then((response) => setMessage(response.data))
                .catch((error) => handleError(error, null, t));
        } else if (triId && fnoId) {
            message.triId = triId;
            message.fnoId = fnoId;
            setMessage({ ...message, ...defaultData });
        } else {
            setMessage({ ...defaultData })
        }
    }

    const open = (msgId, triId, fnoId) => {
        _populateRecordData(msgId, triId, fnoId);
        dialogRef.current.open();
    }

    const replyOpen = (msgId) => {
        getMessage(msgId)
            .then((response) => {
                const data = defaultData
                data.refMessageId = msgId
                data.receiverSsuId = [response.data.senderSsuId]
                data.ccSsuId = response.data.ccSsuId
                data.triId = response.data.triId
                data.code = response.data.code
                setMessage(data)
            })
            .catch((error) => handleError(error, null, t))
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    const _saveMessage = () => {
        if (!checkValidation([{ id: "message-dialog" }], t)) {
            return;
        }
        showWaiting('message-Dialog-okButton', true);
        let data = Object.assign({}, message);
        let receivers = [];
        message?.receiverSsuId?.map(id => {
            receivers.push({ receiverSsuId: id, role: '130301' });
        })
        message?.ccSsuId?.map(id => {
            receivers.push({ receiverSsuId: id, role: '130302' });
        })
        data.receivers = receivers;
        sendMessage(data)
            .then(() => {
                if (tableRef?.current) {
                    tableRef.current.refresh();
                }
                showSuccessMessage(null, t);
                close();
            })
            .catch((error) => {
                handleError(null, error?.response?.data?.message.split("#")[0], t);
            })
            .finally(() => {
                hideWaiting('message-Dialog-okButton')
            })
    }

    const _insertEstimationForms = (selectedData) => {
        if (!message.specifiedFields) {
            message.specifiedFields = [];
        }

        let newLines = [];

        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                bisId: element.id,
                descLo: element.descLo,
                descFo: element.descFo,
            });
        }

        message.specifiedFields = [...message.specifiedFields, ...newLines];
        setMessage({ ...message });

        fieldsListDialogRef?.current?.close();
    }

    useImperativeHandle(ref, () => ({ open, close, replyOpen }))

    return (
        <>
            <SidePanel
                id="message-dialog"
                ref={dialogRef}
                formId={formId}
                title={t('new_msg')}
                icon={<InboxIcon />}
                withFooter={withSave}
                footerLbl={t("save")}
                footerAction={_saveMessage}

                postClose={_postClose}
            >
                <Tabs
                    id="message-dialog-tabs"
                    ref={tabsRef}
                    labels={
                        message.type == ALERT_TYPE_UPDATE_DATA ?
                            ['inbox:main_page', 'inbox:fields'] :
                            ['inbox:main_page']}
                    tabContentStyle={style.tabContentStyle}
                    tabsButtonCustomStyle={style.citiesTabs}
                >
                    <Tab1 message={message} handleDataChanged={_handleDataChanged} fromTransactions={fromTransactions} />
                    <Tab2 data={message} setData={setMessage} handleDataChanged={_handleDataChanged} fieldsListDialogRef={fieldsListDialogRef} formId={formId} />
                </Tabs>
            </SidePanel>
            <MultiInsertDialog
                id="estimation-forms-multi-insert-estimation-forms-dialog"
                formId={formId}
                ref={fieldsListDialogRef}
                title={t('propertiesPolicies:insert_workflow')}
                okBtnAction={_insertEstimationForms}
                api={(searchValue) => getMessageFields(message.fnoId, searchValue)}
                tableStyle={style.organizationDialog}
                selectedIds={(message?.specifiedFields || []).map((line) => line.bisId)}
                searchPlaceholder={t('propertiesPolicies:code') + " , " + t('propertiesPolicies:name')}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('propertiesPolicies:name'), valueLo: 'descLo', valueFo: 'descFo', width: "90%" }
                ]}
            />
        </>
    )
}

export default forwardRef(MsgDialog);