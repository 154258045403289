import OptionsButton from "@ipgd-gauge/options-buttons";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import FiltersIcon from "../../icons/Filters";
import { RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP } from "../../util/constants/forms";
import Lines from "./lines";
import InspectorsEmployeesDuesFollowUpFilter from './Filters';

const InspectorsEmployeesDuesFollowUp = ({ isTab }) => {
    const { t } = useTranslation();
    const filterRef = useRef();
    const [isTrnx, setIsTrnx] = useState(true);
    const listRef = useRef(null);

    return (
        <>
            <div className="headerPagesPanel">
                {!isTab ?
                    <FormTitle
                        formId={RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP}
                    />
                    : <div></div>
                }

                <div className="headerSearchPanel">
                    <div className="details-option-btn">
                        <OptionsButton
                            id='report-type'
                            options={[
                                {
                                    id: 'inspectors-employees-dues-follow-up-no-trxs',
                                    title: t('customerDuesFollowUp:no_trxs'),
                                    selected: isTrnx,
                                    action: () => {
                                        setIsTrnx(true)
                                        listRef.current.refresh(null, null, true);
                                    }
                                },
                                {
                                    id: 'inspectors-employees-dues-follow-up-incomes',
                                    title: t('customerDuesFollowUp:incomes'),
                                    selected: !isTrnx,
                                    action: () => {
                                        setIsTrnx(false)
                                        listRef.current.refresh(null, null, false);
                                    }
                                },
                            ]} />
                    </div>

                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>
            <Lines isTab={isTab} isTrnx={isTrnx} filterRef={filterRef} ref={listRef} />

            <InspectorsEmployeesDuesFollowUpFilter
                ref={filterRef}
                listRef={listRef}

            />

        </>
    );
}

export default InspectorsEmployeesDuesFollowUp;