import React from "react";

function RemindIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.91"
      height="24.149"
      data-name="Group 175736"
      viewBox="0 0 18.91 24.149"
    >
      <g fill={color || "#b8bfc9"} data-name="Group 175736">
        <path
          d="M14.587 2.444v-.631a1.813 1.813 0 10-3.626 0v.63a7.158 7.158 0 013.625 0"
          data-name="Path 6452"
          transform="translate(-3.318)"
        ></path>
        <path
          d="M18.911 20.62L16.5 18.205v-7.33a7.043 7.043 0 00-14.081 0V18.2L0 20.62v.918h7.5a2.283 2.283 0 00-.128.2c-.039.067-.078.134-.111.2a2.415 2.415 0 104.382 0 2.02 2.02 0 00-.111-.2 2.284 2.284 0 00-.128-.2h7.5zm-14.49-1.586v-8.158a5.036 5.036 0 0110.072 0v8.159l.5.5H3.925zm5.034 1.5"
          data-name="Path 6453"
          transform="translate(0 -1.216)"
        ></path>
      </g>
    </svg>
  );
}

export default RemindIcon;