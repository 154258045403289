import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getDomainValues } from "../../../util/apis";
import { USERS_CURRENT_STATUS_DOMAIN, USER_STATUS_DOMAIN } from "../../../util/constants";
import { FND0042_USERS_DEFINITION_FORM_ID } from "../../../util/constants/forms";

const UsersDefinitionFilter = (props, ref) => {
    const { tableRef } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return Object.assign(filterData, {});
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({ open, getFilterData }));

    return (
        <AdvanceFilter
            id="users-definition-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            ref={advanceFilterRef}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={1}
            formId={FND0042_USERS_DEFINITION_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('usersDefinition:status')}</label>
                            <StatusSelectMenu
                                id="users-definition-filter-status"
                                value={filterData.status}
                                isClearable={true}
                                isMulti={true}
                                api={() => getDomainValues(USER_STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("status", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('usersDefinition:current_status')}</label>
                            <StatusSelectMenu
                                id="current-status"
                                value={filterData.currentStatus}
                                onChange={value => _handleDataChanged("currentStatus", value)}
                                api={() => getDomainValues(USERS_CURRENT_STATUS_DOMAIN)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isClearable={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    );
}

export default forwardRef(UsersDefinitionFilter);