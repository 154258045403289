import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import OrganizationList from "../../common/dialogs/organization-list";

const Tab2 = (props) => {
    const { tab, data, setData } = props;

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <OrganizationList
                data={data}
                setData={setData}
                formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                onDataChange={() => {
                    data.araId = null;
                    data.type = null;
                    setData({ ...data });
                }}
            />
        </div>
    )
}

export default Tab2;