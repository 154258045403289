import React from "react";

function TransmittersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="18.5"
      viewBox="0 0 21.5 18.5"
    >
      <g
        fill="#fff"
        data-name="Group 178520"
        transform="translate(-1851.25 -620.75)"
      >
        <path
          d="M15 17.75H5a5.869 5.869 0 01-4.135-1.388A5.686 5.686 0 01-.75 12V5A5.686 5.686 0 01.865.638 5.869 5.869 0 015-.75h10A5.869 5.869 0 0119.135.638 5.686 5.686 0 0120.75 5v7a5.686 5.686 0 01-1.615 4.362A5.869 5.869 0 0115 17.75zM5 .75a4.442 4.442 0 00-3.115.987A4.271 4.271 0 00.75 5v7a4.271 4.271 0 001.135 3.263A4.442 4.442 0 005 16.25h10a4.442 4.442 0 003.115-.987A4.271 4.271 0 0019.25 12V5a4.271 4.271 0 00-1.135-3.263A4.442 4.442 0 0015 .75z"
          transform="translate(1852 621.5)"
        ></path>
        <path
          d="M4.995 3.865a3.761 3.761 0 01-2.342-.778L-.469.585A.75.75 0 01-.585-.469.75.75 0 01.469-.585l3.12 2.5a2.4 2.4 0 002.814 0l3.129-2.5a.75.75 0 011.054.118.75.75 0 01-.118 1.053l-3.13 2.5a3.761 3.761 0 01-2.343.779z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(1857 627)"
        ></path>
      </g>
    </svg>
  );
}

export default TransmittersIcon;
