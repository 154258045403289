import NoDataFound from "../../components/no-data-found";
import Card from "./Card";
import style from './style.module.scss';

const Container = (props) => {
    const { icon, title, forms, favForms, onFavChange } = props;

    return (
        <div style={{ height: forms?.length == 0 ? "100%" : "" }}>
            <div className={`headerTitlePanel ${style.headerTitlePanel}`}>
                <div className={style.headerIconPanel}>
                    {icon}
                </div>
                <div className="headerLblPanel">
                    {title}
                </div>
            </div>
            <div className={`${forms == null ? style.waitingContainer : style.cards} ${forms?.length == 0 ? style.noDataFoundCard : ""}`}  >

                {forms?.length == 0 ?
                    <NoDataFound />
                    :
                    forms?.map((form, index) => {
                        return (
                            <Card key={`favorite-${index}`} data={form} isFav={favForms?.indexOf(form?.dfrId) > -1} onFavChange={onFavChange} />
                        )
                    })}
            </div>
        </div>
    )
}

export default Container;