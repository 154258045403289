import { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../util/hooks/useWindowSize';
import { getDashboardColor, isEnglish, parseNumber } from '../../util/util';


const DoughnutChart = (props) => {
    const { idName, labelNameLo, selectedId, fullScreen, labelNameFo, valueName, list, onSelect, onLegendClick, includedIds, selectedColor } = props;

    const [windowSize] = useWindowSize();

    const [chartList, setChartList] = useState([])

    const chartRef = useRef();

    const { t } = useTranslation()

    useEffect(() => {
        if (selectedId.current && typeof selectedId.current == "object" && selectedId.current?.length > 0) {
            const otherList = list
            let otherTotal = 0
            for (let i = 0; i < otherList.length; i++) {
                otherTotal += otherList[i].count
            }
            const othersObj = {
                count: otherTotal,
                descLo: `${t("other")} )${otherTotal})`,
                descFo: `${t("other")} )${otherTotal}(`,
                id: -1
            }
            setChartList([othersObj])

        }

        else if (list.length > 10) {
            const otherList = list.slice(9)
            let otherTotal = 0
            for (let i = 0; i < otherList.length; i++) {
                otherTotal += otherList[i].count
            }
            const othersObj = {
                count: otherTotal,
                descLo: `${t("other")} )${otherTotal})`,
                descFo: `${t("other")} )${otherTotal}(`,
                id: -1
            }
            setChartList([...list.slice(0, 9), othersObj])
        }

        else {
            setChartList(list)
        }
        const chart = chartRef.current.chartInstance;
        if (chart.ctx !== null) {
            let meta = null;
            if (includedIds.current == null)
                for (let i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
                    meta = chart.getDatasetMeta(i);
                    meta.data.forEach(element => {
                        element.hidden = false;
                    });
                }
            chart.update();
        }
    }, [selectedColor?.datasetColor, list])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: {
            position: isEnglish() ? 'left' : 'right',
            align: 'start',
            display: true,
            position: windowSize.width <= 1200 ? 'bottom' : isEnglish() ? 'left' : 'right',
            rtl: !isEnglish(),
            labels: {
                boxWidth: 15,
                boxHeight: 3,
                fontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi',
                usePointStyle: true,
            },
            onClick(evt, legendItem) {
                var index = legendItem.index;
                var chart = chartRef.current.chartInstance;
                includedIds.current = [];
                let meta = null;
                for (let i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
                    meta = chart.getDatasetMeta(i);
                    if (meta.data[index]) {
                        meta.data[index].hidden = !meta.data[index].hidden;
                    }
                }

                const ids = chartList?.map(element => element[idName]) || [];
                for (let i = 0; i < ids.length; i++) {
                    if (!meta.data[i].hidden) {
                        if (ids[i] == -1) {
                            const othersIds = list.slice(9).map(element => element[idName])
                            includedIds.current = includedIds.current.concat(othersIds);
                        }
                        includedIds.current.push(ids[i]);
                    }
                }

                if (chartList.length == includedIds.current?.length) {
                    includedIds.current = null
                }
                chart.update();
                if (onLegendClick) {
                    onLegendClick(chart, evt, legendItem)
                }
            }
        },

        tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'red',
            backgroundColor: '#fff',
            titleFontColor: '#000',
            bodyFontColor: '#000',
            bodyFontFamily: isEnglish() ? 'Segoe UI' : 'Makdessi',
            callbacks: {
                label: (tooltipItem, data) => {
                    return `${data.labels[tooltipItem.index]}`;
                }
            }
        }
    }

    const data = {
        labels: chartList?.map(element => isEnglish() ? element[labelNameLo] : element[labelNameFo]) || [],
        datasets: [
            {
                data: chartList?.map(element => element[valueName]) || [],
                backgroundColor: getDashboardColor(Number(selectedColor.datasetColor)),
                borderWidth: 1,
                pointStyle: 'rectRounded'
            }
        ]
    }

    const _onElementsClick = (event) => {
        if (event.length > 0) {
            let index = parseNumber(event[0]["_index"]);
            if (event && event[0] && index >= 0 && onSelect) {
                if (chartList[index][idName] == -1) {
                    onSelect(list.slice(9).map(element => element[idName]))
                }
                else {
                    onSelect(chartList[index][idName]);
                }
            }
        }
    }

    return (
        <div id='doughnutChart' className='chartsContainer'>
            <Doughnut
                data={data}
                ref={chartRef}
                options={options}
                onElementsClick={(event) => _onElementsClick(event)}
            />
        </div>
    )
}


export default DoughnutChart;