import { formatDate, formatMoney, handleError, isEnglish } from "@ipgd-gauge/utils";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from '../../../components/table';
import XIcon from "../../../icons/X";
import { getEstimationInvoicesFollowUpList, getEstimationInvoicesFollowUpTotals } from "../../../util/apis";
import { RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID } from "../../../util/constants/forms";
import { closeTotalTableSm } from "../../../util/util";
import style from './style.module.scss';

const Row = (props) => {
    const { data } = props;

    return (
        <div className={` reportsRowHeight ${style.estimationInvoicesRow}`}>
            <div title={isEnglish() ? data?.sourceDescLo : data?.sourceDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:source')}</label>
                <div><label>{isEnglish() ? data?.sourceDescLo : data?.sourceDescFo}</label></div>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:invoice_code')}</label>
                <div><label>{data?.code}</label></div>
            </div>

            <div title={formatDate(data?.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:invoice_date')}</label>
                <div><label>{formatDate(data?.dateDgr)}</label></div>
            </div>

            <div title={data?.refCode} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:invoice_ref_code')}</label>
                <div><label>{data?.refCode}</label></div>
            </div>

            <div title={isEnglish() ? data?.targetDescLo : data?.targetDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:party_customer_code_name')}</label>
                <div><label>{isEnglish() ? data?.targetDescLo : data?.targetDescFo}</label></div>
            </div>

            <div title={data?.linesCount} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:count')}</label>
                <div><label>{data?.linesCount}</label></div>
            </div>

            <div title={formatMoney(data?.totalPriceAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:total_price')}</label>
                <div><label>{formatMoney(data?.totalPriceAlc)}</label></div>
            </div>

            <div title={formatMoney(data?.discountAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:discount')}</label>
                <div><label>{formatMoney(data?.discountAlc)}</label></div>
            </div>

            <div title={formatMoney(data?.taxAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:vat')}</label>
                <div><label>{formatMoney(data?.taxAlc)}</label></div>
            </div>

            <div title={formatMoney(data?.netPriceAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:net')}</label>
                <div><label>{formatMoney(data?.netPriceAlc)}</label></div>
            </div>

            <div title={formatMoney(data?.collPayableAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationInvoices:payable_collection')}</label>
                <div><label>{formatMoney(data?.collPayableAlc)}</label></div>
            </div>

        </div>
    );
};

const Total = (props) => {

    const { t } = useTranslation("estimationInvoices");
    const { totals } = props;

    return (
        <>
            <div id="estimation-invoices-follow-up-total" className="table-total" >
                <div className="hide-total-table-mobile"><div></div></div>
                <div><div className="tableTotalLabel">{t("total")}</div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="hide-total-table-mobile"><div></div></div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoices:total_price')}</label>
                    <div id="estimation-invoices-follow-up-total-price" title={formatMoney(totals.totalPrice)}>{formatMoney(totals.totalPrice)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoices:discount')}</label>
                    <div id="estimation-invoices-follow-up-discount" title={formatMoney(totals.totalDiscount)}>{formatMoney(totals.totalDiscount)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoices:vat')}</label>
                    <div id="estimation-invoices-follow-up-vat" title={formatMoney(totals.totalTax)}>{formatMoney(totals.totalTax)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoices:net')}</label>
                    <div id="estimation-invoices-follow-up-net" title={formatMoney(totals.totalNetPrice)}>{formatMoney(totals.totalNetPrice)}</div>
                </div>
                <div className="show-total-table-mobile">
                    <label>{t('estimationInvoices:payable_collection')}</label>
                    <div id="estimation-invoices-follow-up-payable-collection" title={formatMoney(totals.totalCollPayable)}>{formatMoney(totals.totalCollPayable)}</div>
                </div>
                <button className="closeTotalMobile" onClick={() => closeTotalTableSm('estimation-invoices-follow-up-total')}>
                    <XIcon />
                </button>
            </div>
            <span id="estimation-invoices-follow-up-total-bg" className="totalMobileBg" onClick={() => closeTotalTableSm('estimation-invoices-follow-up-total')}></span>

        </>
    )
}

const EstimationInvoicesFollowUpTable = (props) => {
    const { user, tableRef, filterRef } = props;

    const { t } = useTranslation();

    const [list, setList] = useState({});
    const [totals, setTotals] = useState({});

    const columns = [
        { title: t('estimationInvoices:source'), width: '9%' },
        { title: t('estimationInvoices:invoice_code'), width: '9%' },
        { title: t('estimationInvoices:invoice_date'), width: '10%' },
        { title: t('estimationInvoices:invoice_ref_code'), width: '9%' },
        { title: t('estimationInvoices:party_customer_code_name'), width: '10%' },
        { title: t('estimationInvoices:count'), width: '6%' },
        { title: t('estimationInvoices:total_price'), width: '10%' },
        { title: t('estimationInvoices:discount'), width: '9%' },
        { title: t('estimationInvoices:vat'), width: '9%' },
        { title: t('estimationInvoices:net'), width: '9%' },
        { title: t('estimationInvoices:payable_collection'), width: '10%' }
    ];

    const _fetchData = (page, size) => {
        getEstimationInvoicesFollowUpList(page, size, filterRef?.current?.getFilterData())
            .then((response) => setList(response.data))
            .catch((error) => handleError(error, null, t));
        getEstimationInvoicesFollowUpTotals(filterRef?.current?.getFilterData())
            .then((response) => setTotals(response.data))
            .catch((error) => { });
    }

    return (
        <Table
            id="estimation-invoices-table"
            ref={tableRef}
            formId={RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.estimationInvoicesTable}
            withFilter
            getData={_fetchData}
            rowHeight={80}
            pagingData={list}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            user={user}
            reportsTable
            tableWidth={100}
            Total={(props) => <Total totals={totals} {...props} />}
            labelCardWidth="120px"

        />
    );
};

export default EstimationInvoicesFollowUpTable;