import React from "react";

function LogChatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "63.575"}
      height={props.height || "63.575"}
      viewBox="0 0 63.574 63.575"
    >
      <g
        fill="#668fff"
        data-name="Group 180158"
        transform="translate(11898.909 14414.682)"
      >
        <path
          d="M10.468 21.166a1 1 0 01-.141-.01 1.608 1.608 0 00-.216-.013 2.11 2.11 0 00-.257.015 1 1 0 01-.158.007A11.087 11.087 0 012.24 2.253 11.1 11.1 0 0121.2 10.1v.006a11.115 11.115 0 01-3.125 7.7 10.993 10.993 0 01-7.57 3.364zM10.1 1a9.086 9.086 0 00-.407 18.164 4.045 4.045 0 01.819 0A9.086 9.086 0 0010.1 1z"
          opacity="0.4"
          transform="translate(-11877.22 -14400.658)"
        ></path>
        <path
          d="M20.751 21.212A31.651 31.651 0 01-.675 12.883a1 1 0 01-.319-.843c.348-3.269 2.438-6.347 5.885-8.667A28.953 28.953 0 0120.774-1a28.721 28.721 0 0115.834 4.371c3.45 2.321 5.54 5.4 5.888 8.668a1 1 0 01-.319.843 31.651 31.651 0 01-21.426 8.33zM1.064 11.758a29.654 29.654 0 0019.687 7.454 29.654 29.654 0 0019.687-7.454C40 9.266 38.26 6.894 35.494 5.032A26.712 26.712 0 0020.774 1 26.946 26.946 0 006.005 5.034C3.24 6.895 1.5 9.266 1.064 11.758z"
          data-name="Vector"
          opacity="0.34"
          transform="translate(-11887.872 -14372.318)"
        ></path>
        <path
          d="M30.787-1a31.8 31.8 0 0112.374 61.076A31.8 31.8 0 0118.414 1.5 31.589 31.589 0 0130.787-1zm0 61.575A29.787 29.787 0 101 30.787a29.821 29.821 0 0029.787 29.788z"
          data-name="Vector"
          transform="translate(-11897.909 -14413.682)"
        ></path>
      </g>
    </svg>
  );
}

export default LogChatIcon;