import { DEFAULT_ZOOM } from "@ipgd-gauge/map";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FrmDecimalInput } from "../../../components/decimal-input";
import Modal from "../../../components/modal";
import DeleteIcon from "../../../icons/Delete";
import SettingsBtnIcon from "../../../icons/SettingsBtn";
import { getTransactionForMapCompare } from "../../../util/apis";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const ComparisonConfigurationDialog = (props) => {

    const { comparisonConfigurationDialogRef, comparisonAction } = props;
    const { t } = useTranslation("estimationMap");
    const user = getUser();

    const [data, setData] = useState({});

    useEffect(() => {
        _setDefaultData();
    }, []);

    const _setDefaultData = () => {
        const data = { specifiedDistance: "0", distance: "0", zoom: DEFAULT_ZOOM };
        if (user?.estimationPolicy) {
            const policy = user.estimationPolicy;
            if (policy?.osmComparisonConfigZoom) {
                data.zoom = Number(policy?.osmComparisonConfigZoom);
            }
            // if (policy.osmHasComparisonConfigDistanceLimit) {
            //     data.specifiedDistance = policy.osmHasComparisonConfigDistanceLimit;
            //     if (policy.osmComparisonDistance) {
            //         data.distance = policy.osmComparisonDistance;
            //     }
            // }
        }
        setData({ ...data });
    }

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        if (name === "specifiedDistance" && value === "0") {
            data.distance = "0";
        }
        setData({ ...data });
    }

    const _handleOkAction = () => {
        if (comparisonAction) {
            comparisonAction(data);
        }
        comparisonConfigurationDialogRef.current.close();
    }

    return (
        <Modal
            id="comparisonConfigurationDialog"
            ref={comparisonConfigurationDialogRef}
            title={<><SettingsBtnIcon /> {t('comparison_configuration')}</>}
            withFooter
            withOkButton
            withCancelButton
            okBtnStyleAction="confirm-button"
            okBtnAction={_handleOkAction}
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.comparisonConfigurationModal}
            smallSize
        >
            <div className={`container ${style.comparisonConfigurationModalContaner}`} >
                <button
                    className={style.clearButton}
                    onClick={_setDefaultData}
                >
                    <DeleteIcon /> {t('clear')}
                </button>
                <div className='row rowSmModel'>
                    <div className="w100">
                        <label className='form-label'>{t('application_num')}</label>
                        <FrmSelectMenu
                            id="transaction"
                            isAsync={true}
                            value={data.triId}
                            key={`transaction-${data.id || 0}`}
                            api={(searchValue) => getTransactionForMapCompare(searchValue)}
                            defualtValueApi={(searchValue, id) => getTransactionForMapCompare(searchValue, id)}
                            defualtValueId={data.triId}
                            idName="id"
                            descLo={['code']}
                            descFo={['code']}
                            onChange={e => {
                                _handleDataChanged("triId", e.value)
                                _handleDataChanged("lat", e.latitude)
                                _handleDataChanged("lng", e.longitude)
                            }}
                            portalTarget={document.body}
                        />
                    </div>
                    {/* <div className="w50">
                        <label className='form-label'>{t('distance')}</label>
                        <RadioOptions
                            id="distance"
                            options={[
                                {
                                    title: t('specified'),
                                    action: () => _handleDataChanged("specifiedDistance", "1"),
                                    selected: data.specifiedDistance == "1"
                                },
                                {
                                    title: t('not_specified'),
                                    action: () => _handleDataChanged("specifiedDistance", "0"),
                                    selected: data.specifiedDistance == "0"
                                },
                            ]} />
                    </div>
                    <div className="w50">
                        <label className='form-label'>KM</label>
                        <FrmDecimalInput
                            id="km-distance"
                            value={data.distance}
                            onBlur={(value) => _handleDataChanged("distance", value)}
                            allowDecimals={true}
                            minValue={0}
                            disabled={data.specifiedDistance == "0"}
                        />
                    </div> */}
                    <div className="w100">
                        <label className='form-label'>{t('zoom')}</label>
                        <FrmDecimalInput
                            id="zoom"
                            value={data.zoom}
                            onBlur={(value) => _handleDataChanged("zoom", value)}
                            allowDecimals={false}
                            minValue={0}
                            maxValue={22}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ComparisonConfigurationDialog;