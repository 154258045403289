import ActionsButton from "@ipgd-gauge/actions-button";
import { DateFromToInput } from "@ipgd-gauge/date-picker";
import Spinner from '@ipgd-gauge/spinner';
import { formatMoney, handleError, isEmpty, isEnglish, isNotEmpty, parseNumber } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useCountUp } from 'react-countup';
import { isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import ChartCard from "../../components/chart-card";
import DoughnutChart from "../../components/chart/Doughnut";
import PieChart from "../../components/chart/PieChart";
import PolarChart from "../../components/chart/PolarChart";
import FormTitle from "../../components/form-title";
import { TransactionsNumberDashFour, TransactionsNumberDashOne, TransactionsNumberDashThree } from "../../icons/DashCards";
import DashHomeIcon from "../../icons/DashHome";
import EstimationTransactionsIcon from "../../icons/EstimationTransactions";
import FiltersIcon from "../../icons/Filters";
import HighPriorityIcon from "../../icons/HighPriority";
import InspectorIcon from "../../icons/Inspector";
import LowPriorityIcon from "../../icons/LowPriority";
import PropertyTypeIcon from "../../icons/PropertyType";
import RegionIcon from "../../icons/Region";
import ReloadDashBordIcon from "../../icons/ReloadDashBord";
import StatusDashboardIconIcon from "../../icons/StatusDashboard";
import SuccessStatusIcon from "../../icons/SuccessStatus";
import TransactionStatusIcon from "../../icons/TransactionStatus";
import WaitingStatusIcon from "../../icons/WaitingStatus";
import { saveFormsFilter } from "../../store/formsFilter/actions";
import { getCurrentTime, getCustomersDashboard, getEstimatorDashboard, getInspectorsDashboard, getPropertyTypesDashboard, getRegionsDashboard, getTrnxsStatistics, getTrxStatusDashboard } from "../../util/apis";
import { COLOR_PALETTES_1, COLOR_PALETTES_2, COLOR_PALETTES_3, COLOR_PALETTES_4, COLOR_PALETTES_5, DASHBOARD_NUMBER_ANIMATION_DURATION, POLICY_LVL_SELF_SERVICE } from "../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID, RSM3242_TRANSACTIONS_STATISTICS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import { getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear, getPreviousDay, getPreviousMonth, getPreviousWeek, getPreviousYear } from "../../util/dateUtil";
import { calculateCount, handleScrollLeftShadow, hideChartWaiting, showChartWaiting } from "../../util/util";
import SpecialPeriodModeulDialog from "../common/dialogs/special-period-modeul-dialog";
import TransactionsStatisticsFilter from './Filters';
import style from './style.module.scss';

const ALL = "ALL";
const CURRENT_DAY = "CURRENT_DAY";
const CURRENT_WEEK = "CURRENT_WEEK";
const CURRENT_MONTH = "CURRENT_MONTH";
const CURRENT_YEAR = "CURRENT_YEAR";
const SPECIAL_PERIOD = "SPECIAL_PERIOD";

const CountUpHook = ({ id, value, formattedValue }) => {
    useCountUp({ ref: id, end: value, duration: DASHBOARD_NUMBER_ANIMATION_DURATION });
    return formattedValue || <span id={id} />;
};

const Card = (props) => {
    const { id, title, value, formattedValue, icon, details, titleValue } = props;

    return (
        <div className={style.summaryCard}>
            <div class={style.titlePanel}>
                <div>
                    <label className={style.title} title={title}>{title}</label>
                    <label className={style.value} title={titleValue}>
                        {
                            value == null ?
                                <Spinner className={style.customSpinner} />
                                :
                                <CountUpHook id={id} value={value} formattedValue={formattedValue} key={value} />
                        }
                    </label>
                </div>
                {icon}
            </div>
            {details}
        </div>
    )
}

const TransactionsFiltersBtn = (props) => {
    const { filterData, setFilterData, currentDate, fetchData, specialPeriodModeulDialogRef } = props;

    const { t } = useTranslation("transactionsStatistics");

    const _handleFilterDataChange = (name, value) => {
        if (filterData[name] == value) {
            return;
        }
        filterData[name] = value;

        if (value === CURRENT_DAY) {
            const date = getCurrentDay(currentDate);
            filterData.fromDate = date[0];
            filterData.toDate = date[1];
        } else if (value === CURRENT_WEEK) {
            const date = getCurrentWeek(currentDate);
            filterData.fromDate = date[0];
            filterData.toDate = date[1];
        } else if (value === CURRENT_MONTH) {
            const date = getCurrentMonth(currentDate);
            filterData.fromDate = date[0];
            filterData.toDate = date[1];
        } else if (value === CURRENT_YEAR) {
            const date = getCurrentYear(currentDate);
            filterData.fromDate = date[0];
            filterData.toDate = date[1];
        } else if (value === ALL) {
            filterData.fromDate = null;
            filterData.toDate = null;
        }

        setFilterData({ ...filterData });

        if (value !== SPECIAL_PERIOD) {
            fetchData();
        }
    }

    return (
        <div className={style.optionsMsg}>
            <button
                className={filterData?.dataFormat === CURRENT_DAY ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_DAY)}>
                {t('current_day')}
            </button>

            <button
                className={filterData?.dataFormat === CURRENT_WEEK ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_WEEK)}>
                {t('current_week')}
            </button>

            <button
                className={filterData?.dataFormat === CURRENT_MONTH ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_MONTH)}>
                {t('current_month')}
            </button>

            <button
                className={filterData?.dataFormat === CURRENT_YEAR ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_YEAR)}>
                {t('current_year')}
            </button>

            <button
                className={filterData?.dataFormat === ALL ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", ALL)}>
                {t('all')}
            </button>

            <button
                className={filterData?.dataFormat === SPECIAL_PERIOD ? style.active : ''}
                onClick={() => {
                    _handleFilterDataChange("dataFormat", SPECIAL_PERIOD)
                    // specialPeriodModeulDialogRef?.current?.open();
                }}>
                {t('special_period')}
            </button>

            {filterData?.dataFormat === SPECIAL_PERIOD &&
                <div className={style.optionsDate}>
                    <DateFromToInput
                        id="transactions-statistics-filter-from-to-date"
                        fromValue={filterData.fromDate}
                        toValue={filterData.toDate}
                        fromOnChange={(value) => _handleFilterDataChange('fromDate', value)}
                        toOnChange={(value) => _handleFilterDataChange('toDate', value)}
                    />
                </div>
            }
        </div>
    )
}

const TransactionsStatistics = (props) => {
    const { t } = useTranslation("transactionsStatistics");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = getUser();

    const DEFAULT_VALUES = {
        trnxStatistics: {},
        customers: [],
        transactionStatus: [],
        regions: [],
        inspectors: [],
        propertyTypes: [],
        estimator: [],
    };

    const DEFAULT_FILTER_VALUES = {
        dataFormat: CURRENT_WEEK,
        fromDate: null,
        toDate: null,
    };

    const [data, setData] = useState(DEFAULT_VALUES);
    const [filterData, setFilterData] = useState(DEFAULT_FILTER_VALUES);

    const [currentDate, setCurrentTime] = useState();

    const filterRef = useRef();
    const firestTimeRef = useRef(true);

    const selectedCustomerId = useRef(null);
    const includedCustomerIds = useRef([]);

    const selectedTransactionStatusId = useRef(null);
    const includedTransactionStatusIds = useRef([]);

    const selectedRegionId = useRef(null);
    const includedRegionIds = useRef([]);
    const specialPeriodModeulDialogRef = useRef();
    const selectedInspectorId = useRef(null);
    const includedInspectorIds = useRef([]);

    const selectedPropertyTypeId = useRef(null);
    const includedPropertyTypeIds = useRef([]);

    const selectedEstimatorId = useRef(null);
    const includedEstimatorIds = useRef([]);

    // useEffect(() => {
    //     getCurrentTime()
    //         .then((response) => {
    //             setCurrentTime(response.data);
    //             const currentWeek = getCurrentWeek(response.data);
    //             filterData.fromDate = currentWeek[0];
    //             filterData.toDate = currentWeek[1];
    //             setFilterData({ ...filterData });
    //             _fetchData();
    //         })
    //         .catch((error) => handleError(error, null, t))
    // }, []);

    const _handleDataChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _addValueToLabels = (list) => {
        for (let index = 0; index < list.length; index++) {
            list[index].descLo += (' (' + list[index].count + ')');
            list[index].descFo += isIOS ? (' (' + list[index].count + ')') : (' )' + list[index].count + '(');
        }

        return list;
    }

    const _getPreviousDate = (tempFilterData) => {
        let date = null;
        if (tempFilterData.dataFormat === CURRENT_DAY) {
            date = getPreviousDay(currentDate);
        } else if (tempFilterData.dataFormat === CURRENT_WEEK) {
            date = getPreviousWeek(currentDate);
        } else if (tempFilterData.dataFormat === CURRENT_MONTH) {
            date = getPreviousMonth(currentDate);
        } else if (tempFilterData.dataFormat === CURRENT_YEAR) {
            date = getPreviousYear(currentDate);
        } else {
            date = [];
        }

        if (date.length == 2) {
            tempFilterData.fromDate = date[0];
            tempFilterData.toDate = date[1];
            return tempFilterData;
        } else {
            return null;
        }
    }

    const _getTrnxsStatistics = (tempFilterData) => {
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            return;
        }
        let apis = [getTrnxsStatistics(tempFilterData)];
        if (tempFilterData.dataFormat === CURRENT_DAY || tempFilterData.dataFormat === CURRENT_WEEK || tempFilterData.dataFormat === CURRENT_MONTH || tempFilterData.dataFormat === CURRENT_YEAR) {
            apis.push(getTrnxsStatistics(_getPreviousDate(tempFilterData)));
        }

        Promise.all(apis)
            .then((response) => {
                if (response.length > 1) {
                    let previousFinalPrice = response[1].data.finalPrice;
                    let previousNumberOfTrnxs = response[1].data.numberOfTrnxs;

                    let currentFinalPrice = response[0].data.finalPrice;
                    let currentNumberOfTrnxs = response[0].data.numberOfTrnxs;

                    if (previousFinalPrice && previousFinalPrice != 0) {
                        response[0].data.finalPricePerc = ((currentFinalPrice - previousFinalPrice) / previousFinalPrice) * 100;
                    }

                    if (previousNumberOfTrnxs && previousNumberOfTrnxs != 0) {
                        response[0].data.numberOfTrnxsPerc = ((currentNumberOfTrnxs - previousNumberOfTrnxs) / previousNumberOfTrnxs) * 100;
                    }
                }
                _handleDataChange("trnxStatistics", response[0].data)
            })
            .catch((error) => handleError(error, null, t)).finally(() => {/*hideWaiting("customerId")*/ });
    }

    const _getCustomersDashboard = (tempFilterData) => {
        showChartWaiting("customerId", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("customerId");
            return;
        }
        getCustomersDashboard(tempFilterData)
            .then((response) => _handleDataChange("customers", _addValueToLabels(response.data)))
            .catch((error) => {
                handleError(error, null, t)
            }
            )
            .finally(() => hideChartWaiting("customerId"));
    }

    const _getTrxStatusDashboard = (tempFilterData) => {
        showChartWaiting("transactionStatusId", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("transactionStatusId");
            return;
        }
        getTrxStatusDashboard(tempFilterData)
            .then((response) => _handleDataChange("transactionStatus", _addValueToLabels(response.data)))
            .catch((error) => {
                handleError(error, null, t)
            }
            )
            .finally(() => hideChartWaiting("transactionStatusId"));
    }

    const _getRegionsDashboard = (tempFilterData) => {
        showChartWaiting("regionId", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("regionId");
            return;
        }
        getRegionsDashboard(tempFilterData)
            .then((response) => _handleDataChange("regions", _addValueToLabels(response.data)))
            .catch((error) => {
                handleError(error, null, t)
            }
            )
            .finally(() => hideChartWaiting("regionId"));
    }

    const _getInspectorsDashboard = (tempFilterData) => {
        showChartWaiting("inspectorId", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("inspectorId");
            return;
        }
        getInspectorsDashboard(tempFilterData)
            .then((response) => _handleDataChange("inspectors", _addValueToLabels(response.data)))
            .catch((error) => {
                handleError(error, null, t)
            }
            )
            .finally(() => hideChartWaiting("inspectorId"));
    }

    const _getPropertyTypesDashboard = (tempFilterData) => {
        showChartWaiting("propertyTypeId", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("propertyTypeId");
            return;
        }
        getPropertyTypesDashboard(tempFilterData)
            .then((response) => _handleDataChange("propertyTypes", _addValueToLabels(response.data)))
            .catch((error) => {
                handleError(error, null, t)
            }
            )
            .finally(() => hideChartWaiting("propertyTypeId"));
    }

    const _getEstimatorDashboard = (tempFilterData) => {
        showChartWaiting("estimatorId", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("estimatorId");
            return;
        }
        getEstimatorDashboard(tempFilterData)
            .then((response) => _handleDataChange("estimator", _addValueToLabels(response.data)))
            .catch((error) => {
                handleError(error, null, t)
            }
            )
            .finally(() => hideChartWaiting("estimatorId"));
    }

    const _handleSelect = (name, id) => {
        if (name === "customerId") {
            if (isEmpty(id) || selectedCustomerId.current == id) {
                return;
            }
            selectedCustomerId.current = id;
        } else if (name === "transactionStatusId") {
            if (isEmpty(id) || selectedTransactionStatusId.current == id) {
                return;
            }
            selectedTransactionStatusId.current = id;
        } else if (name === "regionId") {
            if (isEmpty(id) || selectedRegionId.current == id) {
                return;
            }
            selectedRegionId.current = id;
        } else if (name === "inspectorId") {
            if (isEmpty(id) || selectedInspectorId.current == id) {
                return;
            }
            selectedInspectorId.current = id;
        } else if (name === "propertyTypeId") {
            if (isEmpty(id) || selectedPropertyTypeId.current == id) {
                return;
            }
            selectedPropertyTypeId.current = id;
        } else if (name === "estimatorId") {
            if (isEmpty(id) || selectedEstimatorId.current == id) {
                return;
            }
            selectedEstimatorId.current = id;
        }
        _fetchData();
    }

    const _handleClearIds = (name) => {
        if (name === "customerId") {
            selectedCustomerId.current = null;
            includedCustomerIds.current = null;
        } else if (name === "transactionStatusId") {
            selectedTransactionStatusId.current = null;
            includedTransactionStatusIds.current = null;
        } else if (name === "regionId") {
            selectedRegionId.current = null;
            includedRegionIds.current = null;
        } else if (name === "inspectorId") {
            selectedInspectorId.current = null;
            includedInspectorIds.current = null;
        } else if (name === "propertyTypeId") {
            selectedPropertyTypeId.current = null;
            includedPropertyTypeIds.current = null;
        } else if (name === "estimatorId") {
            selectedEstimatorId.current = null;
            includedEstimatorIds.current = null;
        }
        _fetchData();
    }

    const canResetAll = () => (
        (isNotEmpty(selectedCustomerId.current) || (includedCustomerIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedTransactionStatusId.current) || (includedTransactionStatusIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedRegionId.current) || (includedRegionIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedInspectorId.current) || (includedInspectorIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedPropertyTypeId.current) || (includedPropertyTypeIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedEstimatorId.current) || (includedEstimatorIds.current || [])?.length > 0))

    const resetDashboard = () => {
        selectedCustomerId.current = null;
        includedCustomerIds.current = null;
        selectedTransactionStatusId.current = null;
        includedTransactionStatusIds.current = null;
        selectedRegionId.current = null;
        includedRegionIds.current = null;
        selectedInspectorId.current = null;
        includedInspectorIds.current = null;
        selectedPropertyTypeId.current = null;
        includedPropertyTypeIds.current = null;
        selectedEstimatorId.current = null;
        includedEstimatorIds.current = null;
        _fetchData();
    }

    const _getFilterdList = (origin, id, includedIds, filter) => {
        if (isNotEmpty(id)) {
            if (typeof id == "object") {
                return id
            }
            return [id];
        } else if (includedIds?.length > 0 && !origin) {
            return includedIds;
        } else {
            return filter || [];
        }
    }

    const _fetchData = async () => {
        const filterLists = filterRef.current.getFilterData();

        if (firestTimeRef.current && (!filterData.fromDate || !filterData.toDate)) {
            await getCurrentTime()
                .then((response) => {
                    setCurrentTime(response.data);
                    const currentWeek = getCurrentWeek(response.data);
                    filterData.fromDate = currentWeek[0];
                    filterData.toDate = currentWeek[1];
                    setFilterData({ ...filterData });
                    firestTimeRef.current = false;
                })
                .catch((error) => firestTimeRef.current = false);
        }

        const tempIds = {
            customerIds: _getFilterdList(false, selectedCustomerId.current, includedCustomerIds.current || [], filterLists.customerIds),
            statuses: _getFilterdList(false, selectedTransactionStatusId.current, includedTransactionStatusIds.current || [], filterLists.statuses),
            regionIds: _getFilterdList(false, selectedRegionId.current, includedRegionIds.current || [], filterLists.regionIds),
            inspectorIds: _getFilterdList(false, selectedInspectorId.current, includedInspectorIds.current || [], filterLists.inspectorIds),
            propertyTypeIds: _getFilterdList(false, selectedPropertyTypeId.current, includedPropertyTypeIds.current || [], filterLists.propertyTypeIds),
            estimatorIds: _getFilterdList(false, selectedEstimatorId.current, includedEstimatorIds.current || [], filterLists.estimatorIds),
        };

        const tempFilterData = { ...filterData, ...tempIds };
        const customerFilterData = { ...tempFilterData, ...{ customerIds: _getFilterdList(true, selectedCustomerId.current, includedCustomerIds.current || [], filterLists.customerIds) } };
        const transactionStatusFilterData = { ...tempFilterData, ...{ statuses: _getFilterdList(true, selectedTransactionStatusId.current, includedTransactionStatusIds.current || [], filterLists.statuses) } };
        const regionFilterData = { ...tempFilterData, ...{ regionIds: _getFilterdList(true, selectedRegionId.current, includedRegionIds.current || [], filterLists.regionIds) } };
        const inspectorFilterData = { ...tempFilterData, ...{ inspectorIds: _getFilterdList(true, selectedInspectorId.current, includedInspectorIds.current || [], filterLists.inspectorIds) } };
        const propertyTypeFilterData = { ...tempFilterData, ...{ propertyTypeIds: _getFilterdList(true, selectedPropertyTypeId.current, includedPropertyTypeIds.current || [], filterLists.propertyTypeIds) } };
        const estimatorFilterData = { ...tempFilterData, ...{ estimatorIds: _getFilterdList(true, selectedEstimatorId.current, includedEstimatorIds.current || [], filterLists.estimatorIds) } };

        _getCustomersDashboard(customerFilterData);
        _getTrxStatusDashboard(transactionStatusFilterData);
        _getRegionsDashboard(regionFilterData);
        _getInspectorsDashboard(inspectorFilterData);
        _getPropertyTypesDashboard(propertyTypeFilterData);
        _getEstimatorDashboard(estimatorFilterData);
        _getTrnxsStatistics(tempFilterData);
    }

    const isCustomer = () => {
        if (user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE) {
            return user.customerId;
        }
        return false;
    }

    const _openTrnxForm = async () => {
        const filters = {}
        if (selectedCustomerId.current) {
            filters.atrId = [selectedCustomerId.current]
        }
        else if (includedCustomerIds.current && includedCustomerIds.current.length > 0) {
            filters.atrId = includedCustomerIds.current
        }
        else {
            filters.atrId = filterRef.current.getFilterData()?.customerIds || []
        }

        if (selectedTransactionStatusId.current) {
            filters.status = [selectedTransactionStatusId.current]
        }
        else if (includedTransactionStatusIds.current && includedTransactionStatusIds.current.length > 0) {
            filters.status = includedTransactionStatusIds.current
        }
        else {
            filters.status = filterRef.current.getFilterData()?.statuses || []
        }

        if (selectedRegionId.current) {
            filters.triFrsId = [selectedRegionId.current]
        }
        else if (includedRegionIds.current && includedRegionIds.current.length > 0) {
            filters.triFrsId = includedRegionIds.current
        }
        else {
            filters.triFrsId = filterRef.current.getFilterData()?.regionIds || []
        }

        if (selectedInspectorId.current) {
            filters.inspectorAprId = [selectedInspectorId.current]
        }
        else if (includedInspectorIds.current && includedInspectorIds.current.length > 0) {
            filters.inspectorAprId = includedInspectorIds.current
        }
        else {
            filters.inspectorAprId = filterRef.current.getFilterData()?.inspectorIds || []
        }

        if (selectedPropertyTypeId.current) {
            filters.myoId = [selectedPropertyTypeId.current]
        }
        else if (includedPropertyTypeIds.current && includedPropertyTypeIds.current.length > 0) {
            filters.myoId = includedPropertyTypeIds.current
        }
        else {
            filters.myoId = filterRef.current.getFilterData()?.propertyTypeIds || []
        }


        if (selectedEstimatorId.current) {
            filters.estimatorAprId = [selectedEstimatorId.current]
        }
        else if (includedEstimatorIds.current && includedEstimatorIds.current.length > 0) {
            filters.estimatorAprId = includedEstimatorIds.current
        }
        else {
            filters.estimatorAprId = filterRef.current.getFilterData()?.estimatorIds || []
        }

        filters.fromDate = filterData.fromDate
        filters.toDate = filterData.toDate
        dispatch(saveFormsFilter(RSM3230_VALUATION_TRANSACTIONS_FORM_ID, Object.assign({}, filters)));
        navigate("/estimation-transactions")
    }

    const getActions = () => {
        const actions = [];
        actions.push({ id: "estimation_trx_action_1", icon: <EstimationTransactionsIcon />, label: t('open_estimation_trx_list'), action: _openTrnxForm })
        if (canResetAll()) {
            actions.push({ id: "reload_dashboard_data_action_1", icon: <ReloadDashBordIcon />, label: t('reset_all_dashboards'), action: resetDashboard })
        }
        return actions;
    }

    return (
        <>
            <div className={`headerPagesPanel ${style.searchPanel}`}>
                <div className={style.searchPanelHeader}>
                    <FormTitle formId={RSM3242_TRANSACTIONS_STATISTICS_FORM_ID} />
                    <div className={style.filterPanel}>
                        <div className={style.headerButton} id="transactionsFiltersBtn" onScroll={handleScrollLeftShadow}>
                            <TransactionsFiltersBtn
                                filterData={filterData}
                                setFilterData={setFilterData}
                                currentDate={currentDate}
                                fetchData={_fetchData}
                                specialPeriodModeulDialogRef={specialPeriodModeulDialogRef}
                            />
                        </div>
                        <div className={"dashboard-header-actions"}>
                            {
                                canResetAll() &&
                                <button
                                    onClick={resetDashboard}
                                    className={"dashboard-reload-btn"}>
                                    <ReloadDashBordIcon />
                                </button>
                            }
                            <button
                                className='headerPanelBtn'
                                title={t('filter')}
                                aria-label={t('filter')}
                                onClick={() => filterRef.current?.open()} >
                                <FiltersIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <ActionsButton actions={getActions()} />
            </div>


            {/* ***************dashboards card**************** */}
            <div className={`form-templates-panel ${style.transactionsStatisticsContainer}`}>
                <div className={style.summaryContainer}>
                    <Card
                        id="transactions-number"
                        title={t("transactions_number")}
                        titleValue={data.trnxStatistics.numberOfTrnxs}
                        value={data.trnxStatistics.numberOfTrnxs}
                        icon={<div className={`${style.iconPanel} ${style.iconCard1}`}>
                            <TransactionsNumberDashOne /></div>}
                        details={
                            <div className={`${style.detailsPanel} ${style.percPanel}`}>
                                <div className={parseNumber(data?.trnxStatistics?.numberOfTrnxsPerc) >= 0 ? style.increase : style.decrease}>
                                    {parseNumber(data?.trnxStatistics?.numberOfTrnxsPerc) >= 0 &&
                                        <LowPriorityIcon />
                                        ||
                                        <HighPriorityIcon />
                                    }

                                </div>
                                <div title={`${parseNumber(data?.trnxStatistics?.numberOfTrnxsPerc) >= 0 ? '+' : '-'} %${formatMoney(Math.abs(data?.trnxStatistics?.numberOfTrnxsPerc))} ${parseNumber(data?.trnxStatistics?.numberOfTrnxsPerc) >= 0 ? t('increase') : t('decrease')} `}>{`${parseNumber(data?.trnxStatistics?.numberOfTrnxsPerc) >= 0 ? '+' : '-'} %${formatMoney(Math.abs(data?.trnxStatistics?.numberOfTrnxsPerc))}`}</div>
                                <div>{parseNumber(data?.trnxStatistics?.numberOfTrnxsPerc) >= 0 ? t('increase') : t('decrease')}</div>
                            </div>}
                    />

                    <Card
                        id='estimation-qutaion-number'
                        title={t("estimation_qutaion_number")}
                        value={data.trnxStatistics.totalQuotation}
                        titleValue={data.trnxStatistics.totalQuotation || 0}
                        icon={<div className={`${style.iconPanel} ${style.iconCard2}`}><TransactionsNumberDashFour /></div>}
                        details={
                            <div className={`${style.detailsPanel} ${style.detailsTwoLvls}`}>
                                <div>
                                    <div className={style.lblLvl}>
                                        <StatusDashboardIconIcon />
                                        <label>{t("rejected")}</label>
                                    </div>
                                    <div className={style.cardLbl}>
                                        <label title={data.trnxStatistics.rejectedQuotations || 0}>{data.trnxStatistics.rejectedQuotations || 0}</label>
                                    </div>
                                </div>
                                <div>
                                    <div className={style.lblLvl}>
                                        <WaitingStatusIcon />
                                        <label>{t("waiting")}</label>
                                    </div>
                                    <div className={style.cardLbl}>
                                        <label title={data.trnxStatistics.waitingQuotations || 0}>{data.trnxStatistics.waitingQuotations || 0}</label>
                                    </div>
                                </div>
                                <div>
                                    <div className={style.lblLvl}>
                                        <SuccessStatusIcon />
                                        <label>{t("approved")}</label>
                                    </div>
                                    <div className={style.cardLbl}>
                                        <label title={data.trnxStatistics.approvedQuotations || 0}>
                                            {data.trnxStatistics.approvedQuotations || 0}
                                        </label>
                                    </div>
                                </div>
                            </div>}
                    />

                    <Card
                        id='estimations-spaces-number'
                        title={t("estimations_spaces_number")}
                        formattedValue={<><span id='estimations-spaces-number' />{` ${isEnglish() ? 'm' : 'م'}`}<sup>2</sup></>}
                        value={data.trnxStatistics.estimationSpaces}
                        titleValue={formatMoney(data.trnxStatistics.estimationSpaces)}
                        icon={<div className={`${style.iconPanel} ${style.iconCard3}`}><TransactionsNumberDashThree /></div>}
                        details={
                            <div className={`${style.detailsPanel} ${style.detailsTwoLvls}`}>
                                <div>
                                    <div className={style.lblLvl} style={{ justifyContent: 'flex-start' }}>
                                        <label>{t("lands")}</label>
                                    </div>
                                    <div className={style.cardLbl} style={{ textAlign: 'start' }}>
                                        <label title={formatMoney(data.trnxStatistics.totalLands)}>
                                            {`${formatMoney(data.trnxStatistics.totalLands)} ${isEnglish() ? 'm' : 'م'}`}<sup>2</sup>
                                        </label>
                                    </div>
                                </div>

                                <div>
                                    <div className={style.lblLvl} style={{ justifyContent: 'flex-start' }}>
                                        <label>{t("buildings")}</label>
                                    </div>
                                    <div className={style.cardLbl} style={{ textAlign: 'start' }}>
                                        <label title={formatMoney(data.trnxStatistics.totalBuildArea)}>{`${formatMoney(data.trnxStatistics.totalBuildArea)} ${isEnglish() ? 'm' : 'م'}`}<sup>2</sup></label>
                                    </div>
                                </div>
                            </div>}
                    />

                    <Card
                        id='estimations-number'
                        title={t("estimations_number")}
                        value={data.trnxStatistics.finalPrice}
                        icon={<div className={`${style.iconPanel} ${style.iconCard4}`}><TransactionsNumberDashFour /></div>}
                        titleValue={formatMoney(data.trnxStatistics.finalPrice || 0)}
                        details={
                            <div className={`${style.detailsPanel} ${style.percPanel}`}>
                                <div className={parseNumber(data?.trnxStatistics?.finalPricePerc) >= 0 ? style.increase : style.decrease}>
                                    {parseNumber(data?.trnxStatistics?.finalPricePerc) >= 0 &&
                                        <LowPriorityIcon />
                                        ||
                                        <HighPriorityIcon />
                                    }
                                </div>
                                <div title={`${parseNumber(data?.trnxStatistics?.finalPricePerc) >= 0 ? '+' : '-'} ${formatMoney(Math.abs(data?.trnxStatistics?.finalPricePerc))} ${parseNumber(data?.trnxStatistics?.finalPricePerc) >= 0 ? t('increase') : t('decrease')}`}>{`${parseNumber(data?.trnxStatistics?.finalPricePerc) >= 0 ? '+' : '-'} ${formatMoney(Math.abs(data?.trnxStatistics?.finalPricePerc))}`}</div>
                                <div >{parseNumber(data?.trnxStatistics?.finalPricePerc) >= 0 ? t('increase') : t('decrease')}</div>
                            </div>}
                    />
                </div>
                <div className="container">
                    <div className={`row ${style.smContainer}`}>
                        <ChartCard
                            id="customerId"
                            title={t("customer")}
                            icons={<DashHomeIcon />}
                            chartName="customerChart"
                            taskDescription="customerChart"
                            handleClearIds={_handleClearIds}
                            selectedId={selectedCustomerId}
                            includedIds={includedCustomerIds}
                            count={calculateCount(data.customers || [], includedCustomerIds.current || [])}
                        >
                            <PieChart
                                colorPalettes={COLOR_PALETTES_1}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                list={data.customers || []}
                                selectedId={selectedCustomerId}
                                includedIds={includedCustomerIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('customerId', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            taskDescription="transactionStatus"
                            chartName="statusChart"
                            id="transactionStatusId"
                            title={t("transaction_status")}
                            icons={<TransactionStatusIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedTransactionStatusId}
                            includedIds={includedTransactionStatusIds}
                            count={calculateCount(data.transactionStatus || [], includedTransactionStatusIds.current || [])}
                        >
                            <PolarChart
                                colorPalettes={COLOR_PALETTES_2}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                selectedId={selectedTransactionStatusId}
                                valueName="count"
                                list={data.transactionStatus || []}
                                includedIds={includedTransactionStatusIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('transactionStatusId', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="regionId"
                            title={t("region")}
                            icons={<RegionIcon />}
                            chartName="regionChart"
                            handleClearIds={_handleClearIds}
                            selectedId={selectedRegionId}
                            includedIds={includedRegionIds}
                            count={calculateCount(data.regions || [], includedRegionIds.current || [])}
                        >
                            <DoughnutChart
                                colorPalettes={COLOR_PALETTES_4}
                                idName="regionChartId"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                selectedId={selectedRegionId}
                                valueName="count"
                                list={data.regions || []}
                                includedIds={includedRegionIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('regionId', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="inspectorId"
                            title={t("inspector")}
                            icons={<InspectorIcon />}
                            chartName="inspectorChart"
                            handleClearIds={_handleClearIds}
                            selectedId={selectedInspectorId}
                            includedIds={includedInspectorIds}
                            count={calculateCount(data.inspectors || [], includedInspectorIds.current || [])}
                            cardStyle={isCustomer() ? style.hideCard : ''}
                        >
                            <DoughnutChart
                                colorPalettes={COLOR_PALETTES_3}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                selectedId={selectedInspectorId}
                                list={data.inspectors || []}
                                includedIds={includedInspectorIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('inspectorId', id)}

                            />
                        </ChartCard>

                        <ChartCard
                            id="propertyTypeId"
                            title={t("property_type")}
                            icons={<PropertyTypeIcon />}
                            chartName="propertyType"
                            handleClearIds={_handleClearIds}
                            selectedId={selectedPropertyTypeId}
                            includedIds={includedPropertyTypeIds}
                            count={calculateCount(data.propertyTypes || [], includedPropertyTypeIds.current || [])}
                        >
                            <DoughnutChart
                                colorPalettes={COLOR_PALETTES_4}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                selectedId={selectedPropertyTypeId}
                                list={data.propertyTypes || []}
                                includedIds={includedPropertyTypeIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('propertyTypeId', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="estimatorId"
                            title={t("estimator")}
                            icons={<InspectorIcon />}
                            chartName="estimatorChart"
                            handleClearIds={_handleClearIds}
                            selectedId={selectedEstimatorId}
                            includedIds={includedEstimatorIds}
                            count={calculateCount(data.estimator || [], includedEstimatorIds.current || [])}
                            cardStyle={isCustomer() ? style.hideCard : ''}
                        >
                            <PolarChart
                                colorPalettes={COLOR_PALETTES_5}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                selectedId={selectedEstimatorId}
                                list={data.estimator || []}
                                includedIds={includedEstimatorIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('estimatorId', id)}
                            />
                        </ChartCard>
                    </div>
                </div>
            </div>

            <TransactionsStatisticsFilter ref={filterRef} fetchData={_fetchData} setCurrentTime={setCurrentTime} />
            <SpecialPeriodModeulDialog
                specialPeriodModeulDialogRef={specialPeriodModeulDialogRef}
                filterData={filterData}
                setFilterData={setFilterData}
                okAction={() => {
                    _fetchData()
                    specialPeriodModeulDialogRef?.current?.close();
                }}
                formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            />
        </>
    );
}

export default TransactionsStatistics;