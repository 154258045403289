import React from "react";

function OutboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.52"
      height="21.501"
      viewBox="0 0 21.52 21.501"
    >
      <g
        fill="#292d32"
        data-name="Group 176485"
        transform="translate(-621.23 -187.249)"
      >
        <path
          d="M1357.219-9049.719l-2-2a.75.75 0 010-1.062.753.753 0 01.532-.218.752.752 0 01.53.218l.718.719v-5.187a.751.751 0 01.75-.751.75.75 0 01.75.751v5.189l.721-.721a.752.752 0 011.059 0 .75.75 0 010 1.062l-2 2a.749.749 0 01-.531.22.749.749 0 01-.529-.22z"
          data-name="Union 13"
          opacity="0.4"
          transform="rotate(180 994.874 -4431.125)"
        ></path>
        <path
          d="M11.79 4.75H8.26A2.738 2.738 0 015.8 3.225L4.629.885A.238.238 0 004.41.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h4.41a1.736 1.736 0 011.561.965l1.17 2.34a1.246 1.246 0 001.119.7h3.53a1.246 1.246 0 001.119-.7l1.17-2.34A1.758 1.758 0 0115.64-.75H20a.75.75 0 01.75.75.75.75 0 01-.75.75h-4.36a.25.25 0 00-.219.135l-1.17 2.34A2.738 2.738 0 0111.79 4.75z"
          opacity="0.4"
          transform="translate(621.98 199)"
        ></path>
        <path
          d="M13 17.62H7c-2.77 0-4.647-.586-5.905-1.845S-.75 12.64-.75 9.87v-3A8.871 8.871 0 01.564 1.59 6.092 6.092 0 014.891-.742a.75.75 0 01.851.633.75.75 0 01-.633.851C1.972 1.2.75 2.921.75 6.87v3c0 2.346.447 3.886 1.405 4.845S4.654 16.12 7 16.12h6c2.346 0 3.886-.447 4.845-1.405s1.405-2.5 1.405-4.845v-3c0-3.949-1.222-5.667-4.359-6.128a.75.75 0 01-.633-.851.75.75 0 01.851-.633 6.092 6.092 0 014.327 2.332 8.871 8.871 0 011.314 5.28v3c0 2.77-.586 4.647-1.845 5.905S15.77 17.62 13 17.62z"
          data-name="Vector"
          transform="translate(622 191.13)"
        ></path>
      </g>
    </svg>
  );
}

export default OutboxIcon;