import style from './style.module.scss';

const LoadingSpinner = (props) => {
    const { id, className } = props;

    return (
        <div className={style.container}>
            <div id={`${id}-id`} className={`${style.loadingContainer} ${className}`}></div>
        </div>
    )
}

export default LoadingSpinner;