import React from "react";

function LogOutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "20.54"}
      height={props.height || "20.54"}
      viewBox="0 0 20.5 20.54"
    >
      <g fill="#fff" data-name="Group 178469">
        <path
          d="M1856.24 961.28h-.13a7.638 7.638 0 01-4.952-1.332 5.929 5.929 0 01-1.995-4.338.75.75 0 011.494-.14 4.481 4.481 0 001.438 3.306 6.375 6.375 0 004.015 1h.13c2.075 0 3.434-.392 4.276-1.234s1.234-2.2 1.234-4.276v-6.516c0-2.075-.392-3.434-1.234-4.276s-2.201-1.234-4.276-1.234h-.13a6.346 6.346 0 00-4.035 1.017 4.551 4.551 0 00-1.428 3.367.75.75 0 01-.812.683.75.75 0 01-.683-.812c.348-3.98 2.489-5.755 6.958-5.755h.13c2.5 0 4.195.532 5.337 1.673s1.673 2.838 1.673 5.337v6.52c0 2.5-.532 4.195-1.673 5.337s-2.838 1.673-5.337 1.673z"
          transform="translate(-1842.75 -940.74)"
        ></path>
        <g data-name="Group 656" opacity="0.4">
          <path
            d="M762.999 456.75h-11.38a.75.75 0 01-.75-.75.75.75 0 01.75-.75h11.38a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(-1842.75 -940.74) translate(1093 495)"
          ></path>
          <path
            d="M753.85 460.1a.748.748 0 01-.53-.22l-3.35-3.35a.75.75 0 010-1.061l3.35-3.349a.75.75 0 011.061 0 .75.75 0 01-.001 1.06l-2.819 2.82 2.819 2.82a.75.75 0 01-.53 1.28z"
            data-name="Vector"
            transform="translate(-1842.75 -940.74) translate(1093 495)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default LogOutIcon;