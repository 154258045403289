import React from "react";

function ReadMessagesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.372"
      height="16.873"
      viewBox="0 0 24.372 16.873"
    >
      <g data-name="Group 175683">
        <g data-name="Group 175682">
          <path
            fill="#f6b445"
            d="M13.83 8.575l5.262 4.363.707.607a5.894 5.894 0 001.118-3.445v-5a5.849 5.849 0 00-.3-1.776l-.564.406z"
            data-name="Path 6427"
            transform="translate(3.455 .832)"
          ></path>
          <path
            fill="#fed049"
            d="M1.778 3.09l7.674 5.969a4.059 4.059 0 004.988 0l7.7-5.992.841-.607A5.916 5.916 0 0018.2 0H5.7A5.916 5.916 0 00.957 2.4z"
            data-name="Path 6428"
            transform="translate(.239)"
          ></path>
          <path
            fill="#f6a645"
            d="M20.858 13.088l-5.574-4.622a5.9 5.9 0 01-7.162 0l-6.193 5.105a5.881 5.881 0 003.526 1.187h12.5a5.879 5.879 0 003.5-1.162z"
            data-name="Path 6429"
            transform="translate(.482 2.115)"
          ></path>
          <path
            fill="#f6b445"
            d="M.839 3.725l-.522-.437A5.831 5.831 0 000 5.115v5a5.887 5.887 0 001.1 3.422l.729-.594 5.256-4.358z"
            data-name="Path 6430"
            transform="translate(0 .821)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ReadMessagesIcon;