import { formatMoney } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import EstimationMapIcon from "../../../../icons/EstimationMap";
import style from './style.module.scss';

const Totals = (props) => {
    const { totals } = props;
    const { t } = useTranslation("customersEstimationInvoices");

    return (
        <div className={style.totalsTrx}>
            <div>
                <div className='totalsLbl'>
                    <label>{t('count')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{totals?.count}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('total_price')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalPriceAlc)}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('discount')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalDiscountAlc)}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('vat')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalVatAlc)}</label>
                    </div>
                </div>
            </div>

            <div>
                <div className='totalsLbl'>
                    <label>{t('estimatorsInspectorsInvoices:net_price')}</label>
                </div>
                <div className='totalsContainerVal'>
                    <div className='totalsVal'>
                        <label>{formatMoney(totals?.totalNetPriceAlc)}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Summary = (props) => {
    const { t } = useTranslation();
    const { totals } = props;

    return (
        <div className={`summary-container ${style.summaryContainer}`}>
            <div className={`summary-header-title ${style.headerTitle}`}>
                <div className="header-title-panel">
                    <span><EstimationMapIcon /></span>
                    <label>{t('customersEstimationInvoices:summary')}</label>
                </div>
            </div>
            <div className="summaryContainerWithTotal">
                <Totals totals={totals} />
            </div>
        </div>

    );
}

export default Summary;