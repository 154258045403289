import Checkbox from "@ipgd-gauge/checkbox";
import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import { getDomainValues } from "../../util/apis";
import { CUSTOMER_LEVEL_DOMAIN, DATE_LEVEL_DOMAIN, DATE_LEVEL_DOMAIN_ALL } from "../../util/constants";
import { RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID } from "../../util/constants/forms";
import style from './style.module.scss';

const commonId = "estimation-fees-commissions-calculation";

const Tab1 = (props) => {
    const { tab, data, setData } = props;

    const { t } = useTranslation('estimationFees');

    const _handleDataChange = (name, value) => {
        data[name] = value;

        if (name === "customerLevel") {
            data.customers = [];
        } else if (name === "partyLevel") {
            data.parties = [];
        }

        if (name === "dateLevel" && value == DATE_LEVEL_DOMAIN_ALL) {
            data.fromDate = null;
            data.toDate = null;
        }

        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? style.activeEstimation : style.inActiveEstimation}>
            <div className={style.header}>
                <div className={style.title}>
                    <FormTitle formId={RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID} />
                </div>
            </div>
            <div className='container'>
                <div className={`row  rowSmModel ${style.containerRow}`}>
                    <div className="w100">
                        <label className='form-label mandatory'>{t('organization')}</label>
                        <FrmInput
                            id={`${commonId}-tab-1-organization`}
                            value={isEnglish() ? data?.orgDescLo : data?.orgDescFo}
                            disabled={true}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${commonId}-tab-1-date-level-lbl`} className='form-label mandatory'>{t('date_level')}</label>
                        <FrmSelectMenu
                            id={`${commonId}-tab-1-date-level`}
                            api={() => getDomainValues(DATE_LEVEL_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data.dateLevel}
                            onChange={value => _handleDataChange("dateLevel", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50"></div>

                    <div className="w50">
                        <label id={`${commonId}-tab-1-from-date-lbl`} className='form-label mandatory'>{t('from_date')}</label>
                        <FrmTimeDatePickr
                            id={`${commonId}-tab-1-from-date`}
                            value={data.fromDate}
                            onChange={(value) => _handleDataChange("fromDate", value)}
                            disabled={data.dateLevel == DATE_LEVEL_DOMAIN_ALL}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${commonId}-tab-1-to-date-lbl`} className='form-label mandatory'>{t('to_date')}</label>
                        <FrmTimeDatePickr
                            id={`${commonId}-tab-1-to-date`}
                            value={data.toDate}
                            onChange={(value) => _handleDataChange("toDate", value)}
                            disabled={data.dateLevel == DATE_LEVEL_DOMAIN_ALL}
                        />
                    </div>

                    <div className="w50" style={{ margin: '15px 0' }}>
                        <Checkbox
                            id={`${commonId}-tab-1-ignore-previous-values`}
                            label={t('ignore_previous_values')}
                            isChecked={data?.ignorePreviousValues}
                            setIsChecked={value => _handleDataChange("ignorePreviousValues", value)}
                        />
                    </div>

                    <div className="w50"></div>

                    <div className="w50">
                        <label id={`${commonId}-tab-1-customer-level-lbl`} className='form-label mandatory'>{t('customer_level')}</label>
                        <FrmSelectMenu
                            id={`${commonId}-tab-1-customer-level`}
                            
                            api={() => getDomainValues(CUSTOMER_LEVEL_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data.customerLevel}
                            onChange={value => _handleDataChange("customerLevel", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label id={`${commonId}-tab-1-party-level-lbl`} className='form-label mandatory'>{t('party_level')}</label>
                        <FrmSelectMenu
                            id={`${commonId}-tab-1-party-level`}
                            
                            api={() => getDomainValues(CUSTOMER_LEVEL_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={data.partyLevel}
                            onChange={value => _handleDataChange("partyLevel", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;