import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../../components/table";
import FieldIcon from "../../../../icons/Field";
import { ALERT_TYPE_UPDATE_DATA, UPDATED_FIELDS_ALL_FIELDS, UPDATED_FIELDS_MANDATORY_FIELDS, UPDATED_FIELDS_SPECIFIC_FIELDS } from "../../../../util/constants";
import { getUser } from "../../../../util/cookies";
import style from './style.module.scss';

const Tab2 = (props) => {

    const { tab, data, setData, handleDataChanged, fieldsListDialogRef, formId } = props;
    const { t } = useTranslation();

    const _deleteAllEstimationForms = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.specifiedFields = [];
            setData({ ...data });
        });
    }
    return (
        <div className={tab == 2 ? 'active' : ''}>

            <div className='container'>
                <div className='row  rowSmModel'>
                    {data?.type == ALERT_TYPE_UPDATE_DATA &&
                        <div className="w100">
                            <label className='form-label'>{t('inbox:choose_fields_type')}</label>
                            <div className={style.optionsMsg}>
                                <button className={`${data?.updatedFields == UPDATED_FIELDS_MANDATORY_FIELDS ? style.active : ''}`} onClick={() => handleDataChanged("updatedFields", UPDATED_FIELDS_MANDATORY_FIELDS)}> <span><FieldIcon /></span>  {t(`inbox:domain_${UPDATED_FIELDS_MANDATORY_FIELDS}`)}</button>
                                <button className={`${data?.updatedFields == UPDATED_FIELDS_ALL_FIELDS ? style.active : ''}`} onClick={() => handleDataChanged("updatedFields", UPDATED_FIELDS_ALL_FIELDS)}> <span><FieldIcon /></span>  {t(`inbox:domain_${UPDATED_FIELDS_ALL_FIELDS}`)}</button>
                                <button className={`${data?.updatedFields == UPDATED_FIELDS_SPECIFIC_FIELDS ? style.active : ''}`} onClick={() => handleDataChanged("updatedFields", UPDATED_FIELDS_SPECIFIC_FIELDS)}> <span><FieldIcon /></span>{t(`inbox:domain_${UPDATED_FIELDS_SPECIFIC_FIELDS}`)}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {data.updatedFields == UPDATED_FIELDS_SPECIFIC_FIELDS &&
                <div className={style.fieldsListContainer}>
                    <div className='container'>
                        <div className='row  rowSmModel'>
                            <div className="w100 tableHeaderActions">
                                <button
                                    title={t('insert')}
                                    onClick={() => fieldsListDialogRef.current?.open()}
                                >{t('insert')}
                                </button>
                                <button
                                    title={t('delete_all')}
                                    className="tableHeaderDeleBtn"
                                    onClick={_deleteAllEstimationForms}
                                    disabled={(data.specifiedFields || []).length === 0}
                                >{t('delete_all')}</button>
                            </div>
                        </div>
                    </div>
                    <WorkflowTable
                        specifiedFields={data.specifiedFields || []}
                        data={data}
                        setData={setData}
                        formId={formId}
                    />
                </div>
            }
        </div>
    )
}

export default Tab2;

const WorkflowTable = (props) => {
    const { specifiedFields, data, setData, formId } = props;
    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '8%' },
        { title: <span className="mandatory">{t('propertiesPolicies:name')}</span>, width: '74%' },
        { title: "", width: '18%' },
    ];

    const _handelDeleteLine = (index) => {
        if (!data.specifiedFields) {
            data.specifiedFields = [];
        }
        data.specifiedFields.splice(index, 1);
        setData({ ...data });
    }

    return (
        <Table
            id="msg-dialog-table"
            columns={columns}
            formId={formId}
            Row={WorkflowRow}
            tableStyle={style.workflowTable}
            list={specifiedFields || []}
            rowActions={{
                handelDeleteLine: _handelDeleteLine
            }}
            user={getUser()}
            rowHeight={40}
        />
    );
}

const WorkflowRow = (props) => {
    const { virtualizedStyle, data, rowActions, index } = props;
    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${style.workflowRow}`} style={virtualizedStyle}>
            <div><div>{data.sequence}</div></div>
            <div><div>{isEnglish() ? data.descLo : data.descFo}</div></div>
            <div>
                <div>
                    <DeleteBtn onClick={() => rowActions?.handelDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};