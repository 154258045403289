import React from "react";

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 24 24"
    >
      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z"></path>
      <path d="M12 6a1 1 0 00-1 1v4.325l-3.371 2.112a1 1 0 001.062 1.7l3.84-2.4a1 1 0 00.469-.858V7a1 1 0 00-1-1z"></path>
    </svg>
  );
}

export default ClockIcon;