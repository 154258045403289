import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getRealEstateUserLOV } from "../../../util/apis";
import { FND0133_USERS_LOGIN_LOG_FORM_ID } from "../../../util/constants/forms";

const UsersLoginLogFilter = (props, ref) => {
    const { usersLogTableRef } = props;

    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            id="usersLoginLogFilter"
            ref={advanceFilterRef}
            formId={FND0133_USERS_LOGIN_LOG_FORM_ID}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => usersLogTableRef?.current?.refresh()}
            numOfRows={2}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('usersLoginLog:user')}</label>
                            <FrmSelectMenu
                                id="user-usersLog-filter"
                                value={filterData.userIds}
                                onChange={value => _handleDataChanged("userIds", value)}
                                isAsync={true}
                                defualtValueId={filterData.userIds}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.userIds, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        {/* 
                        <div className="w50">
                            <label className='form-label'>{t('usersLoginLog:online_now')}</label>
                            <FrmSelectMenu
                                id="user-usersLog-filter"
                                value={filterData.onlineNow}
                                onChange={value => _handleDataChanged("onlineNow", value)}
                                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                                descLo={['label']}
                                descFo={['label']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div> */}

                        <div className="w50">
                            <label className='form-label'>{t('usersLoginLog:login_from')}</label>
                            <FrmTimeDatePickr
                                id="login-from-date"
                                value={filterData.fromDate}
                                onChange={value => _handleDataChanged("fromDate", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('usersLoginLog:login_to')}</label>
                            <FrmTimeDatePickr
                                id="login-to-date"
                                value={filterData.toDate}
                                onChange={value => _handleDataChanged("toDate", value)}
                                enableTime
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    );
}

export default forwardRef(UsersLoginLogFilter);