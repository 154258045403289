import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, formatMoney, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteEstimationsHistory, getEstimationsHistorys } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3236_VALUATION_HISTORY_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, estimationsHistoryDialogRef, deleteAction } = rowActions;

    const { t } = useTranslation();

    return (
        <div className={`${style.estimationsHistoryRow}`}>
            <div title={data?.sequence} >
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>
            <div title={data?.refNum} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('estimationsHistory:ref_num')}</label>
                <label>{data?.refNum}</label>
            </div>
            <div title={`${(isEnglish() ? data?.cityDescLo : data?.cityDescFo) || ""} ${(isEnglish() ? data?.cityDescLo : data?.cityDescFo)?.length > 0 && (isEnglish() ? data?.districtDescLo : data?.districtDescFo)?.length > 0 ? " - " : ""} ${(isEnglish() ? data?.districtDescLo : data?.districtDescFo) || ""}`} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t(`${t('estimationsHistory:city')} - ${t('estimationsHistory:town')}`)}</label>
                <label>{`${(isEnglish() ? data?.cityDescLo : data?.cityDescFo) || ""} ${(isEnglish() ? data?.cityDescLo : data?.cityDescFo)?.length > 0 && (isEnglish() ? data?.districtDescLo : data?.districtDescFo)?.length > 0 ? " - " : ""} ${(isEnglish() ? data?.districtDescLo : data?.districtDescFo) || ""}`}</label>
            </div>
            <div title={isEnglish() ? data?.customerOfficialNameLo : data?.customerOfficialNameFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('estimationsHistory:customer')}</label>
                <label>{isEnglish() ? data?.customerOfficialNameLo : data?.customerOfficialNameFo}</label>
            </div>
            <div title={isEnglish() ? data?.propertyTypeDescLo : data?.propertyTypeDescFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('estimationsHistory:property_type')}</label>
                <label>{isEnglish() ? data?.propertyTypeDescLo : data?.propertyTypeDescFo}</label>
            </div>
            <div title={data?.desc} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('estimationsHistory:desc')}</label>
                <label>{data?.desc}</label>
            </div>
            <div title={formatMoney(data?.price)} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('estimationsHistory:price')}</label>
                <label>{formatMoney(data?.price)}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3236_VALUATION_HISTORY_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => estimationsHistoryDialogRef?.current?.open(data.id)} />
                    }
                    {isAllowedAction(user, RSM3236_VALUATION_HISTORY_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const EstimationsHistoryTable = (props) => {

    const { user, tableRef, searchTextRef, filterRef, estimationsHistoryDialogRef } = props;
    const { t } = useTranslation("estimationsHistory");
    const [data, setData] = useState();

    const columns = [
        { title: '#', width: "3%" },
        { title: t('estimationsHistory:ref_num'), width: "15%" },
        { title: `${t('estimationsHistory:city')} - ${t('estimationsHistory:town')}`, width: "15%" },
        { title: t('estimationsHistory:customer'), width: "14%" },
        { title: t('estimationsHistory:property_type'), width: "14%" },
        { title: t('estimationsHistory:desc'), width: "12%" },
        { title: t('estimationsHistory:price'), width: "10%" },
        { title: "", width: "17%" },
    ];

    const _fetchData = (page, size) => {
        getEstimationsHistorys(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (isNotEmpty(id)) {
                return deleteEstimationsHistory(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, "delete_fields", t));
            }
        });
    }

    return (
        <Slisting
            id="estimations-history-listing"
            ref={tableRef}
            formId={RSM3236_VALUATION_HISTORY_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.estimationsHistoryTable}
            rowHeight={80}
            withFilter
            getData={_fetchData}
            pagingData={data}
            rowActions={{ user, estimationsHistoryDialogRef, deleteAction: _deleteAction }}
            cardInMobile
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            newAction={() => estimationsHistoryDialogRef.current?.open()}
            deleteAction={deleteEstimationsHistory}
            viewAction={(id) => estimationsHistoryDialogRef.current?.open(id)}
            labelCardWidth="80px"
        />
    );
};

export default EstimationsHistoryTable;