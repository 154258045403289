import React from "react";


export default function PlusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width||"18.5"}
      height={props.height||"18.5"}
      viewBox="0 0 18.5 18.5"
    >
      <g
        fill="none"
        stroke={props.stroke||"#fff"}
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth={props.strokeWidth||"2.5"}
        transform="translate(-2.75 -2.75)"
      >
        <path
          d="M16 0L0 0"
          data-name="Line 5"
          transform="translate(4 12)"
        ></path>
        <path
          d="M0 16L0 0"
          data-name="Line 6"
          transform="translate(12 4)"
        ></path>
      </g>
    </svg>
  );
}

