import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { saveCustomer } from "../../util/apis";
import { RSM3009_CREATE_CUSTOMER_RSM_FORM_ID } from "../../util/constants/forms";
import CustomersDialog from "../common/dialogs/customer-dialog";
import CustomersFilter from "./listing/Filters";
import CustomersTable from "./listing/index";

const Customers = () => {

    const { t } = useTranslation('customers');
    const searchTextRef = useRef();
    const filtersRef = useRef();
    const customersDialogRef = useRef();
    const customersTableRef = useRef();

    const _openFilter = () => filtersRef.current?.open()

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM3009_CREATE_CUSTOMER_RSM_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name') + " , " + t('mobile_num') + " , " + t('email')}
                        search={() => customersTableRef.current.refresh()}
                        formId={RSM3009_CREATE_CUSTOMER_RSM_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={_openFilter}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <CustomersTable
                customersTableRef={customersTableRef}
                searchTextRef={searchTextRef}
                filtersRef={filtersRef}
                customersDialogRef={customersDialogRef}
            />

            <CustomersFilter
                customersTableRef={customersTableRef}
                ref={filtersRef}
            />

            <CustomersDialog
                ref={customersDialogRef}
                saveApi={saveCustomer}
                onSave={() => customersTableRef?.current?.refresh()}
            />
        </>
    )
}

export default Customers;