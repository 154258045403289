import ActionsButton from "@ipgd-gauge/actions-button";
import { DateFromToInput } from "@ipgd-gauge/date-picker";
import { handleError, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ChartCard from "../../components/chart-card";
import DoughnutChart from "../../components/chart/Doughnut";
import PieChart from "../../components/chart/PieChart";
import FormTitle from "../../components/form-title";
import CustomerDashIcon from "../../icons/CustomerDash";
import FiltersIcon from "../../icons/Filters";
import RegionIcon from "../../icons/Region";
import ReloadDashBordIcon from "../../icons/ReloadDashBord";
import TaskDescriptionIcon from "../../icons/TaskDescription";
import UserDashIcon from "../../icons/UserDash";
import { getCurrentTime, getTasksFollowUpDashboardCustomers, getTasksFollowUpDashboardRegions, getTasksFollowUpDashboardTasksDescription, getTasksFollowUpDashboardUsers } from "../../util/apis";
import { COLOR_PALETTES_2, COLOR_PALETTES_4, COLOR_PALETTES_6 } from "../../util/constants";
import { RSM3244_TASKS_STATISTICS_FORM_ID } from "../../util/constants/forms";
import { getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear } from "../../util/dateUtil";
import { calculateCount, handleScrollLeftShadow, hideChartWaiting, showChartWaiting } from "../../util/util";
import SpecialPeriodModeulDialog from "../common/dialogs/special-period-modeul-dialog";
import TasksStatisticsFilter from './Filters';
import style from './style.module.scss';

const ALL = "ALL";
const CURRENT_DAY = "CURRENT_DAY";
const CURRENT_WEEK = "CURRENT_WEEK";
const CURRENT_MONTH = "CURRENT_MONTH";
const CURRENT_YEAR = "CURRENT_YEAR";
const SPECIAL_PERIOD = "SPECIAL_PERIOD";

const CHART_NAME_USERS = "usersChart"
const CHART_NAME_TASK_DESC = "taskDescription"
const CHART_NAME_CUSTOMER = "customerTasksChart"
const CHART_NAME_REGION = "regionTasksChart"

const TransactionsFiltersBtn = (props) => {
    const { filterData, setFilterData, currentDate, fetchData, specialPeriodModeulDialogRef } = props;

    const { t } = useTranslation("tasksStatistics");

    const _handleFilterDataChange = (name, value) => {
        if (filterData[name] == value) {
            return;
        }

        filterData[name] = value;

        let date = [];
        if (value === CURRENT_DAY) {
            date = getCurrentDay(currentDate);
        } else if (value === CURRENT_WEEK) {
            date = getCurrentWeek(currentDate);
        } else if (value === CURRENT_MONTH) {
            date = getCurrentMonth(currentDate);
        } else if (value === CURRENT_YEAR) {
            date = getCurrentYear(currentDate);
        } else if (value === ALL) {
            filterData.fromDate = null;
            filterData.toDate = null;
        }

        if (date.length > 0) {
            filterData.fromDate = date[0];
            filterData.toDate = date[1];
        }

        setFilterData({ ...filterData });

        if (value !== SPECIAL_PERIOD) {
            fetchData();
        }
    }

    return (
        <div className={style.optionsMsg}>
            <button
                className={filterData?.dataFormat === CURRENT_DAY ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_DAY)}>
                {t('current_day')}
            </button>

            <button
                className={filterData?.dataFormat === CURRENT_WEEK ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_WEEK)}>
                {t('current_week')}
            </button>

            <button
                className={filterData?.dataFormat === CURRENT_MONTH ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_MONTH)}>
                {t('current_month')}
            </button>

            <button
                className={filterData?.dataFormat === CURRENT_YEAR ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", CURRENT_YEAR)}>
                {t('current_year')}
            </button>

            <button
                className={filterData?.dataFormat === ALL ? style.active : ''}
                onClick={() => _handleFilterDataChange("dataFormat", ALL)}>
                {t('all')}
            </button>

            <button
                className={filterData?.dataFormat === SPECIAL_PERIOD ? style.active : ''}
                onClick={() => {
                    _handleFilterDataChange("dataFormat", SPECIAL_PERIOD)
                    // specialPeriodModeulDialogRef?.current?.open();
                }}>
                {t('special_period')}
            </button>

            {filterData?.dataFormat === SPECIAL_PERIOD &&
                <div className={style.optionsDate}>
                    <DateFromToInput
                        id="transactions-statistics-filter-from-to-date"
                        fromValue={filterData.fromDate}
                        toValue={filterData.toDate}
                        fromOnChange={(value) => _handleFilterDataChange('fromDate', value)}
                        toOnChange={(value) => _handleFilterDataChange('toDate', value)}
                    />
                </div>
            }
        </div>
    )
}

const TasksStatistics = () => {
    const { t } = useTranslation("tasksStatistics");

    const DEFAULT_VALUES = {
        users: [],
        taskDesc: [],
        customers: [],
        regions: [],
    };

    const DEFAULT_FILTER_VALUES = {
        dataFormat: CURRENT_WEEK,
        fromDate: null,
        toDate: null,
    };

    const [data, setData] = useState(DEFAULT_VALUES);
    const [filterData, setFilterData] = useState(DEFAULT_FILTER_VALUES);

    const [currentDate, setCurrentTime] = useState();

    const filterRef = useRef();
    const firestTimeRef = useRef(true);

    const selectedUserId = useRef(null);
    const includedUserIds = useRef([]);

    const selectedTaskDescId = useRef(null);
    const includedTaskDescIds = useRef([]);

    const selectedCustomerId = useRef(null);
    const includedCustomerIds = useRef([]);

    const selectedRegionId = useRef(null);
    const includedRegionIds = useRef([]);

    const specialPeriodModeulDialogRef = useRef();

    const _handleDataChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _addValueToLabels = (list) => {
        for (let index = 0; index < list.length; index++) {
            list[index].descLo += (' (' + list[index].count + ')');
            list[index].descFo += isIOS ? (' (' + list[index].count + ')') : (' )' + list[index].count + '(');
        }

        return list;
    }

    const _getTasksFollowUpDashboardUsers = (tempFilterData) => {
        showChartWaiting("users", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("users");
            return;
        }
        getTasksFollowUpDashboardUsers(tempFilterData)
            .then((response) => _handleDataChange("users", _addValueToLabels(response.data)))
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("users"));
    }

    const _getTasksFollowUpDashboardTasksDescription = (tempFilterData) => {
        showChartWaiting("taskDesc", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("taskDesc");
            return;
        }
        getTasksFollowUpDashboardTasksDescription(tempFilterData)
            .then((response) => _handleDataChange("taskDesc", _addValueToLabels(response.data)))
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("taskDesc"));
    }

    const _getTasksFollowUpDashboardCustomers = (tempFilterData) => {
        showChartWaiting("customers", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("customers");
            return;
        }
        getTasksFollowUpDashboardCustomers(tempFilterData)
            .then((response) => _handleDataChange("customers", _addValueToLabels(response.data)))
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("customers"));
    }

    const _getTasksFollowUpDashboardRegions = (tempFilterData) => {
        showChartWaiting("regions", false);
        if (tempFilterData.dataFormat != ALL && !tempFilterData.fromDate && !tempFilterData.toDate) {
            hideChartWaiting("regions");
            return;
        }
        getTasksFollowUpDashboardRegions(tempFilterData)
            .then((response) => _handleDataChange("regions", _addValueToLabels(response.data)))
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("regions"));
    }

    const _handleSelect = (name, id) => {
        if (name === "users") {
            if (isEmpty(id) || selectedUserId.current == id) {
                return;
            }
            selectedUserId.current = id;
        } else if (name === "taskDesc") {
            if (isEmpty(id) || selectedTaskDescId.current == id) {
                return;
            }
            selectedTaskDescId.current = id;
        } else if (name === "customers") {
            if (isEmpty(id) || selectedCustomerId.current == id) {
                return;
            }
            selectedCustomerId.current = id;
        } else if (name === "regions") {
            if (isEmpty(id) || selectedRegionId.current == id) {
                return;
            }
            selectedRegionId.current = id;
        }

        _fetchData();
    }

    const _handleClearIds = (name) => {
        if (name === "users") {
            selectedUserId.current = null;
            includedUserIds.current = null;
        } else if (name === "taskDesc") {
            selectedTaskDescId.current = null;
            includedTaskDescIds.current = null;
        } else if (name === "customers") {
            selectedCustomerId.current = null;
            includedCustomerIds.current = null;
        } else if (name === "regions") {
            selectedRegionId.current = null;
            includedRegionIds.current = null;
        }

        _fetchData();
    }

    const canResetAll = () => (
        (isNotEmpty(selectedUserId.current) || (includedUserIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedTaskDescId.current) || (includedTaskDescIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedCustomerId.current) || (includedCustomerIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedRegionId.current) || (includedRegionIds.current || [])?.length > 0))

    const resetDashboard = () => {
        selectedUserId.current = null;
        includedUserIds.current = null;
        selectedTaskDescId.current = null;
        includedTaskDescIds.current = null;
        selectedCustomerId.current = null;
        includedCustomerIds.current = null;
        selectedRegionId.current = null;
        includedRegionIds.current = null;
        _fetchData();
    }

    const _getFilterdList = (origin, id, includedIds, filter) => {
        if (isNotEmpty(id)) {
            if (typeof id == "object") {
                return id
            }
            return [id];
        } else if (includedIds?.length > 0 && !origin) {
            return includedIds;
        } else {
            return filter || [];
        }
    }

    const _fetchData = async () => {
        const filterLists = filterRef.current.getFilterData();

        if (firestTimeRef.current && (!filterData.fromDate || !filterData.toDate)) {
            await getCurrentTime()
                .then((response) => {
                    setCurrentTime(response.data);
                    const currentWeek = getCurrentWeek(response.data);
                    filterData.fromDate = currentWeek[0];
                    filterData.toDate = currentWeek[1];
                    setFilterData({ ...filterData });
                    firestTimeRef.current = false;
                })
                .catch((error) => firestTimeRef.current = false);
        }

        const tempIds = {
            userIds: _getFilterdList(false, selectedUserId.current, includedUserIds.current || [], filterLists.userIds || []),
            taskDescIds: _getFilterdList(false, selectedTaskDescId.current, includedTaskDescIds.current || [], filterLists.taskDescIds || []),
            customerIds: _getFilterdList(false, selectedCustomerId.current, includedCustomerIds.current || [], filterLists.customerIds || []),
            regionIds: _getFilterdList(false, selectedRegionId.current, includedRegionIds.current || [], filterLists.regionIds || []),
        };

        const tempFilterData = { ...filterData, ...tempIds };
        const userFilterData = { ...tempFilterData, ...{ userIds: _getFilterdList(true, selectedUserId.current, includedUserIds.current || [], filterLists.userIds || []) } };
        const transactionStatusFilterData = { ...tempFilterData, ...{ taskDescIds: _getFilterdList(true, selectedTaskDescId.current, includedTaskDescIds.current || [], filterLists.taskDescIds || []) } };
        const customerFilterData = { ...tempFilterData, ...{ customerIds: _getFilterdList(true, selectedCustomerId.current, includedCustomerIds.current || [], filterLists.customerIds || []) } };
        const regionFilterData = { ...tempFilterData, ...{ regionIds: _getFilterdList(true, selectedRegionId.current, includedRegionIds.current || [], filterLists.regionIds || []) } };

        _getTasksFollowUpDashboardUsers(userFilterData);
        _getTasksFollowUpDashboardTasksDescription(transactionStatusFilterData);
        _getTasksFollowUpDashboardCustomers(customerFilterData);
        _getTasksFollowUpDashboardRegions(regionFilterData);
    }

    // useEffect(() => {
    //     getCurrentTime()
    //         .then((response) => {
    //             setCurrentTime(response.data);
    //             const currentWeek = getCurrentWeek(response.data);
    //             filterData.fromDate = currentWeek[0];
    //             filterData.toDate = currentWeek[1];
    //             setFilterData({ ...filterData });
    //             _fetchData();
    //         })
    //         .catch((error) => handleError(error, null, t));
    // }, []);

    const getActions = () => {
        const actions = [];
        if (canResetAll()) {
            actions.push({ id: "reload_all_dashboard_data", icon: <ReloadDashBordIcon />, label: t('reset_all_dashboards'), action: resetDashboard })
        }
        return actions;
    }

    return (
        <>
            <div className={`headerPagesPanel ${style.searchPanel}`}>
                <div className={style.searchPanelHeader}>
                    <FormTitle formId={RSM3244_TASKS_STATISTICS_FORM_ID} />
                    <div className={style.filterPanel}>
                        <div className={style.headerButton} id="transactionsFiltersBtn" onScroll={handleScrollLeftShadow}>
                            <TransactionsFiltersBtn
                                filterData={filterData}
                                setFilterData={setFilterData}
                                currentDate={currentDate}
                                fetchData={_fetchData}
                                specialPeriodModeulDialogRef={specialPeriodModeulDialogRef}
                            />
                        </div>
                        <div className={"dashboard-header-actions"}>
                            <button
                                className='headerPanelBtn'
                                title={t('filter')}
                                aria-label={t('filter')}
                                onClick={() => filterRef.current?.open()} >
                                <FiltersIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`form-templates-panel ${style.tasksStatisticsContainer}`}>
                <div className="container">
                    <div className={`row ${style.smContainer}`}>
                        <ChartCard
                            id="users"
                            title={t("user")}
                            icons={<UserDashIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedUserId}
                            includedIds={includedUserIds}
                            chartName={CHART_NAME_USERS}
                            count={calculateCount(data.users || [], includedUserIds.current || [])}
                        >
                            <PieChart
                                colorPalettes={COLOR_PALETTES_6}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                selectedId={selectedUserId}
                                valueName="count"
                                list={data.users || []}
                                includedIds={includedUserIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('users', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="taskDesc"
                            title={t("task_description")}
                            icons={<TaskDescriptionIcon />}
                            chartName={CHART_NAME_TASK_DESC}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedTaskDescId}
                            includedIds={includedTaskDescIds}
                            count={calculateCount(data.taskDesc || [], includedTaskDescIds.current || [])}
                        >
                            <PieChart
                                colorPalettes={COLOR_PALETTES_2}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                selectedId={selectedTaskDescId}
                                valueName="count"
                                list={data.taskDesc || []}
                                includedIds={includedTaskDescIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('taskDesc', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="customers"
                            title={t("customer")}
                            icons={<CustomerDashIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedCustomerId}
                            includedIds={includedCustomerIds}
                            chartName={CHART_NAME_CUSTOMER}
                            count={calculateCount(data.customers || [], includedCustomerIds.current || [])}
                        >
                            <PieChart
                                colorPalettes={COLOR_PALETTES_4}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                selectedId={selectedCustomerId}
                                list={data.customers || []}
                                includedIds={includedCustomerIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('customers', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="regions"
                            title={t("region")}
                            icons={<RegionIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedRegionId}
                            chartName={CHART_NAME_REGION}
                            includedIds={includedRegionIds}
                            count={calculateCount(data.regions || [], includedRegionIds.current || [])}
                        >
                            <DoughnutChart
                                colorPalettes={COLOR_PALETTES_4}
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                selectedId={selectedRegionId}
                                valueName="count"
                                list={data.regions || []}
                                includedIds={includedRegionIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('regions', id)}
                            />
                        </ChartCard>
                    </div>
                </div>
            </div>
            <TasksStatisticsFilter ref={filterRef} fetchData={_fetchData} />
            <ActionsButton actions={getActions()} />
            <SpecialPeriodModeulDialog
                specialPeriodModeulDialogRef={specialPeriodModeulDialogRef}
                filterData={filterData}
                setFilterData={setFilterData}
                okAction={() => {
                    _fetchData()
                    specialPeriodModeulDialogRef?.current?.close();
                }}
                formId={RSM3244_TASKS_STATISTICS_FORM_ID}
            />
        </>
    );
}

export default TasksStatistics;