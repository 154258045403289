import React from "react";

function QuestionMarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <path fill="#000" d="M13.5 19h-3v-.786c0-2.371 1.388-4.442 3.909-5.832 1.905-1.05 2.891-3.131 2.51-5.301-.352-2.003-1.997-3.648-4-4-1.513-.265-2.979.121-4.131 1.087A4.993 4.993 0 007.001 8h-3a7.986 7.986 0 012.858-6.129C8.673.347 11.074-.287 13.437.126c3.224.566 5.871 3.213 6.437 6.437.597 3.399-1.018 6.794-4.017 8.447-1.564.862-2.357 1.939-2.357 3.204V19zm0 2h-3v3h3v-3z"></path>
    </svg>
  );
}

export default QuestionMarkIcon;
