import HeaderPanel from '@ipgd-gauge/header';
import i18next from 'i18next';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { MenuItem } from 'rc-menu';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import AttentionIcon from '../../icons/Attention';
import CriticalPriorityIcon from '../../icons/CriticalPriority';
import HighPriorityIcon from '../../icons/HighPriority';
import InformationIcon from '../../icons/Information';
import LangIcon from '../../icons/Lang';
import LogoSmIcon from '../../icons/LogoSm';
import LogOutIcon from "../../icons/LogOut";
import LowPriorityIcon from '../../icons/LowPriority';
import NormalPriorityIcon from '../../icons/NormalPriority';
import RemindIcon from '../../icons/Remind';
import TrackingIcon from '../../icons/Tracking';
import UpdateIcon from '../../icons/Update';
import UserProfileIcon from '../../icons/UserProfile';
import { saveFormsFilter } from '../../store/formsFilter/actions';
import { userLogin } from '../../store/user/actions';
import { changeLanguage, getInboxMessages, headerLogoPath } from "../../util/apis";
import { ALERT_TYPE_ALERT, ALERT_TYPE_FOLLOW_UP, ALERT_TYPE_INFORMATION, ALERT_TYPE_REMINDER, ALERT_TYPE_UPDATE_DATA, WORKFLOW_PRIORITIES_CRITICAL, WORKFLOW_PRIORITIES_HIGH, WORKFLOW_PRIORITIES_LOW, WORKFLOW_PRIORITIES_NORMAL } from "../../util/constants";
import { RSM3246_INBOX_FORM_ID } from '../../util/constants/forms';
import { getToken } from '../../util/cookies';
import { formatDateNTime } from "../../util/dateUtil";
import { getFormAttributeByPath, isEnglish } from "../../util/util";
import MsgsCount from '../msgs-count';
import style from './style.module.scss';

const Header = (props) => {
    const { formAttr, logoutAction } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const lottieMessageIconRef = useRef();
    const lottiePlayingRef = useRef(false);
    const lottieTimeoutRef = useRef(null);
    const unreadedMessagesCount = useSelector(state => state?.persistedReducer?.userReducer?.user?.unreadedMsgsCount || 0);
    const currentUser = useSelector(state => state.persistedReducer.userReducer.user);

    const [lastMessages, setLastMessages] = useState([]);

    const _playLottie = () => {
        if (!lottiePlayingRef.current) {
            lottiePlayingRef.current = true;
            lottieMessageIconRef.current.play();
        } else if (lottieTimeoutRef != null) {
            clearTimeout(lottieTimeoutRef.current);
        }
        lottieTimeoutRef.current = setTimeout(() => {
            lottiePlayingRef.current = false;
            lottieMessageIconRef.current.stop();
        }, 10000);
    }

    useEffect(() => {
        if (unreadedMessagesCount && unreadedMessagesCount > 0) {
            getInboxMessages(0, 5, { status: 130402 })
                .then((response) => {
                    setLastMessages(response.data.list);
                })
            _playLottie();
        }
    }, [unreadedMessagesCount]);

    const _handleChangeLanguage = (isEn) => {
        i18next.changeLanguage(isEn ? "en" : "ar");
        changeLanguage(isEn);
        currentUser.en = isEn;
        dispatch(userLogin(currentUser, getToken()));
    }

    const _handleClickOnMessage = (message) => {
        dispatch(saveFormsFilter(`${RSM3246_INBOX_FORM_ID}-inbox-searchText`, message.code));
        navigate("inbox");
    }

    const lastMessagesMenu = () => {

        return (
            <Menu className={`${style.messagesMenu}`}>
                {lastMessages.map((message, index) => {
                    return (
                        <MenuItem key={`message-${index}`} id={`message-${index}`} onClick={() => _handleClickOnMessage(message)}>
                            <div className={isEnglish() ? style.msgTitleEn : style.msgTitleAr}>
                                {t('notification')}
                            </div>
                            <div className={`${isEnglish() ? style.messageEn : style.messageAr}`} >
                                <div className={style.msgContainer}>
                                    <label className={isEnglish() ? style.msgCodeEn : style.msgCodeAr}>
                                        <span>
                                            {message?.priority == WORKFLOW_PRIORITIES_LOW &&
                                                <LowPriorityIcon />
                                            }
                                            {message?.priority == WORKFLOW_PRIORITIES_NORMAL &&
                                                <NormalPriorityIcon />
                                            }
                                            {message?.priority == WORKFLOW_PRIORITIES_HIGH &&
                                                <HighPriorityIcon />
                                            }
                                            {message?.priority == WORKFLOW_PRIORITIES_CRITICAL &&
                                                <CriticalPriorityIcon />
                                            }
                                        </span>
                                        <label>{message.code}</label>
                                    </label>
                                    <label className={isEnglish() ? style.msgLblEn : style.msgLblAr}>{message.text}</label>
                                    <label className={isEnglish() ? style.msgLblEn : style.msgLblAr}>{formatDateNTime(message.sendingDateTime)}</label>
                                </div>
                                <div className={isEnglish() ? style.msgStatusIconEn : style.msgStatusIconAr}>
                                    <span>
                                        {message.type == ALERT_TYPE_ALERT && <RemindIcon />}
                                        {message.type == ALERT_TYPE_REMINDER && <InformationIcon />}
                                        {message.type == ALERT_TYPE_INFORMATION && <AttentionIcon />}
                                        {message.type == ALERT_TYPE_FOLLOW_UP && <TrackingIcon />}
                                        {message.type == ALERT_TYPE_UPDATE_DATA && <UpdateIcon />}
                                    </span>
                                </div>
                            </div>
                        </MenuItem>
                    )
                })
                }
                <MenuItem key={`message-5`} id={`message-5`} onClick={() => navigate("inbox")}>
                    <div
                        className={`${isEnglish() ? style.msgShowMoreEn : style.msgShowMoreAr}`}
                    >
                        <label>
                            {t('show_more')}
                        </label>
                    </div>
                </MenuItem>
            </Menu>
        )
    }

    const sectionsMenu = () => {
        return (
            <Menu className={style.dropMenuProf}>
                <div>
                    <div>
                        <MenuItem >
                            <button
                                className={`${isEnglish() ? style.dropBtnEn : style.dropBtnAr}`}
                                title={t('profile')}
                                aria-label={t('profile')}
                                onClick={() => navigate("/profile")}
                            >
                                <UserProfileIcon />
                                <label>{t('profile')}</label>
                            </button>
                        </MenuItem>


                        {isEnglish() &&
                            <MenuItem key="1">
                                <button className={`${isEnglish() ? style.dropBtnEn : style.dropBtnAr}`} onClick={() => _handleChangeLanguage(false)}>
                                    <LangIcon />
                                    <label className={isEnglish() ? style.labelEn : style.labelAr}>
                                        {t('change_language')}
                                        <span>(عربي)</span>
                                    </label>
                                </button>
                            </MenuItem>
                            ||
                            <MenuItem key="2">
                                <button className={`${isEnglish() ? style.dropBtnEn : style.dropBtnAr}`} onClick={() => _handleChangeLanguage(true)}>
                                    <LangIcon />
                                    <label className={isEnglish() ? style.labelEn : style.labelAr}>{t('change_language')}
                                        <span>(English)</span>
                                    </label>
                                </button>
                            </MenuItem>
                        }

                        <MenuItem >
                            <button
                                className={`${isEnglish() ? style.dropBtnEn : style.dropBtnAr}`}
                                title={t('logout')}
                                aria-label={t('logout')}
                                onClick={() => logoutAction(false)}
                            >
                                <LogOutIcon />
                                <label>{t('logout')}</label>

                            </button>
                        </MenuItem>
                    </div>
                </div>
            </Menu>
        )
    }

    return (
        <HeaderPanel
            handleChangeLanguage={_handleChangeLanguage}
            headerLogoPath={headerLogoPath}
            sectionsMenu={sectionsMenu}
            lastMessagesMenu={lastMessagesMenu}
            getFormAttributeByPath={getFormAttributeByPath}
            breadCrumbsKey={`${formAttr?.path}-${isEnglish()}`}
            breadCrumbsPages={[{ name: t('home'), path: '/not-executed-tasks' },
            { name: t(`${formAttr?.title}`), path: `${formAttr?.path}` }]}
            userMenuStyle={style.userMenu}
            logo={<LogoSmIcon />}
            messagesMenu={
                <Dropdown
                    minOverlayWidthMatchTrigger={true}
                    trigger={['click']}
                    overlay={lastMessagesMenu}
                    align={{ offset: [0, 0] }}
                    overlayClassName={!lastMessages || lastMessages.length <= 0 ? style.hiddenMessagesMenu : ""}
                    openClassName={style.dropdownOpen}
                >
                    <button onClick={() => !lastMessages || lastMessages.length <= 0 ? navigate("inbox") : null} >
                        <MsgsCount lottieMessageIconRef={lottieMessageIconRef} />
                    </button>
                </Dropdown>
            }
        />
    );
}

export default Header;