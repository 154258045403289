import { checkValidation, handleError, hideWaiting, isAllowedAction, isEmpty, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import UploadImg from "../../../components/upload-img";
import CustomerIcon from "../../../icons/Customer";
import { getUser, saveUser } from "../../../util/apis";
import { GAUGE_USER_SUPPLIER_GAUGE_USER_DOMAIN, NOTIFICATION_DOMAIN_DONT_SEND, USERS_CURRENT_STATUS_ON_DUTY_DOMAIN, USER_DEFINITION_PROFILE_IMAGE, USER_STATUS_ACTIVE, USER_STATUS_TERMINATED } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { FND0042_USERS_DEFINITION_FORM_ID } from '../../../util/constants/forms';
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import style from './style.module.scss';

const dialogId = "users-definition-dialog";

const UsersDefinitionDialog = (props, ref) => {
    const { user, tableRef } = props;
    const { t } = useTranslation();

    const dialogRef = useRef();
    const signatureImageRef = useRef();
    const stampImageRef = useRef();
    const userProfileRef = useRef();
    const updateRecordRef = useRef(false);

    const defaultData = {
        isActive: USER_STATUS_ACTIVE,
        currStatus: USERS_CURRENT_STATUS_ON_DUTY_DOMAIN,
        sendWfEmail: NOTIFICATION_DOMAIN_DONT_SEND,
        sendWfSms: NOTIFICATION_DOMAIN_DONT_SEND,
        type: GAUGE_USER_SUPPLIER_GAUGE_USER_DOMAIN
    }

    const [data, setData] = useState({});

    const _okBtnAction = () => {
        if (!checkValidation([{ id: "users-definition-dialog" }, {
            id: "users-definition-dialog-mater-table",
            title: t('usersDefinition:users_definition'),
            data: data?.maters,
            disabledConditions: {
                fromDgr: (data) => !data?.hasTimeLimitation,
                toDgr: (data) => !data?.hasTimeLimitation,
                frmId: () => true,
            }
        }], t)) {
            return;
        }

        if (data.pass != data.confirmPass && isEmpty(data.id)) {
            handleError(null, 'password_and_confirmation_password_do_not_match', t)
            return;
        }

        showWaiting(`${dialogId}-okButton`, true);
        saveUser(data)
            .then(async (response) => {
                await signatureImageRef.current.uploadImages(response.data.id);
                await stampImageRef.current.uploadImages(response.data.id);
                await userProfileRef.current.uploadImages(response.data.id);
                showSuccessMessage('save_successfully', t);
                dialogRef.current.changeSelectedTab(1);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
            })
            .catch((error) => handleError(error, error?.response?.data?.message.split("#")[0], t))
            .finally(() => hideWaiting(`${dialogId}-okButton`, true));
    }

    const _handleDataChanged = (name, value) => {
        data[name] = value;

        if (name === "type") {
            data.gprId = null;
        }

        setData({ ...data });
    }

    const _postClose = () => {
        setData({})
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false;
        tableRef?.current?.refresh();
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getUser(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            userProfileRef?.current?.setImages([]);
            signatureImageRef?.current?.setImages([]);
            stampImageRef?.current?.setImages([]);
            setData({ ...defaultData });
        }
    }

    const close = () => dialogRef?.current?.closeIfCan();

    const open = (id) => {
        dialogRef?.current?.open();
        _populateRecordData(id);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <ModalWithTabs
            id={dialogId}
            ref={dialogRef}
            formId={FND0042_USERS_DEFINITION_FORM_ID}
            title={t('usersDefinition:users_definition')}
            Icon={<CustomerIcon />}
            labels={
                isMobile && !isTablet &&
                ['home#1', 'usersDefinition:settings', 'usersDefinition:responsibilities_authorizations_definition#1', 'attachments']
                ||
                ['home#1', 'usersDefinition:settings', 'usersDefinition:responsibilities_authorizations_definition#1']}
            className={style.usersDefinitionDialog}
            okBtnAction={_okBtnAction}
            hideOkBtn={data?.isActive == USER_STATUS_TERMINATED || !isAllowedAction(user, FND0042_USERS_DEFINITION_FORM_ID, SAVE_ACTION)}
            preClose={_preCloseAction}
            postClose={_postClose}
            customComponents={
                <div className={isMobile && !isTablet ? "uploadImgProfileContainerMobile" : "uploadImgProfileContainer"}>
                    <UploadImg
                        ref={userProfileRef}
                        customStyle={"uploadUserProfileImg"}
                        masterId={data?.id}
                        formId={USER_DEFINITION_PROFILE_IMAGE}
                    />
                </div>
            }
            nextPreviousAction={(id) => _populateRecordData(id)}
        >
            <Tab1 data={data} handleDataChanged={_handleDataChanged} />
            <Tab2
                data={data}
                handleDataChanged={_handleDataChanged}
                signatureImageRef={signatureImageRef}
                stampImageRef={stampImageRef}
            />
            <Tab3 data={data} setData={setData} user={user} />
            {(isMobile && !isTablet) ? <Tab4 data={data} userProfileRef={userProfileRef} /> : <></>}
        </ModalWithTabs>
    )
}

export default forwardRef(UsersDefinitionDialog);