import React from "react";

function TaskDescriptionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#292d32"
        data-name="Group 179939"
        transform="translate(-493.25 -361.25)"
      >
        <path
          d="M13 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h6c2.77 0 4.647.586 5.905 1.845S20.75 4.23 20.75 7v6c0 2.77-.586 4.647-1.845 5.905S15.77 20.75 13 20.75zm-6-20c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h6c2.346 0 3.886-.447 4.845-1.405S19.25 15.346 19.25 13V7c0-2.346-.447-3.886-1.405-4.845S15.346.75 13 .75z"
          opacity="0.5"
          transform="translate(494 362)"
        ></path>
        <g data-name="Group 176497">
          <path
            d="M5.25.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h5.25A.75.75 0 016 0a.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(504.37 368.75)"
          ></path>
          <circle
            cx="1.5"
            cy="1.5"
            r="1.5"
            data-name="Ellipse 55"
            transform="translate(498 367)"
          ></circle>
        </g>
        <g data-name="Group 176496">
          <path
            d="M5.25.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h5.25A.75.75 0 016 0a.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(504.37 375.75)"
          ></path>
          <circle
            cx="1.5"
            cy="1.5"
            r="1.5"
            data-name="Ellipse 56"
            transform="translate(498 374)"
          ></circle>
        </g>
      </g>
    </svg>
  );
}

export default TaskDescriptionIcon;
