import FrmTextArea from "@ipgd-gauge/frm-text-area";
import { checkValidation, removeAllMandatoryClasses } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const HangUpDialog = (props, ref) => {

    const { okAction, postClose } = props;
    const { t } = useTranslation();
    const trnxIdRef = useRef();
    const dialogRef = useRef();

    const [remark, setRemark] = useState();

    const _handleOkAction = () => {
        if (okAction) {
            if (!checkValidation([{ id: "hangup-dialog" }], t)) {
                return
            }
            okAction(trnxIdRef.current, { remark: remark })
        }
    }

    const _postClose = () => {
        setRemark("");
        trnxIdRef.current = "";
        removeAllMandatoryClasses(document.getElementById("hangup-dialog"))
        if (postClose) {
            postClose();
        }
    }

    const open = (trnxId) => {
        trnxIdRef.current = trnxId;
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="hangup-dialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={t('estimationTransaction:hang_up')}
            okBtnLabel={t("estimationTransaction:save")}
            withOkButton
            withCancelButton
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('estimationTransaction:exit')}
            className={`fitHeightDialog ${style.actionDailog}`}
            okBtnAction={_handleOkAction}
            postClose={_postClose}
            withFooter
            smallSize
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id="remark-lbl" className='form-label mandatory'>{t('estimationTransaction:remark')}</label>
                        <FrmTextArea
                            id="remark"
                            value={remark}
                            onBlur={(value) => setRemark(value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(HangUpDialog);