import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteEstimationTransactionsStatuses, getEstimationTransactionsStatusesList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { deleteAction, dialogRef } = rowActions;
    const user = getUser();
    const { t } = useTranslation();

    return (
        <div>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish()? data?.descLo: data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('name')}</label>
                <label>{isEnglish()? data?.descLo: data.descFo}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('cities:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const EstimationTransactionsStatusesTable = (props) => {
    const { tableRef, searchTextRef, filterRef, dialogRef } = props;

    const { t } = useTranslation();

    const [list, setList] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('code'), width: "17%" },
        { title: t('name'), width: "29%" },
        { title: t('status'), width: "28%" },
        { title: "", width: "21%" },
    ];

    const _fetchData = (pageNumber, pageSize) => {
        const filters = {
            searchValue: searchTextRef.current.getSearchText(),
            ...filterRef?.current?.getFilterData(),
        }
        getEstimationTransactionsStatusesList(pageNumber, pageSize, filters)
            .then((response) => setList(response.data))
            .catch((error) => handleError(error, null, t))
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimationTransactionsStatuses(id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    tableRef?.current?.refresh();
                })
                .catch((error) => handleError(error, "delete_fields", t));
        });
    }

    return (
        <div className={style.estimationTransactionsStatusesTableContainer}>
            <Slisting
                id="estimationTransactionsStatusesTable"
                ref={tableRef}
                formId={RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID}
                columns={columns}
                Row={Row}
                tableStyle={style.estimationTransactionsStatusesTable}
                getData={_fetchData}
                pagingData={list}
                withFilter
                rowActions={{ deleteAction: _deleteAction, dialogRef }}
                cardInMobile
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={() => dialogRef.current?.open()}
                deleteAction={deleteEstimationTransactionsStatuses}
                viewAction={(id) => dialogRef.current?.open(id)}
                labelCardWidth="80px"
            />
        </div>
    );
};

export default EstimationTransactionsStatusesTable;