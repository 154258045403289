import { useRef } from "react";
import { useTranslation } from "react-i18next";
import QuickSearch from "../../components/quick-search";
import CitiesIcon from "../../icons/Cities";
import FiltersIcon from "../../icons/Filters";
import { RSM2659_PROPERTIES_TYPES_FORM_ID } from "../../util/constants/forms";
import PropertiesTypesDialog from "./dialogs";
import PropertiesTypesFilter from "./listing/Filters";
import PropertiesTypesTable from "./listing/index";
import FormTitle from "../../components/form-title";

const PropertiesTypes = () => {
    const { t } = useTranslation("propertiesTypes");

    const searchTextRef = useRef();
    const filterRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM2659_PROPERTIES_TYPES_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name') + " , " + t('short_name')}
                        search={() => tableRef.current.refresh()}
                        formId={RSM2659_PROPERTIES_TYPES_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <PropertiesTypesTable
                tableRef={tableRef}
                filterRef={filterRef}
                searchTextRef={searchTextRef}
                dialogRef={dialogRef}
            />

            <PropertiesTypesFilter
                ref={filterRef}
                tableRef={tableRef}
            />

            <PropertiesTypesDialog
                ref={dialogRef}
                tableRef={tableRef}
            />
        </>
    )
}

export default PropertiesTypes;