import React from "react";

const UnpostIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.76"
            height="14.119"
            viewBox="0 0 17.76 14.119"
        >
            <g
                fill={props.fill || "#292d32"}
                data-name="Group 180022"
                transform="translate(-274.12 -140.94)"
            >
                <path
                    d="M11 10.75H3a.75.75 0 010-1.5h8a4.25 4.25 0 000-8.5H0A.75.75 0 01-.75 0 .75.75 0 010-.75h11a5.75 5.75 0 010 11.5z"
                    opacity="0.4"
                    transform="translate(275.13 144.31)"
                ></path>
                <path
                    d="M2.56 5.87a.748.748 0 01-.53-.22L-.53 3.09a.75.75 0 010-1.061L2.03-.53a.75.75 0 011.061 0A.75.75 0 013.09.53L1.061 2.56 3.09 4.59a.75.75 0 01-.53 1.28z"
                    data-name="Vector"
                    transform="translate(274.87 141.69)"
                ></path>
            </g>
        </svg>
    );
}

export default UnpostIcon;