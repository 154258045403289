import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteQuotationType, getQuotationTypeList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, deleteAction, dialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.estimationQuotationsTypesRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotationsTypes:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish()? data?.descLo: data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('name')}</label>
                <label>{isEnglish()? data?.descLo: data.descFo}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('estimationQuotationsTypes:status')}</label>
                <label className={`status-${data?.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, VIEW_EDIT_ACTION) && <EditBtn onClick={() => dialogRef?.current?.open(data.id)} />}
                    {isAllowedAction(user, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, DELETE_ACTION) && <DeleteBtn onClick={() => deleteAction(data.id)} />}
                </div>
            </div>
        </div >
    );
};

const EstimationQuotationsTypesTable = (props) => {
    const { user, tableRef, searchTextRef, filterRef, dialogRef } = props;

    const { t } = useTranslation();

    const [quotationTypes, setQuotationTypes] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('estimationQuotationsTypes:code'), width: "10%" },
        { title: t('name'), width: "35%" },
        { title: t('estimationQuotationsTypes:status'), width: "30%" },
        { title: '', width: "20%" },
    ];

    const _fetchData = (page, size) => {
        getQuotationTypeList(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setQuotationTypes(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (isNotEmpty(id)) {
                return deleteQuotationType(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, "delete_fields", t));
            }
        });
    }

    return (
        <div className={style.estimationQuotationsTypesTableContainer}>
            <Slisting
                id="estimation-quotations-types-table"
                ref={tableRef}
                columns={columns}
                formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
                Row={Row}
                tableStyle={style.estimationQuotationsTypesTable}
                getData={_fetchData}
                pagingData={quotationTypes}
                rowActions={{ user, deleteAction: _deleteAction, dialogRef }}
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                user={user}
                newAction={() => dialogRef.current?.open()}
                deleteAction={deleteQuotationType}
                viewAction={(id) => dialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="90px"

            />
        </div>
    );
};

export default EstimationQuotationsTypesTable;