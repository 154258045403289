export const ACTION_SAVE_FORMS_FILTER = 'SAVE_FORMS_FILTER';
export const ACTION_CLEAR_FORMS_FILTER = 'SAVE_FORMS_FILTER';

export const saveFormsFilter = (formId, data) => ({
    type: ACTION_SAVE_FORMS_FILTER,
    formId: formId,
    data: data
});

export const clearFormsFilter = () => ({
    type: ACTION_CLEAR_FORMS_FILTER,
});