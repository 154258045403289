import { combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import estTrnxReducer from './est-trnx-data/reducers';
import formSelectedRecords from './formSelectedRecords/reducers';
import formsFilterReducer from './formsFilter/reducers';
import userReducer from './user/reducers';
import userTableProfilesReducer from './userTableProfiles/reducers';

const rootReducer = combineReducers({ userReducer, formsFilterReducer, formSelectedRecords, userTableProfilesReducer });

const persistConfig = {
    key: 'rs_est_root',
    storage: sessionStorage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const mainReducer = combineReducers({ persistedReducer, estTrnxReducer })

export let store = createStore(mainReducer)
export let persistor = persistStore(store)