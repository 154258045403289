import React from "react";

function ColorPaletteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.432"
      height="25.433"
      viewBox="0 0 25.432 25.433"
    >
      <g
        fill="#6200b8"
        data-name="Group 176778"
        transform="translate(-109.543 -224.784)"
      >
        <path
          d="M20.346 3.179v3.815a3 3 0 01-3.179 3.179H0a4.944 4.944 0 001.551-.242c.14-.051.28-.1.42-.165A4.449 4.449 0 003.23 9a4 4 0 00.356-.318l.051-.051L12.284 0h4.883a3 3 0 013.179 3.179z"
          opacity="0.08"
          transform="translate(114.629 240.043)"
        ></path>
        <path
          d="M12.093 8.208l-3.446 3.447L0 20.289a5.1 5.1 0 001.45-3.548V4.457L4.9 1.011a3.011 3.011 0 014.5 0l2.7 2.7a3.011 3.011 0 01-.007 4.497z"
          data-name="Vector"
          opacity="0.26"
          transform="translate(118.268 228.389)"
        ></path>
        <path
          d="M6.994 0H3.179A3 3 0 000 3.179v17.167a5.411 5.411 0 00.1 1.017c.038.165.076.331.127.5.064.191.127.381.2.56a.039.039 0 01.013.025c.013 0 .013 0 0 .013a6.412 6.412 0 00.623 1.017c.14.165.28.318.42.471a3.489 3.489 0 00.458.407l.013.013a6.412 6.412 0 001.017.623c.013-.013.013-.013.013 0a4.592 4.592 0 00.585.216c.165.051.331.089.5.127a5.411 5.411 0 001.017.1 4.944 4.944 0 001.551-.242c.14-.051.28-.1.42-.165a4.449 4.449 0 001.259-.763 4 4 0 00.356-.318l.051-.051a5.1 5.1 0 001.45-3.548V3.179A3 3 0 006.994 0zM5.087 22.254a1.907 1.907 0 111.907-1.907 1.9 1.9 0 01-1.907 1.907z"
          data-name="Vector"
          transform="translate(109.543 224.784)"
        ></path>
      </g>
    </svg>
  );
}

export default ColorPaletteIcon;