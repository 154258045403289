import React from "react";

function InspectorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.67"
      height="21.5"
      viewBox="0 0 19.67 21.5"
    >
      <g
        fill="#292d32"
        data-name="Group 179953"
        transform="translate(-622.16 -189.25)"
      >
        <path
          d="M9.081-.75a3.939 3.939 0 011.951.519L16.975 3.2a3.935 3.935 0 011.945 3.38v6.84a3.935 3.935 0 01-1.94 3.38l-5.94 3.43a3.9 3.9 0 01-1.946.521 3.939 3.939 0 01-1.951-.519L1.195 16.8A3.91 3.91 0 01-.75 13.42V6.58A3.935 3.935 0 011.19 3.2L7.13-.23a3.9 3.9 0 011.951-.52zm7.141 5.25l-5.937-3.431A2.433 2.433 0 009.081.75a2.4 2.4 0 00-1.2.32L1.948 4.5A2.43 2.43 0 00.75 6.58v6.84a2.406 2.406 0 001.195 2.08l5.94 3.43a2.433 2.433 0 001.2.319 2.4 2.4 0 001.2-.32l5.937-3.429a2.43 2.43 0 001.2-2.082V6.58a2.4 2.4 0 00-1.2-2.08z"
          transform="translate(622.91 190)"
        ></path>
        <path
          d="M2.33-.75A3.08 3.08 0 11-.75 2.33 3.083 3.083 0 012.33-.75zm0 4.66A1.58 1.58 0 10.75 2.33a1.582 1.582 0 001.58 1.58z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(629.67 194.34)"
        ></path>
        <path
          d="M8 4.01a.75.75 0 01-.75-.75C7.25 1.876 5.792.75 4 .75S.75 1.876.75 3.26a.75.75 0 01-.75.75.75.75 0 01-.75-.75A3.705 3.705 0 01.7.374 5.223 5.223 0 014-.75 5.223 5.223 0 017.3.374 3.705 3.705 0 018.75 3.26a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(628 201.4)"
        ></path>
      </g>
    </svg>
  );
}

export default InspectorIcon;