import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import PropertiesUsersDefinitionDialog from "./dialogs";
import PropertiesUsersDefinitionTable from "./listing";
import PropertiesUsersDefinitionFilter from "./listing/Filters";

const PropertiesUsersDefinition = () => {
    const { t } = useTranslation("propertiesUsersDefinition");
    const user = getUser();

    const searchTextRef = useRef();
    const filtersRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM2661_PROPERTIES_USERS_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name') + " , " + t('user_name') + " , " + t('identifier')}
                        search={() => tableRef.current.refresh()}
                        formId={RSM2661_PROPERTIES_USERS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <PropertiesUsersDefinitionTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filtersRef={filtersRef}
                dialogRef={dialogRef}
            />

            <PropertiesUsersDefinitionFilter ref={filtersRef} tableRef={tableRef} />
            <PropertiesUsersDefinitionDialog ref={dialogRef} user={user} tableRef={tableRef} />
        </>
    )
}

export default PropertiesUsersDefinition;