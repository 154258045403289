import React from "react";

function SaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 75 75"
    >
      <path
        fill="#fff"
        d="M6.989 75A6.993 6.993 0 010 68.014V6.989A7 7 0 016.989 0h46.926a7.049 7.049 0 015.063 2.188l13.979 15.663A7.034 7.034 0 0175 22.794v45.22A6.992 6.992 0 0168.014 75zm-.172-6.986a.171.171 0 00.172.169h61.025a.17.17 0 00.169-.169V22.791c0-.049-.025-.1-.181-.264L54.026 6.867a.191.191 0 00-.11-.049H40.909v10.171a6.779 6.779 0 01-6.818 6.876H6.818zm.12-50.966h27.154V6.818H6.989a.171.171 0 00-.171.164zm16.415 27.61A14.148 14.148 0 1137.5 58.806a14.148 14.148 0 01-14.148-14.148zm6.818 0a7.33 7.33 0 107.33-7.33 7.332 7.332 0 00-7.33 7.33z"
        data-name="Union 12"
      ></path>
    </svg>
  );
}

export default SaveIcon;
