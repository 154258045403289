import React from "react";

function UnauthorizedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 24 24"
    >
      <path d="M16 8a1 1 0 00-1.414 0L12 10.586 9.414 8A1 1 0 008 9.414L10.586 12 8 14.586A1 1 0 009.414 16L12 13.414 14.586 16A1 1 0 0016 14.586L13.414 12 16 9.414A1 1 0 0016 8z"></path>
      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z"></path>
    </svg>
  );
}

export default UnauthorizedIcon;
