import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, deleteItemByIndex, isEnglish } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../icons/Delete2";

const dialogId = "estimation-messages-templates-dialog";

const FieldsRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div> <div>{data?.sequence}</div></div>
            <div> <div><label>{isEnglish() ? data?.blockDescLo : data?.blockDescFo}</label></div></div>
            <div> <div><label>{isEnglish() ? data?.descLo : data?.descFo}</label></div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

const FieldsTable = (props) => {
    const { data, setData, user } = props;
    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data.fields) {
            data.fields = [];
        }

        deleteItemByIndex(data.fields, index);
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: <span data-property="blockDescLo" className="mandatory">{t('estimationMessagesTemplates:block_name')}</span>, width: "40%" },
        { title: <span data-property={isEnglish() ? "descLo" : "descFo"} className="mandatory">{t('name')}</span>, width: "45%" },
        { title: "", width: "10%" }
    ];

    return (
        <Table
            id={`${dialogId}-fields-table`}
            columns={columns}
            list={data?.fields || []}
            Row={FieldsRow}
            rowActions={{ handleDeleteLine: _handleDeleteLine }}
            tableStyle={style.fieldsTable}
            rowHeight={40}
            user={user}
            formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
        />
    );
}

const Tab2 = (props) => {
    const { tab, data, setData, user, fieldsListRef } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.fields = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100 tableHeaderActions">
                        <button
                            title={t('estimationMessagesTemplates:insert')}
                            onClick={() => fieldsListRef.current.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('estimationMessagesTemplates:insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_deleteAllAction}
                            title={t('estimationMessagesTemplates:delete_all')}
                            disabled={(data?.fields || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('estimationMessagesTemplates:delete_all')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>

            <FieldsTable data={data} setData={setData} user={user} />
        </div>
    )
}

export default Tab2;