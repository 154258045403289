import { parseNumber, roundByDigits } from "../../../../util/util";

export const ACTION_CREATE_NEW_INVOICE = '1';
export const ACTION_SET_INVOICE_VALUE = '2';
export const ACTION_SET_INVOICE = '3';
export const ACTION_SET_HEADER_VALUE = '4';
export const ACTION_DELETE_ALL_LINES = '5';
export const ACTION_DELETE_LINE = '6';
export const ACTION_ADD_LINE = '7';
export const ACTION_SET_LINE_VALUE = '8';
export const ACTION_ADD_LINES = '9';
export const ACTION_DELETE_ALL_PARTIES = '10';
export const ACTION_DELETE_PARTY = '11';
export const ACTION_ADD_PARTY = '12';
export const ACTION_ADD_PARTIES = '13';
export const ACTION_RECALCULATE = '14';
export const ACTION_SET_FILTERED_LINES = '15';

const _calculateLine = (line, action, exchngRate) => {
    if (action == "invoiceAmountAfc") {
        line.invoiceAmountAlc = roundByDigits(parseNumber(line.invoiceAmountAfc) * parseNumber(exchngRate || 1), 2)
    }
    if (action == "discountAfc") {
        line.discountAlc = roundByDigits(parseNumber(line.discountAfc) * (exchngRate || 1), 2)
    }
    if (action == "vatPerc" || action == "invoiceAmountAfc" || action == "invoiceAmountAlc" || !line.vatAmountAlc || action == "discountAlc" || action == "discountAfc") {
        line.vatAmountAlc = roundByDigits(((parseNumber(line.invoiceAmountAlc) - parseNumber(line.discountAlc)) * parseNumber(line.vatPerc)) / 100, 2)
        line.vatAmountAfc = roundByDigits(((parseNumber(line.invoiceAmountAfc) - parseNumber(line.discountAfc)) * parseNumber(line.vatPerc)) / 100, 2)
    }
    line.netAlc = roundByDigits(parseNumber(line.invoiceAmountAlc) - parseNumber(line.discountAlc) + parseNumber(line.vatAmountAlc), 2)
    line.netAfc = roundByDigits(parseNumber(line.invoiceAmountAfc) - parseNumber(line.discountAfc) + parseNumber(line.vatAmountAfc), 2)
    return { ...line }
}

const calculateTotals = (lines) => {
    let totals = {
        count: lines.length,
        totalPriceAlc: 0,
        totalDiscountAlc: 0,
        totalVatAlc: 0,
        totalNetPriceAlc: 0,
        totalPriceAfc: 0,
        totalDiscountAfc: 0,
        totalVatAfc: 0,
        totalNetPriceAfc: 0
    }

    lines.map((line) => {
        totals.totalPriceAlc += parseNumber(line.invoiceAmountAlc);
        totals.totalPriceAfc += parseNumber(line.invoiceAmountAfc);
        totals.totalDiscountAlc += parseNumber(line.discountAlc);
        totals.totalDiscountAfc += parseNumber(line.discountAfc);
        totals.totalVatAlc += parseNumber(line.vatAmountAlc);
        totals.totalVatAfc += parseNumber(line.vatAmountAfc);
        totals.totalNetPriceAlc += parseNumber(line.netAlc);
        totals.totalNetPriceAfc += parseNumber(line.netAfc);
    })

    return (totals);
}

const estimatorInvoiceReducer = (state, action) => {
    switch (action.type) {
        case ACTION_CREATE_NEW_INVOICE: {
            return {
                ...state,
                ...action.value,
            }
        };
        case ACTION_SET_INVOICE_VALUE: {
            return {
                ...state,
                invoice: { ...state.invoice, [action.name]: action.value }
            }
        };
        case ACTION_SET_INVOICE: {
            state.totals = calculateTotals(action?.value?.lines || [])
            return {
                ...state,
                invoice: { ...action.value }
            }
        };
        case ACTION_SET_HEADER_VALUE: {

            if (action.name == 'mbnId' || action.name == 'dateDgr' || action.name == 'invoiceLevel') {
                state.totals = {};
                state.invoice.lines = [];
            }

            if (action.name == 'invoiceLevel' && action.value == '130801') {
                state.invoice.parties = [];
            }

            if (action.name === "defaultCda") {
                state.invoice.header.defaultCdaId = action.value.value;
                state.invoice.header.defaultCdaCode = action.value.code;
                state.invoice.header.defaultCdaDescLo = action.value.descLo;
                state.invoice.header.defaultCdaDescFo = action.value.descFo;
                state.invoice.header.defaultCdaVatPerc = action.value.vatPerc;
            } else if (action.name === "defaultExpenseItem") {
                state.invoice.header.defaultCdaCode = action.value.code;
                state.invoice.header.defaultCdaDescLo = action.value.descLo;
                state.invoice.header.defaultCdaDescFo = action.value.descFo;
                state.invoice.header.defaultCdaVatPerc = action.value.vatPerc;
            }
            if (action.name === "billingDateMode") {
                state.invoice.header.billingPeriodFromDgr = null;
                state.invoice.header.billingPeriodToDgr = null;
                state.invoice.header.billingPeriodFromDhi = null;
                state.invoice.header.billingPeriodToDhi = null;
            }


            return {
                ...state,
                invoice: { ...state.invoice, header: { ...state.invoice.header, [action.name]: action.value } }
            }
        };
        case ACTION_DELETE_ALL_LINES: {
            state.totals = calculateTotals([])
            return {
                ...state,
                invoice: { ...state.invoice, lines: [], filteredLines: null }
            }
        };
        case ACTION_DELETE_LINE: {
            let newLines = [];
            let newFilteredLines = null;
            if (state.invoice.filteredLines) {
                const deletedLine = state.invoice.filteredLines[action.index];
                newFilteredLines = state.invoice.filteredLines.filter((line, index) => index != action.index)
                newLines = state.invoice.lines.filter((line, index) => index != deletedLine.originalIndex)
            } else {
                newLines = state.invoice.lines.filter((line, index) => index != action.index)
            }
            state.totals = calculateTotals(newLines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: newLines, filteredLines: newFilteredLines }
            }
        };
        case ACTION_ADD_LINE: {
            let line = action.value;
            line = _calculateLine(line);
            let lines = [...state.invoice.lines, line]
            state.totals = calculateTotals(lines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: lines }
            }
        };
        case ACTION_ADD_LINES: {
            let lines = action.value;
            lines?.map((line) => {
                line = _calculateLine(line);
            })
            let newLines = [...state.invoice.lines, ...lines]
            state.totals = calculateTotals(newLines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: newLines }
            }
        };
        case ACTION_SET_LINE_VALUE: {
            let tempLines = state.invoice.filteredLines ? state.invoice.filteredLines : state.invoice.lines;
            let changeLine = tempLines[action.index];
            changeLine[action.name] = action.value;
            if (action.name === "party") {
                changeLine.aprId = action?.value?.value;
            }
            _calculateLine(changeLine, action.name, action.exchngRate)
            state.totals = calculateTotals(tempLines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: [...state.invoice.lines] }
            }
        };
        case ACTION_DELETE_ALL_PARTIES: {
            return {
                ...state,
                invoice: { ...state.invoice, parties: [] }
            }
        };
        case ACTION_DELETE_PARTY: {
            let newParties = state.invoice.parties.filter((line, index) => index != action.index)
            return {
                ...state,
                invoice: { ...state.invoice, parties: newParties }
            }
        };
        case ACTION_ADD_PARTY: {
            let party = action.value;
            // line = _calculateLine(line);
            return {
                ...state,
                invoice: { ...state.invoice, parties: [...state.invoice.parties, party] }
            }
        };
        case ACTION_ADD_PARTIES: {
            let newParties = action.value;
            // newParties?.map((line) => {
            //     line = _calculateLine(line);
            // })
            return {
                ...state,
                invoice: { ...state.invoice, parties: [...state.invoice.parties, ...newParties] }
            }
        };
        case ACTION_RECALCULATE: {
            for (let key in (action.value || [])) {
                const values = action.value[key].split('-');
                state.invoice.lines[key].dueAmountAlc = values[0];
                state.invoice.lines[key].dueAmountAfc = values[1];
                state.invoice.lines[key].invoiceAmountAlc = values[0];
                state.invoice.lines[key].invoiceAmountAFc = values[1];
                state.invoice.lines[key] = _calculateLine(state.invoice.lines[key]);
            }
            const total = calculateTotals(state.invoice.lines);
            return {
                ...state,
                invoice: { ...state.invoice, lines: state.invoice.lines },
                totals: total
            }
        }
        case ACTION_SET_FILTERED_LINES: {
            return {
                ...state,
                invoice: { ...state.invoice, filteredLines: action.value },
            }
        }
        default:
            return state;
    }
}

export default estimatorInvoiceReducer;