import React from "react";

function StarIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44.432"
            height="47.353"
            viewBox="0 0 44.432 47.353"
        >
            <g data-name="Group 180225" transform="translate(5329.609 13080.374)">
                <g data-name="Group 180224">
                    <g data-name="Group 180062">
                        <path
                            fill="#f8b43c"
                            d="M236.026 150.618a1.35 1.35 0 00-1.089-.918l-12.677-1.844-5.674-11.49a1.4 1.4 0 00-2.417 0l-5.674 11.49-12.677 1.844a1.348 1.348 0 00-.748 2.3l9.177 8.944-2.165 12.632a1.348 1.348 0 001.956 1.424l11.34-5.964L226.719 175a1.347 1.347 0 001.956-1.42l-2.162-12.632 9.174-8.948a1.355 1.355 0 00.339-1.382z"
                            data-name="Path 44887"
                            transform="translate(-5522.771 -13214.549)"
                        ></path>
                        <path
                            fill="#292d32"
                            d="M215.378 134.175a2.8 2.8 0 012.554 1.529l5.323 10.781 11.9 1.731a2.849 2.849 0 011.585 4.854l-8.61 8.394 2.029 11.855a2.827 2.827 0 01-1.131 2.784 2.837 2.837 0 01-1.671.541 2.866 2.866 0 01-1.324-.326l-10.645-5.6-10.642 5.6a2.85 2.85 0 01-2.995-.212l-.009-.006a2.835 2.835 0 01-1.129-2.782l2.032-11.856-8.612-8.393a2.831 2.831 0 01-.722-2.917v-.007a2.837 2.837 0 012.3-1.934l11.9-1.731 5.325-10.783a2.8 2.8 0 012.542-1.522zm19.042 16.965l-13.156-1.914-5.886-11.92-5.886 11.92-13.154 1.913 9.521 9.279-2.246 13.105 11.765-6.187 11.766 6.187-2.244-13.103z"
                            data-name="Path 44887 - Outline"
                            transform="translate(-5522.771 -13214.549)"
                        ></path>
                        <g data-name="Group 180061" transform="translate(-5325.999 -13083)">
                            <path
                                fill="#fff"
                                d="M18.606 48.479l-5-14.489a5.283 5.283 0 119.99 0z"
                                data-name="Path 44880"
                            ></path>
                            <path
                                fill="#292d32"
                                d="M18.606 49.979a1.5 1.5 0 01-1.418-1.011l-4.995-14.489a6.783 6.783 0 1112.825 0l-4.994 14.489a1.5 1.5 0 01-1.418 1.011m0-21.494a3.783 3.783 0 00-3.577 5.015l3.577 10.376L22.182 33.5a3.782 3.782 0 00-3.576-5.016"
                                data-name="Path 44881"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default StarIcon;
