import Tabs from "@ipgd-gauge/tabs";
import { deleteUrlParam, getUrlParam, handleError, isNotEmpty, parseNumber, removeAllMandatoryClasses } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import XIcon from "../../../icons/X";
import { getCurrentTime, getValuationContracts } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { closeSummary } from "../../../util/util";
import Actions from "../Actions";
import style from "./style.module.scss";
import Summary from "./summary";
import Tab1 from "./tab1";
import Tab2 from "./tab2";

const ValuationContract = () => {
    const { t } = useTranslation();

    const id = getUrlParam('id');
    const currentUser = getUser();
    const printDialogRef = useRef();
    const attachmentsImgRef = useRef();
    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);

    const defaultValue = {
        globalStatus: GLOBAL_STATUS_INCOMPLETE,
        customerLevel: CUSTOMER_LEVEL_PREDEFINED
    };

    const [valuationContract, setValuationContract] = useState({ ...defaultValue });

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromNewActionRef.current = false;
            return;
        }

        if (isNotEmpty(id)) {
            getValuationContracts(id)
                .then((response) => setValuationContract(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction();
        }
    }, [id])

    const countNetPrice = () => {
        let netPrice = 0
        for (let i = 0; i < valuationContract.lines.length; i++) {
            netPrice += valuationContract.lines[i].netPrice
        }
        return netPrice
    }


    const _newAction = async () => {
        removeAllMandatoryClasses(document.getElementById('valuation-contract-main'))
        let tempDefaultValue = Object.assign({}, defaultValue);
        let currentTime = null;
        try {
            currentTime = (await getCurrentTime()).data;
        } catch (error) {
            currentTime = new Date().getTime();
        }
        tempDefaultValue.dateDgr = currentTime;
        tempDefaultValue.fcuId = currentUser.fcuId;
        tempDefaultValue.exchngRate = 1;
        fromNewActionRef.current = true;
        deleteUrlParam('id');
        setValuationContract(tempDefaultValue);
    }

    const handleDataChanged = (name, value) => {
        valuationContract[name] = value;
        if (name == "customerLevel") {
            if (value == CUSTOMER_LEVEL_NEW) {
                valuationContract.atrId = null;
            } else if (value == CUSTOMER_LEVEL_PREDEFINED) {
                valuationContract.customerName = null;
                valuationContract.mobileNum = null;
                valuationContract.cardNum = null;
                valuationContract.phoneNumber = null;
                valuationContract.email = null;
                valuationContract.customerCommRegNum = null;
                valuationContract.taxNumber = null;
                valuationContract.customerContact = null;
                valuationContract.customerDeelgationNum = null;
                valuationContract.city = null;
                valuationContract.town = null;
                valuationContract.buildingNumber = null;
                valuationContract.postalCode = null;
                valuationContract.customerAddress = null;
            }
        }
        else if (name == "lines") {
            valuationContract.netPrice = countNetPrice()
        } else if (name === "totalPrice" || name === "vat") {
            valuationContract.netPrice = parseNumber(valuationContract?.totalPrice) + parseNumber(valuationContract?.vat);
        }
        else if (name == "ecdId") {
            valuationContract.criId = null;

        }
        else if (name == "atrId") {
            valuationContract.atrId = value.value;
            valuationContract.customerData = value;
        }
        else if (name === "umyId") {
            valuationContract.umyId = value.value;
            valuationContract.quotationType = value;
            valuationContract.lines = [];
        }

        setValuationContract({ ...valuationContract });
    }

    return (
        <>
            <div className={style.container}>
                <div className={style.linesContainer}>

                    <Tabs
                        id="valuation-contract-main"
                        labels={['home', 'valuationContracts:attachments']}
                        containerStyle={style.tabsContainer}
                        tabContentStyle={style.tabContentStyle}
                    >
                        <Tab1
                            valuationContract={valuationContract}
                            setValuationContract={setValuationContract}
                            currentUser={currentUser}
                            handleDataChanged={handleDataChanged}
                        />
                        <Tab2
                            data={valuationContract}
                            attachmentsImgRef={attachmentsImgRef}
                            handleDataChanged={handleDataChanged}
                        />
                    </Tabs>
                </div>

                <div id='valuation-contract-summary' className="mobileSummary">
                    <span className='customHeaderSummarySm'>
                        <label>{t('valuationContracts:summary')}</label>
                        <button onClick={() => closeSummary('valuation-contract-summary')}> <XIcon /> </button>
                    </span>
                    <Summary valuationContract={valuationContract} />
                </div>
                <div id='summary-bg-mobile' onClick={() => closeSummary('valuation-contract-summary')} className='mobileBg'></div>
            </div>

            <Actions
                user={currentUser}
                isListing={false}
                data={valuationContract}
                setData={setValuationContract}
                newAction={_newAction}
                printDialogRef={printDialogRef}
                attachmentsImgRef={attachmentsImgRef}
                fromSaveActionRef={fromSaveActionRef}
            />

            <PrintDialog
                id="valuation-contract-print-dialog"
                ref={printDialogRef}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                user={currentUser}
            />
        </>
    )
}

export default ValuationContract;