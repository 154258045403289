import React from "react";

function TrackingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.678"
      height="23.678"
      viewBox="0 0 23.678 23.678"
    >
      <g data-name="Group 175738">
        <g  fill={props.color || "#b8bfc9"} data-name="Group 175738">
          <path
            d="M22.911 29.075a8.4 8.4 0 01-3.636 1.365l-.232.029v-1.73l-2.586 2.586 2.587 2.588v-1.8l.184-.018a10.082 10.082 0 008.091-5.706h-1.867a8.543 8.543 0 01-2.542 2.682"
            data-name="Path 6462"
            transform="translate(-6.382 -10.236)"
          ></path>
          <path
            d="M32.117 12.753l-.019-.184a10.077 10.077 0 00-5.7-8.092v1.867a8.538 8.538 0 012.682 2.542 8.416 8.416 0 011.364 3.638l.029.231H28.74l1.686 1.685.017.017.883.883 2.586-2.586z"
            data-name="Path 6463"
            transform="translate(-10.236 -1.737)"
          ></path>
          <path
            d="M8.882 4.838a8.416 8.416 0 013.636-1.364l.232-.029v1.728l2.587-2.586L12.75 0v1.8l-.184.019a10.084 10.084 0 00-8.089 5.7h1.864a8.526 8.526 0 012.541-2.681"
            data-name="Path 6464"
            transform="translate(-1.735)"
          ></path>
          <path
            d="M4.836 22.91a8.422 8.422 0 01-1.365-3.637l-.029-.231h1.73l-2.587-2.587L0 19.043h1.8l.018.184a10.076 10.076 0 005.706 8.091v-1.867a8.538 8.538 0 01-2.688-2.541"
            data-name="Path 6465"
            transform="translate(0 -6.382)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default TrackingIcon;