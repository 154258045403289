import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from 'react-i18next';
import { getCommonCustomers, getDomainValues } from '../../../../util/apis';
import { CUSTOMER_LEVEL, CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, STATUS_ACTIVE } from '../../../../util/constants';

const Customer = (props) => {
    const { estimationQuotation, handleDataChanged } = props;

    const { t } = useTranslation("estimationQuotation");

    const customerRef = useRef(true);

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('customer')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w25">
                        <label className='form-label mandatory'>{t('customer_level')}</label>
                        <FrmSelectMenu
                            id="estimation-quotation-customer-level"
                            api={() => getDomainValues(CUSTOMER_LEVEL)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={estimationQuotation.customerLevel}
                            onChange={value => handleDataChanged("customerLevel", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    {estimationQuotation.customerLevel == CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w75">
                            <label id="estimation-quotation-customer-lbl" className={`form-label ${estimationQuotation?.customerLevel == CUSTOMER_LEVEL_PREDEFINED ? 'mandatory' : ''}`}>{t('customer')}</label>
                            <FrmSelectMenu
                                id="estimation-quotation-customer"
                                value={estimationQuotation.atrId}
                                onChange={value => handleDataChanged("atrId", value)}
                                defualtValueId={estimationQuotation.atrId}
                                defualtValueApi={() => getCommonCustomers(isEmpty(estimationQuotation.atrId) ? [] : [estimationQuotation.atrId], STATUS_ACTIVE, null, true, false, false, [], false)}
                                api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, true, false, false, [], false)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isDisabled={estimationQuotation?.customerLevel == CUSTOMER_LEVEL_NEW}
                                portalTarget={document.body}
                                onDataRetrieved={(list) => {
                                    const id = getUrlParam("id");
                                    if (isNotEmpty(id) && estimationQuotation.atrId && customerRef.current) {
                                        list?.forEach((element) => {
                                            if (element.id == estimationQuotation.atrId) {
                                                handleDataChanged("customerData", { ...element })
                                                return;
                                            }
                                        })
                                    }
                                    if (customerRef.current && estimationQuotation?.id) {
                                        customerRef.current = false;
                                    }
                                }}
                            />
                        </div>
                    }

                    {estimationQuotation.customerLevel == CUSTOMER_LEVEL_NEW &&
                        <div className="w75">
                            <label id="estimation-quotation-customer-name-lbl" className={`form-label ${estimationQuotation?.customerLevel == CUSTOMER_LEVEL_NEW ? 'mandatory' : ''}`}>{t('customer_name')}</label>
                            <FrmInput
                                id="estimation-quotation-customer-name"
                                value={estimationQuotation?.customerName}
                                onBlur={(value) => handleDataChanged("customerName", value)}
                                disabled={estimationQuotation?.customerLevel == CUSTOMER_LEVEL_PREDEFINED}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className={`form-label`}>{t('card_num')}</label>
                            <FrmInput
                                id="estimation-quotation-card-number"
                                value={estimationQuotation?.cardNumber}
                                onBlur={(value) => handleDataChanged("cardNumber", value)}
                                disabled={estimationQuotation?.customerLevel == CUSTOMER_LEVEL_PREDEFINED}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className={`form-label`}>{t('mobile_num')}</label>
                            <FrmInput
                                id="estimation-quotation-mobile-number"
                                type='number'
                                value={estimationQuotation?.mobileNumber}
                                onBlur={(value) => handleDataChanged("mobileNumber", value)}
                                disabled={estimationQuotation?.customerLevel == CUSTOMER_LEVEL_PREDEFINED}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-phone-number-lbl" className="form-label">{t('phone_num')}</label>
                            <FrmInput
                                id="estimation-quotation-phone-number"
                                value={estimationQuotation?.phoneNumber}
                                type='number'
                                onBlur={(value) => handleDataChanged("phoneNumber", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-email-lbl" className="form-label">{t('email')}</label>
                            <FrmInput
                                id="estimation-quotation-email"
                                value={estimationQuotation?.email}
                                onBlur={(value) => handleDataChanged("email", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-commercial-register-lbl" className="form-label">{t('commercial_register')}</label>
                            <FrmInput
                                id="estimation-quotation-commercial-register"
                                value={estimationQuotation?.customerCommRegNum}
                                onBlur={(value) => handleDataChanged("customerCommRegNum", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-tax-id-lbl" className="form-label">{t('tax_id')}</label>
                            <FrmInput
                                id="estimation-quotation-tax-id"
                                value={estimationQuotation?.taxNumber}
                                type='number'
                                onBlur={(value) => handleDataChanged("taxNumber", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-responsible-person-lbl" className="form-label">{t('responsible_person')}</label>
                            <FrmInput
                                id="estimation-quotation-responsible-person"
                                value={estimationQuotation?.customerContact}
                                onBlur={(value) => handleDataChanged("customerContact", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-delegation-number-lbl" className="form-label">{t('delegation_number')}</label>
                            <FrmInput
                                id="estimation-quotation-delegation-number"
                                value={estimationQuotation?.customerDeelgationNum}
                                type='number'
                                onBlur={(value) => handleDataChanged("customerDeelgationNum", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-city-lbl" className="form-label">{t('city')}</label>
                            <FrmInput
                                id="estimation-quotation-city"
                                value={estimationQuotation?.city}
                                onBlur={(value) => handleDataChanged("city", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-town-lbl" className="form-label">{t('town')}</label>
                            <FrmInput
                                id="estimation-quotation-town"
                                value={estimationQuotation?.town}
                                onBlur={(value) => handleDataChanged("town", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-building-no-lbl" className="form-label">{t('building_no')}</label>
                            <FrmInput
                                id="estimation-quotation-building-no"
                                value={estimationQuotation?.buildingNumber}
                                type='number'
                                onBlur={(value) => handleDataChanged("buildingNumber", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label id="estimation-quotation-postal-code-lbl" className='form-label'>{t('postal_code')}</label>
                            <FrmInput
                                id="estimation-quotation-postal-code"
                                value={estimationQuotation?.postalCode}
                                onBlur={(value) => handleDataChanged("postalCode", value)}
                            />
                        </div>
                    }
                    {estimationQuotation?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w100">
                            <label id="estimation-quotation-address-lbl" className='form-label'>{t('address')}</label>
                            <FrmInput
                                id="estimation-quotation-address"
                                value={estimationQuotation.customerAddress}
                                onBlur={(value) => handleDataChanged("customerAddress", value)}
                            />
                        </div>
                    }
                    <div className="w100">
                        <label id="estimation-quotation-delivered-documents-lbl" className='form-label'>{t('delivered_documents')}</label>
                        <FrmInput
                            id="estimation-quotation-delivered-documents"
                            value={estimationQuotation.customerReceivedDocs}
                            onBlur={(value) => handleDataChanged("customerReceivedDocs", value)}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Customer;