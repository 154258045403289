import React from "react";

function DelegationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="21.5"
      viewBox="0 0 19.5 21.5"
    >
      <g
        fill="#088587"
        data-name="Group 178507"
        transform="translate(-4513.25 -12541.25)"
      >
        <path
          d="M6 4.75H2a3.083 3.083 0 01-1.791-.376A2.626 2.626 0 01-.75 2 2.626 2.626 0 01.209-.374 3.083 3.083 0 012-.75h4a3.083 3.083 0 011.791.376A2.626 2.626 0 018.75 2a3.083 3.083 0 01-.376 1.791A2.626 2.626 0 016 4.75zm-4-4a2.1 2.1 0 00-.959.124C.853 1 .75 1.4.75 2s.1 1 .291 1.126A2.1 2.1 0 002 3.25h4c.6 0 1-.1 1.126-.291A2.1 2.1 0 007.25 2c0-.6-.1-1-.291-1.126A2.1 2.1 0 006 .75z"
          transform="translate(4519 12542)"
        ></path>
        <path
          d="M11 18.73H6c-2.663 0-4.391-.558-5.439-1.756C-.594 15.654-.75 13.709-.75 11.98v-6C-.75 3.543-.289 1.91.7.839 1.613-.149 2.927-.639 4.96-.749a.75.75 0 01.789.708.75.75 0 01-.709.79A4.5 4.5 0 001.8 1.856C1.094 2.623.75 3.972.75 5.98v6c0 2.055.281 3.254.939 4.006C2.432 16.835 3.8 17.23 6 17.23h5a.75.75 0 010 1.5z"
          data-name="Vector"
          transform="translate(4514 12544.02)"
        ></path>
        <path
          d="M5 11.73a.75.75 0 01-.75-.75v-5c0-2.012-.344-3.362-1.052-4.127A4.507 4.507 0 00-.04.749.75.75 0 01-.749-.04.75.75 0 01.04-.749c2.034.11 3.348.6 4.259 1.583C5.289 1.9 5.75 3.539 5.75 5.98v5a.75.75 0 01-.75.75z"
          data-name="Vector"
          transform="translate(4527 12544.02)"
        ></path>
        <g
          data-name="Group 627"
          opacity="0.4"
          transform="translate(4211 12288)"
        >
          <path
            d="M0 3.75A.75.75 0 01-.75 3V0A.75.75 0 010-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75H.75V3a.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(315 268)"
          ></path>
          <path
            d="M5.96 6.71a.748.748 0 01-.53-.22L-.53.53a.75.75 0 010-1.06.75.75 0 011.06 0l5.96 5.96a.75.75 0 01-.53 1.28z"
            data-name="Vector"
            transform="translate(315.039 268.04)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default DelegationIcon;