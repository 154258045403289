import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";

const COMMON_ID = "price-agreements";

const Home = (props) => {
    const { data, handleDataChange } = props;

    const { t } = useTranslation('priceAgreement');

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('home')}
                    </label>
                </div>
            </div>

            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w25" id={`${COMMON_ID}-home-code-lbl`}>
                        <CodeInput
                            id={`${COMMON_ID}-home-code`}
                            value={data?.code}
                            onBlur={(value) => handleDataChange("code", value)}
                            formId={RSM3007_PRICE_AGREEMENT_FORM_ID}
                            column="EMI_CODE"
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label'>{t('quotation_num')}</label>
                        <FrmInput
                            id={`${COMMON_ID}-home-quotation-number`}
                            value={data?.estimationQuotation?.code}
                            disabled={true}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label mandatory'>{t('status')}</label>
                        <RadioOptions
                            id={`${COMMON_ID}-home-status`}
                            options={[
                                {
                                    id: 'active-status',
                                    title: t('active'),
                                    action: () => handleDataChange("isActive", STATUS_ACTIVE),
                                    selected: data.isActive == STATUS_ACTIVE
                                },
                                {
                                    id: 'inactive-status',
                                    title: t('inactive'),
                                    action: () => handleDataChange("isActive", STATUS_IN_ACTIVE),
                                    selected: data.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>

                    <div className="w25"></div>

                    <div className="w100">
                        <label id={`${COMMON_ID}-home-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${COMMON_ID}-home-english-name`}
                            value={data?.descLo}
                            onBlur={value => handleDataChange("descLo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id={`${COMMON_ID}-home-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${COMMON_ID}-home-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => handleDataChange("descFo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${COMMON_ID}-home-remarks`}
                            value={data?.rem}
                            onBlur={value => handleDataChange("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;