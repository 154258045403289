import React from "react";

function ExportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.54"
      height="20.498"
      viewBox="0 0 20.54 20.498"
    >
      <path
        fill="#292d32"
        d="M7.01 20.5C2.1 20.5 0 18.4 0 13.487v-.13c0-4.44 1.75-6.58 5.67-6.95a.772.772 0 01.82.68.752.752 0 01-.68.82c-3.14.29-4.31 1.77-4.31 5.46v.13c0 4.07 1.44 5.51 5.51 5.51h6.52c4.071 0 5.511-1.44 5.511-5.51v-.13c0-3.711-1.191-5.19-4.39-5.46a.748.748 0 11.13-1.49c3.98.34 5.76 2.49 5.76 6.961v.13c0 4.89-2.1 6.99-7.01 6.99zm2.51-7.25V2.557L7.45 4.628a.75.75 0 01-1.06-1.06L9.74.218a.753.753 0 011.06 0l3.35 3.35a.753.753 0 010 1.06.726.726 0 01-.53.22.741.741 0 01-.529-.22L11.02 2.557v10.691a.75.75 0 11-1.5 0z"
        data-name="Union 28"
      ></path>
    </svg>
  );
}

export default ExportIcon;
