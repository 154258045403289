import React from "react";

function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.894"
      height="18.542"
      viewBox="0 0 21.894 18.542"
    >
      <path
        fill="#019472"
        d="M69.552 167.1a12.934 12.934 0 00-8.719-4.868.3.3 0 01-.268-.288v-.749a2.018 2.018 0 00-3.451-1.421l-6.261 6.3a2.02 2.02 0 00.216 3.035l6.262 4.728a2.018 2.018 0 003.234-1.61v-1.35a.294.294 0 01.261-.29 8.077 8.077 0 013.233.176c4.394 1.174 6.1 5.837 6.278 6.365a.866.866 0 00.818.586h.073a.865.865 0 00.782-.723 13.191 13.191 0 00-2.458-9.892m-5.046 2a9.533 9.533 0 00-2.475-.322 10.147 10.147 0 00-1.45.1 2.011 2.011 0 00-1.745 2v1.35a.288.288 0 01-.461.23l-6.262-4.729a.288.288 0 01-.031-.434l6.262-6.3a.288.288 0 01.493.2v.749a2.029 2.029 0 001.786 2 11.216 11.216 0 017.558 4.2 10.9 10.9 0 012.15 5.251 10.457 10.457 0 00-5.823-4.306"
        data-name="Path 6425"
        transform="translate(-50.267 -159.175)"
      ></path>
    </svg>
  );
}

export default ShareIcon;