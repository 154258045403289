import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { FND0133_USERS_LOGIN_LOG_FORM_ID } from "../../util/constants/forms";
import UsersLogTable from "./listing";
import UsersLoginLogFilter from "./listing/Filters";

const UsersLog = () => {
    const { t } = useTranslation("usersLoginLog");

    const searchTextRef = useRef();
    const usersLogTableRef = useRef();
    const filterRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={FND0133_USERS_LOGIN_LOG_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('user')}
                        search={() => usersLogTableRef.current.refresh()}
                        formId={FND0133_USERS_LOGIN_LOG_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <UsersLogTable
                usersLogTableRef={usersLogTableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
            />

            <UsersLoginLogFilter usersLogTableRef={usersLogTableRef} ref={filterRef} />
        </>
    );
}

export default UsersLog;