import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { encryptText } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValues } from "../../../util/apis";
import { PROPORTY_TYPE, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM2659_PROPERTIES_TYPES_FORM_ID } from "../../../util/constants/forms";

const dialogId = "properties-types-dialog";

const Tab1 = (props) => {

    const { tab, data, setData } = props;
    const { t } = useTranslation('propertiesTypes');

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <CodeInput
                            id={`${dialogId}-tab-1-code`}
                            value={data.code}
                            onBlur={(value) => _handleDataChanged("code", value)}
                            column="MYO_CODE"
                            formId={RSM2659_PROPERTIES_TYPES_FORM_ID}
                        />
                    </div>
                    <div className="w100">
                        <label id="accounts-groups-status-lbl" data-label={encryptText("MYO_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="accounts-groups-status"
                            sidePanelMode
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-tab-1-english-name-lbl`} data-label={encryptText("MYO_DESC_LO")} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-english-name`}
                            value={data?.descLo}
                            onBlur={value => _handleDataChanged("descLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("MYO_SH_DESC_LO")} className='form-label'>{t('short_english')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-short-english-name`}
                            value={data?.shDescLo}
                            onBlur={value => _handleDataChanged("shDescLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-tab-1-arabic-name-lbl`} data-label={encryptText("MYO_DESC_FO")} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => _handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("MYO_SH_DESC_FO")} className='form-label'>{t('short_arabic')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-short-arabic-name`}
                            value={data?.shDescFo}
                            onBlur={value => _handleDataChanged("shDescFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-tab-1-type-lbl`} data-label={encryptText("MYO_TYPE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-tab-1-type`}
                            value={data.type}
                            api={() => getDomainValues(PROPORTY_TYPE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => _handleDataChanged("type", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("MYO_REM")} className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-remarks`}
                            value={data?.rem}
                            onBlur={value => _handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;