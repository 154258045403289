import OptionsButton from "@ipgd-gauge/options-buttons";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import FiltersIcon from "../../icons/Filters";
import { RSM3268_CUSTOMER_DUES_FOLLOW_UP } from "../../util/constants/forms";
import CustomerDuesFollowUpFilter from "./Filters";
import Lines from "./lines";

const CustomerDuesFollowUp = ({ isTab }) => {
    const { t } = useTranslation();
    const filterRef = useRef();
    const [isTrnx, setIsTrnx] = useState(true);
    const listRef = useRef(null);


    return (
        <>
            <div className="headerPagesPanel">
                {!isTab ?
                    <FormTitle
                        formId={RSM3268_CUSTOMER_DUES_FOLLOW_UP} /> :
                    <div></div>
                }
                <div className="headerSearchPanel">
                    <div className="details-option-btn">
                        <OptionsButton
                            id='report-type'
                            options={[
                                {
                                    id: 'customer-dues-follow-up-no-trxs',
                                    title: t('customerDuesFollowUp:no_trxs'),
                                    selected: isTrnx,
                                    action: () => {
                                        setIsTrnx(true);
                                        listRef.current.refresh(null, null, true);
                                    }
                                },
                                {
                                    id: 'customer-dues-follow-up-incomes',
                                    title: t('customerDuesFollowUp:incomes'),
                                    selected: !isTrnx,
                                    action: () => {
                                        setIsTrnx(false);
                                        listRef.current.refresh(null, null, false);
                                    }
                                },
                            ]} />
                    </div>

                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>
            <Lines isTab={isTab} filterRef={filterRef} isTrnx={isTrnx} ref={listRef} />

            <CustomerDuesFollowUpFilter
                ref={filterRef}
                listRef={listRef}
            />
        </>
    );
}

export default CustomerDuesFollowUp;