import lottie from "lottie-web";
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const LottieViewer = (props, ref) => {
    const { name, className, loop, autoplay } = props;
    const lottieRef = useRef(null);

    useEffect(() => {
        if (lottie && lottieRef.current) {
            const animation = lottie.loadAnimation({
                name: name,
                container: lottieRef.current,
                renderer: 'svg',
                loop: loop !== undefined ? loop : true,
                autoplay: autoplay, // Set autoplay to false
                path: `/lottie/${name}.json`,
            });

            return () => animation.destroy();
        }
    }, []);

    const play = () => {
        if (lottie && !autoplay) {
            lottie.play(name);
        }
    }

    const stop = () => {
        if (lottie && !autoplay) {
            lottie.stop(name);
        }
    }

    useImperativeHandle(ref, () => ({
        play,
        stop
    })
    )

    return (
        <div ref={lottieRef} className={`d-flex align-items-center justify-content-center ${className || ''}`} />
    )
}

export default forwardRef(LottieViewer);