
function TasksStatisticsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <path
                fill="#292d32"
                d="M-2561.25 2998.5c-2.77 0-4.647-.587-5.906-1.845s-1.844-3.135-1.844-5.906v-6c0-2.77.586-4.647 1.844-5.906s3.135-1.844 5.906-1.844h6c2.77 0 4.646.586 5.906 1.844s1.845 3.135 1.845 5.906v6c0 2.771-.587 4.648-1.845 5.906s-3.136 1.845-5.906 1.845zm-4.845-18.595c-.959.958-1.406 2.5-1.406 4.845v6c0 2.347.446 3.886 1.406 4.846s2.5 1.4 4.845 1.4h6c2.347 0 3.886-.446 4.844-1.4s1.406-2.5 1.406-4.846v-6c0-2.346-.447-3.886-1.406-4.845s-2.5-1.406-4.844-1.406h-6c-2.35.001-3.886.448-4.844 1.407zm2.814 10.875a.749.749 0 010-1.06l3.2-3.2a.751.751 0 01.6-.216.751.751 0 01.55.33l1.093 1.639 2.272-2.272h-.189a.75.75 0 01-.75-.75.75.75 0 01.75-.75h2a.745.745 0 01.347.085l.012.006h.006l.009.005h.015l.009.006h.006l.01.007.011.008.011.008.01.008.01.008.009.008.006.005.008.007.007.007.006.006.013.013a.747.747 0 01.22.555v1.976a.75.75 0 01-.75.75.75.75 0 01-.75-.75v-.19l-2.92 2.919a.748.748 0 01-.6.216.746.746 0 01-.55-.33l-1.093-1.639-2.553 2.553a.746.746 0 01-.53.219.748.748 0 01-.504-.187z"
                data-name="Union 18"
                transform="translate(2569 -2977)"
            ></path>
        </svg>
    );
}

export default TasksStatisticsIcon;