import { ACTION_CLEAR_FORMS_FILTER, ACTION_SAVE_FORMS_FILTER } from "../actions";

const formsFilterReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_SAVE_FORMS_FILTER:
            return {
                ...state,
                [action.formId]: action.data,
            };

        case ACTION_CLEAR_FORMS_FILTER:
            return {};

        default:
            return state;
    }
};

export default formsFilterReducer;