import React from "react";

function ChevronLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
    >
      <defs>
        <clipPath id="13l0def8na">
          <path
            fill="transparent"
            d="M3.781 7.8L.213 4.474A.644.644 0 010 4a.627.627 0 01.052-.25v-.025l.005-.01.021-.015a.67.67 0 01.131-.168L3.781.195a.752.752 0 011.01 0 .636.636 0 010 .943l-2.352 2.2h6.847A.692.692 0 0110 4a.692.692 0 01-.715.667H2.438l2.352 2.2a.635.635 0 010 .943.75.75 0 01-1.01 0z"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 178408">
        <path
          fill="transparent"
          d="M6.219.2l3.568 3.326A.644.644 0 0110 4a.627.627 0 01-.052.25v.025l-.005.01-.021.015a.67.67 0 01-.131.168L6.219 7.805a.752.752 0 01-1.01 0 .636.636 0 010-.943l2.352-2.2H.714A.692.692 0 010 4a.692.692 0 01.715-.667h6.847l-2.352-2.2a.635.635 0 010-.943.75.75 0 011.01 0z"
          data-name="Mask"
        ></path>
        <g
          clipPath="url(#13l0def8na)"
          data-name="Mask Group 52"
          transform="rotate(180 5 4)"
        >
          <g data-name="Black Base-900">
            <path
              fill="#0a1f44"
              d="M-4.167-5h20v20h-20z"
              data-name="Black / Black Base-900"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ChevronLeft;
