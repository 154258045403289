import { Outlet } from "react-router-dom";

const MAIN = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default MAIN;