import React from "react";

function EstimationsHistoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.5"
      height="21.45"
      viewBox="0 0 23.5 21.45"
    >
      <g
        fill="#088587"
        data-name="Group 178503"
        transform="translate(-4531.246 -2873.3)"
      >
        <path
          d="M6.453-.75h12.3a2.977 2.977 0 012.76 1.836 2.954 2.954 0 01.149 1.889v.006l-3.37 13.528a2.974 2.974 0 01-2.908 2.282H2.243a2.959 2.959 0 01-2.4-1.219 2.989 2.989 0 01-.453-2.684L3.6 1.367A3.018 3.018 0 016.453-.75zM20.2 2.622a1.466 1.466 0 00-.067-.939l-.007-.017A1.482 1.482 0 0018.753.75h-12.3A1.507 1.507 0 005.03 1.811L.819 15.333a1.5 1.5 0 00.229 1.349 1.473 1.473 0 001.2.608h13.14a1.479 1.479 0 001.45-1.133v-.008z"
          transform="translate(4531.997 2875.96)"
        ></path>
        <path
          d="M4.78 16.75H0A.75.75 0 01-.75 16a.75.75 0 01.75-.75h4.78a1.448 1.448 0 001.07-.464 1.466 1.466 0 00.392-1.114L5.252.054a.75.75 0 01.694-.8.75.75 0 01.8.694l.99 13.62a2.992 2.992 0 01-.791 2.244 2.933 2.933 0 01-2.165.938z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(4547 2878)"
        ></path>
        <path
          d="M0 5.07a.752.752 0 01-.176-.021.75.75 0 01-.554-.9L.311-.176a.75.75 0 01.9-.554.75.75 0 01.554.9L.729 4.5A.75.75 0 010 5.07z"
          data-name="Vector"
          transform="translate(4540.68 2874.06)"
        ></path>
        <path
          d="M0 5.09a.753.753 0 01-.159-.017.75.75 0 01-.574-.892l.94-4.34A.75.75 0 011.1-.733a.75.75 0 01.574.892L.733 4.5A.75.75 0 010 5.09z"
          data-name="Vector"
          transform="translate(4547.38 2874.05)"
        ></path>
        <path
          d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(4538.7 2884)"
        ></path>
        <path
          d="M8 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h8a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(4537.7 2888)"
        ></path>
      </g>
    </svg>
  );
}

export default EstimationsHistoryIcon;