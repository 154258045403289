import Dropdown from "rc-dropdown";
import Menu, { MenuItem } from 'rc-menu';
import { Children, cloneElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorPaletteIcon from "../../icons/ColorPalette";
import DashHomeIcon from "../../icons/DashHome";
import ReloadDashBordIcon from "../../icons/ReloadDashBord";
import ViewDashIcon from "../../icons/ViewDash";
import { getUserDashboardColors, saveUserDashboardColors } from "../../util/apis";
import { getColorList, handleError, isNotEmpty } from "../../util/util";
import LoadingSpinner from "../loading-spinner";
import style from "./style.module.scss";
import Spinner from '@ipgd-gauge/spinner';
import CountUp from 'react-countup';
import { DASHBOARD_NUMBER_ANIMATION_DURATION } from "../../util/constants";

const ChartCard = (props) => {
    const { id, icons, title, width, handleClearIds, selectedId, includedIds, children, count, chartName, cardStyle } = props;

    const { t } = useTranslation();

    const [selectedColor, setSelectedColor] = useState({ dashboardName: chartName, datasetColor: 2 });
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        getUserDashboardColors(chartName)
            .then(response => {
                if (response.data.id) {
                    setSelectedColor(response.data)
                }
            })
            .catch((error) => {
                handleError(error, null, t)
                console.log("🚀 ~ file: index.js ~ line 188 ~ TransactionsStatistics ~ null", null)
            }
            )
    }, []);

    const _fullCard = () => {
        document.getElementById(id).classList.toggle(style.fullCard);
        document.getElementById(`${id}-blackScreen`).classList.toggle(style.fullCardActive);
        document.getElementById('doughnutChart').classList.toggle('fullScreenChart');
        setFullScreen(!fullScreen);
    };

    const _saveUserDashboardColors = (index) => {
        selectedColor.datasetColor = index + 1;
        setSelectedColor({ ...selectedColor })
        saveUserDashboardColors(selectedColor);
    }

    const colorPalette = () => {
        return (
            <Menu className={style.colorPalette}>
                {getColorList().map((colors, index) => {
                    return <MenuItem key={index}>
                        <div key={index} className={style.colorsRows} onClick={() => _saveUserDashboardColors(index)}>
                            {colors.map((color, index) => {
                                return <div key={index} style={{ background: color }}></div>
                            })}
                        </div>
                    </MenuItem>
                })}
            </Menu>
        )
    }

    return (
        <>
            <div onClick={_fullCard} id={`${id}-blackScreen`} className={style.fullCardBg}></div>
            <div id={id} className={`w${width || '50'} ${style.card} ${cardStyle || ''}`}>
                <div className={style.dashCard}>
                    <div className={style.headerCard}>
                        <div className={style.titCard}>
                            <div>
                                {icons}
                                <label>{title}</label>
                                <label dir="ltr">
                                    ({
                                        count == null ?
                                            <Spinner className={style.customSpinner} />
                                            :
                                            <CountUp duration={DASHBOARD_NUMBER_ANIMATION_DURATION} end={count} />
                                    })
                                </label>
                            </div>
                            <LoadingSpinner id={`${id}-chartcard-whaiting`} />
                        </div>

                        <div className={style.headerActions}>

                            {(isNotEmpty(selectedId.current) || (includedIds.current || [])?.length > 0) &&
                                <button onClick={() => {
                                    handleClearIds(id)
                                }} className={style.reloadIcon}>
                                    <ReloadDashBordIcon />
                                </button>
                            }

                            <Dropdown
                                animation="slide-up"
                                trigger={['click']}
                                overlay={colorPalette}
                                align={{ offset: [0, 0] }}
                            >
                                <button>
                                    <ColorPaletteIcon />
                                </button>
                            </Dropdown>

                            <button onClick={_fullCard}>
                                <ViewDashIcon />
                            </button>
                        </div>
                    </div>

                    <div>
                        {Children.map(children, child => {
                            return cloneElement(child, { selectedColor, fullScreen })
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}


export default ChartCard;