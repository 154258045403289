import Spinner from '@ipgd-gauge/spinner';
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import ExcelIcon from "../../icons/Excel";
import FiltersIcon from "../../icons/Filters";
import { exportTransactionDataToExcel } from "../../util/apis";
import { EXPORT_ACTION } from "../../util/constants/actions";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../util/constants/forms";
import EstimationTransactionsTable from "./listing";
import EstimationTransactionsFilter from "./listing/Filter";
import style from './style.module.scss';

const EstimationTransactions = ({ isTab }) => {

    const { t } = useTranslation();
    const tableRef = useRef();
    const searchTextRef = useRef();
    const estimationTransactionsFilterRef = useRef();

    const user = useSelector(state => state.persistedReducer.userReducer.user);

    const _exportExcelAction = (id) => {
        let filterData = {
            searchWhereValue: searchTextRef.current.getSearchText(),
            ...estimationTransactionsFilterRef?.current?.getFilterData()
        };
        exportTransactionDataToExcel(filterData, "EXCEL", id)
    }

    return (
        <>
            <div className="headerPagesPanel">
                {!isTab ?
                    <FormTitle formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID} />
                    : <div></div>
                }
                <div className={`headerSearchPanel ${style.headerSearchPanel}`}>
                    <div className="frm-header-actions">
                        <button
                            id={`form-action-${EXPORT_ACTION}`}
                            className={style.actionButtons}
                            onClick={() => _exportExcelAction(`form-action-${EXPORT_ACTION}-spinner`)}
                        >
                            <ExcelIcon color="green" />
                            <Spinner
                                id={`form-action-${EXPORT_ACTION}-spinner`}
                                className={style.spinnerButton}
                            />
                        </button>
                    </div>
                    <QuickSearch
                        id="valuation-transactions-quick-search"
                        ref={searchTextRef}
                        placeholder={`${t('estimationTransaction:code')}, ${t('estimationTransaction:desc')}, ${t('estimationTransaction:ref_num')}`}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => estimationTransactionsFilterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>


            <EstimationTransactionsFilter
                ref={estimationTransactionsFilterRef}
                user={user}
                tableRef={tableRef}
            />

            <EstimationTransactionsTable
                user={user}
                isTab={isTab}
                tableRef={tableRef}
                estimationTransactionsFilterRef={estimationTransactionsFilterRef}
                searchTextRef={searchTextRef}
            />
        </>
    )
}

export default EstimationTransactions;