import ModalComponent from '@ipgd-gauge/modal';
import { forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { saveCurrentRecordId, saveSelectedRecords } from '../../store/formSelectedRecords/actions';
import { getFormId } from '../../util/formUtil';
import { useHotkeys } from 'react-hotkeys-hook';
import { SAVE_ACTION_SHORT_CUT } from '../../util/constants/hotkeys';
import LogDateDialog from "../../views/common/dialogs/log-date";
/**
 * 
 * @param {id} manditory
 * @param {title} optional
 * @param {className} optional
 * @param {children} optional
 * @param {withFooter} optional
 * @param {withHeader} optional
 * 
 * @param {withOkButton} optional
 * @param {okBtnLabel} optional
 * @param {okBtnAction} optional
 * @param {okBtnStyleAction} optional
 * @param {isTableModal} optional
 * 
 * @param {withCancelButton} optional
 * @param {cancelBtnLabel} optional
 * @param {cancelBtnAction} optional
 * @param {cancelBtnStyleAction} optional
 * 
 * @param {preOpen} optional
 * @param {postOpen} optional
 * @param {preClose} optional
 * @param {postClose} optional
 * @param {customHeader} optional
 */

const Modal = (props, ref) => {

    // props
    const { id, title, className, children, withFooter, withHeader, withOkButton, okBtnLabel, okBtnAction, okBtnStyleAction,
        withCancelButton, cancelBtnLabel, cancelBtnAction, cancelBtnStyleAction, isTableModal, isOKBtnDisabled, preOpen, postOpen,
        preClose, postClose, customHeader, style, customStyle, nextPreviousAction, hideOkBtn, largSize, smallSize , showLog , user , masterId } = props;

    const dispatch = useDispatch();
    const path = useLocation();
    const formId = getFormId();
    const selectedIds = useSelector(state => state.persistedReducer.formSelectedRecords[formId]?.selectedIds || [])
    useHotkeys(okBtnAction ? SAVE_ACTION_SHORT_CUT : "", okBtnAction ? okBtnAction : "");
    const logDialogRef = useRef();

    return (
        <>
            <ModalComponent
                id={id}
                ref={ref}
                title={title}
                className={className}
                children={children}
                withFooter={withFooter}
                withHeader={withHeader}
                withOkButton={withOkButton}
                okBtnLabel={okBtnLabel}
                okBtnAction={okBtnAction}
                okBtnStyleAction={okBtnStyleAction}
                withCancelButton={withCancelButton}
                cancelBtnLabel={cancelBtnLabel}
                cancelBtnAction={cancelBtnAction}
                cancelBtnStyleAction={cancelBtnStyleAction}
                isTableModal={isTableModal}
                isOKBtnDisabled={isOKBtnDisabled}
                preOpen={preOpen}
                postOpen={postOpen}
                preClose={preClose}
                postClose={postClose}
                customHeader={customHeader}
                style={style}
                customStyle={customStyle}
                nextPreviousAction={nextPreviousAction}
                hideOkBtn={hideOkBtn}
                largSize={largSize}
                smallSize={smallSize}
                selectedIds={selectedIds}
                cleareSelectedRecords={() => dispatch(saveSelectedRecords(formId, []))}
                formId={formId}
                isTransactionForm={false}
                changeCurrentRecordId={(newId) => dispatch(saveCurrentRecordId(formId, newId))}
                saveHotKeyShortCut={SAVE_ACTION_SHORT_CUT}
                showLog={showLog}
                masterId={masterId}
                showLogAction={() => logDialogRef?.current?.open(masterId)}

            />
            {showLog &&
                <LogDateDialog ref={logDialogRef} user={user} formId={formId} />
            }
        </>
    )
}

export default forwardRef(Modal)