import GMultiInsertDialog from '@ipgd-gauge/multi-insert-dialog';
import { forwardRef } from "react";
import { saveFormsFilter } from '../../store/formsFilter/actions';
import { updateUserTableProfiles } from '../../store/userTableProfiles/actions';
import { deleteUserTableProfiles, getUserTableProfiles, saveUserTableProfiles, updateSelectedUserTableProfiles } from '../../util/apis';
import { getUser } from '../../util/cookies';
import Waiting from '../waiting';

const MultiInsertDialog = (props, ref) => {

    const { id, title, columns, api, rowHeight, apiParams, okBtnAction, searchPlaceholder, dialogStyle, tableStyle, selectedIds, idName, idSelectors, tableWidth, isSmallSize, AdvanceFilters, AdvanceFiltersProps, formId } = props

    return (
        <GMultiInsertDialog
            ref={ref}
            id={id}
            title={title}
            columns={columns}
            api={api}
            rowHeight={rowHeight}
            apiParams={apiParams}
            okBtnAction={okBtnAction}
            searchPlaceholder={searchPlaceholder}
            dialogStyle={dialogStyle}
            tableStyle={tableStyle}
            selectedIds={selectedIds}
            idName={idName}
            idSelectors={idSelectors}
            tableWidth={tableWidth}
            isSmallSize={isSmallSize}
            AdvanceFilters={AdvanceFilters}
            AdvanceFiltersProps={AdvanceFiltersProps}
            WaitingComponent={Waiting}
            saveFormsFilter={saveFormsFilter}
            updateUserTableProfiles={updateUserTableProfiles}
            deleteUserTableProfiles={deleteUserTableProfiles}
            getUserTableProfiles={getUserTableProfiles}
            saveUserTableProfiles={saveUserTableProfiles}
            updateSelectedUserTableProfiles={updateSelectedUserTableProfiles}
            user={getUser()}
            formId={formId}
        />)
}

export default forwardRef(MultiInsertDialog);