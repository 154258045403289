import React from "react";

function OpeningMessgeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.063"
      height="22.124"
      viewBox="0 0 24.063 22.124"
    >
      <defs>
        <clipPath id="Opening-path">
          <path
            fill="#757575"
            d="M0 0H24.063V22.124H0z"
            data-name="Rectangle 161933"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 175685">
        <g clipPath="url(#Opening-path)" data-name="Group 175684">
          <path
            fill="#757575"
            d="M24.063 9.3c0-.1-.015-.191-.02-.286a.908.908 0 00-.028-.426 5.862 5.862 0 00-2.967-4.413L14.878.743a5.827 5.827 0 00-5.694 0L3.015 4.171A5.862 5.862 0 00.048 8.584a.908.908 0 00-.028.426C.015 9.1 0 9.2 0 9.3v6.967a5.837 5.837 0 001.67 4.083c0 .006 0 .012.01.017l.014.011a5.844 5.844 0 004.168 1.75H18.2a5.839 5.839 0 004.168-1.75l.014-.011s0-.011.01-.017a5.837 5.837 0 001.67-4.083zm-1.851 6.967a3.984 3.984 0 01-.665 2.2l-4.479-3.716 5.145-4zM3.914 5.79l6.17-3.428a3.983 3.983 0 013.894 0l6.17 3.428a4 4 0 011.969 2.688l-7.624 5.931a4.011 4.011 0 01-4.926 0L1.945 8.478A4 4 0 013.914 5.79m-1.4 12.677a3.987 3.987 0 01-.665-2.2v-5.518l5.145 4zm3.345 1.807a3.959 3.959 0 01-1.973-.54L8.5 15.911a5.827 5.827 0 007.07 0l4.609 3.822a3.963 3.963 0 01-1.973.54z"
            data-name="Path 6431"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default OpeningMessgeIcon;