import React from "react";

function BuilderProceduresIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/bold/layer" transform="translate(-620 -380)">
        <g fill="#ff7b66">
          <path
            d="M16.44 2.895L10.58.285a4.143 4.143 0 00-3.02 0L1.7 2.895C.22 3.555 0 4.455 0 4.935s.22 1.38 1.7 2.04l5.86 2.61a3.835 3.835 0 001.51.29 3.835 3.835 0 001.51-.29l5.86-2.61c1.48-.66 1.7-1.56 1.7-2.04s-.21-1.38-1.7-2.04z"
            transform="translate(622.93 381.995)"
          ></path>
          <path
            d="M9.68 6.79a2.829 2.829 0 01-1.11-.23l-6.74-3A3.3 3.3 0 010 .74a.74.74 0 011.48 0 1.815 1.815 0 00.95 1.46l6.74 3a1.267 1.267 0 001.01 0l6.74-3a1.8 1.8 0 00.95-1.46.74.74 0 011.48 0 3.309 3.309 0 01-1.83 2.82l-6.74 3a2.745 2.745 0 01-1.1.23z"
            data-name="Vector"
            transform="translate(622.32 390.25)"
          ></path>
          <path
            d="M9.68 6.79a2.829 2.829 0 01-1.11-.23l-6.74-3A3.085 3.085 0 010 .74a.74.74 0 011.48 0 1.6 1.6 0 00.95 1.46l6.74 3a1.267 1.267 0 001.01 0l6.74-3a1.6 1.6 0 00.95-1.46.74.74 0 011.48 0 3.1 3.1 0 01-1.83 2.82l-6.74 3a2.745 2.745 0 01-1.1.23z"
            data-name="Vector"
            transform="translate(622.32 395.21)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default BuilderProceduresIcon;
