import { useRef } from "react";
import { useTranslation } from 'react-i18next';
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import { RSM3232_DELEGATION_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import DelegationDialog from "./dialogs";
import DelegationTable from "./listing";

const Delegation = () => {
    const { t } = useTranslation("delegation");
    const searchTextRef = useRef();
    const delegationDialogRef = useRef();
    const delegationTableRef = useRef();

    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3232_DELEGATION_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('to_user') + " , " + t('from_user') + " , " + t('amount') + " , " + t('remarks') + " , " + t('status')}
                        search={() => delegationTableRef?.current?.refresh()}
                        formId={RSM3232_DELEGATION_FORM_ID}
                    />
                </div>
            </div>
            <DelegationTable
                user={user}
                delegationTableRef={delegationTableRef}
                delegationDialogRef={delegationDialogRef}
                searchTextRef={searchTextRef}
            />
            <DelegationDialog
                ref={delegationDialogRef}
                user={user}
                delegationTableRef={delegationTableRef}
            />
        </>
    )
}

export default Delegation;