import { handleScrollShadow } from '@ipgd-gauge/utils';
import { useTranslation } from 'react-i18next';
import AllIcon from '../../../../icons/All';
import { OpenCloseMenu, TrnxMode1, TrnxMode2 } from '../../../../icons/TrnxMode';
import { TRX_DISPLAY_MODE_1, TRX_DISPLAY_MODE_2 } from '../../../../util/constants';
import HeaderCard from './Card';
import style from './style.module.scss';

const Header = (props) => {
    const { blocks, linesRef, displayMode, handleMode, handleSelectBlock, handleShowHideMenu } = props;
    const { t } = useTranslation("estimationTransaction");

    return (
        <div id='estimation-trx-menu' className={`frm-entry-panel ${style.cardsMenuPanel}`}>
            <button id='estimation-trx-btn' onClick={handleShowHideMenu} className={style.openCloseBtn}>
                <OpenCloseMenu />
            </button>
            <div>
                <div id='estimation-trx-menu-header' className={`frm-header-title-panel ${style.blocksTitlePanel}`}>
                    <div>
                        <div className={style.titleIcon}><AllIcon /> </div>
                        <label className={`frm-title ${style.title}`}>{t('trnx_details')}</label>
                    </div>
                    <div className={style.modePanel}>
                        <button className={displayMode == TRX_DISPLAY_MODE_2 ? style.selectedMode : ''}
                            onClick={() => handleMode(TRX_DISPLAY_MODE_2)}>
                            <TrnxMode2 />
                        </button>
                        <button className={displayMode == TRX_DISPLAY_MODE_1 ? style.selectedMode : ''}
                            onClick={() => handleMode(TRX_DISPLAY_MODE_1)}>
                            <TrnxMode1 />
                        </button>
                    </div>
                </div>
                <div className={style.stepContainer} id="headerCard" onScroll={handleScrollShadow}>
                    {blocks.map((block, index) =>
                        <HeaderCard
                            key={block.id}
                            index={index}
                            block={block}
                            linesRef={linesRef}
                            displayMode={displayMode}
                            handleSelectBlock={handleSelectBlock}
                        />
                    )
                    }
                    <HeaderCard block={{ id: '0' }} linesRef={linesRef}
                        displayMode={displayMode} handleSelectBlock={handleSelectBlock} />
                </div>
            </div>
        </div>
    )
}

export default Header;