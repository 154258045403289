import FrmInput from "@ipgd-gauge/frm-input";
import { encryptText } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Map from "../../../components/map";
import MapPinIcon from "../../../icons/MapPin";
import style from './style.module.scss';

const Tab2 = (props) => {

    const { tab, handleDataChanged, district, mapRef } = props;
    const { t } = useTranslation("cities");

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className={`container  ${style.summaryContainer} `}>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label data-label={encryptText("CRI_LATITUDE")} className='form-label'>{t('latitude')}</label>
                        <FrmInput
                            id="latitude_district"
                            type="number"
                            value={district.latitude}
                            onBlur={(value) => handleDataChanged("latitude", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("CRI_LONGITUDE")} className='form-label'>{t('longitude')}</label>
                        <FrmInput
                            id="longitude_district"
                            type="number"
                            value={district.longitude}
                            onBlur={(value) => handleDataChanged("longitude", value)}
                        />
                    </div>
                    <div className="w100">
                        <div className={style.mapContainer}>
                            <Map
                                withSearch
                                key={district.id}
                                ref={mapRef}
                                value={district?.longitude && district.latitude ? `${district?.latitude},${district?.longitude}` : null}
                                withPin={true}
                                MapPinCard={<MapPinIcon />}
                                handleChange={(e) => {
                                    handleDataChanged("longitude", e.lng)
                                    handleDataChanged("latitude", e.lat)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab2;