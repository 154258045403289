import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AdvanceFilter from "../../components/advance-filter";
import { getRealEstateUserLOV } from "../../util/apis";
import { RSM3240_NOT_EXECUTED_TASKS_FORM_ID } from "../../util/constants/forms";

const NotExecutedTasksFilter = (props, ref) => {
    const { fetchData } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const user = useSelector(state => state.persistedReducer.userReducer.user);

    const [filterData, setFilterData] = useState({});

    const getFilterData = () => {
        return filterData.users;
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }));

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="not-executed-tasks-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={fetchData}
            getDefaultFilter={() => ({ users: [user?.id] })}
            hideMoreOptions
            numOfRows={1}
            formId={RSM3240_NOT_EXECUTED_TASKS_FORM_ID}
        >
            <div className="container">
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="row advanceFilterContainer filter-panel-row">
                    <div className={`w50`}>
                        <label className='form-label'>{t('notExecutedTasks:users')}</label>
                        <FrmSelectMenu
                            id="not-executed-tasks-filter-users"
                            isMulti={true}
                            isAsync={true}
                            value={filterData.users}
                            defualtValueId={filterData.users}
                            defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.users, true)}
                            api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => {
                                filterData.users = value || [];
                                setFilterData({ ...filterData })
                            }}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div >
        </AdvanceFilter>
    )
}

export default forwardRef(NotExecutedTasksFilter);