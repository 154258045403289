import { PROPERTIES_ACCOUNT_TYPES } from "../../../util/constants";
import { RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID } from "../../../util/constants/forms";
import FinancialIntegration from "../../common/dialogs/financial-intergration";

const Tab3 = (props) => {
    const { tab, data, setData } = props;

    return (
        <div className={tab == 3 ? 'active' : ''}>
            <FinancialIntegration
                data={data}
                setData={setData}
                type={PROPERTIES_ACCOUNT_TYPES}
                formId={RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID}
            />
        </div>
    );
}

export default Tab3;