import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import { MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import PropertiesValuationCustomReportsDialog from "./dialogs";
import PropertiesValuationCustomReportsTable from "./listing";

const PropertiesValuationCustomReports = () => {

    const { t } = useTranslation("customReports");
    const searchTextRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={`${t('description')}`}
                        search={() => tableRef.current.refresh()}
                        formId={MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID}
                    />
                </div>
            </div>
            <PropertiesValuationCustomReportsTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                dialogRef={dialogRef}
            />
            <PropertiesValuationCustomReportsDialog
                ref={dialogRef}
                user={user}
                tableRef={tableRef}
            />
        </>
    )
}

export default PropertiesValuationCustomReports;