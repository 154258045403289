import React from "react";

function MyTasksIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.499"
      viewBox="0 0 21.5 21.499"
    >
      <g
        fill="#292d32"
        data-name="Group 179962"
        transform="translate(-685.25 -189.251)"
      >
        <path
          d="M9.4 15.75H5.6c-2.259 0-3.794-.483-4.83-1.52S-.75 11.659-.75 9.4V5.6c0-2.259.483-3.794 1.52-4.83S3.341-.75 5.6-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75h-3C2.019.75.75 2.019.75 5.6v3.8c0 3.581 1.269 4.85 4.85 4.85h3.8c3.581 0 4.85-1.269 4.85-4.85v-3a.75.75 0 011.5 0v3c0 2.259-.483 3.794-1.52 4.83s-2.571 1.52-4.83 1.52z"
          transform="translate(686 195)"
        ></path>
        <path
          d="M6.4 7.15H3.2a4.028 4.028 0 01-3.03-.92A4.028 4.028 0 01-.75 3.2V0a.75.75 0 01.463-.693.75.75 0 01.817.163l6.4 6.4a.75.75 0 01-.53 1.28zM.75 1.811V3.2c0 1.992.458 2.45 2.45 2.45h1.389z"
          data-name="Vector"
          transform="translate(694.6 195)"
        ></path>
        <path
          d="M5271.19 11136.159a.751.751 0 01.749-.751 2.06 2.06 0 002.06-2.058v-5.441h-2.25a3.193 3.193 0 01-3.748-3.751v-2.25h-5.251a2.256 2.256 0 00-2.25 2.25.749.749 0 11-1.5 0 3.754 3.754 0 013.748-3.748h5.6a.736.736 0 01.2.026.758.758 0 01.732.191l6 6a.756.756 0 01.184.308c0 .01.006.021.009.031v.008c0 .01 0 .019.006.028 0 .012 0 .023.006.035v.033a.669.669 0 010 .094v6.173a3.563 3.563 0 01-3.561 3.559.749.749 0 01-.734-.737zm-1.691-12a1.788 1.788 0 002.25 2.25h1.191l-3.441-3.438z"
          data-name="Union 392"
          opacity="0.45"
          transform="translate(-4568.75 -10931.159)"
        ></path>
      </g>
    </svg>
  );
}

export default MyTasksIcon;