import ActionsButton from "@ipgd-gauge/actions-button";
import { addUrlParam, checkValidation, confirmationDialog, handleError, hideWaiting, isAllowedAction, isNotEmpty, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddBtnIcon from "../../../icons/AddBtn";
import SaveIcon from "../../../icons/Save";
import { deletePriceAgreement, savePriceAgreement } from "../../../util/apis";
import { DELETE_ACTION, NEW_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3007_PRICE_AGREEMENT_FORM_ID } from "../../../util/constants/forms";
import { isLocalCurrency } from "../../../util/util";
import { useHotkeys } from 'react-hotkeys-hook';
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import DeleteTowIcon from "../../../icons/Delete2";
import { useNavigate } from "react-router";

const COMMON_ID = "price-agreements";

const Actions = (props) => {
    const { currentUser, data, setData, newAction, fromSaveActionRef } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [actions, setActions] = useState([]);

    const _saveAction = () => {
        if (!checkValidation([{ id: COMMON_ID },
        {
            id: `${COMMON_ID}-property-type-fees-upon-table`,
            title: t('priceAgreement:property_type_fees_upon'),
            data: data.priceListAgreementCustPropertyTypes,
            disabledConditions: {
                priceAfc: () => isLocalCurrency(data?.fcuId)
            }
        },
        {
            id: `${COMMON_ID}-location-fess-upon-table`,
            title: t('priceAgreement:location_fess_upon'),
            data: data.priceListAgreementCustomLocations,
            disabledConditions: {
                priceAfc: () => isLocalCurrency(data?.fcuId)
            }
        },
        {
            id: `${COMMON_ID}-custom-fees-table`,
            title: t('priceAgreement:custom_fees'),
            data: data.priceListAgreementFieldsCustoms,
            disabledConditions: {
                priceAfc: () => isLocalCurrency(data?.fcuId)
            }
        }
        ], t)) {
            return;
        }
        showWaiting(`save`, true);
        savePriceAgreement(data)
            .then((response) => {
                fromSaveActionRef.current = true;
                setData({ ...data, ...response.data });
                showSuccessMessage('save_successfully', t);
                addUrlParam("id", response.data?.id);
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                    handleError(error, message, t)
                }
                else
                    handleError(null, message ? `priceAgreement:${message}` : null, t);
            })
            .catch((error) => {
                const message = error?.response?.data?.message;
                if (message.includes('#')) {
                    const messages = message.split('#');
                    if (messages.length == 5) {
                        handleError(null, messages[0], t, [{ name: 'marketerLineNum', value: messages[2] }, { name: 'lineNum', value: messages[4] }])
                    } else if (messages.length == 3) {
                        handleError(null, messages[0], t, [{ name: 'lineNum', value: messages[2] }])
                    } else if (messages.length == 2) {
                        handleError(error, messages[0], t)
                    }
                } else {
                    handleError(error, message, t)
                }
            })
            .finally(() => hideWaiting("save"));
    }

    const _handleThenAction = (isDelete) => {
        showSuccessMessage(isDelete ? "delete_successfully" : null, t);
        navigate("/price-agreements");
    }

    const _deleteAction = () => {
        if (isNotEmpty(data?.id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return deletePriceAgreement(data?.id)
                    .then(() => _handleThenAction(true))
                    .catch((error) => handleError(error, "delete_fields", t));
            });
        }
    }

    const _isAllowedSaveAction = () => {
        return isAllowedAction(currentUser, RSM3007_PRICE_AGREEMENT_FORM_ID, SAVE_ACTION);
    }

    useEffect(() => {
        let temp = [];

        if (isNotEmpty(data?.id) && isAllowedAction(currentUser, RSM3007_PRICE_AGREEMENT_FORM_ID, DELETE_ACTION)) {
            temp.push({
                id: 'delete',
                label: t('delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                icon: <DeleteTowIcon />,
                action: _deleteAction
            })
        }

        if (newAction && isAllowedAction(currentUser, RSM3007_PRICE_AGREEMENT_FORM_ID, NEW_ACTION)) {
            temp.push({
                id: 'new',
                label: t('new'),
                color: "var(--action-copy)",
                action: () => newAction(),
                icon: <AddBtnIcon />
            });
        }

        if (_isAllowedSaveAction())
            temp.push({
                id: 'save',
                label: t('save'),
                hotkey: SAVE_ACTION_SHORT_CUT,
                color: "var(--action-save)",
                icon: <SaveIcon />,
                action: _saveAction
            });


        setActions(temp);
    }, [i18next.language, data]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction();
        }
    }, { enableOnFormTags: true });

    return (<ActionsButton actions={actions} />);
}

export default Actions;