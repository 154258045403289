import React from "react";

function ApproveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        fill="#292d32"
        data-name="Group 180026"
        transform="translate(-574.485 -135.85)"
      >
        <path
          d="M9.25 19.25A9.989 9.989 0 012.187 2.187a9.989 9.989 0 1114.126 14.126A9.929 9.929 0 019.25 19.25zm0-18.6a8.6 8.6 0 108.6 8.6A8.614 8.614 0 009.25.645z"
          opacity="0.4"
          transform="translate(575.235 136.6)"
        ></path>
        <path
          d="M2.58 5.91a.7.7 0 01-.493-.2L-.546 3.074a.7.7 0 010-.987.7.7 0 01.987 0l2.14 2.14L7.362-.546a.7.7 0 01.987 0 .7.7 0 010 .987L3.073 5.707a.7.7 0 01-.493.203z"
          data-name="Vector"
          transform="translate(580.584 143.27)"
        ></path>
      </g>
    </svg>
  );
}

export default ApproveIcon;
