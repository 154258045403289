import React from "react";

function PrevPlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.375"
      height="18.375"
      viewBox="0 0 18.375 18.375"
    >
      <path
        fill="#797979"
        d="M20.7 113.546a1.266 1.266 0 01.39.923v1.312a1.332 1.332 0 01-1.313 1.313h-5.902V123a1.332 1.332 0 01-1.313 1.313H11.25A1.332 1.332 0 019.937 123v-5.906H4.031a1.261 1.261 0 01-.923-.39 1.258 1.258 0 01-.389-.923v-1.312a1.261 1.261 0 01.389-.923 1.265 1.265 0 01.923-.39h5.906v-5.906a1.33 1.33 0 011.313-1.312h1.312a1.33 1.33 0 011.313 1.312v5.906h5.906a1.266 1.266 0 01.923.39"
        data-name="Path 44846"
        transform="translate(-2.719 -105.938)"
      ></path>
    </svg>
  );
}

export default PrevPlusIcon;
