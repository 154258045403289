import { formatDate, formatTime, isEnglish } from '@ipgd-gauge/utils';
import { useTranslation } from 'react-i18next';
import Table from "../../../../components/table";
import style from './style.module.scss';

const LogRow = (props) => {
    const { t } = useTranslation();
    const { data } = props;
    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row_radio`}>
            <div title={data?.sequence}><div>{data?.sequence}</div></div>
            <div title={isEnglish() ? data?.typeDescLo : data?.typeDescFo}><div>{isEnglish() ? data?.typeDescLo : data?.typeDescFo}</div></div>
            <div title={isEnglish() ? data?.userNameLo : data?.userNameFo}><div>{isEnglish() ? data?.userNameLo : data?.userNameFo}</div></div>
            <div title={data?.ipAddress}><div>{data?.ipAddress}</div></div>
            <div title={formatDate(data?.date)}><div>{formatDate(data?.date)}</div></div>
            <div title={formatTime(data?.date)}><div>{formatTime(data?.date)}</div></div>
        </div>
    );
};

const LogTable = (props) => {
    const { user, formId, logs, detailsTableRef } = props;
    const { t } = useTranslation();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('action'), width: '17%' },
        { title: t('user'), width: '25%' },
        { title: t('ip_address'), width: '18%' },
        { title: t('date'), width: '18%' },
        { title: t('time'), width: '17%' },
    ];

    return (
        <div className={style.logDateTableContainer}>
            <Table
                id={`log-table-${formId}`}
                formId={formId}
                columns={columns}
                Row={LogRow}
                tableStyle={style.logDateTable}
                rowHeight={40}
                withRadioButton={true}
                list={logs}
                user={user}
                onRowSelected={(data) => detailsTableRef?.current?.changeSelectedIndex((data?.sequence || 1) - 1)}
            />
        </div>
    )
}

const LogDate = (props) => {

    const { user, formId, logs, detailsTableRef } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('log_date')}
                    </label>
                </div>
            </div>
            <LogTable user={user} formId={formId} logs={logs} detailsTableRef={detailsTableRef} />
        </div>
    )
}

export default LogDate;