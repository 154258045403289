import React from "react";

function QrCodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <path d="M3 11h8V3H3v8zm1-7h6v6H4V4zm17-1h-8v8h8V3zm-1 7h-6V4h6v6zM3 21h8v-8H3v8zm1-7h6v6H4v-6zm1 5h4v-4H5v4zm1-3h2v2H6v-2zM5 9h4V5H5v4zm1-3h2v2H6V6zm13-1h-4v4h4V5zm-1 3h-2V6h2v2zm1 11v-3h2v-3h-3v2h-2v-2h-3v3h2v2h-2v3h3v-2h3zm0-5h1v1h-1v-1zm-5 1v-1h1v1h-1zm2 1h2v2h-2v-2zm-1 4h-1v-1h1v1zM1 7H0V2.5C0 1.122 1.122 0 2.5 0H7v1H2.5C1.673 1 1 1.673 1 2.5V7zm1.5 16H7v1H2.5A2.503 2.503 0 010 21.5V17h1v4.5c0 .827.673 1.5 1.5 1.5zM23 17h1v4.5c0 1.378-1.122 2.5-2.5 2.5H17v-1h4.5c.827 0 1.5-.673 1.5-1.5V17zm1-14.5V7h-1V2.5c0-.827-.673-1.5-1.5-1.5H17V0h4.5C22.878 0 24 1.122 24 2.5z"></path>
    </svg>
  );
}

export default QrCodeIcon;
