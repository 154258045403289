import React from "react";

function RotateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.125"
      height="20.484"
      data-name="Group 179920"
      viewBox="0 0 21.125 20.484"
    >
      <path
        fill="#292d32"
        d="M14.884 12.112a2.659 2.659 0 102.659 2.658 2.662 2.662 0 00-2.659-2.658m0 3.862a1.2 1.2 0 111.2-1.2 1.205 1.205 0 01-1.2 1.2"
        data-name="Path 6389"
        transform="translate(-4.458 -4.417)"
      ></path>
      <path
        fill="#292d32"
        d="M16.9 19.023l.345-.281A10.562 10.562 0 0011.62.055 10.562 10.562 0 000 10.565c0 .151.011.3.021.447v.022h1.454a6.58 6.58 0 01-.02-.468 9.12 9.12 0 019.1-9.117h.008a9.11 9.11 0 014.448 17.059l-.236.132v-4.81a.733.733 0 00-.171-.544.726.726 0 00-.5-.255h-.06a.73.73 0 00-.727.672.586.586 0 000 .116v5.935a.732.732 0 00.731.731h5.886a.731.731 0 10.13-1.456.718.718 0 00-.117 0l-.013-.158v.159z"
        data-name="Path 6390"
      ></path>
    </svg>
  );
}

export default RotateIcon;