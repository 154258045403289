import HijriDateComponent from "@ipgd-gauge/hijri-date";
import { convertGregorianDateToHijri, convertHijriToMilliseconds } from "../../util/apis";

const HijriDate = (props) => {

    const { id, hijriDate, gregorianDate, onChange, className, separator, disabled } = props;

    return (
        <HijriDateComponent
            id={id}
            hijriDate={hijriDate}
            gregorianDate={gregorianDate}
            onChange={onChange}
            className={className}
            separator={separator}
            convertHijriToMilliseconds={convertHijriToMilliseconds}
            convertGregorianDateToHijri={convertGregorianDateToHijri}
            disabled={disabled}
        />
    )
}

export default HijriDate;