import { formatDateNTime } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import Table from "../../../components/table";
import { AllReadIcon, NoOneReadIcon, OneExecuteIcon } from "../../../icons/MsgStatus";
import { RSM3248_OUTBOX_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { virtualizedStyle, data } = props;

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${style.statusesTableRow}`} style={virtualizedStyle}>
            <div>
                <div>
                    <label>
                        {i18next.language == "en" ? data.descLo : data.descFo}
                    </label>
                </div>
            </div>
            <div>
                <div>
                    <label>
                        {formatDateNTime(data.executionDateTime || data.readingDateTime)}
                    </label>
                </div>
            </div>
            <div>
                <div>
                    {data.executionDateTime ?
                        <OneExecuteIcon />
                        :
                        data.userReadingStatus == '1' ?
                            <AllReadIcon />
                            :
                            <NoOneReadIcon />
                    }
                </div>
            </div>
        </div>
    )
}

const ReadingDialog = (props, ref) => {

    const { user } = props;
    const { t } = useTranslation();
    const dialogRef = useRef();

    const [statuses, setStatuses] = useState(null);

    const columns = [
        { title: t('user'), width: '40%' },
        { title: t('outBox:date_time'), width: '30%' },
        { title: t('outBox:status'), width: '30%' },
    ];

    const open = (receivers) => {
        if (receivers) {
            setStatuses(receivers || [])
        }
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close, }))

    return (
        <Modal
            id="customersDialog"
            ref={dialogRef}
            title={t('outBox:receivers')}
            withFooter="true"
            withOkButton={false}
            withCancelButton="true"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={() => setStatuses(null)}
            isTableModal
            className={style.readingDialog}
        >
            <Table
                id="reading-message-dialog"
                rowHeight={40}
                columns={columns}
                Row={Row}
                list={statuses}
                tableStyle={style.statusesTable}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                user={user}
                formId={RSM3248_OUTBOX_FORM_ID}
            />
        </Modal>
    )
}

export default forwardRef(ReadingDialog);