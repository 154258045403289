import React from "react";

function EstimationFormsFieldsBuilderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="20.147"
      viewBox="0 0 19.5 20.147"
    >
      <g data-name="Group 179966" transform="translate(4885.75 11641.055)">
        <g
          fill="#292d32"
          data-name="Group 179965"
          transform="translate(-5506 -12023)"
        >
          <path
            d="M8.4-.75a3.832 3.832 0 011.527.29l5.9 2.619c1.5.66 1.721 1.571 1.721 2.055s-.224 1.4-1.722 2.056L9.919 8.89a3.831 3.831 0 01-1.519.29 3.832 3.832 0 01-1.526-.29L.971 6.27C-.526 5.61-.75 4.7-.75 4.215S-.526 2.82.972 2.159L6.871-.46A3.831 3.831 0 018.4-.75zm0 8.43a2.393 2.393 0 00.913-.16l5.9-2.621c.609-.269.829-.556.829-.685s-.22-.416-.828-.684L9.311.91A2.393 2.393 0 008.4.75a2.393 2.393 0 00-.913.16L1.579 3.53C.97 3.8.75 4.087.75 4.215s.22.416.828.684l5.9 2.621a2.393 2.393 0 00.922.16z"
            transform="translate(621.395 382.695)"
          ></path>
          <path
            d="M9 6.093a2.765 2.765 0 01-1.118-.237L1.1 2.835A3.319 3.319 0 01-.75 0 .75.75 0 010-.75.75.75 0 01.75 0a1.819 1.819 0 00.95 1.464l6.795 3.021a1.237 1.237 0 001.007 0l6.798-3.02A1.819 1.819 0 0017.25 0a.75.75 0 011.5 0 3.32 3.32 0 01-1.85 2.836l-6.785 3.019A2.7 2.7 0 019 6.093z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(621 391)"
          ></path>
          <path
            d="M9 6.093a2.765 2.765 0 01-1.118-.237L1.1 2.835A3.112 3.112 0 01-.75 0 .75.75 0 010-.75.75.75 0 01.75 0a1.61 1.61 0 00.956 1.465l6.789 3.02a1.237 1.237 0 001.007 0l6.798-3.02A1.61 1.61 0 0017.25 0a.75.75 0 011.5 0 3.112 3.112 0 01-1.844 2.835l-6.791 3.02A2.7 2.7 0 019 6.093z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(621 396)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default EstimationFormsFieldsBuilderIcon;