import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../util/constants/forms";
import CreditNotesforCustomersEstimationTable from "./listing";
import CreditNotesforCustomersEstimationFilter from "./listing/Filters";

const CreditNotesforCustomersEstimation = () => {

    const { t } = useTranslation("creditNotesForCustomersEstimation");

    const searchTextRef = useRef();
    const filtersRef = useRef();
    const tableRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('notes_num') + " , " + t('ref_num') + " , " + t('customer') + " , " + t('invoice_num')}
                        search={() => tableRef.current.refresh()}
                        formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <CreditNotesforCustomersEstimationTable
                tableRef={tableRef}
                filtersRef={filtersRef}
                searchTextRef={searchTextRef}
            />

            <CreditNotesforCustomersEstimationFilter
                ref={filtersRef}
                tableRef={tableRef}
            />
        </>
    )
}

export default CreditNotesforCustomersEstimation;