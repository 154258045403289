import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID } from "../../util/constants/forms";
import { handleAdvanceFilter } from "../../util/util";
import EstimationQuotationsTypesDialog from "./dialogs";
import EstimationQuotationsTypesTable from "./listing";
import EstimationQuotationsTypesFilter from "./listing/Filters";
import { getUser } from "../../util/cookies";

const EstimationQuotationsTypes = () => {
    const searchTextRef = useRef();
    const filterRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    const user = getUser();
    const { t } = useTranslation("estimationQuotationsTypes");

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => handleAdvanceFilter("estimation-quotations-types-filter")}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <EstimationQuotationsTypesTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
                dialogRef={dialogRef}
            />

            <EstimationQuotationsTypesFilter ref={filterRef} tableRef={tableRef} />

            <EstimationQuotationsTypesDialog ref={dialogRef} tableRef={tableRef} user={user} />
        </>
    )
}
export default EstimationQuotationsTypes;