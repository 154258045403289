import React from "react";

function AddHeaderBtnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.66 86.66">
      <path
        fill="#fff"
        d="M86.66 37.3L49.36 37.3 49.36 0 37.3 0 37.3 37.3 0 37.3 0 49.36 37.3 49.36 37.3 86.66 49.36 86.66 49.36 49.36 86.66 49.36 86.66 37.3z"
      ></path>
    </svg>
  );
}

export default AddHeaderBtnIcon;
