import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, isNotEmpty, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SidePanel from "../../../components/side-panel";
import CitiesIcon from "../../../icons/Cities";
import { getPropertyType, savePropertyType } from "../../../util/apis";
import { PROPERTY_TYPE_GENERAL, STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2659_PROPERTIES_TYPES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Tab1 from "./Tab1";
import Tab2 from './Tab2';
import style from './style.module.scss';
const dialogId = "properties-types-dialog";

const PropertiesTypesDialog = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);

    const user = useSelector(state => state.persistedReducer.userReducer.user);
    const [data, setData] = useState({});

    const _saveAction = () => {
        if (!checkValidation([{ id: "properties-types-dialog" }], t)) {
            return
        }
        showWaiting(`${dialogId}-btn`, true)
        savePropertyType(data)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting(`${dialogId}-btn`, true))
    }

    const _postClose = () => {
        setData({});
        removeAllMandatoryClasses(document.getElementById(dialogId))
        tabsRef.current.changeTab(1);
    }

    const _newAction = () => {
        const newData = {
            isActive: STATUS_ACTIVE,
            general: false,
            organizations: [
                {
                    fogId: user?.fogId,
                    offecialNameLo: user?.orgNameLo,
                    offecialNameFo: user?.orgNameFo,
                    organizationCode: user?.orgCode
                }
            ],
            type: PROPERTY_TYPE_GENERAL
        }
        setData({ ...newData });
        removeAllMandatoryClasses(document.getElementById(dialogId))
        tabsRef.current.changeTab(1);
    }

    const _populateRecordData = (id) => {
        if (isNotEmpty(id)) {
            updateRecordRef.current = true;
            getPropertyType(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction();
        }
    }

    const open = (id) => {
        _populateRecordData(id)
        dialogRef?.current?.open();
    }

    const close = () => {
        dialogRef?.current?.close();
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <>
            <SidePanel
                id={dialogId}
                ref={dialogRef}
                formId={RSM2659_PROPERTIES_TYPES_FORM_ID}
                title={t('propertiesTypes:properties_types')}
                icon={<CitiesIcon />}
                footerAction={_saveAction}
                withFooter={isAllowedAction(getUser(), RSM2659_PROPERTIES_TYPES_FORM_ID, SAVE_ACTION)}
                footerLbl={t("save")}
                postClose={_postClose}
                preClose={_preCloseAction}
                nextPreviousAction={_populateRecordData}
                showLog
                user={user}
                masterId={data.id}
            >
                <Tabs
                    id="properties-types-tabs"
                    ref={tabsRef}
                    labels={['home', 'propertiesTypes:organization_list']}
                    tabContentStyle={style.tabContentStyle}
                    tabsButtonCustomStyle={style.propertiesTypesTabs}
                >
                    <Tab1 data={data} setData={setData} />
                    <Tab2 data={data} setData={setData} />
                </Tabs>
            </SidePanel>
        </>
    );
}

export default forwardRef(PropertiesTypesDialog);