import React from "react";

function ProcessesMangIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g data-name="Group 176535" transform="translate(-2026.25 -10860.25)">
        <g
          fill="#fff"
          data-name="Group 176534"
          transform="translate(1593 10863)"
        >
          <path
            d="M4.44-.75h11.11c2.117 0 3.3.321 4.09 1.108s1.11 1.97 1.11 4.082v6.33c0 2.116-.321 3.3-1.109 4.086-.972.97-2.536 1.1-4.081 1.1H4.381c-2.087 0-3.256-.323-4.037-1.114S-.75 12.881-.75 10.78V4.44c0-2.112.321-3.294 1.108-4.082S2.328-.75 4.44-.75zm11.12 15.21c1.65 0 2.554-.2 3.021-.666s.669-1.372.669-3.024V4.44c0-1.648-.2-2.552-.67-3.02S17.2.75 15.55.75H4.44c-1.648 0-2.552.2-3.021.669S.75 2.792.75 4.44v6.34c0 1.642.2 2.542.661 3.012s1.35.668 2.97.668H15.56z"
            transform="translate(434 -2)"
          ></path>
          <path
            d="M0 5.53a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v4.78a.75.75 0 01-.75.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(444 13.22)"
          ></path>
          <path
            d="M20 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h20a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(434 9)"
          ></path>
          <path
            d="M9 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h9a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(439.5 18)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ProcessesMangIcon;