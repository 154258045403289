import UtilQuickSearch from "@ipgd-gauge/quick-search";
import { forwardRef } from "react";
import { saveFormsFilter } from '../../store/formsFilter/actions';

const QuickSearch = (props, ref) => {
    const { id, placeholder, search , formId} = props;

    return (
        <UtilQuickSearch
            id={id}
            ref={ref}
            formId={formId}
            placeholder={placeholder}
            search={search}
            saveFormsFilter={saveFormsFilter}
        />
    );
}

export default forwardRef(QuickSearch);