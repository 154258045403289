import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, isAllowedAction, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteTowIcon from "../../../icons/Delete2";
import PrinterIcon from "../../../icons/Printer";
import ViewIcon from "../../../icons/View";
import { deleteCustomReports } from "../../../util/apis";
import { DELETE_ACTION, PRINT_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID } from "../../../util/constants/forms";

const Actions = (props) => {

    const { user, data, tableRef, printDialogRef, dialogRef } = props
    const { t } = useTranslation();

    const [actions, setActions] = useState([]);

    const _deleteInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteCustomReports(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
        });
    }

    const _getActions = () => {
        let actions = [];

        if (isAllowedAction(user, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, VIEW_EDIT_ACTION)) {
            actions.push({
                label: t('edit'),
                action: () => dialogRef.current.open(data.id),
                icon: <ViewIcon />
            });
        }

        if (isAllowedAction(user, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, PRINT_ACTION)) {
            actions.push({
                label: t('print'),
                action: () => printDialogRef?.current?.open([data.id]),
                icon: <PrinterIcon />
            });
        }

        if (isAllowedAction(user, MGR3188_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS_FORM_ID, DELETE_ACTION)) {
            actions.push({
                label: t('delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action:_deleteInvoice,
                icon: <DeleteTowIcon />
            });
        }

        setActions(actions);
    }

    useEffect(() => {
        _getActions();
    }, [data.id, data.globalStatus])

    return (
        <TableActionsButton defaultIndex={0} buttons={actions} />
    )

}

export default Actions;