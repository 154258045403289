import React from "react";

function UserMapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.993"
      height="10.35"
      viewBox="0 0 8.993 10.35"
    >
      <g
        fill="#1facae"
        data-name="Group 176712"
        transform="translate(-1.28 -.602)"
      >
        <path
          d="M2.018-.75A2.768 2.768 0 11-.75 2.018 2.771 2.771 0 012.018-.75zm0 4.814A2.046 2.046 0 10-.028 2.018a2.048 2.048 0 002.046 2.046z"
          transform="translate(3.759 1.352)"
        ></path>
        <path
          d="M7.882 3.342a.361.361 0 01-.361-.361c0-1.659-1.693-3.009-3.774-3.009S-.028 1.322-.028 2.981a.361.361 0 01-.361.361.361.361 0 01-.361-.361A3.424 3.424 0 01.6.317 4.986 4.986 0 013.746-.75 4.986 4.986 0 016.9.317a3.424 3.424 0 011.343 2.664.361.361 0 01-.361.361z"
          data-name="Vector"
          transform="translate(2.03 7.61)"
        ></path>
      </g>
    </svg>
  );
}

export default UserMapIcon;