import React from "react";

function ServiceDeniedIcon() {
  return (
    <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 158 158"
  >
    <defs>
      <clipPath id="clippath">
        <path className="cls-5" d="M0 0h158v158H0z" />
      </clipPath>
      <clipPath id="clippath-1">
        <path
          className="cls-5"
          d="M14.43 79.17a64.124 64.124 0 0 1 13.34-39.2c.33-.45.96-.55 1.41-.22.05.04.1.08.14.12l88.79 88.79a1.002 1.002 0 0 1-.1 1.55c-28.17 21.67-68.58 16.4-90.25-11.77a64.425 64.425 0 0 1-13.35-39.28m113.89 39.29L39.53 29.67c-.41-.38-.43-1.02-.05-1.43.04-.05.09-.09.14-.12 28.17-21.67 68.58-16.4 90.25 11.78 17.79 23.13 17.79 55.34 0 78.47a1 1 0 0 1-.81.41c-.28 0-.55-.12-.74-.32M78.83.35C35.29.35 0 35.64 0 79.17S35.29 158 78.83 158s78.83-35.29 78.83-78.83C157.6 35.66 122.34.4 78.83.35Z"
        />
      </clipPath>
      <clipPath id="clippath-2">
        <path
          className="cls-5"
          d="M2.08 78.85c0 5.64.61 11.27 1.84 16.77.21.85 1.03 1.41 1.9 1.29l8.14-1.23c.77-.1 1.31-.81 1.2-1.57 0-.06-.02-.12-.03-.18A65.88 65.88 0 0 1 27.06 38.7a2.57 2.57 0 0 1 3.6-.5c.1.08.2.16.29.26l46.37 46.37c.72.65 1.68.96 2.65.87l10.8-1.63c.85-.13 1.05-.73.44-1.34L38.95 30.45a2.562 2.562 0 0 1-.05-3.63c.09-.09.19-.18.29-.26a65.272 65.272 0 0 1 40.15-13.67c34.63.04 63.35 26.83 65.79 61.37.03.77.68 1.37 1.45 1.34.06 0 .12 0 .18-.02l8.15-1.23c.87-.14 1.48-.91 1.43-1.79-3.46-42.52-40.75-74.18-83.28-70.71-40.1 3.27-70.98 36.77-70.98 77"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1={393.83}
        y1={1251.25}
        x2={393.83}
        y2={1251.25}
        gradientTransform="matrix(158 0 0 -158 -62224.61 197777.02)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#c70606" />
        <stop offset={0.3} stopColor="#d80606" />
        <stop offset={0.7} stopColor="#e90606" />
        <stop offset={1} stopColor="#f00707" />
      </linearGradient>
      <style>{".cls-5{fill:none}"}</style>
    </defs>
    <g
      style={{
        clipPath: "url(#clippath)",
      }}
    >
      <g id="Group_179907">
        <g
          style={{
            clipPath: "url(#clippath-1)",
          }}
          id="Group_179893"
        >
          <g id="Group_179892">
            <path
              id="Rectangle_164125"
              style={{
                fill: "url(#linear-gradient)",
              }}
              d="M0 0h158v158H0z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_179896">
      <g
        style={{
          clipPath: "url(#clippath-2)",
        }}
        id="Group_179895"
      >
        <g id="Group_179894">
          <path
            id="Path_44951"
            d="M2.08 1.59h154.34v95.45H2.08V1.59Z"
            style={{
              fill: "#fab5b5",
            }}
          />
        </g>
      </g>
    </g>
    <g id="Group_179902">
      <path
        id="Path_44839"
        d="M79 1.74c42.67 0 77.27 34.59 77.27 77.27s-34.59 77.26-77.26 77.26S1.74 121.67 1.74 79C1.78 36.35 36.35 1.78 79 1.74m50.24 118.44c.8 0 1.56-.38 2.05-1.02a65.272 65.272 0 0 0 13.67-40.15C144.92 42.6 115.41 13.1 79 13.06a65.272 65.272 0 0 0-40.15 13.67 2.57 2.57 0 0 0-.5 3.6c.08.1.16.2.26.29l88.79 88.79c.49.49 1.15.77 1.85.78m-50.24 24.78c14.53.04 28.66-4.77 40.15-13.67a2.57 2.57 0 0 0 .5-3.6c-.08-.1-.16-.2-.26-.29L30.6 38.6c-.49-.49-1.15-.77-1.84-.78-.8 0-1.56.38-2.05 1.02a65.272 65.272 0 0 0-13.67 40.15c.04 36.41 29.55 65.91 65.95 65.95m0-144.78C35.47.17.17 35.47.17 79S35.46 157.83 79 157.83s78.83-35.29 78.83-78.83C157.78 35.49 122.51.22 79 .17m50.24 118.44c-.28 0-.55-.12-.74-.32L39.71 29.5a1.002 1.002 0 0 1 .1-1.55c28.18-21.67 68.58-16.39 90.25 11.78 17.79 23.13 17.79 55.34 0 78.47a1 1 0 0 1-.81.41m-50.24 24.78c-35.54.02-64.37-28.78-64.39-64.32 0-14.21 4.68-28.01 13.34-39.27a1 1 0 0 1 .81-.41c.28 0 .55.12.74.32l88.79 88.79c.41.38.43 1.02.04 1.43-.04.04-.09.08-.14.12A64.062 64.062 0 0 1 79 143.39"
        style={{
          fill: "#ad0606",
        }}
      />
    </g>
  </svg>
  );
}

export default ServiceDeniedIcon;
