import { useSelector } from "react-redux";
import LottieViewer from "../lottie-viewer";
import style from "./style.module.scss";

const MsgsCount = ({ showAll, lottieMessageIconRef }) => {
    const unreadedMessagesCount = useSelector(state => state?.persistedReducer?.userReducer?.user?.unreadedMsgsCount || 0);

    return (
        <div className={style.notificationBtn}>
            <span>
                <LottieViewer ref={lottieMessageIconRef} loop={true} autoplay={false} name="headerMessages" />
            </span>
            {((unreadedMessagesCount > 0 && unreadedMessagesCount < 100) || showAll) &&
                <div className={`${style.msgCounter}`}>
                    <label>{unreadedMessagesCount}</label>
                </div>
            }

            {!showAll && unreadedMessagesCount > 100 &&
                <div className={`${style.msgCounter}`}>
                    <label>+99</label>
                </div>
            }
        </div>
    )
}

export default MsgsCount;
