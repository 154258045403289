import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEnglish } from "@ipgd-gauge/utils";
import style from "./style.module.scss";

const LocationOption = (props) => {
    const { data } = props;
    return (
        <div className={style.optionContainer}>
            <div>
                <label>{data.code}</label>
                <label>{isEnglish() ? data.nameLo : data.nameFo}</label>
            </div>
        </div>
    )
}

const LocationSelectMenu = (props) => {
    const { id, key, value, api, options, descLo, descFo, onChange, isClearable, isMulti, isDisabled } = props;
    return (
        <FrmSelectMenu
            id={id}
            key={key}
            value={value}
            api={api}
            options={options}
            OptionComponent={({ data }) => <LocationOption data={data} />}
            descLo={descLo}
            descFo={descFo}
            onChange={onChange}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
            portalTarget={document.body}
        />
    );
}

export default LocationSelectMenu;