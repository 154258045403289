import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slisting from "../../../components/slisting";
import { deletePropertyPolicy, getPropertiesPolicies } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { navigate, deleteAction, isAllowViewNEditAction, isAllowDeleteAction } = rowActions;

    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesPoliciesRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>
            <div title={data.code} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesPolicies:code')}</label>
                <label>{data.code}</label>
            </div>

            <div title={isEnglish() ? data.descLo : data.descFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesPolicies:name')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>

            <div title={isEnglish() ? data.levelDescLo : data.levelDescFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesPolicies:level')}</label>
                <label>{isEnglish() ? data.levelDescLo : data.levelDescFo}</label>
            </div>

            <div title={isEnglish() ? data.functionDescLo : data.functionDescFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesPolicies:function')}</label>
                <label>{isEnglish() ? data.functionDescLo : data.functionDescFo}</label>
            </div>

            <div title={Boolean(data.general) ? t('all_organization') : t('specific_organization')} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesPolicies:organization')}</label>
                <label>{Boolean(data.general) ? t('all_organization') : t('specific_organization')}</label>
            </div>

            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('propertiesPolicies:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowViewNEditAction && <EditBtn onClick={() => navigate(`/evaluation-policy?id=${data.id}`)} />}
                    {isAllowDeleteAction && <DeleteBtn onClick={() => deleteAction(data.id)} />}
                </div>
            </div>
        </div>
    );
};

const PropertiesPoliciesTable = (props) => {
    const { propertiesPoliciesTableRef, searchTextRef, filtersRef } = props;

    const { t } = useTranslation();

    const user = getUser();
    const navigate = useNavigate();

    const [propertiesPolicies, setPropertiesPolicies] = useState({});

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPolicies:code'), width: "15%" },
        { title: t('propertiesPolicies:name'), width: "15%" },
        { title: t('propertiesPolicies:policy_level'), width: "15%" },
        { title: t('propertiesPolicies:function'), width: "15%" },
        { title: t('propertiesPolicies:organization'), width: "10%" },
        { title: t('propertiesPolicies:status'), width: "10%" },
        { title: "", width: "15%" },
    ];

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deletePropertyPolicy(id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    propertiesPoliciesTableRef.current?.refresh();
                })
                .catch((error) => handleError(error, "delete_fields", t))
        });
    }

    const _fetchData = (pageNumber, pageSize) => {
        let filterData = {
            searchValue: searchTextRef.current.getSearchText(),
            ...filtersRef.current.getFilterData()
        }
        getPropertiesPolicies(pageNumber, pageSize, filterData)
            .then((response) => setPropertiesPolicies(response.data))
            .catch((error) => handleError(error, null, t))
    }

    return (
        <Slisting
            id="properties-policies-table"
            ref={propertiesPoliciesTableRef}
            formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
            columns={columns}
            Row={Row}
            tableStyle={style.propertiesPoliciesTable}
            getData={_fetchData}
            rowHeight={80}
            pagingData={propertiesPolicies}
            rowActions={{
                navigate,
                deleteAction: _deleteAction,
                isAllowViewNEditAction: isAllowedAction(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, VIEW_EDIT_ACTION),
                isAllowDeleteAction: isAllowedAction(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, DELETE_ACTION),
            }}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            cardInMobile
            viewAction={(id) => navigate(`/evaluation-policy?id=${id}`)}
            newAction={() => navigate(`/evaluation-policy`)}
            deleteAction={deletePropertyPolicy}
            labelCardWidth="80px"
        />
    );
};

export default PropertiesPoliciesTable;