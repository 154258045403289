export const ACTION_SET_TRNX_VAL = 'SET_TRNX_VAL';
export const ACTION_SET_BLOCK_FEILDS = 'SET_BLOCK_FEILDS';
export const ACTION_UPDATE_DATA = 'UPDATE_DATA';
export const ACTION_RESET_DATA = 'RESET_DATA';

export const changeValue = (name, value) => ({
    type: ACTION_SET_TRNX_VAL,
    name: name,
    value: value
});

export const addBlockFeilds = (blockId, feilds) => ({
    type: ACTION_SET_BLOCK_FEILDS,
    blockId: blockId,
    feilds: feilds
});

export const updateData = (data) => ({
    type: ACTION_UPDATE_DATA,
    value: data
});

export const resetData = () => ({
    type: ACTION_RESET_DATA,
});
