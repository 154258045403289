import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { checkValidation, encryptText, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import UploadImg from "../../../components/upload-img";
import ReportsDesignIcon from "../../../icons/ReportsDesign";
import { getEstimationPrintOut, saveEstimationPrintOut } from "../../../util/apis";
import { ESTIMATION_PRINT_OUT, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { FND3168_REPORTS_DESIGN_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const ReportsDesignDialog = (props, ref) => {
    const { reportsDesignTableRef } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const attachmentRef = useRef();
    const updateRecordRef = useRef(false);

    const [report, setreport] = useState({ isActive: STATUS_ACTIVE, });

    const _handleDataChanged = (name, value) => {
        report[name] = value;
        setreport({ ...report });
    }

    const _saveReport = () => {
        if (!checkValidation([{ id: 'reports-design-dialog' }], t)) {
            return;
        }

        if (!attachmentRef.current.getImages() && !attachmentRef.current.getImageIds()) {
            handleError(null, "should_upload_attachment", t);
            return;
        }

        showWaiting("reports-design-dialog-btn", true);
        saveEstimationPrintOut(report)
            .then(async (response) => {
                await attachmentRef.current?.uploadImages(response.data.id);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                reportsDesignTableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting("reports-design-dialog-btn", true))
    }

    const _postClose = () => {
        setreport({ isActive: STATUS_ACTIVE })
        removeAllMandatoryClasses(document.getElementById('reports-design-dialog'))
    }

    const _populateRecordData = (reportId) => {
        if (reportId) {
            updateRecordRef.current = true;
            getEstimationPrintOut(reportId)
                .then((response) => setreport(response.data))
                .catch((error) => handleError(error, null, t));
        } else {
            setreport({ isActive: STATUS_ACTIVE, imgKey: new Date().getTime() })
        }
    }

    const open = (reportId) => {
        _populateRecordData(reportId);
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false;
    }

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <SidePanel
            id="reports-design-dialog"
            formId={FND3168_REPORTS_DESIGN_FORM_ID}
            ref={dialogRef}
            title={t('reportsDesign:edit_report')}
            icon={<ReportsDesignIcon />}
            footerAction={_saveReport}
            withFooter={isAllowedAction(getUser(), FND3168_REPORTS_DESIGN_FORM_ID, SAVE_ACTION)}
            footerLbl={t("save")}
            postClose={_postClose}
            preClose={_preCloseAction}
            nextPreviousAction={_populateRecordData}
            showLog
            user={getUser()}
            masterId={report.id}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id="report-name-lbl" data-label={encryptText("ERI_NAME")} className='form-label mandatory'>{t('reportsDesign:report_name')}</label>
                        <FrmInput
                            id="report-name"
                            value={report.name}
                            onBlur={(value) => _handleDataChanged("name", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("ERI_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label'>{t('reportsDesign:status')}</label>
                        <RadioOptions
                            id="report-status"
                            sidePanelMode
                            options={[
                                {
                                    title: t(`status_${STATUS_ACTIVE}`),
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: report.isActive == STATUS_ACTIVE
                                },
                                {
                                    title: t(`status_${STATUS_IN_ACTIVE}`),
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: report.isActive == STATUS_IN_ACTIVE
                                }
                            ]} />
                    </div>
                </div>
                <div className={style.imgContainer}>
                    <UploadImg
                        mandatory
                        accept={[".doc", ".docx"]}
                        note={t("reportsDesign:doc_file")}
                        ref={attachmentRef}
                        title={t("reportsDesign:upload_report")}
                        customStyle={style.uploadImgStyle}
                        masterId={report?.id}
                        formId={ESTIMATION_PRINT_OUT}
                        key={report?.imgKey}
                    />
                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(ReportsDesignDialog);