import i18next from "i18next";
import qrcode from "qrcode-generator";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Image from "../../components/image";
import KindaLoginIcon from "../../icons/KindaLogin";
import PasswordIcon from "../../icons/Password";
import UserNameIcon from "../../icons/UserName";
import ValueMateIcon from "../../icons/ValueMate";
import XIcon from "../../icons/X";
import { userLogin } from "../../store/user/actions";
import { setUserTableProfiles } from "../../store/userTableProfiles/actions";
import { getCurrentTime, getLicense, getSelectedtUserTableProfiles, loginLogoPath, loginUser } from "../../util/apis";
import { KEY_ENTER, LICENSE_WARNING_DAYS } from "../../util/constants";
import { setExpiryDate, setToken } from "../../util/cookies";
import { formatDateNTime } from "../../util/dateUtil";
import { deletePath, isEnglish } from "../../util/util";
import style from './style.module.scss';

const VALID = 0;
const VALID_WARNING = 1;
const NOT_VALID = 2;

const checkValidity = (param3, expiryDifference) => {
    if (param3) {
        if (expiryDifference > 0 && expiryDifference < LICENSE_WARNING_DAYS) {
            return VALID_WARNING;
        }
        if (expiryDifference < 0) {
            return NOT_VALID;
        }
    }
    return VALID;
}

const Login = ({ serviceExpireWarningRef, serviceExpireErrorRef }) => {

    const { t } = useTranslation();

    const [loginData, setLoginData] = useState({});
    const [bgNumber, setBgNumber] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");

    const currentDateRef = useRef();

    let dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        deletePath();
        getCurrentTime()
            .then((response) => {
                currentDateRef.current = response.data;
            })
        setBgNumber(Math.ceil(Math.random() * 13) + 1);

    }, []);



    const _handleChange = (name, value) => {
        loginData[name] = value;
        setLoginData({ ...loginData });
    }

    const _handleKeyPress = (event) => {
        if (event.charCode === KEY_ENTER) {
            if (event.target.id == 'username') {
                document.getElementById('password').focus();
            } else if (event.target.id == 'password') {
                _handleLogin();
            }
        }
    }

    const _handleLogin = () => {
        document.getElementById('confirmBtn').classList.add(style.processing);
        if (!loginData.username || !loginData.password || loginData.username === "" || loginData.password === "") {
            document.getElementById('confirmBtn').classList.remove(style.processing);
            setErrorMessage(t('user_name_password_should_not_be_empty'));
        } else {
            loginUser(loginData)
                .then(async (response) => {

                    const user = { ...response.data.user, fndUserId: response.data.user.fusId };
                    const allowedForms = user.allowedForms;
                    let tempAllowedForms = {};
                    let tempFavoriteForms = [];

                    allowedForms.forEach(element => {
                        if (element.actions) {
                            element.actions = element.actions.split(",");
                        } else {
                            element.actions = [];
                        }

                        if (tempAllowedForms[element.id]) {
                            tempAllowedForms[element.id] = [...tempAllowedForms[element.id], ...element.actions];
                        } else {
                            tempAllowedForms[element.id] = element.actions;
                        }

                        if (element.favorite) {
                            tempFavoriteForms.push(element.id);
                        }
                    });

                    user.allowedForms = tempAllowedForms;
                    user.favoriteForms = tempFavoriteForms;

                    await setToken(response.data.token, response.data.sessionAge, currentDateRef.current);
                    await setExpiryDate(response.data.sessionAge, currentDateRef.current);
                    await dispatch(userLogin(user, response.data.token));
                    await sessionStorage.setItem("systemConfig", JSON.stringify(response.data.systemConfig))
                    i18next.changeLanguage(user.en ? 'en' : 'ar');

                    const defaultUserTableProfilesResponse = await getSelectedtUserTableProfiles();
                    const defaultUserTableProfilesData = {};
                    defaultUserTableProfilesResponse.data?.map(data => {
                        data.columns = JSON.parse(data.columns);
                        defaultUserTableProfilesData[`${data.dfrId}-${data.tableId}`] = [{ ...data }];
                    })
                    await dispatch(setUserTableProfiles(defaultUserTableProfilesData));

                    getLicense().then(res => {
                        if (checkValidity(res.data.param3, res.data.expiryDifference) == VALID_WARNING) {
                            serviceExpireWarningRef.current.open(res.data.expiryDifference)
                        }
                        else if (checkValidity(res.data.param3, res.data.expiryDifference) == NOT_VALID) {
                            serviceExpireErrorRef.current.open()
                        }
                    });

                    navigate("/home");
                    // const forms = Object.keys(tempAllowedForms);
                    // if (forms?.indexOf(RSM3240_NOT_EXECUTED_TASKS_FORM_ID) >= 0) {
                    //     navigate("/not-executed-tasks");
                    // } else if (forms?.indexOf(RSM3230_VALUATION_TRANSACTIONS_FORM_ID) >= 0) {
                    //     navigate("/estimation-transactions");
                    // } else if (forms?.indexOf(RSM3246_INBOX_FORM_ID) >= 0) {
                    //     navigate("/inbox");
                    // } else if (forms.length > 0) {
                    //     navigate(getFormUrl(forms[0]))
                    // }
                }).catch((error) => {
                    console.error(error);
                    let code = error.response.data.message.split('#')
                    code = (code.length > 0) ? code[0] : 'error_occurred'
                    setErrorMessage(t(code));
                    document.getElementById('confirmBtn').classList.remove(style.processing);
                });
        }
    }

    const _handleChangeLanguage = (isEn) => {
        i18next.changeLanguage(isEn ? "en" : "ar");
    }

    return (
        <div className={isEnglish() ? style.containerEn : style.containerAr}
            style={{ backgroundImage: `url("/images/loginBg/login-bg-${bgNumber}.png")` }}
            dir={isEnglish() ? "ltr" : "rtl"}
        >
            <div className={style.loginLang}>
                {isEnglish() &&
                    <button onClick={() => _handleChangeLanguage(false)}>
                        AR
                    </button>
                    ||
                    <button onClick={() => _handleChangeLanguage(true)}>
                        EN
                    </button>
                }
            </div>
            <div className={style.containerPanel}>
                <div className={style.valuationLogoContainer}>
                    <ValueMateIcon />
                </div>
                <div className={style.loginContentContainer}>
                    <div className={style.loginContent}>
                        <div className={style.logoContainer}>
                            <div className={style.logo}>
                                <Image
                                    src={loginLogoPath(isEnglish())}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <div className={style.signInContainer}>
                            <div className={style.signInLbl}>{t('welcome_back')}</div>
                            <div>
                                <div className={style.signInForm}>
                                    <label className='form-label'>{t('user_name_login')}</label>
                                    <div>
                                        <span>
                                            <UserNameIcon />
                                        </span>
                                        <input
                                            id='username'
                                            className="frm-entry-input"
                                            focus='auto'
                                            autoComplete="off"
                                            type='text'
                                            value={loginData.username}
                                            onChange={(e) => _handleChange("username", e.target.value)}
                                            onKeyPress={(e) => _handleKeyPress(e)}
                                            autoFocus
                                        />
                                    </div>
                                </div>
                                <div className={style.signInForm} style={{ marginBottom: "0" }}>
                                    <label className='form-label'>{t('password')}</label>
                                    <div>
                                        <span>
                                            <PasswordIcon />
                                        </span>
                                        <input
                                            id='password'
                                            className="frm-entry-input"
                                            autoComplete="off"
                                            type='password'
                                            value={loginData.password}
                                            onChange={(e) => _handleChange("password", e.target.value)}
                                            onKeyPress={(e) => _handleKeyPress(e)}
                                        />
                                    </div>
                                </div>
                                <div className={style.error_message}>
                                    {errorMessage !== "" &&
                                        <label>{errorMessage}</label>
                                    }
                                </div>
                                <div id="confirmBtn" className={style.confirmBtn}>
                                    <button onClick={_handleLogin}>{t('continue')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.footerLogin}>
                <div className={style.kindaLogo}>
                    <KindaLoginIcon />
                </div>
                <LicenseQrCode />
            </div>
        </div>
    )
}

export default Login;

const LicenseQrCode = () => {

    const [licenseData, setLicenseData] = useState({});

    const { t } = useTranslation();
    const isHoveredRef = useRef(false);

    const handleMouseEnter = () => {
        isHoveredRef.current = true;
        if (isHoveredRef.current) {
            document.getElementById('active-qr').classList.add(style.qrCodeHover);
            document.getElementById('active-qr-bg').classList.add('active');
            document.getElementById('active-qr-close-btn').classList.add('active');
        }
    };

    const handleMouseLeave = () => {
        isHoveredRef.current = false;
        document.getElementById('active-qr').classList.remove(style.qrCodeHover);
        document.getElementById('active-qr-bg').classList.remove('active');
        document.getElementById('active-qr-close-btn').classList.remove('active');
    };

    useEffect(() => {
        getLicense().then(res => {
            setLicenseData(res.data)
            // qr.addData('Hi!');
            // qr.make();
            // document.getElementById('placeHolder111').innerHTML = qr.createImgTag();
        })
    }, [])
    useEffect(() => {
        if (licenseData) {
            var typeNumber = 15;
            var errorCorrectionLevel = 'L';
            var encoded = "UTF-8"
            qrcode.stringToBytes = qrcode.stringToBytesFuncs[encoded];
            var qr = qrcode(typeNumber, errorCorrectionLevel);
            qr.addData(`${licenseData.liscenseDescFo?.replaceAll('\\n', ` ${`\n`} `)} \n ${licenseData.linksHashCode ||""}`, "Byte");
            qr.make();
            document.getElementById('placeHolder111').innerHTML = qr.createImgTag();
        }
    }, [licenseData])

    return (
        <div className={style.statusContainer}>
            <label style={{ color: "white" }}>

            </label>
            <div className={style.userInfo}>
                <div className={checkValidity(licenseData.param3, licenseData.expiryDifference) == VALID ? style.statusLogValid : checkValidity(licenseData.param3, licenseData.expiryDifference) == VALID_WARNING ? style.statusLogWarning : style.statusLogNotValid}>
                    <label>{!licenseData.param3 ? t("valid") : checkValidity(licenseData.param3, licenseData.expiryDifference) == NOT_VALID ? t("not_valid") : t("valid_until")}</label>
                </div>
                {licenseData.param3 && checkValidity(licenseData.param3, licenseData.expiryDifference) != NOT_VALID &&
                    <div>
                        <div>
                            <label>{formatDateNTime(licenseData.param3)}</label>
                        </div>
                    </div>
                }

            </div>
            <div className={style.qrData}
                ref={isHoveredRef}
                onClick={handleMouseEnter}
            >
                <div id="active-qr">
                    <div id="placeHolder111"></div>
                </div>
            </div>
            <span id="active-qr-bg" className={style.bgQRCode}></span>
            <button onClick={handleMouseLeave} id="active-qr-close-btn" className={style.qRCodeCloseBtn}>
                <XIcon />
            </button>
        </div>
    )
}