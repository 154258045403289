import { handleError } from '@ipgd-gauge/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarTrxIcon } from '../../../icons/EstimationTrx';
import ImportantTableIcon from '../../../icons/ImportantTable';
import MsgTableIcon from '../../../icons/MsgTable';
import { TransactionFav, TransactionUnFav } from '../../../icons/TransactionFav';
import { favEstimationTransaction } from '../../../util/apis';
import style from './style.module.scss';

const TransactionFavourit = (props) => {

    const { data } = props;
    const { t } = useTranslation('estimationTransaction');
    const [isFav, setIsFav] = useState(Boolean(data.isFav));

    const _handleFav = (e) => {
        e.stopPropagation();
        favEstimationTransaction(data.id)
            .then((res) => setIsFav(!isFav))
            .catch((error) => handleError(error, null, t))
    }

    return (
        <button onClick={_handleFav} title={t('fav')} >
            {isFav ? <TransactionFav /> : <TransactionUnFav />}
        </button>
    )
}

const RowActions = (props) => {
    const { data, showMessages, setShowMessages, ratesLogDialogRef } = props;
    const { t } = useTranslation('estimationTransaction');

    return (
        <div className={` ${style.actionRow}`}>
            <TransactionFavourit key={data.id} data={data} />
            <button onClick={() => setShowMessages(!showMessages)} title={t('messages')}>
                <MsgTableIcon />
            </button>
            <button title={t('attachments')} style={{ cursor: 'auto' }}>
                <ImportantTableIcon />
                {data.numberOfAttach > 0 && <span className={style.notifiction}>{data.numberOfAttach}</span>}
            </button>
            <button className={style.estimationQualityBtn} onClick={() => ratesLogDialogRef?.current?.open(data.id)}>
                <StarTrxIcon />
                <span className={style.starNotifiction}>{data.rate}</span>
            </button>
        </div>
    )
}

export default RowActions;