import ExchangeRateInputComponent from "@ipgd-gauge/exchange-rate";
import { getCurrencyExchangeRate } from "../../util/apis";

const ExchangeRateInput = (props) => {

    const { id, value, onBlur, currencyId, transactionId, transactionDate, disabled, organizationCurrency, decimalScale } = props;

    return (
        <ExchangeRateInputComponent
            id={id}
            value={value}
            onBlur={onBlur}
            currencyId={currencyId}
            transactionId={transactionId}
            transactionDate={transactionDate}
            disabled={disabled}
            getCurrencyExchangeRateApi={getCurrencyExchangeRate}
            organizationCurrency={organizationCurrency}
            decimalScale={decimalScale}
        />
    )
}

export default ExchangeRateInput;