import React from "react";

function TransactionStatusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        data-name="vuesax/twotone/document-sketch"
        transform="translate(-557.25 -189.25)"
      >
        <g fill="#292d32">
          <path
            d="M13 20.75H7c-2.77 0-4.647-.586-5.905-1.845S-.75 15.77-.75 13V7c0-2.77.586-4.647 1.845-5.905S4.23-.75 7-.75h5a.75.75 0 01.75.75.75.75 0 01-.75.75H7c-2.346 0-3.886.45-4.845 1.405S.75 4.654.75 7v6c0 2.346.447 3.886 1.405 4.845S4.654 19.25 7 19.25h6c2.346 0 3.886-.447 4.845-1.405S19.25 15.346 19.25 13V8a.75.75 0 011.5 0v5c0 2.77-.586 4.647-1.845 5.905S15.77 20.75 13 20.75z"
            transform="translate(558 190)"
          ></path>
          <path
            d="M8 8.75H4C.582 8.75-.75 7.418-.75 4V0a.75.75 0 01.463-.693.75.75 0 01.817.163l8 8A.75.75 0 018 8.75zM.75 1.811V4c0 2.582.668 3.25 3.25 3.25h2.189z"
            data-name="Vector"
            transform="translate(570 190)"
          ></path>
          <path
            d="M2.035-.75h2.65a1.743 1.743 0 011.378.74l1.125 1.693a1.7 1.7 0 01-.2 2.087L4.525 6.23a1.661 1.661 0 01-2.341 0l-2.46-2.46a1.7 1.7 0 01-.2-2.087L.65-.015l.018-.026A1.8 1.8 0 012.035-.75zM4.678.75H2.045a.4.4 0 00-.161.089L.769 2.515v.005a.2.2 0 00.019.189l2.46 2.46a.162.162 0 00.219 0l2.46-2.46a.2.2 0 00.019-.189v-.005L4.811.816A.334.334 0 004.678.75z"
            data-name="Vector"
            opacity="0.4"
            transform="translate(562.285 200)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default TransactionStatusIcon;