import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { getDomainValues } from "../../../util/apis";
import { DOMAIN_WORKFLOW_PRIORITIES, WORKFLOW_PRIORITIES_NORMAL } from "../../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const ChangePriorityDialog = (props, ref) => {

    const { okAction, postClose } = props;
    const { t } = useTranslation();

    const trnxIdRef = useRef();
    const dialogRef = useRef();

    const [priority, setPriority] = useState(WORKFLOW_PRIORITIES_NORMAL);

    const _postClose = () => {
        setPriority(WORKFLOW_PRIORITIES_NORMAL);
        trnxIdRef.current = "";
        if (postClose) {
            postClose();
        }
    }

    const _okAction = () => {
        if (okAction) {
            okAction(trnxIdRef.current, { priority: priority })
        }
    }

    const open = (trnxId) => {
        trnxIdRef.current = trnxId;
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="change-priority-dialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={t('estimationTransaction:change_priority')}
            okBtnLabel={t("save")}
            withOkButton
            withCancelButton
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={`fitHeightDialog ${style.actionDailog}`}
            okBtnAction={_okAction}
            postClose={_postClose}
            withFooter
            smallSize
        >
            <div className='container'>
                <div className='row  rowSmModel'>

                    <div className="w100">
                        <label className='form-label mandatory'>{t('estimationTransaction:priority')}</label>
                        <FrmSelectMenu
                            id="priority"
                            value={priority}
                            api={() => getDomainValues(DOMAIN_WORKFLOW_PRIORITIES)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => setPriority(e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(ChangePriorityDialog);