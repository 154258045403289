import { isEnglish } from '@ipgd-gauge/utils';
import style from './style.module.scss';

const DetailsCard = (props) => {
    const { feild, children, inCard, inTable } = props;

    return (
        <div className={`${style.trxCard} ${inTable ? 'inTable' : (inCard ? '' : 'notInCard')}`}>
            {!inTable && <div className={style.trxCardHeader}>
                <div className={style.trxTitHeader}>
                    <label className={`form-label ${feild?.mandatory ? 'mandatory' : ''}`} title={isEnglish() ? feild?.userDescLo : feild?.userDescFo}>
                        {isEnglish() ? feild?.userDescLo : feild?.userDescFo}</label>
                </div>
                {inCard && <div className={style.trxHeaderIcon}>
                    {/* <button> <ImgIcon /> </button>
                    <button> <MicrophoneIcon /> </button> */}
                </div>}
            </div>}
            {children}
        </div>
    )
}
export default DetailsCard;