import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getValuationFieldBlocks } from "../../../util/apis";
import { RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID } from "../../../util/constants/forms";

const UpdateValuationFieldsFilter = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation("");

    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            id="update-valuation-fields-filter"
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            numOfRows={1}
            formId={RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('updateValuationFields:block2')}</label>
                            <FrmSelectMenu
                                id="update-valuation-fields-filter-block"
                                isMulti={true}
                                value={filterData.blocks}
                                api={() => getValuationFieldBlocks()}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("blocks", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div >
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(UpdateValuationFieldsFilter);