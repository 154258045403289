import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { checkValidation, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import { getImportantLink, saveImportantLink } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3266_IMPORTATNT_LINKS } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";

const dialogId = "important-links-dialog";

const ImportantLinksDialog = (props, ref) => {
    const { tableRef } = props; const { t } = useTranslation();
    const dialogRef = useRef();
    const updateRecordRef = useRef(false);

    const DEFAULT_DATA = { isActive: STATUS_ACTIVE };
    const [data, setData] = useState(DEFAULT_DATA);

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }
        showWaiting(`${dialogId}-btn`, true)
        saveImportantLink(data)
            .then(() => {
                tableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-btn`))
    }

    const _populateRecordData = (id) => {
        if (id) {
            updateRecordRef.current = true;
            getImportantLink(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t))
        } else {
            setData(DEFAULT_DATA)
        }
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef.current.open();
    }

    const _postClose = () => {
        setData(DEFAULT_DATA)
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const _preCloseAction = () => {
        updateRecordRef.current = false
        tableRef?.current?.refresh()
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            formId={RSM3266_IMPORTATNT_LINKS}
            title={t('importantLinks:important_links')}
            footerLbl={t("save")}
            footerAction={_saveAction}
            withFooter={isAllowedAction(getUser(), RSM3266_IMPORTATNT_LINKS, SAVE_ACTION)}
            postClose={_postClose}
            preClose={_preCloseAction}
            nextPreviousAction={_populateRecordData}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id={`${dialogId}-status-lbl`} className='form-label mandatory'> {t('importantLinks:status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            sidePanelMode
                            options={[
                                {
                                    title: t('active'),
                                    action: () => _handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: data.isActive == STATUS_ACTIVE
                                },
                                {
                                    title: t('inactive'),
                                    action: () => _handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: data.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-english-descreption-lbl`} className='form-label mandatory'>{t('importantLinks:english_descreption')}</label>
                        <FrmInput
                            id={`${dialogId}-english-descreption`}
                            value={data.descLo}
                            onBlur={(value) => _handleDataChanged("descLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-arabic-descreption-lbl`} className='form-label mandatory'>{t('importantLinks:arabic_descreption')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-descreption`}
                            value={data.descFo}
                            onBlur={(value) => _handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-link-lbl`} className='form-label mandatory'>{t('importantLinks:link')}</label>
                        <FrmInput
                            id={`${dialogId}-link`}
                            value={data.link}
                            onBlur={(value) => _handleDataChanged("link", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('importantLinks:remark')}</label>
                        <FrmInput
                            id={`${dialogId}-remark`}
                            value={data.remarks}
                            onBlur={(value) => _handleDataChanged("remarks", value)}
                        />
                    </div>

                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(ImportantLinksDialog);