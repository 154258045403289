import { isEnglish, isNotEmpty } from '@ipgd-gauge/utils';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DATA_TYPE_TEXT_ALIGN_CENTER } from '../../../../util/constants';
import DetailsCard from "./Card";
import style from './style.module.scss';

const _getCoordinateValues = (value) => {
    if (value.includes('/') || value.includes(',')) {
        let values = []
        if (value.includes('/')) {
            values = value.split('/')
        } else if (value.includes(',')) {
            values = value.split(',')
        }
        return `${Number(values[0] || 0).toFixed(8)},${Number(values[1] || 0).toFixed(8)}`;
    }
}

const InputText = (props) => {
    const { feild, handleChange, inCard, inTable, multiLines, isCoordinateFeild } = props;
    const inputValue = useSelector(state => {
        let value = state.estTrnxReducer.data[feild.internalName];
        if (isCoordinateFeild && isNotEmpty(value)) {
            value = _getCoordinateValues(value);
        }
        return value;
    });

    const [data, setData] = useState(inputValue);
    const textAreaRef = useRef(null);

    useEffect(() => {
        setData(inputValue || "")
    }, [inputValue])

    useEffect(() => {
        if (multiLines && textAreaRef.current) {
            adjustTextAreaHeight();
        }
    }, [data]);

    const adjustTextAreaHeight = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    };

    const getNumOfRows = () => {
        return data?.split(/\r|\r\n|\n/)?.length || 1;
        // let numOfRows = 1;
        // const textArea = document.getElementById(`${feild.internalName}-text`);
        // if (textArea) {
        //         numOfRows = document.getElementById(`${feild.internalName}-text`).scrollHeight == 35 ? 1 : Math.ceil(document.getElementById(`${feild.internalName}-text`).scrollHeight / 35) + 1;
        // }
        // return numOfRows;
    }

    const _handleBlur = (name, value) => {
        if (inputValue != value) {
            handleChange(name, value)
        }
    }

    return (
        <DetailsCard feild={feild} inCard={inCard} inTable={inTable}>
            <div className={style.trxInput}>
                {multiLines ?
                    <textarea
                        ref={textAreaRef}
                        id={`${feild.internalName}-text`}
                        value={data}
                        disabled={!feild.enabled}
                        className='frm-entry-input'
                        title={data}
                        onBlur={e => _handleBlur(feild.internalName, e.target.value)}
                        onChange={(e) => setData(e.target.value)}
                        style={{ overflow: 'hidden', /*lineHeight: '1.5',*/ height: `${getNumOfRows() == 1 ? 37 : ((getNumOfRows() * 20) + 20)}px` }}>
                    </textarea>
                    :
                    <input
                        id={`${feild.internalName}-text`}
                        type='text'
                        value={data}
                        title={data}
                        className='frm-entry-input'
                        style={{ textAlign: feild.dataType == DATA_TYPE_TEXT_ALIGN_CENTER ? 'center' : isEnglish() ? 'left' : 'right', direction: feild.internalName == "DTR_COORDINATE" ? "ltr" : "inherit" }}
                        onChange={(e) => setData(e.target.value)}
                        onBlur={e => _handleBlur(feild.internalName, e.target.value)}
                        disabled={!feild.enabled || feild.internalName == 'TRI_APPROVED_ORG_FEES_AFC'}
                    />
                }
            </div>
        </DetailsCard>
    )
}

export default InputText;