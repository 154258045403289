import Tabs from "@ipgd-gauge/tabs";
import { handleError, hideWaiting, isAllowedAction, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import EstimationFormsFieldsBuilderIcon from "../../../icons/EstimationFormsFieldsBuilder";
import { getValuationField, saveValuationField } from "../../../util/apis";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID } from "../../../util/constants/forms";
import { } from "../../../util/util";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const dialogId = "update-valuation-fields-dialog";

const UpdateValuationFieldsDialog = (props, ref) => {
    const { user, tableRef } = props;

    const { t } = useTranslation();
    const tabsRef = useRef();
    const dialogRef = useRef();

    const [data, setData] = useState({});

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _saveAction = () => {
        showWaiting(`${dialogId}-btn`)
        saveValuationField(data)
            .then(() => {
                showSuccessMessage('save_successfully', t);
                tableRef?.current?.refresh();
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-btn`));
    }

    const _populateRecordData = (id) => {
        if (id) {
            dialogRef.current.open();
            getValuationField(id)
                .then((response) => setData(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }

    const open = (id) => _populateRecordData(id);

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    const _postClose = () => {
        setData({});
        tabsRef.current?.changeTab(1);
    }

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            formId={RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID}
            title={t('updateValuationFields:update_valuation_fields')}
            icon={<EstimationFormsFieldsBuilderIcon />}
            footerAction={_saveAction}
            withFooter={isAllowedAction(user, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, SAVE_ACTION)}
            footerLbl={t("save")}
            postClose={_postClose}
            nextPreviousAction={(id) => _populateRecordData(id)}
        >
            <Tabs
                id={`${dialogId}-tabs`}
                ref={tabsRef}
                labels={['home', 'updateValuationFields:evaluation_approaches']}
                tabContentStyle={style.tabContentStyle}
                tabsButtonCustomStyle={style.valuationFieldTabs}
            >
                <Tab1 data={data} handleDataChanged={_handleDataChanged} />
                <Tab2 data={data} handleDataChanged={_handleDataChanged} />
            </Tabs>
        </SidePanel>
    )
}

export default forwardRef(UpdateValuationFieldsDialog);