import React from "react";

function CriticalPriorityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g
        fill="red"
        data-name="Group 178496"
        transform="translate(-7069.732 -3268.81)"
      >
        <circle
          cx="14.5"
          cy="14.5"
          r="14.5"
          data-name="Ellipse 42"
          opacity="0.14"
          transform="translate(7069.732 3268.81)"
        ></circle>
        <path
          d="M.91 12.3a.834.834 0 01-.91-.725V.728A.834.834 0 01.91 0a.834.834 0 01.91.728v10.847a.834.834 0 01-.91.725z"
          data-name="Union 7"
          transform="translate(7083.69 3275.083)"
        ></path>
        <circle
          cx="1.5"
          cy="1.5"
          r="1.5"
          data-name="Ellipse 114"
          transform="translate(7083.101 3288.906)"
        ></circle>
      </g>
    </svg>
  );
}

export default CriticalPriorityIcon;
