import React from "react";

export function PreviewHomeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.473"
            viewBox="0 0 21.5 21.473"
        >
            <g
                fill="#292d32"
                data-name="Group 179923"
                transform="translate(4708.75 10799.736)"
            >
                <path
                    d="M9.615-.75a4.973 4.973 0 012.835.838l6.18 4.33a5.473 5.473 0 012.12 4.064v7.28a4.966 4.966 0 01-4.96 4.96H4.21a4.971 4.971 0 01-4.96-4.97V8.343a5.52 5.52 0 011.92-3.912l5.389-4.2A4.982 4.982 0 019.615-.75zm6.175 19.973a3.464 3.464 0 003.46-3.46v-7.28a4 4 0 00-1.479-2.835L11.59 1.317A3.466 3.466 0 009.615.75a3.529 3.529 0 00-2.135.665l-5.389 4.2A4.037 4.037 0 00.75 8.343v7.41a3.469 3.469 0 003.46 3.47z"
                    transform="translate(-4708 -10798.986)"
                ></path>
                <path
                    d="M0 3.75A.75.75 0 01-.75 3V0A.75.75 0 010-.75.75.75 0 01.75 0v3a.75.75 0 01-.75.75z"
                    data-name="Vector"
                    transform="translate(-4698 -10786.014)"
                ></path>
            </g>
        </svg>
    );
}

export function FillPreviewHomeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.5"
            height="25.473"
            viewBox="0 0 19 19.473"
        >
            <g data-name="Group 179921" transform="translate(4708.5 10835.403)">
                <g data-name="Group 179924">
                    <path
                        fill="#fff"
                        stroke="#460067"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M-4700.98-10833.907l-5.39 4.2a4.759 4.759 0 00-1.63 3.32v7.41a4.225 4.225 0 004.21 4.22h11.58a4.223 4.223 0 004.21-4.21v-7.28a4.723 4.723 0 00-1.8-3.45l-6.18-4.333a4.487 4.487 0 00-5 .123z"
                    ></path>
                    <path
                        fill="#000"
                        d="M0 3.75A.75.75 0 01-.75 3V0A.75.75 0 010-.75.75.75 0 01.75 0v3a.75.75 0 01-.75.75z"
                        data-name="Vector"
                        transform="translate(-4698 -10821.758)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}