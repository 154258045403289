import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from 'react-i18next';
import { getCommonCustomers, getDomainValues } from '../../../../util/apis';
import { CUSTOMER_LEVEL, CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, STATUS_ACTIVE } from '../../../../util/constants';

const Customer = (props) => {
    const { valuationContract, handleDataChanged } = props;

    const { t } = useTranslation("valuationContracts");

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('customer')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <label id='valuation-contract-customer-level-lbl' className='form-label mandatory'>{t('customer_level')}</label>
                        <FrmSelectMenu
                            id="valuation-contract-customer-level"
                            api={() => getDomainValues(CUSTOMER_LEVEL)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            value={valuationContract.customerLevel}
                            onChange={value => handleDataChanged("customerLevel", value.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    {valuationContract.customerLevel == CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w75">
                            <label id="valuation-contract-customer-lbl" className={`form-label ${valuationContract?.customerLevel == CUSTOMER_LEVEL_PREDEFINED ? 'mandatory' : ''}`}>{t('customer')}</label>
                            <FrmSelectMenu
                                id="valuation-contract-customer"
                                value={valuationContract.atrId}
                                onChange={value => handleDataChanged("atrId", value)}
                                defualtValueId={valuationContract.atrId}
                                defualtValueApi={() => getCommonCustomers(isEmpty(valuationContract.atrId) ? [] : [valuationContract.atrId], STATUS_ACTIVE, null, true, false, false, [], false)}
                                api={(searchValue) => getCommonCustomers(null, STATUS_ACTIVE, searchValue, true, false, false, [], false)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isDisabled={valuationContract?.customerLevel == CUSTOMER_LEVEL_NEW}
                                portalTarget={document.body}
                                onDataRetrieved={(list) => {
                                    const id = getUrlParam("id");
                                    if (isNotEmpty(id) && valuationContract.atrId) {
                                        list?.forEach((element) => {
                                            if (element.id == valuationContract.atrId) {
                                                handleDataChanged("customerData", { ...element })
                                                return;
                                            }
                                        })
                                    }
                                }}
                            />
                        </div>
                    }

                    {valuationContract.customerLevel == CUSTOMER_LEVEL_NEW &&
                        <div className="w75">
                            <label id="valuation-contract-customer-name-lbl" className={`form-label ${valuationContract?.customerLevel == CUSTOMER_LEVEL_NEW ? 'mandatory' : ''}`}>{t('customer_name')}</label>
                            <FrmInput
                                id="valuation-contract-customer-name"
                                value={valuationContract?.customerName}
                                onBlur={(value) => handleDataChanged("customerName", value)}
                                disabled={valuationContract?.customerLevel == CUSTOMER_LEVEL_PREDEFINED}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className={`form-label`}>{t('card_num')}</label>
                            <FrmInput
                                id="valuation-contract-card-number"
                                value={valuationContract?.cardNum}
                                onBlur={(value) => handleDataChanged("cardNum", value)}
                                disabled={valuationContract?.customerLevel == CUSTOMER_LEVEL_PREDEFINED}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className={`form-label`}>{t('mobile_num')}</label>
                            <FrmInput
                                id="valuation-contract-mobile-number"
                                type='number'
                                value={valuationContract?.mobileNum}
                                onBlur={(value) => handleDataChanged("mobileNum", value)}
                                disabled={valuationContract?.customerLevel == CUSTOMER_LEVEL_PREDEFINED}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('phone_num')}</label>
                            <FrmInput
                                id="valuation-contract-phone-number"
                                value={valuationContract?.phoneNumber}
                                type='number'
                                onBlur={(value) => handleDataChanged("phoneNumber", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('email')}</label>
                            <FrmInput
                                id="valuation-contract-email"
                                value={valuationContract?.email}
                                onBlur={(value) => handleDataChanged("email", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('commercial_register')}</label>
                            <FrmInput
                                id="valuation-contract-commercial-register"
                                value={valuationContract?.customerCommRegNum}
                                onBlur={(value) => handleDataChanged("customerCommRegNum", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('tax_id')}</label>
                            <FrmInput
                                id="valuation-contract-tax-id"
                                value={valuationContract?.taxNumber}
                                type='number'
                                onBlur={(value) => handleDataChanged("taxNumber", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('responsible_person')}</label>
                            <FrmInput
                                id="valuation-contract-responsible-person"
                                value={valuationContract?.customerContact}
                                onBlur={(value) => handleDataChanged("customerContact", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('delegation_number')}</label>
                            <FrmInput
                                id="valuation-contract-delegation-number"
                                value={valuationContract?.customerDeelgationNum}
                                type='number'
                                onBlur={(value) => handleDataChanged("customerDeelgationNum", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('city')}</label>
                            <FrmInput
                                id="valuation-contract-city"
                                value={valuationContract?.city}
                                onBlur={(value) => handleDataChanged("city", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('town')}</label>
                            <FrmInput
                                id="valuation-contract-town"
                                value={valuationContract?.town}
                                onBlur={(value) => handleDataChanged("town", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className="form-label">{t('building_no')}</label>
                            <FrmInput
                                id="valuation-contract-building-no"
                                value={valuationContract?.buildingNumber}
                                type='number'
                                onBlur={(value) => handleDataChanged("buildingNumber", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w25">
                            <label className='form-label'>{t('postal_code')}</label>
                            <FrmInput
                                id="valuation-contract-postal-code"
                                value={valuationContract?.postalCode}
                                onBlur={(value) => handleDataChanged("postalCode", value)}
                            />
                        </div>
                    }
                    {valuationContract?.customerLevel != CUSTOMER_LEVEL_PREDEFINED &&
                        <div className="w100">
                            <label className='form-label'>{t('address')}</label>
                            <FrmInput
                                id="valuation-contract-address"
                                value={valuationContract.customerAddress}
                                onBlur={(value) => handleDataChanged("customerAddress", value)}
                            />
                        </div>
                    }
                    <div className="w100">
                        <label className='form-label'>{t('delivered_documents')}</label>
                        <FrmInput
                            id="valuation-contract-delivered-documents"
                            value={valuationContract.customerReceivedDocs}
                            onBlur={(value) => handleDataChanged("customerReceivedDocs", value)}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Customer;