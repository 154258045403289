import { ACTION_SET_USER_TABLE_PROFILES, ACTION_UPDATE_USER_TABLE_PROFILES } from '../actions';

const userTableProfilesReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_SET_USER_TABLE_PROFILES:
            return action.data;

        case ACTION_UPDATE_USER_TABLE_PROFILES:
            return {
                ...state,
                [action.id]: [...action.data],
            };

        default:
            return state;
    }
};

export default userTableProfilesReducer;