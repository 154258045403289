import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { checkValidation, handleError, hideWaiting, isEmpty, isNotEmpty, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modal";
import { createTransaction, getCommonBranches, getCommonDivisionsList, getEvaluationFunctions } from "../../../../util/apis";
import { STATUS_ACTIVE } from "../../../../util/constants";
import style from './style.module.scss';

const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnBranchCondition';
const dialogId = "generate-transaction-dialog";
const GenerateTransactionDialog = (props, ref) => {
    const { tableRef } = props;
    const { t } = useTranslation();

    const dialogRef = useRef();

    const [data, setData] = useState({});

    const _handleChange = (name, value) => {
        data[name] = value;

        if (name === "mbnId") {
            data.dsvId = null;
        }

        setData({ ...data })
    }

    const open = (id) => {
        if (isNotEmpty(id)) {
            dialogRef.current.open();
            setData({ mmaId: id, mbnId: null, dsvId: null, fnoId: null });
        }
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    const _createTransaction = () => {
        if (!checkValidation([{ id: dialogId }], t)) {
            return
        }
        showWaiting(`${dialogId}-okButton`, true)
        createTransaction(data)
            .then((response) => {
                tableRef?.current?.refresh();
                close();
                showSuccessMessage("transactions_have_been_created", t, [{ name: 'transactionNumber', value: response.data }]);
            })
            .catch(error => handleError(error, "TRANSACTION_IS_NOT_CREATED", t))
            .finally(() => hideWaiting(`${dialogId}-okButton`))
    }

    return (
        <Modal
            id={dialogId}
            ref={dialogRef}
            title={t('estimationQuotation:create_transaction')}
            withFooter="true"
            okBtnLabel={t("estimationQuotation:save")}
            withOkButton="true"
            withCancelButton="true"
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('estimationQuotation:exit')}
            className={` fitDialog ${style.generateTransactionDialog}`}
            okBtnAction={_createTransaction}
        >
            <div className='container'>
                <div className='row  rowSmModel'>

                    <div className="w100">
                        <label id={`${dialogId}-branch-lbl`} className='form-label mandatory'>{t('estimationQuotation:branch')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-branch`}
                            key={`${dialogId}-branch-${data?.id}`}
                            value={data?.mbnId}
                            api={() => getCommonBranches(null, isEmpty(data?.mbnId) ? [] : [data?.mbnId], STATUS_ACTIVE, BRANCH_CONDITION)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => _handleChange("mbnId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w100">
                        <label id={`${dialogId}-division-lbl`} className='form-label mandatory'>{t('estimationQuotation:division')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-division`}
                            key={`division-${data?.mbnId}`}
                            value={data?.dsvId}
                            api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false, null, null, data?.mbnId, STATUS_ACTIVE, isEmpty(data?.dsvId) ? [] : [data?.dsvId])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => _handleChange("dsvId", e.value)}
                            isDisabled={!data?.mbnId}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label id={`${dialogId}-forms-lbl`} className='form-label mandatory'>{t('estimationQuotation:workflow')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-forms`}
                            value={data?.fnoId}
                            api={() => getEvaluationFunctions(isEmpty(data?.fnoId) ? [] : [data?.fnoId], null, STATUS_ACTIVE, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => _handleChange("fnoId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(GenerateTransactionDialog);
