import React from "react";

function CardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.83 71.3">
      <rect
        width="32.43"
        height="32.43"
        x="40.4"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="32.43"
        height="32.43"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="32.43"
        height="32.43"
        x="40.4"
        y="38.87"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="32.43"
        height="32.43"
        y="38.87"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
    </svg>
  );
}

export default CardIcon;
