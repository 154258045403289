import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValues, getPartiesDefinitionCategories } from "../../../util/apis";
import { DOMAIN_PROPORTIES_PARTIES_TYPE, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { handleNumbersKeyDown } from "../../../util/util";
import style from './style.module.scss';
import { isEmpty } from "@ipgd-gauge/utils";

const Tab1 = (props) => {
    const { tab, data, handleDataChanged } = props;
    const { t } = useTranslation('propertiesPartiesDefinition');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`container ${style.mainContainer}`}>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id="code"
                            value={data.code}
                            column="APR_CODE"
                            onBlur={(v) => handleDataChanged("code", v)}
                            formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                        />
                    </div>
                    <div className="w25">
                        <label className='form-label'> {t('status')}</label>
                        <RadioOptions
                            id="accounts-groups-status"
                            options={[
                                {
                                    id: 'active',
                                    title: t('active'),
                                    selected: data.isActive == STATUS_ACTIVE,
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE)
                                },
                                {
                                    id: 'inactive',
                                    title: t('inactive'),
                                    selected: data.isActive == STATUS_IN_ACTIVE,
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE)
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label id="english-name-lbl" className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id="english-name"
                            value={data.nameLo}
                            onBlur={(value) => handleDataChanged("nameLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id="arabic-name-lbl" className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id="arabic-name"
                            value={data.nameFo}
                            onBlur={(value) => handleDataChanged("nameFo", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id="category-lbl" className='form-label mandatory'>{t('category')}</label>
                        <FrmSelectMenu
                            id="category"
                            key={`category-${data?.general}-${data?.organizations}`}
                            value={data.araId}
                            api={() => getPartiesDefinitionCategories(STATUS_ACTIVE, data?.general, (data?.organizations || [])?.map((org) => org.fogId), false, null, isEmpty(data.araId) ? [] : [data.araId])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("araId", value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w33">
                        <label id="type-lbl" className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id="type"
                            value={data.type}
                            api={() => getDomainValues(DOMAIN_PROPORTIES_PARTIES_TYPE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("type", value.value)}
                            portalTarget={document.body}
                            isDisabled
                        />
                    </div>
                    <div className="w33">
                        <label id="membership-id-lbl" className='form-label'>{t('membership_id')}</label>
                        <FrmInput
                            id="membership_id"
                            value={data.membershipNum}
                            onBlur={(value) => handleDataChanged("membershipNum", value)}
                        />
                    </div>

                    <div className="w33">
                        <label id="tax-id-lbl" className='form-label'>{t('tax_id')}</label>
                        <FrmInput
                            id="tax_id"
                            value={data.taxNumber}
                            onBlur={(value) => handleDataChanged("taxNumber", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id="mobile-1-lbl" className='form-label mandatory'>{t('mobile_1')}</label>
                        <FrmInput
                            id="mobile-1"
                            value={data.mobile1}
                            onBlur={(value) => handleDataChanged("mobile1", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id="mobile-2-lbl" className='form-label'>{t('mobile_2')}</label>
                        <FrmInput
                            id="mobile-2"
                            value={data.mobile2}
                            onBlur={(value) => handleDataChanged("mobile2", value)}
                        />
                    </div>

                    <div className="w33">
                        <label id="phone-lbl" className='form-label'>{t('phone')}</label>
                        <FrmInput
                            id="phone"
                            type='number'
                            value={data.phoneNum}
                            onKeyDown={handleNumbersKeyDown}
                            onBlur={(value) => handleDataChanged("phoneNum", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id="fax-lbl" className='form-label'>{t('fax')}</label>
                        <FrmInput
                            id="fax"
                            type='number'
                            value={data.fax}
                            onKeyDown={handleNumbersKeyDown}
                            onBlur={(value) => handleDataChanged("fax", value)}
                        />
                    </div>
                    <div className="w33">
                        <label id="email-lbl" className='form-label'>{t('email')}</label>
                        <FrmInput
                            id="email"
                            value={data.emailAddress}
                            onBlur={(value) => handleDataChanged("emailAddress", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="remarks-lbl" className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id="remarks"
                            value={data.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;