import { useRef } from "react";
import { useTranslation } from "react-i18next";
import QuickSearch from "../../components/quick-search";
import EstimationTransactionsStatusesIcon from "../../icons/EstimationTransactionsStatuses";
import FiltersIcon from "../../icons/Filters";
import { RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID } from "../../util/constants/forms";
import EstimationTransactionsStatusesDialog from "./dialogs";
import EstimationTransactionsStatusesFilter from "./listing/Filters";
import EstimationTransactionsStatusesTable from "./listing/index";
import FormTitle from "../../components/form-title";

const EstimationTransactionsStatuses = () => {
    const { t } = useTranslation();

    const searchTextRef = useRef();
    const filterRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <EstimationTransactionsStatusesTable
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
                dialogRef={dialogRef}
            />

            <EstimationTransactionsStatusesFilter
                ref={filterRef}
                tableRef={tableRef}
            />

            <EstimationTransactionsStatusesDialog
                ref={dialogRef}
                tableRef={tableRef}
            />
        </>
    )
}

export default EstimationTransactionsStatuses;