import React from "react";

function FavImgIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.053"
            height="13.15"
            data-name="Group 175638"
            viewBox="0 0 15.053 13.15"
        >
            <defs>
                <clipPath id="fav-path">
                    <path
                        fill="#ff385c"
                        d="M0 0H14.049V12.042H0z"
                        data-name="Rectangle 161919"
                    ></path>
                </clipPath>
            </defs>
            <g data-name="Group 175637" transform="translate(.502 .502)">
                <g data-name="Group 175636">
                    <g clipPath="url(#fav-path)" data-name="Group 175635">
                        <path
                            fill="#ff385c"
                            d="M8.15 13.167c3.512-2.375 7.025-5.018 7.025-8.53a3.512 3.512 0 00-6-2.484L8.15 3.182 7.121 2.154a3.513 3.513 0 00-6 2.484c0 3.512 3.512 6.155 7.025 8.53"
                            data-name="Path 6387"
                            transform="translate(-1.125 -1.125)"
                        ></path>
                    </g>
                </g>
            </g>
            <path
                fill="#fff"
                d="M7.526 13.15l-.281-.19C3.579 10.481 0 7.748 0 4.014a4.014 4.014 0 016.853-2.839l.674.674.673-.674a4.014 4.014 0 016.852 2.839c0 3.734-3.579 6.467-7.245 8.946zM4.014 1A3.011 3.011 0 001 4.014c0 3.154 3.181 5.647 6.523 7.924 3.342-2.276 6.523-4.77 6.523-7.924A3.011 3.011 0 008.91 1.885L7.526 3.269 6.143 1.885A2.994 2.994 0 004.014 1z"
                data-name="Path 6388"
            ></path>
        </svg>
    );
}

export default FavImgIcon;