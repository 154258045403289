import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Map from "../../../../components/map";
import Modal from "../../../../components/modal";
import style from './style.module.scss';

const MapDialog = (props, ref) => {
    const { okAction } = props;
    const { t } = useTranslation("estimationMap");

    const mapRef = useRef(null);
    const dialogRef = useRef(null);
    const mapDataChangedRef = useRef(false);

    const [coords, setCoords] = useState({});

    const handleChange = (e) => {
        mapDataChangedRef.current = true
        setCoords({ lat: e?.center?.lat, lng: e?.center?.lng, zoom: e?.zoom });
    }

    const _handleOkAction = () => {
        if (okAction) {
            const selectedCoords = Object.assign({}, coords);
            if (!selectedCoords?.lat && !selectedCoords?.lng) {
                const defaultLocation = mapRef.current.getDefaultLocation();
                selectedCoords.lat = defaultLocation.lat;
                selectedCoords.lng = defaultLocation.lng;
            }
            okAction(selectedCoords)
        }
    }

    const _handlePostClose = () => {
        setCoords({});
        mapDataChangedRef.current = false;
        mapRef.current.setDefaultLocation();
    }

    const open = () => {
        mapDataChangedRef.current = false;
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    const changePinLocation = (value) => {
        if (value) {
            setCoords(value);
            mapRef.current.changePinLocation(value.lat, value.lng)
        } else {
            setCoords({});
            mapRef.current.setDefaultLocation();
        }
    }

    useImperativeHandle(ref, () => ({
        open,
        close,
        changePinLocation
    }));

    return (
        <Modal
            id="mapDialog"
            ref={dialogRef}
            title={t('map')}
            withFooter="true"
            withOkButton="true"
            withCancelButton="true"
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.mapDialog}
            okBtnAction={_handleOkAction}
            postClose={_handlePostClose}
        >
            <div className={style.mapContainer}>
                <Map
                    ref={mapRef}
                    withPin
                    withSearch
                    onChange={(e) => handleChange(e)}
                    value={coords?.lat && coords?.lng ? `${coords.lat},${coords.lng}` : null}
                    zoom={coords?.zoom && !mapDataChangedRef.current ? coords.zoom : null}
                />
            </div>
        </Modal>
    )
}

export default forwardRef(MapDialog);