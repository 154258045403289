import ActionsButton from "@ipgd-gauge/actions-button";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from '@ipgd-gauge/spinner';
import Tabs from "@ipgd-gauge/tabs";
import { getUrlParam, handleError, hideActionWaiting, hideWaiting, isEnglish, isNotEmpty, showActionWaiting, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import EstimationFormsFieldsBuilderIcon from "../../../icons/EstimationFormsFieldsBuilder";
import SaveActionIcon from "../../../icons/SaveAction";
import XIcon from "../../../icons/X";
import { exportValuationFormLines, getDomainValues, getEstimationFields, getEstimationFieldsRefCode, getEstimationForm, importValuationFormLines, saveEstimationFields } from "../../../util/apis";
import { STATUS_DOMAIN } from "../../../util/constants";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';
import Tab1 from "./tables/ApplicationSettings";
import Tab4 from "./tables/ApprovalSettings";
import Tab2 from "./tables/InspectionSettings";
import Tab5 from "./tables/OtherSettings";
import Tab3 from "./tables/RevisionSettings";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import { useHotkeys } from 'react-hotkeys-hook';

const EstimationFormField = () => {

    const { t } = useTranslation();
    const tabsTableRef = useRef();
    const navigate = useNavigate();
    const fields = useRef()
    const levelMenus = useRef(new Map())

    const user = getUser();

    const [filteredFields, setFilteredFields] = useState([]);
    const [status, setStatus] = useState([]);
    const [refCodeFeilds, setRefCodeFeilds] = useState([]);
    const [blocksOptions, setBlocksOptions] = useState()

    const setFilterBlocksOptions = () => {
        const blocksArr = []
        if (fields.current) {
            for (let i = 0; i < fields.current.length; i++) {
                let blockFields = blocksArr.filter((block) => block.id == fields.current[i].blockId)
                if (blockFields.length <= 0) {
                    levelMenus.current.set(fields.current[i].blockId, fields.current.filter(field => field.blockId == fields.current[i].blockId).map((field, index) => {
                        return {
                            value: index + 1,
                            label: index + 1
                        }
                    }))
                    blocksArr.push({
                        id: fields.current[i].blockId,
                        descLo: fields.current[i].blockDescLo,
                        descFo: fields.current[i].blockDescFo,
                    })
                }
            }
        }
        setBlocksOptions(blocksArr)
    }

    useEffect(() => {
        const id = getUrlParam('id');
        getDomainValues(STATUS_DOMAIN).then(response => setStatus(response.data)).catch(error => handleError(error, null, t));
        if (isNotEmpty(id)) {
            getEstimationFields(id)
                .then((response) => {
                    fields.current = response.data
                    setFilterBlocksOptions()
                    setFilteredFields(fields.current)
                })
                .catch((error) => {
                    handleError(error, null, t);
                    navigate(`/valuation-forms`);
                });
        } else {
            navigate(`/valuation-forms`);
        }

        getEstimationFieldsRefCode().then(res => {
            setRefCodeFeilds(res.data)
        })
    }, []);

    const _handleDataChange = (index, name, value) => {
        if (name == "showInEntry" && !value) {
            filteredFields[index].isDisabledEntry = false
            filteredFields[index].isOptionalEntry = false
        }
        if (name == "showInApproval" && !value) {
            filteredFields[index].isDisabledApproval = false
            filteredFields[index].isOptionalApproval = false
        }
        if (name == "showInRevision" && !value) {
            filteredFields[index].isOptionalRevision = false
            filteredFields[index].isDisabledRevision = false
        }
        if (name == "showInEstimation" && !value) {
            filteredFields[index].isOptionalEstimation = false
            filteredFields[index].isDisabledEstimation = false
        }
        if (name == "fieldSequence") {
            filteredFields[index].seq = value;
        }
        filteredFields[index][name] = value;
        setFilteredFields([...filteredFields]);
    }

    const _saveAction = () => {
        const id = getUrlParam('id');
        if (isNotEmpty(id)) {
            showActionWaiting("valuation-form-save-action-1", true)
            saveEstimationFields(id, fields.current)
                .then(() => {
                    showSuccessMessage("save_successfully", t);
                    navigate(`/valuation-forms`);
                })
                .catch(error => {
                    let errorMessage = error.response.data.message;
                    if (errorMessage && errorMessage.includes("#")) {
                        handleError(null, `estimationFormsFieldsBuilder:${errorMessage.split('#')[0]}`, t, [{ name: 'block', value: isEnglish() ? errorMessage.split('#')[1] : errorMessage.split('#')[2] }]);
                    } else {
                        handleError(null, null, t)
                    }
                })
                .finally(() => {
                    hideActionWaiting("valuation-form-save-action-1")
                });
        }
    }


    const _isSelected = (name) => {
        if (filteredFields && filteredFields.length > 0) {
            if (name == "showInEntry" || name == "showInEstimation" || name == "showInRevision" || name == "showInApproval") {
                return filteredFields.filter(line => line[name] || Boolean(line.isSystemField)).length === filteredFields.length;
            }
            if (name == "isOptionalEntry") {
                return filteredFields.filter(line => line[name] || !Boolean(line.systemIsOptional) || !Boolean(line.showInEntry)).length === filteredFields.length;
            }
            if (name == "isDisabledEntry") {
                return filteredFields.filter(line => line[name] || Boolean(line.systemIsDisabled) || !Boolean(line.showInEntry)).length === filteredFields.length;
            }
            if (name == "isOptionalEstimation") {
                return filteredFields.filter(line => line[name] || !Boolean(line.systemIsOptional) || !Boolean(line.showInEstimation)).length === filteredFields.length;
            }
            if (name == "isDisabledEstimation") {
                return filteredFields.filter(line => line[name] || Boolean(line.systemIsDisabled) || !Boolean(line.showInEstimation)).length === filteredFields.length;
            }
            if (name == "isOptionalRevision") {
                return filteredFields.filter(line => line[name] || !Boolean(line.systemIsOptional) || !Boolean(line.showInRevision)).length === filteredFields.length;
            }
            if (name == "isDisabledRevision") {
                return filteredFields.filter(line => line[name] || Boolean(line.systemIsDisabled) || !Boolean(line.showInRevision)).length === filteredFields.length;
            }
            if (name == "isOptionalApproval") {
                return filteredFields.filter(line => line[name] || !Boolean(line.systemIsOptional) || !Boolean(line.showInApproval)).length === filteredFields.length;
            }
            if (name == "isDisabledApproval") {
                return filteredFields.filter(line => line[name] || Boolean(line.systemIsDisabled) || !Boolean(line.showInApproval)).length === filteredFields.length;
            }
            if (name == "isSearchable") {
                return filteredFields.filter(line => line[name] || !Boolean(line.systemIsSearchable)).length === filteredFields.length;
            }
            return filteredFields.filter(line => line[name]).length === filteredFields.length;
        }

        return false;
    }


    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        _saveAction();
    }, { enableOnFormTags: true });

    return (
        <div className={style.tabsContainer}>
            <ActionsButton actions={[{ id: "valuation-form-save-action-1", label: t('save'), icon: <SaveActionIcon />, hotkey: SAVE_ACTION_SHORT_CUT, action: _saveAction }]} />

            <div className={style.filterContainer}>
                <FilterPanel
                    blocksOptions={blocksOptions}
                    setFilteredFields={setFilteredFields}
                    fields={fields.current}
                    filteredFields={filteredFields}
                />
            </div>
            <Tabs
                id="valuation-form-builder-tabs"
                ref={tabsTableRef}
                labels={[
                    'estimationFormsFieldsBuilder:application_settings',
                    'estimationFormsFieldsBuilder:inspection_settings',
                    'estimationFormsFieldsBuilder:revision_settings',
                    'estimationFormsFieldsBuilder:approval_settings',
                    'estimationFormsFieldsBuilder:other_settings'
                ]}
                containerStyle={style.tabsContainer}
                tabsButtonCustomStyle={style.mainTabs}
            >
                <Tab1
                    user={user}
                    fields={filteredFields}
                    setFields={setFilteredFields}
                    handleDataChange={_handleDataChange}
                    isSelected={_isSelected}
                />
                <Tab2
                    user={user}
                    fields={filteredFields}
                    setFields={setFilteredFields}
                    handleDataChange={_handleDataChange}
                    isSelected={_isSelected}
                />
                <Tab3
                    user={user}
                    fields={filteredFields}
                    setFields={setFilteredFields}
                    handleDataChange={_handleDataChange}
                    isSelected={_isSelected}
                />
                <Tab4
                    user={user}
                    fields={filteredFields}
                    setFields={setFilteredFields}
                    handleDataChange={_handleDataChange}
                    isSelected={_isSelected}
                />
                <Tab5
                    user={user}
                    levelMenus={levelMenus}
                    fields={filteredFields}
                    setFields={setFilteredFields}
                    handleDataChange={_handleDataChange}
                    isSelected={_isSelected}
                    status={status}
                    refCodeFeilds={refCodeFeilds}
                />
            </Tabs>
        </div>
    );
};

const ImportLines = () => {
    const id = getUrlParam('id')
    const { t } = useTranslation();
    const navigate = useNavigate();

    const _handleUpload = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                showWaiting("import-valuation-form", true, true);
                importValuationFormLines(id, file)
                    .then(() => {
                        showSuccessMessage("save_successfully", t);
                        navigate("/valuation-forms");
                    })
                    .catch((error) => { })
                    .finally(() => {
                        hideWaiting("import-valuation-form");
                    })
            };
            reader.readAsDataURL(file);
        })
    }

    const { getRootProps: getRootProps, getInputProps: getInputProps } = useDropzone({
        accept: '.txt',
        onDrop: _handleUpload,
        maxFiles: 1,
        onDropRejected: (e) => { }
    });

    return (
        <div {...getRootProps({ className: "primary-button" })}>
            <input {...getInputProps()} />
            <Spinner id="import-valuation-form" className={style.spinner} />
            {t("import")}
        </div>
    )
}

const FilterPanel = (props) => {
    const { fields, setFilteredFields, blocksOptions } = props

    const id = getUrlParam('id')
    const form = useRef(null);
    const filters = useRef({ searchVal: "" })
    const { t } = useTranslation();

    const [blocksFilter, setBlocksFilter] = useState([])

    useEffect(() => {
        if (id) {
            getEstimationForm(id)
                .then(res => form.current = res.data)
                .catch(() => { });
        }
    }, [])

    const search = (name, value) => {
        filters.current[name] = value
        setFilteredFields(fields.filter(field =>
            (!filters.current.blocks ||
                filters.current.blocks.length <= 0 ||
                filters.current.blocks.includes(field.blockId + ""))
            &&
            (field.blockDescFo.includes(filters.current.searchVal) ||
                field.blockDescLo.includes(filters.current.searchVal) ||
                field.fieldDescFo.includes(filters.current.searchVal) ||
                field.fieldDescLo.includes(filters.current.searchVal))
        ))
    }

    return (
        <div className={`row ${style.filterRow}`}>
            <div className={`w25 ${style.titlePanel}`}>
                <EstimationFormsFieldsBuilderIcon />
                <label className={style.fitlerTitle}>{isEnglish() ? form?.current?.descLo : form?.current?.descFo}</label>
            </div>
            <div className={`w25 ${style.dataActions}`}>
                <button
                    className="primary-button"
                    onClick={() => exportValuationFormLines(id, isEnglish() ? form?.current?.descLo : form?.current?.descFo, "export-valuation-form")}
                >
                    {t("export")}
                    <Spinner id="export-valuation-form" className={style.spinner} />
                </button>
                <ImportLines />
            </div>
            <div className='w25'>
                <FrmSelectMenu
                    isMulti
                    placeholder={t('block')}
                    onChange={(value) => {
                        setBlocksFilter(value)
                        search("blocks", value)
                    }}
                    value={blocksFilter}
                    options={blocksOptions}
                    descLo={["descLo"]}
                    descFo={["descFo"]}
                />
            </div>
            <div className={`w25 ${style.filterInputWidth}`}>
                <InputSearchWhileType action={(value) => search("searchVal", value)} />
            </div>
        </div>
    )
}

const InputSearchWhileType = (props) => {
    const { action } = props
    const { t } = useTranslation();
    const [val, setVal] = useState(null)

    useEffect(() => {
        if (val != null) {
            const timeOutId = setTimeout(() => {
                if (action) {
                    action(val)
                }
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [val]);

    return (
        <div className={style.inputSearchWhileType}>
            <FrmInput
                value={val}
                onChange={(e) => {
                    setVal(e.target.value)
                }}
                placeholder={`${t('estimationFormsFieldsBuilder:block_name')} , ${t('estimationFormsFieldsBuilder:field_user_name_a')}`}
            />
            {val &&
                <button className={style.closeBtn} onClick={() => setVal("")}><XIcon width="12px" height="12px" /></button>
            }
        </div>
    )
}

export default EstimationFormField;