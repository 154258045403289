import RadioOptions from "@ipgd-gauge/radio-options";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, isEnglish } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MultiInsertDialog from "../../../../components/multi-insert-dialog";
import Table from "../../../../components/table";
import Waiting from "../../../../components/waiting";
import { getOrganizationsList } from "../../../../util/apis";
import { getUser } from "../../../../util/cookies";
import style from './style.module.scss';
import AddHeaderBtnIcon from "../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../icons/Delete2";

const OrganizationList = (props) => {

    const { data, setData, formId, generalName, orgList, tableOrganizationStyle, tableMultiInsertStyle, multiInsertDialogStyle, inSidePanel, onDataChange } = props;

    const { t } = useTranslation();
    const organizationDialogRef = useRef();

    const user = useSelector(state => state.persistedReducer.userReducer.user);

    const _handleDataChanged = (name, value) => {
        data[name] = value;

        if (name === (generalName || "general")) {
            if (value) {
                data[orgList || 'organizations'] = [];
            } else {
                data[orgList || 'organizations'] = [
                    {
                        fogId: user?.fogId,
                        offecialNameLo: user?.orgNameLo,
                        offecialNameFo: user?.orgNameFo,
                        organizationCode: user?.orgCode,
                    }
                ]
            }
        }

        if (onDataChange) {
            onDataChange();
        }

        setData({ ...data });
    }

    const _insertOrganizations = (selectedData) => {
        if (!data[orgList || "organizations"]) {
            data[orgList || "organizations"] = [];
        }
        const newLines = [];
        for (let index = 0; index < selectedData.length; index++) {
            const element = selectedData[index];
            newLines.push({
                fogId: element.id,
                offecialNameLo: element.offecialNameLo,
                offecialNameFo: element.offecialNameFo,
                organizationCode: element.code
            });
        }
        data[orgList || "organizations"] = [...data[orgList || "organizations"], ...newLines];
        setData({ ...data });

        organizationDialogRef?.current?.close();
    }

    const _handleDeleteAll = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            _handleDataChanged(orgList || "organizations", [])
        });
    }

    return (
        <>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className={` ${inSidePanel ? 'w100' : "w50"}  ${inSidePanel ? style.organizationRadioSidePanel : ""}`} style={{ marginBottom: inSidePanel ? "15px" : "" }}>
                        <label className="form-label">{t('organization')}</label>

                        <div className={style.installations}>
                            <RadioOptions
                                id={`organization-general`}
                                sidePanelMode={inSidePanel ? true : false}
                                options={[
                                    {
                                        id: "all_organization",
                                        title: t('all'),
                                        selected: data[generalName || 'general'],
                                        action: () => _handleDataChanged(generalName || 'general', true),
                                    },
                                    {
                                        id: "specific_organization",
                                        title: t('specific'),
                                        selected: !data[generalName || 'general'],
                                        action: () => _handleDataChanged(generalName || 'general', false),
                                    },
                                ]} />
                        </div>
                    </div>

                    <div className={`${inSidePanel ? 'w100' : "w50"} ${inSidePanel ? 'tableHeaderActionsTable' : "tableHeaderActionsOrg"} ${style.tableHeaderActionsSm} ${inSidePanel ? style.insertBtnSidePanel : ""}`}>
                        <button
                            disabled={data[generalName || 'general']}
                            onClick={() => organizationDialogRef?.current?.open()}>
                            <span>
                                <AddHeaderBtnIcon />
                            </span>
                            <label>
                                {t('insert')}
                            </label>
                        </button>

                        <button
                            className="tableHeaderDeleBtn"
                            onClick={_handleDeleteAll}
                            disabled={(data[orgList || "organizations"] || []).length === 0}>
                            <span>
                                <DeleteTowIcon />
                            </span>
                            <label>
                                {t('delete_all')}
                            </label>
                        </button>
                    </div>
                </div>

                {!data[generalName || 'general'] &&
                    <OrganizationTable
                        data={data}
                        setData={setData}
                        generalList={orgList}
                        tableOrganizationStyle={tableOrganizationStyle}
                        inSidePanel={inSidePanel}
                        formId={formId}
                    />
                }
            </div>

            <MultiInsertDialog
                id="insert-organization-dialog"
                ref={organizationDialogRef}
                title={t('insert_organizations')}
                okBtnAction={_insertOrganizations}
                api={getOrganizationsList}
                tableStyle={`${style.organizationsTableDialog} ${tableMultiInsertStyle}`}
                dialogStyle={`${style.organizationDialog} ${multiInsertDialogStyle}`}
                selectedIds={(data[orgList || "organizations"] || []).map((line) => line.fogId)}
                apiParams={formId}
                searchPlaceholder={t('code') + " , " + t('name')}
                inSidePanel={inSidePanel}
                formId={formId}
                columns={[
                    { width: isMobile ? '10%' : '5%' },
                    { label: '#', width: isMobile ? "10%" : "5%" },
                    { label: t('name'), valueLo: 'organizationCodeNameLo', valueFo: 'organizationCodeNameFo', width: isMobile ? "80%" : "90%" },
                ]}
            />
        </>
    )
}

const OrganizationTable = (props) => {
    const { data, setData, orgsList, tableOrganizationStyle, formId, inSidePanel } = props;
    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data[orgsList || "organizations"]) {
            data[orgsList || "organizations"] = [];
        }

        data[orgsList || "organizations"].splice(index, 1);
        setData({ ...data });
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: <span className="mandatory">{t('organization_name')}</span>, width: "78%" },
        { title: "", width: "17%" },
    ];

    return (
        <>
            <div className={style.organizationTableContainer}>
                <Table
                    id="organizationTable"
                    columns={columns}
                    Row={OrganizationRow}
                    tableStyle={`${style.organizationTable} ${tableOrganizationStyle} ${inSidePanel ? style.organizationTableSidePanel : ""}`}
                    list={data[orgsList || "organizations"] || []}
                    rowActions={{ handleDeleteLine: _handleDeleteLine }}
                    showNoDataFound={(data) => data && data.length == 0}
                    rowHeight={40}
                    user={getUser()}
                    formId={formId}
                    Waiting={Waiting}
                />
            </div>
        </>
    );
};

const OrganizationRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${style.organizationRow}`} style={virtualizedStyle}>
            <div title={data?.sequence}><div>{data?.sequence}</div></div>
            <div title={isEnglish() ? `${data.organizationCode || ""}  -  ${data?.offecialNameLo}` : `${data.organizationCode || ""}  -  ${data?.offecialNameFo}`}><div>{isEnglish() ? `${data.organizationCode || ""}  -  ${data?.offecialNameLo}` : `${data.organizationCode || ""}  -  ${data?.offecialNameFo}`}</div></div>
            <div>
                <div className="containerButtonTable deleteBtnSm">
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

export default OrganizationList;