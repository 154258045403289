import React from "react";

function ReportsDesignIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        fill="#088587"
        data-name="Group 177928"
        transform="translate(-279.25 -213.25)"
      >
        <path
          d="M6 14.75H3.43c-2.7 0-4.18-1.484-4.18-4.18V3.43C-.75.734.734-.75 3.43-.75H8c2.7 0 4.18 1.484 4.18 4.18a.75.75 0 01-1.5 0c0-1.879-.8-2.68-2.68-2.68H3.43C1.552.75.75 1.551.75 3.43v7.14c0 1.878.8 2.68 2.68 2.68H6a.75.75 0 010 1.5z"
          opacity="0.4"
          transform="translate(280 214)"
        ></path>
        <path
          d="M8 14.75H3.43c-2.7 0-4.18-1.484-4.18-4.18V3.43C-.75.734.734-.75 3.43-.75H8c2.7 0 4.18 1.484 4.18 4.18v7.14c0 2.696-1.48 4.18-4.18 4.18zM3.43.75C1.552.75.75 1.551.75 3.43v7.14c0 1.878.8 2.68 2.68 2.68H8c1.878 0 2.68-.8 2.68-2.68V3.43c0-1.879-.8-2.68-2.68-2.68z"
          data-name="Vector"
          transform="translate(288.57 220)"
        ></path>
        <g data-name="Group 624" opacity="0.4" transform="translate(278 212)">
          <path
            d="M3.26.75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h3.26a.75.75 0 01.75.75.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(14.869 15)"
          ></path>
          <path
            d="M0 4.01a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v3.26a.75.75 0 01-.75.75z"
            data-name="Vector"
            transform="translate(16.5 13.37)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ReportsDesignIcon;