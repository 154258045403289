import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import ExchangeRateInput from "../../../components/exchange-rate";
import HijriDate from "../../../components/hijri-date";
import { convertGregorianDateToHijri, getAdditionalItemsLov, getCommonBranches, getCommonDivisionsList, getCurrenciesLov, getDomainValues, getExpenseAccountList } from "../../../util/apis";
import { AFFECTED_ACCOUNT_PARTIES_ACCOUNTS, DATE_LEVEL_DOMAIN, DATE_LEVEL_DOMAIN_ALL, DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES, DOMAIN_AFFECTED_ACCOUNT, DOMAIN_INVOICE_LEVEL, STATUS_ACTIVE, STATUS_ESTIMATOR_FEES, STATUS_INSPECTOR_FEES } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { handleScrollShadow } from "../../../util/util";
import { ACTION_SET_HEADER_VALUE } from "./reducer";
import { useRef } from "react";

const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnBranchCondition';
const Header = (props) => {
    const { user, invoice, dispatchInvoice } = props;

    const { t } = useTranslation("estimatorsInspectorsInvoices");
    const defaultExpenseItem = useRef(true);

    const userPolicy = user.estimationInvoiceLsoId;

    const _handleChange = (name, value) => {
        dispatchInvoice({
            type: ACTION_SET_HEADER_VALUE,
            name: name,
            value: value
        })
        if (name == 'dateDgr') {
            if (value) {
                convertGregorianDateToHijri(value)
                    .then((response) => {
                        dispatchInvoice({
                            type: ACTION_SET_HEADER_VALUE,
                            name: 'dateDhi',
                            value: response.data
                        })
                    })
                    .catch(() => { })
            } else {
                dispatchInvoice({
                    type: ACTION_SET_HEADER_VALUE,
                    name: 'dateDhi',
                    value: ''
                })
            }
        }
    }

    return (
        <>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('header')}
                        </label>
                        <div className={`status-${invoice?.header?.globalStatus}`}>
                            <label>{t(`status_${invoice.header.globalStatus}`)}</label>
                        </div>
                    </div>
                </div>
                <div className={`container`}>
                    <div className={`row`} id="estimatorsInspectorsInvoicesHeader" onScroll={handleScrollShadow}>
                        <div className="w25">
                            <CodeInput
                                id="code"
                                value={invoice?.header?.code}
                                onBlur={(value) => _handleChange("code", value)}
                                column="EIO_CODE"
                                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                            />
                        </div>

                        <div className="w25">
                            <label id="date-g-lbl" className='form-label mandatory'>{t('date_g')}</label>
                            <FrmTimeDatePickr
                                id="date-g"
                                value={invoice?.header?.dateDgr}
                                onChange={(value) => _handleChange("dateDgr", value)}
                            />
                        </div>
                        <div className="w25">
                            <label id="date-h-lbl" className='form-label mandatory'>{t('date_h')}</label>
                            <HijriDate
                                id="date-h"
                                hijriDate={invoice?.header?.dateDhi}
                                gregorianDate={invoice?.header?.dateDgr}
                                onChange={(value) => _handleChange("dateDhi", value)}
                            />
                        </div>
                        <div className="w25">
                            <label className='form-label'>{t('ref_code')}</label>
                            <FrmInput
                                id="ref_code"
                                value={invoice?.header?.refCode || ''}
                                onBlur={(value) => _handleChange("refCode", value)}
                            />
                        </div>
                        <div className="w100">
                            <label className='form-label'>{t('remarks')}</label>
                            <FrmInput
                                id="remarks"
                                value={invoice?.header?.rem || ''}
                                onBlur={(value) => _handleChange("rem", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('branch_division')}
                        </label>
                    </div>
                </div>
                <div className={`container`}>
                    <div className={`row`}>
                        <div className="w50">
                            <label id="branch-lbl" className='form-label mandatory'>{t('branch')}</label>
                            <FrmSelectMenu
                                id="branch"
                                key={`branch-${invoice?.id}`}
                                value={invoice?.header?.mbnId}
                                api={() => getCommonBranches(null, isEmpty(invoice?.header?.mbnId) ? [] : [invoice?.header?.mbnId], STATUS_ACTIVE, BRANCH_CONDITION, false, userPolicy)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("mbnId", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label id="division-lbl" className='form-label mandatory'>{t('division')}</label>
                            <FrmSelectMenu
                                id="division"
                                key={`division-${invoice?.header?.mbnId}`}
                                value={invoice?.header?.dsvId}
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false, null, null, invoice?.header?.mbnId, STATUS_ACTIVE, isEmpty(invoice?.header?.dsvId) ? [] : [invoice?.header?.dsvId])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("dsvId", value.value)}
                                isDisabled={!invoice?.header?.mbnId}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('party')}
                        </label>
                    </div>
                </div>
                <div className={`container`}>
                    <div className={`row`}>
                        <div className="w25">
                            <label id="invoice_level-lbl" className='form-label mandatory'>{t('invoice_level')}</label>
                            <FrmSelectMenu
                                id="invoice_level"
                                value={invoice?.header?.invoiceLevel}
                                api={() => getDomainValues(DOMAIN_INVOICE_LEVEL)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("invoiceLevel", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w25">
                            <label id="affected_account-lbl" className='form-label mandatory'>{t('affected_account')}</label>
                            <FrmSelectMenu
                                id="affected_account"
                                value={invoice?.header?.affectedAccounts}
                                api={() => getDomainValues(DOMAIN_AFFECTED_ACCOUNT)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("affectedAccounts", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label
                                id={`${invoice?.header?.affectedAccounts}` == AFFECTED_ACCOUNT_PARTIES_ACCOUNTS ? "other_accounts" : "other_accounts-lbl"}
                                className={`form-label ${invoice?.header?.affectedAccounts == AFFECTED_ACCOUNT_PARTIES_ACCOUNTS ? '' : 'mandatory'}`}
                            >
                                {t('other_accounts')}
                            </label>
                            <FrmSelectMenu
                                id="other_accounts"
                                value={invoice?.header?.otherGstId}
                                api={() => getExpenseAccountList(null, invoice?.header?.otherGstId)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={(e) => _handleChange("otherGstId", e.value)}
                                isDisabled={invoice?.header?.affectedAccounts == AFFECTED_ACCOUNT_PARTIES_ACCOUNTS}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('customersEstimationInvoices:financial_statements')}
                        </label>
                    </div>
                </div>
                <div className={`container`}>
                    <div className={`row`}>
                        <div className="w25">
                            <label id="default_expense_items-lbl" className='form-label mandatory'>{t('default_expense_items')}</label>
                            <FrmSelectMenu
                                id="default_expense_items"
                                key={`default_expense_items_${invoice?.id}`}
                                value={invoice?.header?.defaultCdaId}
                                api={() => getAdditionalItemsLov(null, isEmpty(invoice?.header?.defaultCdaId) ? [] : [invoice?.header?.defaultCdaId], STATUS_ACTIVE, [STATUS_INSPECTOR_FEES, STATUS_ESTIMATOR_FEES, DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleChange("defaultCda", value)}
                                onDataRetrieved={(list) => {
                                    console.log("🚀  list:", list)
                                    const id = getUrlParam("id");
                                    if (isNotEmpty(id) && invoice?.header?.defaultCdaId && defaultExpenseItem.current) {
                                        list?.forEach((element) => {
                                            if (element?.id == invoice?.header?.defaultCdaId) {
                                                _handleChange("defaultExpenseItem", { ...element })
                                                return;
                                            }
                                        })
                                    }
                                    if (defaultExpenseItem.current && invoice?.header?.defaultCdaId) {
                                        defaultExpenseItem.current = false;
                                    }
                                }}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w25">
                            <label id="currency-lbl" className='form-label mandatory'>{t('currency')}</label>
                            <FrmSelectMenu
                                id="currency"
                                key={`currency-${invoice?.id}`}
                                api={() => getCurrenciesLov(invoice?.header?.fcuId)}
                                value={invoice?.header?.fcuId}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={(e) => _handleChange("fcuId", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w25">
                            <label id="ex_rate-lbl" className='form-label mandatory'>{t('ex_rate')}</label>
                            <ExchangeRateInput
                                id="ex_rate"
                                value={invoice?.header?.exchngRate || ''}
                                onBlur={(value) => _handleChange("exchngRate", value)}
                                currencyId={invoice?.header?.fcuId}
                                transactionId={invoice?.header?.id}
                                transactionDate={invoice?.header?.dateDgr}
                                organizationCurrency={user.fcuId}
                            />
                        </div>
                        <div className="w25">
                            <label className='form-label'>{t('required_date')}</label>
                            <FrmSelectMenu
                                id={"required_date"}
                                api={() => getDomainValues(DATE_LEVEL_DOMAIN)}
                                value={invoice?.header?.billingDateMode}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={(e) => _handleChange("billingDateMode", e.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null &&
                            < div className="w25">
                                <label className='form-label'>{t('required_date_from_g')}</label>
                                <FrmTimeDatePickr
                                    id={"billing-period-from-dgr"}
                                    value={invoice?.header?.billingPeriodFromDgr}
                                    onChange={value => _handleChange("billingPeriodFromDgr", value)}
                                />
                            </div>
                        }
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null &&

                            <div className="w25">
                                <label className='form-label'>{t('required_date_to_g')}</label>
                                <FrmTimeDatePickr
                                    id={"billing-period-to-dgr"}
                                    value={invoice?.header?.billingPeriodToDgr}
                                    onChange={value => _handleChange("billingPeriodToDgr", value)}
                                />
                            </div>
                        }
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null &&

                            <div className="w25">
                                <label className='form-label'>{t('required_date_from_h')}</label>
                                <HijriDate
                                    id={"billing-period-from-dhi"}
                                    hijriDate={invoice?.header?.billingPeriodFromDhi}
                                    gregorianDate={invoice?.header?.billingPeriodFromDgr}
                                    onChange={(value) => _handleChange("billingPeriodFromDhi", value)}
                                />
                            </div>
                        }
                        {invoice?.header?.billingDateMode != DATE_LEVEL_DOMAIN_ALL && invoice?.header?.billingDateMode != null &&

                            <div className="w25">
                                <label className='form-label'>{t('required_date_to_h')}</label>
                                <HijriDate
                                    id={"billing-period-to-dhi"}
                                    hijriDate={invoice?.header?.billingPeriodToDhi}
                                    gregorianDate={invoice?.header?.billingPeriodToDgr}
                                    onChange={(value) => _handleChange("billingPeriodToDhi", value)}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;