import FrmInput from "@ipgd-gauge/frm-input";
import { DeleteBtn } from "@ipgd-gauge/table";
import { deleteItemByIndex } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { confirmationDialog, isEnglish } from "../../../util/util";
import style from './style.module.scss';

const Tab5 = (props) => {
    const { tab, user, data, setData } = props;
    const { t } = useTranslation();

    const _addLine = () => {
        if (!data.banks) {
            data.banks = [];
        }
        data.banks.push({});
        setData({ ...data });
    }

    const _deleteAllBanks = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.banks = [];
            setData({ ...data });
        });
    }

    return (
        <div className={tab == 5 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50"></div>
                    <div className={`w50 insertBtn`}>
                        <button className="primary-button" onClick={_addLine} title={t('add')}>
                            {t('add')}
                            
                        </button>
                        <button
                            title={t('delete_all')}
                            className="delete-button"
                            onClick={_deleteAllBanks}
                            disabled={(data.banks || []).length === 0}
                        >
                            {t('delete_all')}
                        </button>
                    </div>
                </div>
            </div>
            <BanksTable user={user} data={data} setData={setData} />
        </div>
    )
}

const BanksTable = (props) => {
    const { user, data, setData } = props;
    const { t } = useTranslation();

    const _handleDeleteLine = (index) => {
        if (!data.banks) {
            data.banks = [];
        }

        deleteItemByIndex(data.banks, index);
        setData({ ...data });
    }

    const _handleChange = (index, name, value) => {
        let bank = data.banks[index];
        bank[name] = value;
        data.banks[index] = bank;
        setData({ ...data })
    }

    const columns = [
        { title: '#', width: "5%" },
        { title: <span data-property="bankName" className='mandatory'>{t('propertiesPartiesDefinition:bank')}</span>, width: "20%" },
        { title: <span data-property="ibanCode" className='mandatory'>{t('propertiesPartiesDefinition:account_num')}</span>, width: "20%" },
        { title: <span data-property="accountNumber" className='mandatory'>{t('propertiesPartiesDefinition:i_ban_num')}</span>, width: "20%" },
        { title: t('propertiesPartiesDefinition:remarks'), width: "20%" },
        { title: '', width: "15%" }
    ]

    return (
        <>
            <div className={style.banksTableContainer}>
                <Table
                    id="banks-table"
                    columns={columns}
                    Row={BanksRow}
                    tableStyle={style.banksTable}
                    rowHeight={50}
                    list={data.banks || []}
                    rowActions={{ handleDeleteLine: _handleDeleteLine, handleChange: _handleChange }}
                    user={user}
                    formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                />
            </div>
        </>
    );
}

const BanksRow = (props) => {
    const { data, index, rowActions, virtualizedStyle } = props
    const { handleChange, handleDeleteLine } = rowActions;

    const _handleDataChanged = (name, value) => {
        handleChange(index, name, value);
    }

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row`} style={virtualizedStyle}>
            <div><div>{data.sequence}</div></div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        index={`bank-name-${index}`}
                        value={data.bankName}
                        onBlur={(value) => _handleDataChanged('bankName', value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        index={`iban-code-${index}`}
                        value={data.ibanCode}
                        onBlur={(value) => _handleDataChanged('ibanCode', value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        index={`account-number-${index}`}
                        value={data.accountNumber}
                        onBlur={(value) => _handleDataChanged('accountNumber', value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        index={`bank-rem-${index}`}
                        value={data.rem}
                        onBlur={(value) => _handleDataChanged('rem', value)}
                    />
                </div>
            </div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};

export default Tab5;