import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { isEnglish } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../../../components/multi-insert-dialog";
import Table from "../../../../../components/table";
import AddHeaderBtnIcon from "../../../../../icons/AddHeaderBtn";
import DeleteTowIcon from "../../../../../icons/Delete2";
import { getCommonBranches } from "../../../../../util/apis";
import { CUSTOMER_LEVEL_SPECIFIC, POLICY_LVL_GENERAL, STATUS_ACTIVE } from "../../../../../util/constants";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../../../util/constants/forms";
import { ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES } from "../reducer";
import style from '../style.module.scss';

const BRANCH_CONDITION = 'dependsOnOrgIds';

const Branches = (props) => {
    const { user, data, lov, handleDataChanged, handleDeleteLine, handleDeleteAllLines, dispatchPropertyPolicy } = props;

    const { t } = useTranslation();
    const branchDialogRef = useRef();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesPolicies:code'), width: "20%" },
        { title: t('propertiesPolicies:name'), width: "60%" },
        { title: "", width: "15%" },
    ];

    const _insertBranches = (selectedData) => {
        let newLines = [];

        selectedData?.map((element) => newLines.push({
            mbnId: element.id,
            code: element.code,
            descLo: element.descLo,
            descFo: element.descFo,
        }));

        dispatchPropertyPolicy({ type: ACTION_INSERT_PROPERTIES_POLICY_DETAIL_LINES, name: "branches", value: newLines });
        branchDialogRef?.current?.close();
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle-table">
                <div>
                    <label className="frm-title-form-border">
                        {t('propertiesPolicies:branches')}
                    </label>
                </div>

                <div className={style.actionSections}>
                    <div className={style.inputSection}>
                        <label className='form-label' > {t('propertiesPolicies:level')}</label>
                        <FrmSelectMenu
                            id={`properties-policies-dialog-branches`}
                            value={data.allBranches}
                            options={lov?.customerLevel}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("allBranches", value.value)}
                            isDisabled={!(data?.usageLevel == POLICY_LVL_GENERAL && data?.function?.allBranches == 1)}
                            portalTarget={document.body}
                        />
                    </div>

                    {data?.allBranches == CUSTOMER_LEVEL_SPECIFIC &&
                        <div className="tableHeaderActionsOrg">
                            <button title={t('insert')} onClick={() => branchDialogRef.current?.open()}>
                                <span><AddHeaderBtnIcon /></span>
                                <label>{t('insert')}</label>
                            </button>

                            <button
                                className="tableHeaderDeleBtn"
                                title={t('delete_all')}
                                onClick={() => handleDeleteAllLines('branches')}
                                disabled={(data.branches || []).length === 0}>
                                <span><DeleteTowIcon /></span>
                                <label>{t('delete_all')}</label>
                            </button>
                        </div>
                    }
                </div>
            </div>

            {data?.allBranches == CUSTOMER_LEVEL_SPECIFIC &&
                <>
                    <Table
                        id="policy-branches-table"
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        columns={columns}
                        user={user}
                        Row={BranchesRow}
                        rowHeight={40}
                        tableStyle={style.branchesTable}
                        list={data?.branches}
                        rowActions={{ handleDeleteLine }}
                    />

                    <MultiInsertDialog
                        id="properties-Policies-multi-insert-branch-dialog"
                        ref={branchDialogRef}
                        formId={RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID}
                        title={t('propertiesPolicies:insert_branches')}
                        okBtnAction={_insertBranches}
                        api={(searchKey) => getCommonBranches(searchKey, null, STATUS_ACTIVE, BRANCH_CONDITION, data.general, null, data.organizations?.map((org) => org.fogId))}
                        selectedIds={(data?.branches || []).map((line) => line.mbnId)}
                        apiParams={{ general: data.general, organizationIds: data.organizations?.map((org) => org.fogId) }}
                        searchPlaceholder={`${t('propertiesPolicies:code')} , ${t('propertiesPolicies:name')}`}
                        columns={[
                            { width: '5%' },
                            { label: '#', width: '10%' },
                            { label: t('propertiesPolicies:code'), value: 'code', width: '25%' },
                            { label: t('propertiesPolicies:name'), valueLo: 'descLo', valueFo: 'descFo', width: '60%' },
                        ]}
                    />
                </>
            }
        </div>
    );
}

const BranchesRow = (props) => {
    const { virtualizedStyle, data, rowActions, index } = props;
    const { handleDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${style.branchesRow}`} style={virtualizedStyle}>
            <div><div>{data.sequence}</div></div>
            <div><div>{data.code}</div></div>
            <div><div>{isEnglish() ? data.descLo : data.descFo}</div></div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index, "branches")} />
                </div>
            </div>
        </div>
    );
};

export default Branches;