import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { handleError, handleNumbersKeyDown, hideWaiting, isAllowedAction, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import { getControlWithExcelField, saveControlWithExcelFields } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3274_CONTROL_WITH_EXCEL_FIELDS } from "../../../util/constants/forms";

const dialogId = "control-with-excel-fields-dialog";

const ControlWithExcelFieldsDialog = (props, ref) => {
    const { user, tableRef } = props;
    const { t } = useTranslation();
    const dialogRef = useRef();
    const [excelField, setExcelField] = useState({ status: STATUS_ACTIVE });

    const _handleDataChanged = (name, value) => {
        excelField[name] = value;
        setExcelField({ ...excelField });
    }

    const _saveAction = () => {
        showWaiting(`${dialogId}-btn`, true)
        saveControlWithExcelFields(excelField)
            .then(() => {
                tableRef?.current?.refresh();
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-btn`))
    }

    const _populateRecordData = (id) => {
        if (id) {
            getControlWithExcelField(id)
                .then((response) => setExcelField(response.data))
                .catch((error) => handleError(error, null, t))
        } else {
            setExcelField({ ...excelField })
        }
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef.current.open();
    }

    const _postClose = () => {
        setExcelField({ status: STATUS_ACTIVE })
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <SidePanel
            id={dialogId}
            ref={dialogRef}
            formId={RSM3274_CONTROL_WITH_EXCEL_FIELDS}
            title={t('controlWithExcelFields:control_with_excel_fields')}
            footerLbl={t("save")}
            footerAction={_saveAction}
            withFooter={isAllowedAction(user, RSM3274_CONTROL_WITH_EXCEL_FIELDS, SAVE_ACTION)}
            postClose={_postClose}
            nextPreviousAction={_populateRecordData}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label className='form-label'>{t('controlWithExcelFields:english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-english-name`}
                            value={excelField.labelLo}
                            onBlur={(value) => _handleDataChanged("labelLo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('controlWithExcelFields:arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-arabic-name`}
                            value={excelField.labelFo}
                            onBlur={(value) => _handleDataChanged("labelFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('controlWithExcelFields:sequance')}</label>
                        <FrmInput
                            id={`${dialogId}-sequence`}
                            value={excelField.excelSeq}
                            type="number"
                            onKeyDown={handleNumbersKeyDown}
                            onBlur={(value) => _handleDataChanged("excelSeq", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id={`${dialogId}-status-lbl`} className='form-label mandatory'> {t('controlWithExcelFields:status')}</label>
                        <RadioOptions
                            id={`${dialogId}-status`}
                            sidePanelMode
                            options={[
                                {
                                    title: t('active'),
                                    action: () => _handleDataChanged("excelStatus", STATUS_ACTIVE),
                                    selected: excelField.excelStatus == STATUS_ACTIVE
                                },
                                {
                                    title: t('inactive'),
                                    action: () => _handleDataChanged("excelStatus", STATUS_IN_ACTIVE),
                                    selected: excelField.excelStatus == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                </div>
            </div>
        </SidePanel>
    )
}

export default forwardRef(ControlWithExcelFieldsDialog);