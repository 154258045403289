import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { useSelector } from 'react-redux';
import HijriDate from '../../../../components/hijri-date';
import { DATA_TYPE_DATE_TIME, DATA_TYPE_HIJRI_DATE, DATA_TYPE_TIME } from '../../../../util/constants';
import DetailsCard from "./Card";
import style from './style.module.scss';

const DatePicker = (props) => {
    const { feild, handleChange, inCard, inTable } = props;
    const value = useSelector(state => state.estTrnxReducer.data[feild.internalName]);

    return (
        <DetailsCard feild={feild} inCard={inCard} inTable={inTable}>
            <div className={style.dateTimeContainer} >
                {feild.dataType != DATA_TYPE_HIJRI_DATE ?
                    <FrmTimeDatePickr
                        id={`${feild.id}_id`}
                        value={value}
                        onChange={value => handleChange(feild.internalName, value)}
                        enableTime={feild.dataType == DATA_TYPE_DATE_TIME || feild.dataType == DATA_TYPE_TIME}
                        disableDate={feild.dataType == DATA_TYPE_TIME}
                        disabled={!feild.enabled}
                    />
                    :
                    <HijriDate
                        id={`${feild.id}_id`}
                        hijriDate={value}
                        onChange={value => handleChange(feild.internalName, value)}
                        disabled={!feild.enabled}
                    />
                }
            </div>
        </DetailsCard>
    )
}

export default DatePicker;