import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getDomainValuesWithCondition } from "../../../util/apis";
import { PROPORTIES_PARTIES_TYPE, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR, PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_INSPECTOR, STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID } from "../../../util/constants/forms";

const dialogId = "properties-parties-categories-dialog";

const Tab1 = (props) => {
    const { tab, data, handleDataChanged } = props;

    const { t } = useTranslation('propertiesPartiesCategories');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <CodeInput
                            id={`${dialogId}-tab-1-code`}
                            value={data.code}
                            onBlur={value => handleDataChanged("code", value)}
                            column="ARA_CODE"
                            formId={RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id={`${dialogId}-tab-1-status`}
                            options={[
                                {
                                    id: STATUS_ACTIVE,
                                    title: t('active'),
                                    selected: data?.isActive == STATUS_ACTIVE,
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE),
                                },
                                {
                                    id: STATUS_IN_ACTIVE,
                                    title: t('inactive'),
                                    selected: data?.isActive == STATUS_IN_ACTIVE,
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                },
                            ]} />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-tab-1-english-name-lbl`} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-english-name`}
                            value={data?.descLo}
                            onBlur={value => handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-tab-1-arabic-name-lbl`} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-arabic-name`}
                            value={data?.descFo}
                            onBlur={value => handleDataChanged("descFo", value)}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${dialogId}-tab-1-type-lbl`} className='form-label mandatory'>{t('type')}</label>
                        <FrmSelectMenu
                            id={`${dialogId}-tab-1-type`}
                            value={data.type}
                            api={() => getDomainValuesWithCondition(PROPORTIES_PARTIES_TYPE, true, [PROPORTIES_PARTIES_TYPE_INSPECTOR, PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR])}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("type", value.value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id={`${dialogId}-tab-1-remarks`}
                            value={data?.rem}
                            onBlur={value => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tab1;