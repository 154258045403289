import React from "react";

function NumberDelayedTasksIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.501"
      height="21.514"
      viewBox="0 0 19.501 21.514"
    >
      <g data-name="Group 179938" transform="translate(4737 10938)">
        <g data-name="Group 179936" transform="translate(-4737 -10938)">
          <g fill="#292d32" data-name="Group 179937">
            <path
              d="M11.75 5.514h-4a3.08 3.08 0 01-1.791-.376A2.625 2.625 0 015 2.764 2.625 2.625 0 015.959.39 3.08 3.08 0 017.75.014h4a3.08 3.08 0 011.791.376 2.625 2.625 0 01.959 2.374 3.08 3.08 0 01-.376 1.791 2.625 2.625 0 01-2.374.959m-4-4a2.1 2.1 0 00-.959.124c-.191.126-.291.526-.291 1.126s.1 1 .291 1.126a2.1 2.1 0 00.959.124h4c.6 0 1-.1 1.126-.291A2.1 2.1 0 0013 2.764c0-.6-.1-1-.291-1.126a2.1 2.1 0 00-.959-.124z"
              data-name="Path 44848"
            ></path>
            <path
              d="M12.75 21.514h-6c-2.663 0-4.391-.558-5.439-1.756C.156 18.438 0 16.493 0 14.764v-6c0-2.437.461-4.07 1.45-5.141.913-.988 2.227-1.478 4.26-1.588a.75.75 0 01.081 1.5A4.5 4.5 0 002.55 4.64c-.706.767-1.05 2.116-1.05 4.124v6c0 2.055.281 3.254.939 4.006.743.849 2.111 1.244 4.311 1.244h6c2.2 0 3.568-.4 4.311-1.244.658-.753.939-1.951.939-4.006v-6c0-2.012-.344-3.362-1.052-4.127a4.508 4.508 0 00-3.238-1.1.75.75 0 11.081-1.5c2.034.11 3.348.6 4.259 1.583.99 1.07 1.451 2.7 1.451 5.146v6c0 1.729-.156 3.674-1.311 4.994-1.049 1.2-2.777 1.755-5.44 1.755"
              data-name="Path 44849"
              opacity="0.51"
            ></path>
            <path
              d="M9.745 14.169a.847.847 0 00.847-.847V9.056a.847.847 0 10-1.695 0v4.271a.847.847 0 00.847.842"
              data-name="Path 44850"
            ></path>
            <path
              d="M9.754 16.958a1.067 1.067 0 100-2.133h-.007a1.067 1.067 0 000 2.133z"
              data-name="Path 44851"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NumberDelayedTasksIcon;