import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modal";
import { getUserAuditLogs } from "../../../../util/apis";
import ActionDetails from "./ActionDetails";
import LogDate from "./LogDateTable";
import style from './style.module.scss';
import LogeDateIcon from "../../../../icons/LogeDate";
import Waiting from "../../../../components/waiting";

const LogDateDialog = (props, ref) => {

    const { user, formId } = props;

    const { t } = useTranslation();
    const dialogRef = useRef();
    const detailsTableRef = useRef();

    const [logs, setLogs] = useState([]);

    const _postClose = () => {
        detailsTableRef?.current?.changeSelectedIndex(-1)
        setLogs([]);
    }

    const _populateRecordData = (id) => {
        document.getElementById('waiting-log-date').style.display = "flex";
        document.getElementById('log-date-container').style.display = "none";
        getUserAuditLogs(formId, id)
            .then((response) => {
                setLogs(response.data || [])
                document.getElementById('waiting-log-date').style.display = "none";
                document.getElementById('log-date-container').style.display = "block"
            })
            .catch(() => { })
            .finally(
        );
    }

    const open = (id) => {
        _populateRecordData(id);
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({
        open,
        close
    }))

    return (
        <Modal
            id="log-dialog"
            ref={dialogRef}
            title=
            {
                <>
                    <LogeDateIcon />
                    {t('log_date')}
                </>
            }
            withFooter="true"
            withCancelButton="true"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={`${style.containerDailog}`}
            postClose={_postClose}
        >
            <Waiting id="waiting-log-date" className={style.hideWaitting} />
            <div id="log-date-container" className={style.container}>
                <LogDate user={user} formId={formId} logs={logs} detailsTableRef={detailsTableRef} />
                <ActionDetails user={user} formId={formId} logs={logs} detailsTableRef={detailsTableRef} />
            </div>
        </Modal>
    )
}

export default forwardRef(LogDateDialog);