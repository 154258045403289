import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isEnglish } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiInsertDialog from "../../../components/multi-insert-dialog";
import Table from "../../../components/table";
import { getDomainValues, getEstimatorInvoiceParties, getPartiesDefinitionCategories } from "../../../util/apis";
import { DOMAIN_PROPORTIES_PARTIES_TYPE, PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR, PROPORTIES_PARTIES_TYPE_ESTIMATOR, PROPORTIES_PARTIES_TYPE_INSPECTOR, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { ACTION_ADD_PARTIES, ACTION_DELETE_ALL_PARTIES, ACTION_DELETE_PARTY } from "./reducer";
import style from './style.module.scss';

const HeaderLines = (props) => {

    const { invoice, dispatchInvoice } = props;
    const { t } = useTranslation();
    const insertItemDialog = useRef(null);

    const _insertParty = (selectedData) => {
        let rows = [];
        selectedData.map((data, index) => {
            let row = {};
            row.aprId = data.id;
            row.seq = index + 1;
            row.party = {};
            row.party.code = data.code
            row.party.nameLo = data.nameLo
            row.party.nameFo = data.nameFo
            row.party.descLo = data.descLo
            row.party.descFo = data.descFo
            rows.push(row);
        })
        dispatchInvoice({ value: rows, type: ACTION_ADD_PARTIES })
        insertItemDialog.current.close();
    }

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => dispatchInvoice({ type: ACTION_DELETE_ALL_PARTIES }));
    }

    return (
        <>
            <div className={style.headerLines}>
                <div className={style.containerBtn}>
                    <button
                        className="secondary-button"
                        onClick={() => insertItemDialog.current.open()}
                    >
                        {t('estimatorsInspectorsInvoices:insert')}
                    </button>
                    <button
                        className="delete-button"
                        onClick={_deleteAllAction}
                        disabled={invoice?.parties?.length == 0}
                    >
                        {t('estimatorsInspectorsInvoices:delete_all')}
                    </button>
                </div>
            </div>
            <MultiInsertDialog
                id='parties_insert_item'
                ref={insertItemDialog}
                title={t('estimatorsInspectorsInvoices:insert_item')}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                okBtnAction={_insertParty}
                selectedIds={invoice?.parties.map((line) => line.aprId)}
                api={(searchKey) => getEstimatorInvoiceParties(searchKey, invoice?.header?.fogId)}
                searchPlaceholder={t('estimatorsInspectorsInvoices:party_code') + " , " + t('estimatorsInspectorsInvoices:party')}
                AdvanceFilters={AdvanceFiltersPanel}
                columns={[
                    { width: '5%' },
                    { label: '#', width: '5%' },
                    { label: t('estimatorsInspectorsInvoices:party_code'), value: 'code', width: '25%' },
                    { label: t('estimatorsInspectorsInvoices:party'), valueLo: 'nameLo', valueFo: 'nameFo', width: '65%' },
                ]}
                isSmallSize
            />
        </>
    )
}

const InvoicesListRow = (props) => {

    const { data, rowActions, virtualizedStyle, index } = props;
    const { handelDeleteLine } = rowActions;

    return (
        <div dir={isEnglish() ? "ltr" : "rtl"} className={`v_table_row ${style.invoicePartiesRow}`} style={virtualizedStyle}>
            <div><div><label>{data.sequence}</label></div></div>
            <div><div><label>{data?.party?.code}</label></div></div>
            <div><div><label>{isEnglish() ? data?.party?.nameLo : data?.party?.nameFo}</label></div></div>
            <div><div><DeleteBtn onClick={() => handelDeleteLine(index)} /></div></div>
        </div>
    );
};

const InvoicesList = (props) => {
    const { invoice, dispatchInvoice, user } = props;
    const { t } = useTranslation("estimatorsInspectorsInvoices");

    const columns = [
        { title: '#', width: "4%" },
        { title: t('party_code'), width: "43%" },
        { title: t('party'), width: "43%" },
        { title: " ", width: "10%" }
    ];

    const _handelDeleteLine = (index) => {
        dispatchInvoice({ index: index, type: ACTION_DELETE_PARTY })
    }

    return (
        <Table
            id="invoices-parties-list-table"
            columns={columns}
            Row={InvoicesListRow}
            tableStyle={style.invoicePartiesTable}
            rowHeight={45}
            rowActions={{ handelDeleteLine: _handelDeleteLine }}
            list={invoice.parties}
            user={user}
            formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
        />
    );
}

const AdvanceFiltersPanel = forwardRef((props, ref) => {

    const { t } = useTranslation("estimatorsInspectorsInvoices");
    const [filterData, setFilterData] = useState({});
    const [partyType, setPartyType] = useState([]);

    useEffect(() => {
        getDomainValues(DOMAIN_PROPORTIES_PARTIES_TYPE)
            .then((response) => {
                setPartyType(response.data.filter((partyType) => partyType.id == PROPORTIES_PARTIES_TYPE_INSPECTOR || partyType.id == PROPORTIES_PARTIES_TYPE_ESTIMATOR || partyType.id == PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR));
            })
            .catch((error) => handleError(error, null, t));
    }, [])

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return filterData;
    }

    const clearFilterData = () => {
        setFilterData({})
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        clearFilterData
    }))

    return (
        <div className={`container ${style.advanceFiltersPanelContainer}`}>
            <div className="row">
                <div className="w50">
                    <label className='form-label'>{t('party_type')}</label>
                    <FrmSelectMenu
                        id="party_type"
                        isMulti={true}
                        value={filterData.partyType}
                        options={partyType || []}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => _handleDataChanged("partyType", value)}
                        portalTarget={document.body}
                    />
                </div>
                <div className="w50">
                    <label className='form-label'>{t('category')}</label>
                    <FrmSelectMenu
                        id="category"
                        isMulti={true}
                        value={filterData.groups}
                        api={() => getPartiesDefinitionCategories(STATUS_ACTIVE, true, [], false, null, filterData.groups)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => _handleDataChanged("groups", value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
        </div>
    )
})

const PartyList = (props) => {
    const { user, invoice, dispatchInvoice } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('estimatorsInspectorsInvoices:party_list')}
                    </label>
                </div>
            </div>
            <HeaderLines
                invoice={invoice}
                dispatchInvoice={dispatchInvoice}
            />

            <InvoicesList
                invoice={invoice}
                dispatchInvoice={dispatchInvoice}
                user={user}
            />
        </div>
    );
}

export default PartyList;