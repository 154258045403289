import React from "react";

function AllIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="23"
      data-name="Group 176500"
      viewBox="0 0 29 23"
    >
      <circle
        cx="2"
        cy="2"
        r="2"
        fill="#155eda"
        data-name="Ellipse 109"
        transform="translate(13 13)"
      ></circle>
      <circle
        cx="2"
        cy="2"
        r="2"
        fill="#155eda"
        data-name="Ellipse 110"
        transform="translate(6 13)"
      ></circle>
      <rect
        width="27"
        height="7"
        fill="#155eda"
        data-name="Rectangle 161830"
        opacity="0.07"
        rx="3.5"
        transform="translate(1 1)"
      ></rect>
      <path
        fill="#155eda"
        d="M24 2a3 3 0 013 3v13a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h19m0-2H5a5 5 0 00-5 5v13a5 5 0 005 5h19a5 5 0 005-5V5a5 5 0 00-5-5z"
        data-name="Rectangle 161829 - Outline"
      ></path>
      <path
        fill="#155eda"
        d="M21.5 17.5a1 1 0 001-1v-10a1 1 0 00-1-1 1 1 0 00-1 1v10a1 1 0 001 1z"
        data-name="Line 416"
      ></path>
      <circle
        cx="2"
        cy="2"
        r="2"
        fill="#155eda"
        data-name="Ellipse 108"
        transform="translate(13 6)"
      ></circle>
      <circle
        cx="2"
        cy="2"
        r="2"
        fill="#155eda"
        data-name="Ellipse 111"
        transform="translate(6 6)"
      ></circle>
    </svg>
  );
}

export default AllIcon;