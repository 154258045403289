import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import EstimationMessagesTemplatesDialog from "./dialogs";
import EstimationMessagesTemplatesFilter from "./listing/Filters";
import EstimationMessagesTemplatesTable from "./listing/index";

const EstimationMessagesTemplates = () => {

    const { t } = useTranslation("estimationMessagesTemplates");

    const searchTextRef = useRef();
    const filterRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <EstimationMessagesTemplatesTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
                dialogRef={dialogRef}
            />

            <EstimationMessagesTemplatesFilter ref={filterRef} tableRef={tableRef} />
            <EstimationMessagesTemplatesDialog ref={dialogRef} tableRef={tableRef} user={user} />
        </>
    )
}
export default EstimationMessagesTemplates;