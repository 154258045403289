import { DateFromToInput } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { handleError, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getCategories, getCommonBranches, getCommonCustomers, getCommonDivisionsList, getCommonPartiesLov, getDomainValues, getEstimationDomains, getFinancialLocationsList, getPartiesDefinitionCategories } from "../../../util/apis";
import { DOMAIN_GLOBAL_STATUS, DOMAIN_PAYING_STATUS_SOURCES, DOMAIN_SOURCE, GLOBAL_STATUS_APPROVED, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID } from "../../../util/constants/forms";

const filterId = "estimation-invoices-follow-up-filter";
const CATEGORY_CONDITION = "dependsOnUserOrg";
const BRANCH_CONDITION = 'dependsOnPolicyId';

const EstimationInvoicesFollowUpFilter = (props, ref) => {
    const { user, tableRef, policyFilterRef } = props;

    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});
    const [globalStatus, setGlobalStatus] = useState([]);

    useEffect(() => {
        getDomainValues(DOMAIN_GLOBAL_STATUS)
            .then((response) => setGlobalStatus(response.data.filter(status => [GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_SUBMITTED, GLOBAL_STATUS_APPROVED, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_CONFIRMED].includes(status.id))))
            .catch((error) => handleError(error, null, t));
    }, [user?.fogId]);

    const _getDefaultFilter = () => {
        const defaultFilter = {
            status: [GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_SUBMITTED, GLOBAL_STATUS_APPROVED, GLOBAL_STATUS_CONFIRMED],
        }
        if (isNotEmpty(user.estimationInvoiceLsoId)) {
            defaultFilter.policy = user.estimationInvoiceLsoId;
        }
        return defaultFilter;
    }

    const handleDataChange = (name, value) => {
        if (name === "policy") {
            filterData.policy = value.value;
            filterData.branchs = [];
            filterData.divisions = [];
        } else {
            filterData[name] = value;
        }
        setFilterData({ ...filterData });
        if (name === "policy") {
            tableRef?.current?.refresh();
        }
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open, handleDataChange }));

    const DIVISION_CONDITION = 'dependsOnPolicyIdOnly';
    return (
        <AdvanceFilter
            id={filterId}
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            getDefaultFilter={_getDefaultFilter}
            numOfRows={4}
            formId={RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID}
            postDefaultFilterRetrieved={(data) => policyFilterRef.current.setPolicyId(data.policy)}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row advanceFilterContainer-row">
                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoices:source')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-source`}
                                isMulti={true}
                                value={filterData.source}
                                api={() => getDomainValues(DOMAIN_SOURCE)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("source", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoices:status')}</label>
                            <StatusSelectMenu
                                id={`${filterId}-status`}
                                isMulti={true}
                                value={filterData.status}
                                options={globalStatus || []}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("status", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w25`}>
                            <label className='form-label'>{t('estimationInvoices:invoice_code')}</label>
                            <FrmInput
                                id={`${filterId}-invoice-code`}
                                value={filterData.invoiceCode}
                                onBlur={(value) => handleDataChange("invoiceCode", value)}
                            />
                        </div>
                        <div className={`w25`}>
                            <label className='form-label'>{t('estimationInvoices:invoice_ref_code')}</label>
                            <FrmInput
                                id={`${filterId}-invoice-ref-code`}
                                value={filterData.refCode}
                                onBlur={(value) => handleDataChange("refCode", value)}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationInvoices:invoice_date_from_to_g')}</label>
                            <DateFromToInput
                                id={`${filterId}-invoice-date-from-to-g`}
                                fromValue={filterData?.invoiceDateFromDGR}
                                toValue={filterData?.invoiceDateToDGR}
                                fromOnChange={(value) => handleDataChange("invoiceDateFromDGR", value)}
                                toOnChange={(value) => handleDataChange("invoiceDateToDGR", value)}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationInvoices:customer_category')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-customer-category`}
                                isMulti={true}
                                value={filterData.customerCategory}
                                api={() => getCategories(null, CATEGORY_CONDITION, false)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("customerCategory", value)}

                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationInvoices:customer')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-customer`}
                                value={filterData.customers}
                                onChange={value => handleDataChange("customers", value)}
                                defualtValueId={filterData.customers}
                                defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, [], false)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], false)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationInvoices:party_category')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-party-category`}
                                isMulti={true}
                                value={filterData.partyCategory}
                                api={() => getPartiesDefinitionCategories(null, null, null, true, null)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChange("partyCategory", value)}

                                portalTarget={document.body}
                            />
                        </div>
                        <div className={`w50`}>
                            <label className='form-label'>{t('estimationInvoices:party')}</label>
                            <FrmSelectMenu
                                id={`${filterId}-party`}
                                isMulti={true}
                                value={filterData.parties}
                                api={() => getCommonPartiesLov(null, null, null, null, filterData.parties, null, null, 1)}
                                descLo={["nameLo"]}
                                descFo={["nameFo"]}
                                onChange={value => handleDataChange("parties", value)}

                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('more_options')}</label>
                </div>
                <div className="row">
                    <div className={`w50`}>
                        <label className='form-label'>{t('estimationInvoices:branch')}</label>
                        <FrmSelectMenu
                            id={`${filterId}-branch`}
                            key={`${filterId}-branch-${filterData.policy}`}
                            isMulti={true}
                            value={filterData.branchs}
                            api={() => getCommonBranches(null, filterData.branchs, null, BRANCH_CONDITION, false, filterData.policy || "-1")}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChange("branchs", value)}
                            isDisabled={isEmpty(filterData.policy)}

                            portalTarget={document.body}
                        />
                    </div>

                    <div className={`w50`}>
                        <label className='form-label'>{t('estimationInvoices:division')}</label>
                        <FrmSelectMenu
                            id={`${filterId}-division`}
                            key={`${filterId}-division-${filterData.policy}`}
                            isMulti={true}
                            value={filterData.divisions}
                            api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false, null, filterData.policy || "-1")}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChange("divisions", value)}
                            isDisabled={isEmpty(filterData.policy)}

                            portalTarget={document.body}
                        />
                    </div>

                    <div className={`w50`}>
                        <label className='form-label'>{t('estimationInvoices:location')}</label>
                        <FrmSelectMenu
                            id={`${filterId}-location`}
                            isMulti={true}
                            value={filterData.locations}
                            api={() => getFinancialLocationsList(null, null, filterData.locations)}
                            descLo={['code', 'nameLo']}
                            descFo={['code', 'nameFo']}
                            onChange={value => handleDataChange("locations", value)}

                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationInvoices:paying_status')}</label>
                        <FrmSelectMenu
                            id={`${filterId}-paying-status`}
                            isMulti={true}
                            value={filterData.payingStatus}
                            api={() => getDomainValues(DOMAIN_PAYING_STATUS_SOURCES)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChange("payingStatus", value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationInvoices:transaction_status')}</label>
                        <FrmSelectMenu
                            id={`${filterId}-transaction-status`}
                            isMulti={true}
                            value={filterData.transactionStatus}
                            api={() => getEstimationDomains("1048")}
                            descLo={["descLo"]}
                            descFo={["descFo"]}
                            onChange={value => handleDataChange("transactionStatus", value)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(EstimationInvoicesFollowUpFilter);