import CommonCheckbox from "@ipgd-gauge/checkbox";
import { isEnglish } from '@ipgd-gauge/utils';
import { useSelector } from 'react-redux';

const Checkbox = (props) => {
    const { feild, handleChange } = props;

    const value = useSelector(state => state.estTrnxReducer.data[feild.internalName]);

    return (
        <CommonCheckbox
            id={feild.id}
            label={isEnglish() ? feild?.userDescLo : feild?.userDescFo}
            isChecked={Number(value) == 1}
            disabled={!feild.enabled}
            setIsChecked={(isChecked) => handleChange(feild.internalName, isChecked ? 1 : 0)}
        />
    )
}

export default Checkbox;