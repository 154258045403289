import React from "react";

function NoteIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g data-name="Group 176540" transform="translate(-13.25 -291.5)">
                <g data-name="Group 176537" transform="translate(0 -10)">
                    <g
                        fill="#668fff"
                        data-name="Group 176536"
                        transform="translate(-736 -15.75)"
                    >
                        <path
                            d="M14.75 21.5h-13A1.758 1.758 0 010 19.75v-13C0 2.33 2.33 0 6.75 0h8c4.42 0 6.75 2.33 6.75 6.75v8c0 4.42-2.33 6.75-6.75 6.75zm-8-20C3.17 1.5 1.5 3.17 1.5 6.75v13a.248.248 0 00.25.25h13c3.58 0 5.25-1.67 5.25-5.25v-8c0-3.58-1.67-5.25-5.25-5.25z"
                            transform="translate(749.25 317.25)"
                        ></path>
                        <path
                            d="M10.75 1.5h-10A.755.755 0 010 .75.755.755 0 01.75 0h10a.755.755 0 01.75.75.755.755 0 01-.75.75z"
                            data-name="Vector"
                            transform="translate(754.25 324.75)"
                        ></path>
                        <path
                            d="M7.75 1.5h-7A.755.755 0 010 .75.755.755 0 01.75 0h7a.755.755 0 01.75.75.755.755 0 01-.75.75z"
                            data-name="Vector"
                            transform="translate(754.25 329.75)"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default NoteIcon;
