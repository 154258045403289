import React from "react";

function LogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.185"
      height="26.913"
      viewBox="0 0 20.185 26.913"
    >
      <path
        fill="#80b341"
        d="M21.542 3.5l-1.857-1.858A5.571 5.571 0 0015.72 0H8.607A5.614 5.614 0 003 5.607v15.7a5.614 5.614 0 005.607 5.607h8.971a5.614 5.614 0 005.607-5.607V7.465A5.571 5.571 0 0021.542 3.5zm-1.586 1.587a3.288 3.288 0 01.425.52h-2.8V2.8a3.476 3.476 0 01.521.426zm.986 16.219a3.364 3.364 0 01-3.364 3.364H8.607a3.364 3.364 0 01-3.364-3.364V5.607a3.364 3.364 0 013.364-3.364h6.728v3.364a2.243 2.243 0 002.243 2.243h3.364zm-3.364-11.214a1.121 1.121 0 110 2.243H8.607a1.121 1.121 0 110-2.243zM18.7 15.7a1.121 1.121 0 01-1.121 1.121H8.607a1.121 1.121 0 110-2.243h8.971A1.121 1.121 0 0118.7 15.7z"
        transform="translate(-3)"
      ></path>
    </svg>
  );
}

export default LogIcon;
