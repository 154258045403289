import React from "react";

function AttentionIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.223"
      height="24.668"
      data-name="Group 175740"
      viewBox="0 0 28.223 24.668"
    >
      <g  fill={props.color || "#b8bfc9"} data-name="Group 175739">
        <path
          d="M15.286.678A1.346 1.346 0 0014.119 0H14.1a1.345 1.345 0 00-1.168.675L.184 22.624a1.361 1.361 0 001.177 2.044h25.5a1.361 1.361 0 001.178-2.044zM2.224 22.812l11.89-20.46L26 22.812z"
          data-name="Path 6466"
        ></path>
        <path
          d="M14.355 17.385a.813.813 0 00.808.751.811.811 0 00.806-.753l.465-6.613a1.26 1.26 0 00-1.283-1.279h-.092a1.284 1.284 0 00-1.187 1.375z"
          data-name="Path 6467"
          transform="translate(-1.04 -.712)"
        ></path>
        <path
          d="M14.78 20.6a.607.607 0 101.035.429v-.062a.608.608 0 00-.6-.545.6.6 0 00-.43.178"
          data-name="Path 6468"
          transform="translate(-1.095 -1.532)"
        ></path>
      </g>
    </svg>
  );
}

export default AttentionIcon;