import React from "react";

function FullRatingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.641"
      height="18.823"
      viewBox="0 0 17.641 16.823"
    >
      <path
        fill="#fba422"
        fillRule="evenodd"
        d="M9.9 3.1a.948.948 0 011.809 0l1.63 5.02h5.268a.957.957 0 01.562 1.756l-4.276 3.1 1.633 5.031a.948.948 0 01-1.466 1.062l-4.285-3.134L6.5 19.042a.948.948 0 01-1.467-1.062l1.633-5.031L2.39 9.85a.957.957 0 01.562-1.756H8.22z"
        data-name="Path 38"
        transform="translate(-1.959 -2.435)"
      ></path>
    </svg>
  );
}

export default FullRatingIcon;