import React from "react";

function MinusPreviewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.375"
      height="3.938"
      viewBox="0 0 18.375 3.938"
    >
      <path
        fill="#797979"
        d="M20.7 188.546a1.266 1.266 0 01.39.923v1.312a1.332 1.332 0 01-1.313 1.313H4.031a1.261 1.261 0 01-.923-.39 1.258 1.258 0 01-.389-.923v-1.312a1.33 1.33 0 011.312-1.313h15.75a1.262 1.262 0 01.923.39"
        data-name="Path 44845"
        transform="translate(-2.719 -188.156)"
      ></path>
    </svg>
  );
}

export default MinusPreviewIcon;