import { parseNumber, roundByDigits } from "../../../../util/util";

export const ACTION_CREATE_NEW_CREADIT_NOTE = '1';
export const ACTION_SET_CREADIT_NOTE_VALUE = '2';
export const ACTION_SET_CREADIT_NOTE = '3';
export const ACTION_SET_HEADER_VALUE = '4';
export const ACTION_DELETE_ALL_LINES = '5';
export const ACTION_DELETE_LINE = '6';
export const ACTION_ADD_LINES = '7';
export const ACTION_COUNT_TOTALS = '8';

const _calculateLine = (line, action, exchngRate) => {
    if (action == "invoiceAmountAfc") {
        line.invoiceAmountAlc = roundByDigits(parseNumber(line.invoiceAmountAfc) * parseNumber(exchngRate || 1), 6)
    }
    if (action == "discountAfc") {
        line.discountAlc = roundByDigits(parseNumber(line.discountAfc) * (exchngRate || 1), 6)
    }
    if (action == "vatPerc" || action == "invoiceAmountAfc" || action == "invoiceAmountAlc" || !line.vatAmountAlc || action == "discountAlc" || action == "discountAfc") {
        line.vatAmountAlc = roundByDigits(((parseNumber(line.invoiceAmountAlc) - parseNumber(line.discountAlc)) * parseNumber(line.vatPerc)) / 100.0, 6)
        line.vatAmountAfc = roundByDigits(((parseNumber(line.invoiceAmountAfc) - parseNumber(line.discountAfc)) * parseNumber(line.vatPerc)) / 100.0, 6)
    }
    line.netAlc = roundByDigits(parseNumber(line.invoiceAmountAlc) - parseNumber(line.discountAlc) + parseNumber(line.vatAmountAlc), 6)
    line.netAfc = roundByDigits(parseNumber(line.invoiceAmountAfc) - parseNumber(line.discountAfc) + parseNumber(line.vatAmountAfc), 6)
    return { ...line }
}

const calculateTotals = (lines) => {
    const totals = {
        count: lines.length,
        totalPriceAlc: 0,
        totalDiscountAlc: 0,
        totalVatAlc: 0,
        totalNetPriceAlc: 0,
        totalPriceAfc: 0,
        totalDiscountAfc: 0,
        totalVatAfc: 0,
        totalNetPriceAfc: 0
    }

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        totals.totalPriceAlc += parseNumber(line.invoiceAmountAlc);
        totals.totalPriceAfc += parseNumber(line.invoiceAmountAfc);
        totals.totalDiscountAlc += parseNumber(line.discountAlc);
        totals.totalDiscountAfc += parseNumber(line.discountAfc);
        totals.totalVatAlc += parseNumber(line.vatAmountAlc);
        totals.totalVatAfc += parseNumber(line.vatAmountAfc);
        totals.totalNetPriceAlc += parseNumber(line.netAlc);
        totals.totalNetPriceAfc += parseNumber(line.netAfc);
    }
    return (totals);
}

const creditNoteReducer = (state, action) => {
    switch (action.type) {
        case ACTION_CREATE_NEW_CREADIT_NOTE: {
            return {
                ...state,
                ...action.value,
            }
        };

        case ACTION_SET_CREADIT_NOTE_VALUE: {
            return {
                ...state,
                creditNote: { ...state.creditNote, [action.name]: action.value }
            }
        };

        case ACTION_SET_CREADIT_NOTE: {
            const customerData = state?.creditNote?.header?.customerData || {};
            action.value.header.customerData = customerData;
            const invoiceData = state?.creditNote?.header?.invoiceData || {};
            action.value.header.invoiceData = invoiceData;
            return {
                ...state,
                creditNote: { ...action.value }
            }
        };

        case ACTION_SET_HEADER_VALUE: {
            state.creditNote.header[action.name] = action.value
            if (action.name == 'dateDgr') {
                state.creditNote.lines = [];
            } else if (action.name == 'atrId') {
                state.creditNote.header.atrId = action.value.value;
                state.creditNote.header.customerData = action.value;
                state.creditNote.header.isnId = null;
                state.creditNote.lines = [];
            } else if (action.name == 'isnId') {
                state.creditNote.header.invoiceData = action.value;
                state.creditNote.header.isnId = action.value.value;
                state.creditNote.lines = [];
            } else if (action.name === "mbnId") {
                state.creditNote.header.dsvId = null;
            }

            return {
                ...state,
                creditNote: { ...state.creditNote, header: { ...state.creditNote.header } }
            }
        };

        case ACTION_DELETE_ALL_LINES: {
            const total = calculateTotals([])
            return {
                ...state,
                creditNote: { ...state.creditNote, lines: [] },
                totals: total
            }
        };

        case ACTION_DELETE_LINE: {
            let newLines = state.creditNote.lines.filter((line, index) => index != action.index)
            const total = calculateTotals(newLines)
            return {
                ...state,
                creditNote: { ...state.creditNote, lines: newLines },
                totals: total

            }
        };

        case ACTION_ADD_LINES: {
            let lines = action.value;
            lines?.map((line) => {
                line = _calculateLine(line);
            })
            let newLines = [...state.creditNote.lines, ...lines]

            const total = calculateTotals(newLines)
            return {
                ...state,
                creditNote: { ...state.creditNote, lines: newLines },
                totals: total
            }
        };

        case ACTION_COUNT_TOTALS: {
            const total = calculateTotals(state?.creditNote?.lines)
            return {
                ...state,
                totals: total,
            }
        };

        default:
            return state;
    }
}

export default creditNoteReducer;