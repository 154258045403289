import { DeleteBtn, EditBtn } from '@ipgd-gauge/table';
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteCity, getCities } from '../../../util/apis';
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { FND2319_CITIES_DEFINITION_FORM_ID } from '../../../util/constants/forms';
import style from './style.module.scss';

const Row = (props) => {
    const { t } = useTranslation();
    const { data, rowActions } = props;
    const { user, citiesDialogRef, deleteCity, logDialogRef } = rowActions;

    return (
        <div className={`${style.citiesTableRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('cities:code')}</label>
                <label>{data.code}</label>
            </div>
            <div title={isEnglish() ? data.descLo : data.descFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('cities:name')}</label>
                <label className="cardRowDesc">{isEnglish() ? data.descLo : data.descFo}</label>
            </div>
            <div title={isEnglish() ? data.countryNameLo : data.countryNameFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('cities:country')}</label>
                <label>{isEnglish() ? data.countryNameLo : data.countryNameFo}</label>
            </div>
            <div title={isEnglish() ? data.regionNameLo : data.regionNameFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('cities:region')}</label>
                <label>{isEnglish() ? data.regionNameLo : data.regionNameFo}</label>
            </div>
            <div title={t(`status_${data.isActive}`)} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('cities:status')}</label>
                <label className={`status-${data.isActive}`}>{t(`status_${data.isActive}`)}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, FND2319_CITIES_DEFINITION_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => citiesDialogRef?.current?.open(data.id)} />}
                    {isAllowedAction(user, FND2319_CITIES_DEFINITION_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteCity(data.id)} />}

                    {/* <button onClick={() => logDialogRef.current.open(data.id)}>
                        showLog
                    </button> */}
                </div>
            </div>
        </div>
    );
};

const CitiesTable = (props) => {

    const { user, citiesTableRef, searchTextRef, filtersRef, citiesDialogRef, logDialogRef } = props;
    const { t } = useTranslation();
    const [cities, setCities] = useState();

    const _deleteCity = (cityId) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteCity(cityId)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    citiesTableRef?.current?.refresh();
                })
                .catch((error) => {
                    handleError(error, "delete_fields", t);
                })
        });
    }

    const _fetchData = (pageNumber, pageSize) => {
        const filters = {
            searchValue: searchTextRef.current.getSearchText(),
            ...filtersRef.current?.getFilterData()
        }
        getCities(pageNumber, pageSize, filters)
            .then((response) => {
                setCities(response.data);
            })
            .catch((error) => {
                handleError(null, null, t)
            })
    }

    const columns = [
        { title: '#', width: "3%" },
        { title: t('cities:code'), width: "12%" },
        { title: t('cities:name'), width: "19%" },
        { title: t('cities:country'), width: "19%" },
        { title: t('cities:region'), width: "19%" },
        { title: t('cities:status'), width: "11%" },
        { title: "", width: "17%" },
    ];

    return (
        <Slisting
            id="cities"
            ref={citiesTableRef}
            withFilter={true}
            columns={columns}
            Row={Row}
            tableStyle={style.citiesTable}
            rowHeight={80}
            pagingData={cities}
            getData={_fetchData}
            rowActions={{ user, citiesDialogRef, deleteCity: _deleteCity, logDialogRef }}
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            newAction={() => citiesDialogRef.current?.open()}
            deleteAction={deleteCity}
            viewAction={(id) => citiesDialogRef.current?.open(id)}
            formId={FND2319_CITIES_DEFINITION_FORM_ID}
            cardInMobile
            labelCardWidth="80px"
        />
    );
};

export default CitiesTable;