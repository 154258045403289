import Tabs from "@ipgd-gauge/tabs";
import { checkValidation, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../components/side-panel";
import CitiesIcon from "../../../icons/Cities";
import { getCity, saveCity } from "../../../util/apis";
import { LIMIT_LAT, LIMIT_LNG, STATUS_ACTIVE } from "../../../util/constants";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { FND2319_CITIES_DEFINITION_FORM_ID } from "../../../util/constants/forms";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import style from './style.module.scss';

const CityDialog = (props, ref) => {
    const { user, citiesTableRef } = props;
    const { t } = useTranslation();

    const mapRef = useRef();
    const dialogRef = useRef();
    const tabsRef = useRef();
    const updateRecordRef = useRef(false);

    const [city, setCity] = useState({ isActive: STATUS_ACTIVE, });

    const _handleDataChanged = (name, value) => {
        if (name == "longitude" && Math.abs(value) > LIMIT_LNG) {
            value = (value < 0 ? -1 : 1) * LIMIT_LNG
        } else if (name == "latitude" && Math.abs(value) > LIMIT_LAT) {
            value = (value < 0 ? -1 : 1) * LIMIT_LAT
        }
        if (name == "longitude" || name == "latitude") {
            value = Number(value).toFixed("6")
        }
        city[name] = value;
        if (city.latitude && city.longitude) {
            mapRef.current.changePinLocation(city.latitude, city.longitude)
        }
        setCity({ ...city });
    }

    const _saveCity = () => {

        if (!checkValidation([{ id: "cities-dialog" }], t)) {
            return
        }
        showWaiting('cities-dialog-btn', true);

        saveCity(city)
            .then(() => {
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                citiesTableRef?.current?.refresh()
                showSuccessMessage('save_successfully', t);
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => {
                hideWaiting('cities-dialog-btn', true);
            })
    }

    const _populateRecordData = (cityId) => {
        if (cityId) {
            updateRecordRef.current = true;
            getCity(cityId)
                .then((response) => {
                    setCity(response.data);
                })
                .catch((error) => {
                    handleError(error, null, t);
                })
        } else {
            setCity({ isActive: STATUS_ACTIVE })
        }
    }

    const _postClose = () => {
        setCity({ isActive: STATUS_ACTIVE })
        removeAllMandatoryClasses(document.getElementById("cities-dialog"))
    }

    const open = (cityId) => {
        _populateRecordData(cityId);
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({
        open,
        close,
    }))

    return (
        <SidePanel
            id="cities-dialog"
            ref={dialogRef}
            formId={FND2319_CITIES_DEFINITION_FORM_ID}
            title={t('cities')}
            icon={<CitiesIcon />}
            postClose={_postClose}
            preClose={() => updateRecordRef.current = false}
            withFooter={isAllowedAction(user, FND2319_CITIES_DEFINITION_FORM_ID, SAVE_ACTION)}
            footerLbl={t("save")}
            footerAction={_saveCity}
            nextPreviousAction={_populateRecordData}
            showLog
            user={user}
            masterId={city.id}
        >
            <Tabs
                id="cities-dialog-tabs"
                ref={tabsRef}
                labels={['home', 'address']}
                tabContentStyle={style.tabContentStyle}
                tabsButtonCustomStyle={style.citiesTabs}
            >
                <Tab1 handleDataChanged={_handleDataChanged} city={city} />
                <Tab2 handleDataChanged={_handleDataChanged} city={city} mapRef={mapRef} />
            </Tabs>
        </SidePanel>
    )
}

export default forwardRef(CityDialog);