import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { encryptText, isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import { getCitiesLov, getCountriesLov, getTownsList } from "../../../../util/apis";
import { STATUS_ACTIVE } from "../../../../util/constants";
import style from './style.module.scss';

const Tab3 = (props) => {
    const { tab, customer, handleDataChanged } = props;
    const { t } = useTranslation('customers');

    return (
        <div className={tab == 3 ? 'active' : ''}>
            <div className={`container ${style.containerCustomersDialog}`}>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label id="country-lbl" data-label={encryptText("ATR_FCO_ID")} data-info={encryptText("FCO&&nameLo&&nameFo")} className='form-label mandatory'>{t('country')}</label>
                        <FrmSelectMenu
                            id="country"
                            value={customer.fcoId}
                            api={() => getCountriesLov([customer.fcoId], STATUS_ACTIVE)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            onChange={e => handleDataChanged("fcoId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label id="city-lbl" data-label={encryptText("ATR_ECD_ID")} data-info={encryptText("ECD&&descLo&&descFo")} className='form-label mandatory'>{t('city')}</label>
                        <FrmSelectMenu
                            id="city"
                            key={customer.fcoId}
                            value={customer.ecdId}
                            onChange={e => handleDataChanged("ecdId", e.value)}
                            defualtValueId={customer.ecdId}
                            defualtValueApi={() => getCitiesLov(isEmpty(customer.ecdId) ? [] : [customer.ecdId])}
                            api={(searchValue) => getCitiesLov(null, STATUS_ACTIVE, customer.fcoId, null, searchValue)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isDisabled={!customer.fcoId}
                            portalTarget={document.body}
                            isAsync={true}
                        />
                    </div>
                    <div className="w100">
                        <label id="town-lbl" data-label={encryptText("ATR_CRI_ID")} data-info={encryptText("CRI&&descLo&&descFo")} className='form-label mandatory'>{t('town')}</label>
                        <FrmSelectMenu
                            id="town"
                            key={customer.ecdId}
                            value={customer.criId}
                            onChange={e => handleDataChanged("criId", e.value)}
                            defualtValueId={customer.criId}
                            defualtValueApi={() => getTownsList(null, isEmpty(customer.criId) ? [] : [customer.criId], customer.ecdId, STATUS_ACTIVE, false, null, false)}
                            api={(searchKey) => getTownsList(searchKey, [], customer.ecdId, STATUS_ACTIVE, false, null, false)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            isAsync={true}
                            isDisabled={!customer.ecdId}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("ATR_ADDRESS")} className='form-label'>{t('address')}</label>
                        <FrmInput
                            id="address"
                            value={customer.address}
                            onBlur={(value) => handleDataChanged("address", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("ATR_PHONE_NUM")} className='form-label'>{t('phone')}</label>
                        <FrmInput
                            id="phone"
                            type={"number"}
                            value={customer.phoneNum}
                            onBlur={(value) => handleDataChanged("phoneNum", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("ATR_FAX")} className='form-label'>{t('fax')}</label>
                        <FrmInput
                            id="fax"
                            type={"number"}
                            value={customer.fax}
                            onBlur={(value) => handleDataChanged("fax", value)}
                        />
                    </div>
                    <div className="w100">
                        <label data-label={encryptText("ATR_REM")} className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id="customer-remarks"
                            value={customer.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab3;