import React from "react";

function FavMessagesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.285"
      height="22.475"
      viewBox="0 0 23.285 22.475"
    >
      <g data-name="Group 175689" transform="translate(.647 .571)">
        <g data-name="Group 175690">
          <path
            fill="#fed049"
            stroke="#f6a645"
            strokeWidth="1"
            d="M20.05 6.516h-4.887a.616.616 0 01-.589-.428l-1.51-4.648a2.1 2.1 0 00-3.987 0l-1.51 4.648a.616.616 0 01-.589.428H2.091a2.1 2.1 0 00-1.235 3.792L4.81 13.18a.62.62 0 01.225.693l-1.51 4.648a2.1 2.1 0 003.226 2.343l3.949-2.873a.614.614 0 01.727 0l3.954 2.873a2.092 2.092 0 001.232.408h0a2.1 2.1 0 001.233-.408 2.066 2.066 0 00.761-2.344L17.1 13.872a.619.619 0 01.225-.692l3.954-2.873a2.1 2.1 0 00-1.229-3.791"
            data-name="Path 6434"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default FavMessagesIcon;