import FrmTextArea from "@ipgd-gauge/frm-text-area";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GRating from "../../../components/g-rating";
import Modal from "../../../components/modal";
import { getEstimationRating } from "../../../util/apis";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const EstimationRatingDialog = (props, ref) => {
    const { rateAction } = props;

    const { t } = useTranslation("estimationTransaction");
    const trnxIdRef = useRef();
    const rateFromLogRef = useRef(false);
    const dialogRef = useRef();

    const [data, setData] = useState({});

    const _handleChange = (name, value) => {
        data[name] = value;
        setData({ ...data })
    }

    const _postClose = () => {
        setData({});
        trnxIdRef.current = "";
        rateFromLogRef.current = false;
    }

    const _rateAction = () => {
        // if (!checkValidation([{ id: "estimation-rating-dialog" }], t)) {
        //     return
        // }
        if (rateAction) {
            rateAction(trnxIdRef.current, data, rateFromLogRef.current)
        }
    }

    const open = async (trnxId, fromLog) => {
        trnxIdRef.current = trnxId;
        rateFromLogRef.current = fromLog;
        await getEstimationRating(trnxId)
            .then((response) => {
                if (response.data) {
                    data.rate = response.data?.rate || 0
                    data.rateId = response.data?.id || null
                    setData({ ...data })
                }
            })
            .catch(() => { })
        dialogRef.current.open();
    }

    const close = () => {
        dialogRef.current.close();
    }

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="estimation-rating-dialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={t(data.rateId ? 'edit_estimation_rating' : 'add_estimation_rating')}
            withFooter="true"
            okBtnLabel={t("save")}
            withOkButton="true"
            className={`fitHeightDialog ${style.actionDailog}`}
            okBtnAction={_rateAction}
            withCancelButton="true"
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            postClose={_postClose}
            smallSize
        >
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className={`w100 ${style.containerRating}`}>
                        <GRating
                            fractions="1"
                            value={data.rate}
                            onChange={(value) => _handleChange("rate", value)}
                            starNumber={10}
                        />
                    </div>
                    <div className="w100" style={{ margin: 0 }}>
                        <label id="estimation-rating-remarks-lbl" className='form-label'>{t('remarks')}</label>
                        <FrmTextArea
                            id="estimation-rating-remarks"
                            value={data.remark}
                            onChange={(e) => _handleChange("remark", e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(EstimationRatingDialog);