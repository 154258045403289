import ActionsButton from "@ipgd-gauge/actions-button";
import { addUrlParam, checkValidation, confirmationDialog, handleError, hideWaiting, isAllowedAction, isNotEmpty, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../../icons/AddBtn";
import DeleteTowIcon from "../../../icons/Delete2";
import InputsBtnVeiwIcon from "../../../icons/InputsBtnVeiw";
import SaveIcon from "../../../icons/Save";
import { deletePropertyPolicy, savePropertyPolicy } from "../../../util/apis";
import { DELETE_ACTION, NEW_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID } from "../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import { openSummary } from "../../../util/util";
import { ACTION_UPDATE_PROPERTIES_POLICY } from "./lines/reducer";

const Actions = (props) => {
    const { user, propertyPolicy, dispatchPropertyPolicy, newAction, fromSaveActionRef } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [actions, setActions] = useState([]);

    const openSummaryMobile = () => {
        openSummary('evaluation-policies-summary');
    }

    const _saveAction = () => {
        if (!checkValidation([{ id: "properties-policies-main" }], t)) {
            return;
        }
        showWaiting(`save`, true);
        savePropertyPolicy(propertyPolicy)
            .then((response) => {
                showSuccessMessage('save_successfully', t);
                fromSaveActionRef.current = true;
                hideWaiting(`save`)
                dispatchPropertyPolicy({ type: ACTION_UPDATE_PROPERTIES_POLICY, value: response.data });
                addUrlParam("id", response.data.id);
            })
            .catch((error) => handleError(error, error.response.data.message.split('#')[0], t))
            .finally(() => hideWaiting(`save`));
    }

    const _deleteAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deletePropertyPolicy(propertyPolicy?.id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    navigate(`/evaluation-policies`);
                })
                .catch((error) => handleError(error, "delete_fields", t));
        });
    }

    const _isAllowedSaveAction = () => {
        return isAllowedAction(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, SAVE_ACTION);
    }

    useEffect(() => {
        const actions = [];

        if (isAllowedAction(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, DELETE_ACTION) && isNotEmpty(propertyPolicy?.id)) {
            actions.push({
                id: 'delete',
                label: t('delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                icon: <DeleteTowIcon />,
                action: _deleteAction,
            });
        }

        if (_isAllowedSaveAction()) {
            actions.push({
                id: 'save',
                label: t('save'), color: "var(--action-save)",
                hotkey: SAVE_ACTION_SHORT_CUT,
                icon: <SaveIcon />,
                color: "var(--action-save)",
                action: () => _saveAction()
            });
        }

        if (isAllowedAction(user, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, NEW_ACTION)) {
            actions.push({
                id: 'new',
                label: t('new'),
                icon: <AddBtnIcon />,
                color: "var(--action-copy)",
                action: () => newAction(),
            });
        }

        actions.push({
            label: t('summary'),
            action: openSummaryMobile,
            icon: <InputsBtnVeiwIcon />,
            color: "#8A3FFC",
            style: "summaryActionBtn"
        });

        setActions(actions);
    }, [i18next.language, propertyPolicy]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction();
        }
    }, { enableOnFormTags: true });

    return (
        <ActionsButton actions={actions} />
    )
}

export default Actions;