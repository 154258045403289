import React from "react";

function ActionTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="23"
      viewBox="0 0 5 23"
    >
      <g
        fill="#d5d5d5"
        data-name="Group 178463"
        transform="rotate(90 189.033 97.952)"
      >
        <ellipse
          cx="2.5"
          cy="2.5"
          data-name="Ellipse 33"
          rx="2.5"
          ry="2.5"
          transform="translate(109.081 281.985)"
        ></ellipse>
        <ellipse
          cx="2.5"
          cy="2.5"
          data-name="Ellipse 34"
          rx="2.5"
          ry="2.5"
          transform="translate(100.081 281.985)"
        ></ellipse>
        <ellipse
          cx="2.5"
          cy="2.5"
          data-name="Ellipse 35"
          rx="2.5"
          ry="2.5"
          transform="translate(91.081 281.985)"
        ></ellipse>
      </g>
    </svg>
  );
}

export default ActionTableIcon;