import React from "react";

function KindaLoginIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 304.78 76.09">
      <path
        fill="#fff"
        d="M87.69 26.03c-3.05 0-5.52 2.47-5.52 5.52v37.01c0 3.05 2.47 5.52 5.52 5.52 3.05 0 5.52-2.47 5.52-5.52V31.55c0-3.05-2.47-5.52-5.52-5.52zM187.1 7.14c-3.05 0-5.52 2.47-5.52 5.52v18.33c-2.26-1.94-11.96-9.16-25.06-2.41 0 0-15.73 8.22-9.66 31.98 0 0 10.22 25.88 33.1 10.81 0 0 .73-.87 1.71-1.87.45 2.6 2.7 4.58 5.43 4.58 3.05 0 5.52-2.47 5.52-5.52v-55.9c0-3.05-2.47-5.52-5.52-5.52zm-18.2 59.17c-7.41 0-13.41-6.9-13.41-15.42s6.01-15.42 13.41-15.42c5.87 0 10.85 4.34 12.67 10.37v10.08c-1.82 6.04-6.8 10.38-12.67 10.38zM240.12 26.03c-2.81 0-5.1 2.1-5.45 4.81-2.56-2.14-12.1-8.84-24.87-2.25 0 0-15.73 8.22-9.66 31.98 0 0 10.22 25.88 33.1 10.81 0 0 .62-.74 1.49-1.64a5.518 5.518 0 0010.91-1.17V31.55c0-3.05-2.47-5.52-5.52-5.52zM222.18 66.3c-7.41 0-13.41-6.9-13.41-15.42s6.01-15.42 13.41-15.42c5.62 0 10.42 3.98 12.42 9.61v11.61c-2 5.64-6.8 9.62-12.42 9.62zM88.28 8.95h-.69c-2.99 0-5.41 2.42-5.41 5.42s2.42 5.41 5.41 5.41h.69c2.99 0 5.41-2.42 5.41-5.41s-2.42-5.42-5.41-5.42zM129.08 25.12c-11.4-1.83-17.37 4.3-19.43 7.06v-.63c0-3.05-2.47-5.52-5.52-5.52-3.05 0-5.52 2.47-5.52 5.52v37.01c0 3.05 2.47 5.52 5.52 5.52 3.05 0 5.52-2.47 5.52-5.52V43.99c1.2-7.91 7.85-8.92 7.85-8.92 11.2-2.32 12.33 7.16 12.33 7.16l.09 26.57s-.84-2.31.09.25c.93 2.57 1.45 4.87 5.44 5.11s5.22-3.86 5.22-3.86l.22-18.11s2.76-24.72-11.82-27.06z"
      ></path>
      <path fill="#fff" d="M37.28 0L73.87 0 55.69 18.18 37.28 0z"></path>
      <path fill="#fff" d="M37.28 0L37.28 36.94 55.86 18.35 37.28 0z"></path>
      <path
        fill="#fff"
        d="M18.65 18.64L37.11 37.1 0 37.1 18.65 18.64z"
        opacity="0.6"
      ></path>
      <path
        fill="#fff"
        d="M37.11 37.29L37.11 74.09 18.56 55.53 37.11 37.29z"
        opacity="0.3"
      ></path>
      <path
        fill="#fff"
        d="M37.11 37.29L55.41 55.58 37.01 73.99 37.11 37.29z"
      ></path>
      <path
        fill="#fff"
        d="M55.41 55.53L73.74 73.86 37.01 73.86 55.41 55.53z"
        opacity="0.7"
      ></path>
      <path
        fill="#fff"
        d="M261.91 65.35h6.32v7.45c-.29.23-.64.42-1.04.59s-.83.31-1.29.42c-.46.11-.93.2-1.41.27-.48.06-.96.1-1.42.1-1.16 0-2.23-.17-3.22-.52a6.89 6.89 0 01-2.58-1.6c-.73-.72-1.31-1.64-1.72-2.74s-.63-2.4-.63-3.89.25-2.89.76-4c.51-1.11 1.16-2.02 1.96-2.72.8-.71 1.7-1.22 2.69-1.54.99-.32 1.97-.48 2.95-.48 1.06 0 1.94.06 2.64.18.7.12 1.28.27 1.75.45l-.58 2.05c-.84-.37-2.04-.55-3.62-.55-.72 0-1.44.11-2.16.34-.72.23-1.36.6-1.93 1.12-.57.52-1.03 1.21-1.39 2.05s-.53 1.88-.53 3.12c0 1.14.15 2.13.45 2.96.3.84.71 1.53 1.24 2.07.53.55 1.16.95 1.88 1.21s1.51.39 2.36.39c.53 0 1.04-.05 1.53-.16.49-.1.92-.25 1.29-.44v-4.22l-4.31-.45v-1.43zM274.56 61.21v6.97c0 .67.04 1.27.12 1.78.08.51.22.94.43 1.29.21.35.49.61.86.8.36.19.82.28 1.39.28.96 0 1.75-.27 2.36-.82.61-.55 1.08-1.22 1.4-2.02v-8.27h2.22v9.06c0 .61.02 1.23.07 1.87.05.64.12 1.21.22 1.72h-1.49l-.58-2.1h-.24c-.42.72-1 1.31-1.74 1.76s-1.66.67-2.77.67c-.74 0-1.39-.09-1.94-.27-.55-.18-1.02-.48-1.4-.9-.38-.43-.66-.99-.84-1.7-.18-.71-.28-1.59-.28-2.65v-7.47h2.22zM290.97 70.51c0 .66.12 1.12.37 1.4.25.27.58.41 1 .41.26 0 .51-.02.75-.05.24-.03.53-.1.87-.19l.29 1.64c-.16.06-.35.12-.58.18-.23.06-.46.11-.7.16-.24.05-.48.08-.72.11-.24.02-.46.04-.65.04-.87 0-1.56-.22-2.07-.66-.51-.44-.77-1.18-.77-2.21V57h2.22v13.52zM295.63 61.21h2.14v-.34c0-.79.07-1.45.22-1.99.14-.54.37-.97.69-1.3.31-.33.72-.57 1.23-.71s1.12-.22 1.84-.22c.61 0 1.14.04 1.6.11.46.07.94.2 1.43.37l-.48 1.81c-.42-.18-.81-.29-1.17-.34-.36-.05-.71-.07-1.05-.07-.45 0-.81.04-1.08.12-.27.08-.49.22-.64.42-.15.2-.25.47-.3.82-.05.35-.07.78-.07 1.31h3.71v1.88h-3.71v10.77h-2.22V63.08h-2.14V61.2z"
      ></path>
    </svg>
  );
}

export default KindaLoginIcon;