import FrmInput from "@ipgd-gauge/frm-input";
import RadioOptions from "@ipgd-gauge/radio-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { encryptText, isEmpty } from "@ipgd-gauge/utils";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import { getCountriesLov, getRegionsLov } from "../../../util/apis";
import { STATUS_ACTIVE, STATUS_IN_ACTIVE } from "../../../util/constants";
import { FND2319_CITIES_DEFINITION_FORM_ID } from "../../../util/constants/forms";

const Tab1 = (props) => {

    const { tab, handleDataChanged, city } = props;
    const { t } = useTranslation('cities');

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w100">
                        <CodeInput
                            id="code"
                            value={city.code}
                            onBlur={(value) => handleDataChanged("code", value)}
                            column="ECD_CODE"
                            formId={FND2319_CITIES_DEFINITION_FORM_ID}
                        />
                    </div>
                    <div className="w100">
                        <label id="city-status-lbl" data-label={encryptText("ECD_IS_ACTIVE")} data-info={encryptText("DOMAIN")} className='form-label mandatory'> {t('status')}</label>
                        <RadioOptions
                            id="city-status"
                            sidePanelMode
                            options={[
                                {
                                    id: "active",
                                    title: t('active'),
                                    action: () => handleDataChanged("isActive", STATUS_ACTIVE),
                                    selected: city.isActive == STATUS_ACTIVE
                                },
                                {
                                    id: "inactive",
                                    title: t('inactive'),
                                    action: () => handleDataChanged("isActive", STATUS_IN_ACTIVE),
                                    selected: city.isActive == STATUS_IN_ACTIVE
                                },
                            ]} />
                    </div>
                    <div className="w100">
                        <label id="english_name_city-lbl" data-label={encryptText("ECD_DESC_LO")} className='form-label mandatory'>{t('english_name')}</label>
                        <FrmInput
                            id="english_name_city"
                            value={city.descLo}
                            onBlur={(value) => handleDataChanged("descLo", value)}
                        />
                    </div>

                    <div className="w100">
                        <label id="arabic_name_city-lbl" data-label={encryptText("ECD_DESC_FO")} className='form-label mandatory'>{t('arabic_name')}</label>
                        <FrmInput
                            id="arabic_name_city"
                            value={city.descFo}
                            onBlur={(value) => handleDataChanged("descFo", value)}
                        />
                    </div>
                    <div className="w100">
                        <label id="country-lbl" className='form-label mandatory'>{t('country')}</label>
                        <FrmSelectMenu
                            id="country"
                            key={`country-${city?.id}`}
                            value={city.countryId}
                            api={() => getCountriesLov([city.countryId], STATUS_ACTIVE)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            onChange={e => handleDataChanged("countryId", e.value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label id="region-lbl" data-label={encryptText("ECD_FRS_ID")} data-info={encryptText("FRS&&nameLo&&nameFo")} className='form-label mandatory'>{t('region')}</label>
                        <FrmSelectMenu
                            id="region"
                            key={city.countryId}
                            value={city.frsId}
                            api={() => getRegionsLov(isEmpty(city.frsId) ? [] : [city.frsId], STATUS_ACTIVE, null, city.countryId)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => handleDataChanged("frsId", e.value)}
                            isDisabled={!city.countryId}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label' data-label={encryptText("ECD_REM")}>{t('remarks')}</label>
                        <FrmInput
                            id="remarks"
                            value={city.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab1;