import { DateFromToInput } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty, parseNumber } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getCitiesLov, getCommonCustomers, getCommonPartiesLov, getCommonPropertyTypes, getCoordinatorsForSelect, getCurrentTime, getDomainValues, getEstimationDomains, getEstimationQuotationsLov, getEvaluationFunctions, getRealEstateUserLOV, getRegionsLov, getRsmEstimator, getRsmStatus, getTownsList } from "../../../util/apis";
import { DOMAIN_WORKFLOW_PRIORITIES, ESTIMATION_DOMAINS_STAGES, ESTIMATION_DOMAINS_TRANSACTION_TYPE, ESTIMATOR_PARTY, INSPECTOR_PARTY, POLICY_LVL_SELF_SERVICE, USERS_TYPES_CUSTOMER, USERS_TYPES_MANAGER, USERS_TYPES_NORMAL_EMPLOYEE, USERS_TYPES_SUPERVISOR } from "../../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";

const MainFilters = (props) => {
    const { user, filterData, handleDataChanged } = props;
    const { t } = useTranslation();

    const archivedOptions = [
        { value: '0', label: t('estimationTransaction:under_process') },
        { value: '1', label: t('estimationTransaction:archived') }
    ]

    const favOptions = [
        { value: '0', label: t('estimationTransaction:fav') },
        { value: '1', label: t('estimationTransaction:unfav') },
        { value: '2', label: t('estimationTransaction:with_note') },
        { value: '3', label: t('estimationTransaction:without_note') },
        { value: '4', label: t('estimationTransaction:with_task') },
        { value: '5', label: t('estimationTransaction:without_task') },
    ]

    return (
        <div>
            <div className="frm-title-panel">
                <label className="frm-title-form">{t('filters')}</label>
            </div>
            <div className="container">
                <div className="row rowSmModel">
                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:status')}</label>
                        <FrmSelectMenu
                            id="status"
                            isMulti={true}
                            value={filterData.status}
                            api={getRsmStatus}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => handleDataChanged("status", e)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:archived_status')}</label>
                        <FrmSelectMenu
                            id="archived-status"
                            value={filterData.archivedStatus}
                            options={archivedOptions}
                            idName='value'
                            descLo={['label']}
                            descFo={['label']}
                            onChange={e => handleDataChanged("archivedStatus", e.value)}
                            isClearable={true}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:from_to_date')}</label>
                        <DateFromToInput
                            id="from-to-date"
                            enableTime
                            fromValue={filterData.fromDate}
                            toValue={filterData.toDate}
                            fromOnChange={value => handleDataChanged("fromDate", value)}
                            toOnChange={value => handleDataChanged("toDate", value)}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:approval_date_from_to')}</label>
                        <DateFromToInput
                            id="approval-date-from-to"
                            enableTime
                            fromValue={filterData.approvalFromDate}
                            toValue={filterData.approvalToDate}
                            fromOnChange={value => handleDataChanged("approvalFromDate", value)}
                            toOnChange={value => handleDataChanged("approvalToDate", value)}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:fav_note_task')}</label>
                        <FrmSelectMenu
                            id="fav-note-task"
                            isMulti={true}
                            value={filterData.fav}
                            options={favOptions}
                            idName='value'
                            descLo={['label']}
                            descFo={['label']}
                            onChange={value => handleDataChanged("fav", value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:workflow')}</label>
                        <FrmSelectMenu
                            id="workflow"
                            isMulti={true}
                            value={filterData.fnoId}
                            api={() => getEvaluationFunctions(filterData?.fnoId, null, null, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("fnoId", value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:customer')}</label>
                        <FrmSelectMenu
                            id="customer"
                            value={filterData.atrId}
                            onChange={e => handleDataChanged("atrId", e)}
                            defualtValueId={filterData.atrId}
                            defualtValueApi={() => getCommonCustomers(filterData.atrId, null, null, true, false, false, [], true)}
                            api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                            descLo={['code', 'descLo']}
                            descFo={['code', 'descFo']}
                            isMulti={true}
                            isAsync={true}
                            isDisabled={user.type == USERS_TYPES_CUSTOMER && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:property_type')}</label>
                        <FrmSelectMenu
                            id="property-type"
                            isMulti={true}
                            value={filterData.myoId}
                            api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.myoId)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("myoId", value)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:region')}</label>
                        <FrmSelectMenu
                            id="region"
                            isMulti={true}
                            value={filterData.triFrsId}
                            api={() => getRegionsLov(filterData.triFrsId, null, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={value => handleDataChanged("triFrsId", value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:city')}</label>
                        <FrmSelectMenu
                            id="city"
                            isMulti={true}
                            value={filterData.ecdId}
                            defualtValueId={filterData.ecdId}
                            defualtValueApi={() => getCitiesLov(filterData.ecdId)}
                            api={(searchValue) => getCitiesLov(null, null, null, null, searchValue, true)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isAsync={true}
                            onChange={value => handleDataChanged("ecdId", value)}
                            portalTarget={document.body}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:town')}</label>
                        <FrmSelectMenu
                            id="town"
                            value={filterData.criId}
                            onChange={value => handleDataChanged("criId", value)}
                            defualtValueId={filterData.criId}
                            defualtValueApi={() => getTownsList(null, isEmpty(filterData.criId) ? [] : [filterData.criId], null, null, true, null, true)}
                            api={(searchKey) => getTownsList(searchKey, null, null, null, true, null, true)}
                            descLo={['nameLo']}
                            descFo={['nameFo']}
                            isMulti={true}
                            isAsync={true}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:priority')}</label>
                        <FrmSelectMenu
                            id="priority"
                            isMulti={true}
                            value={filterData.priorities}
                            api={() => getDomainValues(DOMAIN_WORKFLOW_PRIORITIES)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => handleDataChanged("priorities", e)}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const MoreFilters = (props) => {
    const { users, filterData, handleDataChanged, user } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="container">
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('more_options')}</label>
                </div>
                <div className="row">
                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:actual_date_from_to')}</label>
                        <DateFromToInput
                            id="actual-date-from-to"
                            enableTime
                            fromValue={filterData.actualFromDate}
                            toValue={filterData.actualToDate}
                            fromOnChange={value => handleDataChanged("actualFromDate", value)}
                            toOnChange={value => handleDataChanged("actualToDate", value)}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:stage')}</label>
                        <FrmSelectMenu
                            id="stage"
                            isMulti={true}
                            value={filterData.stages}
                            api={() => getEstimationDomains(ESTIMATION_DOMAINS_STAGES)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => handleDataChanged("stages", e)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:transaction_type')}</label>
                        <FrmSelectMenu
                            id="transaction-type"
                            isMulti={true}
                            value={filterData.trxType}
                            api={() => getEstimationDomains(ESTIMATION_DOMAINS_TRANSACTION_TYPE)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onChange={e => handleDataChanged("trxType", e)}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label className='form-label'>{t('estimationTransaction:quotation')}</label>
                        <FrmSelectMenu
                            id="quotation"
                            value={filterData.quotations}
                            api={(searchvalue) => getEstimationQuotationsLov(searchvalue)}
                            defualtValueApi={() => getEstimationQuotationsLov(null, filterData.quotations)}
                            defualtValueId={filterData.quotations}
                            descLo={['code', 'officialNameLo', 'mobileNum']}
                            descFo={['code', 'officialNameFo', 'mobileNum']}
                            onChange={e => handleDataChanged("quotations", e)}
                            isMulti={true}
                            isAsync
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>

            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('estimationTransaction:procedures')}</label>
                </div>
                <div className='container'>
                    <div className='row  rowSmModel'>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:inspector')}</label>
                            <FrmSelectMenu
                                id="inspector"
                                isMulti={true}
                                value={filterData.inspectorAprId}
                                api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.inspectorAprId, null, null, 1)}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={e => handleDataChanged("inspectorAprId", e)}
                                portalTarget={document.body}
                                isDisabled={(user.inspectorId || user.type == USERS_TYPES_CUSTOMER) && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:estimator')}</label>
                            <FrmSelectMenu
                                id="estimator"
                                isMulti={true}
                                value={filterData.estimatorAprId}
                                api={getRsmEstimator}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={e => handleDataChanged("estimatorAprId", e)}
                                portalTarget={document.body}
                                isDisabled={(user.estimatorId || user.type == USERS_TYPES_CUSTOMER) && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:executor_user')}</label>
                            <FrmSelectMenu
                                id="executor"
                                isMulti={true}
                                value={filterData.executorUser}
                                isAsync={true}
                                defualtValueId={filterData.executorUser}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.executorUser, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("executorUser", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:entered_by')}</label>
                            <FrmSelectMenu
                                id="entered-by"
                                isMulti={true}
                                value={filterData.enteredBy}
                                isAsync={true}
                                defualtValueId={filterData.enteredBy}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.enteredBy, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("enteredBy", e)}
                                isDisabled={user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE && user.type == USERS_TYPES_NORMAL_EMPLOYEE}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:inspection_user')}</label>
                            <FrmSelectMenu
                                id="inspection-user"
                                isMulti={true}
                                value={filterData.inspectionUser}
                                isAsync={true}
                                defualtValueId={filterData.inspectionUser}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.inspectionUser, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("inspectionUser", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:inspection_auditor')}</label>
                            <FrmSelectMenu
                                id="inspection-auditor"
                                isMulti={true}
                                value={filterData.inspectionAuditor}
                                isAsync={true}
                                defualtValueId={filterData.inspectionAuditor}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.inspectionAuditor, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("inspectionAuditor", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:reviewer')}</label>
                            <FrmSelectMenu
                                id="reviewer"
                                isMulti={true}
                                value={filterData.reviewer}
                                isAsync={true}
                                defualtValueId={filterData.reviewer}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.reviewer, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("reviewer", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:reviewer_auditor')}</label>
                            <FrmSelectMenu
                                id="reviewer-auditor"
                                isMulti={true}
                                value={filterData.reviewerAuditor}
                                isAsync={true}
                                defualtValueId={filterData.reviewerAuditor}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.reviewerAuditor, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("reviewerAuditor", e)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:approved_by')}</label>
                            <FrmSelectMenu
                                id="approved-by"
                                isMulti={true}
                                value={filterData.approvedBy}
                                isAsync={true}
                                defualtValueId={filterData.approvedBy}
                                defualtValueApi={() => getRealEstateUserLOV(null, null, false, false, null, null, null, null, filterData.approvedBy, true)}
                                api={(searchValue) => getRealEstateUserLOV(searchValue, null, false, false, null, null, null, null,
                                    null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => handleDataChanged("approvedBy", e)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationTransaction:coordinator')}</label>
                            <FrmSelectMenu
                                id="coordinator"
                                isMulti={true}
                                value={filterData.coordinatorAprId}
                                api={getCoordinatorsForSelect}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={e => handleDataChanged("coordinatorAprId", e)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const EstimationTransactionsFilter = (props, ref) => {
    const { user, tableRef } = props;
    const advanceFilterRef = useRef()
    const currentTimeRef = useRef()

    const [filterData, setFilterData] = useState({ archivedStatus: "0" });

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getDefaultFilters = async () => {
        const defaultFilters = { archivedStatus: "0" };
        let currentTime = await getCurrentTime();
        let fromDate = parseNumber(currentTime.data) - 7884100000;
        defaultFilters.fromDate = fromDate
        currentTimeRef.current = fromDate
        if (user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE) {
            if (user.type == USERS_TYPES_NORMAL_EMPLOYEE) {
                defaultFilters.enteredBy = [user.id];
                defaultFilters.fusId = user.fndUserId;
            } else if (user.type == USERS_TYPES_CUSTOMER) {
                defaultFilters.atrId = [user.customerId];
            } else if (user.type == USERS_TYPES_MANAGER || user.type == USERS_TYPES_SUPERVISOR) {
                defaultFilters.fusId = user.fndUserId;
            } else {
                if (user.inspectorId) {
                    defaultFilters.inspectorAprId = [user.inspectorId];
                }
                if (user.estimatorId) {
                    defaultFilters.estimatorAprId = [user.estimatorId];
                }
            }
        }

        return defaultFilters;
    }

    const getFilterData = () => {
        return Object.assign({}, filterData);
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            id="estimationTransactionsFilter"
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            ref={advanceFilterRef}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            getDefaultFilter={getDefaultFilters}
            numOfRows={6}
        >
            <MainFilters user={user} filterData={filterData} handleDataChanged={_handleDataChanged} />
            <MoreFilters user={user} filterData={filterData} handleDataChanged={_handleDataChanged} />
        </AdvanceFilter>
    )
}

export default forwardRef(EstimationTransactionsFilter);