import React from "react";

function MarkerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="23.889"
      viewBox="0 0 20 23.889"
    >
      <g data-name="marker(4)" transform="translate(-2)">
        <path
          d="M12 0A10.011 10.011 0 002 10c0 5.282 8.4 12.533 9.354 13.343l.646.546.646-.546C13.6 22.533 22 15.282 22 10A10.011 10.011 0 0012 0zm0 15a5 5 0 115-5 5 5 0 01-5 5z"
          data-name="Path 43634"
        ></path>
        <circle
          cx="3"
          cy="3"
          r="3"
          data-name="Ellipse 60"
          transform="translate(9 7)"
        ></circle>
      </g>
    </svg>
  );
}

export default MarkerIcon;
