import React from "react";

function MsgTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.499"
      height="21.561"
      viewBox="0 0 21.499 21.561"
    >
      <path
        fill="#410060"
        d="M-1981.051-8723.4l-1.5-2a.51.51 0 00-.2-.1h-.494c-1.729 0-3.673-.153-4.995-1.311-1.2-1.049-1.756-2.775-1.756-5.437v-5c0-4.415 2.333-6.75 6.75-6.75h8c4.417 0 6.75 2.335 6.75 6.75v5c0 4.415-2.333 6.748-6.75 6.748h-.5a.265.265 0 00-.2.105l-1.5 1.994a2.271 2.271 0 01-1.8.962 2.272 2.272 0 01-1.805-.961zm-7.45-13.849v5c0 2.2.395 3.568 1.244 4.31.753.659 1.951.939 4.007.939h.5a1.877 1.877 0 011.4.7l1.5 2a.781.781 0 00.6.36.781.781 0 00.6-.36l1.5-2a1.777 1.777 0 011.4-.7h.5c3.583 0 5.251-1.668 5.251-5.249v-5c0-3.581-1.668-5.249-5.251-5.249h-8c-3.581-.002-5.25 1.667-5.25 5.248zm13.248 4a1 1 0 01-1-1 1 1 0 011-1h.009a1 1 0 011 1 1 1 0 01-1 1zm-4 0a1 1 0 01-1-1 1 1 0 011-1h.011a1 1 0 011 1 1 1 0 01-1 1zm-4 0a1 1 0 01-1-1 1 1 0 011-1h.009a1 1 0 011 1 1 1 0 01-1 1z"
        data-name="Union 51"
        transform="translate(1990 8744)"
      ></path>
    </svg>
  );
}

export default MsgTableIcon;