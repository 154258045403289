import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../components/advance-filter";
import { getCommonCustomers, getCommonPartiesLov, getCommonPropertyTypes, getRegionsLov, getRsmEstimator, getRsmStatus } from "../../util/apis";
import { ESTIMATOR_PARTY, INSPECTOR_PARTY, POLICY_LVL_SELF_SERVICE, USERS_TYPES_CUSTOMER, USERS_TYPES_ESTIMATER, USERS_TYPES_INSPECTOR } from "../../util/constants";
import { RSM3242_TRANSACTIONS_STATISTICS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";

const TransactionsStatisticsFilter = (props, ref) => {
    const { fetchData } = props;
    const advanceFilterRef = useRef();
    const { t } = useTranslation();
    const user = getUser();

    const [filterData, setFilterData] = useState({});

    const _handleFilterDataChange = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return filterData;
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }));

    const getDefaultFilters = () => {
        const defaultFilters = {};
        if (user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE) {
            if (user.customerId) {
                defaultFilters.customerIds = [user.customerId];
            }
            if (user.inspectorId) {
                defaultFilters.inspectorIds = [user.inspectorId];
            }
            if (user.estimatorId) {
                defaultFilters.estimatorIds = [user.estimatorId];
            }
        }
        return defaultFilters;
    }

    const isCustomer = () => {
        if (user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE) {
            return user.customerId;
        }
        return false;
    }

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="transactions-statistics-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={fetchData}
            getDefaultFilter={getDefaultFilters}
            numOfRows={3}
            formId={RSM3242_TRANSACTIONS_STATISTICS_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('customer')}</label>
                            <FrmSelectMenu
                                id="customer"
                                value={filterData.customerIds}
                                onChange={e => _handleFilterDataChange("customerIds", e)}
                                defualtValueId={filterData.customerIds}
                                defualtValueApi={() => getCommonCustomers(filterData.customerIds, null, null, true, false, false, [], true)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                isDisabled={user.type == USERS_TYPES_CUSTOMER && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                                portalTarget={document.body}
                            />
                        </div>
                        {!isCustomer() &&
                            <div className="w50">
                                <label className='form-label'>{t('inspector')}</label>
                                <FrmSelectMenu
                                    id="transactions-statistics-filter-inspector"
                                    key="transactions-statistics-filter-inspector"
                                    isMulti={true}
                                    value={filterData.inspectorIds}
                                    api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, filterData.inspectorIds, null, null, 1)}
                                    descLo={['nameLo']}
                                    descFo={['nameFo']}
                                    onChange={e => _handleFilterDataChange("inspectorIds", e)}
                                    portalTarget={document.body}
                                    isDisabled={user.type == USERS_TYPES_INSPECTOR && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                                />
                            </div>}
                        {!isCustomer() &&
                            <div className="w50">
                                <label className='form-label'>{t('estimator')}</label>
                                <FrmSelectMenu
                                    id="transactions-statistics-filter-estimator"
                                    isMulti={true}
                                    value={filterData.estimatorIds}
                                    api={() => getRsmEstimator()}
                                    descLo={['nameLo']}
                                    descFo={['nameFo']}
                                    onChange={e => _handleFilterDataChange("estimatorIds", e)}
                                    portalTarget={document.body}
                                    isDisabled={user.type == USERS_TYPES_ESTIMATER && user.estimationPolicy.lsoUsageLevel == POLICY_LVL_SELF_SERVICE}
                                />
                            </div>}
                        <div className="w50">
                            <label className='form-label'>{t('status')}</label>
                            <FrmSelectMenu
                                id="transactions-statistics-filter-status"
                                isMulti={true}
                                value={filterData.statuses}
                                api={() => getRsmStatus()}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleFilterDataChange("statuses", e)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('region')}</label>
                            <FrmSelectMenu
                                id="transactions-statistics-filter-region"
                                isMulti={true}
                                value={filterData.regionIds}
                                api={() => getRegionsLov(filterData.regionIds)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleFilterDataChange("regionIds", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('property_type')}</label>
                            <FrmSelectMenu
                                id="transactions-statistics-filter-property-type"
                                isMulti={true}
                                value={filterData.propertyTypeIds}
                                api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.propertyTypeIds)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleFilterDataChange("propertyTypeIds", value)}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </AdvanceFilter>
    )
}

export default forwardRef(TransactionsStatisticsFilter);