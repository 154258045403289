import React from "react";

export function ElectricityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.293"
      height="20.769"
      viewBox="0 0 22.293 20.769"
    >
      <g
        fill="#292d32"
        data-name="Group 179922"
        transform="translate(4675.25 10797.384)"
      >
        <path
          d="M5.411 20.018a1.912 1.912 0 01-1.915-1.9v-6.45h-2.34A1.909 1.909 0 01-.281 8.5L7.293-.1a1.863 1.863 0 011.418-.65 1.912 1.912 0 011.915 1.9V7.6h2.34a1.9 1.9 0 011.434 3.17l-7.57 8.6a1.863 1.863 0 01-1.419.648zM.852 9.487a.4.4 0 00-.062.441.392.392 0 00.366.237h3.09a.75.75 0 01.75.75v7.2a.4.4 0 00.415.4.374.374 0 00.29-.138l7.571-8.6a.4.4 0 00-.307-.674H9.876a.75.75 0 01-.75-.75v-7.2a.4.4 0 00-.415-.4.375.375 0 00-.29.138z"
          transform="translate(-4667.836 -10796.634)"
        ></path>
        <path
          d="M7 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h7a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          transform="translate(-4674.5 -10795)"
        ></path>
        <path
          d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          transform="translate(-4674.5 -10779)"
        ></path>
        <path
          d="M3 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector"
          transform="translate(-4674.5 -10787)"
        ></path>
      </g>
    </svg>
  );
}

export function FilledElectricityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.062"
      height="20.31"
      viewBox="0 0 22.062 20.31"
    >
      <g data-name="Group 179925" transform="translate(4675.25 10832.154)">
        <path
          fill="#f0da17"
          stroke="#dbc500"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M-4666.68-10820.72h3.09v7.2a1.154 1.154 0 002.02.76l7.57-8.6a1.155 1.155 0 00-.87-1.92h-3.09v-7.2a1.154 1.154 0 00-2.02-.76l-7.57 8.6a1.159 1.159 0 00.87 1.92z"
        ></path>
        <path
          fill="#dbc500"
          d="M7 0H0"
          data-name="Vector"
          transform="translate(-4674.5 -10830)"
        ></path>
        <path
          fill="#dbc500"
          d="M7 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h7a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector - Outline"
          transform="translate(-4674.5 -10830)"
        ></path>
        <path
          fill="#dbc500"
          d="M6 0H0"
          data-name="Vector"
          transform="translate(-4674.5 -10814)"
        ></path>
        <path
          fill="#dbc500"
          d="M6 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h6a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector - Outline"
          transform="translate(-4674.5 -10814)"
        ></path>
        <path
          fill="#dbc500"
          d="M3 0H0"
          data-name="Vector"
          transform="translate(-4674.5 -10822)"
        ></path>
        <path
          fill="#dbc500"
          d="M3 .75H0A.75.75 0 01-.75 0 .75.75 0 010-.75h3a.75.75 0 01.75.75.75.75 0 01-.75.75z"
          data-name="Vector - Outline"
          transform="translate(-4674.5 -10822)"
        ></path>
      </g>
    </svg>
  );
}
