import { parseNumber } from '@ipgd-gauge/utils';
import { setAuthorizationHeader } from '../../../util/apis';
import { ACTION_CHANGE_FAVORITE_COUNT, ACTION_CHANGE_FAVORITE_FORMS, ACTION_CHANGE_UNREADED_MSGS_COUNT, ACTION_USER_LOGIN, ACTION_USER_LOGOUT, ACTION_USER_UPDATE_PROFILE } from '../actions';

const userReducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_USER_LOGIN:
            setAuthorizationHeader(action.token)
            return {
                ...state,
                token: action.token,
                user: action.user,
            };

        case ACTION_USER_UPDATE_PROFILE:
            return {
                ...state,
                user: action.user,
            };

        case ACTION_USER_LOGOUT:
            setAuthorizationHeader(null)
            return {
                ...state,
                token: null,
                user: null,
            };

        case ACTION_CHANGE_FAVORITE_FORMS:
            const formId = parseNumber(action.formId);

            if (state.user.favoriteForms.includes(formId)) {
                const index = state.user.favoriteForms.indexOf(formId);

                if (index > -1) {
                    state.user.favoriteForms.splice(index, 1);
                }
            } else {
                state.user.favoriteForms.push(formId);
            }

            return { ...state };

        case ACTION_CHANGE_FAVORITE_COUNT:
            state.user.favoriteCount = action.count;
            return { ...state };
        case ACTION_CHANGE_UNREADED_MSGS_COUNT:
            state.user.unreadedMsgsCount = action.count;
            return { ...state };

        default:
            return state;
    }
};

export default userReducer;