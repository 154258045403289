import Tabs from "@ipgd-gauge/tabs";
import { useRef } from "react";
import Tab2 from "./Tab2";
import style from './style.module.scss';
import Tab1 from "./tab1";

const Lines = (props) => {
    const { user, data, dispatchPropertyPolicy } = props;

    const tabsRef = useRef();

    return (
        <div className={style.container}>
            <Tabs
                id="properties-policies-main"
                containerStyle={style.tabsContainer}
                tabContentStyle={style.tabContentStyle}
                ref={tabsRef}
                labels={['home', 'propertiesPolicies:organization_list']}
            >
                <Tab1 data={data} dispatchPropertyPolicy={dispatchPropertyPolicy} user={user} />
                <Tab2 data={data} dispatchPropertyPolicy={dispatchPropertyPolicy} />
            </Tabs>
        </div>
    );
}
export default Lines;