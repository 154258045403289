import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { formatMoney, isEnglish } from '@ipgd-gauge/utils';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DecimalInput } from '../../../../components/decimal-input';
import { addUnitsDetail, deleteUnitsDetail, getDomainValues, getUnitsDetails } from '../../../../util/apis';
import { DOMAIN_FLOORS, DOMAIN_USAGE_TRANSACTION } from '../../../../util/constants';
import style from './style.module.scss';

const UnitsDetailsBlock = (props, ref) => {

    const { handleChangeUnitDetail, getTrxKey } = props;
    const { t } = useTranslation();

    const labels = [
        { descLo: "#", descFo: "#", mandatory: false },
        { descLo: "Description", descFo: "المستأجر", mandatory: false },
        { descLo: "Floor", descFo: "الدور", mandatory: false },
        { descLo: "Usage", descFo: "الاستعمال", mandatory: false },
        { descLo: "Unit Number", descFo: "رقم الوحدة", mandatory: false },
        { descLo: "Area", descFo: "المساحة", mandatory: false },
        { descLo: "Total Rent", descFo: "فيمة إجمالي الإيجار", mandatory: false },
        { descLo: "Unit Rent", descFo: "قيمة إيجار المتر الواحد", mandatory: false },
        { descLo: " ", descFo: " ", mandatory: false },
    ];
    const numOfCells = labels.length - 2;

    const [rows, setRows] = useState([]);
    const [totals, setTotals] = useState({});

    useEffect(() => {
        refresh();
    }, [])

    const _handleDeleteLine = (seq) => {
        deleteUnitsDetail({ key: getTrxKey(), seq: seq })
            .then(() => {
                refresh();
            })
            .catch(() => { })
    }

    const _handelChangeData = (index, name, value) => {
        let newRow = rows[index];
        newRow[name] = value;
        rows[index] = newRow;
        setRows([...rows])
        _calculateTotals(rows);
    }

    const _calculateTotals = (rows) => {
        let totalArea = 0;
        let totalRent = 0;
        let totalUnitRent = 0;
        rows?.map((row) => {
            totalArea += parseFloat(row.area);
            totalRent += parseFloat(row.totalRent);
            totalUnitRent += parseFloat(row.unitRent);
        })
        setTotals({ totalArea, totalRent, totalUnitRent })
    }

    const addRow = () => {
        addUnitsDetail({ key: getTrxKey() })
            .then((response) => {
                let newRows = [...rows];
                newRows.push({ seq: response.data });
                setRows([...newRows]);
            })
            .catch(() => { })
    }

    const deleteAllRows = () => {
        deleteUnitsDetail({ key: getTrxKey(), seq: "-1" })
            .then(() => {
                setRows([]);
                _calculateTotals([]);
            })
            .catch(() => { })
    }

    const refresh = () => {
        getUnitsDetails({ key: getTrxKey() })
            .then((response) => {
                let rows = response.data || []
                setRows(rows);
                _calculateTotals(rows);
            })
            .catch(() => { })
    }

    useImperativeHandle(ref, () => ({
        addRow,
        deleteAllRows,
        refresh,
    }))

    return (
        <>
            <div className={style.classifications_labels_head}>
                <div>
                    <button className='secondary-button' onClick={addRow}> {t('add')} </button>
                    <button
                        className='delete-button'
                        onClick={deleteAllRows}
                    >
                        {t('delete_all')}
                    </button>
                </div>
            </div>
            <div className={style.table}>
                <div className={style.th} style={{ '--num-of-cells': numOfCells }}>
                    {labels.map((label, index) => {
                        return <div key={`units-details-header-${index}`} style={{ justifyContent: "center", width: index == 0 ? "5%" : "" }}>
                            <div className={label?.mandatory ? 'mandatory' : ''}>{isEnglish() ? label.descLo : label.descFo}</div>
                        </div>
                    })}
                </div>
                {rows?.map((row, index) => {
                    return (
                        <div key={`units-details-row-${index}`} className={style.tr}>
                            <div style={{ '--num-of-cells': numOfCells, justifyContent: "center", width: "5%" }}>
                                <label>{row.seq}</label>
                            </div>
                            <div style={{ '--num-of-cells': numOfCells }}>
                                <input
                                    type='text'
                                    value={row.description}
                                    title={row.description || ""}
                                    className='frm-entry-input'
                                    onChange={e => _handelChangeData(index, "description", e.target.value)}
                                    onBlur={(e) => handleChangeUnitDetail(index, "description", e.target.value)}
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells, padding: "0 2px" }}>
                                <FrmSelectMenu
                                    id={`units-details-floor-row-${index}`}
                                    value={row.floor}
                                    key={`units-details-floor-row-${index}`}
                                    api={() => getDomainValues(DOMAIN_FLOORS)}
                                    defualtValueId={row.floor}
                                    descLo={['descLo']}
                                    descFo={['descFo']}
                                    onChange={(e) => {
                                        _handelChangeData(index, "floor", e.value)
                                        handleChangeUnitDetail(index, "floor", e.value)
                                    }}
                                    portalTarget={document.body}
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells, padding: "0 2px" }}>
                                <FrmSelectMenu
                                    id={`units-details-usage-row-${index}`}
                                    value={row.usage}
                                    key={`units-details-usage-row-${index}`}
                                    api={() => getDomainValues(DOMAIN_USAGE_TRANSACTION)}
                                    defualtValueId={row.usage}
                                    descLo={['descLo']}
                                    descFo={['descFo']}
                                    onChange={(e) => {
                                        _handelChangeData(index, "usage", e.value)
                                        handleChangeUnitDetail(index, "usage", e.value)
                                    }}
                                    portalTarget={document.body}
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells }}>
                                <input
                                    type='text'
                                    value={row.unitNumber}
                                    title={row.unitNumber || ""}
                                    className='frm-entry-input'
                                    onChange={e => _handelChangeData(index, "unitNumber", e.value)}
                                    onBlur={(e) => handleChangeUnitDetail(index, "unitNumber", e.target.value)}
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells }}>
                                <DecimalInput
                                    id={`units-details-area-row-${index}`}
                                    value={row.area}
                                    className='frm-entry-input'
                                    onBlur={value => {
                                        _handelChangeData(index, "area", value)
                                        handleChangeUnitDetail(index, "area", value)
                                    }}
                                    allowDecimals
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells }}>
                                <DecimalInput
                                    id={`units-details-total-rent-row-${index}`}
                                    value={row.totalRent}
                                    className='frm-entry-input'
                                    onBlur={value => {
                                        _handelChangeData(index, "totalRent", value)
                                        handleChangeUnitDetail(index, "totalRent", value)
                                    }}
                                    allowDecimals
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells }}>
                                <DecimalInput
                                    id={`units-details-unit-rent-row-${index}`}
                                    value={row.unitRent}
                                    className='frm-entry-input'
                                    onBlur={value => {
                                        _handelChangeData(index, "unitRent", value)
                                        handleChangeUnitDetail(index, "unitRent", value)
                                    }}
                                    allowDecimals
                                />
                            </div>
                            <div style={{ '--num-of-cells': numOfCells }}>
                                <button
                                    className='delete-button'
                                    onClick={() => _handleDeleteLine(row.seq)}
                                >
                                    {t('delete')}
                                </button>
                            </div>
                        </div>
                    )
                })}
                {rows?.length > 0 && totals &&
                    <div key={`units-details-row-${rows?.length}`} className={`table-total ${style.tr}`}>
                        <div style={{ '--num-of-cells': numOfCells, width: "5%" }}></div>
                        <div style={{ '--num-of-cells': numOfCells }}></div>
                        <div style={{ '--num-of-cells': numOfCells }}></div>
                        <div style={{ '--num-of-cells': numOfCells }}></div>
                        <div style={{ '--num-of-cells': numOfCells }}></div>
                        <div style={{ '--num-of-cells': numOfCells }}>
                            {formatMoney(totals?.totalArea)}
                        </div>
                        <div style={{ '--num-of-cells': numOfCells }}>
                            {formatMoney(totals?.totalRent)}
                        </div>
                        <div style={{ '--num-of-cells': numOfCells }}>
                            {formatMoney(totals?.totalUnitRent)}
                        </div>
                        <div style={{ '--num-of-cells': numOfCells }}></div>
                    </div>
                }
            </div>
        </>
    )
}

export default forwardRef(UnitsDetailsBlock);