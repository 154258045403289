import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import Spinner from '@ipgd-gauge/spinner';
import { handleError, hideWaiting, isEmpty, isEnglish, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import UploadImg from '../../components/upload-img';
import SuccessIcon from '../../icons/Success';
import { userUpdateProfile } from '../../store/user/actions';
import { getEvaluationFunctions, getRsmEstimator, updateUserProfile } from '../../util/apis';
import { STATUS_ACTIVE, USER_PROFILE } from '../../util/constants';
import { getUser } from "../../util/cookies";
import ForgetPasswordDialog from './forgetPasswordDialog';
import style from './style.module.scss';

const Profile = () => {
    const profileImgRef = useRef();
    const { t } = useTranslation();
    const forgetPasswordDialogRef = useRef();
    const currentUser = getUser();
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        id: currentUser.id,
        nameLo: currentUser.nameLo,
        nameFo: currentUser.nameFo,
        defaultFnoId: currentUser.defaultFnoId,
        defaultEstimatorAprId: currentUser.defaultEstimatorAprId,
        email: currentUser.email,
        username: currentUser.username,
        fusId: currentUser.fusId
    })

    const _handleChange = (name, value) => {
        user[name] = value
        setUser({ ...user })
    }

    const _handleSaveProfile = () => {
        showActionWaiting("profile-save-button", true)
        updateUserProfile(user)
            .then(async res => {
                await profileImgRef.current.uploadImages(currentUser.fusId);
                let newUser = { ...currentUser, ...res.data };
                newUser.en = isEnglish();
                newUser.allowedForms = currentUser.allowedForms;
                dispatch(userUpdateProfile(newUser));
                showSuccessMessage("save_successfully", t);
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideWaiting("profile-save-button"))
    }

    return (
        <div className={style.container}>
            <div className={style.profileContent}>
                <div className={style.headerProf}>
                    <section className={style.profileImg}>
                        <UploadImg
                            ref={profileImgRef}
                            isProfile={true}
                            formId={USER_PROFILE}
                            masterId={currentUser.fusId}
                        />
                        <div className={style.userNameSection}>
                            <label className={style.userName}>{isEnglish() ? user.nameLo : user.nameFo}</label>
                        </div>
                    </section>
                </div>
                <div className={style.profileInfo}>
                    <div className='container'>
                        <div className='row  rowSmModel'>
                            <div className="w50">
                                <label className='form-label'>{t('en_name')}</label>
                                <FrmInput
                                    id="en_name"
                                    value={user.nameLo}
                                    onBlur={(value) => _handleChange("nameLo", value)}
                                />
                            </div>
                            <div className="w50">
                                <label className='form-label'>{t('ar_name')}</label>
                                <FrmInput
                                    id="ar_name"
                                    value={user.nameFo}
                                    onBlur={(value) => _handleChange("nameFo", value)}
                                />
                            </div>
                            <div className="w50">
                                <label className='form-label'>{t('email')}</label>
                                <FrmInput
                                    id="email"
                                    name={"username"}
                                    value={user.email}
                                    onBlur={(value) => _handleChange("email", value)}
                                />
                            </div>
                            <div className="w50">
                                <label className='form-label'>{t('user_name')}</label>
                                <FrmInput
                                    id="profile_user_name"
                                    value={user.username}
                                    disabled
                                />
                            </div>
                            <div className="w50">
                                <label className='form-label'>{t('estimator')}</label>
                                <FrmSelectMenu
                                    id="profile_estimator"
                                    api={getRsmEstimator}
                                    descLo={['nameLo']}
                                    descFo={['nameFo']}
                                    value={user.defaultEstimatorAprId}
                                    onChange={(value) => _handleChange("defaultEstimatorAprId", value.value)}
                                    portalTarget={document.body}
                                />
                            </div>

                            <div className="w50">
                                <label className='form-label'>{t('workflow')}</label>
                                <FrmSelectMenu
                                    id="profile_workflow"
                                    key={`profile_workflow-${user?.id}`}
                                    api={() => getEvaluationFunctions(isEmpty(user.defaultFnoId) ? [] : [user.defaultFnoId], null, STATUS_ACTIVE, null, true)}
                                    descLo={['descLo']}
                                    descFo={['descFo']}
                                    value={user.defaultFnoId}
                                    onChange={(value) => _handleChange("defaultFnoId", value.value)}
                                    portalTarget={document.body}
                                />
                            </div>
                            <div className="w50">
                                <div className={style.changepasswordContainer}>
                                    <label>{t('change_password')}</label>
                                    <button onClick={() => forgetPasswordDialogRef?.current?.open(currentUser?.fusId)}>{t('change')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w100">
                        <div className={style.confirmBtn}>
                            <button onClick={_handleSaveProfile} className='secondary-button'>
                                <Spinner id={`profile-save-button`} className={style.saveSpinner} />
                                <span>
                                    <SuccessIcon />
                                </span>
                                {t('ok')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ForgetPasswordDialog
                ref={forgetPasswordDialogRef}
                withCurrentPassword={true}
            />
        </div>
    );
}

export default Profile;