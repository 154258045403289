import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deletePropertyType, getPropertyTypeList } from "../../../util/apis";
import { RSM2659_PROPERTIES_TYPES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";

const Row = (props) => {
    const { data, rowActions } = props;
    const { dialogRef, deleteAction } = rowActions;

    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesTypesRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data?.code} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesTypes:code')}</label>
                <label>{data?.code}</label>
            </div>
            <div title={isEnglish() ? data?.descLo : data?.descFo} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesTypes:name')}</label>
                <label>{isEnglish() ? data?.descLo : data?.descFo}</label>
            </div>
            <div title={Number(data.isGeneral) == 1 ? t('propertiesTypes:all_organization') : t('propertiesTypes:specific_organization')} className="overflowTableField cardRowMode">
                <label className="nameLblMobile">{t('propertiesTypes:organization')}</label>
                <label>{Number(data.isGeneral) == 1 ? t('propertiesTypes:all_organization') : t('propertiesTypes:specific_organization')}</label>
            </div>
            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('propertiesTypes:status')}</label>
                <label className={`status-${data.isActive}`}>
                    {isEnglish() ? data?.statusDescLo : data?.statusDescFo}
                </label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(getUser(), RSM2659_PROPERTIES_TYPES_FORM_ID, VIEW_EDIT_ACTION) && <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />}
                    {isAllowedAction(getUser(), RSM2659_PROPERTIES_TYPES_FORM_ID, DELETE_ACTION) && <DeleteBtn onClick={() => deleteAction(data?.id)} />}
                </div>
            </div>
        </div>
    );
};

const PropertiesTypesTable = (props) => {
    const { tableRef, filterRef, searchTextRef, dialogRef } = props;

    const { t } = useTranslation();

    const [propertiesTypes, setPropertiesTypes] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesTypes:code'), width: "15%" },
        { title: t('propertiesTypes:name'), width: "25%" },
        { title: t('propertiesTypes:organization'), width: "20%" },
        { title: t('propertiesTypes:status'), width: "20%" },
        { title: '', width: "15%" },
    ]

    const _fetchData = (page, size) => {
        getPropertyTypeList(page, size, searchTextRef.current.getSearchText(), filterRef.current?.getFilterData())
            .then((response) => setPropertiesTypes(response.data))
            .catch((error) => handleError(error, null, t));
    };

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deletePropertyType(id)
                .then(() => {
                    showSuccessMessage("delete_successfully", t);
                    tableRef?.current?.refresh();
                })
                .catch(() => handleError(null, "delete_fields", t));
        });
    }

    return (
        <div className={style.propertiesTypesTableContainer}>
            <Slisting
                id="properties-types-table"
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.propertiesTypesTable}
                pagingData={propertiesTypes}
                getData={_fetchData}
                rowActions={{ dialogRef, deleteAction: _deleteAction }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => data && data.length == 0}
                user={getUser()}
                formId={RSM2659_PROPERTIES_TYPES_FORM_ID}
                viewAction={(id) => dialogRef.current?.open(id)}
                deleteAction={deletePropertyType}
                newAction={() => dialogRef.current?.open()}
                cardInMobile
                labelCardWidth="80px"
                withFilter
            />
        </div>
    );
};

export default PropertiesTypesTable;