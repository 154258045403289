import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteEstimationMessageTemplt, getEstimationMessageTempltList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, dialogRef, deleteAction } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.estimationMessagesTemplatesRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationMessagesTemplates:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish()? data?.descLo: data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('name')}</label>
                <label>{isEnglish()? data?.descLo: data.descFo}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('cities:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const EstimationMessagesTemplatesTable = (props) => {
    const { user, tableRef, searchTextRef, filterRef, dialogRef } = props;

    const { t } = useTranslation();

    const [messagesTemplates, setMessagesTemplates] = useState({});

    const columns = [
        { title: '#', width: "5%" },
        { title: t('estimationMessagesTemplates:code'), width: "17%" },
        { title: t('name'), width: "29%" },
        { title: t('estimationMessagesTemplates:status'), width: "28%" },
        { title: "", width: "21%" },
    ];

    const _fetchData = (page, size) => {
        getEstimationMessageTempltList(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setMessagesTemplates(response.data))
            .catch((error) => handleError(error, null, t))
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (isNotEmpty(id)) {
                return deleteEstimationMessageTemplt(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, "delete_fields", t));
            }
        });
    }

    return (
        <>
            <Slisting
                id="estimation-messages-templates-table"
                ref={tableRef}
                formId={RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID}
                columns={columns}
                Row={Row}
                tableStyle={style.estimationMessagesTemplatesTable}
                getData={_fetchData}
                pagingData={messagesTemplates}
                rowActions={{ user, dialogRef, deleteAction: _deleteAction }}
                withFilter
                cardInMobile
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                newAction={() => dialogRef.current?.open()}
                deleteAction={deleteEstimationMessageTemplt}
                viewAction={(id) => dialogRef.current?.open(id)}
                labelCardWidth="80px"
            />
        </>
    );
};

export default EstimationMessagesTemplatesTable;