import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { getUrlParam, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { getDomainValues, getEvaluationPolicyFunctions } from "../../../../../util/apis";
import { POLICY_LVL_DOMAIN } from "../../../../../util/constants";

const commonId = "evaluation-policy-";

const FormAndPolicy = (props) => {
    const { data, handleDataChanged } = props;

    const { t } = useTranslation();
    const retrievePolicyLevelFirstTime = useRef(true);
    const retrieveFunctionFirstTime = useRef(true);

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('propertiesPolicies:form_and_policy')}
                    </label>
                </div>
            </div>
            <div className='container'>
                <div className='row rowSmModel'>
                    <div className="w50">
                        <label id={`${commonId}policy-level-lbl`} className='form-label mandatory'>{t('propertiesPolicies:policy_level')}</label>
                        <FrmSelectMenu
                            id={`${commonId}policy-level`}
                            key={`${commonId}policy-level-${data?.id}`}
                            value={data?.usageLevel}
                            onChange={value => handleDataChanged("usageLevel", value)}
                            api={() => getDomainValues(POLICY_LVL_DOMAIN)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            isDisabled={isNotEmpty(data?.id)}
                            onDataRetrieved={(list) => {
                                if (isNotEmpty(getUrlParam("id")) && isNotEmpty(data.usageLevel) && retrievePolicyLevelFirstTime.current) {
                                    list?.forEach((d) => {
                                        if (d.id == data.usageLevel) {
                                            handleDataChanged("usageLevelData", d);
                                            return;
                                        }
                                    })
                                }
                                if (retrievePolicyLevelFirstTime.current && data?.id) {
                                    retrievePolicyLevelFirstTime.current = false;
                                }
                            }}
                            portalTarget={document.body}
                        />
                    </div>

                    <div className="w50">
                        <label id={`${commonId}form-lbl`} className='form-label mandatory'>{t('propertiesPolicies:function')}</label>
                        <FrmSelectMenu
                            id={`${commonId}form`}
                            key={data.id}
                            value={data?.ipeId}
                            onChange={value => handleDataChanged("ipeId", value)}
                            api={() => getEvaluationPolicyFunctions(data?.ipeId)}
                            descLo={['descLo']}
                            descFo={['descFo']}
                            onDataRetrieved={(list) => {
                                const id = getUrlParam("id");
                                if (isNotEmpty(id) && isNotEmpty(data.ipeId) && retrieveFunctionFirstTime.current && !data.function) {
                                    list?.forEach((d) => {
                                        if (d.id == data.ipeId) {
                                            handleDataChanged("function", { ...d });
                                            return;
                                        }
                                    })
                                }
                                if (retrieveFunctionFirstTime.current && data?.id) {
                                    retrieveFunctionFirstTime.current = false;
                                }
                            }}
                            portalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormAndPolicy;