import AdvanceFilterComponent from "@ipgd-gauge/advance-filter";
import { forwardRef } from "react";
import { saveFormsFilter } from "../../store/formsFilter/actions";
import { deleteFilter, getFilters, saveFilter, updateFilter } from "../../util/apis";

const AdvanceFilter = (props, ref) => {

    const { id, children, searchAction, className, closeAction, clearAction, filterData, setFilterData, getDefaultFilter, disableSearch, searchBtnLabel, disabledFirstRefresh, numOfRows, hideMoreOptions, childrenContainerStyle, formId, postDefaultFilterRetrieved, includeDefaultFilter } = props;

    return (
        <AdvanceFilterComponent
            ref={ref}
            id={id}
            children={children}
            searchAction={searchAction}
            className={className}
            closeAction={closeAction}
            clearAction={clearAction}
            filterData={filterData}
            setFilterData={setFilterData}
            getDefaultFilter={getDefaultFilter}
            disableSearch={disableSearch}
            searchBtnLabel={searchBtnLabel}
            disabledFirstRefresh={disabledFirstRefresh}
            numOfRows={numOfRows}
            hideMoreOptions={hideMoreOptions}
            childrenContainerStyle={childrenContainerStyle}
            saveFormsFilter={saveFormsFilter}
            deleteFilterApi={deleteFilter}
            getFiltersApi={getFilters}
            saveFilterApi={saveFilter}
            updateFilterApi={updateFilter}
            formId={formId}
            postDefaultFilterRetrieved={postDefaultFilterRetrieved}
            includeDefaultFilter={includeDefaultFilter}
        />
    )
}


export default forwardRef(AdvanceFilter);