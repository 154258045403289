import ActionsButton from "@ipgd-gauge/actions-button";
import { handleError, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ChartCard from "../../components/chart-card";
import BarWithLineChart from "../../components/chart/BarWithLine";
import DoughnutChart from "../../components/chart/Doughnut";
import PieChart from "../../components/chart/PieChart";
import PolarChart from "../../components/chart/PolarChart";
import FormTitle from "../../components/form-title";
import FiltersIcon from "../../icons/Filters";
import MyTasksIcon from "../../icons/MyTasks";
import NumberDelayedTasksIcon from "../../icons/NumberDelayedTasks";
import PropertyTypeIcon from "../../icons/PropertyType";
import ReloadDashBordIcon from "../../icons/ReloadDashBord";
import TaskDescriptionIcon from "../../icons/TaskDescription";
import { getNumberOfDelayedTasksDashboard, getPropertyTypeDashboard, getTaskDashboard, getTaskDescriptionDashboard } from "../../util/apis";
import { POLICY_LVL_SELF_SERVICE } from "../../util/constants";
import { RSM3240_NOT_EXECUTED_TASKS_FORM_ID } from "../../util/constants/forms";
import { calculateCount, hideChartWaiting, showChartWaiting } from "../../util/util";
import NotExecutedTasksFilter from './Filters';
import style from './style.module.scss';

const NotExecutedTasks = () => {
    const { t } = useTranslation('notExecutedTasks');

    const DEFAULT_VALUES = {
        task: [],
        numberOfDelayedTasks: [],
        taskDescription: [],
        propertyType: [],
    };

    const [data, setData] = useState(DEFAULT_VALUES);

    const user = useSelector(state => state.persistedReducer.userReducer.user);

    const filterRef = useRef();

    const selectedTaskId = useRef(null);
    const includedTaskIds = useRef([]);

    const selectedNumberOfDelayedTasksId = useRef(null);
    const includedNumberOfDelayedTasksIds = useRef([]);

    const selectedTaskDescriptionId = useRef(null);
    const includedTaskDescriptionIds = useRef([]);

    const selectedPropertyTypeId = useRef(null);
    const includedPropertyTypeIds = useRef([]);

    const _handleDataChange = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _addValueToLabels = (list) => {
        for (let index = 0; index < list.length; index++) {
            list[index].descLo += (' (' + list[index].count + ')');
            list[index].descFo += isIOS ? (' (' + list[index].count + ')') : (' )' + list[index].count + '(');
        }

        return list;
    }

    const _getTaskDashboard = (tempFilterData) => {
        showChartWaiting("task", false);
        getTaskDashboard(tempFilterData)
            .then((response) => {
                let data = response.data;
                // because font family in arabic
                if (isIOS) {
                    data?.map((d) => {
                        d.descFo = d.descFo.replace(')', '#')
                        d.descFo = d.descFo.replace('(', ')')
                        d.descFo = d.descFo.replace('#', '(')
                    })
                }
                _handleDataChange("task", data)
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("task"));
    }

    const _getNumberOfDelayedTasksDashboard = (tempFilterData) => {
        showChartWaiting("numberOfDelayedTasks", false);
        getNumberOfDelayedTasksDashboard(tempFilterData)
            .then((response) => {
                let data = response.data;
                // because font family in arabic
                if (isIOS) {
                    response.data?.map((d) => {
                        d.descFo = d.descFo.replace(')', '#')
                        d.descFo = d.descFo.replace('(', ')')
                        d.descFo = d.descFo.replace('#', '(')
                    })
                }
                _handleDataChange("numberOfDelayedTasks", data)
            })
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("numberOfDelayedTasks"));
    }

    const _getTaskDescriptionDashboard = (tempFilterData) => {
        showChartWaiting("taskDescription", false);
        getTaskDescriptionDashboard(tempFilterData)
            .then((response) => _handleDataChange("taskDescription", _addValueToLabels(response.data)))
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("taskDescription"));
    }

    const _getPropertyTypeDashboard = (tempFilterData) => {
        showChartWaiting("propertyType", false);
        getPropertyTypeDashboard(tempFilterData)
            .then((response) => _handleDataChange("propertyType", _addValueToLabels(response.data)))
            .catch((error) => handleError(error, null, t))
            .finally(() => hideChartWaiting("propertyType"));
    }

    const _handleSelect = (name, id) => {
        if (name === "task") {
            if (isEmpty(id) || selectedTaskId.current == id) {
                return;
            }
            selectedTaskId.current = id;
        } else if (name === "numberOfDelayedTasks") {
            if (isEmpty(id) || selectedNumberOfDelayedTasksId.current == id) {
                return;
            }
            selectedNumberOfDelayedTasksId.current = id;
        } else if (name === "taskDescription") {
            if (isEmpty(id) || selectedTaskDescriptionId.current == id) {
                return;
            }
            selectedTaskDescriptionId.current = id;
        } else if (name === "propertyType") {
            if (isEmpty(id) || selectedPropertyTypeId.current == id) {
                return;
            }
            selectedPropertyTypeId.current = id;
        }

        _fetchData();
    }

    const _handleClearIds = (name) => {
        if (name === "task") {
            selectedTaskId.current = null;
            includedTaskIds.current = null;
        } else if (name === "numberOfDelayedTasks") {
            selectedNumberOfDelayedTasksId.current = null;
            includedNumberOfDelayedTasksIds.current = null;
        } else if (name === "taskDescription") {
            selectedTaskDescriptionId.current = null;
            includedTaskDescriptionIds.current = null;
        } else if (name === "propertyType") {
            selectedPropertyTypeId.current = null;
            includedPropertyTypeIds.current = null;
        }

        _fetchData();
    }

    const canResetAll = () =>
        (isNotEmpty(selectedTaskId.current) || (includedTaskIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedNumberOfDelayedTasksId.current) || (includedNumberOfDelayedTasksIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedTaskDescriptionId.current) || (includedTaskDescriptionIds.current || [])?.length > 0) ||
        (isNotEmpty(selectedPropertyTypeId.current) || (includedPropertyTypeIds.current || [])?.length > 0)

    const resetDashboard = () => {
        selectedTaskId.current = null;
        includedTaskIds.current = null;
        selectedNumberOfDelayedTasksId.current = null;
        includedNumberOfDelayedTasksIds.current = null;
        selectedTaskDescriptionId.current = null;
        includedTaskDescriptionIds.current = null;
        selectedPropertyTypeId.current = null;
        includedPropertyTypeIds.current = null;
        _fetchData();
    }

    const _getFilterdList = (origin, id, includedIds, filter) => {
        if (isNotEmpty(id)) {
            if (typeof id == "object") {
                return id
            }
            return [id];
        } else if (includedIds?.length > 0 && !origin) {
            return includedIds;
        } else {
            return filter || [];
        }
    }

    const _fetchData = () => {
        const filterLists = filterRef.current.getFilterData();

        const tempIds = {
            tasks: _getFilterdList(false, selectedTaskId.current, includedTaskIds.current || [], []),
            numberOfDelayedTasks: _getFilterdList(false, selectedNumberOfDelayedTasksId.current, includedNumberOfDelayedTasksIds.current || [], []),
            taskDescriptionIds: _getFilterdList(false, selectedTaskDescriptionId.current, includedTaskDescriptionIds.current || [], []),
            propertyTypeIds: _getFilterdList(false, selectedPropertyTypeId.current, includedPropertyTypeIds.current || [], []),
        };

        const tempFilterData = { ...{ users: filterLists }, ...tempIds };
        const tasksFilterData = { ...tempFilterData, ...{ tasks: _getFilterdList(true, selectedTaskId.current, includedTaskIds.current || [], []) } };
        const numberOfDelayedFilterData = { ...tempFilterData, ...{ numberOfDelayedTasks: _getFilterdList(true, selectedNumberOfDelayedTasksId.current, includedNumberOfDelayedTasksIds.current || [], []) } };
        const taskDescriptionFilterData = { ...tempFilterData, ...{ taskDescriptionIds: _getFilterdList(true, selectedTaskDescriptionId.current, includedTaskDescriptionIds.current || [], []) } };
        const propertyTypeFilterData = { ...tempFilterData, ...{ propertyTypeIds: _getFilterdList(true, selectedPropertyTypeId.current, includedPropertyTypeIds.current || [], []) } };

        _getTaskDashboard(tasksFilterData);
        _getNumberOfDelayedTasksDashboard(numberOfDelayedFilterData);
        _getTaskDescriptionDashboard(taskDescriptionFilterData);
        _getPropertyTypeDashboard(propertyTypeFilterData);
    }

    // useEffect(_fetchData, []);

    const getActions = () => {
        const actions = [];
        if (canResetAll()) {
            actions.push({ id: "reload_all_dashboard_data", icon: <ReloadDashBordIcon />, label: t('reset_all_dashboards'), action: resetDashboard })
        }
        return actions;
    }

    return (
        <>
            <div className={`headerPagesPanel ${style.headerPages} `}>
                <div className={style.headerPanel}>
                    <FormTitle formId={RSM3240_NOT_EXECUTED_TASKS_FORM_ID} />
                    <div className={"dashboard-header-actions"}>
                        {user.estimationPolicy.lsoUsageLevel != POLICY_LVL_SELF_SERVICE &&
                            <button
                                className='headerPanelBtn'
                                title={t('filter')}
                                aria-label={t('filter')}
                                onClick={() => filterRef.current?.open()} >
                                <FiltersIcon />
                            </button>}
                    </div>
                </div>
            </div>
            <div className={`form-templates-panel ${style.noTasksStatisticsContainer}`}>
                <div className="container">
                    <div className={`row ${style.smContainer}`}>
                        <ChartCard
                            id="task"
                            title={t('my_tasks')}
                            icons={<MyTasksIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedTaskId}
                            includedIds={includedTaskIds}
                            count={calculateCount(data.task || [], includedTaskIds.current || [])}
                            chartName="task"
                        >
                            <PolarChart
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                selectedId={selectedTaskId}
                                list={data.task || []}
                                selectedTaskId={selectedTaskId}
                                includedIds={includedTaskIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}

                                onSelect={(id) => _handleSelect('task', id)}
                            />
                        </ChartCard>
                        <ChartCard
                            id="numberOfDelayedTasks"
                            icons={<NumberDelayedTasksIcon />}
                            title={t("number_of_delayed_tasks")}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedNumberOfDelayedTasksId}
                            includedIds={includedNumberOfDelayedTasksIds}
                            count={calculateCount(data.numberOfDelayedTasks || [], includedNumberOfDelayedTasksIds.current || [])}
                            chartName="numberOfDelayedTasks"
                        >
                            <BarWithLineChart
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                selectedId={selectedNumberOfDelayedTasksId}
                                list={data.numberOfDelayedTasks || []}
                                includedIds={includedNumberOfDelayedTasksIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('numberOfDelayedTasks', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="taskDescription"
                            title={t("task_description")}
                            icons={<TaskDescriptionIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedTaskDescriptionId}
                            includedIds={includedTaskDescriptionIds}
                            count={calculateCount(data.taskDescription || [], includedTaskDescriptionIds.current || [])}
                            chartName="taskDescription"
                        >
                            <PieChart
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                valueName="count"
                                list={data.taskDescription || []}
                                selectedId={selectedTaskDescriptionId}
                                includedIds={includedTaskDescriptionIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('taskDescription', id)}
                            />
                        </ChartCard>

                        <ChartCard
                            id="propertyType"
                            title={t("property_type")}
                            icons={<PropertyTypeIcon />}
                            handleClearIds={_handleClearIds}
                            selectedId={selectedPropertyTypeId}
                            includedIds={includedPropertyTypeIds}
                            count={calculateCount(data.propertyType || [], includedPropertyTypeIds.current || [])}
                            chartName="propertyType"
                        >
                            <DoughnutChart
                                idName="id"
                                labelNameLo="descLo"
                                labelNameFo="descFo"
                                selectedId={selectedPropertyTypeId}
                                valueName="count"
                                list={data.propertyType || []}
                                includedIds={includedPropertyTypeIds}
                                onLegendClick={(chart, evt, legendItem) => _fetchData()}
                                onSelect={(id) => _handleSelect('propertyType', id)}
                            />
                        </ChartCard>
                    </div>
                </div>
            </div>

            <NotExecutedTasksFilter ref={filterRef} fetchData={_fetchData} />
            <ActionsButton actions={getActions()} />
        </>
    );
}

export default NotExecutedTasks;