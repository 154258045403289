export const ACTION_SAVE_SELECTED_RECORDS = 'SAVE_SELECTED_RECORDS';
export const ACTION_SAVE_CURRENT_RECORD_ID = 'SAVE_CURRENT_RECORD_ID';
export const ACTION_CLEAR_SELECTED_RECORDS = 'CLEAR_SELECTED_RECORDS';

export const saveSelectedRecords = (formId, data) => ({
    type: ACTION_SAVE_SELECTED_RECORDS,
    formId: formId,
    data: data
});

export const saveCurrentRecordId = (formId, id) => ({
    type: ACTION_SAVE_CURRENT_RECORD_ID,
    formId: formId,
    id: id
});

export const clearSelectedRecords = (formId) => ({
    type: ACTION_CLEAR_SELECTED_RECORDS,
    formId: formId
});