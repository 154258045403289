import React from "react";

function EstimationMapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.21"
      height="18.834"
      viewBox="0 0 21.21 18.834"
    >
      <g
        fill="#088587"
        data-name="Group 177930"
        transform="translate(-1528.54 -145.588)"
      >
        <path
          d="M6.343-.75a2.818 2.818 0 011.217.259l5.25 2.62h.006a1.267 1.267 0 00.539.1A1.392 1.392 0 0014 2.1L16.348.761a3.407 3.407 0 011.69-.5c1.449 0 2.422 1.161 2.422 2.889v9.73a2.975 2.975 0 01-1.383 2.388l-4.33 2.48a3.009 3.009 0 01-2.593.073h-.006l-5.25-2.63a1.267 1.267 0 00-.539-.1 1.392 1.392 0 00-.64.138l-2.349 1.34a3.407 3.407 0 01-1.69.5C.223 17.061-.75 15.9-.75 14.172v-9.73A2.948 2.948 0 01.638 2.061l4.329-2.48A2.824 2.824 0 016.343-.75zM6.9.854a1.354 1.354 0 00-.555-.1 1.379 1.379 0 00-.632.134l-4.33 2.475A1.467 1.467 0 00.75 4.442v9.73c0 .417.09 1.389.922 1.389a1.976 1.976 0 00.945-.3l2.351-1.341a2.839 2.839 0 011.382-.335 2.735 2.735 0 011.218.267l5.244 2.627a1.353 1.353 0 00.555.1A1.38 1.38 0 0014 16.45l4.325-2.477a1.522 1.522 0 00.636-1.091v-9.73c0-.417-.09-1.388-.922-1.388a1.976 1.976 0 00-.945.3L14.742 3.4a2.838 2.838 0 01-1.382.335 2.736 2.736 0 01-1.216-.266z"
          transform="translate(1529.29 146.338)"
        ></path>
        <path
          d="M0 13.75A.75.75 0 01-.75 13V0A.75.75 0 010-.75.75.75 0 01.75 0v13a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(1535.56 147)"
        ></path>
        <path
          d="M0 14.13a.75.75 0 01-.75-.75V0A.75.75 0 010-.75.75.75 0 01.75 0v13.38a.75.75 0 01-.75.75z"
          data-name="Vector"
          opacity="0.4"
          transform="translate(1542.73 149.62)"
        ></path>
      </g>
    </svg>
  );
}

export default EstimationMapIcon;