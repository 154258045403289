import { useEffect, useState } from 'react';
import style from './style.module.scss'

const Progress = (props) => {

    const { label, value, prec, color, classStyle, customValueComponent } = props;
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        if (prec) {
            const interval = setInterval(() => { setCompleted(prec) }, 100);
            return () => clearInterval(interval);
        } else {
            setCompleted(0)
        }
    }, [prec]);

    return (
        <div className={`${style.mainProgress} ${classStyle}`}>
            <div className={style.progressLabels}>
                <label className={style.ProgLabel}>{label}</label>
                <label className={style.ProgLabel}>{customValueComponent || value}%</label>
            </div>
            <div className={`${style.progress} progress-bar`}>
                <div className={style.bar} style={{ width: `${completed}%`, backgroundColor: color || 'var(--primary-color)' }}></div>
            </div>
        </div>
    )
}

export default Progress