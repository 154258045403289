import { confirmationDialog, isEnglish, isNotEmpty } from '@ipgd-gauge/utils';
import i18next from 'i18next';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../../components/loading-spinner';
import ActionTableIcon from '../../../icons/ActionTable';
import EditTableIcon from '../../../icons/EditTable';
import { CancelTrxIcon, ChangePriorityTrxIcon, CommentTrxIcon, DivertIcon, EstimationQualityTrxIcon, HangUpTrxIcon, InspectionEstimationIcon, InspectionEstimationTrxIcon, LogTrxIcon, PdfTrxIcon, RecoveryTrxIcon, ResumeTrxIcon, SendMessageTrxIcon, ViewEstimationFormIcon, WorkflowStatusIcon } from "../../../icons/EstimationTrx";
import EyeIcon from '../../../icons/Eye';
import NewWordIcon from '../../../icons/NewWord';
import NoteIcon from '../../../icons/Note';
import QrCodeIcon from '../../../icons/QrCode';
import ViewIcon from '../../../icons/View';
import { ACTION_ADD_QR_CODE, ACTION_CANCEL, ACTION_CHANGE_PRIORITY, ACTION_COMMENT, ACTION_DIVERT_TO_OTHER_ESTIMATOR, ACTION_DIVERT_TO_OTHER_INSPECTOR, ACTION_EDIT_TRNX, ACTION_ESTIMATION, ACTION_ESTIMATION_QUALITY, ACTION_HANG_UP, ACTION_LOG, ACTION_PRINT_PDF, ACTION_PRINT_WORD, ACTION_RECOVERY, ACTION_RESUME, ACTION_SEND_MESSAGE, ACTION_VIEW, ACTION_VIEW_ESTIMATION_FORM, ACTION_VIEW_INSPECTION_FORM, ACTION_VIEW_REVISION_FORM, ACTION_WORKFLOW_STATUS, ESTIMATION_STATUS_CANCELLED, ESTIMATION_STATUS_DRAFT, ESTIMATION_STATUS_HANGED_UP, ESTIMATION_STATUS_INCOMPLETE_TRANSACTION, ESTIMATION_STATUS_PRINTED, ESTIMATION_STATUS_REJECTED, ESTIMATION_STATUS_REVIEWED, ESTIMATION_STATUS_UNDER_ESTIMATION_PHASE } from '../../../util/constants';
import style from './style.module.scss';

const ActionsBtn = (props) => {
    const { user, data, msgDialogRef, HangUpDialogRef, changePriorityDialog, divertToEstimatorDialogRef, executeListAction, workFlowStatusDialogRef, chooseReportDialogRef, estimationRatingDialogRef, recoveryDialogRef } = props;

    const { t } = useTranslation('estimationTransaction');
    const navigate = useNavigate();
    const groupNum = useRef(1);

    const actions = [
        { id: ACTION_EDIT_TRNX, icon: <EditTableIcon />, label: t('edit'), group: 1 },
        { id: ACTION_VIEW, icon: <ViewIcon />, label: t('view'), group: 1 },

        { id: ACTION_PRINT_PDF, icon: <PdfTrxIcon />, label: t('print_pdf'), group: 2 },
        { id: ACTION_PRINT_WORD, icon: <NewWordIcon />, label: t('print_word'), group: 2 },
        { id: ACTION_COMMENT, icon: <CommentTrxIcon />, label: t('comment'), group: 2 },

        { id: ACTION_LOG, icon: <LogTrxIcon />, label: t('log'), group: 3 },
        { id: ACTION_WORKFLOW_STATUS, icon: <WorkflowStatusIcon />, label: t('workflow_status'), group: 3 },

        { id: ACTION_VIEW_INSPECTION_FORM, icon: <InspectionEstimationTrxIcon />, label: t('view_inspection_form'), group: 4 },
        { id: ACTION_VIEW_ESTIMATION_FORM, icon: <ViewEstimationFormIcon />, label: t('view_estimation_form'), group: 4 },
        { id: ACTION_VIEW_REVISION_FORM, icon: <ViewEstimationFormIcon />, label: t('view_revision_form'), group: 4 },

        { id: ACTION_RECOVERY, icon: <RecoveryTrxIcon />, label: t('recovery'), group: 5 },
        { id: ACTION_HANG_UP, icon: <HangUpTrxIcon />, label: t('hang_up'), group: 5 },
        { id: ACTION_RESUME, icon: <ResumeTrxIcon />, label: t('resume'), group: 5 },
        { id: ACTION_CANCEL, icon: <CancelTrxIcon />, label: t('cancel'), group: 5 },

        { id: ACTION_SEND_MESSAGE, icon: <SendMessageTrxIcon />, label: t('send_message'), group: 6 },
        { id: ACTION_ESTIMATION_QUALITY, icon: <EstimationQualityTrxIcon />, label: t('estimation_quality'), group: 6 },
        { id: ACTION_DIVERT_TO_OTHER_INSPECTOR, icon: <DivertIcon />, label: t('divert_to_other_inspector'), group: 6 },
        { id: ACTION_DIVERT_TO_OTHER_ESTIMATOR, icon: <DivertIcon />, label: t('divert_to_other_estimator'), group: 6 },
        { id: ACTION_CHANGE_PRIORITY, icon: <ChangePriorityTrxIcon />, label: t('change_priority'), group: 6 },

        { id: ACTION_ESTIMATION, icon: <InspectionEstimationIcon />, label: t('inspection_estimation') },
    ];

    const [actionsList, setActionsList] = useState([]);

    useEffect(() => {
        const allowedActions = [];

        actions.map(action => {
            if (isAllowedAction(action.id)) {
                allowedActions.push(action);
            }
        })

        setActionsList(allowedActions);
    }, [data.id, i18next.language]);

    const isAllowedAction = (action) => {
        if (user.estimationPolicy?.actions?.indexOf(`${action}`) !== -1) {
            switch (action) {
                case ACTION_EDIT_TRNX:
                    return data.status == ESTIMATION_STATUS_DRAFT || data.status == ESTIMATION_STATUS_INCOMPLETE_TRANSACTION;
                case ACTION_VIEW:
                case ACTION_PRINT_PDF:
                case ACTION_PRINT_WORD:
                case ACTION_LOG:
                case ACTION_COMMENT:
                case ACTION_ESTIMATION_QUALITY:
                case ACTION_WORKFLOW_STATUS:
                case ACTION_CHANGE_PRIORITY:
                    return true;
                case ACTION_ESTIMATION:
                    // estTrxListController.currentUserInspector!=null and estTrxListController.currentUserInspector.equals(estTable['TRI_INSPECTOR_APR_ID'])
                    return data.status == 285403;
                case ACTION_VIEW_INSPECTION_FORM:
                    return data.status != ESTIMATION_STATUS_INCOMPLETE_TRANSACTION && data.status != 285403;
                case ACTION_CANCEL:
                    return data.status == ESTIMATION_STATUS_UNDER_ESTIMATION_PHASE || data.status == ESTIMATION_STATUS_REVIEWED;
                case ACTION_RECOVERY:
                case ACTION_HANG_UP:
                    return data.status == ESTIMATION_STATUS_UNDER_ESTIMATION_PHASE || data.status == ESTIMATION_STATUS_REJECTED || data.status == ESTIMATION_STATUS_REVIEWED || data.status == ESTIMATION_STATUS_PRINTED || data.status == ESTIMATION_STATUS_CANCELLED;
                case ACTION_RESUME:
                    return data.status == ESTIMATION_STATUS_HANGED_UP;
                case ACTION_SEND_MESSAGE:
                case ACTION_DIVERT_TO_OTHER_INSPECTOR:
                case ACTION_DIVERT_TO_OTHER_ESTIMATOR:
                    return data.code;
                case ACTION_VIEW_ESTIMATION_FORM:
                case ACTION_VIEW_REVISION_FORM:
                    return data.status != ESTIMATION_STATUS_DRAFT && data.status != ESTIMATION_STATUS_INCOMPLETE_TRANSACTION && data.status != 285403;
            }
        }
        return false;
    }

    const _executeAction = (action) => {
        if (action == ACTION_CANCEL) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () =>
                executeListAction(action, data.id, {})
            );
        } else if (action == ACTION_SEND_MESSAGE) {
            msgDialogRef?.current?.open(null, data.id, data.fnoId)
        } else if (action == ACTION_HANG_UP) {
            HangUpDialogRef?.current?.open(data.id);
        } else if (action == ACTION_CHANGE_PRIORITY) {
            changePriorityDialog?.current?.open(data.id);
        } else if (action == ACTION_DIVERT_TO_OTHER_ESTIMATOR || action == ACTION_DIVERT_TO_OTHER_INSPECTOR) {
            divertToEstimatorDialogRef?.current?.open(data, action);
        } else if (action == ACTION_WORKFLOW_STATUS) {
            workFlowStatusDialogRef?.current?.open(data.id);
        } else if (action == ACTION_PRINT_WORD) {
            chooseReportDialogRef?.current?.open(ACTION_PRINT_WORD, data.id, data.eriId, data.code);
        } else if (action == ACTION_PRINT_PDF) {
            chooseReportDialogRef?.current?.open(ACTION_PRINT_PDF, data.id, data.eriId, data.code);
        } else if (action == ACTION_RESUME) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return executeListAction(action, data.id, { sseId: data.taskId })
            });
        } else if (action == ACTION_RECOVERY) {
            recoveryDialogRef?.current?.open(data.id);
        } else if (action == ACTION_ESTIMATION_QUALITY) {
            estimationRatingDialogRef?.current?.open(data.id);
        } else if (action == ACTION_COMMENT) {
            navigate(`/trx-summary?id=${data.id}&mode=note`);
        } else if (action == ACTION_LOG) {
            navigate(`/trx-summary?id=${data.id}&mode=log`);
        } else {
            executeListAction(action, data.id, {})
        }
    }

    const actionsMenu = () => {
        const isNewGroup = (group) => {
            let isNew = false;
            if (groupNum.current != group) {
                isNew = true
                groupNum.current = group;
            }
            return isNew;
        }

        return (
            <Menu className={style.actionsMenuContainer}>
                {actionsList.slice(1).map((action, index) =>
                    <MenuItem key={index} className={`${isNewGroup(action.group) ? style.groupSeperator : ""} ${style.actionsMenuItem}`} title={action.label}>
                        <button onClick={() => _executeAction(action.id)} className={isEnglish() ? style.actionBtn : style.actionBtnAr}>
                            <span>{action.icon}</span>
                            <label>{action.label}</label>
                        </button>
                    </MenuItem>
                )}
            </Menu>
        );
    }

    return (
        <div>
            {actionsList?.length > 0 &&
                <button
                    className={`${style.viewBtn} mainbutton`}
                    onClick={() => _executeAction(actionsList[0].id)}
                >
                    {actionsList[0].icon}
                    {actionsList[0].label}
                    <div className={style.actionsSpinnerContainer} id={`transaction-action-btn-spinner-id-${actionsList[0].id}-${data.id}`}>
                        <LoadingSpinner className={style.actionsSpinner} />
                    </div>
                </button>}
            <Dropdown
                className={style.settingBtn}
                minOverlayWidthMatchTrigger={true}
                trigger={['click']}
                overlay={actionsMenu}
                align={{ offset: [0, 0] }}
                openClassName={style.dropdownOpen}
            >
                <button className='dropdown-open'>
                    <ActionTableIcon />
                </button>
            </Dropdown>
        </div>
    )
}

const Actions = (props) => {
    const { user, data, msgDialogRef, hangUpDialogRef, changePriorityDialog, divertToEstimatorDialogRef, executeListAction, workFlowStatusDialogRef, chooseReportDialogRef, estimationRatingDialogRef, recoveryDialogRef, summaryDialogRef, qrCodeDialogRef } = props;

    const { t } = useTranslation('estimationTransaction');
    const navigate = useNavigate();

    return (
        <div className={style.actionsEstimationTransactions}>
            <div>
                {user.estimationPolicy?.actions?.indexOf(`${ACTION_ADD_QR_CODE}`) !== -1 &&
                    <button className={style.qrCodeButton} onClick={() => qrCodeDialogRef?.current?.open(data?.id, data.qrImagePath, data.depositCode)}>
                        <QrCodeIcon />
                        <span className={isNotEmpty(data.qrImagePath) ? 'active' : ''} ></span>
                    </button>
                }
                <button onClick={() => summaryDialogRef?.current?.open(data.id)} title={t('view_summary')}>
                    <EyeIcon />
                </button>
                {data.specialNotesCount > 0 &&
                    <div className={style.specialNoteBtn} >
                        <button onClick={() => navigate(`/trx-summary?id=${data.id}`)} title={t('view_notes')}>
                            <span>{data.specialNotesCount}</span>
                            <NoteIcon />
                        </button>
                    </div>
                }
            </div>
            <ActionsBtn
                user={user}
                data={data}
                msgDialogRef={msgDialogRef}
                HangUpDialogRef={hangUpDialogRef}
                changePriorityDialog={changePriorityDialog}
                divertToEstimatorDialogRef={divertToEstimatorDialogRef}
                executeListAction={executeListAction}
                workFlowStatusDialogRef={workFlowStatusDialogRef}
                chooseReportDialogRef={chooseReportDialogRef}
                estimationRatingDialogRef={estimationRatingDialogRef}
                recoveryDialogRef={recoveryDialogRef}
            />
        </div>
    )
}

export default Actions;