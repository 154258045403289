import React from "react";

function ImportantTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.514"
      height="18.236"
      viewBox="0 0 17.514 18.236"
    >
      <path
        fill="#657489"
        d="M17.312 7.4a.614.614 0 00-.847.02l-8.429 8.469A4.029 4.029 0 012.2 10.331a2.95 2.95 0 01.137-.137L10.5 2a2.481 2.481 0 113.51 3.509L5.847 13.7a.949.949 0 01-1.319 0 .936.936 0 01-.05-1.272l.047-.048 7.269-7.307a.613.613 0 000-.868.624.624 0 00-.867 0l-7.269 7.3a2.16 2.16 0 000 3.058 2.213 2.213 0 003.06 0l8.16-8.2A3.709 3.709 0 0012.345 0h-.053a3.684 3.684 0 00-2.585 1.05 1.63 1.63 0 00-.072.072l-8.167 8.2A5.258 5.258 0 008.9 16.755L17.332 8.3a.616.616 0 000-.87z"
        data-name="Path 43626"
      ></path>
    </svg>
  );
}

export default ImportantTableIcon;