import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3236_VALUATION_HISTORY_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import EstimationsHistoryDialog from "./dialogs";
import EstimationsHistoryTable from "./listing";
import EstimationsHistoryFilter from "./listing/Filters";

const EstimationsHistory = () => {

    const { t } = useTranslation('estimationsHistory');
    const searchTextRef = useRef();
    const estimationsHistoryDialogRef = useRef(null);
    const tableRef = useRef();
    const filterRef = useRef();
    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3236_VALUATION_HISTORY_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('ref_num') + ' , ' + t('desc')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3236_VALUATION_HISTORY_FORM_ID}
                    />
                    <button onClick={() => filterRef.current?.open()} className="headerPanelBtn">
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <EstimationsHistoryTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                filterRef={filterRef}
                estimationsHistoryDialogRef={estimationsHistoryDialogRef}
            />

            <EstimationsHistoryFilter ref={filterRef} tableRef={tableRef} />

            <EstimationsHistoryDialog ref={estimationsHistoryDialogRef} user={user} tableRef={tableRef} />
        </>
    )
}

export default EstimationsHistory;