import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DecimalInput } from '../../../../components/decimal-input';
import { isOrgCurrency } from '../../../../util/util';
import DetailsCard from "./Card";
import style from './style.module.scss';

const InputNumber = (props) => {
    const { user, feild, handleChange, inCard, inTable } = props;
    const trnxData = useSelector(state => state.estTrnxReducer.data)
    const inputValue = useSelector(state => state.estTrnxReducer.data[feild.internalName]);

    const [data, setData] = useState(parseFloat(inputValue || '0'));

    useEffect(() => {
        setData(parseFloat(inputValue || 0))
    }, [inputValue])

    const disableFcFeild = () => {
        if (feild.internalName.endsWith('_AFC') && isOrgCurrency(user, trnxData.TRI_FCU_ID)) {
            return true;
        }
        return false;
    }

    const _handleBlur = (name, value) => {
        if (inputValue != value) {
            handleChange(name, value)
        }
    }

    return (
        <DetailsCard feild={feild} inCard={inCard} inTable={inTable}>
            <div className={style.trxInput}>
                <DecimalInput
                    id={`${feild.internalName}-feild`}
                    value={data}
                    className='frm-entry-input'
                    disabled={!feild.enabled || disableFcFeild()}
                    onChange={value => setData(value)}
                    onBlur={value => _handleBlur(feild.internalName, value)}
                    withoutFormat={!feild.moneyFormat}
                    decimalScale={feild.internalName == "TRI_LONGITUDE" || feild.internalName == "TRI_LATITUDE" ? 8 : (!feild.moneyFormat) ? -1 : null}
                    allowDecimals
                />
            </div>
        </DetailsCard>
    )
}

export default InputNumber;