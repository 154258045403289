import { RSM3009_CREATE_CUSTOMER_RSM_FORM_ID } from "../../../../util/constants/forms";
import OrganizationList from '../organization-list';

const Tab2 = (props) => {
    const { tab, customer, setCustomer } = props;

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <OrganizationList
                data={customer}
                setData={setCustomer}
                formId={RSM3009_CREATE_CUSTOMER_RSM_FORM_ID}
                inSidePanel
            />
        </div>
    )
}

export default Tab2;