
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM2667_DIVISIONS_DEFINITIONS_FORM_ID } from "../../util/constants/forms";
import DivisionDefinitionDialog from "./dialogs";
import DivisionDefinitionTable from "./listing";
import DivisionDefinitionFilter from "./listing/Filters";
import { getUser } from "../../util/cookies";

const DivisionDefinition = () => {
    const searchTextRef = useRef("");
    const filterRef = useRef({});
    const tableRef = useRef();
    const dialogRef = useRef(null);
    const user = getUser();

    const { t } = useTranslation("divisionDefinition");

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM2667_DIVISIONS_DEFINITIONS_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => tableRef.current.refresh()}
                        formId={RSM2667_DIVISIONS_DEFINITIONS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <DivisionDefinitionTable
                filterRef={filterRef}
                dialogRef={dialogRef}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                user={user}
            />

            <DivisionDefinitionFilter
                ref={filterRef}
                tableRef={tableRef}
            />

            <DivisionDefinitionDialog
                ref={dialogRef}
                tableRef={tableRef}
                user={user}
            />
        </>
    )
}
export default DivisionDefinition;