import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { isEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { getCommonPartiesLov, getCurrentTime, getRsmEstimator } from "../../../util/apis";
import { ACTION_DIVERT_TO_OTHER_ESTIMATOR, ACTION_DIVERT_TO_OTHER_INSPECTOR, ESTIMATOR_PARTY, INSPECTOR_PARTY, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const DivertToEstimatorDialog = (props, ref) => {
    const { okAction } = props;

    const { t } = useTranslation("estimationTransaction");
    const trnxIdRef = useRef();
    const dialogRef = useRef();

    const [data, setData] = useState({});
    const [isInspector, setIsInspector] = useState(true);

    const _handleDataChanged = (name, value) => {
        data[name] = value;
        setData({ ...data });
    }

    const _okAction = () => {
        if (okAction) {
            let action = isInspector ? ACTION_DIVERT_TO_OTHER_INSPECTOR : ACTION_DIVERT_TO_OTHER_ESTIMATOR;
            okAction(trnxIdRef.current, action, { newInspector: data.newInspectorAprId, newEstimator: data.newEstimatorAprId, diversionDate: data.diversionDate })
        }
    }

    const _postClose = () => {
        setData({});
        trnxIdRef.current = "";
    }

    const open = (trnx, action) => {
        setIsInspector(action == ACTION_DIVERT_TO_OTHER_INSPECTOR)
        getCurrentTime()
            .then((response) => {
                data.diversionDate = response.data;
                setData({ ...data })
            })
            .catch(() => { })
        trnxIdRef.current = trnx.id;
        data.code = trnx.code;
        data.dateTime = trnx.dateTime;
        data.oldInspectorAprId = trnx.inspectorAprId;
        data.oldEstimatorAprId = trnx.estimatorAprId;
        setData({ ...data });
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }))

    return (
        <Modal
            id="divert-to-estimator-dialog"
            ref={dialogRef}
            formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID}
            title={t(isInspector ? 'divert_to_other_inspector' : 'divert_to_other_estimator')}
            withFooter
            okBtnLabel={t("save")}
            withOkButton
            withCancelButton
            okBtnStyleAction="primary-button"
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('exit')}
            className={style.divertToEstimatorDialog}
            okBtnAction={_okAction}
            postClose={_postClose}
        >
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50">
                        <label className='form-label mandatory'>{t('transaction_code')}</label>
                        <FrmInput
                            id="transaction_code"
                            value={data.code}
                            disabled={true}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label mandatory'>{t('date')}</label>
                        <FrmTimeDatePickr
                            id="date"
                            value={data.dateTime}
                            enableTime={true}
                            disabled={true}
                        />
                    </div>
                    <div className="w50">
                        <label className='form-label mandatory'>{t('diversion_date')}</label>
                        <FrmTimeDatePickr
                            id="diversion_date"
                            value={data.diversionDate}
                            enableTime={true}
                            disabled={true}
                        />
                    </div>
                    <div className="w50"></div>
                </div>
            </div>
            <div className={`container ${style.estimatorContainer}`}>
                <div className='row  rowSmModel'>
                    {isInspector ?
                        <div className="w50">
                            <label className={`form-label ${style.lblStatus}`}>{t('old')}</label>
                            <label className='form-label mandatory'>{t('inspector')}</label>
                            <FrmSelectMenu
                                id="old-inspector"
                                value={data.oldInspectorAprId}
                                key={`from-${data.code || -1}`}
                                api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, isEmpty(data.oldInspectorAprId) ? [] : [data.oldInspectorAprId], STATUS_ACTIVE, null, 1)}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={e => _handleDataChanged("fromAprId", e.value)}
                                portalTarget={document.body}
                                isDisabled={true}
                            />
                        </div>
                        :
                        <div className="w50">
                            <label className={`form-label ${style.lblStatus}`}>{t('old')}</label>
                            <label className='form-label mandatory'>{t('estimator')}</label>
                            <FrmSelectMenu
                                id="old-estimator"
                                value={data.oldEstimatorAprId}
                                api={() => getRsmEstimator()}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                portalTarget={document.body}
                                isDisabled={true}
                            />
                        </div>
                    }
                    <div className="w50"></div>
                    {isInspector ?
                        <div className="w50">
                            <label className={`form-label ${style.lblStatus}`}>{t('new')}</label>
                            <label className='form-label mandatory'>{t('inspector')}</label>
                            <FrmSelectMenu
                                id="new-inspector"
                                value={data.newInspectorAprId}
                                key={`from-${data.code || -1}`}
                                api={() => getCommonPartiesLov(true, [ESTIMATOR_PARTY, INSPECTOR_PARTY], true, null, [data.newInspectorAprId], STATUS_ACTIVE, null, 1)}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={e => _handleDataChanged("newInspectorAprId", e.value)}
                                menuPlacement="top"
                                portalTarget={document.body}
                            />
                        </div>
                        :
                        <div className="w50">
                            <label className={`form-label ${style.lblStatus}`}>{t('new')}</label>
                            <label className='form-label mandatory'>{t('estimator')}</label>
                            <FrmSelectMenu
                                id="new-estimator"
                                value={data.newEstimatorAprId}
                                api={() => getRsmEstimator()}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                onChange={e => _handleDataChanged("newEstimatorAprId", e.value)}
                                menuPlacement="top"
                                portalTarget={document.body}
                            />
                        </div>
                    }
                    <div className="w50"></div>
                </div>
            </div>
        </Modal>
    )
}

export default forwardRef(DivertToEstimatorDialog);