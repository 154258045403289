import React from "react";

function MessagesArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.04"
      height="17.252"
      viewBox="0 0 10.04 17.252"
    >
      <path
        fill="none"
        stroke="#b1adad"
        strokeLinecap="round"
        strokeWidth="2"
        d="M8.626 1.414L1.414 8.625l7.212 7.213"
        data-name="Path 6441"
      ></path>
    </svg>
  );
}

export default MessagesArrowIcon;