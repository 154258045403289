import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPoliciesList } from "../../../../util/apis";

const PolicyFilter = (props, ref) => {
    const { filterRef, formId, id, className, functionFormId } = props;
    const { t } = useTranslation();
    const [lsoId, setLsoId] = useState();
    const [key, setKey] = useState(0);

    const _handlePolicyChange = (value) => {
        setLsoId(value.value);
        filterRef?.current?.handleDataChange("policy", value);
    }

    const setPolicyId = (value) => {
        setLsoId(value);
        setKey(key + 1);
    }

    useImperativeHandle(ref, () => ({ setPolicyId }));

    return (
        <div id={id} className={`headerFilter ${className}`}>
            <label className="form-label mandatory">{t("policy")}<span className="label-info"> {`(${t("security_applied")})`}</span></label>
            <FrmSelectMenu
                id={'policy-filter'}
                key={key}
                value={lsoId}
                onChange={(value) => _handlePolicyChange(value)}
                api={() => getPoliciesList(formId, lsoId, null, functionFormId)}
                descLo={["descLo"]}
                descFo={["descFo"]}
                isClearable={false}
                portalTarget={document.body}
            />
        </div>
    );
}

export default forwardRef(PolicyFilter);