import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import { RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import PropertiesEstimationListOfValuesDialog from "./dialogs";
import PropertiesEstimationListOfValuesTable from "./listing";

const PropertiesEstimationListOfValues = () => {
    const { t } = useTranslation("propertiesEstimationListOfValues");

    const searchTextRef = useRef();
    const tableRef = useRef();
    const dialogRef = useRef();

    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('code') + " , " + t('name') + " , " + t('short_name')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID}
                    />
                </div>
            </div>

            <PropertiesEstimationListOfValuesTable
                user={user}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                dialogRef={dialogRef}
            />

            <PropertiesEstimationListOfValuesDialog user={user} ref={dialogRef} tableRef={tableRef} />
        </>
    )
}

export default PropertiesEstimationListOfValues;