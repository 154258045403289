import React from "react";

function ReloadDashBordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.979"
      height="25.979"
      viewBox="0 0 25.979 25.979"
    >
      <g
        fill="#ab8860"
        data-name="Group 176755"
        transform="translate(-168 -224.51)"
      >
        <path
          d="M15.865 25.229h-7.25C5.267 25.229 3 24.521 1.479 23S-.75 19.212-.75 15.865v-7.25c0-3.348.708-5.615 2.229-7.136S5.267-.75 8.615-.75h7.25c3.348 0 5.615.708 7.136 2.229s2.229 3.788 2.229 7.136v7.25c0 3.348-.708 5.615-2.229 7.136s-3.789 2.228-7.136 2.228zM8.615 1.063c-2.835 0-4.7.54-5.854 1.7s-1.7 3.019-1.7 5.854v7.25c0 2.835.54 4.7 1.7 5.854s3.019 1.7 5.854 1.7h7.25c2.835 0 4.7-.54 5.854-1.7s1.7-3.019 1.7-5.854V8.615c0-2.835-.54-4.7-1.7-5.854s-3.019-1.7-5.854-1.7z"
          opacity="0.55"
          transform="translate(168.75 225.26)"
        ></path>
        <g transform="translate(174.043 231.301)">
          <path
            d="M8.337 8.506H2.392a.906.906 0 110-1.812h5.945a2.815 2.815 0 100-5.631H.156a.906.906 0 010-1.813h8.18a4.628 4.628 0 110 9.256z"
            data-name="Vector"
            transform="translate(.931 2.683)"
          ></path>
          <path
            d="M2.053 4.869A.9.9 0 011.41 4.6l-1.9-1.909a.906.906 0 010-1.28l1.9-1.9A.906.906 0 012.694.8L1.436 2.055 2.7 3.324a.906.906 0 01-.643 1.545z"
            data-name="Vector"
            transform="translate(.75 .75)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ReloadDashBordIcon;