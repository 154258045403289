import React from "react";

function FieldIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.5"
            height="21.5"
            viewBox="0 0 21.5 21.5"
        >
            <g
                fill="#c6cbd3"
                data-name="Group 177527"
                transform="translate(-1220.25 -281.25)"
            >
                <path
                    d="M2.1-.75h15.8c1.918 0 2.85.975 2.85 2.98v4.04c0 2.005-.932 2.98-2.85 2.98H2.1c-1.918 0-2.85-.975-2.85-2.98V2.23C-.75.225.182-.75 2.1-.75zm15.8 8.5c1 0 1.35-.224 1.35-1.48V2.23C19.25.974 18.9.75 17.9.75H2.1C1.1.75.75.974.75 2.23v4.04c0 1.256.354 1.48 1.35 1.48z"
                    transform="translate(1221 293.5)"
                ></path>
                <path
                    d="M2.1-.75h15.8c1.918 0 2.85.975 2.85 2.98v4.04c0 2.005-.932 2.98-2.85 2.98H2.1c-1.918 0-2.85-.975-2.85-2.98V2.23C-.75.225.182-.75 2.1-.75zm15.8 8.5c1 0 1.35-.224 1.35-1.48V2.23C19.25.974 18.9.75 17.9.75H2.1C1.1.75.75.974.75 2.23v4.04c0 1.256.354 1.48 1.35 1.48z"
                    data-name="Vector"
                    opacity="0.4"
                    transform="translate(1221 282)"
                ></path>
            </g>
        </svg>
    );
}

export default FieldIcon;
