import React from "react";

function ListIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
    >
      <g data-name="list(1)" transform="translate(0 -3)">
        <path
          d="M7 6h16a1 1 0 000-2H7a1 1 0 000 2z"
          data-name="Path 43631"
        ></path>
        <path
          d="M23 11H7a1 1 0 000 2h16a1 1 0 000-2z"
          data-name="Path 43632"
        ></path>
        <path
          d="M23 18H7a1 1 0 000 2h16a1 1 0 000-2z"
          data-name="Path 43633"
        ></path>
        <circle
          cx="2"
          cy="2"
          r="2"
          data-name="Ellipse 57"
          transform="translate(0 3)"
        ></circle>
        <circle
          cx="2"
          cy="2"
          r="2"
          data-name="Ellipse 58"
          transform="translate(0 10)"
        ></circle>
        <circle
          cx="2"
          cy="2"
          r="2"
          data-name="Ellipse 59"
          transform="translate(0 17)"
        ></circle>
      </g>
    </svg>
  );
}

export default ListIcon;
